



.custom-popover-wrapper .ant-popover-inner{
    width: 230px !important;
    height: 130px !important;
    background: #255F63;
    border-radius: 5px;
}
    