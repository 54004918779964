.root-user-container {
  font-size: 16px;
  font-family: "Poppins";
}
.user-dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 2em;
  width: 100%;
}
.user-dasboard-left-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: start;
  width: 67%;
}
.user-dasboard-right-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: start;
  width: 33%;
}
.user-dashboard-small-card-title {
  font-size: 1.2em;
  font-family: "Poppins";
  font-weight: 600;
  line-height: normal;
  font-style: normal;
}
.user-dashboard-small-card-para {
  font-size: 1em;
  font-family: "Poppins";
  font-weight: 500;
  line-height: normal;
  font-style: normal;
  margin: 0;
}
.user-board-welcome-banner-heading {
  font-size: 1.5em;
  color: #fff;
  font-family: "Poppins";
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.user-board-welcome-banner-para {
  font-size: 1.1em;
  font-family: "Poppins";
  color: #fff;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.user-board-welcome-banner-button,
.user-board-welcome-banner-button:hover,
.user-board-welcome-banner-button:focus {
  background-color: #4ccb1f;
  font-size: 1.25em;
  font-weight: 600;
  font-family: "Poppins";
  padding: 0.5em 1em;
  border-radius: 0.5em;
  border: none;
  color: #fff;
  text-align: center;
  height: 3em;
  white-space: nowrap;
  margin-top: 1em;
}
.user-dasboard-banner-for-survey-taken {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  height: fit-content;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}
.user-board-welcome-banner-heading-taken {
  color: #4ccb1f;
  font-size: 1.25em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.user-board-welcome-greeting-taken {
  color: #000000;
  font-size: 2.2em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.baner-tip-title {
  color: #000000;
  font-size: 1em;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.baner-tip-content {
  color: #000000;
  font-size: 1em;
  font-weight: 400;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
  padding-top: 0.5em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-dashboard-okr-performance-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  justify-content: start;
  width: 100%;
  padding: 1em;
}
.user-board-section-heading {
  color: #000000;
  font-size: 1.35em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
  text-align: left;
}
.dashboard-performance-table-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}
.dashboard-performance-table {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.dashboard-performance-table-data-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 2em;
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  border: 1px solid #d5e5f2;
  width: 100%;
}
.dasboard-performance-title-column {
  width: 45% !important;
}
.dasboard-performance-progress-column {
  width: 20% !important;
}
.dasboard-performance-status-column {
  width: 15% !important;
}
.dasboard-performance-date-column {
  width: 15% !important;
}
.dashboard-performance-status-btn {
  width: 8em;
  height: 2.5em;
  border-radius: 0.5em;
  font-size: 0.85em;
  font-weight: 700;
  font-family: "Manrope";
  text-align: center;
  padding: 0.5em;
}
.no-performance-container {
  border-radius: 0.5em;
  border: 1px solid #d5e5f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  height: 25em;
}
.dashboard-performance-overflow {
  overflow: auto;
  height: 22em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.dashboard-engagement-overflow {
  overflow: auto;
  height: 30em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.dashobard-performance-table-heading {
  color: #a3a3a3;
  font-size: 1em !important;
  font-family: "Manrope";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
}
.dashboard-engagement-container {
  border: 1px solid #d5e5f2;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1em;
  width: 100%;
}
.dashboard-engagement-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 2.5em;
  flex-wrap: wrap;
}
.dashboard-engagement-sub-container .sub-title {
  color: #a3a3a3;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.dashboard-engagement-container .main-title {
  color: #303030;
  font-size: 1.2em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}
.dashboard-engagement-sub-container .content-title {
  color: #303030;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.user-dasboard-small-card-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
}
.small-kudos-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  height: auto;
}
.small-one-on-one-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  height: 28em;
}
.small-one-on-one-container-overflow {
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: auto;
  height: 22em;
}
.small-team-members-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  max-height: 25em;
}
.one-on-one-see-detail-button {
  color: #0dd671;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
.user-dashboard-small-card-para-one-on-one {
  color: #aabac6;
  font-size: 0.85em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  margin: 0;
}
.dashboard-kudos-heading {
  color: #000000;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  margin: 0;
}
.dashboard-kudos-image-container {
  background-color: #f6fbff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em;
}
.dashboard-scape-card {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: start !important;
  justify-content: center;
  background-color: #f9fcff;
  border-radius: 0.5em;
  padding: 0.75em 1em;
  width: 15em;
}
.team-assessment-data-cintainer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1em;
  padding: 1em;
  border: 1px solid #d5e5f2;
  border-radius: 0.5em;
}
.dashboard-member-search-section {
  width: 100%;
  background-color: rgba(170, 186, 198, 0.2);
  border-radius: 0.75em;
  height: 3.5em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 1em;
}
.dashboard-member-search-input {
  border: none !important;
  height: 2.5em;
  font-family: "Poppins";
  font-size: 1em;
  color: #000000;
  font-style: normal;
  background-color: rgba(170, 186, 198, 0.01);
}
.dashboard-member-section-member-container {
  border: 1px solid rgba(213, 229, 242, 1);
  border-radius: 0.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0.5em 1em;
  width: 100%;
}
.dashboard-view-more-button:hover {
  color: #4ccb1f;
}
.dashboard-check-box {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #8c8ca1;
  cursor: pointer;
}
.dashboard-check-box:hover {
  border: 1px solid #4ccb1f;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots {
  margin-left: 0px !important;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots-bottom {
  bottom: 12px;
  height: 10px;
  margin-right: 20px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button {
  background: #d9d9d9;
  height: 10px;
  width: 10px;
  border-radius: 10px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active {
  width: 17px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active button {
  background: #4ccb1f;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button:before {
  color: #ffff;
  visibility: hidden;
}
.dashboard-banner-section-container {
  width: 60%;
}
.dashboard-banner-image {
  max-width: 30%;
  max-height: 11em;
}
.soul-tip-dashboard .slick-list {
  margin: 0px 0px !important;
}
.text-overflow-elipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.survey-360-table-ttile{
  font-family: "Manrope";
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  font-style: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.survey-360-table-data-title{
  font-family: "Poppins";
  font-size: .9em;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.survey-360-table-pending-status{
  background: rgba(249, 95, 83, 0.2);
  color:rgba(249, 95, 83, 1);
  font-size: .95em;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  padding: .25em 1em;
  border-radius: .25em;
  width: 8em;
  text-align: center;
  margin: 0;
}
.survey-360-table-data-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  border-radius: .5em;
  border: 1px solid #D5E5F2;
  padding: .5em 1em;
}
@media (max-width: 1800px) {
  .root-user-container {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .root-user-container {
    font-size: 12px;
  }
}
@media (max-width: 960px) {
  .root-user-container {
    font-size: 10px;
  }
  .user-dashboard-container {
    flex-wrap: wrap;
  }
  .user-dasboard-left-container {
    width: 100%;
  }
  .user-dasboard-right-container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .root-user-container {
    font-size: 8px;
  }
  .user-dasboard-banner-for-survey-taken {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dashboard-banner-section-container {
    width: 100%;
  }
  .dashboard-banner-image {
    display: none;
  }
}

.soul-tip-preview-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preview-tip-popup{
  margin-top: 1em;
  font-size: 1.25em;
  text-align: center;
}

.soul-tip-available-effect:hover{
  background: #fbfffb;
  cursor: pointer;
}