.idp-card{
    box-shadow: 0px 10px 15px 0px #AABAC633;
    background: #FFFFFF;
    border-radius: 0.75em;
    padding: 1em 2em;
    margin-top: 1em;
}

.idp-create-body{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-top: 1em;
}

.idp-switch-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
    width: fit-content;
}
.idp-switch-tab-text{
    padding: .5em ;
    font-family: "Poppins";
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: 15.5em;
    height: 3em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.idp-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}

.idp-note{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    color: #8998A2;
    margin-top: 1em;
}

.idp-input,.idp-input:hover,.idp-input:focus{
    width: 100%;
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.idp-form-label{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}

.idp-chip-wraper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25em;
    margin-top: 1em;
}

.idp-ck-editor .cke_top {
    border-bottom: 1px solid #D5E5F2;
    background: #fff !important;
    padding: 5px 0px 5px 0px !important;
    white-space: normal;
}


.idp-ck-editor .cke_button__underline{
    border-right: 2px solid #D5E5F2;
    padding-right: 10px;
}
.idp-ck-editor .cke_toolgroup{
    margin: 0px !important;
}
.idp-ck-editor .cke_button{
    height: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.idp-ck-editor .cke_button__bulletedlist{
    border-right: 2px solid #D5E5F2;
    padding-right: 10px;
}

.idp-ck-editor .ant-form-item-control-input-content{
 padding: 0px !important;
}

.idp-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}

.idp-ck-editor .cke_bottom{
display: none;
}
.idp-ck-editor .cke_reset{
    padding: 0px 0px;
}
.idp-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.idp-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.idp-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.idp-ck-editor .cke_toolgroup a.cke_button:last-child:after{
    border-right: none !important ;
}

.idp-row-wrapper{
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    flex-wrap: nowrap;
    align-items: center;
}

.idp-btn{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 500;
    line-height:1.25em;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.5em 1.25em;
    height: 3em;
    margin: 1em 0px !important;
    margin-right: 1em !important;
    border-radius: 0.5em;
    min-width: 9em !important;
}

.idp-submit, .idp-submit:hover, .idp-submit:focus{
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px #0000000B;
    color: #FFFFFF;
    border: 1px solid #4CCB1F
}

.idp-cancel, .idp-cancel:hover, .idp-cancel:focus{
    border: 1px solid #AABAC6;
    background: #FFFFFF;
    color: #8998A2;
}

.idp-submit-outline, .idp-submit-outline:hover, .idp-submit-outline:focus{
    background: #FFFFFF;
    color: #4CCB1F;
    border: 1px solid #4CCB1F
}

.row-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.idp-modal .ant-modal-content{
    background: #FFFFFF;
}

.idp-select-container{
    width: 100%;
    margin-top: 2em;
}

.idp-select .ant-select-selector{
    height: 3em !important;
    border-radius: 0.75em !important;
    border: 1px solid #AABAC6 !important;
}

.idp-detail-select .ant-select-selector, .idp-detail-select .ant-select-selector:focus{
    border: 1px solid transparent !important;
    outline: none !important;
}

.idp-select .ant-select-selection-placeholder , .idp-select .ant-select-selection-item{
    display: flex !important;
    align-items: center !important;
    color:#AABAC6 !important;
    font-size: 1.25em !important;
}

.idp-detail-select .ant-select-selection-placeholder , .idp-detail-select .ant-select-selection-item{
    color:#000 !important;
    font-size: 1.5em !important;
    font-weight: 600 !important;
}

.idp-select .ant-select-selection-search-input{
    height: 100% !important;
    color:#AABAC6 !important;
    font-size: 1.25em !important;
}


@media screen and (max-width:1200px) {
    .idp-create-body{
        width: 75%;
    }
}

@media screen and (max-width:960px) {
    .idp-create-date{
        height: 2.25em !important;
    }
}

@media screen and (max-width:800px) {
    .idp-create-body{
        width: 100%;
    }
    .idp-switch-tab-text{
        width: 12em;
    }
}

@media screen and (max-width:600px) {
    .idp-switch-tab{
        width: 100%;
    }
    .idp-switch-tab-text{
        width: 100%;
    }
    .idp-mobile-col{
        flex-direction: column !important;
    }
    .idp-create-date{
        max-width: 100% !important;

    }
}

.idp-create-date{
    max-width: 15em;
    height: 2.5em;
}