@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

// custom styles
.HVCenter {
  height: 85vh;
  width: 95vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0 20px !important;
  padding: 0;
  overflow: hidden;
}

@primary-color: #B0CB1F;@skeleton-color: #fff;@radio-dot-color: #881FCC;@slider-rail-background-color: #c9c9c9;