

/* File upload css*/

.rd-file-upload-drag{
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.rd-file-upload-label{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
}
.rd-uploaded-file-container{
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    padding: 1.5em;
    display: flex;
    flex-direction:column;
    gap: 1em;
    margin-top: 1em;
}
.rd-file-name{
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    margin: 0;
}
.rd-file-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
    width: 100%;
}
.rd-uploaded-file-container .ant-progress-inner{
    border-radius: 8px !important;
}
.rd-divider{
    margin: 2em 0em!important;
}
.rd-divider .ant-divider-inner-text{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    margin: 0em 1em !important;
}
.rd-divider.ant-divider-with-text::before{
    border-top:1px solid #8C8CA1 !important;
}
.rd-divider.ant-divider-with-text::after{
    border-top:1px solid #8C8CA1 !important;
}

.rd-template-container{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
}

.sample-xlsx-container{
  position: absolute;
  left: 0.75em;
  top: 0.25em;
  height: 2.5em;
}

.template-label{
  color: #000;
  font-family: Poppins;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
  text-align: center;
  margin-top: 0.65em;
}

.sample-xlsx-div{
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rd-url-upload-container{
  position: absolute;
  right: 0.25em;
  top: 0.25em;
  border-radius: 0.5rem;
  background: #F4F9FD;
  width: 6em;
  height: 2.5em;
  overflow: hidden;
  cursor: pointer;
}

.rd-url-upload-label{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rd-url-upload-btn{
    color: #0098F0;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0px;
   
}
.rd-rogress-count{
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    position: absolute;
    top: -25px;
    right: 10px;
}
.rd-file-size{
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin: 0;
}
.rd-search-input-filter, .rd-search-input-filter:hover, .rd-search-input-filter:focus {
    border-radius: 0.5em 0em 0em 0.5rem;
    border: 1px solid #A9A9A9;
    background: #FFF;    
    padding-left: 35px;
    width: 16em;
    height: 3em !important;
    font-size: 1.1em;
    /* border-right: none; */
    font-family: 'Poppins';
    font-weight: 500;
  }
  .rd-input-filter-new {
    border: 1px solid #A9A9A9;
    background: #FFF;   
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    height: 3em !important;
    font-size: 1em;
    border-right: none;
    font-family: 'Poppins';
    font-weight: 500;
    height:46px !important;
  }
  
  .rd-input-filter-new:hover {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  
  .rd-input-filter-new:focus {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  .rd-input-filter {
    border: 1px solid #A9A9A9;
    background: #FFF;   
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;
    /* height: 3.3em !important; */
    height:46px !important;
  }
  .rd-input-filter:hover {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  
  .rd-input-filter:focus {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000";
  }
  .rd-search-menu-title{
    color: #4CCB1F;
    font-family: "Poppins";
    font-size: 1.1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rd-dropdown-btn{
    width: 250px;
    min-width:100px;
    display:flex;
    height:46px !important;
    justify-content:space-between;
    align-items:center;
    gap:5px;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 0em .5em !important;
    border-right:1px solid #A9A9A9;
  }
  .rd-add-plus-btn, .rd-add-plus-btn:hover, .rd-add-plus-btn:focus{
    border-radius: 1rem;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    width: 3em;
    height: 1.4em !important;
    margin-top: .5em;
    cursor: pointer;
  }
  .rd-selected-main-competecny-title-container{
    border-radius: 0.5em;
    background: #FFF;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    padding:1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .5em;
  }
  .rd-create-main-competency-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 2em;
    position: relative;
}
  .cp-modal-close-icon{
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
}
.cp-modal-max-width{
  max-width: 58em;
  padding: 0em 2em;
}

.rd-add-new-popover .ant-popover-inner{
  background:rgba(170, 186, 198, 1) !important;
  border-radius:.875em !important;
  margin-bottom:-4px !important;
  margin-right:-10px !important;
}
.rd-add-new-popover .ant-popover-inner .ant-popover-inner-content{
  color: #fff !important;
  font-size: 1em;
  font-weight: 500 !important;
  font-family:"Poppins";
}
.rd-add-new-popover .ant-popover-content > .ant-popover-arrow{
  border-right-color:rgba(170, 186, 198, 1);
  border-bottom-color: rgba(170, 186, 198, 1);
  margin-bottom:-5px !important;
  margin-right:-8px !important;
}
.rd_competency_drop_render{
  background:  #ECF1F4;
  width: 98%;
  height: fit-content;
  max-height: 6em;
  overflow: auto;
}
.rd-selected-option{
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 500;
  color: #fff;
  background:#4CCB1F;
  line-height: 1.375em;
  padding: .5em;
}
.rd-competency-option{
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 500;
  color: #000;
  line-height: 1.375em;
  padding: .5em;
}
.rd-competency-option:hover{
  color: #fff;
  background:#4CCB1F;
}

.rd-expanded-detail-comp{
  height: 0px;
  padding: 0;
  border-radius: 0.75em;
  background: #F4F9FD;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: hidden;
  transition-duration: 0.5s;
}

.rd-open-expansion-detail-competency{
  height: 14.5em;
  padding: 1.2em;
  transition-duration: 0.5s;
  margin: .5em 1em;
}
.rd-expanded-label-title-comp{
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375em;
}
.rd-expanded-label-comp{
  color: #000;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75em;
  cursor: default;
}
.rd-expand-date-lable{
  color:#262626;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375em;
  cursor: default;
}
.rd-selected-competency-table-value-container{
  border-radius: 0.5rem;
  background: #F4F9FD;
  box-shadow: 0px 4px 0px -3px rgba(170, 186, 198, 0.50);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  height: 2.8125em;
  width: 100%;
  padding: .5em 1em;
}
.rd-selected-competency-table-value{
  color: #1F1F1F;
  text-align: center;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.175em;
}

.rd-table-select-filed .ant-select-selector,.rd-table-select-filed .ant-select-selector:hover,.rd-table-select-filed .ant-select-selector:focus,.rd-table-select-filed .ant-select-selector:focus-visible,.rd-table-select-filed .ant-select-selector:focus-within{
  background: #F4F9FD !important;
  height:2.8125em !important;
  border-radius: 0.5em !important;
  width:100% !important;
  font-family:'Poppins';
  border: none !important;
  box-shadow: 0px 4px 0px -3px rgba(170, 186, 198, 0.50);
  font-weight: 400;
  font-size: 1em;
}
.rd-table-select-filed .ant-select-selector .ant-select-selection-search-input{
  height:2.8125em !important;
  font-size: 1em;
  font-family:'Poppins';
  font-weight: 400;
}
.rd-tabel-type-select .ant-select-selector .ant-select-selection-item{
  padding-top: .275em !important;
  font-weight: 500;
  font-size: 1em;
}
.rd-tabel-type-select .ant-select-selector .ant-select-selection-placeholder{
  padding-top: .275em !important;
  font-weight: 500;
  font-size: 1em;
}

.rd-text-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0em;
  width: 14em;

}

.rd-text-ellipsis:before {
  content: '';
  display: block;
}

@media (max-width:693px) {
  .rd-coll-competency{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 1em !important;
  }
  .rd-dotted-button{
    margin-right: 0em !important;
  }
}
@media (max-width:480px) {
  .rd-dropdown-btn{
    min-width: 80px;
    width: fit-content;
  }
  .rd-search-input-filter{
    width: 12em ;
  }
}
@media (max-width:400px) {
  .rd-coll-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  .rd-add-button{
    margin-right: 0em !important;
  }
}