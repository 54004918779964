.coaching-title {
    margin: 0em !important;
    font-size: 38px;
}
.inner-page-heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #2E2E2E;
    margin: 0;
}
.page_heading-table-grow{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #414141;
    margin: 0;
}
.grow-custom-table{
    padding: 0px 75px;
}
.grow-custom-link{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 33px;
}
.grow-table-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    /* identical to box height */


    color: #000000;
}
.grow-detail-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #000000;
}
.grow-question-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25em;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
}

.coach-eng-comm-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
    text-align: justify;
    width: max-content;
    margin-bottom: 1em;
}
/* css below for commitment list section */
.comm-list-section{
    padding-top: 0.8em;
    width: 75%;
}
.grow-status-inprgress{
    background: rgba(255, 199, 0, 0.2);
    width: 8em;
    border-color: .5em;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1em;
    font-style: normal;
    line-height: normal;
    color: rgba(255, 199, 0, 1);
    padding: .35em 1em;
}
.grow-status-complete{
    background: rgba(13, 214, 113, 0.2);
    color: rgba(13, 214, 113, 1);
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    width: 8em;
    border-radius: .25em;

}

/* css for styling the table */

.ant-table-content-grow table {
    background-color: #f7fef1;
    /* width: 900px !important; */
}

.ant-table-content-grow .ant-table table {
    border-spacing: 0em !important;
}

.ant-table-content-grow .ant-table-thead .ant-table-cell {
    background-color: #e7f9e096;

}

.ant-table-content-grow .ant-table-cell p {
    margin-bottom: 0 !important;
}

/* css for table title */
.ant-table-content-grow .ant-table-title {
    background-color: #e7f9e096;

}

.grow-answer-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15em;
    line-height: normal;
    text-align: justify;
    letter-spacing: -0.8px;
    color: #000000;
    margin: 0;
}
.grow-table-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.35em;
    line-height: normal;
    text-align: justify;
    letter-spacing: -0.8px;
    color: #3B8855;
    margin: 0;
}
.ant-form-item-control-input-content{
    padding-left: 0px !important;
}
@media (max-width:1600px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 28px;
    }  
    .inner-page-heading{
        font-size: 30px;
        line-height: 40px;
    }
    .page_heading-table-grow{
        font-size: 37px;
        line-height: 50px;
    }
    .grow-custom-table{
        padding: 0px 55px;
    }
    .grow-custom-link{
        font-size: 16px;
        line-height: 22px;
    }
    .grow-table-content{
        font-size: 16px;
        line-height: 22px;
    }
    .grow-detail-title{
        font-size: 16px;
    }
}
@media (max-width:960px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 22px;
    }  
    .inner-page-heading{
        font-size: 26px;
        line-height: 36px;
    }
    .page_heading-table-grow{
        font-size: 30px;
        line-height: 45px;
    }
    .comm-list-section{
        padding-top: 0.8em;
        width: 100%;
    }
}
@media (max-width:480px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 16px;
    }  
    .inner-page-heading{
        font-size: 22px;
        line-height: 42px;
    }
    .page_heading-table-grow{
        font-size: 22px;
        line-height: 32px;
    }
    .grow-custom-table{
        padding: 0px 25px;
    }
}