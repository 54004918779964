
.sa-parametric-popup-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    align-items: center;
}
.sa-parametric-title{
    font-family: 'Poppins';
    font-size: 1.5em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-gropup-modal{
    min-width: 50%;
    width: 80%;
}
.sa-gropup-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
   
}