
.idp-detail-heading{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 2em;
    font-weight: 600;
    margin: 0;
}
.idp-detail-small-title{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
}
.idp-detail-para{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
}

.idp-markdown a {
    color: #009AF1 !important;
}

.idp-markdown *{
    word-break: break-all;
}

.idp-detail-left-container{
    width:60%;
}
.idp-detail-right-container{
    width:20%;
}
.idp-container-wrap{
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
}
.idp-textarea{
    border: 1px solid #AABAC6;
    border-radius: 1em;
    width:100%;
    height: 15em;
    resize: none;
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 500;
    outline: none;
    padding: 1em;
}
.idp-textarea:focus{
    outline: none;
}
.idp-date-picker-custom{
    border: 1px solid #fff;
    border-radius: 8px !important;
    width: 100%;
    background-color: #fff;
}
.idp-date-picker-custom input{
    font-family:'Poppins';
    color: #000;
    font-size: 1.5em !important;
    font-weight: 600 !important;
}
.idp-date-picker-custom:hover{
    border-radius: 8px;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus-visible{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus-within{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}

.detail-mobile-view{
    display: none;
}

.detail-web-view{
    display: block;
}

@media (max-width:1200px) {
    .idp-detail-left-container{
        width:60%;
    }
    .idp-detail-right-container{
        width:30%;
    }
}
@media (max-width:960px) {
    .idp-detail-left-container{
        width:60%;
    }
    .idp-detail-right-container{
        width:40%;
    }

    .idp-detail-left-container{
        width:100%;
    }
    .idp-detail-right-container{
        width:100%;
    }
    .idp-container-wrap{
        flex-direction: column !important;
    }
    .detail-mobile-view{
        display: block;
    }
    
    .detail-web-view{
        display: none;
    }
}

.idp-public-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5em;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    padding: 10px 20px 10px;
}

.idp-public-container{
  padding: 2em 1.25em;
}

.overflow-ellipse{
    max-width: 100% !important;
    overflow: hidden !important;
}

.idp-table-overflow{
    min-width: 800px;
}