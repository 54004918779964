
.bar-detail-heading-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
    padding: 0em 2.5em;
}
.bar-detail-title{
    color: #262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.bar-detail-container-seaction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
    width: 100%;
    margin-top: 2em;
}
.bar-select-filed .ant-select-selector,.bar-select-filed .ant-select-selector:hover,.bar-select-filed .ant-select-selector:focus,.bar-select-filed .ant-select-selector:focus-visible,.rd-select-filed .ant-select-selector:focus-within{
    background: linear-gradient(270deg, #25EA68 18.24%, #02D7A4 87.83%) !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: none !important;
    font-weight: 400;
    font-size: 1em;
    padding-right: 1em !important;
    min-width: 12em;
    width:fit-content;
}
.bar-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1.15em;
    font-family:'Poppins';
    color: #fff !important;
    font-weight: 400;
}
.bar-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 1.15em;
    text-align: left !important;
}
.bar-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 1.15em;
}
.bar-raing-scale-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.5em;
    padding: 0em 5em;
}
.bar-rating-card-container{
    padding: 1em;
}
.bar-rating-card{
    min-width: 17em;
    border-radius: 0.5em;
    background: #FFF;
    box-shadow: 0px 4px 89px 1px rgba(210, 216, 220, 0.57);
    width: 100%;
    min-height: 25em;
    max-height: 35em;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding:2em !important;
}
.bar-rating-scale-title{
    color:#262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.bar-rating-scale-description{
    color: #000;
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.05em;
    overflow-y: auto;
    word-break: break-all;
}
.bar-tab-container{
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0px 6px 45px 0px rgba(170, 186, 198, 0.21);
    width: 100%;
    height: 5em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 3em;
    padding: 2em;
    overflow-x: auto;
    overflow-y:hidden;
}
.bar-tab-container-seaction{
    padding: 0em 5.5em;
    width: 100%;
}
.bar-tab-title{
    text-align: center;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
}
.bar-sub-title-container{
    display: flex;
    justify-content: start;
    width:"100%";
    padding:0em 5.5em
}
@media (max-width:1000px) {
    .bar-rating-card-container{
        width: 33.33%;
    }
}
@media (max-width:800px) {
    .bar-rating-card-container{
        width: 50%;
    }
    .bar-sub-title-container{
        padding:0em 3.5em
    }
    .bar-tab-container-seaction{
        padding:0em 3.5em
    }
    .bar-raing-scale-container{
        padding:0em 3.5em
    }
}
@media (max-width:560px) {
    .bar-rating-card-container{
        width: 100%;
    }
    .bar-sub-title-container{
        padding:0em 2em
    }
    .bar-tab-container-seaction{
        padding:0em 2em
    }
    .bar-raing-scale-container{
        padding:0em 2em
    }
}