.create-bars-root {
    font-size: 16px;

}

.create-bars-body {
    width: 90%;
    border-radius: 0.8em;
    margin: auto;
    background-color: #F4F9FD;
    padding: 1.5em 4em;
}

.create-bars-header {
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 2.1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.37em;
    margin-bottom: 1em;
    /* 88% */
}

.create-bars-header span {
    font-weight: 700;
}

.create-bar-sub-he {
    font-size: 1.2em;
    font-weight: 500;
}

.bars-create-competency-title {
    padding: 0.7em 0em 0.7em 2.5em;
    border-radius: 0.5em;
    background: #fff;
    border: 1px solid #AABAC6;
}

.bars-tab-panel .ant-tabs-tab-active, .bar-sub-competency-view .ant-tabs-tab-active{
    background: #4CCB1F;
    color: #fff;
    border-radius: 0.5em;
}

.bars-tab-panel .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.bar-sub-competency-view .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: #fff;
    padding: 0.7em 1.3em 0.7em 1.3em;
    font-size: 1.3em;
    font-weight: 500;
}

.bars-tab-panel .ant-tabs-tab-btn, .bar-sub-competency-view .ant-tabs-tab-btn{
    font-size: 1.3em;
    font-weight: 500;
    padding: 0.7em 1.3em 0.7em 1.3em;
    text-align: center;
    width: 100%;
}

.bars-tab-panel .ant-tabs-tab-btn, .bar-sub-competency-view .ant-tabs-tab-btn:hover{
    color: #000;
}

.bars-tab-panel .ant-tabs-ink-bar, .bar-sub-competency-view .ant-tabs-ink-bar  {
    display: none;
}

.bars-tab-panel .ant-tabs-tab+.ant-tabs-tab, .bar-sub-competency-view .ant-tabs-tab+.ant-tabs-tab{
    margin: 0px;
}

.bars-tab-panel .ant-tabs-tab, .bar-sub-competency-view .ant-tabs-tab {
    min-width: 10em;
}

.bars-tab-panel .ant-tabs-tab,  .bar-sub-competency-view .ant-tabs-tab {
    padding: 0em;
}

.bars-rating-scale-text {
    width: 100%;
    height: 8.5em !important;
    border-radius: 0.5em;
    padding: 1.5em;
    border: 1px solid #AABAC6;
}

.bars-rating-scale-text:focus {
    border: 1px solid #AABAC6;

}

.bars-rating-scale-text:focus-visible {
    outline: none;
}

.bars-tab-panel .ant-tabs-nav-wrap, .bar-sub-competency-view .ant-tabs-nav-wrap {
    border: 0.0625em solid #AABAC6;
    border-radius: 0.5em;
    background-color: #fff;
}

.create-bar-sub-heading {
    font-size: 1.2em;
    font-weight: 600;

}

.create-bar-left-arrow-completed{
    cursor: pointer !important;
}

.create-bar-left-arrow-completed, .create-bars-arrow-completed{
    filter: invert(54%) sepia(94%) saturate(421%) hue-rotate(59deg) brightness(100%) contrast(93%);
}


.bars-button-panel-container .ant-btn[disabled], 
.bars-button-panel-container .ant-btn[disabled]:hover, 
.bars-button-panel-container .ant-btn[disabled]:focus, 
.bars-button-panel-container .ant-btn[disabled]:active{
    font-size: 1.2em;
    font-family: poppins;
    border-radius: 0.5em;
    height: 3.3em;
    background-color:#F4F9FD;
    padding: 0.7em 1.3em;
    color: #8C8CA1 ;
    border: 0.0625em solid #8C8CA1;
    background: #F4F9FD;
}


.bars-next-level {
    font-size: 1.2em;
    font-family: poppins;
    border-radius: 0.5em;
    height: 3.3em;
    background-color:#F4F9FD;
    padding: 0.7em 1.3em;
    color: #8C8CA1 ;
    border: 0.0625em solid #8C8CA1;
    background: #F4F9FD;
}

.bars-next-level:hover,
.bars-next-level:focus {
    color: #8C8CA1;
    border-radius: 12px;
    border: 0.0625em solid #8C8CA1;
    background-color: #F4F9FD;
}


.bars-next-level-completed{
    background-color: #F4F9FD !important;
      border-color: #4CCB1F !important;
      color: #4CCB1F !important;
    }
    


.create-bars-arrow {
    width: 1em;
}

.create-bar-left-arrow {
    width: 4.6em;
    cursor: not-allowed;
}

.create-bar-margin-seperator {
    margin-bottom: 1em;
    margin-top: 0.6em;
}

.bars-button-panel-container {
    margin-top: 2em;
    margin-bottom: 1.5em;
}

.create-bar-back-arrow {
    position: relative;
    left: 1em;
    top: 4em;
}

.create-bar-competency-heading {
    color:  #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25em;
}

.bar-sub-competency-view .ant-tabs-nav-wrap{
    padding: 0.7em;
}

.bars-tab-panel .ant-tabs-nav-wrap{
    padding: 0em;
}


.complete-level-bars{
    color: #000 !important;
}


.incomplete-level-bars{
    font-family: poppins;
    color: #8C8CA1 !important;
}


.bars-rating-scale-text:hover{
border: 1px solid #AABAC6;
}


.define-bars-selector .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #AABAC6 !important;
    box-shadow: none !important;
}

.define-bars-selector .ant-select-selector{
    border-color: #AABAC6 !important;
}

.define-bars-selector .ant-select-selector:hover{
    border-color: #AABAC6 !important;
}


.define-bars-selector .ant-select-selector:focus{
    box-shadow: none !important
}

.define-bars-selector .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #c7d943;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
}