.profile-notification-root {
    font-size: 16px;
    font-family: 'Poppins' !important;
}

.proile-notification-title {
    font-size: 1.25em;
    font-family: 'Poppins' !important;
    color: #000;
    font-Weight: 600;
    line-height: normal;
    margin: 0;
}

.notifcation-list-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    align-items: start;
    gap: 2em;
    margin-top: 1em;
}

.notifcation-list-left-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-top: 0em;
}

.notifcation-list-right-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    grid-gap: 0em !important
}

.notification-type-container {
    border-top: 1px solid #CBD5E1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em !important;
    align-items: center;
    padding: 1em 0em 1em 2em;
    grid-gap: 0em !important
}

.notigcation-label {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: .85em;
    line-height: normal;
    margin: 0;
    color: #000;
}

.notification-action-active-button,
.notification-action-active-button:hover,
.notification-action-active-button:focus {
    background: #4CCB1F;
    color: #FFFFFF;
    border: none;
    border-radius: .5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.notification-action-cancel-button,
.notification-action-cancel-button:hover,
.notification-action-cancel-button:focus {
    background: #CBD5E1;
    color: #FFFFFF;
    border: none;
    border-radius: .5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.notification-setting-custom-scroll {
    height: calc(100vh - 380px);
    max-height: fit-content;
    padding-top: 16px;
    overflow: auto;
}

.notification-setting-custom-scroll::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.notification-setting-custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.notification-setting-custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}
