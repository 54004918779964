.rd-table-scroll{
    overflow: auto;
}

.rd-custom-table{
    width: 100%;
    min-width: 350px;
}

.rd-table-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.5em;
    background: #31D0AA;
}

.rd-th-full{
    width: 100%;
    overflow: hidden;
    min-width: 10em;
}

.rd-th-action{
    width: 10em;
    min-width: 10em;
    max-width: 10em;
    display: flex;
    justify-content: center;
}

.rd-th-label{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.35em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.35em;
    margin: 0px
}


.rd-table-body{
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    padding-top: 1.7em;
}

.rd-table-content{
    border-radius: 0.5em;
    background: #FCFCFC;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.el-expansion-container{
    position: absolute;
    height: 100%;
    top: 0px;
    left: 1.6em;
    display: flex;
    align-items: center;
}

.rd-expansion-arrow{
    cursor: pointer;
    transition-duration: 0.5s;
}

.rd-open-expansion{
    transition-duration: 0.5s;
    transform: rotate(90deg);
}

.rd-td-label{
    color:  #1F1F1F;
    font-size: 1.15em !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35em;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.rd-expanded-detail{
    height: 0px;
    padding: 0;
    border-radius: 0.75em;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: hidden;
    transition-duration: 0.5s;
}

.rd-open-expansion-detail{
    height: 14.5em;
    padding: 1.6em;
    transition-duration: 0.5s;
}

.rd-expanded-label{
    color: #000;
    font-size: 1em !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: 'Poppins';
}
.rd-expanded-label-desc{
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75em;
    cursor: default;
  }
.rd-modal-max-width{
    max-width: 58rem;
}