.add-okr-table .ant-form-item-control-input-content {
    padding-left: 0px;
}


.okr-side-tool button {
    width: 47px !important;
    height: 47px !important;
    top: 210px !important;
}

.okrType-filter .selected-filter {
    color: rgb(48, 48, 48);
}

.okr-side-tool .ant-btn {
    top: 210px !important;
}


.okr-side-tool .ant-btn:hover {
    background: rgb(176, 203, 31) !important;
}

.okr-side-tool .ant-btn::before {
    background: rgb(176, 203, 31) !important;
}

.main-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: 52px;
    color: #141414;
    margin: 0px;
    text-align: center;
}

.okr-dashboard-tab .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
}

@media screen and (min-width:800px) and (max-width:950px) {
    .main-page-title {
        font-size: 28px;
    }
}

@media screen and (min-width:600px) and (max-width:800px) {

    .main-page-title {
        font-size: 28px;
    }
}

@media screen and (max-width:600px) {

    .main-page-title {
        font-size: 18px;
        line-height: 20px;
        color: #141414;
        margin: 0px !important;
    }
}



.okr-home-wrapper .title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 0px; */
}



@media (max-width:600px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}


@media screen and (min-width:600px) and (max-width:960px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}

@media screen and (min-width:960px) and (max-width:1200px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}


@media screen and (min-width:1200px) and (max-width:1800px) {

    .okr-back-button .back-btn {
        width: 35px;
        height: 35px;
        margin-bottom: 0px;
    }

}

@media screen and (min-height:601px) {


    .okr-side-tool button {
        width: 47px !important;
        height: 47px !important;
        top: 240px !important;
    }
}

.okr-home-arrow {
    margin-left: 0.32em;
}

@media screen and (min-width: 569px) and (max-width: 568px) and (orientation: landscape) {
    .okr-home-arrow {
        margin-left: 0.25em;
    }
}

.okr-main-menu {
    background: #FFFFFF;
    box-shadow: 1px 3px 4.900000095367432px 4px #AABAC64D;
    border-radius: 0.625em;
    padding: 0.5em 0em;
    margin-top: 0.25em;
    width: 150px;
}

.okr-menu-label {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0.75em 0.5em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
}

.okr-row-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.custom-okr-picker .ant-btn {
    border-radius: 12px !important;
}

.okr-detail-status-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
}

.okr-detail-status-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin: 5px;
}

.okr-detail-status-section h4 {
    margin: 0px;
    text-align: center;
}

.okr-status-progress-section {
    width: 300px;
    max-width: 100%;
}

.okr-detail-main-layout {
    width: 100%;
    padding: 0px 40px;
}

.okr-detail-status-sub-container {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
}

.okr-detail-titile-section {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 2.5em;
}

.okr-detail-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: normal;
    text-align: center;
    color: #000000;
    display: -webkit-box;
    margin: 0px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.okr-detail-input-box {
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width: 100% !important;
    font-family: 'Poppins';
    border-color: #a4a4a4 !important;
}

.okr-detail-competency-container{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 1em;
    flex-direction: row;
}

.okr-competency-card{
    width: 200px;
    padding: 24px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 42px 1px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1F1F1F;
    margin-bottom:15px ;
    max-width: 100% !important;
  }

@media screen and (max-width:1100px) {
    .okr-detail-status-container {
        flex-direction: column;
    }

    .okr-detail-status-sub-container {
        width: 100%;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
    }

    .okr-detail-titile-section {
        font-size: 14px;
    }
}

@media screen and (max-width:960px) {

    .okr-detail-titile-section {
        font-size: 12px;
    }
    .okr-competency-card{
        padding: 20px 8px;
    }
}

@media screen and (max-width:700px) {
    .okr-detail-competency-container{
        flex-direction: column;
    }
}

@media screen and (max-width:600px) {
    .okr-detail-main-layout {
        padding: 0px 0px;
    }

    .okr-detail-titile-section {
        font-size: 10px;
    }
}

@media screen and (max-width:480px) {
    .okr-competency-card{
        width: 100%;
    }
}
