.soul-report-root {
    font-size: 16px;
}

.soul-report-container-view {
    width: 100%;
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: .25em;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    align-items: center;
}

.soul-report-container-view h4 {
    text-align: center;
    font-size: 1.25em;
    font-weight: 500;
}

.soul-report-answer-main-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.soul-report-answer {
    width: 100%;
    max-width: 19%;
    min-width: 10em;
    height: 8em;
    margin: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
}
.soul-team-report-answer {
    width: 17% !important;
    min-width: 10em; 
    height: 4em;
    text-overflow: ellipsis;
    display: flex;
    justify-content: start;
    align-items: center !important;
    flex-direction: row;
    gap:1em;
    padding: 1em 1em;
}
.soul-answer {
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.soul-team-answer{
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.15em;
    font-family: Poppins !important;
    font-weight: 400;
}

.value-gradient {
    border: 1px solid rgba(255, 245, 210, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 245, 210, 0.5) 100%);
}

.strength-gradient {
    border: 1px solid rgba(255, 228, 229, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 228, 229, 0.5) 100%);
}

.personality-gradient {
    border: 1px solid rgba(208, 255, 248, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(224, 255, 250, 0.5) 100%);
}

.knowledge-gradient {
    border: 1px solid rgba(255, 245, 210, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(223, 234, 255, 0.5) 100%);
}

.soul-report-tab-switcher {
    display: flex;
    border: 1px solid #C9D0DA;
    border-radius: .5em;
    overflow: hidden;
    width: fit-content;
    padding: .75em 1em;
    gap: 1em;
    background-color: #FFFFFF;
  }
  .soul-report-switch-button,.soul-report-switch-button:hover,.soul-report-switch-button:focus {
    border: none;
    padding: .5em 1em;
    cursor: pointer;
    font-size: 1.25em !important;
    border-radius: 0;
    font-family: Poppins !important;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: none !important;
    height: 3em;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
  }
  
  .selected-soul-active-tab,.selected-soul-active-tab:hover,.selected-soul-active-tab:focus {
    background: #4CCB1F;
    box-shadow: 11px 9px 10.9px -1px #4CCB1F4D;
    color: white;
    border-radius: .5em;
    height: 3em;
    font-size: 1.25em !important;
    font-family: Poppins !important;
    font-weight: 500;
    border: none;
    outline: none;
  }
.soul-report-card-title{
    color: #000;
    font-family: Poppins !important;
    font-size: 1.5em;
    font-weight: 600;
    line-height: normal;
}
.soul-report-container-card-container {
    width: 100%;
    padding: 1em 1em;
    display: flex;
    flex-direction: column;
    gap: .25em;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    align-items: center;
}
.comparison-menu-container .ant-select-selector,.comparison-menu-container .ant-select-selector:hover,.comparison-menu-container .ant-select-selector:focus,.comparison-menu-container .ant-select-selector:focus-visible,.comparison-menu-container .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid rgba(170, 186, 198, 1) !important;
    font-weight: 400;
    font-size: 1em;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-bottom: 0.375em !important;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-item{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-top: 0.375em !important;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #868686 !important;
    font-weight: 400 !important;
    font-size: .85em;
    font-family:'Poppins';
}
.soul-report-active-btn,.soul-report-active-btn:hover,.soul-report-active-btn:focus{
    background: rgba(76, 203, 31, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1.25em;
    font-weight: 600;
    border-radius: .5em;
    line-height: normal;
    font-family: Poppins;
    padding: .5em 1em;
    outline: none;
    border: none;
    height: 2.5em;
}
.comparison-card-title{
    color: rgba(28, 28, 28, 1);
    font-weight: 600;
    font-size: 1.5em;
    font-family: Poppins;
    line-height: normal;
    margin: 0px;
}
@media screen and (max-width:1160px) {
    .soul-report-answer-main-section {
        flex-wrap: wrap;
    }
}
@media screen and (max-width:960px) {
    .soul-report-root {
        font-size: 14px;
    }
}

@media screen and (max-width:600px) {
    .soul-report-answer {
        width: 100%;
        min-width: none;
    }
    .soul-report-root {
        font-size: 12px;
    }
}