
.team-idp-main-head{
    font-family: "Poppins";
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    margin: 0;
    font-size: 1.5em;
    color:rgba(0, 0, 0, 1);
}
.idp-tab-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.idp-tab-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.idp-tab-active{
    border-bottom: 5px solid #4CCB1F;
}

.idp-tab-active-label{
    color: #4CCB1F !important;
}

.idp-tab-label{
    color: #000000;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}