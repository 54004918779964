.casper-fixed-container {
  position: fixed;
  bottom: 20px;
  z-index: 1500;
  left: 20px;
  right: 20px;
}

.casper-relative-container {
  width: 100%;
  position: relative;
}

.casper-icon {
  width: 100px;
  height: 100px;
  cursor: grab !important;
  position: absolute;
  bottom: 0px;
}

.casper-hide-mode {
  width: 450px;
  max-width: 90dvw;
  max-height: 500px;
  height: 0px;
  overflow: hidden;
  background-color: #fff;
  transition-duration: 0.5s;
  border-radius: 12px;
  position: absolute;
  bottom: 90px;
}

.casper-view-mode {
  width: 450px;
  max-width: 90dvw;
  max-height: 500px;
  height: calc(80dvh - 110px);
  transition-duration: 0.5s;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
  position: absolute;
  bottom: 90px;
}

.casper-view-container {
  height: 100%;
  width: 100%;
  padding: 0.5em 1em;
}

.casper-header-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}

.casper-header-title {
  margin: 0px;
  font-family: Poppins;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.75em;
  text-align: left;
  color: rgba(76, 203, 31, 1);
}

.casper-list {
  width: 100%;
  height: calc(100% - 120px); /*need to change 165px*/
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding-right: 0.5em;
  padding-bottom: 4px;
}

.empty-casper-body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: 700;
}

.casper-list.dynamic-width {
  height: calc(100% - 165px) !important; /*need to change 165px*/
}

.casper-list::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.border-sepeartion {
  height: 16px;
  width: 1px;
  border: 1px solid rgba(170, 186, 198, 1);
}

.casper-card {
  background: rgba(246, 251, 255, 1);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  padding: 1.5em;
}

.casper-card h3 {
  font-family: Poppins;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
}

.casper-card h4 {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
}

.bottom-center {
  left: 50%;
  transform: translateX(-50%);
}

.bottom-left {
  left: 0px;
}

.bottom-right {
  right: 0px;
}

.casper-list-root {
  font-size: 16px;
  width: 100%;
  height: calc(100vh - 200px);
  background-color: #fff;
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
}

.casper-list-root::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-list-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-list-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.list-ai-card-container {
  padding: 1em;
  width: 33%;
}

.list-casper-card {
  background: rgba(246, 251, 255, 1);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* justify-content: space-between; */
  padding: 1em;
}

.list-casper-card h3 {
  font-family: Poppins;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
  margin: 0px;
}

.list-casper-card h4 {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.casper-module-select,
.casper-module-select:hover,
.casper-module-select:focus {
  border: 1px solid rgba(170, 186, 198, 1);
  border-radius: 8px;
  overflow: hidden;
}

.casper-bookmark-btn-filter,
.casper-bookmark-btn-filter:hover,
.casper-bookmark-btn-filter:focus {
  border: 1px solid rgba(170, 186, 198, 1);
  color: rgba(137, 152, 162, 1);
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.casper-bookmark-active {
  border: 1px solid #4ccb1f !important;
  color: #fff !important;
  background: #4ccb1f !important;
}

.casper-date-picker {
  border: 1px solid rgba(170, 186, 198, 1);
  color: rgba(137, 152, 162, 1);
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.show-preview-casper {
  position: fixed;
  right: 20px;
  width: 450px;
  background-color: #fff;
  box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
  z-index: 2000;
  top: 100px;
  padding: 16px;
  transition-duration: 0.5s;
  border-radius: 12px;
}

.hide-preview-casper {
  position: fixed;
  right: 10px;
  width: 0px;
  top: 100px;
  overflow: hidden;
  transition-duration: 0.5s;
  background-color: #fff;
  z-index: 2000;
}

.casper-preview-content {
  width: 100%;
  max-height: 180px;
  overflow: auto;
  padding-right: 0.5em;
}

.casper-preview-content::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-preview-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-preview-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.casper-loader-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.casper-footer-section {
  display: flex;
  align-items: center;
  height: 60px; /* need to change: 105px*/
}

.casper-footer-section.dynamic-width {
  height: 105px !important;
}

.casper-footer-section .capser-message-input {
  height: 40px;
  border-radius: 100px;
  background-color: #eaedff;
  padding: 0px 15px;
  color: #a93ad5;
}

.casper-footer-section .capser-message-input-with-reply {
  height: 40px;
  border-radius: 25px;
  background-color: #eaedff;
  padding: 0px 15px;
  color: #a93ad5;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: unset;
}

.casper-footer-section .input-message-container {
  margin: 0px 10px;
  background-color: unset;
  width: 100%;
}

.input-message-container.dynamic-width {
  max-width: calc(100% - 100px);
}

.casper-footer-section .reply-message-container {
  height: 50px;
  background-color: #eaedff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid #d9d9d9;
  padding: 0px 15px;
  border-bottom: unset;
  display: flex;
  align-items: end;
}

.reply-message-container .reply-message {
  padding: 10px 20px;
  background-color: #cf94e9;
  border-radius: 100px;
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.reply-message-container .reply-message.dynamic-color {
  background-color: #b6a0f3;
}

.user-msg-container {
  width: 100%;
  display: flex;
  justify-content: end;
}

.reply-msg-container {
  width: 100%;
  display: flex;
  justify-content: start;
}

.message-content {
  max-width: calc(80% - 6px);
  padding: 10px 20px;
  border-radius: 1em;
  margin-bottom: 10px;
}

.user-msg-container .error-msg{
  font-size: smaller;
  line-height: 0px;
  position: relative;
  top: 20px;
  color: red;
  right: -20px;
  display: flex;
  justify-content: end;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.user-msg-container .message-content {
  background-color: #dfe6ff;
  margin-right: 10px;
}

.reply-msg-container .message-content {
  background-color: #cf93e9;
  margin-left: 10px;
}

.message-content .reply-button {
  width: 100%;
  display: flex;
  justify-content: end;
  height: 0px;
  position: relative;
  right: -32px;
  bottom: -3px;
}

.message-content.generating-response {
  margin: 0px 0px 0px 10px;
  background-color: unset ;
  padding: unset;
}

.message-content .reply-button svg {
  margin: 10px;
  cursor: pointer;
}

.user-msg-container .reply-to-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #cf94e9;
  padding: 10px 20px;
  border-radius: 0.8em;
  margin-bottom: 10px;
  width: 100%;
}

.user-msg-container .reply-to-message.dynamic-color {
  background-color: #b6a0f3;
}

.casper-profile {
  display: flex;
  width: 34px !important;
  height: 34px !important;
  font-weight: 700;
  font-size: 13px;
  color: #0031b1;
  overflow: hidden;
  height: fit-content;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  text-transform: uppercase;
  background-color: #eaedff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px -5px;
}

.casper-setting-overlay {
  top: var(--top-pos) !important;
  width: var(--width-px);
  left: unset !important;
  position: fixed !important;
  right: 45px;
  max-width: 80dvw;
}

.casper-section-container {
  background-color: #dfe6ff;
}

.casper-action-container {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: 500;
}

.casper-action-switch.ant-switch-checked {
  background-color: #f6fbff !important;
  border: 1px solid #783de2;
}

.casper-action-switch .ant-switch-handle {
  width: 16px;
  height: 16px;
}

.casper-action-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #783de2 !important;
}

.casper-action-switch.ant-switch {
  background-color: #f6fbff !important;
  border: 1px solid #783de2;
}

.casper-action-switch.ant-switch .ant-switch-handle::before {
  background-color: #783de2 !important;
}

.casper-action-switch.ant-switch:hover {
  border-color: #783de2 !important;
}

@media (max-width: 1200px) {
  .list-ai-card-container {
    padding: 1em;
    width: 50%;
  }
}

@media (max-width: 760px) {
  .list-ai-card-container {
    padding: 1em;
    width: 100%;
  }

  .bookmark-container {
    flex-wrap: wrap;
  }
}
