.active-icon{
    display: block;
}
.icactive-icon{
    display: none;
}

.survey-comp-root{
    font-size: 16px;
}

.comp-header-nav-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2em;
}

.comp-tab-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
}

.comp-tab-icon{
    width: 1.8em;
    height: 1.8em;
    margin: 0 .5em;
    cursor: pointer;
}

.comp-search-box{
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-radius: .5em;
    /* padding: .25em; */
    width: 50%;
    height: 2.5em;
    font-size: 1em
    /* border-right: none; */
}

.comp-request-note{
    width: 100%; 
    height: 15em;
    resize: none;
    border: 1px solid #C2C2C2;
    border-radius: .5em;
    outline: none !important;
    padding: .25em .8em;
    font-size: 1em;
    line-height: 1.5715;
    margin-top: 1em;
}

.comp-sent-request-btn{
    padding: 0.75em 1.5em;
    gap: 0.625em;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin:1em;
}

.comp-sent-request-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.comp-sent-request-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.request-scroll{
    margin-top: 1em;
    height: 50vh;
    overflow: auto;
    padding: 1em 1em 1em 0px;
}

.request-data-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.request-main-padding{
    padding: 1em;
    width: 50%;
    padding-left: 0px !important;
}

.request-user-card{
    display: flex;
    flex-flow: row nowrap;
    gap: .5em;
    justify-content: space-between;
    align-items: center;
    border-radius: .5em;
    padding: .5em;
    box-sizing: border-box;
    border: 1px solid #4CCB1F;
    cursor: default;
    width: 100%;
}

.profile-info-contianer{
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: .5em;
}

.req-user-image-div{
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
}

.req-user-char-div{
    border: 1px solid #4CCB1F;
    display: flex;
    padding: 0px !important;
    align-items: center;
    justify-content: center;
}

.req-user-char-div h4{
    margin: 0 !important;
    line-height: 0 !important;
}
.req-user-image-div .profile-image{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.um-request-tab-info{
    width: 100%; 
    display: flex;
    flex-direction: column;
}

.request-user-name{ 
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 1em;
}

.request-user-email{
    font-size: 0.75em;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.comp-request-btn{
    padding: 0.75em;
    gap: 0.625em;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 400;
    font-size: .8em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.comp-request-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.comp-request-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.request-bin{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
}

.request-card{
    display: flex;
    flex-flow: column ;
    gap: .5em;
    border-radius: .5em;
    padding: .5em;
    box-sizing: border-box;
    border: 1px solid #4CCB1F;
    cursor: default;
    width: 100%;
}

.request-card-section{
    display: flex;
    flex-flow: row nowrap ;
    justify-content: space-between;
    align-items: center;
}
.request-accepted,.request-pending{
    margin-left: .5em;
}
.request-accepted img,.request-pending img{
    height: 1em;
    width: 1em;
}

.request-accepted span{
 color: #4CCB1F;
}

.request-pending span{
 color: #22A6FC;
}

.req-msg-icon{
    width: 2em;
    height: 2em;
    cursor: pointer;
}

.req_pagination_view{
    cursor: pointer;
}
.req_pagination_view:hover{
    text-decoration: underline;
    color: #4CCB1F;
}

@media only screen and (max-width: 1200px) {
    .survey-comp-root{
        font-size: 14px;
    }
}


@media only screen and (max-width: 600px) {
    .survey-comp-root{
        font-size: 12px;
    }
    .request-main-padding{
        width: 100%;
    }
}