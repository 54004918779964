.emp-selector .ant-select-selector {
    height: 35px !important;
}

.emp-selector .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 31px !important;
    padding-left: 7px !important;
}

.emp-selector .ant-select-selection-search-input {
    font-size: 0.7em;
}


.emp-selector .ant-btn-icon-only.ant-btn-sm {
    width: 35px;
    height: 34px;
}



.emp-selector .ant-select-selection-item div {
    margin-top: 5px;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}