.rd-job-family-sub-info-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 2em;
    position: relative;
}

.rd-job-family-sub-info-heading-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.rd-job-family-sub-info-title {
    color: #414141;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.675em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    min-width: 6.25em;
    width: fit-content;
    background: rgba(255, 255, 255, 0.37);
    box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.10);
    padding: .7em 1em;
    border-radius: 1em;
    height: 3em;
    width: fit-content;
}

.rd-job-family-sub-info-label {
    color: #000;
    text-align: left;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}


.jf-modal-close-icon {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
}

.jf-no-data-fund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4em;
}

.rd-job-family-sub-info-label-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    max-height: 60%;
    overflow: auto;

}




.jf-margin-seperator {
    margin-top: 1em;
    margin-bottom: 1em;
}

.jf-row-header-seperator {
    margin-bottom: 0.8em;
}



.jf-body {
    padding: 1.5em 3em 2.5em 3em;
    background: #F4F9FD;
    border-radius: 1em;
}

.jf-title {

    text-align: center;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 2.87em;

}


.jf-icons {
    width: 1.4em
}

.jf-icons:first-child {
    margin-right: 0.5em;
}

.jf-text-content-body {
    text-align: center;
    line-height: 1.37em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.jf-attribute-values {
    padding: 1.25em 1.35em 1.25em 1.35em;
    font-weight: 500;
    border-radius: 1em;
    background: #FFF;
    font-size: 1em;
}

.jf-text-body {
    color: #000;
}

.jf-text-header {
    color: #262626;
}


.jf-side-info {
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 500;

}

.jf-side-info:first-child {
    margin-right: 1em;
}

.jf-row-header {
    text-align: left;
    font-size: 1.16em;
    font-style: normal;
    font-weight: 600;

}

.jf-spinner {
    top: 10em;
}


.jf-desc-header {
    color: #000;
    text-align: left;
    font-family: Poppins;
    font-size: 0.95em;
    font-style: normal;
    font-weight: 600;
    line-height: 0.97em;
}


.jf-description {
    color: #000;
    font-size: 0.6em;
    font-style: normal;
    font-weight: 500;
    line-height: 0.97em;

}

.jf-margin-seperator .ant-col-8 {
    display: block;
    flex: 0 0 31.33333333% !important;
    max-width: 31.33% !important;
}

.jf-margin-seperator-eligibilities .ant-col-8 {
    display: block;
    flex: 0 0 48.33333333% !important;
    max-width: 48.33% !important;
}

.jf-margin-seperator-eligibilities .jf-text-content-body {
    text-align: left;
    white-space: normal;
}

.jf-attr-row {
    column-gap: 1em !important;
    row-gap: 1em !important;
}

.jf-attr-section-margin-seperator {
    margin-bottom: 1.5em;
}

.eligibility-desc {
    margin-bottom: -0.62em;
}



.rd-detail-pop-up-title div {
    color: #414141;
    text-align: center;
    margin-top: 2.75em;
    white-space: nowrap;
    z-index: 500;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 1.62em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.05em;
    padding: 0.55em;
    border-radius: 0.5em;

}

.rd-detail-pop-up-title {
    border-radius: 2.5em;
    width: 100%;
    margin: auto;
    box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
}

.rd-detail-popup-margin-spacer {
    margin-top: 3.125em;
    margin-bottom: 3.125em;
}

.rd-detail-popup-attbitute-title {
    color: #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.37em;
}

.rd-detail-root {
    max-height: 36em;
    overflow: auto;
    position: relative;
    padding: 0em 4.37em 4.125em 4.37em;
}

.rd-detail-desc {
    line-height: 2.1;
    font-size: 1.1em;
}

.rd-detail-desc {
    font-size: 1.1em;
}

.close-icon-rd-detail {
    width: 2em;
    z-index: 1500;
    margin-bottom: 1em;
    cursor: pointer;
    position: absolute;
    top: 1.85em;
    right: 1.55em;
}


.rd-details-modal .ant-modal-body {
    padding: 0px !important;
}


.header-info-rd-detail {
    flex-wrap: nowrap;
}


@media screen and (max-width:600px) {


    .header-info-rd-detail {
        flex-wrap: wrap;
    }

    .jf-title {
        line-height: 1.5em;
        text-align: left;
    }

    .header-rd-detail-actions {
        margin-bottom: 2em;
        margin-top: 1em;
    }

    .jf-margin-seperator .ant-col-8 {
        display: block;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width:380px) {


    .jf-body {
        padding: 1.5em 1.5em 2.5em 1.5em;
    }

}




.hide-btn, .hide-btn:hover, .hide-btn:focus, .hide-btn:active {
    border: none;
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px 0px 0px 8px !important;
}

.back-arrow-rd-detail{
    width: 20px;
    height: 20px;
    margin-left: 1em;
}