.page-back{
   width: 4.75em;
   height: 1.25em;
   display: flex;
   justify-content: end;
   align-items: center;
   padding: 1.5em 0px;
}

.page-back img{
    cursor: pointer;
}


.global-submit-btn, .global-submit-btn:hover, .global-submit-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #FFF;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: Poppins;
}

.global-cancel-btn, .global-cancel-btn:hover, .global-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #262626;
    color: #262626;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: Poppins;
}

.ant-form-item-has-error .ant-input{
    border-color: #ff4d4f !important;
}

.add-okr-table .ant-form-item-has-error .ant-input,
.add-okr-table .ant-form-item-has-error .ant-input:focus
{
    border-color: #c7d943 !important;
    box-shadow: none !important;
}

.custom-multiselectdropdown{
    width: 100%;
    height: 3em;
    padding: 0em 0em 0em 1em;
    position: relative;
    border-radius: 0.5em;
    border: 1px solid #AABAC6;
    background: #FFF;
}

.custom-multiselectdropdown-icon{
    position: absolute;
    right: 1.8em;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
}

.custom-multiselectdropdown-search{
    width: 100%;
    border-radius: 1em;
    background: #FFF;
    height: 2.8em;
    margin: 0px !important;
    padding: 0em 4em 0em 0em;
    outline: none;
    border: none;
}

.custom-multiselectdropdown-menu{
    width: 100%;
    /* padding: 1em; */
    background:#FFF;
    border: 1px solid #AABAC6;
    border-radius: 1em;
}

.custom-multiselectdropdown-choice, .custom-multiselectdropdown-choice:hover, .custom-multiselectdropdown-choice:focus{
    border-radius: 1em;
    background: #F4F9FD;
    height: 3em;
    padding: 0.5em 1em 0.5em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 1em 0em;
    cursor: pointer;
    flex-wrap: nowrap;
}

.multiselectdropdown-choice-label{
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.01em;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
}


.multiselectdropdown-choice-selection{
    border-radius: 0.75rem;
    background: #FFF;
    width: 2rem;
    height: 2rem;
    border: none;
    outline: none;
    color: #000;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    cursor: pointer;
}

.active-choice-selection{
    background: #4CCB1F;
    color: #FFF
}

.custom-multiselectdropdown-add{
    border-top: 1px solid #AABAC6 ;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5em;
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}

.custom-multiselectdropdown-scroll{
    max-height: 250px;
    overflow: auto;
}

.custom-multiselectdropdown-scroll::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
}
.custom-multiselectdropdown-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.custom-multiselectdropdown-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}

.custom-dropdown-with-chip{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    gap: 0.25em !important;
    width: 100% !important;
    padding: 0em 0em 0em 1em;
    position: relative;
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
}

.custom-dropdown-insider-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content !important;
    max-width: 100%;
    /* padding-top: 0.5em; */
}


.custom-dropdown-insider-ele{
    height: 2.25em;
    border-radius: 0.5rem;
    background: rgba(244, 249, 253, 0.80);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    margin: 0.5em;
    padding: 0.01em 0.5em;
    justify-content: space-between;
    align-items: center;
}

.custom-dropdown-insider-ele span{
    color: #262626;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    white-space: nowrap;
}

.custom-dropdown-insider-ele img{
    width: 0.75em;
    height: 0.75em;
    cursor: pointer;
}

.custom-multiselectdropdown-search-chip{
    /* width: 100%; */
    border-radius: 1em;
    background: #FFF;
    height: 2.8em;
    margin: 0px !important;
    padding: 0em 0.5em 0em 0.5em;
    outline: none;
    border: none;
    margin: 0.5em;
}

.row-justify-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.align-content-center{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (max-width:1000px) {
    .page-back{
        width: 100%;
        display: flex;
        justify-content: start;
    }
}

.main-router-container{
    background-color: #f6fbff;
    min-height: 100vh;
    padding: 105px 25px 20px 140px;
    transition-duration: 1s;
}

.collapsed-router{
    padding: 105px 25px 20px 360px;
    transition-duration: 1s;
}

.no-navbar-router{
    padding: 0px;
}

.page-title-wrapper{
 display: flex;
 flex-direction: row;
 gap: 1em;   
 margin-bottom: 1em;
}

.page-title-wrapper h4{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 2em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.page-title-wrapper img{
    width: 1.25em;
    cursor: pointer;
}

@media screen and (max-width:960px) {
    .main-router-container{
        padding: 105px 25px 20px 35px;
    }
    .collapsed-router{
        padding: 105px 25px 20px 35px;
    }
}