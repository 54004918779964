.rd3t-leaf-node{
  cursor: grab !important;
}
.aligin-center{
  display: flex;
  align-items: center !important;
}

.foreign-parent{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-node-border {
    border: 9px solid #25EA68 !important;
}

.foreign-rect{
    background-color: #fff;
    box-shadow: -1px 3px 23px -7px rgba(0, 0, 0, 0.17);
    border-radius: 13px;
    border: 9px solid #fff;
    height: 195px;
    width: 435px;
    max-width: 435px;
    cursor: pointer;
    /* position: relative; */
}

.foreign-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
}

.react-data-container{
  margin-left: -35px;
  padding-top:20px;
  height: 100%;
  width: 100%;
}

.foreign-circle-left{
    /* position: absolute !important; */
    margin-top: 55px !important;
    margin-left: -37px !important;
    /* z-index: 500; */
}

.node-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    margin: 0px !important;
    color: #FFFFFF;
    white-space: nowrap;
}
.node-goal-statement{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 31px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 380px;
    text-align: center;
}

.node-user-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 27px; */
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding: 0px;
}

.custom-node-btn{
    width: 45px;
    height: 35px;
    background: #FFFFFF;
    box-shadow: -1px 1px 8px -1px rgba(0, 0, 0, 0.09);
    border-radius: 2px;
    padding: 2px;
    /* margin: 10px 2px; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer !important;
    border: 1px solid transparent;
    position: initial !important;
}

.hover-key:hover{
  border: 1px solid #009AF1;
}

.hover-mile:hover{
  border: 1px solid #FAC53C;
}


.modal-heading{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin: 0px !important;
}

.close-icon{
  cursor: pointer;
  /* margin-right: 100px; */
}

.tree-modal .ant-modal-content {
    border-radius: 20px;
    width:100%;
    background-color: #F5F5F5;
}

.foreign-circle .ant-spin-dot-item {
    background-color: #FFF;
  }

  .null-link{
    stroke: black !important;
    stroke-width: 3px;
  }

  .objective-link{
    stroke: rgb(76, 203, 31) !important;
    stroke-width: 3px;
  }

  .key-res-link {
    stroke: #009AF1 !important;
    stroke-width: 3px;
  }

  .milestone-link{
    stroke: #FAC53C !important;
    stroke-width: 3px;
  }

  .progress-div{
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 55px;
  }

  .okr-title-div{
    padding: 8px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    overflow: hidden;
    width: 95%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mile-title-hover:hover{
    background: #d9a111 !important;
  }

  .key-title-hover:hover{
    background: #023fe6f0 !important;
  }

  .okr-title-div .title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    white-space: nowrap;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .container-div{
    width: 450px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: nowrap;
    margin: 15px 0px;
  }

  .container-div .progress{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #595959;
    margin: 0px;
  }

  .contributor-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
}

.contributor-circle .node-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.okr-list-container{
    max-height: 350px;
    overflow: auto;
}

.okr-list-container::-webkit-scrollbar {	
    height: 0px;	
    width: 8px;
    padding-left:10px;
  }	
  .okr-list-container::-webkit-scrollbar-track {	
    display:none;
  }	
  .okr-list-container::-webkit-scrollbar-thumb {	
    border-radius: 10px;	
    background-color:#F0F0F0;	
  } 

  .okr-child-modal{
    width: 565px !important;
    max-width: 95% !important;
  }

  .okr-child-modal .ant-modal-body{
    min-height: 437px !important;
  }

  

 

  .process-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .d3-modal-sub-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
  }

  .modal-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 27px !important;
    line-height: 33px;
    color: #262626;
  }

 

  .progress-parent .ant-progress-bg, .progress-parent .ant-progress-inner, .progress-parent .ant-progress-line{
    position: initial !important;
  }

  .level-label{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 31px;

    color: #000000;
  }

  .process-div{
    width: 85px;
  }


  .level-selector{
    width: 53px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
  }

  .level-selector .ant-select-selector{
    background-color: transparent !important;
    border-radius: inherit !important;
    border: none !important;
  }

  .level-info{
    width:16px;
    height: 16px;
    cursor: pointer;
  }

  .recenter-icon{
    width: 17px;
    height: 17px;
  }

  .arrows{
    width: 28px;
    height: 28px;
  }

  @media screen and  (min-width:1700px) {
    .close-icon{
      /* margin-right: 200px; */
    }

    .level-info{
      width:18px;
      height: 18px;
      cursor: pointer;
    }

    .process-div{
      width: 105px;
    }
    .level-selector{
      width: 65px;
    }
    .level-label{
      font-size: 20px;
    }
  
 }

 @media screen and (max-width:960px) {
  .modal-heading{
    font-size:30px ;
  }

  .close-icon{
    /* margin-right: 10px; */
  }

}

 @media screen and (max-width:600px) {
  .modal-heading{
    font-size:28px ;
  }

  .close-icon{
    /* margin-right: 5px; */
  }

}