@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap);
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
[class^=ant-],
[class*= ant-],
[class^=ant-] *,
[class*= ant-] *,
[class^=ant-] *::before,
[class*= ant-] *::before,
[class^=ant-] *::after,
[class*= ant-] *::after {
  box-sizing: border-box;
}
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #B0CB1F;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #c7d943;
}
a:active {
  color: #8aa611;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #B0CB1F;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #B0CB1F;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #B0CB1F;
  box-shadow: 0 0 0 0 #B0CB1F;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #B0CB1F;
    box-shadow: 0 0 0 6px #B0CB1F;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #B0CB1F;
    box-shadow: 0 0 0 6px #B0CB1F;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  -webkit-transform: none;
          transform: none;
}
.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #fffff0;
  border: 1px solid #eef299;
}
.ant-alert-info .ant-alert-icon {
  color: #B0CB1F;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #B0CB1F;
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #B0CB1F;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
  -webkit-transform: none;
          transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
          transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #B0CB1F;
  border-radius: 2px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  -webkit-transform: scaleY(0.75);
          transform: scaleY(0.75);
  -webkit-transform-origin: top;
          transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
  -webkit-animation-name: antZoomBadgeInRtl;
          animation-name: antZoomBadgeInRtl;
}
.ant-badge-rtl .ant-badge-zoom-leave {
  -webkit-animation-name: antZoomBadgeOutRtl;
          animation-name: antZoomBadgeOutRtl;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
          transform: none;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
@-webkit-keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #c7d943;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #c7d943;
  background: #fff;
  border-color: #c7d943;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #8aa611;
  background: #fff;
  border-color: #8aa611;
}
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  background: #B0CB1F;
  border-color: #B0CB1F;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #c7d943;
  border-color: #c7d943;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  background: #8aa611;
  border-color: #8aa611;
}
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #c7d943;
  border-left-color: #c7d943;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #c7d943;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #c7d943;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #c7d943;
  background: #fff;
  border-color: #c7d943;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #8aa611;
  background: #fff;
  border-color: #8aa611;
}
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #B0CB1F;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}
.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -1px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
          animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #B0CB1F;
  background: transparent;
  border-color: #B0CB1F;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #c7d943;
  background: transparent;
  border-color: #c7d943;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #8aa611;
  background: transparent;
  border-color: #8aa611;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #c7d943;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #c7d943;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #fffff0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #B0CB1F;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #B0CB1F;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-rtl .ant-card-grid {
  float: right;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-rtl .ant-card-actions > li {
  float: right;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #B0CB1F;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #B0CB1F;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-borderless .ant-cascader-input {
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 2px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fffff0;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}
.ant-cascader-picker-rtl {
  direction: rtl;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: auto;
  left: 8px;
}
.ant-cascader-menu-rtl .ant-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 2px 0 0 2px;
}
.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
  border-radius: 2px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #B0CB1F;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #B0CB1F;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #B0CB1F;
  border-color: #B0CB1F;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #B0CB1F;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: left;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
}
.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #B0CB1F;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 6.66666667px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #B0CB1F;
  background: #fffff0;
  border-color: #eef299;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}
.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: '';
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #B0CB1F;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #B0CB1F;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell-disabled {
  cursor: not-allowed;
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #B0CB1F;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fffff0;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #B0CB1F;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #fffff0;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #d4e868;
  border-bottom: 1px dashed #d4e868;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #e7f2aa;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #e7f2aa;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #d4e868;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #d4e868;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: #f5f5f5;
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #B0CB1F;
}
.ant-picker-today-btn:hover {
  color: #c7d943;
}
.ant-picker-today-btn:active {
  color: #8aa611;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #d4e868;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #d4e868;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #d4e868;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #d4e868;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #B0CB1F;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(255, 255, 240, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #fffff0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable-next-line */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #d4e868;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #d4e868;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #d4e868;
  border-left: 1px dashed #d4e868;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer > * {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: 0;
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - 0);
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #B0CB1F;
  background-color: #fffff0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled > .anticon + span > a,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  position: relative;
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-dropdown-menu-item-disabled > .anticon + span > a::after,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a::after,
.ant-dropdown-menu-item-disabled > a::after,
.ant-dropdown-menu-submenu-title-disabled > a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  content: '';
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #B0CB1F;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #B0CB1F;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: auto;
}
.ant-form-item .ant-upload {
  background: transparent;
}
.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}
.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%;
}
.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain.ant-form-item-explain-warning {
  color: #faad14;
}
.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}
.ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-prefix {
  color: #faad14;
}
.ant-form-item-has-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-form-item-has-warning .has-feedback {
  color: #faad14;
}
.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #fff;
  border-color: #faad14;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-form-item-has-error .has-feedback {
  color: #ff4d4f;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #fff;
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #ff4d4f !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff4d4f;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #B0CB1F;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
          writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item .ant-input-textarea-show-count::after {
  margin-bottom: -22px;
}
.ant-show-help-enter,
.ant-show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-show-help-enter.ant-show-help-enter-active,
.ant-show-help-appear.ant-show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-show-help-leave.ant-show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-show-help-enter,
.ant-show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  display: block;
  width: 100%;
  height: auto;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info .anticon {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.ant-input-clear-icon {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.ant-input-clear-icon:last-child {
  margin-right: 0;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::placeholder {
  color: #bfbfbf;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #B0CB1F;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #c7d943;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #c7d943;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #c7d943;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #c7d943;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
}
.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #c7d943;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #B0CB1F;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
}
.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 0px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
       tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #B0CB1F;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fffff0;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #B0CB1F;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #B0CB1F;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #B0CB1F;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #B0CB1F;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #B0CB1F;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fffff0;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2.5px);
          transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2.5px);
          transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #B0CB1F;
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2.5px);
          transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2.5px);
          transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2.5px);
          transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2.5px);
          transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #B0CB1F;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #B0CB1F;
  border-bottom: 2px solid #B0CB1F;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #B0CB1F;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #B0CB1F;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #B0CB1F;
  -webkit-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #B0CB1F;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #B0CB1F;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #B0CB1F;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateY(-2px);
          transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateY(2px);
          transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #B0CB1F;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #B0CB1F;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder,
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-hook-holder > .ant-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}
.ant-notification-notice {
  padding: 16px 24px;
  line-height: 1.5715;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #B0CB1F;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #B0CB1F;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #c7d943;
}
.ant-page-header-back-button:active {
  color: #8aa611;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #B0CB1F;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #B0CB1F;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #B0CB1F;
}
.ant-pagination-item-active a {
  color: #B0CB1F;
}
.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: #c7d943;
}
.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
  color: #c7d943;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #B0CB1F;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #c7d943;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #B0CB1F;
  border-color: #B0CB1F;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #B0CB1F;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
  line-height: unset;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #881FCC;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(136, 31, 204, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #881FCC;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #881FCC;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #881FCC;
}
.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #881FCC;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(136, 31, 204, 0.08);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #881FCC;
  background: #fff;
  border-color: #881FCC;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #881FCC;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #881FCC;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #c7d943;
  border-color: #c7d943;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #c7d943;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #8aa611;
  border-color: #8aa611;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #8aa611;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(136, 31, 204, 0.08);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #881FCC;
  border-color: #881FCC;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #c7d943;
  border-color: #c7d943;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #8aa611;
  border-color: #8aa611;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(136, 31, 204, 0.08);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #c7d943;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #B0CB1F;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #c7d943;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #c7d943;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fffff0;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #B0CB1F;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #fff;
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #fff;
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  width: 100%;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: #fff;
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #c9c9c9;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #eef299;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #eef299;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #c0d54c;
  box-shadow: 0 0 0 5px rgba(176, 203, 31, 0.12);
}
.ant-slider-handle:focus {
  border-color: #c0d54c;
  outline: none;
  box-shadow: 0 0 0 5px rgba(176, 203, 31, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #B0CB1F;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #dbe66c;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #dbe66c;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #d8e58f;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #B0CB1F;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #B0CB1F;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
          animation-name: antRotateRtl;
}
@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}
@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: left;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #B0CB1F;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #B0CB1F;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #B0CB1F;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #B0CB1F;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #B0CB1F;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #B0CB1F;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #B0CB1F;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #B0CB1F;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #B0CB1F;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #B0CB1F;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #B0CB1F;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #B0CB1F;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #B0CB1F;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #B0CB1F;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
          transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #B0CB1F;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
  padding-left: 4px;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(176, 203, 31, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #B0CB1F;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #B0CB1F;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fafafa;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fffff0;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #ffffe6;
}
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 8px;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #f7f7f7;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px 16px;
}
.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #B0CB1F;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px -16px;
}
.ant-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}
.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-filter-trigger.active {
  color: #B0CB1F;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table .ant-table-selection-col {
  width: 32px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
          margin-inline-start: 100%;
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #B0CB1F;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  -webkit-transform: scale(0.94117647);
          transform: scale(0.94117647);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: all 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #c7d943;
}
.ant-table-row-expand-icon:active {
  color: #8aa611;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
}
.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 0;
  margin-left: 2px;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #B0CB1F;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: flex;
  overflow: hidden;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transform: translate(0);
          transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #c7d943;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #8aa611;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #B0CB1F;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #8aa611;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #c7d943;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #B0CB1F;
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  display: flex;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-content-animated {
  transition: margin 0.3s;
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #B0CB1F;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #B0CB1F;
}
.ant-tag-checkable:active {
  background-color: #8aa611;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #B0CB1F;
  border-color: #B0CB1F;
}
.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
          transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
          transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
          transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
          transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  color: #B0CB1F;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #c7d943;
}
.ant-transfer-list-content-item-remove:active {
  color: #8aa611;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item-remove:hover {
  color: #c7d943;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #ffffe6;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #fffff0;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-checkbox .ant-checkbox {
  top: 0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #B0CB1F;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #B0CB1F;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #B0CB1F;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #B0CB1F;
  border-color: #B0CB1F;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #B0CB1F;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #fffff0;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #B0CB1F;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #feffc9;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #B0CB1F;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #B0CB1F;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #B0CB1F;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl.ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #B0CB1F;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #B0CB1F;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #B0CB1F;
  border-color: #B0CB1F;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #B0CB1F;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px 0;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 8px;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #fffff0;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #B0CB1F;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #feffc9;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #B0CB1F;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #B0CB1F;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #B0CB1F;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
}
a.ant-typography,
.ant-typography a {
  color: #B0CB1F;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #c7d943;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #8aa611;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #B0CB1F;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #c7d943;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #8aa611;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #B0CB1F;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #8aa611;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #c7d943;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #c7d943;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
h1 {
  font-weight: bold;
}
h2 {
  font-weight: bold;
}
.HVCenter {
  height: 85vh;
  width: 95vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-list {
  position: relative;
  display: block;
  margin: 0 20px !important;
  padding: 0;
  overflow: hidden;
}

.root-user-container {
  font-size: 16px;
  font-family: "Poppins";
}
.user-dashboard-container {
  display: flex;
  flex-direction: row;
  grid-gap: 2em;
  gap: 2em;
  width: 100%;
}
.user-dasboard-left-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  justify-content: start;
  width: 67%;
}
.user-dasboard-right-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  justify-content: start;
  width: 33%;
}
.user-dashboard-small-card-title {
  font-size: 1.2em;
  font-family: "Poppins";
  font-weight: 600;
  line-height: normal;
  font-style: normal;
}
.user-dashboard-small-card-para {
  font-size: 1em;
  font-family: "Poppins";
  font-weight: 500;
  line-height: normal;
  font-style: normal;
  margin: 0;
}
.user-board-welcome-banner-heading {
  font-size: 1.5em;
  color: #fff;
  font-family: "Poppins";
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.user-board-welcome-banner-para {
  font-size: 1.1em;
  font-family: "Poppins";
  color: #fff;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.user-board-welcome-banner-button,
.user-board-welcome-banner-button:hover,
.user-board-welcome-banner-button:focus {
  background-color: #4ccb1f;
  font-size: 1.25em;
  font-weight: 600;
  font-family: "Poppins";
  padding: 0.5em 1em;
  border-radius: 0.5em;
  border: none;
  color: #fff;
  text-align: center;
  height: 3em;
  white-space: nowrap;
  margin-top: 1em;
}
.user-dasboard-banner-for-survey-taken {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}
.user-board-welcome-banner-heading-taken {
  color: #4ccb1f;
  font-size: 1.25em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.user-board-welcome-greeting-taken {
  color: #000000;
  font-size: 2.2em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.baner-tip-title {
  color: #000000;
  font-size: 1em;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
}
.baner-tip-content {
  color: #000000;
  font-size: 1em;
  font-weight: 400;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
  padding-top: 0.5em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-dashboard-okr-performance-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  justify-content: start;
  width: 100%;
  padding: 1em;
}
.user-board-section-heading {
  color: #000000;
  font-size: 1.35em;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  margin: 0;
  text-align: left;
}
.dashboard-performance-table-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}
.dashboard-performance-table {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.dashboard-performance-table-data-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  border: 1px solid #d5e5f2;
  width: 100%;
}
.dasboard-performance-title-column {
  width: 45% !important;
}
.dasboard-performance-progress-column {
  width: 20% !important;
}
.dasboard-performance-status-column {
  width: 15% !important;
}
.dasboard-performance-date-column {
  width: 15% !important;
}
.dashboard-performance-status-btn {
  width: 8em;
  height: 2.5em;
  border-radius: 0.5em;
  font-size: 0.85em;
  font-weight: 700;
  font-family: "Manrope";
  text-align: center;
  padding: 0.5em;
}
.no-performance-container {
  border-radius: 0.5em;
  border: 1px solid #d5e5f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  height: 25em;
}
.dashboard-performance-overflow {
  overflow: auto;
  height: 22em;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.dashboard-engagement-overflow {
  overflow: auto;
  height: 30em;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.dashobard-performance-table-heading {
  color: #a3a3a3;
  font-size: 1em !important;
  font-family: "Manrope";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
}
.dashboard-engagement-container {
  border: 1px solid #d5e5f2;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1em;
  width: 100%;
}
.dashboard-engagement-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 2.5em;
  gap: 2.5em;
  flex-wrap: wrap;
}
.dashboard-engagement-sub-container .sub-title {
  color: #a3a3a3;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.dashboard-engagement-container .main-title {
  color: #303030;
  font-size: 1.2em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}
.dashboard-engagement-sub-container .content-title {
  color: #303030;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}
.user-dasboard-small-card-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
}
.small-kudos-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  height: auto;
}
.small-one-on-one-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  height: 28em;
}
.small-one-on-one-container-overflow {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  overflow: auto;
  height: 22em;
}
.small-team-members-container {
  box-shadow: 0px 10px 15px 0px #aabac633;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  width: 100%;
  max-height: 25em;
}
.one-on-one-see-detail-button {
  color: #0dd671;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
.user-dashboard-small-card-para-one-on-one {
  color: #aabac6;
  font-size: 0.85em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  margin: 0;
}
.dashboard-kudos-heading {
  color: #000000;
  font-size: 1em;
  font-family: "Poppins";
  line-height: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  margin: 0;
}
.dashboard-kudos-image-container {
  background-color: #f6fbff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
}
.dashboard-scape-card {
  display: flex;
  flex-direction: row;
  grid-gap: 1em;
  gap: 1em;
  justify-content: start !important;
  justify-content: center;
  background-color: #f9fcff;
  border-radius: 0.5em;
  padding: 0.75em 1em;
  width: 15em;
}
.team-assessment-data-cintainer {
  display: flex;
  align-items: center;
  justify-content: start;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  border: 1px solid #d5e5f2;
  border-radius: 0.5em;
}
.dashboard-member-search-section {
  width: 100%;
  background-color: rgba(170, 186, 198, 0.2);
  border-radius: 0.75em;
  height: 3.5em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 1em;
}
.dashboard-member-search-input {
  border: none !important;
  height: 2.5em;
  font-family: "Poppins";
  font-size: 1em;
  color: #000000;
  font-style: normal;
  background-color: rgba(170, 186, 198, 0.01);
}
.dashboard-member-section-member-container {
  border: 1px solid rgba(213, 229, 242, 1);
  border-radius: 0.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 0.5em 1em;
  width: 100%;
}
.dashboard-view-more-button:hover {
  color: #4ccb1f;
}
.dashboard-check-box {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #8c8ca1;
  cursor: pointer;
}
.dashboard-check-box:hover {
  border: 1px solid #4ccb1f;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots {
  margin-left: 0px !important;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots-bottom {
  bottom: 12px;
  height: 10px;
  margin-right: 20px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button {
  background: #d9d9d9;
  height: 10px;
  width: 10px;
  border-radius: 10px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active {
  width: 17px;
}

.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active button {
  background: #4ccb1f;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button:before {
  color: #ffff;
  visibility: hidden;
}
.dashboard-banner-section-container {
  width: 60%;
}
.dashboard-banner-image {
  max-width: 30%;
  max-height: 11em;
}
.soul-tip-dashboard .slick-list {
  margin: 0px 0px !important;
}
.text-overflow-elipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.survey-360-table-ttile{
  font-family: "Manrope";
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  font-style: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.survey-360-table-data-title{
  font-family: "Poppins";
  font-size: .9em;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.survey-360-table-pending-status{
  background: rgba(249, 95, 83, 0.2);
  color:rgba(249, 95, 83, 1);
  font-size: .95em;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
  line-height: normal;
  padding: .25em 1em;
  border-radius: .25em;
  width: 8em;
  text-align: center;
  margin: 0;
}
.survey-360-table-data-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  border-radius: .5em;
  border: 1px solid #D5E5F2;
  padding: .5em 1em;
}
@media (max-width: 1800px) {
  .root-user-container {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .root-user-container {
    font-size: 12px;
  }
}
@media (max-width: 960px) {
  .root-user-container {
    font-size: 10px;
  }
  .user-dashboard-container {
    flex-wrap: wrap;
  }
  .user-dasboard-left-container {
    width: 100%;
  }
  .user-dasboard-right-container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .root-user-container {
    font-size: 8px;
  }
  .user-dasboard-banner-for-survey-taken {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dashboard-banner-section-container {
    width: 100%;
  }
  .dashboard-banner-image {
    display: none;
  }
}

.soul-tip-preview-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preview-tip-popup{
  margin-top: 1em;
  font-size: 1.25em;
  text-align: center;
}

.soul-tip-available-effect:hover{
  background: #fbfffb;
  cursor: pointer;
}

/* InputField */

.generic-survey-input-filed{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    border-color:#ffffff !important ;
    font-weight: 400;
    font-size: 1rem;
}
.generic-survey-input-filed:hover{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.generic-survey-input-filed:focus{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed .ant-input-number-input{
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1rem;
    padding-left: 10px;
}
.generic-survey-input-filed .ant-input-number-handler-wrap{
    background: #ECF1F4 !important;
    border: none !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 30px;
    height: 2.8rem !important;
    padding-top: 10px;
}
.generic-survey-input-filed .ant-input-number-handler-down {
    border-top: none !important;
}
.grow-survey-input-filed,.grow-survey-input-filed:hover,.grow-survey-input-filed:focus,.grow-survey-input-filed:focus-visible,.grow-survey-input-filed:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    border-color:#AABAC6 !important ;
    font-weight: 400;
    font-size: 1rem;
}
.grow-survey-input-filed .ant-input-number-input{
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1rem;
    padding-left: 10px;
}
.grow-survey-input-filed .ant-input-number-handler-wrap{
    background: #ECF1F4 !important;
    border: none !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 30px;
    height: 2.8rem !important;
    padding-top: 10px;
}
.grow-survey-input-filed .ant-input-number-handler-down {
    border-top: none !important;
}

/* InputColor */

.sketch-trigger-button{
    background: #B0CB1F;
    width: 111px;
    height: 1.5rem;
    border-radius: 6px;
    border: none;
    box-shadow: none;
    margin-right: 10px !important;
}

.picked-color-text{
    font-family:'Poppins';
    font-weight: 400;
    color: #000;
    font-size: 1rem;
    padding-top: 3px;
}
.color-picker-container-max-width{
    max-width: 300px;
    width: 100%;
}

/* InputRange */

.generic-survey-range-slider .ant-slider-rail{
    height: 10px !important;
    background: #A9A7A7 !important;
    border-radius: 20px;
}
.generic-survey-range-slider .ant-slider-track{
    height: 10px !important;
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important;
    border-radius: 20px;
    /* transition:  0.2s !important; */
}
.generic-survey-range-slider .ant-slider-handle{
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important; 
    border: none;
    width: 20px;
    height: 20px;
    /* transition: 0.1s !important; */
}

.not-answered-slider .ant-slider-handle{
    background: #b3b1b1 !important; 
}

.generic-survey-range-slider .ant-slider-dot{
    display: none !important;
}
.generic-survey-range-slider .ant-slider-mark-text{
    margin-top: 5px !important;
    color: #000000;
    font-family:'Poppins';
    font-size: 1rem !important;
    font-weight: 500;
}

/* InputDatePicker */

.generic-survey-date-picker-custom{
    border: 1px solid #DDDDDD;
    border-radius: 8px !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1.12rem;
    width: 100%;
    background-color: #fff;
    color: #303030;
}
.generic-survey-date-picker-custom:hover{
    border-radius: 8px;
    border-color: #31D0AA;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.generic-survey-date-picker-custom:focus{
    border-radius: 8px;
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.generic-survey-date-picker-custom:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-date-picker-custom:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-date-picker-date {
    border-radius: 8px !important;
    border: 1px solid #31D0AA!important;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    margin-top: 30px !important;
}
.generic-survey-date-picker-date .ant-picker-panel-container{
    border-radius: 8px !important;
}
.generic-survey-date-picker-date .ant-picker-content{
    color: #333333 !important;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 14px !important;
}
/* .generic-survey-date-picker-date .ant-picker-footer{
    display: none !important;
} */

.generic-survey-date-picker-date .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #4CCB1F !important;
}
.generic-survey-date-picker-date .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background:#4CCB1F ;
}
.generic-survey-date-picker-date .ant-picker-today-btn{
    color: #4CCB1F !important;
}

.generic-survey-date-picker-date .ant-picker-content .ant-picker-week-panel-row-selected td {
    background:#4CCB1F !important;
}
/* InputTimePicker */

.survey-time-select-picker .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px !important;
    height: 40px !important;
    
    
}
.survey-time-select-picker .ant-select-selector:hover{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    
}
.survey-time-select-picker .ant-select-selector:focus{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-search-input{
    height: 40px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #d0d0d0 !important;
    padding-left: 0px !important;
    padding-top: 4.5px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 40px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-item{
    height: 40px !important;
    padding-top: 4.5px !important;
}
.survey-time-drop-item{
    font-family: 'Poppins';
    font-weight: 500;
}

/* InputRadio button */
.survey-radio-choice p{
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1rem !important;
    /* line-height: 1,25rem; */
    /* color: #0000; */
    margin: 2px 0px;
}
.survey-radio-choice {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1rem !important;
    /* line-height: 1,25rem; */
    /* color: #0000; */
    margin: 2px 0px;
}
.survey-radio-choice .ant-radio-checked .ant-radio-inner {
    border-color: #4CCB1F !important;
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner::after{
    background-color: #4CCB1F !important;
    top: 4px !important;
    left: 4px !important;
    width: 10px !important ;
    height: 10px !important;
}
.survey-radio-choice .ant-radio-input{
    width: 0px !important;
    height: 0px !important;
}
.survey-radio-choice .ant-radio-inner{
    border-width: 1px !important;
}
.survey-radio-choice .ant-radio-inner:hover{
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner:focus{
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner::before{
    border-color: #4CCB1F !important;
}


.survey-radio-choice .ant-radio:hover{
    border-color: #4CCB1F !important;
}
.survey-radio-choice .ant-radio:focus{
    border-color: #4CCB1F !important;
}
.survey-radio-choice .ant-radio::before{
    border-color: #4CCB1F !important;
}

/* InputCheckBox */

.survey-check-box-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.survey-check-box-content p{
    margin-bottom: -5px !important;
    font-family: 'poppins';
    font-weight: 500;
    /* font-size: 1rem !important;
    line-height: 1,25rem;
    color: #0000; */
}
.survey-check-box-content .ant-checkbox{
    padding: 3px !important;
    border: 1px solid #8C8CA1;
    border-radius: 4px !important;
    background-color: #F4F9FD !important;
}
.survey-check-box-content .ant-checkbox-checked{
    border: 1px solid #4CCB1F !important;
}
.survey-check-box-content .ant-checkbox:focus{
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.survey-check-box-content .ant-checkbox:hover {
    border: 1px solid #31D0AA!important;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.survey-check-box-content .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #4CCB1F !important;
}
.survey-check-box-content .ant-checkbox-inner{
    border: none !important;
}
.survey-check-box-content .ant-checkbox-checked::after{
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.survey-check-box-content .ant-checkbox-checked::after .ant-checkbox {
    border: none !important;
}


.generic-survey-select-filed .ant-select-selector{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #FFF ;
    font-weight: 400;
    font-size: 1rem;
}
.grow-surevey-select-filed .ant-select-selector,
.grow-surevey-select-filed .ant-select-selector:hover,
.grow-surevey-select-filed .ant-select-selector:focus,
.grow-surevey-select-filed .ant-select-selector:focus-visible,
.grow-surevey-select-filed .ant-select-selector:focus-within{
    border: 1px solid #AABAC6 !important;
    height: 2.5rem !important;
    background: #fff !important;
    border-radius: 8px !important;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 500;
    font-size: 1rem;
    border-color: #AABAC6;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 2.5rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.grow-surevey-select-row .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #AABAC6 !important;
}
.grow-surevey-select-row  .ant-select-dropdown{
    box-shadow: none !important;
}
.generic-survey-select-filed .ant-select-selector:hover{
    border-radius: 8px;
    border-color: #31D0AA;
    border: 1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.generic-survey-select-filed .ant-select-selector:focus{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA ;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA ;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 2.75rem !important;
}
.generic-survey-selected-row  .ant-select-dropdown{
    box-shadow: none !important;
}
.generic-survey-selected-row .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #ffffff !important;
}
.selected-survey.ant-select-item{
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    
}
.selected-survey.ant-select-item p{
    margin: 0px;
}
.selected-survey.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.selected-survey.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
    
}
.selected-survey.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 4px !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1rem;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 6px !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1rem;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 5px !important;
    font-weight: 500;
    font-size: 1rem;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 6px !important;
    font-weight: 500;
    font-size: 1rem;
}
.no-shadow-inputs:hover{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus-visible{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus-within{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-border .ant-select-selector{
    border: none !important;
}
.no-shadow-inputs .ant-select-selector:hover{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus-visible{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus-within{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.generic-survey-input-filed .ant-input-number-handler-wrap{
    display: none !important;
}

/* Survey Skin Stepper */
.generic-survey-skil-stepper{
    padding: 2rem 4rem;
}
.generic-survey-skil-stepper .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background: #B0CB1F !important;
    /* width: 18px !important;
        height: 18px !important; */

}
.generic-survey-skil-stepper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        background:#4CCB1F;
        /* width: 12px !important;
        height: 12px !important; */
}
.generic-survey-skil-stepper .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot{
    left: 0 !important;
}
.generic-survey-skil-stepper .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon > .ant-steps-icon{ 
    /* margin-top: 8px; */
    /* background: #fff;
    border: 1px solid #4CCB1F;
    width: 18px;
    height: 18px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%; */
    /* padding: 2px !important; */
    }
.generic-survey-skil-stepper .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot{
    /* margin: 4px !important; */
}
.generic-survey-skil-stepper .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after{
    background-color:  #B0CB1F !important;
    /* width: 20px !important;
    height: 20px !important; */
    height: 85%;
}
.generic-survey-skil-stepper .ant-steps-dot .ant-steps-item-tail::after{
    margin-top: 15px;
    margin-left: 13px !important;
}
.generic-survey-question-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 20px;
    margin-top: 2.5rem;
    transition: all 0.5s ease;
}
.generic-survey-question{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 !important;
}
.generic-survey-question p{
    margin: 0 !important;  
}



.survey-upload-seaction .ant-upload.ant-upload-select-picture-card{
    border: none !important;
    background-color: transparent !important;
}
.survey-metrix-col-data{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    margin: 0px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.survey-metrix-col-data p{
    margin: 0px !important;
}
.generic-survey-radio-choice-container{
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
}
.generic-survey-action-btn, .generic-survey-action-btn:focus, .generic-survey-action-btn:hover, .generic-survey-action-btn:active{
    border-radius: 8px;
    background-color: #4CCB1F;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1.2rem;
    padding: 10px 30px;
    cursor: pointer;
    border: none !important;
    height: 50px !important;
}
.generic-survey-help-text-popover .ant-popover-inner{
    background: #0E0E2C;
}
.generic-survey-help-text-popover .ant-popover-content > .ant-popover-arrow{
    border-right-color: #0E0E2C;
    border-bottom-color: #0E0E2C;
}
.generic-survey-help-text{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: .875rem;
    color: #FAFCFE;

}
.generic-survey-help-text-btn{
    background-color: #4CCB1F;
    color: #fff;
    border-radius: 6px;
    padding: 6px 15px;
    cursor: pointer;
    font-weight: 700;
    font-size: .875rem;
    border: none;
}
.generic-survey-quit-btn, .generic-survey-quit-btn:hover, .generic-survey-quit-btn:focus{
    background:#f67927;
     /* #E13F3F; */
    border-radius: 8px;
    border: none;
    font-size: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    padding: 4px 15px;
    color: #fff;
    height: 50px;
}
.generic-survey-quit-title{
    font-size: 1.2rem;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 1.5rem;
}
.generic-survey-quit-para{
    font-size: .875rem;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.35rem;
}
.generic-survey-remove-btn{
    background-color: #fff;
    border: 1px solid #8C8CA1;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #8C8CA1;
    cursor: pointer;
}
.generic-survey-remove-btn:hover{
    background-color: #8C8CA1;
    border: 1px solid #8C8CA1;
    color: #fff;
    cursor: pointer;
}
.generic-survey-submit-btn{
    background-color: #fff;
    border: 1px solid #4CCB1F;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4CCB1F;
    cursor: pointer;
}
.generic-survey-submit-btn:hover{
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #fff;
    cursor: pointer;
}

.generic-survey-ok-button, .generic-survey-ok-button:hover, .generic-survey-ok-button:focus, .generic-survey-ok-button:active {
    background-color: #4CCB1F;
    color: #fff !important;
    border-radius: 8px;
    border: none;
    padding: 9px 15px;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    cursor: pointer;
    width: 4.6rem;
}


.generic-survey-assessment-heading{
    font-size: 2rem;
    font-weight: 700;
    color: #141414;
    font-family: 'Poppins';
    line-height: 2.5rem;
    margin: 0 !important;
}

@media (max-width:600px) {
    .generic-survey-skil-stepper{
        padding: 1rem 2rem;
    } 
    .generic-survey-range-slider{
        padding: 0px !important;
    }
}
@media (max-width:480px) {
    .generic-survey-skil-stepper{
        padding: 1rem 1rem;
    } 
}

.survey-group-title{
    font-size: 1.5rem;
    font-family: 'Poppins';
    font-weight: 700;
    line-height: 2rem;
    color: #141414;
}
.survey-ck-editior-view .cke_chrome{
    border-radius: 8px !important;
    border: 1px solid #ffffff !important;
}
.survey-ck-editior-view .cke_bottom{
    display: none !important;
}
.survey-ck-editior-view .cke_top{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ECF1F4 !important;
}
.cke_dialog_body{
    border-bottom: 16px !important;
    background-color: #ffffff !important;
    
}
.cke_dialog_title{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem !important;
    line-height: 1.25rem;
    color: #000;
}
.cke_dialog_ui_input_text{
    height: 3rem !important;
    border-radius: 8px !important;
    border: 1px solif #ECF1F4 !important;
    background-color: #ffffff;
}
.cke_dialog_ui_labeled_label{
    font-size: .875rem;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1rem;
    color: #141414;
}
.cke_dialog_contents_body .cke_dialog_ui_select{
display:  none !important;
}
.cke_dialog_ui_hbox_first{
    width: 0px !important;
    padding-right: 0px !important;
}
.cke_dialog_ui_hbox_last{
    width: 100% !important;
}
.cke_dialog_ui_input_select{
    height: 3rem !important;
    border-radius: 8px !important;
    border: 1px solif #ECF1F4 !important;
    background-color: #ffffff;
}
.cke_dialog_footer{
    border-top: none !important;
}
.cke_dialog_footer_buttons{
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    grid-gap: 10px !important;
    gap: 10px !important;
}
.cke_dialog_ui_button_ok{
    background: #4CCB1F !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: 'Poppins'!important;
    color: #ffffff !important;
    padding: 8px 20px;
    border-radius: 8px !important;
    border: 1px solid #4CCB1F !important;
    height: 100% !important;
    margin-right: 10px !important;
}
.cke_dialog_ui_button_cancel{
    background: #ffffff !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: 'Poppins'!important;
    color: #8C8CA1 !important;
    padding: 8px 20px;
    border-radius: 8px !important;
    border: 1px solid #8C8CA1!important;
    height: 100% !important;
}
.cke_dialog_tabs{
    display: none !important;
}
.cke_dialog_contents{
    margin-top: 0px !important;
    border: none !important;

}
.cke_dialog_contents_body{
    height: 100% !important;
}
.cke_dialog .cke_resizer_ltr{
    display: none !important;
}
.generic-survey-audio-input{
    border-radius: 16px;
    width: 100%;
    background: #ffffff;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.audio-titmer-text{
    font-size: 3rem;
    font-weight: 500;
    font-family: 'Poppins';
    color: #000;
    line-height: 4rem;
}

.generic-survey-next-btn, .generic-survey-next-btn:hover, .generic-survey-next-btn:active, .generic-survey-next-btn:focus{
    border-radius: 8px;
    background-color: #4CCB1F;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1.2rem;
    padding: 10px 30px;
    height: 50px;
    cursor: pointer;
    border: none !important;
    height: 50px !important;
}

.color-picker-extend-height{
    height: 300px;
    transition-duration: .5s;
}

.color-picker-default-height{
    height: 35px;
    transition-duration: .5s;
}

.sketch-picker-default-height{
    opacity: 0 !important;
    margin-top: 10px;
    width: 95% !important;
    overflow: auto;
    height: 0px;
    transition-duration: .5s;
}

.sketch-picker-extend-height{
    transition-duration: .5s;
    opacity: 1 !important;
    margin-top: 10px;
    width: 95% !important;
    overflow: auto;
    height: 250px;
}

.generic-survey-date-picker-date .ant-picker-ok button, .generic-survey-date-picker-date .ant-picker-ok button:hover{
    border-radius: 4px;
    background-color: #4CCB1F;
    color: #fff;
}

.ant-picker-now-btn, .ant-picker-now-btn:hover{
    color: #4CCB1F;;
}

.survey-matrix-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
}

.survey-matrix-question-container{
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
}

.survey-matrix-sections{
    height: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.survey-matrix-table-head{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
}

.question-input div{
    display: flex;
}

.generic-survey-question-preview-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 1.25em; 
    background-color: rgba(246, 251, 255, 1);
}

.survey-preview-main-alignment-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.survey-preview-main-section{
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    width: 90%;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 8px;
    margin-top: 1em; 
}

 



.containct-us-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    -webkit-transform: translate(-50%, -50%) scale(0.6);
            transform: translate(-50%, -50%) scale(0.6);
    width: 25px;
 }
 .containct-us-table .ant-checkbox-checked .ant-checkbox-inner::after {
    -webkit-transform: rotate(45deg) scale(1) translate(-197%, -69%);
            transform: rotate(45deg) scale(1) translate(-197%, -69%);
 }
 .containct-us-table .ant-checkbox-checked .ant-checkbox-inner::after {
    -webkit-transform: rotate(45deg) scale(1) translate(-197%, -69%);
            transform: rotate(45deg) scale(1) translate(-197%, -69%);
 }
 .containct-us-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    display: none;
    background: inherit;
    border: inherit;
 }
 
 
.side-bar-root {
  font-size: 16px;
  font-family: "Poppins";
}

.main-header-continer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  border-bottom: 0.0625em solid #aabac6;
  padding-right: 1.5em;
  position: fixed;
  height: 90px;
  z-index: 1100 !important;
  background-color: #f6fbff;
}

.main-side-bar-container{
  background: transparent !important;
  position: fixed;
  top: 90px;
  left: 0px;
  bottom: 0px;
  width: 130px;
  z-index: 1000 !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  transition-duration: 1s !important;
  
}

.expand-main-side-bar{
  transition-duration: 1s !important;
  width: 350px !important;
  max-width: 90% !important;
}

.expand-side-nav-section {
  width: 100%;
  background-color: #fff;
  padding: 0px 15px;
}

.child-route-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  /* align-items: center; */
  justify-content: start;
  padding: 16px 5px;
  overflow: auto;
  height: 100%;
}



.main-body-container {
  width: 100%;
  background: #f6fbff;
  height: calc(100vh - 5.85em);
  overflow-y: auto;
}
.side-bar-scroll-container {
  height: 100%;
  width: 100px !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-gap: 1em;
  gap: 1em;
  background-color:#E1F1FF;
  padding: 1em 0px;

}

.side-bar-log-continer {
  background: #e1f1ff;
  width: 100px;
  height: 91px;
  border-bottom: 0.0625em solid #aabac6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.side-bar-container {
  height: 100%;
  width: 100px;
  position: relative;
}

.sidebar-expand-icon{
  position: absolute;
  top: 15px;
  left: 100px;
  cursor: pointer;
}

.side-bar-logo {
  height: 60px !important;
  width: 60px !important;
  cursor: pointer;
}

.nav-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.side-nav-selector {
  visibility: hidden;
}
.side-nav-selector-active {
  visibility: visible;
}
.nav-card-container:hover .side-nav-selector {
  visibility: visible;
}

.side-nav-icon-card {
  width: 70px;
  height: 70px;
  border-radius: 0.75em;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2.5px;
  gap: 2.5px;
  cursor: pointer;
  padding: 5px !important;
}
.side-nav-icon-card:hover .side-nav-ttile {
  font-weight: 500;
}

.side-nav-ttile {
  color: #1f1f1f;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.side-nav-ttile-active{
  font-weight: 500;
}

/* *********** */





.side-nav-card-icon {
  height: 2.225em;
  width: 2.225em;
}
.side-bar-logo {
  height: 5.125em;
  width: 5.125em;
  cursor: pointer;
}


.search-icon-container {
  width: 3.5em;
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.65em;
  background-color: #fff;
  margin-left: 1em;
  cursor: pointer;
}
.search-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 5.8em;
  padding: 0em 1em;
  width: 250px;
}
.search-input-container-input {
  border: none !important;
  background-color: #fff;
  font-size: 1em;
  font-family: "Poppins";
  color: #303030;
  width: 100%;
}


.active-side-icon {
  display: none;
}
.non-active-side-icon {
  display: block;
}
.child-route-button:hover {
  /* border: 1px solid rgba(213, 229, 242, 1); */
  box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
  /* border-radius: 0.75em; */
}


.child-route-button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  /* width: 100%; */
  width: 92%;
  min-height: 56px;
  padding: 0.5em 0.25em 0.5em 0.75em;
  border: 1px transparent;
  font-family: "Poppins";
  font-size: 0.95em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* white-space: nowrap; */
}
.child-route-button-active{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  /* width: 100%; */
  width: 92%;
  min-height: 56px;
  padding: 0.5em 0.25em 0.5em 0.75em;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.child-route-button-selected {
  background-color: #4ccb1f;
  color: #fff;
}
.child-route-button-selected .active-side-icon {
  display: block;
}
.child-route-button-selected .non-active-side-icon {
  display: none;
}

.header-page-title{
  font-size: 1.5em;
  font-weight: 700;
  margin: 0px;
  margin-left: 10px !important;

}
.expand-child-route{
  border: 1px solid rgba(213, 229, 242, 1) !important;
  box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
  display: flex;
  flex-direction: column;
  grid-gap: .5em;
  gap: .5em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 92%;
  border-radius: 0.75em;
  transition-duration: 1s;
  transition: ease .5s;
  outline: none !important;
  padding-left: .5em;
  padding-right: .5em;
}

.bread-crum-btn{
  background: #E1FFD7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 1em 2px 1em;
  border-radius: 0.25em;
  font-family: "Poppins";
  font-size: 1.15em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  color: #6BB96A;
}
.bread-crum-btn-inactive{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 1em 2px 1em;
  border-radius: 0.25em;
  font-family: "Poppins";
  font-size: 1.15em;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.bread-crum-container{
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  grid-gap: 1em;
  gap: 1em;
}
/* .child-route-button-active{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  width: 100%;
  min-height: 56px;
  padding: 0.5em 1em;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */
.top-nav-bar-icons-align{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}
.sidebar-mobile-expand-icon{
  position: absolute;
  left:0px;
  top:105px;
  cursor: pointer;
  display: none;
  z-index: 1;
}
@media (max-width:960px) {
  .sidebar-mobile-expand-icon{
    display: block;
  }
  .main-side-bar-container-for-web{
    display: none;
  }
}
@media (max-width:380px) {
  .top-nav-bar-icons-align{
    grid-gap: 1em;
    gap: 1em;
  }
}

.header-dropdown-index{
  z-index: 1500 !important;
}

.side-nav-ttile-green-active{
  color: #4CCB1F !important;
}
.navigation-warning-btn, .navigation-warning-btn:hover, .navigation-warning-btn:focus {
  margin: 8px;
  border: 1px solid #4ccb1f;
  color: #4ccb1f;
  border-radius: 8px;
  width: 120px;
  height: 44px;
  font-size: 1.15rem;
  font-weight: 500;
}

.custom_nav_icon_size{
  width:28px;
  height: 28px;
}
.feedback-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.space-between-elements-feedback {
    margin: 20px auto;
}

.receipient-feedback-title:hover {
    color: #000000;
}

.feedback-badge-award-carousel .ant-carousel .slick-slide.slick-active {
    width: 230px !important;
}


.discarded-okr{
    color: rgb(190, 67, 67) !important;
}

.feedback-badge-award-carousel .ant-select-item-option-content {
    color: #000000;
}

.red-bordered .ant-select-selector {
    border: 1px solid red !important;
}

.normal-bordered .ant-select-selector {
    border: 1px solid #D9D9D9 !important;
}

.checkbox-panel-feedback .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4CCB1F !important;
    border-color: #4CCB1F !important;
}

.checkbox-panel-feedback .ant-checkbox-checked::after {
    border: 1px solid #4CCB1F !important;

}


.feedback-input-box-label {
    margin-bottom: 6px !important;
    /* margin-left: 20px !important; */
}

.receipient-feedback-title {
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.feedback-type-selector .ant-btn:hover, .feedback-type-selector
.ant-btn:focus {
    color: rgba(0, 0, 0, 0.85);
}

.feedback-selection-elements-wrapper {
    width: 80%;
    margin: auto;
}

.feedback-pop-up-header {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    left: 30px
}

.top-bottom-margin-feedback {
    margin-top: 20px;
    margin-bottom: 20px;
}

.given-kudos-container {
    padding: 6px;
    height: 40px;
    width: 50px;
    border: 1px solid #D9D9D9;
    border-color: #4CCB1F;
    border-radius: 8px;
}

.feedback-pop-up-container::-webkit-scrollbar {
    display: none;
}

.delete-given-kudos-button-give-feedback {
    position: relative;
    top: -29px;
    /* left: 57px; */
    background: white;
}

.delete-given-kudos-button-edit-feedback {
    position: relative;
    top: 12px;
    left: 40px;
    background: white;
}



.feedback-receipient-selector .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    border-radius: 10px;
}

.give-kudos-button:hover {
    color: #4CCB1F !important;
    border-color: #4CCB1F !important;
}

.feedback-receipient-selector .ant-select-selection-placeholder {
    color: #D9D9D9 !important;
    margin-top: 3px;
}

.feedback-receipient-selector .ant-select-selection-item {
    margin-top: 3px;
}

.okr-title-feedback {
    padding: 9px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    cursor: not-allowed;
    width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.feedback-user {
    font-size: 18px;
    font-weight: 500;
}

.feedback-pop-up-form-title {
    margin-bottom: 6px;
}

.feedback-pop-up-form-title p {
    margin-bottom: 0px;
}

.text-ellipsis-feedback {
    overflow: hidden;
    margin-bottom: 5px;
    white-space: nowrap;
    width: 280px;
    text-overflow: ellipsis;
}

.fw-500-feedback {
    font-weight: 500;
}

.fw-700-feedback {
    font-weight: 700;
}


.checkbox-panel-feedback {
    margin: 20px auto 10px auto;
    width: 100%;

}

.give-kudos-button {
    height: 45px;
    width: 140px;
    color: #4CCB1F;
    border-color: #4CCB1F;
    border-radius: 8px;
}

/* .meeting-checkbox-selector {
    margin-left: 50px;
} */

.okr-checkbox-selector {
    position: relative;
    right: 20px;
    left: 20px;
}

.feedback-wrapper::-webkit-scrollbar {
    color: red;
    margin-right: 10px;
    width: 5px;
    padding-right: 10px;
}

.feedback-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    padding-right: 10px;
    color: red;
}

.feedback-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    color: red;
    margin-right: 10px;
    padding-right: 10px;
    background-color: #D9D9D9;
}


/* give and edit feedback pop up styles */


.feedback-pop-up-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 6px;
    color: #000000;
}

.feedback-pop-up-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}

.feedback-pop-up-ck-editor .cke_chrome {
    border: none;
    padding: 0 !important;
}

.feedback-pop-up-ck-editor .cke_bottom {
    display: none;
}

.feedback-pop-up-ck-editor .cke_reset {
    padding: 0px 0px;
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width: 5px;
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D9D9D9;
}

.feedback-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    line-height: 140%;
    text-align: left;
    margin-bottom: 10px;
    color: #141414;
}

.feedbaack-give-kudos-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #595959 !important;
}

.feedback-okr-selection-drop {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    max-width: 500px !important;
    line-height: 18px;
    color: #595959;
}

.feedback-input-field-style input {
    height: 40px;
    width: 100px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
}


.feedback-tab-btn-focus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn-focus:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn-focus:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-tab-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #141414;
    border-radius: 8px;
    border: 1px solid #4CCB1F;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-tab-btn-active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn-active:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn-active:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-save-btn-modal {
    padding: 7px 24px;
    height: 40px;
    width: 108px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin-top: 10px;
}

.feedback-save-btn-modal:hover {
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.feedback-save-btn-modal:focus {
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}



.feedback-pop-up-ck-editor {
    padding-right: 20px;
    border-radius: 8px;
    width: 100%;
}

.normal-border{
    border: 1px solid #D9D9D9;
    padding-left: 2px;
}

.focused-border{
    border: 1px solid #4CCB1F;
}

.feedback-cancel-btn-modal {
    padding: 7px 22px;
    height: 40px;
    width: 105px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
    border-radius: 8px;
    margin-top: 10px;
}

.feedback-cancel-btn-modal:hover {
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}

.feedback-cancel-btn-modal:focus {
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}


.close-modal-feedback {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding-right: 30px;
}

.feedback-popup-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #141414;
}

.feedback-popup-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}

.feedback-type-container {
    flex-wrap: nowrap;
}



@media (max-width:500px) {

    .view-feedback-pop-up-header {
        align-items: center;
    }



    .feedback-pop-up-header {
        left: 150px;
    }

    .give-kudos-wrapper {
        margin-top: 20px;
    }

    .feedback-type-container {
        flex-wrap: wrap;
    }

    .user-info-view-feedback-popup {
        margin-top: 20px;
    }

    .close-modal-feedback {
        top: -45px;
        right: -14px;
    }

    .feedback-selection-elements-wrapper {
        width: 52%;
    }

}
.feedback-type-switcher{
    width:100%;
    display:flex;
    flex-direction:row;
    border-radius:.5em;
    height:4em;
    background: rgba(244, 244, 244, 1);
}
.feedback-type-content-container{
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1em;
    cursor:pointer;
    border: 2px solid transparent
}
.feedback-type-content-container:hover {
    border: 2px solid rgba(134, 216, 80, 1);
    border-radius:.5em;
}
.feedback-type-content-container-active{
    background: linear-gradient(274.71deg, #FFFFFF -35.42%, #CCFF41 -0.39%, #29BF00 17.24%, #23A400 34.11%);
    /* border: 2px solid;
    border-image-source: linear-gradient(274.71deg, #FFFFFF -35.42%, #CCFF41 -0.39%, #29BF00 17.24%, #23A400 34.11%); */
    border-radius:.5em;
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1em;
    cursor:pointer
}
.feedback-type-content-container-active h4{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin:0px;
    color:rgba(255, 255, 255, 1);
}
.feedback-type-content-container h4{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin:0px;
    color:rgba(89, 89, 89, 1);
}


@media (max-width:1600px) {

    .feedback-pop-up-form-title {
        font-size: 0.9em;
    }

    .feedback-popup-head {
        font-size: 20px;
    }


    .one-on-one-meeting-popup-para {
        font-size: 14px;
    }

    .feedback-form-title {
        font-size: 14px;
    }

}
.badge-upload-container .anticon-eye{
    display: none;
}

.kudos-root-class{
    font-size: 16px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
}
.kudos-modal-delete{
    width: 38.625em !important;
}
.kudos-modal-delete .ant-modal-body{
    padding: 0px 10px 10px 10px !important;
}
.kudos-modal{
    width: 53.063em !important;
}

.kudos-modal-overflow{
    max-height: 80vh;
    overflow: auto;
}

.kudos-modal-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    display: block;
  }
  
  .kudos-modal-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .kudos-modal-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
  }

.kudos-space-between-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.625em;
    gap: 0.625em; 
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    /* padding: 0.38em 2.75em 3em 2.75em; */
    /* background-color: #4CCB1F; */
}
.kudos-prev-page,.kudos-next-page{
    position: absolute;
    top: 22em;
    background-color: transparent;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 50%;
    padding: 0px;
}

.kudos-next-page{
    right: 0px;
}

.kudos-prev-page:hover,.kudos-next-page:hover{
    background-color: transparent !important;
    border: none;
}

.kudos-prev-page:active,.kudos-next-page:active{
    background-color: transparent;
    border: none;
}

.kudos-prev-page:focus,.kudos-next-page:focus{
    background-color: transparent;
    border: none;
}

.kudos-prev-page:disabled,.kudos-next-page:disabled{
    background-color: transparent;
}

.kudos-tool-titile{
    font-weight: 500;
    font-size: 2.1875em;
    line-height: 1;
    color: #141414;
    margin: 0px;
}

.kudos-sub-title{
    font-weight: 500 !important;
    font-size: 1em !important;
    /* line-height: 140%; */
    color: #595959;
    text-align: center;
    margin-top: .5em;
}

.kudos-add-badge{
    padding: 0.75em 1.5em;
    grid-gap: 0.625em;
    gap: 0.625em;
    width: 8.9375em;
    height: 3em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 700;
    font-size: 1em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.kudos-add-badge:hover,.kudos-add-badge:active,.kudos-add-badge:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;

}

.kudos-btn-disable{
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;
}

.kudos-cursor-disabled{
    cursor: not-allowed !important;
}

.kudos-btn-disable:hover,.kudos-btn-disable:active,.kudos-btn-disable:focus{
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.kudos-search-container{
    padding: 0px;
    width: 22.6875em;
    height: 3em;
    position: relative;
}
.kudos-search-input{
    /* width: 100%; */
    padding-left: 2.5em;
    height: 100%;
    border-radius: 10px;
}
.kudos-search-icon{
    position: absolute;
    top: 0.75em;
    left: 0.625em;
    z-index: 1;
    width: 1.5em;
    height: 1.5em;
}

.kudos-modal-title{
    font-weight: 400 !important;
    font-size: 1.6em !important;
    line-height: 1 !important;
    letter-spacing: -0.02em;
}


.kudos-badge-form{
    padding: 1em 7% 1em 0px ;
}

.kudos-form-group{
    width: 100%;
}

.kudos-description{
    width: 100%; 
    height: 120px;
    resize: none;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    outline: none !important;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5715;
}

.kudos-description::-webkit-input-placeholder{
    color: rgb(194, 194, 194);
}

.kudos-description:-ms-input-placeholder{
    color: rgb(194, 194, 194);
}

.kudos-description::placeholder{
    color: rgb(194, 194, 194);
}

.badge-info-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.badge-info-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    margin-bottom: 1.25em;
}

.upload-container{
    min-width: 110px;
    min-height: 110px;
    border-radius: 50%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: #D9D9D9;
    cursor: pointer;
}

.dummy-container{
    min-width: 110px;
    min-height: 110px;
    border-radius: 50%;
    background-color: transparent;
}
/* 7.438em; */
.upload-success-container{
    min-width: 110px;
    min-height: 110px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
}

.upload-success-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
}

.kudos-modal-btn{
    padding: 0.75em 1.5em;
    line-height: 1.5em;
    font-size: 1em;
    margin: 0.75em;
    height: 3em;
    border-radius: 0.5em;
    font-weight: 700;
    cursor: pointer;
}

.kudos-save, .kudos-save:hover, .kudos-save:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 2px solid #4CCB1F;
}

.kudos-new-badge, .kudos-new-badge:hover, .kudos-new-badge:focus{
    background: #FFFFFF;
    color: #4CCB1F;
    border: 2px solid #4CCB1F;
}

.kudos-form-group .ant-select .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;
}

.kudos-form-group .ant-select .ant-select-arrow{
    top: 48% !important;
}

.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    display: flex;
    align-items: center;
}

.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: rgb(194, 194, 194) !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.kudos-form-group .ant-select-selector .ant-select-selection-search-input{
    padding-top:7px !important;
}
.no-border-category .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
}
.badge-index{
    font-weight: 400;
    font-size: 1.5em;
    line-height: 36px;
    letter-spacing: -0.02em;
    padding: 0px !important;
    margin: 0px !important;
    color: rgba(0, 0, 0, 0.67);
}

.selected-badge-index{
    color: #4CCB1F !important;
}

/* kudos home screen */

.kudos-fluid-container{
    width: 100%;
    /* max-width: 1920px; */
    
}

.kudos-fluid-container .ant-tabs-top > .ant-tabs-nav, .kudos-fluid-container .ant-tabs-bottom > .ant-tabs-nav,.kudos-fluid-container .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin: 0px !important;
}


.kudos-main-card-container{
    width: 100%;
    background: rgba(246, 246, 246, 0.62);
    padding: 1em 2.5em;
    
}

.card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px 2em;
    /* background: red; */
    flex-wrap: wrap;
}

.badge-card-vertical-container{
    width: 33.33%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    /* position: relative; */
}

.badge-card{
    width: 100%;
    height: 15.375em;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.11);
    border-radius: 0.3em;
    overflow: hidden;
    padding: 0.813em 0.938em;
    display: flex;
    flex-direction: column;
    transition-duration: .5s;
    margin-bottom:6.375em ;
}

.badge-card-exp{
    height: 44.75em;
    transition-duration: .5s;
}

.kudos-delete-icon{
    width: 1.125em;
    height: 1.5em;
    cursor: pointer;
}

.kudos-card-info{
    width: 100%;
    /* height: 100%; */
    height: 11.8em; 
    background-color: rgba(76, 203, 31, 0.05);;
    border-radius: 0em !important;
    margin-top: 0.375em;
    padding: 0.375em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.badge-info-card-fixed{
    height: 17.2em !important; 
}

.kudos-card-title-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
}

.kudos-badge-sub-action{
    padding: 0px !important;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: start !important;
    align-items: center !important;
    width: 80%;
}

.kudos-badge-sub-action .title{
    margin: 0px;
    font-weight: 500;
    font-size: 1em;
    line-height: 140%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.kudos-card-title{
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 2em;
  cursor: default;
}

.kudos-card-edit{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
}

.kudos-card-dis{
    overflow: hidden;
    height: 100%;
    padding: 0px 6%;
    text-align: left;
    cursor: default;
    font-weight: 300;
    font-size: 1em;
    line-height: 140%;
    text-transform: lowercase;
    color: #8C8CA2;
}

.kudos-card-action-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}

.kudos-card-add-badge{
    font-weight: 300;
    font-size: 0.875em;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 0.313em;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.card-discription{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* width: 155px; */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.new_drop_arrow{
    transition-duration: .5s;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    cursor: pointer;
}
.kudos-rotate-arrow{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition-duration: .5s;
}

.badge-list-container{
    height: 0px;
    padding: 0px 1.2em;
}

.badge-list-container *{
    opacity: 0;
}
.badge-list-visible{
    height: 100%;
    overflow: auto;
}

.badge-list-visible::-webkit-scrollbar {
    height: 0px;
    width: 5px;
  }
  
  .badge-list-visible::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .badge-list-visible::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
  }

.badge-list-visible *{
    transition-delay: .5s;
    opacity: 1 !important;
}
.kudos-border{
    border-left: 1px dashed rgba(0, 0, 0, 0.5);
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='1' stroke-dasharray='24' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e"); */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* min-height: 100%; */
    padding: 1em 1.419em;
}

.kudos-single-badge-card{
    border-width: 0px 0.3px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5);
    padding: 0px 0.688em;
    margin-bottom: 1.5em;
    height: 3.75em;
    position: relative;
}

.badge-index-circle{
    position: absolute;
    width: 1.625em;
    height: 1.625em;
    top: 1em;
    left: -2.29em;
    background: #FFFFFF;
    border: 1px solid #4CCB1F;
    border-radius: 50%;
}

.badge-behavioural-description{
    text-align: left;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 140%;
    color: #8C8CA2;
    margin-top: 0.5em;
}

.kudos-single-badge-info :hover{
    /* scale: 2; */
    transition-duration: .5s;
}

.badge-card-hide{
    height: 0px;
    padding: 0px;
    opacity: 0;
    margin: 0px !important;
    transition-duration: .5s;
}
.badge-card-hide *{
    display: none !important;
}

.delete-logo{
    width: 15.438em;
    height: 15.938em;
    margin-right: 5em;
}

.kudos-delete-title{
    margin-top: 1.2em;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 2em;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
}

.kudos-delete-btn{
    width: 6.625em;
    height: 3em;
    background: #E86161;
    border-radius: 0.5em;
    margin: 1em;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
    border: 2px solid #E86161;
    padding: 0.75em 1em;
}

.kudos-delete-btn:hover,.kudos-delete-btn:focus{
    background: #E86161;
    border: 2px solid #E86161;
    color: #FFFFFF;
}

.kudos-cancel-btn{
    margin: 1em;
    width: 6.625em;
    height: 3em;
    background:transparent;
    border-radius: 0.5em;
    margin: 1em;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
    border: 2px solid #8C8C8C;
    padding: 0.75em 0px;
}

.kudos-cancel-btn:hover,.kudos-cancel-btn:focus{
    background: transparent;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.kudos-existing-no-data{
    min-height: 50vh;
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(246, 246, 246, 0.62);
    /* background-color: #E86161; */
}

.kudos-no-data-img{
    object-fit: contain;
    max-width: 95%;
}

.kuods-not-found{
    font-weight: 400;
    font-size: 1.5em;
    line-height: 2.25;
    text-transform: capitalize;
    color: #000000;
    margin-top: -4em;
    text-align: center;
}

.kudos-no-data-sub{
    color: #777777;
    font-size: 1em;
    text-align: center;
    width: 53.125em;
    max-width: 100%;
}

.kudos-go-back-btn{
    width: 7.571em;
    height: 2.245em;
    background: #777777;
    color: #FFFFFF;
    font-size: 1.5em;
    font-weight: 400;
    margin-top: 2em;
    cursor: pointer;
    border: 1px solid #777777;
}
.kudos-go-back-btn:hover ,.kudos-go-back-btn:focus{
    background: #777777;
    color: #FFFFFF;
    border: 1px solid #777777;
}

.kudos-page-arrow{
    width: 2em;
    height: 2em;
}
.kudos-fluid-container .ant-tabs-tab div{
    font-size: 1.5em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}

.kudos-fluid-container .ant-tabs-tab-active div{
    color: #4CCB1F !important;
}
.kudos-fluid-container .ant-tabs-ink-bar{
    background-color:#4CCB1F !important;
}

.delete-show-mobile{
    display: none;
}

.delete-hide-mobile{
    display: block;
}
.kudos-popup-inner-container{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}
.kudos-detail-right-container-inner{
    padding: 0px 1em;
}
.kudos-badge-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 140%;
    color: #4CCB1F;
}
.kudos-badge-description-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 140%;
    color: #8C8CA2;
}
.kudos-badge-modal-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size:2em;
    line-height: 2.4em;
    color: #000000;
    text-align: left;
}
.kudos-modal-main-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2em;
    line-height: 3em;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #000000;
}
.kudos-fluid-container .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.kudos-fluid-container .ant-tabs-tab{
    /* padding: 5px 0px !important; */
  }
  .kudos-fluid-container .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 1.75em !important;
  }

@media (max-width:1600px) {
    .kudos-search-container{
        width: 18em;
        height: 2.5em;
    }
    .kudos-search-icon{
        top: .5em;
    }
    .kudos-modal{
        width: 45.063em !important;
    }
    .badge-card{
        margin-bottom: 4.375em;
    }
}
@media (max-width:1400px) {
    .badge-card-vertical-container{
        width: 50%;
        padding: 1.5em;
    }
}

@media (max-width:1200px) {
    .kudos-root-class{
       font-size: 14px !important;
    }

    .delete-logo{
        margin-right: 8em;
    }

    .kudos-prev-page,.kudos-next-page{
        top: 19em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.4em;
    }
}

@media (max-width:960px) {
    .kudos-root-class{
       font-size: 12px !important;
    }
   
    .kudos-badge-form{
        padding: 1em 10px 1em 0px;
    }
    .delete-logo{
        margin-right: 12.5em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 16.5em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.3em;
    }
}

@media (max-width:700px) {
    .align-logo{
        display: flex;
        align-items: start;
    }
    .delete-logo{
        margin-right:40%;
        margin-left: 40%;
    }
}

@media (max-width:760px) {
    .badge-card-vertical-container{
        width: 100%;
    }
}

@media (max-width:600px) {
    .kudos-root-class{
        font-size: 11px !important;
     }
    .badge-info-row{
        flex-direction: column;
    }

    .badge-info-row .ant-col-sm-13 {
        display: block;
        flex: 0 0 54.16666667%;
        max-width: 100%;
    }
    .badge-info-main{
        flex-direction: column;
    }
    .dummy-container{
        display: none !important;
    }
    .delete-logo{
        margin-right: .5em;
    }
    .kudos-go-back-btn{
        width: 7em;
        height: 2.5em;
        font-size: 1em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 14.5em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.2em;
    }
    .kudos-form-row{
        display: flex;
        flex-direction: row;
        grid-gap: 2em;
        gap: 2em;
    }
    .delete-show-mobile{
        display: block;
    }
    .delete-hide-mobile{
    display: none;
    }
}

@media (max-width:450px) {
    .kudos-col-450{
        flex-direction: column;
    }
    .kudos-hide-450{
        display: none;
    }
    .kudos-search-container{
        max-width: 100%;
    }
    .kuods-not-found{
        margin-top: -3em;
        font-size: 1.2em;
    }
    .kudos-no-data-sub{
        font-size: 0.8em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1em;
    }
    .kudos-page-arrow{
        width: 1.5em;
        height: 1.5em;
    }
    .badge-card-vertical-container{
        padding: 1.8em .4em;
    }
    .badge-card{
        margin-bottom: 4.375em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 13.5em;
    }
}







  .image-container-bg{
    background: url(/static/media/login-bg.3ef2018e.jpg) !important;
    background-repeat: no-repeat !important;
    background-size:100% 100% !important;
    background-position: center !important;
  }
  .login-logo{
    display: none !important;
  }

  .custom-form .ant-input{
    padding-left: 19px;
  }

  @media (max-width:1380px) {
    .image-container-bg{
      background: url(/static/media/login-bg.3ef2018e.jpg) !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      background-position: center !important;
    }
    .login-logo{
      display: none !important;
    }
  }
  @media (max-width:1240px) {
    .image-container-bg{
      background: url(/static/media/login-bg-medium.07b04c00.jpg) !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      background-position: center !important;
    }
    .login-logo{
      display: none !important;
    }
  }
  @media screen and (max-width:1400px) and (min-height:840px) {
    .image-container-bg{
      background: url(/static/media/login-bg-only.37709ca5.png) !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      /* background-size: cover; */
      background-position: center !important;
    }
    .login-logo{
      display: block !important;
    }
    .login-logo{
      height: 130px !important;
    }
  }
  @media (max-width:1050px) {
    .image-container-bg{
      background: url(/static/media/login-bg-only.37709ca5.png) !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      /* background-size: cover; */
      background-position: center !important;
    }
    .login-logo{
      display: block !important;
      height: 100px !important;
    }
  }
  .faq-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #D3F261;
    position: absolute;
    right: 50px;
    top: 60px;
    cursor: pointer;
  }
  .login-sub-head{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.005em;
  
  /* Gray 7 */
  
  color: #BFBFBF;
  }
  .custom-form .ant-form-item-label > label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #434343;
    padding-left: 14px;
  }
  .ant-form-item-explain.ant-form-item-explain-error{
    padding-left: 14px;
    padding-top: 6px;
    font-family: 'Poppins';
  }
  .ant-form-item-explain{
    padding-left: 14px;
    color:#ff4d4f;
    padding-top: 6px;
    font-family: 'Poppins';
  }
  .custom-form .ant-input{
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 119px;
    font-size: 18px;
    padding-left: 19px;
  }
  .custom-form .ant-input-affix-wrapper{
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 80px;
    font-size: 18px;
    padding: 0px 10px 0px 0px;
  }
  .custom-form .ant-input-affix-wrapper .ant-input{
    height: 62px;
    background: #FFFFFF;
    border: none;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 14px;
    padding-left: 19px;
  }
  .custom-form .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon{
    display: none;
  }
  .custom-form .ant-form label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #434343;
  }
  .mGSDE{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4CCB1F !important;
  }
  
  .login-btn{
    width:100%;
    height: 81px;
    background: #4CCB1F;
    border-radius: 216px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: -0.025em;
    color: #FFFFFF;
    border:1px solid #4CCB1F;
  }
  .login-btn:hover{
    border: 2px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
    opacity: .8;
  }
  .login-btn:focus{
    border: 2px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .find-btn{
    height: 53px;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    border-radius: 5.01099px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20.044px;
    line-height: 30px;
    color: #FFFFFF;
    border:1px solid #4CCB1F;
    margin-top: 0px;
  }

  @media screen and (max-width:420px) {
    
    .find-btn{
      margin-bottom: 10px;

    }
  }

  .find-btn:hover{
    border: 1px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
    opacity: .8;
  }
  .find-btn:focus{
    border: 1px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .account-form{
    margin-top: 20px;
  }
  .go-back-btn{
    height: 53px;
    background: #fff !important;
    border: 1px solid #8C8C8C !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19.2088px;
    line-height: 29px;
    letter-spacing: 0.005em;
    color: #8C8C8C !important;
    border-radius: 5.01099px;
    margin-top: 10px;
  }
  .go-back-btn:hover{
    border: 1px solid #8C8C8C !important;
    color: #8C8C8C!important;
    background-color: #fff !important;
    opacity: .8;
  }
  .go-back-btn:focus{
    border: 1px solid #8C8C8C !important;
    color: #8C8C8C!important;
    background-color: #fff !important;
  }
  .login-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 10px 100px 0px 100px;
    background-color: #f5f5f5;
  }
  .open-logo{
    display: none;
}

@media (max-width:420px) {

  .login-sub-head{   
    text-align: center;
  }

}

  @media (max-width:1700px) {
    .login-logo{
      height: 100px;
    }
    .login-sub-head{
      font-size: 14px;
    }
    .custom-form .ant-form-item-label > label{
      font-size: 14px;
    }
    .custom-form .ant-input{
      height: 43px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 80px;
      font-size: 14px;
      padding-left: 19px;
    }
    .custom-form .ant-input-affix-wrapper{
      height: 43px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 80px;
      font-size: 14px;
    }
    .custom-form .ant-input-affix-wrapper .ant-input{
      height: 40px;
      border: none;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 14px;
      padding-left: 19px;
    }
    .mGSDE{
      font-size: 14px;
    }
    .custom-form .ant-form label {
      font-size: 14px;
    }
    .login-btn{
      height: 61px;
      font-size: 22px;
      border-radius: 160px;
    }
    .find-btn{
        width: 100px !important;
        height:42px;
        font-size: 16px;
    }
    .go-back-btn{
        width: 100px !important;
        height:42px;
        font-size: 14px;
    }
  }
  
  .form-mobile-screen{
    display: none !important;
  }
  
  .custom-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color:#4CCB1F;
  border-color: #4CCB1F;
  }
  
.email-sent-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #1F1F1F;
    padding: 20px 0px 0px 0px;
}
  
  @media (min-width:1700px) {
    .login-popup-head{
        font-size: 24px;
      }
    .email-sent-text{
        font-size: 20px;
    }
  }
  @media (max-width:1150px) {
      .login-bg-bottom {
        position: absolute;
        width: 165% !important;
        z-index: 10;
        left: -237px;
        right: 0;
        top: 58%;
    }
    .login-container{
      padding: 10px 80px 0px 80px;
    }
    .login-btn{
      height: 55px;
      font-size: 22px;
      border-radius: 160px;
    }
  }
  @media (max-width:1010px) {
    .login-bg-bottom {
      position: absolute;
      width: 165% !important;
      z-index: 10;
      left: -207px;
      right: 0;
      top: 64%;
  }
}
  @media (max-width:960px) {
    .login-logo{
      height: 90px;
    }
    .login-container{
      padding: 10px 60px 0px 60px;
    }
    .login-btn{
      height: 50px;
      font-size: 22px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 165% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  @media (max-width:820px) {
    .login-logo{
      height: 90px;
    }
    .login-container{
      padding: 10px 40px 0px 40px;
    }
    .login-btn{
      height: 50px;
      font-size: 20px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 185% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  @media (max-width:740px) {
    .login-logo{
      height: 80px;
    }
    .login-container{
      padding: 10px 20px 0px 20px;
    }
    .login-btn{
      height: 50px;
      font-size: 20px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 200% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  
  
  @media (max-width:600px) {
    .form-mobile-screen{
      display: flex !important;
    }
    .web-login{
      display: none;
    }
    .login-container{
      background: #fff !important;
      overflow: auto;
      /* margin-top: 20px; */
    }
    .login-btn{
      max-width: 100%;
      width: 100%;
    }
    .login-popup-head{
        font-size: 20px;
    }
    .email-sent-text{
        font-size: 14px;
    }
    .open-logo{
        width: 220px;
        display: block;
    }

  

  }



  @media (max-width:420px) and (orientation:portrait) {

    .login-sub-head{
      width: 63%;
      font-size: 16px;
    }

  }

  @media (max-width:340px) {
    .login-popup-head{
        font-size: 18px !important;
    }
    .email-sent-text{
        font-size: 14px;
    }
    .open-logo{
        width: 140px;
        display: block;
    }
    .mobile-logo{
      margin-top: 40px !important;
      width: 170px;
    }

    .login-sub-head{
      width: 70%;
      font-size: 14px;
    }

  }
  .login-popup-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #000000;
    text-align: center;
  }

  
.profile-update-Root{
    font-size: 16px;
    font-family: Poppins;
}
.profile-update-popup-main-head{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.5em;
    font-style: normal;
    line-height: normal;
    color:rgba(38, 38, 38, 1);
    margin: 0;
}
.save-btn-profile-update, .save-btn-profile-update:hover, .save-btn-profile-update:focus{
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2em;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
    background: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: .5em;
    padding: .5em 2em;
    cursor: pointer;
    outline: none;
    border: none;
    height: 3em;
}
.cancel-btn-profile-update, .cancel-btn-profile-update:hover, .cancel-btn-profile-update:focus{
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2em;
    font-style: normal;
    line-height: normal;
    color: rgba(137, 152, 162, 1);
    background: #ffffff;
    border-radius: .5em;
    padding: .5em 2em;
    cursor: pointer;
    outline: none;
    border: none;
    height: 3em;
    border: 1px solid rgba(170, 186, 198, 1)
}
.profile-edit-select .ant-select-selector{
    border-radius: .5em !important;
    height: 3em !important;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    box-shadow: none !important;
}
.profile-edit-select .ant-select-selection-item{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.profile-edit-input{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.profile-edit-select .ant-select-selection-search-input{
    height: 2.5em !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.update-profile-form .ant-form-item {
    margin-bottom: 1em;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}
.profile-notification-root {
    font-size: 16px;
    font-family: 'Poppins' !important;
}

.proile-notification-title {
    font-size: 1.25em;
    font-family: 'Poppins' !important;
    color: #000;
    font-Weight: 600;
    line-height: normal;
    margin: 0;
}

.notifcation-list-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    align-items: start;
    grid-gap: 2em;
    gap: 2em;
    margin-top: 1em;
}

.notifcation-list-left-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    padding-top: 0em;
}

.notifcation-list-right-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    grid-gap: 0em !important
}

.notification-type-container {
    border-top: 1px solid #CBD5E1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 1em !important;
    gap: 1em !important;
    align-items: center;
    padding: 1em 0em 1em 2em;
    grid-gap: 0em !important
}

.notigcation-label {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: .85em;
    line-height: normal;
    margin: 0;
    color: #000;
}

.notification-action-active-button,
.notification-action-active-button:hover,
.notification-action-active-button:focus {
    background: #4CCB1F;
    color: #FFFFFF;
    border: none;
    border-radius: .5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.notification-action-cancel-button,
.notification-action-cancel-button:hover,
.notification-action-cancel-button:focus {
    background: #CBD5E1;
    color: #FFFFFF;
    border: none;
    border-radius: .5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.notification-setting-custom-scroll {
    height: calc(100vh - 380px);
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding-top: 16px;
    overflow: auto;
}

.notification-setting-custom-scroll::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.notification-setting-custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.notification-setting-custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.change-password-input,.change-password-input:hover,.change-password-input:focus {
    border: 1px solid #AABAC6 !important;
    border-radius: .5em;
    width: 100%;
    height: 3.5em !important;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 400;
    box-shadow: none;
}
.change-password-action-button-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
    justify-content: start;
    align-items: center;
}
.profile-notification-root .ant-form-item-children-icon{
    display: none !important;
}
.tip-section-container {
  display: flex;
}

.section-title-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 30%;
  font-weight: bold;
  margin: 15px 0px;
}

.first-element {
  border-top: 1px solid #cbd5e1;
}

.message-action-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cbd5e1;
  padding: 0rem 2rem;
}

.action-message-label {
  margin: 15px 0px;
}

.tip-action-switch {
  margin: 15px 0px;
}

.tip-section-container .ant-switch-handle::before {
  background-color: #aabac6 !important;
}

.tip-section-container .ant-switch-checked .ant-switch-handle::before {
  background-color: #0dd671 !important;
}

.message-action-container .ant-switch,
.message-action-container .ant-switch-checked {
  background-color: #f6fbff !important;
  border: 1px solid #e1f1ff !important;
}

@media (max-width: 1140px) {
  .ai-tips-root {
    max-width: 100% !important;
  }
}

@media (max-width: 760px) {
  .tip-section-container {
    flex-direction: column !important;
  }

  .section-title-label {
    max-width: none !important;
    margin: 15px 0px 0px 0px !important;
  }

  .first-element {
    border-top: none !important;
  }

  .first-section {
    border-top: 1px solid #cbd5e1 !important;
    align-items: normal !important;
  }

  .message-action-container {
    padding: 0px !important;
  }
}

/* css for main cards container with media query for card position adjustments */

.feedback-sub-cards-container {
    display: grid;
    width: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: minmax(-webkit-min-content, -webkit-max-content);
    grid-template-rows: minmax(min-content, max-content);
    grid-gap: 2em;
    gap: 2em;
    padding: 0em 2.1em 0em 2.1em;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    height: -webkit-min-content;
    height: min-content;
}

.height-fix-submitted {
    height: 840px;
}

.height-fix-received {
    height: 840px;
}

.feedback-sub-cards-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

@media screen and (min-width: 511px) and (max-width: 1112px) {
    .feedback-sub-cards-container {
        grid-template-columns: 1fr;
      }
}

@media screen and (min-width: 550px) and (max-width: 552px) and (min-height: 830px) {
    .feedback-sub-cards-container {
        grid-template-columns: 1fr 1fr;
      }
}

@media screen and (min-width: 638px) and (max-width: 640px) and (min-height: 1365px) and (orientation: portrait) {
    .feedback-sub-cards-container {
      grid-template-columns: 1fr !important;
    }
  }

@media screen and (max-width: 510px) {
  .feedback-sub-cards-container {
    grid-template-columns: 1fr;

  }
}

@media screen and (max-width: 321px) {

    .feedback-sub-cards-container {
      grid-template-columns: 1fr;
    }
  }

/* the below section is problem area one of. the width is creating issue */
.feedback-sub-detail-text {
    height: 65px !important;
    width: 100px;
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    overflow-y: hidden;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 1.4em;
    font-size: 0.96rem;
    /* word-wrap: break-word; */
    text-align: justify;
    /* grid-area: detail; */
    margin-top: 0.6em;
    color: #8C8CA1;
    overflow: hidden !important;
    /* margin-bottom: -0.3em; */
}

.feedback-sub-detail-text {
    width: 100%;
}

.feedback-rec-detail-text {
    height: 64px !important;
    
}

/* Image CSS */

.feedback_card_picture {
    grid-area: image;
    object-fit: cover;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 1367px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto auto auto;
    }
    .feedback_card_picture {
        width: 7em;
        height: 7em;
    }
}

@media screen and (max-width: 1071px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto auto;
    }
    .feedback_card_picture {
        width: 9em;
        height: 9em;
    }
}

@media screen and (max-width: 695px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto;
    }
    .feedback_card_picture {
        width: 9em;
        height: 9em;
    }
}


/* from / to / posted / kudos badge section container */


.feedback-sub-card-title {
    grid-area: matter;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    grid-gap: 0.6em;
    gap: 0.6em;
    padding: 1em 0em;
    margin-left: 0.5em;
}

.feedback-sub-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    word-wrap: break-word;
    font-size: 130%;
    /* width: 60%; */
    letter-spacing: -0.02em;
    margin-bottom: 0em;
    /* text-overflow: ellipsis !important;  */
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* received on section div and elements */

.received-on-section {
    align-items: center;
    margin-top: -0.25em;
    margin-bottom: 0;
}

.received-on-section p {
    margin-bottom: 0 !important;
}

.feedback-sub-kudos-image {
    /* align-self: flex-start; */
    margin-right: 2.98em;
    /* margin-left: auto; */
    /* display: inline-block; */
}

.received-on-text {
    /* height: 27%; */
    display: inline;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* font-size: 1.1em; */
    /* text-align: justify; */
    word-wrap: break-word;
    /* or 22px */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #000000;
}

.received-on-date {
    /* height: 27%; */
    color: #009AF1;
    font-family: 'Poppins';
    word-wrap: break-word;
    font-style: normal;
    font-weight: 500;
    /* font-size: 1.1em; */
}

/* p {
    margin-bottom: 0;
} */

/* from text */

.feedback-card-from-text {
    display: block;
    width: 44px;
    height: 15px;
    font-family: 'Poppins';
    margin-bottom: -0.7em;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */
    color: rgba(0, 0, 0, 0.5);
}

/* Kudos Badge Image CSS */

.feedback-sub-from-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback-image-title-cont {
    background: #FFFFFF;
    justify-content: space-between;
    align-items: center;
}

/* on applying width in below container there is issue getting created */
.feedback-image-title-cont {
    width: 100%;
    /* margin: 0 0.8em; */
    display: grid;
    grid-template-areas:
        'image matter matter matter matter'
        'image matter matter matter matter'
        'image matter matter matter matter';
}

.card-border-container {
    border: 1px solid #8C8CA1;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 2em;
    padding: 1em 1.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 350px;
}

/* css for the card elements div for */

.card-border-container:hover {
    border: 1px solid #4CCB1F;
    box-shadow: 0px 14px 14px 0px #00000021;
    -webkit-transform: scale(1.002);
            transform: scale(1.002);
}

.feedback-sub-main-btns {
    position: absolute;
    display: flex;
    grid-gap: 0.9em;
    gap: 0.9em;
    align-items: center;
    justify-content: space-between;
    right: 0.7em;
    top: 0.3em;
    z-index: 5;
    width: 15%;
    height: auto;
}

.feedback-sub-main-btns > img {
    width: 60%;
}

/* css for the date wise sorting of cards */

.feedback-date-sort-container {
    margin-left: 1.6em;
    /* margin-top: 1em; */
    /* margin-bottom: -1.5em; */
    /* min-height: min-content; */
}

/* .feedback-date-sort-container > button {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    color: #31D0AA;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
}

.feedback-date-sort-container > button:hover {
    background: #31D0AA;
    color: white;
} */

.clicked-date-btn {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
    background: #31D0AA;
    color: white;
}

.unclicked-date-btn {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    color: #31D0AA;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
}

/* css to adjust size of custom btn images */
.feedback-date-sort-container > img {
    width: 30px;
    height: 30px;
}

.feedback-image-title-cont {
        min-width: 0;
        max-width: auto;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
}

@media screen and (max-width: 415px) {
    .feedback-image-title-cont {
        width: 100%;
        min-height: auto;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
}
}

/* feedback edit and delete css */

.card-delete-btn-image {
    -webkit-filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
            filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
}

.card-delete-btn-image:hover {
    -webkit-filter: invert(28%) sepia(89%) saturate(7451%) hue-rotate(4deg) brightness(88%) contrast(124%);
            filter: invert(28%) sepia(89%) saturate(7451%) hue-rotate(4deg) brightness(88%) contrast(124%);
}

.card-edit-btn-image {
    -webkit-filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
            filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
    margin-top: 0.2em;
}

.card-edit-btn-image:hover {
    -webkit-filter: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(5911%) hue-rotate(245deg) brightness(87%) contrast(113%);
            filter: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(5911%) hue-rotate(245deg) brightness(87%) contrast(113%);
}

/* css media queries for mobile upto 320 width */

/* css media queries for mobile upto 416 to 782 width */
@media screen and (min-width: 416px) and (max-width: 770px) {
    .feedback-sub-cards-container {
        height: 912px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(-webkit-min-content, -webkit-max-content);
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        grid-gap: 2em;
        gap: 2em;
        padding: 0em 2em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 500px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .feedback-date-sort-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        min-height: auto;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        margin-bottom: 2em;
        overflow: scroll;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 75%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 80px;
        height: 80px;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        grid-gap: 0.6em;
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 17%;
        font-size: 0.7em;

    }
    .received-on-text {
        height: 17%;
        font-size: 0.7em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 17%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
    }
    .feedback-date-sort-container {
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: -webkit-max-content;
        width: max-content;
        /* margin-bottom: 2em; */
        margin-bottom: -2em;
        margin-top: 1.5em;

    }
}

/* css media queries for mobile upto 415 width */
@media screen and (max-width: 415px) {
    .feedback-sub-cards-container {
        height: 912px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(-webkit-min-content, -webkit-max-content);
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        grid-gap: 2em;
        gap: 2em;
        padding: 0em 0.5em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 500px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .feedback-date-sort-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        min-height: auto;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        margin-bottom: 2em;
        overflow: scroll;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 95%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 80px;
        height: 80px;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        grid-gap: 0.6em;
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 17%;
        font-size: 0.7em;

    }
    .received-on-text {
        height: 17%;
        font-size: 0.7em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 17%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
    }
    .feedback-date-sort-container {
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: -webkit-max-content;
        width: max-content;
        /* margin-bottom: 2em; */
        margin-bottom: -2em;
        margin-top: 1.5em;

    }
}

@media screen and (max-width: 320px) {
    .feedback-sub-cards-container {
        height: 600px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(-webkit-min-content, -webkit-max-content);
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        grid-gap: 2em;
        gap: 2em;
        padding: 0em 0.4em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 500px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .feedback-date-sort-container {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        min-height: auto;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 75%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 65px;
        height: 65px;
        margin-left: -0.3em;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        grid-gap: 0.6em;
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 12%;
        font-size: 0.6em;

    }
    .received-on-text {
        height: 12%;
        font-size: 0.6em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 12%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
        width: 31px;
        height: 31px;
    border-radius: 50% !important;

    }

    .feedback-date-sort-container {
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: -webkit-max-content;
        width: max-content;
        margin-bottom: -2em;
        margin-top: 1.5em;
    }
}

/* css media queries for mobile upto 416 to 782 width */



@media screen and (max-width: 321px) {
    .feedback-date-sort-container {
        width: 100%;
    }
}

.feedback-date-sort-card-icon {
margin-left: 0.24em;
margin-bottom: 0.29em;
}

.feedback-date-sort-card-icon:hover {
    fill: red;
}

/* css for custom date btn */

.active-icon {
    color: white;
}

.inactive-icon {
    color: #31D0AA;
}

/* load more btn css */

.load-more-title-feedback{
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #303030;
    margin-top: -1em;
    margin-bottom: 1.5em;
}
.load-more-title-feedback:hover{
    color:#4CCB1F;
}

.center-item {
    grid-column: 1 / -1;
}

@media screen and (max-width:780px) {
    .adjust-margin-feedback{
        margin-top: 30px;
    }
}

/* feedback action list css */

.feedback-action-list-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.action-card-main-container{
    padding: 1em;
    width: 33%;  
}

.action-card-border-container {
    border: 1px solid #8C8CA1;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 2em;
    padding: 1em 1.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
}

.column-section-action-reminder{
    display: flex;
    flex-direction: column;
    grid-gap: 0.25em;
    gap: 0.25em;
    overflow: hidden;
}

.column-section-action-reminder h1, .column-section-action-reminder h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedback-action-dp{
    width: 4.5em;
    height: 4.5em;
    min-width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
}

.feedback-action-dp img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width:1300px) {
    .action-card-main-container{
        width: 50%;
    }
}

@media screen and (max-width:960px) {
    .action-card-main-container{
        width: 100%;
    }
}
.tabpane-feedback-view {
    color: red;
}

.tabpane-feedback-view:hover {
    color: black;
    background: black;
}

.add-new-feed-btn{
    width: 25em !important;
}

.feedback-search-container{
    width: 100%;
    display: flex;
    justify-content: end;
}

.feedback-actions-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

.dummy-feedback-div{
    width: 100%;
    /* max-width: 95%; */
    height: 46px;
}

.feedback-search-bar {
    /* display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -2em;
    margin-top: 2em;
    position: absolute;
    right: 66px;
    top: 163px; */
    z-index: 100;
}

.feedback-tabpane .ant-tabs-tab {
    padding: 6px;
}

.search-input-bars:hover {
    border: 1px solid #4CCB1F;
}

.feedback-drop-dtn:hover {
    background: #FFFFFF;
    border: 1px solid #4CCB1F
}

.feedback-drop-dtn:hover+div div div .search-bar-feedback {
    border-right: none;
}

.feedback-drop-dtn:focus {
    background: #FFFFFF !important;
    border: 1px solid #4CCB1F;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

.search-bar-feedback {
    border: 1px solid #d9d9d9 !important;
    /* .ant-input:focus, .ant-input-focused */
}

.search-bar-feedback:hover {
    border-color: #4CCB1F !important;
}

/* css media queries for mobile upto 415 width */
@media screen and (max-width: 415px) {
    /* .feedback-search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 2em;
    } */

    .feedback-tab-bar {
        margin-top: 3em;
    }

    .feedback-tab-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

/* css media queries for mobile upto 320 width */

@media screen and (max-width: 320px) {

    .feedback-tab-bar {
        margin-top: 3em;
    }
}

/* css media queries for tabs from size 416px to 770px */
@media screen and (min-width: 416px) and (max-width: 770px) {

    .feedback-tab-bar {
        margin-top: 3em;
    }
}

/* css above 770px */

@media screen and (max-width: 900px) {
    .dummy-feedback-div{
        display: none;
    }

    .add-new-feed-btn{
        width: 10em !important;
        max-width: 100%;
    }

    .feedback-actions-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .feedback-search-container{
        justify-content: center;
    }
}

.feedback-tabpane .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4CCB1F;
    font-weight: 700;
    outline: none;
}

.feedback-tabpane .ant-tabs-tab-btn:hover {
    color: #4CCB1F;
}

.feedback-tabpane .ant-tabs-tab:hover {
    color: #4CCB1F;
}

.feedback-tabpane .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    color: #4CCB1F;
    background-color: #4CCB1F;
    border-bottom: 2px solid #4ccb1e !important;
}

.feedback-tabpane .ant-tabs {
    color: #BFBFBF;
    font-weight: 700;
}

.feedback-tabpane {
    z-index: 0;
    /* margin: 0 2.5em 0 2.5em; */
}


.give_feedback_btna {
    background-color: #4CCB1F;
    width: 170px;
    height: 42px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
}

.give_feedback_btna {
    background-color: #4CCB1F;
    border: solid #4CCB1F;
    -webkit-border: solid #4CCB1F;
    border-radius: 5px;
}

.give_feedback_btna:hover {
    background: #39DD00;
    background-color: #39DD00;
    -webkit-background: #39DD00;
    border: solid #39DD00;
    -webkit-border: solid #39DD00;
}

.one-one-filter-menu-feedback .ant-dropdown-menu-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #141414;
    padding-bottom: 10px;
}

.one-one-filter-menu-feedback .ant-dropdown-menu-item {
    font-size: 14px;
}

/* filter dropdown button css */
.feedback-input-filter-one-on-one {
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    height: 46px !important;
    width: 126px !important;
    border-right: none;
}

/* filter input field css */
.feedback-search-input-filter {
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 35px;
    width: 100%;
    height: 46px;
    font-size: 16px;
    border-right: none;
}

/* search selector button */

.feedback-one-one-filter-menu .ant-dropdown-menu-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #BBBBBB;
    padding-bottom: 10px;
}

.feedback-one-one-filter-menu .ant-dropdown-menu-item-active {
    color: #141414;
}

@media (max-width:1600px) {

    .feedback-one-one-filter-menu .ant-dropdown-menu-item {
        font-size: 14px;
    }

}
.ai-tip-fixed-container {
    position: fixed;
    bottom: 20px;
    z-index: 1500;
    left: 20px;
    right: 20px;
}

.ai-tip-relative-container {
    width: 100%;
    position: relative;
}

.ai-tips-icon {
    width: 100px;
    height: 100px;
    cursor: grab !important;
    position: absolute;
    bottom: 0px;
}

.tip-hide-mode {
    width: 450px;
    max-width: 90dvw;
    height: 0px;
    overflow: hidden;
    background-color: #fff;
    transition-duration: 0.5s;
    border-radius: 12px;
    position: absolute;
    bottom: 90px;
}


.tip-view-mode {
    width: 450px;
    max-width: 90dvw;
    height: calc(80dvh - 80px);
    transition-duration: 0.5s;
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
    position: absolute;
    bottom: 90px;
}

.tips-view-container {
    height: 100%;
    width: 100%;
    padding: .5em 1em;
}

.tip-header-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
}

.ai-tip-header-title {
    margin: 0px;
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.75em;
    text-align: left;
    color: rgba(76, 203, 31, 1);
}

.ai-tip-list {
    height: calc(80dvh - 220px);
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    overflow: auto;
    padding-right: 0.5em;
}

.ai-tip-list::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.ai-tip-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.ai-tip-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}



.border-sepeartion {
    height: 16px;
    width: 1px;
    border: 1px solid rgba(170, 186, 198, 1)
}

.ai-tip-card {
    background: rgba(246, 251, 255, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    justify-content: space-between;
    padding: 1em;
}

.ai-tip-card h3 {
    font-family: Poppins;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
}

.ai-tip-card h4 {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default
}

.bottom-center {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
}

.bottom-left {
    left: 0px;
}

.bottom-right {
    right: 0px;
}

.tip-list-root {
    font-size: 16px;
    width: 100%;
    height: calc(100vh - 200px);
    background-color: #fff;
    border-radius: 1em;
    padding: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;

}

.tip-list-root::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.tip-list-root::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.tip-list-root::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.list-ai-card-container {
    padding: 1em;
    width: 33%;
}

.list-ai-tip-card {
    background: rgba(246, 251, 255, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    /* justify-content: space-between; */
    padding: 1em;
}

.list-ai-tip-card h3 {
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
    margin: 0px;
}

.list-ai-tip-card h4 {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tip-module-select,
.tip-module-select:hover,
.tip-module-select:focus {
    border: 1px solid rgba(170, 186, 198, 1);
    border-radius: 8px;
    overflow: hidden;
}

.tip-bookmark-btn-filter,
.tip-bookmark-btn-filter:hover,
.tip-bookmark-btn-filter:focus {
    border: 1px solid rgba(170, 186, 198, 1);
    color: rgba(137, 152, 162, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.tip-bookmark-active {
    border: 1px solid #4ccb1f !important;
    color: #fff !important;
    background: #4ccb1f !important
}

.tip-date-picker {
    border: 1px solid rgba(170, 186, 198, 1);
    color: rgba(137, 152, 162, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.show-preview-tip {
    position: fixed;
    right: 20px;
    width: 450px;
    background-color: #fff;
    box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
    z-index: 2000;
    top: 100px;
    padding: 16px;
    transition-duration: .5s;
    border-radius: 12px;
}

.hide-preview-tip {
    position: fixed;
    right: 10px;
    width: 0px;
    top: 100px;
    overflow: hidden;
    transition-duration: .5s;
    background-color: #fff;
    z-index: 2000;
}

.tip-preview-content {
    width: 100%;
    max-height: 180px;
    overflow: auto;
    padding-right: 0.5em;
}

.tip-preview-content::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.tip-preview-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.tip-preview-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.ai-tip-loader-container {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 
 
@media (max-width:1200px) {

    .list-ai-card-container {
        padding: 1em;
        width: 50%;
    }
}

@media (max-width:760px) {

    .list-ai-card-container {
        padding: 1em;
        width: 100%;
    }

    .bookmark-container {
        flex-wrap: wrap;
    }
}
.bottom-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.bottom-left {
  left: 0px;
}

.bottom-right {
  right: 0px;
}

.global-buttons-icon {
  width: 52px;
  height: 52px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(100, 100, 111, 0.3));
          filter: drop-shadow(0px 0px 10px rgba(100, 100, 111, 0.3));
}

.ai-global-button-container {
  height: 100px;
  width: 110px;
  cursor: grab !important;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  margin: 0px 44px 23px 25px;
  z-index: 10000;
}

.ai-global-button-container.half-width {
  width: 55px !important;
  justify-content: end;
}

.casper-info-popup-container {
  max-width: 350px;
  position: fixed;
  right: -370px;
  bottom: 20vh;
  background-color: #fff;
  border-radius: 20px;
  margin-right: 20px;
  overflow: hidden;
  transition: right 0.5s ease-out;
}

.casper-info-popup-container.visible {
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.casper-info-popup-container .casper-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.casper-info-popup-container .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.casper-info-popup-container .popup-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.casper-info-popup-container .image-icon {
  width: 25px;
  margin-right: 20px;
}

.casper-info-popup-container .title {
  font-size: large;
  font-weight: 700;
  font-family: poppins;
}

.casper-info-popup-container .popup-content .text-content {
  font-weight: 500;
}

.casper-fixed-container {
  position: fixed;
  bottom: 20px;
  z-index: 1500;
  left: 20px;
  right: 20px;
}

.casper-relative-container {
  width: 100%;
  position: relative;
}

.casper-icon {
  width: 100px;
  height: 100px;
  cursor: grab !important;
  position: absolute;
  bottom: 0px;
}

.casper-hide-mode {
  width: 450px;
  max-width: 90dvw;
  max-height: 500px;
  height: 0px;
  overflow: hidden;
  background-color: #fff;
  transition-duration: 0.5s;
  border-radius: 12px;
  position: absolute;
  bottom: 90px;
}

.casper-view-mode {
  width: 450px;
  max-width: 90dvw;
  max-height: 500px;
  height: calc(80dvh - 110px);
  transition-duration: 0.5s;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
  position: absolute;
  bottom: 90px;
}

.casper-view-container {
  height: 100%;
  width: 100%;
  padding: 0.5em 1em;
}

.casper-header-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 4px;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}

.casper-header-title {
  margin: 0px;
  font-family: Poppins;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.75em;
  text-align: left;
  color: rgba(76, 203, 31, 1);
}

.casper-list {
  width: 100%;
  height: calc(100% - 120px); /*need to change 165px*/
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  overflow: auto;
  padding-right: 0.5em;
  padding-bottom: 4px;
}

.empty-casper-body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: 700;
}

.casper-list.dynamic-width {
  height: calc(100% - 165px) !important; /*need to change 165px*/
}

.casper-list::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.border-sepeartion {
  height: 16px;
  width: 1px;
  border: 1px solid rgba(170, 186, 198, 1);
}

.casper-card {
  background: rgba(246, 251, 255, 1);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  justify-content: space-between;
  padding: 1.5em;
}

.casper-card h3 {
  font-family: Poppins;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
}

.casper-card h4 {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
}

.bottom-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.bottom-left {
  left: 0px;
}

.bottom-right {
  right: 0px;
}

.casper-list-root {
  font-size: 16px;
  width: 100%;
  height: calc(100vh - 200px);
  background-color: #fff;
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
}

.casper-list-root::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-list-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-list-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.list-ai-card-container {
  padding: 1em;
  width: 33%;
}

.list-casper-card {
  background: rgba(246, 251, 255, 1);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  /* justify-content: space-between; */
  padding: 1em;
}

.list-casper-card h3 {
  font-family: Poppins;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
  margin: 0px;
}

.list-casper-card h4 {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.75em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  cursor: default;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.casper-module-select,
.casper-module-select:hover,
.casper-module-select:focus {
  border: 1px solid rgba(170, 186, 198, 1);
  border-radius: 8px;
  overflow: hidden;
}

.casper-bookmark-btn-filter,
.casper-bookmark-btn-filter:hover,
.casper-bookmark-btn-filter:focus {
  border: 1px solid rgba(170, 186, 198, 1);
  color: rgba(137, 152, 162, 1);
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.casper-bookmark-active {
  border: 1px solid #4ccb1f !important;
  color: #fff !important;
  background: #4ccb1f !important;
}

.casper-date-picker {
  border: 1px solid rgba(170, 186, 198, 1);
  color: rgba(137, 152, 162, 1);
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.show-preview-casper {
  position: fixed;
  right: 20px;
  width: 450px;
  background-color: #fff;
  box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
  z-index: 2000;
  top: 100px;
  padding: 16px;
  transition-duration: 0.5s;
  border-radius: 12px;
}

.hide-preview-casper {
  position: fixed;
  right: 10px;
  width: 0px;
  top: 100px;
  overflow: hidden;
  transition-duration: 0.5s;
  background-color: #fff;
  z-index: 2000;
}

.casper-preview-content {
  width: 100%;
  max-height: 180px;
  overflow: auto;
  padding-right: 0.5em;
}

.casper-preview-content::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.casper-preview-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.casper-preview-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b1b0b0e1;
}

.casper-loader-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.casper-footer-section {
  display: flex;
  align-items: center;
  height: 60px; /* need to change: 105px*/
}

.casper-footer-section.dynamic-width {
  height: 105px !important;
}

.casper-footer-section .capser-message-input {
  height: 40px;
  border-radius: 100px;
  background-color: #eaedff;
  padding: 0px 15px;
  color: #a93ad5;
}

.casper-footer-section .capser-message-input-with-reply {
  height: 40px;
  border-radius: 25px;
  background-color: #eaedff;
  padding: 0px 15px;
  color: #a93ad5;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: unset;
}

.casper-footer-section .input-message-container {
  margin: 0px 10px;
  background-color: unset;
  width: 100%;
}

.input-message-container.dynamic-width {
  max-width: calc(100% - 100px);
}

.casper-footer-section .reply-message-container {
  height: 50px;
  background-color: #eaedff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid #d9d9d9;
  padding: 0px 15px;
  border-bottom: unset;
  display: flex;
  align-items: end;
}

.reply-message-container .reply-message {
  padding: 10px 20px;
  background-color: #cf94e9;
  border-radius: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.reply-message-container .reply-message.dynamic-color {
  background-color: #b6a0f3;
}

.user-msg-container {
  width: 100%;
  display: flex;
  justify-content: end;
}

.reply-msg-container {
  width: 100%;
  display: flex;
  justify-content: start;
}

.message-content {
  max-width: calc(80% - 6px);
  padding: 10px 20px;
  border-radius: 1em;
  margin-bottom: 10px;
}

.user-msg-container .error-msg{
  font-size: smaller;
  line-height: 0px;
  position: relative;
  top: 20px;
  color: red;
  right: -20px;
  display: flex;
  justify-content: end;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.user-msg-container .message-content {
  background-color: #dfe6ff;
  margin-right: 10px;
}

.reply-msg-container .message-content {
  background-color: #cf93e9;
  margin-left: 10px;
}

.message-content .reply-button {
  width: 100%;
  display: flex;
  justify-content: end;
  height: 0px;
  position: relative;
  right: -32px;
  bottom: -3px;
}

.message-content.generating-response {
  margin: 0px 0px 0px 10px;
  background-color: unset ;
  padding: unset;
}

.message-content .reply-button svg {
  margin: 10px;
  cursor: pointer;
}

.user-msg-container .reply-to-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #cf94e9;
  padding: 10px 20px;
  border-radius: 0.8em;
  margin-bottom: 10px;
  width: 100%;
}

.user-msg-container .reply-to-message.dynamic-color {
  background-color: #b6a0f3;
}

.casper-profile {
  display: flex;
  width: 34px !important;
  height: 34px !important;
  font-weight: 700;
  font-size: 13px;
  color: #0031b1;
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  text-transform: uppercase;
  background-color: #eaedff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px -5px;
}

.casper-setting-overlay {
  top: var(--top-pos) !important;
  width: var(--width-px);
  left: unset !important;
  position: fixed !important;
  right: 45px;
  max-width: 80dvw;
}

.casper-section-container {
  background-color: #dfe6ff;
}

.casper-action-container {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: 500;
}

.casper-action-switch.ant-switch-checked {
  background-color: #f6fbff !important;
  border: 1px solid #783de2;
}

.casper-action-switch .ant-switch-handle {
  width: 16px;
  height: 16px;
}

.casper-action-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #783de2 !important;
}

.casper-action-switch.ant-switch {
  background-color: #f6fbff !important;
  border: 1px solid #783de2;
}

.casper-action-switch.ant-switch .ant-switch-handle::before {
  background-color: #783de2 !important;
}

.casper-action-switch.ant-switch:hover {
  border-color: #783de2 !important;
}

@media (max-width: 1200px) {
  .list-ai-card-container {
    padding: 1em;
    width: 50%;
  }
}

@media (max-width: 760px) {
  .list-ai-card-container {
    padding: 1em;
    width: 100%;
  }

  .bookmark-container {
    flex-wrap: wrap;
  }
}

::-webkit-scrollbar {
display: none;
  }
  
/* ::-webkit-scrollbar-thumb {
    background: rgb(196, 188, 188);
    height: 50px;
  } */

  /* .new_feedback_home {
    scrollbar-color: red yellow;
  } */

.feedback-home-image {
width: 60%;
height: auto;
margin-top: 5em;
margin-left: auto;
margin-right: auto;
z-index: 1;
}

.feedback-home-btn-cont {
    margin-left: auto;
    margin-right: auto;
}

.feedback-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-left-wireframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    z-index: 0;
}

.feedback-right-wireframe {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    z-index: 0;
}

.feedback-view-btn:hover {
border-color: #3AE100;
    background-color: #3AE100;
    transition: background-color 250ms linear;
}

.feedback-view-btn {
    margin: '0 1.5em'; 
    border: 1.5px solid;
    border-color: #4CCB1F; 
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-give-btn {
    background-color: #4CCB1F;
    border: solid #4CCB1F;
    border-radius: 5px;
    cursor: pointer;
}

.feedback-give-btn:hover {
    background-color: #3AE100;
    transition: background-color 250ms linear;
    border: solid #3AE100;
}

/* CSS FOR HANDLING GRID FOR MOBILE VIEW ADJUSTMENTS */

.feedback-btn-container {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
    gap: 2em;
}

@media screen and (max-width: 415px) {
    .feedback-btn-container {
        margin: 1em auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
        gap: 1em;
    }
    .feedback-home-image {
        width: 78%;
    margin-top: 2em;
        }
}

.mapp-review-root{
    font-size: 16px;
    font-family: 'Poppins';
}
.mapp-feedback-review-popup .ant-modal-content{
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 1);
}
.mapp-review-container{
    padding: 2em;
    display:flex;
    flex-direction: column;
    justify-content: start;
    grid-gap:1em;
    gap:1em;
    align-items: start;
}
.mapp-review-title{
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    color:rgba(23, 15, 73, 1);
    margin: 0px;
}
.mapp-review-comment-input,.mapp-review-comment-input:focus,.mapp-review-comment-input:hover{
    width: 100%;
    resize: none;
    border-radius: 1em;
    border: 1px solid rgba(239, 240, 246, 1);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    outline: none;
    margin-top: 1em;
    padding: .5em;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 400;
}
.mapp-review-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    margin-top: 1em;
}
.mapp-review-star-container{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    grid-gap: 4em;
    gap: 4em;
    width: 100%;
}
.mapp-review-submit-btn,.mapp-review-submit-btn:hover,.mapp-review-submit-btn:focus {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2em;
    height: 3.5em !important;
    border-radius: 2em;
    text-align: center;
    padding: .5em 2em;
    border: none;
    box-shadow: 0px 8px 22px 0px rgba(74, 58, 255, 0.26);
    outline: none;
    cursor: pointer;
}
.mapp-review-skip-btn,.mapp-review-skip-btn:focus,.mapp-review-skip-btn:hover {
    background: rgba(116, 116, 128, 0.08);
    font-family: 'Poppins';
    font-weight: 600;
    font-size: .85em;
    color: rgba(0, 0, 0, 1);
    border-radius: 1em;
    padding: .5em 2em;
    border: none;
    outline: none;
    cursor: pointer;
}
.review-popcon-yes-btn,.review-popcon-yes-btn:hover,.review-popcon-yes-btn:focus {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    height: 2.5em !important;
    border-radius: 1.5em;
    text-align: center;
    padding: 0em 2em;
    border: none;
    box-shadow: 0px 8px 22px 0px rgba(74, 58, 255, 0.26);
    outline: none;
    cursor: pointer;
}
.review-popcon-no-btn,.review-popcon-no-btn:hover,.review-popcon-no-btn:focus {
    background: rgba(116, 116, 128, 0.08);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    color: rgba(0, 0, 0, 1);
    border-radius: 1.5em;
    padding: 0em 2em;
    height: 2.5em !important;
    border: none;
    outline: none;
    cursor: pointer;
}
.review-popcon{
    margin-top: 30px !important;
}
.review-popcon .ant-popover-content{
    margin-top: 80px !important;
}
.review-popcon .ant-popover-buttons {
    display: flex;
    justify-content: center;
}

.user-badge-root-class{
        font-size: 16px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
}
.user-badge-award-popup-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.5em;
    line-height: 2.5em;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: center;
    margin: 0;
}
.user-badge-award-popup-input-div{
    width:20.688em;
    height: 40px;
}
.dashboard-user-badge-award-popup-input-div{
  width:100%;
  height: 40px;
}
.search-input-user-kudos{
    height: 40px;
    border: 1px solid #8F8D8D;
    border-radius: 0px 8px 8px 0px;
}
.dashboard-search-input-user-kudos{
  height: 38px !important;
  width: 100%;
}
.search-input-user-kudos input:focus{
  box-shadow: none !important;
}
.dashboard-search-input-user-kudos input:focus{
  box-shadow: none !important;
}
.filter-select-user-select .ant-select-selector{
  height: 40px !important;
  border: none;
  padding-top: 3px !important;
}
.dasboard-filter-select-user-select .ant-select-selector{
  height: 38px !important;
  border:none !important;
  padding-top: 3px !important;
  margin-left: 1px !important;
}
.filter-select-user-select .ant-select-selector:focus{
  box-shadow: none !important;
}
.dasboard-filter-select-user-select .ant-select-selector:focus{
  box-shadow: none !important;
}
.filter-select-user-select .ant-select-selector input{
  height: 40px !important;
}
.dasboard-filter-select-user-select .ant-select-selector input{
  height: 38px !important;
}
.filter-select-user-select .ant-select-selector input:focus{
  box-shadow: none !important;
}
.dasboard-filter-select-user-select .ant-select-selector input:focus{
  box-shadow: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  color:#4CCB1F !important;
  font-weight: normal !important;
  background-color: #fff !important;
  font-size: 1em !important;
}
.user-badge-award-popup-input{
    width:22.688em;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #8C8CA2;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 500;
    padding-left: 40px;
}
.user-badge-award-carosul-container{
    border: 1px solid #000000;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 14px;
    width:100% !important;
    max-width: 70.5em;
    height: 17em !important;
    padding: 0px 1em;
    min-width: 50em;
}
.user-badge-award-carosul-container-loading{
  border: 1px solid #000000;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 14px;
  width:100%;
  min-width: 50em;
  max-width: 70.5em;
  height: 17em !important;
  padding: 0px 1em;
}
.feedback-badge-award-carosul-container-loading{
  height: 10em !important;
}
.feedback-badge-award-carosul-height{
  max-width: 70.5em;
  height: 13em !important;
}
.dasboard-badge-award-carosul-container{
  border-top: 1px solid #8F8D8D;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 414px!important;
    height: 10em !important;
    padding: 0px 1em;
    border-top: none !important;
}
.dasboard-badge-award-carosul-container .ant-spin-nested-loading{
  width: 100% !important;
}
.dasboard-badge-award-carosul-container-loading{
  border-top: 1px solid #8F8D8D;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 414px!important;
    height: 10em !important;
    padding: 0px 1em;
    border-top: none !important;
}
.dasboard-badge-award-carosul-container .slick-next{
  right: 0px !important;
}
.dasboard-badge-award-carosul-container .slick-prev{
  left: -4px !important;
}

.user-badge-award-carosul-container .slick-next{
  right: 10px !important;
}
.user-badge-award-carosul-container .slick-prev{
  left: 10px !important;
}
.user-award-badge-image{
    width:200px !important;
    height: 210px !important;
    margin-top: 15px;
    padding: 10px;
    cursor: pointer;
}

.user-award-badge-image-selected{
    width:200px !important;
    height: 210px !important;
    margin-top: 15px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:160px !important;
  height: 170px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:160px !important;
  height: 170px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
.dashboard-user-award-badge-image-selected-container{
  height:8.5em;
  width:7.5em !important;
  border-radius: 8px;
  border: 1px solid #AABAC633;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  margin-top: 1em;
  cursor: pointer;
}
.dashboard-show-kudos-detail-card{
  display: none;
}
.dashboard-selected-kudos-card{
  border-radius: 8px;
  border: 1px solid #AABAC633;
  width: 93%;
  padding: .5em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 9em;
  margin: .5em 1em;
}
.dashboard-user-award-badge-image-selected-container:hover{
  border: 1px solid #4CCB1F;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-show-kudos-detail-card{
  display: block;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected{
  display: none;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected-title{
  display: none;
}
.dashboard-user-award-badge-image-selected-container-selected{
  border: 2px solid #4CCB1F;
}
.dashboard-user-award-badge-image-selected{
  height: 5em !important;
  width: 5em!important;
  cursor: pointer;
  object-fit: contain;
  align-items: center;
  margin: .5em 0em;
}
.dashboard-user-award-badge-image-selected-title{
  font-family: 'Poppins';
  font-size: .65em;
  font-weight: 400;
  font-size: normal;
  font-style: normal;
  text-align: center;
  border-top: 1px solid #AABAC633;
  padding-top: 6px;
}
.dashboard-user-award-badge-image-selected-detail{
  font-family: 'Poppins';
  font-size: .65em;
  font-weight: 400;
  font-size: normal;
  font-style: normal;
  text-align: center;
  border-top: 1px solid #AABAC633;
  padding-top: 6px;
  max-height: 8em;
  text-overflow: ellipsis;
}
.dashboard-details-elipsis{
  display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 3; 
            overflow: hidden; 
            text-overflow: ellipsis; 
}
.user-badge-award-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    line-height: 2.5em;
    color: #000000;
    text-align: left;
    display: flex;
    align-items: start;
    margin: 0;
}
.user-badge-award-selected-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2em;
    line-height: 2.5em;
    color: #4CCB1F;
    text-align: left;
    margin: 0;
}
.user-badge-award-selected-description-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 1.65em;
    color: #8C8CA2;
    text-align: justify;
    margin: 0;
    word-break: break-all;
}
.user-badge-award-carosul-second-container{
    width:100%;
    min-width:100% !important;
    max-width: 70.5em;
}
  
.user-badge-award-carosul-second-section-image-container{
    width:190px;
    height: 200px;
    align-items: center;
}
.feedback-badge-award-carosul-second-section-image-container{
  width:140px;
  height: 150px;
  align-items: center;
}
.user-badge-award-carosul-second-section-image{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: fill;
}
.user-badge-award-popup-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .user-badge-award-popup-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .user-badge-award-popup-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }
  .user-awards-view-container{
    width:100%;
    max-height: 17em;
    background: rgba(217, 217, 217, 0.04);
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.14);
    text-align: center;
    padding: 10px 20px;

  }
  .user-awards-view-header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.5em;
    line-height: 1em;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #4CCB1F;
    margin: 0px;
    padding-bottom: 10px;
  }
  .user-awards-view-slider {
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
    /* cursor: grab; */
  }
  
  .user-awards-view-slider::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  
  .user-awards-view-slider::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 0px;
    margin: 0px;
  }
  
  .user-awards-view-slider::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #eeeded;
  }
  .user-awards-view-image{
    width: 10em;
    height: 10em;
    border: 2px solid rgba(217, 217, 217, 0.04);
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
  }
  .user-awards-view-image:hover{
    border: 2px solid #9CD600;
  }
  .recived-award-image-container{
    width: 20.25em;
    height: 19.875em;
    background: #FFFFFF;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1.25em 1.25em;
    cursor: pointer;
    margin-top: 1em;
  }
  .recived-award-image-container:hover{
    background: #FFFFFF;
    border: 2px solid #9CD600;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .recived-award-image-container:hover .award-delete-icon{
    left:25px !important;
    top:15px !important;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:50px !important;
    top:50px !important; */
  }
  .recived-award-image-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
    margin-top: 10px;
  }
  .recived-award-image-container:hover .recived-award-image-title{
    color: #4CCB1F;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #B0CB1F;
    border-color: #B0CB1F;
}
  .issued-award-image-container{
    width: 20em;
    height: 20.125em;
    background: #FFFFFF;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1.25em 1.25em;
    margin-top: 1em;
  }
  .issued-award-image-container:hover{
    background: #FFFFFF;
    border: 2px solid #9CD600;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .recived-award-image{
    max-width: 75%;
    max-height: 73%;
    object-fit: contain;
    object-position: center;
  }
  .uer-profile-award-main-card-container{
    width: 100%;
    padding: 1em 2em;
    min-height: 60vh;
    }
  .uer-profile-award-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap:1.5em;
    gap:1.5em;
    margin: 0px 3.5em;
}
.uer-profile-award-vertical-container{
    width: 25% !important;
    padding: 0em .5em .5em .5em;
    display: flex;
    flex-direction: column;
    /* position: relative; */
}
.user-profile-award-detail-left-container{
  /* max-width: 25%; */
  width: 14em;
}
.user-profile-award-detail-right-container{
  /* width: 75%; */
  padding: 1em;
}
.user-profile-award-detail-right-container-inner{
  margin-left: 2em;
 
}
.user-badge-award-popup-inner-container{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
}
.user-badge-award-detail-modal{
  min-width: 62.313em;
  max-width: 62.313em;
}
.user-badge-award-share-modal{
  min-width: 62.313em;
  max-width: 62.313em;
}
.user-badge-award-share-modal .ant-modal-content{
  background: url("/static/media/share modal-bg.434cf371.png");
  background-size:100%;
  background-repeat: no-repeat;
  background-position: center;
  height:40em;
  border-radius: 8px;
}
.recent-badge-modal .ant-modal-content{
  background-image: url(/static/media/surprise_happy_bday_instagram.d8416b5b.gif) !important;
  background-size:100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  min-width: 700px;
}
.recent-modal-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 3.25em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #539300;
  margin: 0;
}
.recent-modal-sub-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 3em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin: 0;
  color: #080800;
}
.recent-modal-image{
  width:17.266em;
  height: 15.938em;
  object-fit: contain;
}
.social-icon{
  width:6.25em !important;
  height: 6.25em !important;
}
.user-badge-award-share-div{
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  /* padding: 10px; */
  cursor: pointer;
}
.user-badge-award-share-div:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.user-profile-issued-employee-name{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
  padding: 3px 5px;
  border:none !important;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.user-profile-issued-employee-name-selected{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
  border: 2px solid #4CCB1F !important;
  padding: 3px 5px;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.issued-detail-nav-button{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 3em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 0;
}
.kudos-user-home-select-option{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.7em;
  color: #000000;
}
.user-badge-drop{
  background-color: rgba(246, 246, 246, 0.62) !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
  height: 40px !important;
}
.user-badge-drop-active{
  background-color: #fff !important;
  border: 1px solid #9CD600 !important;
  border-radius: 8px !important;
  height: 40px !important;
}
.user-badge-select .ant-select-selector{
  background-color: rgba(246, 246, 246, 0.62) !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
}
.user-badge-select-active .ant-select-selector{
  background-color: #fff !important;
  border: 1px solid #9CD600 !important;
  border-radius: 8px !important;
}
.user-badge-select .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  color: rgb(194, 194, 194) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-top: 4px !important;
}
.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  display: block !important;
  padding-top: 4px !important;
  white-space: nowrap;
}
.ant-select-dropdown {
  padding: 0px 0px !important;
  }

.kudos-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.kudos-tab .ant-tabs-tab{
  /* padding: 5px 0px !important; */
}
.kudos-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 1.75em !important;
}
.give-kudos-btn{
  width: 9.375em;
  height: 3em;
  background: #4CCB1F;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  border:none;
}
.give-kudos-btn:hover{
  background: #4CCB1F;
  color: #FFFFFF;
  border:none;
}
.give-kudos-btn:focus{
  background: #4CCB1F;
  color: #FFFFFF;
  border:none;
}
.badge-check span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.66);
}
.badge-check + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.give-kudos-form-section{
  background: rgba(246, 246, 246, 0.62) !important;
  border-radius: 8px;
  padding: 1em 1.313em;
}
.give-kudos-save-btn[disabled]{
  color: #FFFFFF !important;
  background: #8C8CA2 !important;
  border-color: #8C8CA2 !important;
}
.give-kudos-save-btn[disabled]:hover{
  color: #FFFFFF !important;
  background: #8C8CA2 !important;
  border-color: #8C8CA2 !important;
}
.share-kudos-button{
  font-size: 1.25em !important;
}

.badge-popover .ant-popover-inner{
  background: #F1F1F1 !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21) !important;
  border-radius: 8px !important;
  margin-top:-15px !important;
}
.badge-popover .ant-popover-arrow{
  display: none !important;
}
.filter-badge-family-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  white-space: nowrap;
  color: #000000;
}
.filter-badge-family-para{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.65em;
  color: rgba(0, 0, 0, 0.7);
}
.card-group {
  display: flex;
  width:100%
}

  /* .slick-slider {
    display: flex !important;
  }

.slick-list {
  display: flex !important;
  align-items: flex-start !important;
}

.slick-slide {
  display: flex !important;
  justify-content: flex-start !important;
} */
.user-badge-award-carosul-container .slick-track{
  display: flex !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  width: 100% !important;
}

.radio-button-section .ant-radio-checked .ant-radio-inner{
  border-color:#B0CB1F !important ;
  border: 6px solid #B0CB1F !important;
  border-radius: 50% !important;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:after{
  background-color: #fff;
  width: 0px !important;
  height: 0px !important;
}

.radio-button-section .ant-radio:hover .ant-radio-inner {
  border-color: #B0CB1F ;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:focus{
  border-color: #B0CB1F;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:hover{
  border-color: #B0CB1F;
}
.radio-button-section .ant-radio-group .ant-radio-wrapper{
  font-size: 15px !important;
  font-weight: 500 !important;
}
.user-given-badge-popup-container{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap:1em;
  gap:1em;
  width:100%;
  margin-top: 30px;
}
.given-kudos-right-container{
  max-width:35%;
}
.kudos-okr-selection-drop{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  max-width: 500px !important;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #595959;
}
@media (max-width:1600px) {
  .recent-badge-modal .ant-modal-content{
    min-width: 100%;
  }
  .issued-award-image-container{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container:hover{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:45px !important;
    top:45px !important; */
  }
    .user-badge-root-class{
        font-size: 14px !important;
    }
    .user-badge-award-carosul-container{
        width:85%;
        max-width: 60.5em;
        min-width: 40em;
        height: 14em !important;
    }
    .feedback-badge-award-carosul-height{
      width:85%;
        max-width: 60.5em;
        height: 10em !important;
    }
    .user-badge-award-carosul-second-container{
        width:100%;
        min-width: 100%;
        max-width: 68.5em;
    }
    .user-award-badge-image{
        width:160px !important;
        height: 170px !important;
        margin-top: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .user-award-badge-image-selected{
        width:160px !important;
        height: 170px !important;
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #4CCB1F;
        border-radius: 3em;
        cursor: pointer;
    }
    .feedback-award-badge-image{
      width:110px !important;
      height: 120px !important;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
    }
    .feedback-award-badge-image-selected{
      width:110px !important;
      height: 120px !important;
      margin-top: 10px;
      padding: 10px;
        border: 2px solid #4CCB1F;
        border-radius: 34px;
        cursor: pointer;
    }
    .user-badge-award-selected-description-modal{
      font-size: 1.2em;
    }
    .user-badge-award-selected-title-modal{
      font-size: 1.6em;
    }
    .user-badge-award-popup-title{
      font-size: 2em;
      line-height: 2em;
    }
    .user-badge-award-subhead{
      font-size: 1.6em;
    }
    .issued-detail-nav-button{
      font-size: 1em;
    }
    .user-badge-award-share-modal{
      min-width: 53.313em;
    }
    .user-badge-award-share-modal .ant-modal-content{
      height:34em;
    }
    .social-icon{
      width:5.375em !important;
      height:5.375em !important;
    }
    .share-modal-container{
      height: 400px !important;
    }
    .user-badge-award-detail-modal{
      min-width: 54.313em;
      max-width: 54.313em !important;
    }
    .filter-badge-family-title{
      font-size: 1.6em;
    }
    .filter-badge-family-para{
      font-size: 1.3em;
    }
}
@media (max-width:1450px) {
  .issued-award-image-container{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container:hover{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:40px !important;
    top:40px !important; */
  }
  .kudos-fluid-container .ant-tabs-top > .ant-tabs-nav{
    margin-top: -20px !important;
  }
  .user-badge-award-share-modal{
    min-width: 45.313em;
  }
  .user-badge-award-share-modal .ant-modal-content{
    height:30em;
  }
  .user-badge-award-share-modal{
    min-width: 42.313em;
  }
  .user-badge-award-share-modal .ant-modal-content{
    height:28em;
  }
  .social-icon{
    width:5.375em !important;
    height:5.375em !important;
  }
  .share-modal-container{
    height: 350px !important;
  }
  .user-badge-award-detail-modal{
    min-width: 50.313em;
    max-width: 50.313em !important;
  }
  .recent-modal-title{
    font-size: 2em;
    line-height: 2.2em;
  }
  .recent-modal-sub-title{
    font-size: 1.5em;
    line-height: 2em;
  }
  .recent-modal-image{
    width:13.266em;
    height: 11.938em;
  }
}
@media (max-width:1380px) {
  .issued-award-image-container{
    width: 15em;
    height: 14.875em;
  }
  .recived-award-image-container:hover{
    width: 14em;
    height: 12.875em;
  }
  .recived-award-image-container{
    width: 13em;
    height: 11.875em;
  }
  .recent-modal-image{
    width:13.266em;
    height: 11.938em;
  }
  .filter-badge-family-title{
    font-size: 1.5em;
  }
  .filter-badge-family-para{
    font-size: 1.2em;
  }
  .uer-profile-award-card-container{
     justify-content: center;
  }
}
@media (max-width:1300px) {
  .uer-profile-award-vertical-container{
    width:33.33% !important
  }
}
@media (max-width:1200px) {
    .user-badge-root-class{
        font-size: 12px !important;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:35px !important;
      top:35px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.2em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
    .user-badge-award-carosul-container{
      width:100%;
      max-width: 60.5em;
      min-width: 40em;
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
    width:100%;
      max-width: 60.5em;
      height: 12em !important;
  }
  
}

@media (max-width:960px) {
    .user-badge-root-class{
        font-size: 11px !important;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:30px !important;
      top:30px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.5em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
    .filter-badge-family-title{
      font-size: 1.25em;
    }
    .filter-badge-family-para{
      font-size: 1em;
    }
    .user-badge-award-selected-description-modal{
      font-size: 1em;
    }
    .user-badge-award-carosul-container{
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
      height: 12em !important;
  }
  .user-award-badge-image{
    width:150px !important;
    height: 155px !important;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
}
.user-award-badge-image-selected{
    width:150px !important;
    height: 155px !important;
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 3em;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:95px !important;
  height: 105px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:95px !important;
  height: 105px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
}

@media (max-width:920px) {
  .uer-profile-award-card-container{
    margin: 0px 3em;
  }
  
}
@media (max-width:780px) {
  .uer-profile-award-vertical-container{
    width:50% !important
  }
  .uer-profile-award-card-container{
    margin: 0px 2.5em;
  }
  .user-badge-award-detail-modal{
    min-width: 100%;
    max-width: 100%;
  }
  .recent-modal-title{
    font-size: 2em;
    line-height: 2.5em;
  }
  .recent-modal-sub-title{
    font-size: 1.5em;
    line-height: 2em;
  }
  .user-badge-award-carosul-container{
      width:100%;
      max-width: 60.5em;
      min-width: 40em;
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
    width:100%;
    max-width: 60.5em;
    height: 12em !important;
}
}
@media (max-width:600px) {
    .user-badge-root-class{
        font-size: 10px !important;
    }
    .user-badge-award-detail-modal{
      min-width:98% !important;
      max-width:98% !important;
    }
    .uer-profile-award-card-container{
      margin: 0px 2.5em;
      justify-content: center;
      align-items: center;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:25px !important;
      top:25px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.5em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
}

@media (max-width:450px) {
  .user-badge-award-popup-inner-container{
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width:480px) {
  .uer-profile-award-vertical-container{
    width:100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width:360px) {
  .user-award-badge-image{
    width:130px !important;
    height: 135px !important;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
}
.user-award-badge-image-selected{
    width:130px !important;
    height: 135px !important;
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 3em;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:90px !important;
  height: 100px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:90px !important;
  height: 100px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
}
.recieved-badge-head{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
  color: #4CCB1F;
  text-align: center;
  margin: 0;
}


.user-badge-award-popup-input-div .ant-select-clear{
  color: red !important;
}
.dashboard-user-badge-award-popup-input-div .ant-select-clear{
  color: red !important;
}
.profile-detail-layout{
    padding: 0px 3em;
}

.profile-card{
    box-shadow: 0px 10px 15px 0px #AABAC633;
    width: 100%;
    border-radius: 0.75em;
    background-color: #FFFFFF;
    font-family: Poppins;
    color: #000000;
}

.row-wrapper{
    display: flex;
    flex-direction: row;
    
}

.col-wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.ipad-view{
    display: none;
}
.web-view{
    display: block;
}
.user-profile-detail{
    width: 35%;
    border-right: 2px solid #8998A2;
    display: flex;
    flex-direction: row;
    grid-gap: 1.5em;
    gap: 1.5em;
    justify-content: space-between;
    align-items: start;
}

.pro-data-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5em;
    gap: 1.5em;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.user-profile-detail img{
    width: 8em;
    height:8em;
    border-radius: 50%;
    object-fit: cover;
}
.pro-data-image-container{
    width: 8em;
    height:8em;
    border-radius: 50%;
    position: relative;
}
.pro-data-image-container img{
    width: 8em;
    height:8em;
    border-radius: 50%;
    object-fit: cover;
}
.pro-data-image-container:hover .pro-data-hover-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1200;
    grid-gap:.5em;
    gap:.5em
}
.pro-data-hover-content{
    width: 8em;
    height:8em;
    position: absolute !important;
    display: none;
    top: .5em;
}
.pro-data-hover-content img{
    width: 2em;
    height:2em;
}
.pro-data-hover-content p{
    font-size: .85em;
    font-family: 'Poppins';
    font-weight: 500;
    color: #FFFFFF;
    line-height: normal;
    text-align: center;
}
.Profile-pic-option-container{
    box-shadow: 7px 10px 15px 0px #AABAC64D;
    background: #FFF;
    border-radius: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    position: absolute;
    left: 10px;
    z-index: 1100 !important;
}
.profile-pic-upload-button .ant-upload{
    font-size: 1em ;
    font-family: 'Poppins' !important;
    font-weight: 500;
}
.Profile-pic-option-container h4 {
    font-size: 1em;
    font-family: 'Poppins' !important;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    padding: .5em;
    cursor: pointer;
}
.Profile-pic-option-container h4:hover{
    background: #AABAC633;
    border-radius: .5em;
}
.profile-name{
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.1em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.role-name{
    font-size: 1.15em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.user-surevey-detail{
    width: 75%;
    padding-left: 5%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.info-label-title{
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.313em;
    letter-spacing: 0em;
    text-align: left;
    color: #8998A2;
    margin: 0px;
    white-space: nowrap;
}

.info-label-content{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
}

.profile-tab-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.profile-tab-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.profile-tab-active{
    border-bottom: 5px solid #4CCB1F;
}

.profile-tab-active-label{
    color: #4CCB1F;
}

.profile-tab-label{
    color: #8998A2;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}

.user-content-container{
    padding: 2em 2.5em;
}

.employee-profile-table{
    min-width: 600px;
    width: 100%;
    border-collapse:separate;
    border-spacing:0 15px;
}

.employee-profile-table tr th{
    color:  #A3A3A3;
    font-size: 1em;
    font-family: 'Manrope';
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
    text-align: center;
}

.employee-profile-table .table-body-td{
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    text-align: center;
}

.table-body-tr .first-child{
    border-right: none;
    border-left: 1px solid #D5E5F2 !important;
    border-top-left-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
    padding-left: 1.5em;
}

.table-body-tr .last-child{
    border-left:none ;
    border-right: 1px solid #D5E5F2 !important;
    border-top-right-radius: 0.75em;
    border-bottom-right-radius: 0.75em;
    padding-right: 1.5em;
}
.employee-profile-table  td {
    padding: 1em;
}

.td-main-label{
    font-family: Poppins;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.313em;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
}

.td-sub-label{
    font-family: Poppins;
    font-size: 0.938em;
    font-weight: 400;
    line-height: 1.438em;
    letter-spacing: 0em;
    text-align: left;
    color: #8D8D8D;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
}

.impact-header-userprofile{
    width: 100%;
    box-sizing: border-box;
    border: 4px solid #02D7A4; 
    border-radius: 1.5em;
    padding: 1.25em;
    position: relative;
    margin-top: 2em;
}

.header-border-user-profile{
    position: absolute;
    top: -1.6em;
    background:transparent;
    width: 100%;
    left: 3em;
    right: 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    box-sizing: border-box;
}

.border-inner-content-user-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFFFFF;
    padding: 0px 1em;
    box-sizing: border-box;
}

.header-border-label-user-profile{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75em;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    flex: none;
    flex-grow: 0;
    margin: 0px;
}

.user-impact-content{
    font-family: Poppins;
    font-size: 1.15em;
    font-weight: 500;
    line-height: 2.25em;
    letter-spacing: 0em;
    text-align: justified;

}

.cs-user-progress-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    width: 100%;
    padding:1em 1.5em;
}

.detail-btn{
    width: 10em;
    max-width: 95%;
    border-radius: 1em;
    border-radius: 0.5em;
    font-family: Poppins;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.5em 1em 1.5em 1em;
}

.no-data-profile{
    font-size: 1.5em;
}

.employee-badge-icon{
    width: 3.5em;
    height: 3.5em;
    object-fit: cover;
}

.employee-badge-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.content-wrap{
    flex-wrap: wrap !important;
}
@media (max-width:1100px) {
    .media-col-wrapper{
        flex-direction: column !important;
        grid-gap: 1.5em;
        gap: 1.5em;
    }
    .user-profile-detail{
        width: 100% !important;
        border-right: none;
    }
    .user-surevey-detail{
        padding-left: 0px;
        width: 100% !important;
    }

    .ipad-view{
        display: block;
    }
    .web-view{
        display: none;
    }
}

.emp-selector .ant-select-selector {
    height: 35px !important;
}

.emp-selector .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 31px !important;
    padding-left: 7px !important;
}

.emp-selector .ant-select-selection-search-input {
    font-size: 0.7em;
}


.emp-selector .ant-btn-icon-only.ant-btn-sm {
    width: 35px;
    height: 34px;
}



.emp-selector .ant-select-selection-item div {
    margin-top: 5px;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.main-container {
    margin-top: 1.7em;
}

.back-icon{
    top: 25px !important;
    left: 20px;
    cursor: pointer;
    height: 100%;
    margin-left: 15px;
}
.bread-crubs{
    position: fixed;
    top: 24px;
    left: 50%;
    right: 50%;
    z-index: 1000;
}
.image-section-mobile{
    display: none !important;
}
.soul-item-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 52px;
    color: #141414;
    margin: 0;
}
.soul-detail-content-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 82%;
    margin: auto;

    /* Gray 2 */

    color: #1F1F1F;
}
.soul-item-bg{
    max-width: 1179px;
    height: 328px;
    width:90%;
    height:100%;
}
.line{
    width:100px;
    height:1px;
    background:
    repeating-linear-gradient(90deg,#BFBFBF 0 3px,#0000 0 7px)
}
.take-test-btn{
    padding: 10px 50px;
    height: 64px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}
.take-test-btn:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.take-test-btn:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.survey-behavior-btn-non-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
    padding: 10px 20px;
    height: 64px;
    border-radius: 6px;
    cursor: pointer;
}
.survey-behavior-btn-non-active:hover{
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
}
.survey-behavior-btn-non-active:focus{
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
}

.edit-test-survey-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #4CCB1F;
    padding: 10px 20px;
    height: 64px;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #4CCB1F;
    cursor: pointer;
    padding: 10px 50p;
}
.edit-test-survey-btn:hover{
    color: #4CCB1F;
    background: #fff;
    border: 2px solid #4CCB1F;
}
.edit-test-survey-btn:focus{
    color: #4CCB1F;
    background: #fff;
    border: 2px solid #4CCB1F;
}
.survey-behavior-btn-active{
    height: 64px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
    cursor: pointer;
}
.survey-behavior-btn-active:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FFFFFF;
}
.survey-behavior-btn-active:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FFFFFF;
}
.step-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1F1F1F;
}
.step-2-go-test{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1F1F1F;
    margin-top: 20px;
}
.note-modal-container{
    padding: 10px 50px;
}
.soul-modal-bottom-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146.5%;
    color: #8C8C8C;
    text-align: center;
    margin-top: 20px;
}
.note-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #141414;
}
.note-number{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 146.5%;
    text-align: center;
    color: #8C8C8C;
}
.note-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 146.5%;
    color: #000000;
}
.modal-button-for-soul-survey{
    width: 158px;
    height: 51px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    letter-spacing: 0.005em;
    color: #FAFAFA;
}
.modal-button-for-soul-survey:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.modal-button-for-soul-survey:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
@media (max-width:991px) {
    .bread-crubs{
        display: none !important;
    }
    .soul-item-bg{
        max-width: 100%;
        width:100%;
        height:100%
    }
}

.selected-tab{
    margin: 0;
    background: #E1FFD7;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    color: #6BB96A;
}
.selected-tab-non-selected{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    color: #8C8C8C;
    margin: 0;
    padding-top: 10px !important;
    padding-left: 5px;
    padding-right: 5px;
}
.tab{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8C8C8C;
}
.soul-head{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 36px;
    color: #141414;
}
.soul-sub-head{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
}
.home-icon{
    height: 50px;
}
.image-container-soul{
    box-shadow: 0px -27px 50px -4px rgba(0, 0, 0, 0.09);
    border-radius: 28px;
    position: relative;
    height: 500px;
    margin-top: 30px;
    cursor: pointer;
}
.image-pettal-soul{
    width: 300px !important;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    /* filter: grayscale(100%); */
}
.image-active{
    display: none;
}
.image-inactive{
    display: block;
}
.image-title-section{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:10px;
    background: #FFFFFF;
    text-align: center;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14);
}
.home-component-card .card-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 44px;
    color: #141414;
    padding-bottom: 20px;
}
.start-btn{
    position: absolute;
    bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #4CCB1F;
    cursor: pointer;
}
.start-icon{
    padding-left: 3px;
}
.start-btn:hover .start-icon{
    padding-left: 10px;
}
.edit-btn{
    position: absolute;
    bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #8C8C8C;
    cursor: pointer;
}
.edit-icon{
    padding-left: 3px;
}
.edit-btn:hover .edit-icon{
    padding-left: 10px;
}
.number-title{
    margin-top: -20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 116%;
    text-align: center;
    color: #D9D9D9;
}
.number-container{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #8C8C8C;
    border: 1px solid #BFBFBF;
    padding: 5px 10px;
    border-radius: 50%;
}
.completed-numberContainer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    padding: 6px 10px;
    border-radius: 50%;
}
.completed-numberContainer-first{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    padding: 5px 11px;
    border-radius: 50%;
}
.number-first{
    /* padding: 5px 12px; */
}
.stepper-div{
    margin: 40px 0px;
}
.tick-icon{
    position: absolute;
    right: -20px;
    top:-20px;
    width: 50px;
}
.forMobile{
    display: none !important;
}
.image-title-section-for-mobile{
    display: none;
}
.impact-narative-warning-popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5em 1em 1em 1em;
}
.impact-narative-warning-title{
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    font-style: normal;

}
.impact-narative-warning-content{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    text-align: center;

}
.impact-narative-warning-btn{
    padding: 5px 10px;
    min-width: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    color: #fff;
    background-color: #4CCB1F;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
}
.impact-narative-warning-cancel-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #595959;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    outline: none;
    border: 1px solid #595959;
    background-color: #fff;
    min-width: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10px;
}
@media (min-width:1700px) {
    .soul-head{
        font-size: 41px;
        line-height: 52px;
    }
    .soul-sub-head{
        font-size: 23px;
        line-height: 34px;
    }
    .home-icon{
        height: 75px;
    }
}
@media (max-width:1770px){
    .image-pettal-soul{
        width: 230px !important;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    .card-section{
        margin: 30px 0px;
    }
    .image-container-soul{
        border-radius: 25px;
        position: relative;
        height: 400px;
    }
    .image-title-section{
        height: 130px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .home-component-card .card-title{
        font-size: 24px;
        line-height: 34px;
    }
    .number-title{
        font-size: 20px;
    }
    .start-btn{
        font-size: 14px;
    }
    .edit-btn{
        font-size: 14px;
    }
    .take-test-btn{
        height: 54px;
        padding: 8px 40px;
        font-size: 22px;
    }
    .survey-behavior-btn-non-active{
        height: 54px;
        padding: 8px 20px;
        font-size: 22px;
    }
    .edit-test-survey-btn{
        height: 54px;
        padding: 8px 40px;
        font-size: 22px;
    }
    .survey-behavior-btn-active{
        height: 54px;
        padding: 8px 20px;
        font-size: 22px;
    }
    .step-title{
        font-size: 18px;
    }
    .step-2-go-test{
        font-size: 18px;
    }
    .note-head{
        font-size: 18px;
    }
    .note-number{
        font-weight: 400;
        font-size: 16px;
       
    }
    .note-content{
        font-size: 14px;
    }
    .note-modal-container{
        padding: 10px 30px;
    }
    .modal-button-for-soul-survey{
        height: 40px !important;
        font-size: 16px;
        width: 130px;
    }
    .soul-modal-bottom-text{
        font-size: 10px;
    }
    .soul-item-bg{
        width:100%;
        height: 100%;
        max-height:280px;
    }
    .selected-tab{
        font-size: 18px;
        line-height: 24px;
        padding: 6px 10px;
    }
    .selected-tab-non-selected{
        font-size: 18px;
        line-height: 24px;
        padding-top: 7px !important;
    }
} 
@media (max-width:1440px) {
    .stepper-div{
        margin: 30px 0px;
    }
    .soul-item-bg{
        max-width: 1000px !important;
        width:100%;
        height: 100%;
        max-height:280px;
    }
    .image-pettal-soul{
        width: 210px !important;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
    }
    .card-section{
        margin: 20px 0px;
    }
    .image-container-soul{
        border-radius: 23px;
        position: relative;
        height: 345px;
    }
    .image-title-section{
        height: 100px;
        border-bottom-left-radius: 23px;
        border-bottom-right-radius: 23px;
        margin-bottom: -12px;
    }
    .home-component-card .card-title{
        font-size: 18px;
        line-height: 23px;
        margin-left: -21px;
    }
    .number-title{
        font-size: 17px;
        margin-left: 10px;
    }
    .start-btn{
        font-size: 13px;
    }
    .edit-btn{
        font-size: 13px;
    }
    .edit-icon{
        height: 12px;
        margin-bottom: 3px;
    }
    .number-container{
        font-size: 14px;
        padding: 5px 8px !important;
    }
    .number-first{
        padding: 5px 10px;
    }
    .completed-numberContainer-first{
        font-size: 14px;
        padding: 5px 9px;
    }
    .completed-numberContainer{
        font-size: 14px;
        padding: 5px 8px;
    }
    .tick-icon{
        position: absolute;
        right: -15px;
        top:-15px;
        width: 40px;
    }
    .soul-item-title{
        font-size: 25px !important;
    }
    .soul-detail-content-text{
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
    }
    .take-test-btn{
        height: 48px;
        padding: 5px 40px;
        font-size: 18px;
    }
    .survey-behavior-btn-non-active{
        height: 48px;
        padding: 5px 20px;
        font-size: 18px;
    }
    .edit-test-survey-btn{
        height: 48px;
        padding: 5px 40px;
        font-size: 18px;
    }
    .survey-behavior-btn-active{
        height: 48px;
        padding: 5px 20px;
        font-size: 20px;
    }
    .step-title{
        font-size: 16px;
    }
    .step-2-go-test{
        font-size: 16px;
    }
    .note-head{
        font-size: 18px;
    }
    .note-number{
        font-weight: 400;
        font-size: 14px;
       
    }
    .note-modal-container{
        padding: 10px 30px;
    }
    .note-content{
        font-size: 13px;
        line-height: 140.5%;
    }
    .selected-tab{
        font-size: 16px;
        padding: 7px 10px;
    }
    .selected-tab-non-selected{
        font-size: 16px;
    }
}

@media (max-width:600px) {
    .image-pettal-soul{
        width: 200px !important;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        margin-top: -10px !important;
        border-radius: 22px !important;
    }
    .image-container-soul{
        border-radius: 22px;
        position: relative;
        height: 320px;
        margin-top: 40px !important;
    }
    .soul-detail-content-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        padding: 10px;
    }
    .step-2-go-test{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .image-title-section{
        height: 120px;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
    }
    .home-component-card .card-title{
        font-size: 19px;
        line-height: 24px;
    }
    .number-title{
        font-size: 17px;
    }
    .start-btn{
        font-size: 13px;
    }
    .edit-btn{
        font-size: 13px;
    }
    .line{
        width:80px;
    }
    .forMobile{
        display: block !important;
    }
    .forWeb{
        display: none !important;
    }
    .action-btn{
        height: 40px !important;
        width: 40px !important;
    }
    .bulb-icon{
        height: 20px !important;
        margin-right: 5px !important;
    }
    .take-test-btn{
        height: 44px;
        padding: 5px 20px;
        font-size: 16px;
    }
    .survey-behavior-btn-non-active{
        height: 44px;
        padding: 5px 20px;
        font-size: 16px;
    }
    .edit-test-survey-btn{
        height: 44px;
        padding: 5px 20px;
        font-size: 18px;
    }
    .survey-behavior-btn-active{
        height: 44px;
        padding: 5px 20px;
        font-size: 20px;
    }
    .step-title{
        font-size: 14px;
    }
    .note-number{
        font-weight: 400;
        font-size: 14px;
       
    }
    .note-modal-container{
        padding: 10px 20px;
    }
    .note-content{
        font-size: 12px;
        line-height: 140.5%;
    }
    .modal-button-for-soul-survey{
        height: 40px !important;
        font-size: 14px;
        width: 110px;
    }
    .tick-icon{
        position: absolute;
        right: -15px;
        top:-25px;
        width: 40px;
    }
    .soul-item-bg{
        margin: 10px 0px;
    }
}

@media (max-width:480px) {
    .back-icon{
        top: 0px !important;
        left: 10px;
        cursor: pointer;
        height: 100%;
        margin-left: 1em;
    }
    .image-pettal-soul{
        width: 100% !important;
        height: 150px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-radius: 20px !important;
        margin-top: -10px !important;
    }
    .soul-item-bg{
        width:95% !important;
        margin-top: 20px !important;
    }
    .soul-detail-content-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        padding: 20px 10px;
    }
    .step-2-go-test{
        font-size: 12px;
        margin-top: 20px;
    }
    .step-title{
        font-size: 14px;
        margin-top: 10px;
    }
    .button-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap:20px;
        gap:20px
    }
    .edit-test-survey-btn{
        width: 271px;
        height: 45px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .take-test-btn{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .survey-behavior-btn-non-active{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .survey-behavior-btn-active{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .image-container-soul{
        height: 160px;
        margin-top: 35px !important;
    }
    .image-title-section{
        display: none !important;
    }
    .image-title-section-for-mobile{
        height: 50px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
        background: #FFFFFF;
        text-align: center;
        border-bottom-left-radius: 28px;
        border-bottom-right-radius: 28px;
        box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14);
    }
    .image-section-mobile{
        display: block !important;
    }
    .image-section-web{
        display: none !important;
    }
    .edit-btn{
        position: relative;
        flex-wrap: nowrap;
    }
    .start-btn{
        position: relative;
        flex-wrap: nowrap;
    }
    .home-component-card .card-title{
        font-size: 13px;
        line-height: 20px;
        padding-bottom: 0px;
        white-space: nowrap !important;
    }
    .number-title{
        font-size: 12px;
        margin-top: 0px;
    }
    .home-icon{
        display: none;
    }
    .tick-icon{
        width: 30px;
        top: -20px;
        right: -10px;
    }
    .action-btn{
        top: 120px !important;
    }
}

@media (max-width:380px) {
    .line{
        width:60px;
    }
    .image-pettal-soul{
        width: 100% !important;
        height: 140px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .image-container-soul{
        height: 150px;
    }
    .home-component-card .card-title{
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 0px;
        white-space: nowrap !important;
    }
    .number-title{
        font-size: 11px;
        margin-top: 0px;
    }
    .action-btn{
        top: 100px !important;
    }
}
@media (max-width:340px) {

    .image-pettal-soul{
        width: 100% !important;
        height: 130px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-radius: 20px !important;
    }
    .image-container-soul{
        height: 140px;
    }
}
@media (max-width:420px) {
    /* .line{
        width:30px;
    } */
}
@media (max-height:500px) {
    .main-container{
        margin-top: 20px !important;
    }
}
@media (max-height:370px) {
    .main-container{
        margin-top: 40px !important;
    }
}
@media screen and (max-width:600px) and (max-height:500px) {
    .main-container{
        margin-top: 0px !important;
    }
}
@media screen and (max-width:800px) and (max-height:500px) {
    .main-container{
            margin-top: 15px;
    }
}
@media (max-width:520px){
    .custom-side-modal{
        max-width: 300px !important;
    }
    .custom-close-btn{
        right: 260px !important;
    }
}
@media (max-width:340px){
    .custom-side-modal{
        max-width: 280px !important;
    }
    .custom-close-btn{
        right: 240px !important;
    }
}
@media (max-height:560px) {
    .side-modal{
        position: absolute !important;
        right: -20px !important;
        top: 80px;
        max-width: 500px;
        z-index: 1200;
        width: 520px;
        -webkit-transform-origin: 453px 44px;
                transform-origin: 453px 44px;

    }
}
@media (min-height:600px) {
    .action-btn{
        top: 170px !important;
    } 
    .side-modal{
        top: 160px !important;
    }
    .custom-close-btn{
        top: 200px !important;
    }
}
@media (min-height:800px) {
    .action-btn{
        top: 200px !important;
    } 
    .side-modal{
        top: 190px !important;
    }
    .custom-close-btn{
        top: 230px !important;
    }
}
@media (min-height:900px) {
    .action-btn{
        top: 230px !important;
    } 
    .side-modal{
        top: 220px !important;
    }
    .custom-close-btn{
        top: 260px !important;
    }
}
@media (min-height:1150px) {
    .action-btn{
        top: 260px !important;
    } 
    .side-modal{
        top: 250px !important;
    }
    .custom-close-btn{
        top: 290px !important;
    }
  
}
@media screen and (max-width:480px) and (min-height:600px) {
    .action-btn{
        top: 130px !important;
    } 
    .side-modal{
        top: 110px !important;
    }
    .custom-close-btn{
        top: 160px !important;
    }
}

/* soul home cards width adjustment. range from 320px to 438px */


@media screen and (max-width: 375px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 2em 0 2em;
    }
}

@media screen and (max-width: 320px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 1.3em 0 1.3em;
    }
}

@media screen and (min-width: 413px) and (max-width: 438px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 3em 0 3em;
    }
}


.value-section{
    border-radius: 1.1875em;
    background: #FFF;
    box-shadow: -6px 2px 61px 12px rgba(0, 0, 0, 0.06);
    margin: 3em 1em 0.5em 1em;
    padding: 2em 2.1em;
    width: 45%;
}
.value-list{
    padding: 0.625em 0.8125em 0.625em 0.8125em;
    margin: 2em 0.2em;
    border-radius: 0.6875em;
    background:  #F5F5F5;
    display: flex;
    justify-content: start;
    align-items: center;
}

.value-list h4{
    color:#1F1F1F;
    font-family: Poppins;
    font-size: 1.3125em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    /* word-break: break-all; */
}

.value-list span{
    color:#009AF1;
    margin-right: 0.75em;
}

@media screen and (max-width:1150px) {
    .value-section{
        width: 65%;
    }
}

@media screen and (max-width:950px) {
    .value-section{
        width: 75%;
    }
}

@media screen and (max-width:800px) {
    .value-section{
        width: 95%;
    }
}

.sales-page-link {
    font-size: 17px;
    cursor: pointer;
}

.tool-nav-item-sales {
    font-size: 17px;
}

.back-icon-thank-you{
    width: 18px;
    -webkit-filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
            filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.thank-you-message-text{
    font-size: 14px;
    font-weight: 500;
}

.page-intro-header-sales-page {
    font-size: 45px;
    font-weight: 700;
}

.auto-margin-sales-page {
    margin: auto;
}

.sales-page-hero-section {
    margin-top: 55px;
}

.sales-page-tagline {
    font-size: 22px;
    color: #4CCB1F;
    font-family: poppins;
    font-weight: 700;
    margin-top: 25px;
}

.sales-page-navbar-button {
    background-color: #4CCB1F;
    color: #FFFFFF;
    height: 40px;
    border-radius: 8px;
    font-weight: 700;
}

.sales-page-navbar-button-panel .ant-btn:hover,
.sales-page-navbar-button-panel .ant-btn:focus {
    background-color: #4CCB1F;
    color: #FFFFFF;
}

.sales-page-link:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2.5px;
    -webkit-text-decoration-color: rgb(186, 208, 64);
            text-decoration-color: rgb(186, 208, 64);
}

.team-size-menu-element {
    background: #FFFFFF;
    text-align: left;
    color: #8C8CA1;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    padding: 20px;
    border: 1px solid #ECF1F4;
    border-radius: 8px;
}



.team-size-menu-element:hover{
    background-color:rgb(236, 241, 244);
    color: #000000;
}


.ordered-list-sales-page {
    font-weight: 500;
    height: 40px;
    font-size: 18px;
    letter-spacing: 0.9px;
}

.explore-mapp-sales-page:hover {
    color: #FFFFFF;
    background: #4CCB1F;
}


.explore-mapp-sales-page {
    width: 30%;
    height: 50px;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 8px;
    color: #FFFFFF;
    background: #0D57FF;
}

.soul-page-ordered-list {
    font-weight: 500;
    font-size: 16px;
    font-family: poppins;
}

.soul-identity-margin {
    margin: 20px auto;
}


.soul-identity-title-container {
    line-height: 30px;
    justify-content: start;
}

.soul-identity-bread-crumb {
    color: #2BA341;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 20px 4px 20px;
    text-align: center;
    border-radius: 40px;
    background: #CDEFBF 80%;
    text-transform: uppercase;
}

.soul-identity-bread-crumb-container {
    justify-content: start;
}

.tool-break-crumb-sales {
    padding: 4px 30px 4px 30px;
}

.button-link-sales-page {

    height: 50px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 8px;
    font-family: poppins;
    color: #FFFFFF;
    background: #4CCB1F;
}

.button-link-sales-page span {
    letter-spacing: 0.8px;
    font-size: 16px;
}

.button-link-sales-page:hover {
    background: #0D57FF;
    color: #FFFFFF;
}

.soul-identity-header {
    font-size: 40px;
    line-height: 100px;
    font-weight: 650;
}

.tool-card-sales-page {
    padding: 40px;
    min-height: 415px;
    border-radius: 20px;
    background: #F8FAFF;
}

.tool-card-sales-page:hover {
    background: #054BFF;
    transition: 0.5s ease;
}

.contact-number-container .ant-input-prefix {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.tool-nav-item-sales:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2.5px;
    position: relative;
    z-index: 1100;
    -webkit-text-decoration-color: rgb(186, 208, 64);
            text-decoration-color: rgb(186, 208, 64);
}


.tool-title-sales-pape,
.tool-description-sales-pape,
.sales-page-tool-link {
    color: #262626;
}

.tool-card-sales-page:hover .tool-title-sales-pape {
    color: #FFFFFF;
}

.tool-card-sales-page:hover .tool-description-sales-pape {
    color: #FFFFFF;
}

.tool-card-sales-page:hover .sales-page-tool-link {
    color: #FFFFFF;
}

.tool-title-sales-pape {
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
}

.tool-description-sales-pape {
    font-size: 14px;
    max-height: 130px;
    overflow: auto;

}

.card-image-sales-page svg {
    width: 66px;
    height: 66px;
    transition: 0.5s;
}



.tool-card-sales-page:hover svg {
    width: 76px;
    height: 76px;
    transition: 0.5s ease;
}


.sales-page-tool-link {
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    top: 380px;
    color: #054BFF;
}



.book-demo-button {
    background: #0D57FF;
    height: 40px;
    border: none;
    height: 50px;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.book-demo-button:hover {
    background: #4CCB1F;
    ;


}

.sales-page-white-color {
    color: #FFFFFF;
}

.sales-page-spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}

.sales-page-navlink-container .ant-btn {
    border: none;
    height: auto;
    padding: 0px !important;
}

.navbar-menu-container {
    width: 800px;
    background: #F8FAFF;
    padding: 10px;
    position: relative;
    top: 23px;
    border-radius: 6px;
}

.info-text-get-in-touch {
    color: #FFFFFF;
}

.form-header-get-in-touch {
    line-height: 37px;
    font-size: 30px;
    font-weight: 700;
}

.messgae-tag-get-in-touch {

    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 8px;

}

.form-label-get-in-touch {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 10px;
    color: #868686;
}

.input-box-get-in-touch {
    background: #ECF1F4;
    margin-bottom: 1.2em;
}

.textarea-get-in-touch {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    border: none;
    border-radius: 8px;
    height: 110px;
    resize: none;
}

/* .your-msg-box:focus {
    outline: 2px solid #88aff6;
    -webkit-outline: 2px solid #88aff6;
} */

.input-form-get-in-touch .ant-input {
    background-color: #ECF1F4;
    padding: 11px;
    font-size: 12px;
    border: none;
    border-radius: 8px;
}

.input-form-get-in-touch .ant-input-suffix {
    position: absolute;
    left: 5px;
    top: 10px;
}

.input-form-get-in-touch .ant-input-affix-wrapper {
    padding: 9px;
    border: none;
    border-radius: 8px;
}

.input-form-get-in-touch .ant-input-affix-wrapper>input.ant-input {
    margin-left: 30px;
}

.input-form-get-in-touch .ant-input-suffix img {
    width: 20px;
}

.input-form-get-in-touch .ant-checkbox-checked::after {
    border: none;
}

.input-form-get-in-touch .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: #C4C4C4;
    border-color: #ECF1F4 !important;
}

.input-form-get-in-touch .ant-checkbox-inner::after {
    height: 14px;
    top: 46%;
    left: 27%;
}

.input-form-get-in-touch .ant-checkbox-input:focus {
    border-color: #237AFF;
    ;
}

.input-form-get-in-touch .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #FFFFFF;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(50deg) scale(1) translate(-50%, -50%);
            transform: rotate(50deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    content: ' ';
}

.input-form-get-in-touch .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #237AFF;
    border-color: #D9D9D9;
}

.up_down_arrow {
    transition: -webkit-transform 0.45s ease;
    transition: transform 0.45s ease;
    transition: transform 0.45s ease, -webkit-transform 0.45s ease;
}

.up_down_arrow.up {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}


.checkboxes-get-in-touch {
    background-color: #ECF1F4;
    padding: 3px 7px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxes-get-in-touch.green {
    background-color: #4CCB1F;

}

.checkbox-choice-get-in-touch {
    font-size: 14px;
    color: #8C8CA1;
    align-self: center;
}

.checkbox-choice-get-in-touch.gray {
    color: #FFFFFF;
}

.checkbox-selection-get-in-touch {
    width: 30px;
    height: 30px;
    background-color: #D9D9D9;
    border-radius: 25px;
}


.send-msg-button {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    font-weight: 500;
}

.send-msg-button-enabled {
    background: #237AFF;
    color: #FFFFFF;
}



.send-msg-button-diabled {
    background: #D9D9D9;
}

.send-msg-button-diabled span {
    color: #FFFFFF;
}

.send-msg-button-enabled span {
    color: #FFFFFF;
}


.send-msg-button:hover {
    background: #237AFF;
}

.advanced-option-container-enabled {
    display: block;
    transition: 0.5s ease-out;
}

.advanced-option-container-disabled {
    display: none;
    transition: 0.5s ease-out;
}

.contact-number-container .ant-input-affix-wrapper>input.ant-input {
    margin-left: 0px !important;
}

.contact-number-container .ant-input {

    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

}

.contact-number-container .ant-input-prefix {
    background: #ECF1F4;
    margin-left: 0px !important;
    margin-right: -2px;
}

.book-demo-container {
    margin-top: 15px;
}


.info-text-get-in-touch {
    font-size: 13px;
}

.contact-number-container .ant-input-affix-wrapper {
    padding: 0px !important;
    height: 40px !important;
}

.soul-tool-intro-section video {
    -webkit-clip-path: inset(1px 1px);
            clip-path: inset(1px 1px);
}

.contact-label-get-in-touch {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 17px !important;
    font-weight: 600;
}

.footer-mapp-description {
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
}

.footer-column-header-sales-page {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 30px;
    font-weight: 600;
}

.footer-menu-item-sales-page {
    font-size: 14px;
    margin-top: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
}

.footer-menu-item-sales-page:hover {
    color: #0D57FF;
}

.sales-footer-spacer {
    padding-top: 37px;
    padding-left: 30px;
    border-right: 1px solid #D9D9D9;
}

.sales-footer-spacer:last-child {
    border: none;
}

.ordered-list-container li:before {
    content: "• ";
    color: #4CCB1F;
    position: relative;
    top: 8px;
    font-size: 40px;
}

.feature-row {
    margin: 0px 15px 15px 0px;
}

.feature-label {
    position: absolute;
    top: 22px;
    left: 30px;
    font-family: poppins;
    font-size: 22px;
    font-weight: 500;
}

.feature-description-image-soul-tool {
    padding: 0px 20px 20px 0px;
}

.feature-description-soul-tool-title {
    font-size: 45px;
    font-weight: 700;
}

.feature-description-soul-tool-text {
    font-size: 17px;
    font-weight: 500;
}

.soul-tool-spacer {
    margin-top: 70px;
    margin-bottom: 70px;
}

.soul-tool-feature-row {
    margin-bottom: 20px;
}

.soul-tool-feature-header {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    left: 85px;
}

.soul-tool-identity-header {
    font-size: 45px;
    font-weight: 700;
}

.thank-you-modal .ant-modal-body {
    background: #ECF1F4;
}

.team-size-options-menu-item .ant-menu-item {
    padding: 0px !important;
}

.selected-team-size:hover {
    color:#000000;
    -webkit-text-decoration-color: #8C8CA1 !important;
            text-decoration-color: #8C8CA1 !important;
}

.selected-team-size .ant-btn:hover,
.selected-team-size .ant-btn:focus {
    color: rgb(140, 140, 161);
}

.feature-col-soul-sales-page:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    transition: all 0.3s ease-out;
}

.info-container-get-in-touch {
    top: 250px;
    position: relative;
    /* margin-top: calc(100% - 50px); */
}

.sales-page-footer-container {
    padding-bottom: 30px;
    padding-top: 70px;
}


.header-decorative-line {
    position: absolute;
    width: 95%;
    margin: auto;
    object-fit: cover;
}

.soul-decorative-line-sales {
    position: absolute;
    width: 50%;
    top: 21%;
    left: -9px;
}

.sales-tools-decorative-line {
    width: 25%;
    position: absolute;
    top: 37.8%;
    right: 0px;
}

.contact-number-container .ant-select-selection-item {
    cursor: pointer;
}


.send-msg-button-disbaled {
    background: #D9D9D9 !important;
}

.send-msg-button {
    transition: none !important;
}

.input-form-get-in-touch .ant-btn[disabled] {
    color: #000000 !important;
}


.book-demo-background-sales {
    height: 340px;
}

.mapp-tools-header {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 15px;
}

.tools-card-sales {
    padding: 25px;
    min-height: 375px;
}


.book-demo-header {
    font-size: 45px;
    color: #FFFFFF;
    font-weight: 700;
}

.book-demo-description-sales {
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 25px;
    margin-bottom: 40px;
    font-weight: 600;
}

.tool-set-footer-sales {
    padding-left: 27px;
}

.get-in-touch-sales {
    width: 100%;
    position: relative;
    z-index: 10;
}

.mapp-logo-get-in-touch {
    margin-top: 15px;
    position: relative;
    left: -95px;
}

.text-content-get-in-touch {
    flex-direction: column;
    position: absolute;
    z-index: 100;
    padding: 65px;
    grid-gap: 10px;
    gap: 10px;
    top: 0px;
}

.back-icon-get-in-touch {
    width: 15px;
    margin-right: 10px;
}

.checkbox-panel-get-in-touch {
    flex-wrap: nowrap;
}

.get-in-touch-sales {
    height: 100%;
    /* min-height: 830px; */
    /* max-height: 840px; */
}


.soul-tool-intro-section video {
    height: 600px;
    position: relative;
    z-index: 10;
}

.survey-type-soul-tool {
    flex-wrap: nowrap;
}


.feature-card-soul-tool {
    grid-gap: 15px;
    gap: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.soul-tool-feature-card-navigation {
    grid-gap: 15px;
    gap: 15px;
    margin-right: 15px;
}


.feature-description-image-soul-tool {
    width: 85%;
}

.sales-navbar-web {
   width:100%
}

.sales-navbar-drawer {
    display: none;
}

.sales-drwaer-item {
    margin-bottom: 20px;
}

.sales-nav-phone-icon {
    position: absolute;
    right: 18px;
    top: -18px;
}


.sales-navbar-web {
    box-shadow: 0px 4px 53px rgba(0, 0, 0, 0.09);
    padding: 20px 30px 20px 50px;
    position: relative;
    z-index: 100;
}

.sales-menu-container .ant-select-selector,.sales-menu-container .ant-select-selector:hover,.sales-menu-container .ant-select-selector:focus,.sales-menu-container .ant-select-selector:focus-visible,.sales-menu-container .ant-select-selector:focus-within{
    background: rgb(236, 241, 244) !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: none !important;
    font-weight: 400;
    font-size: 1em;
}
.sales-menu-container .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-bottom: 0.375em !important;
}
.sales-menu-container .ant-select-selector .ant-select-selection-item{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-top: 0.375em !important;
}
.sales-menu-container .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #868686 !important;
    font-weight: 400 !important;
    font-size: .85em;
    font-family:'Poppins';
}
/* styles for bigger device */


@media screen and (max-width:1380px) {

    .messgae-tag-get-in-touch,
    .form-label-get-in-touch,
    .input-form-get-in-touch .ant-input {
        font-size: 16px;
    }

    .form-label-get-in-touch {
        margin-bottom: 20px;
    }

    .input-box-get-in-touch {
        margin-bottom: 2em;
    }

    /* sales page styles */

    .explore-mapp-sales-page {
        margin-top: 50px;
        width: 41%;
    }



    .tool-break-crumb-sales {
        width: 10%;
    }

    .page-intro-header-sales-page {
        font-size: 37px;
    }

}




@media screen and (max-width:1030px) {

    .checkbox-panel-get-in-touch .ant-col:first-child {
        margin-bottom: 20px;
    }

    .checkbox-panel-get-in-touch .ant-col-lg-12 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .soul-tool-intro-section {
        flex-direction: column-reverse;
    }

    .get-in-touch-sales {
        height: 100%;
        max-height: 1000px;
    }

    .get-in-touch-image-info-wrapper {
        flex-wrap: nowrap;
    }


    .ordered-list-sales-page {
        font-size: 15px;
    }

    .explore-mapp-sales-page {
        width: 46%;
    }

    .checkbox-panel-get-in-touch {
        flex-wrap: wrap;
    }


}


@media screen and (max-width:1024px) and (min-height:768px) {

    .soul-tool-intro-section .ant-col-lg-11 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .soul-tool-intro-section .ant-col-lg-10 {
        flex: 0 0 80%;
        max-width: 80%;
    }

}


@media screen and (min-height:830px) {

    .get-in-touch-sales {
        min-height: 834px;
    }
}



/* @media screen and (max-width:900px) {

    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }
} */




@media screen and (max-width:768px) {

    /* footer styles */

    .sales-footer-spacer {
        border-right: none;
    }


    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }

    


    /* soul tool styles */



    .feature-description-image-soul-tool {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .feature-description-soul-tool-title {
        text-align: center;
    }

    .feature-description-soul-tool-text {
        text-align: justify;
        padding-left: 20px;
        padding-right: 20px;
    }


    .feature-row {
        margin: 0px;
    }

    .horizontal-card-container-soul-tool img {
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .soul-tool-feature-card-navigation {
        margin-right: 0px;
    }

    .feature-card-soul-tool {
        margin-right: 0px;
    }

    .survey-type-soul-tool {
        flex-wrap: wrap;
    }

    .survey-type-soul-tool .ant-col {
        margin: auto;
        margin-bottom: 20px;
    }

    .soul-tool-intro-section video {
        width: 95%;
        margin: auto;
        height: auto;
    }

    .soul-tool-intro-section .ant-row {
        margin: auto;
        text-align: justify;
    }




    .soul-tool-feature-header {
        left: 0px;
        font-size: 22px;
        text-align: center;
    }






    /* get in touch page styles */

    .form-col-get-in-touch {
        padding-top: 40px !important;
    }

    .get-in-touch-image-info-wrapper {
        flex-wrap: wrap !important;
    }


    .info-text-get-in-touch {
        font-size: 16px;
    }

    .get-in-touch-sales {
        height: 800px;
    }

    .contact-label-get-in-touch {
        font-size: 18px;
    }


    .mapp-logo-get-in-touch {
        left: -230px;
    }



    /* Sales page home styles */




    .page-intro-header-sales-page {
        font-size: 25px;
    }

    .ordered-list-sales-page {
        font-size: 14px;
    }

    .explore-mapp-sales-page span {
        font-size: 14px;
    }

    .explore-mapp-sales-page {
        width: 33%;
        margin-top: 10px;
    }

    .sales-page-spacer {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .soul-identity-bread-crumb {
        margin-top: 50px;
    }

    .soul-identity-header {
        font-size: 35px;
    }

    .soul-page-ordered-list {
        text-align: justify;
    }



    .button-link-sales-page span {
        font-size: 14px;
    }

    .tool-break-crumb-sales {
        width: 12% !important;
    }

    .mapp-tools-header {
        font-size: 35px;
        margin-top: 20px;
    }

    .tool-title-sales-pape {
        font-size: 22px;
    }

    .card-image-sales-page svg {
        width: 46px;
        height: 46px;
    }

    .tool-card-sales-page:hover svg {
        width: 56px;
        height: 56px;
    }

    .tools-card-sales {
        padding: 15px;
    }

    .book-demo-header {
        font-size: 25px;
    }


    .book-demo-description-sales {
        font-size: 16px;
        text-align: center;
    }

    .footer-column-header-sales-page {
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .tool-set-footer-sales {
        padding-left: 18px;
    }


    .checkbox-panel-get-in-touch {
        flex-wrap: wrap;
    }

    .checkbox-panel-get-in-touch .ant-col {
        margin: auto;
    }




}


@media screen and (min-width:760px) and (min-height:1000px) {

    .survey-type-soul-tool {
        flex-wrap: nowrap;
    }
}








/* styles for smaller device */

@media screen and (max-width:500px) {




    /* get in touch page styles */

    .form-header-get-in-touch {
        padding-top: 40px;
    }

    .mapp-logo-get-in-touch {
        left: 0px;
    }

    .text-content-get-in-touch {
        padding-left: 40px;
    }


    .get-in-touch-sales {
        width: 100%;
        position: relative;
    }

    .info-container-get-in-touch {
        position: relative;
        z-index: 100;
    }

    .input-form-get-in-touch {
        position: relative;
        padding-bottom: 40px;
    }



    /* sales page home styles */

    .soul-identity-bread-crumb-container,
    .soul-identity-container {
        justify-content: center;
    }

    .page-intro-header-sales-page {
        margin-top: 20px;
    }

    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }

    .page-intro-header-sales-page {
        font-size: 25px;
    }

    .ordered-list-sales-page {
        font-size: 15px;
    }

    .explore-mapp-sales-page span {
        font-size: 14px;
    }

    .explore-mapp-sales-page {
        width: 52%;
        margin-top: 61px;
    }

    .sales-page-spacer {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .soul-identity-bread-crumb {
        margin-top: 20px;
    }

    .soul-identity-header {
        font-size: 35px;
    }

    .soul-page-ordered-list {
        text-align: justify;
    }



    .button-link-sales-page span {
        font-size: 14px;
    }

    .tool-break-crumb-sales {
        width: 30% !important;
    }

    .mapp-tools-header {
        font-size: 35px;
        margin-top: 20px;
    }

    .tool-title-sales-pape {
        font-size: 22px;
    }

    .card-image-sales-page svg {
        width: 46px;
        height: 46px;
    }

    .tool-card-sales-page:hover svg {
        width: 56px;
        height: 56px;
    }

    .tools-card-sales {
        padding: 15px;
    }

    .book-demo-header {
        font-size: 25px;
    }


    .book-demo-description-sales {
        font-size: 16px;
        text-align: center;
    }

    .footer-column-header-sales-page {
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .tool-set-footer-sales {
        padding-left: 18px;
    }


}


/* Styles for bigger device */


@media screen and (min-width:1500px) {

    .book-demo-background-sales {
        height: 340px;
    }

    .soul-decorative-line-sales {
        top: 26.5%;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 100%; */
        /* height: 17%; */
    }

    .sales-tools-decorative-line {
        top: 51%;
    }

    .sales-page-tagline {
        font-size: 24px;
    }

    .ordered-list-sales-page {
        font-size: 20px;
    }

    .soul-identity-bread-crumb {
        font-size: 18px;
    }

    .tool-description-sales-pape {
        font-size: 18px;
    }

    .soul-page-ordered-list {
        font-size: 20px;
    }

    .sales-page-tool-link {
        font-size: 20px;
    }

}



@media screen and (min-width:1900px) {

    .header-decorative-line {
        position: absolute;
        /* width: 108%; */
        /* height: 17%; */
    }

    .soul-decorative-line-sales {
        top: 27%;
    }

    .sales-tools-decorative-line {
        top: 51.7%;
    }

    .messgae-tag-get-in-touch,
    .form-label-get-in-touch,
    .input-form-get-in-touch .ant-input {
        font-size: 20px;
    }

    .form-label-get-in-touch {
        margin-bottom: 30px;
    }

    .input-box-get-in-touch {
        margin-bottom: 2em;
    }

    .contact-label-get-in-touch {
        font-size: 22px;
    }

    .info-text-get-in-touch {
        font-size: 18px;
    }

    /* navbar styles */

    .sales-page-link {
        font-size: 22px;
    }

    .sales-page-navbar-button {
        height: 55px;
    }

    .sales-page-navbar-button .ant-btn {
        font-size: 18px;
    }

    .sales-navbar-web {
        width: 100%;
        padding: 25px 35px 25px 50px
    }

    .tool-nav-item-sales {
        font-size: 22px;
    }

}

@media screen and (min-width:2100px) {

    .page-intro-header-sales-page {
        font-size: 70px;
    }

    .sales-page-tagline {
        font-size: 37px;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 115%; */
    }

    .sales-tools-decorative-line {
        top: 53.1%;
    }

    .sales-page-tagline {
        font-size: 26px;
    }

    .ordered-list-sales-page {
        font-size: 26px;
        height: 60px;
    }

    .soul-identity-bread-crumb {
        font-size: 24px;
    }

    .tool-description-sales-pape {
        font-size: 22px;
    }

    .soul-page-ordered-list {
        font-size: 26px;
    }

    .sales-page-tool-link {
        font-size: 24px;
    }

    .button-link-sales-page span {
        font-size: 25px;
    }

    .tool-title-sales-pape {
        font-size: 34px;
    }

}



@media screen and (min-width:2200px) {

    .soul-decorative-line-sales {
        top: 28.5%;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 121%; */
    }

    .sales-tools-decorative-line {
        top: 54.8%;
    }

}

@media screen and (min-width:2300px) {


    .sales-tools-decorative-line {
        top: 53.8%;
    }

}

.team-size-drop:focus{
    color:#000000 !important
}

.team-size-drop:hover{
    color:#000000 !important
}

.focus-black{
    outline: none !important;
}
.focus-black:focus{
    outline: none !important;
}

.rating-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1em;
    gap: 1em;
    font-family: Arial, sans-serif;
  }
  
  .nps-rating-value {
    font-family: Poppins;
    font-size: 2.5em;
    font-weight: 400;
    line-height: normal;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    margin: 0px;
  }
  
  .stars {
    display: flex;
    grid-gap: .5em;
    gap: .5em;
  }
  
  .star {
    width: 1.5em !important;
    height: 1.5em !important;
  }
.nps-rating-heading{
    font-family: Poppins;
    font-size: .5em;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    background-color: #000000;
    text-align: center;
    padding: 0em 1em;
    border-radius: 1em;
    margin: 0px;
    height: 2em;
    padding-top: .35em;
}
  
@media (max-width: 1010px) {
    .sales-navbar-web {
        padding: 20px 10px 20px 10px
    }
}
@media (max-width: 880px) {
    .sales-navbar-web {
        display: none !important;
    }
    .sales-navbar-drawer {
        display: block !important;
    }
    .hide-nps-title{
        display: none !important;
    }
    .nps-container{
        flex-direction: column !important;
    }
    .sales-page-navbar-button{
        width: 100%;
    }

}
.soul-report-root {
    font-size: 16px;
}

.soul-report-container-view {
    width: 100%;
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    grid-gap: .25em;
    gap: .25em;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    align-items: center;
}

.soul-report-container-view h4 {
    text-align: center;
    font-size: 1.25em;
    font-weight: 500;
}

.soul-report-answer-main-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.soul-report-answer {
    width: 100%;
    max-width: 19%;
    min-width: 10em;
    height: 8em;
    margin: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
}
.soul-team-report-answer {
    width: 17% !important;
    min-width: 10em; 
    height: 4em;
    text-overflow: ellipsis;
    display: flex;
    justify-content: start;
    align-items: center !important;
    flex-direction: row;
    grid-gap:1em;
    gap:1em;
    padding: 1em 1em;
}
.soul-answer {
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.soul-team-answer{
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.15em;
    font-family: Poppins !important;
    font-weight: 400;
}

.value-gradient {
    border: 1px solid rgba(255, 245, 210, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 245, 210, 0.5) 100%);
}

.strength-gradient {
    border: 1px solid rgba(255, 228, 229, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 228, 229, 0.5) 100%);
}

.personality-gradient {
    border: 1px solid rgba(208, 255, 248, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(224, 255, 250, 0.5) 100%);
}

.knowledge-gradient {
    border: 1px solid rgba(255, 245, 210, 1);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(223, 234, 255, 0.5) 100%);
}

.soul-report-tab-switcher {
    display: flex;
    border: 1px solid #C9D0DA;
    border-radius: .5em;
    overflow: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: .75em 1em;
    grid-gap: 1em;
    gap: 1em;
    background-color: #FFFFFF;
  }
  .soul-report-switch-button,.soul-report-switch-button:hover,.soul-report-switch-button:focus {
    border: none;
    padding: .5em 1em;
    cursor: pointer;
    font-size: 1.25em !important;
    border-radius: 0;
    font-family: Poppins !important;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: none !important;
    height: 3em;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
  }
  
  .selected-soul-active-tab,.selected-soul-active-tab:hover,.selected-soul-active-tab:focus {
    background: #4CCB1F;
    box-shadow: 11px 9px 10.9px -1px #4CCB1F4D;
    color: white;
    border-radius: .5em;
    height: 3em;
    font-size: 1.25em !important;
    font-family: Poppins !important;
    font-weight: 500;
    border: none;
    outline: none;
  }
.soul-report-card-title{
    color: #000;
    font-family: Poppins !important;
    font-size: 1.5em;
    font-weight: 600;
    line-height: normal;
}
.soul-report-container-card-container {
    width: 100%;
    padding: 1em 1em;
    display: flex;
    flex-direction: column;
    grid-gap: .25em;
    gap: .25em;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    align-items: center;
}
.comparison-menu-container .ant-select-selector,.comparison-menu-container .ant-select-selector:hover,.comparison-menu-container .ant-select-selector:focus,.comparison-menu-container .ant-select-selector:focus-visible,.comparison-menu-container .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid rgba(170, 186, 198, 1) !important;
    font-weight: 400;
    font-size: 1em;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-bottom: 0.375em !important;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-item{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-top: 0.375em !important;
}
.comparison-menu-container .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #868686 !important;
    font-weight: 400 !important;
    font-size: .85em;
    font-family:'Poppins';
}
.soul-report-active-btn,.soul-report-active-btn:hover,.soul-report-active-btn:focus{
    background: rgba(76, 203, 31, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1.25em;
    font-weight: 600;
    border-radius: .5em;
    line-height: normal;
    font-family: Poppins;
    padding: .5em 1em;
    outline: none;
    border: none;
    height: 2.5em;
}
.comparison-card-title{
    color: rgba(28, 28, 28, 1);
    font-weight: 600;
    font-size: 1.5em;
    font-family: Poppins;
    line-height: normal;
    margin: 0px;
}
@media screen and (max-width:1160px) {
    .soul-report-answer-main-section {
        flex-wrap: wrap;
    }
}
@media screen and (max-width:960px) {
    .soul-report-root {
        font-size: 14px;
    }
}

@media screen and (max-width:600px) {
    .soul-report-answer {
        width: 100%;
        min-width: none;
    }
    .soul-report-root {
        font-size: 12px;
    }
}
.thank-you-root{
    font-size: 16px !important;
}

.thank-you-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 0;
    height: 100vh;
    overflow: auto;
    padding:5em 5em !important;
}
.content-container{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}
.thank-you-title{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 4em;
    color: #0E0E2C;
    margin: 0;
}
.thank-you-sub-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5em;
    line-height: 2em;
    color: #0E0E2C;
    margin: 0;
}
.thank-you-paragraph{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.15em;
    line-height: 1.55em;
    color: #0E0E2C;
    text-align: left;
    margin: 0;
}
.bg-container{
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 31px 10px #7B589D29;
    width: 55% !important;
    /* max-height: 30rem; */
    padding: 0px 10px;
}
.thank-you-content-container{
    width: 38% !important;
}
.thank-you-bg-image{
    width: 70% !important;
    height: 65% !important;

}
.back-to-home-btn{
    margin-top: 2rem !important;
    border-radius: 8px;
    background-color: #4CCB1F;
    color: #fff;
    padding: 12px 24px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: 1.25em;
    text-align: center;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
}
.thank-you-logo-image{
    height: 6em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 12.675em;
    object-position: center;
    object-fit: contain;
}

@media (max-width:960px) {
    .thank-you-root{
        font-size: 14px !important;
    } 
    .thank-you-bg-image{
        width: 100%;
        height: 100%;
    
    }
    .bg-container{
        width: 39% !important;
    }
    .thank-you-content-container{
        width: 59% !important;
    }
    .thank-you-title{
        font-size: 3em;
    }
    .thank-you-sub-title{
        font-size: 1.5em;
    }
    .thank-you-paragraph{
        font-size: 1em;
    }
}
@media (max-width:600px) {
    .thank-you-root{
        font-size: 12px !important;
    } 
    .bg-container{
        display: none !important;
    }
    .thank-you-content-container{
        width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .thank-you-container{
        background: rgba(176, 203, 31, 0.2);
    }
    .thank-you-paragraph{
        text-align: center;
    }
    .thank-you-title{
        font-size: 2.5em;
    }
    .thank-you-sub-title{
        font-size: 1.5em;
    }
    .thank-you-paragraph{
        font-size: 1em;
    }
    .button-div{
        justify-content: center !important;
        align-items: center !important;
    }
}
@media (max-width:480px) {
    .thank-you-root{
        font-size: 10px !important;
    } 
}

.token-input-field .ant-input,.token-input-field .ant-select-selector{
    height: 36px !important;
}

.select-employee-survey .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    font-size: 1em !important;
}

.user-edit-icon-btn{
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #B0CB1F;
    margin-top: 33px;
}

.user-edit-icon-btn svg{
    width: 18px;
    height: 18px;
    fill: #B0CB1F;
}

.value-counter{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 5px 6px 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px;
}

.value-survey-card{
    position: relative;
    border-radius: 8px;
}

.value-survey-card:hover .ant-card-body{
    background-color: #4ccb1f !important;
    border-radius: 8px;
}

.value-active-card .ant-card-body{
    background-color: #4ccb1f !important;
    border-radius: 8px;
}

.value-survey-card:hover .value-card-text{
    color: #fff;
}

.value-active-card .value-card-text{
    color: #fff !important;
}
.value-survey-card .ant-card-body{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.value-card-text{
    color: #1F1F1F;
    font-size: 1em;
    width: 85%;
}
.main-container-row {
    margin-top: 15px;
}

.survey-section-container {
    margin-top: 5vh;
    overflow-y: auto;
    max-height: calc(90vh - 280px);
    padding-bottom: 3vh;
}

.survey-section-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.survey-section-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.soul-survey-edit-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    color: #141414;
    text-align: center;
    margin-top: 0.5em;
}

.soul-survey-edit-head span {
    font-weight: 700;
}

.survey-index {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    color: #1F1F1F;
    margin-top: 10px !important;
}

.survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1.5px solid #BFBFBF;
    border-radius: 7px;
    width: 484px !important;
    height: 60px !important;
    cursor: pointer;
}

.survey-select-filed .ant-select-selection-search-input {
    width: 484px !important;
    height: 60px !important;
}

.survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-top: 14px;
}

.save-step-2-button {
    padding: 10px 30px;
    height: 60px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}

.save-step-2-button:hover {
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}

.save-step-2-button:focus {
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}

.soul-survey-go-back-button {
    padding: 10px 30px;
    height: 60px;
    background: #fff;
    border: 2px solid #8C8C8C;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
}

.soul-survey-go-back-button:hover {
    background: #fff;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.soul-survey-go-back-button:focus {
    background: #fff;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.back-icon-survey {
    /* position: absolute; */
    /* left: 11px; */
    cursor: pointer !important;
    top: 15px !important;
    margin-left: 0.82em;
}

.enter-value-mobile-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #141414;
    display: none;
}

@media (max-width:1700px) {
    .soul-survey-edit-head {
        font-size: 28px;
        line-height: 44px;
    }

    .survey-index {
        font-size: 20px;
        line-height: 39px;
        margin-top: 4px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 364px !important;
        height: 43px !important;
    }

    .survey-select-filed .ant-select-selection-search-input {
        width: 364px !important;
        height: 43px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 16px;
        line-height: 22px;
        padding-top: 10px;
    }

    .save-step-2-button {
        height: 50px;
        font-size: 20px;
        line-height: 24px;
    }

    .soul-survey-go-back-button {
        height: 50px;
        font-size: 20px;
        line-height: 24px;
    }
}

@media (min-width:960px) {
    .survey-section-container-margin-left {
        margin-left: 249px;
        transition-duration: 1s;
        padding-right: 25em !important;
    }

    .custom-alignment {
        margin-left: -5em;
    }
}

@media (max-width:650px) {
    .soul-survey-edit-head {
        font-size: 28px;
        line-height: 44px;
    }

    .survey-section-container {
        margin-top: 3vh;
    }

    .survey-index {
        font-size: 20px;
        line-height: 24px;
        margin-top: 5px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        max-width: 280px !important;
        min-width: 100% !important;
        height: 40px !important;
    }

    .survey-select-filed .ant-select-selection-search-input {
        max-width: 280px !important;
        min-width: 100% !important;
        height: 38px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
    }
}

@media (max-width:480px) {
    .main-container-row {
        margin-top: 5px;
    }

    .survey-section-container {
        margin-top: 2vh;
    }

    .enter-value-mobile-head {
        display: block;
    }

    .back-icon-survey {
        top: -30px !important;
    }

    .soul-survey-edit-head {
        margin-top: 0.5em;
        font-size: 20px;
        line-height: 30px;
        max-width: 290px;
        width: 90%;
        /* padding:10px 20px ; */
        background: #F5F5F5;
        border-radius: 11px;

    }

    .survey-index {
        font-size: 13px;
        line-height: 20px;
        margin-top: 5px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-width: 100% !important;
        height: 35px !important;
        border-radius: 3.5px;
    }

    .survey-select-filed .ant-select-selection-search-input {
        min-width: 100% !important;
        height: 30px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 13px;
        line-height: 20px;
        padding-top: 6px;
    }

    /* .save-step-2-button{
        height: 45px;
        width: 80% !important;
        font-size: 20px;
        line-height: 24px;
        border: 1.26414px solid #4CCB1F;
        border-radius: 93px;
        font-size: 16px;
        line-height: 24px
    } */
    .soul-survey-go-back-button {
        height: 45px;
        font-size: 20px;
        line-height: 24px;
        width: 80% !important;
        border: 1.26414px solid #BFBFBF;
        border-radius: 93px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 2em !important;
    }
}

@media (max-width:360px) {
    .soul-survey-edit-head {
        width: 80%;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-width: 100% !important;
        width: 210px !important;
        height: 35px !important;
        border-radius: 3.5px;
    }

    .survey-select-filed .ant-select-selection-search-input {
        min-width: 100% !important;
        width: 210px !important;
        height: 30px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 13px;
        line-height: 20px;
        padding-top: 6px;
    }
}

.cs-col-section{
    width: 100%;
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cs-dropdown-employee{
    width: 30em !important;
    max-width: 90% !important;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-search-input{
    height: 100% !important;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-item{
    margin-top: 6px;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-placeholder{
    margin-top: 6px;
}

.cs-dropdown-employee .ant-select-selector{
 
    height: 44px !important;
    border: 1.24587px solid #BFBFBF !important;
    border-radius: 5.81405px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 27px;
    text-align: left;
    color: #303030;
    border-color:#BFBFBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer !important;
  }

  .cs-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cs-action-button{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25em;
    line-height: normal;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    height: 3em !important;
    margin: 0.8em;
    width: 220px;
  }
  .cs-action-button:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
  .cs-action-button:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
.toolcontent img{
        object-fit: fill;
        width: 100%;
}
.impact-body-padding{
        padding:0px 16px
}

.impact-row{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
}
.web-screen{
        display: block;
}
.mobile-screen{
        display: none;
}
.tool-body{
        padding: 15px 30px;
}
.space-between-row-impact{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        grid-gap: 10px;
        gap: 10px; 
        justify-content: space-between;
        align-items: center;
}

.space-between-row-impact-ui-flow-tool{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        grid-gap: 10px;
        gap: 10px; 
        justify-content: space-between;
        align-items: center;
}

.selector-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap:10px;
        gap:10px;
        padding: 5px 10%;
}

.drop-field{
        width:100%;
        max-width:100%;
        overflow: hidden;
}
.drop-field div{
        margin: 0px !important;
        padding: 0px !important;
        max-width:100%
}

.compare-div{
        width: 35px !important;
        height: 30px !important;
        min-width: 35px !important;
        min-height: 30px !important;
        cursor: pointer;
        padding: 0px;
        border: none;
        background: transparent !important;
        /* margin: 0px 50px; */
}

.compare-div img{
        width: 100%;
        height: 100%;
}

.employee-selector{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        grid-gap: 5px;
        gap: 5px;
        
}
.employee-selector .ant-select-selector{
        border-radius: 7px !important;
        border: 1.5px solid #BFBFBF !important;
        text-align: left !important;
        cursor: pointer !important;
}
.employee-selector input {
        cursor: pointer !important;
}
.employee-selector .ant-select-selection-item,.ant-select-selection-placeholder{
 padding: 0px 10px !important;
 font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 14px !important;
line-height: 32px !important;
color: #595959 !important;
}

.employee-selector .ant-select-selection-item div{
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
}

.impat-tool-titile{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 52px;
        color: #141414;
        margin: 0px;
}

.tab-container{
        background-color: #F0F0F0;
        border-radius: 8px;
        padding: 6px 7px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
}

.tab-btn{
        background-color: transparent;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        color: #8C8C8C;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        white-space: nowrap;
        height: 53px;
        border: 1px solid transparent;
        cursor: pointer;
}

.tab-btn:hover{
        background-color: transparent; 
        color: #8C8C8C;
        border: 1px solid transparent;
}

.tab-btn:focus{
        background-color: transparent; 
        color: #8C8C8C;
        border: 1px solid transparent;
}

.selected-tab-btn{
        color: #4CCB1F !important;
        background: #FFFFFF !important;
        box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14) !important;
        border-radius: 5px !important;
}

.tool-content{
        width: 100%;
}

.card-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: content-box;
        justify-content: space-between;
        align-items: center;
        margin-top: 75px;
}

.card-gap{
        margin:30px 0px;
}

.team-section-gap{
        margin:0px 12.5px;
        width: 100% !important;
}

.impact-header-card{
        width: 100%;
        box-sizing: border-box;
        border: 4px solid #02D7A4; 
        border-radius: 25px;
        padding: 50px 50px 20px 50px;
        margin-top: 80px;
        position: relative;
}

.impact-header-card *{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        /* color: #1F1F1F; */
}

.profile-img{
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        margin: 0px;
}

.header-border-div{
        position: absolute;
        top: -26px;
        background:transparent;
        width: 100%;
        left: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
}

.compare-border-div{
        top: -36px;
}

.border-inner-content{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        grid-gap: 10px;
        gap: 10px;
        justify-content: center;
        align-items: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: #f6fbff;
        padding: 0px 20px;
        box-sizing: border-box;
}

.header-border-label{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
        background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        flex: none;
        flex-grow: 0;
        margin: 0px;
}

.compare-border-label{
        font-weight: 700;
        font-size: 25px;
        line-height: 128.5%;
}

.impact-card{
        width: 47%;
        box-sizing: border-box;
        border: 4px solid #009AF1;
        border-radius: 25px;
        position: relative;
        padding: 20px;
}

.compare-full-width{
        width: 100% !important;     
}

.overflow-card{
        padding: 10px 30px 0px 30px;
        min-height: 350px;
        height: 350px; 
        overflow: auto; 
}

.overflow-card::-webkit-scrollbar {
        height: 4px;
        width: 4px !important;
}
      
.overflow-card::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
padding-right: 10px;
margin-bottom:10px;
margin-top:10px
}

.overflow-card::-webkit-scrollbar-thumb {
border-radius: 10px;

background-color:#b1b0b0e1;
}


.impact-card *{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        /* color: #1F1F1F; */
}



.sub-border-div{
        position: absolute;
        top: -26px;
        background:#f6fbff;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        left: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 15px;
}

.sub-border-label{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 46px;
        color: #009AF1;
        flex: none;
        flex-grow: 0;
        margin: 0px;
}

.bread-crubs-container{
        position: fixed;
        top: 20px;
        left: 50%;
        right: 50%;
        z-index: 1000;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        grid-gap: 20px;
        gap: 20px;
        align-items: center;
        justify-content: center;
}

.bread-crubs-tab{
        border-radius: 5px;
        padding: 5px 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        color: #8C8C8C;
        background: transparent;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        border: none;
}
.bread-crubs-tab:hover{
        background: transparent;
        color: #8C8C8C;   
}
.bread-crubs-tab:focus{
        background: transparent;
        color: #8C8C8C;   
}
.active-bread-crubs{
        background: #E1FFD7;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        color: #6BB96A;
}
.active-bread-crubs:hover{
        color: #6BB96A;   
        background: #E1FFD7;
}
.active-bread-crubs:focus{
        color: #6BB96A !important;   
        background: #E1FFD7 !important;
}

@media (min-width:1800px) {
        .tab-container{
                margin-top: 60px !important;
        }
}

@media (min-width:1300px) {
        .tab-container{
                margin-top: 40px;
        }
        .tool-content{
                max-width: 1292px;
        }
        .tool-logo{
                max-width: 1250px;
                max-height: 328px;
        }
      
}

@media screen and (max-width:950px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: 37px;
        }
}

@media screen and (max-width:950px) and (min-height:760px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: 0px;
        }
}

@media screen and (max-width:950px) and (min-height:1360px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: -30px;
        }
}

@media screen and (min-width:950px) and  (max-width:1300px) {
        .bread-crubs-container{
                grid-gap: 10px;
                gap: 10px;
        }
        .bread-crub{
                grid-gap: 10px;
                gap: 10px;
        }
        .card-wrapper{
                margin-top: 40px;
        }
        
        .impact-header-card{
                margin-top: 65px;        
        }
        .tool-content{
                max-width: 1292px;
        }
        .tool-logo{
                width: 100%;  
        }
        .tool-body{
                padding: 5px 0px;
        }
        .selector-container{
                padding: 5px 0px;
        }
        .compare-border-label{
                font-size: 22px;
        }

        .compare-border-div{
                top: -30px;
        }
}

@media screen and (min-width:800px) and  (max-width:950px) {
        
        .card-wrapper{
                margin-top: 35px;
        }
        .impact-header-card{
                margin-top: 60px;        
        }
        .impat-tool-titile{
                font-size: 28px; 
        }
        .tool-content{
                max-width: 950px;
        }
        .tool-logo{
                width: 100%;
        }
        .tool-body{
                padding: 5px 0px;
        }
        .tab-btn{
                padding: 5px !important;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content; 
        }
        .impact-header-card{
                padding: 30px 30px 15px 30px;
                border: 2px solid #02D7A4; 

        }
        .sub-border-div{
                left: 15px;
                padding: 0px 8px 0px 15px;

        }

        .impact-card {
                padding: 20px 15px 0px 15px;
                border: 2px solid #009AF1; 
                /* min-height: 200px !important;
                height: 200px !important; */
        }

        .overflow-card{
                padding: 0px 15px 0px 15px;
                min-height: 220px;
                height: 220px; 
                overflow: auto; 
        }

        .impact-header-card *{
                font-size: 14px;
        }
        
        .impact-card *{
                font-size: 14px;
        }

        .header-border-label{
                font-size: 18px !important;    
        }
        .sub-border-label{
                font-size: 16px !important;
        }
        .selector-container{
                padding: 5px 0px;
        }

        .compare-border-label{
                font-size: 15px;
        }

        .compare-border-div{
                top: -22px;
        }
        .compare-border-div img{
                display: none;
        }
}

@media screen and (min-width:600px) and  (max-width:800px) {

        .card-wrapper{
                margin-top: 35px;
        }
        .impact-header-card{
                margin-top: 60px;        
        }
        .impat-tool-titile{
                font-size: 28px; 
        }
        .tool-content{
                max-width: 800px;
        }
        .tool-logo{
                width: 100%;
        }
        .tool-body{
                padding: 5px 0px;
        }
        .tab-btn{
                padding: 5px !important;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content; 
        }
        .impact-header-card{
                padding: 15px 8px 5px 8px;
                border: 2px solid #02D7A4; 
                border-radius: 15px;

        }
        .sub-border-div{
                left: 8px;
                padding: 0px 8px 0px 8px;
        }

        .impact-card {
                padding: 20px 8px 0px 0px;
                border: 2px solid #009AF1; 
                border-radius: 15px;
        }

        .overflow-card{
                padding: 0px 15px 0px 15px;
                min-height: 220px;
                height: 220px; 
                overflow: auto; 
        }

        .impact-header-card *{
                font-size: 14px;
        }
        
        .impact-card *{
                font-size: 14px;
        }

        .header-border-label{
                font-size: 14px !important;    
        }
        .sub-border-label{
                font-size: 14px;
        }
        .selector-container{
                padding: 5px 0px;
        }

        .compare-border-label{
                font-size: 15px;
        }

        .compare-border-div{
                top: -22px;
        }
        .compare-border-div img{
                display: none;
        }
}

@media (max-width:950px) {
        .impact-card{
                width: 100%;
        }
        .bread-crubs-container{
                display: none !important;
        }
}

@media screen and (max-width:600px) and (max-height:768px) {

        .space-between-row-impact-ui-flow-tool{
                margin-top:10px
        }

}

@media screen and (max-width:600px) and (max-height:500px) {
        .space-between-row-impact{
                margin-top: 0px !important;
                /* padding: 10px; */
        }

        .space-between-row-impact-ui-flow-tool{
                margin-top:50px
        }
      
}





@media screen and (max-width:800px) and (max-height:500px) {
        .space-between-row-impact{
                margin-top: 15px;
        }

}

@media screen and (max-width:600px)  {
       
        .impact-back{
                margin-left: -10px;
        }

        .impact-body-padding{
                padding:0
        }
        .impat-tool-titile{
                font-size: 18px;
                line-height: 0px !important;
                color: #141414;
                white-space: nowrap;
                margin: 0px !important;
        }
        .web-screen{
                display: none;
        }
        .mobile-screen{
                display: block;
                /* width: 100%; */
        }
        .tool-body{
                width: 100%;
                padding: 0px;
        }
        .tool-content{
                margin-top: 20px;
        }
        .tool-logo{
                width: 298px;
                height: 198px;
                max-width: 100% !important;
        }
        .tab-container{
                background-color: #F0F0F0;
                border-radius: 4.10042px;
                padding: 4px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 20px;
        }
        .tab-btn{
                background-color: transparent;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                white-space: nowrap;
                height: 27px;
                padding: 4.5px ;
        }
        .impact-header-card{
             width: 100%;
             border-radius: 13.4563px;
             padding: 31px 21px 20px 21px;
             margin-top: 30px;
             border: 1.9px solid #02D7A4; 
        }
        .header-border-label{
                font-weight: 700 !important;
                font-size: 16px !important;
                line-height: 24px !important;
        }
        .profile-img{
                width: 20.83px;
                height: 20.83px ;
        }
        .header-border-div{
               top:-13px
        }
        .border-inner-content{
                padding: 0px 12px;
        }
        .impact-header-card *{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-align: justify;
                /* color: #1F1F1F; */
        }
        .selector-container{
                max-width: 257px;
                width: 100%;
                padding: 0px;
        }
        .compare-border-div{
                top: -25px;
        }
       
}



@media screen and (max-width:420px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:10px
        }
}

@media screen and (max-width:320px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:20px
        }
}

@media screen and (max-width:320px) and (min-height:800px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:0px
        }
}

@media screen and (max-width:420px) and (min-height:1000px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:-25px
        }
}

@media screen and (min-width:1000px) and (max-height:568px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:15px
        }
}








.tool-body-padding{
    padding:0px 16px
}
.tool-background{
    background:#F7F7F7
}
.space-between-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px; 
    justify-content: space-between;
    align-items: center;
}
.text-render-margin{
    margin: 15px;
}
.tool-titile{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    color: #141414;
    margin: 0px;
  }

.soul-section{
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-top: 41px; */
    padding: 20px 0px;
}
.soul-top-margin{
    margin-top: 41px;
}

.soul-fluid-container{
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1460px;
}

.first-section{
    flex-wrap: nowrap !important;
    display: flex;
    align-items: center;
}


.top-section-container{
    width: 45%;
    margin: 15px;
    /* margin: 21px; */
}

.inst-header{
    font-size: 22px;
}

/* .top-section-container li{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    color: #1F1F1F;
} */

.top-section-container video{
    max-height: 431px;
    max-width: 762px;
    width: 100%;
    height: 100%;
    border-radius: 19px !important;
}

.bottom-section-container{
    width: 100%;
    margin: 15px;
    /* margin: 21px; */
    /* background-color: red; */
}

.soul-value-section{
    width: 321px;
    /* width: 25% !important; */
    background: #FFFFFF !important;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06) !important;
    border-radius: 19px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.soul-value-section video{
    width: 310px !important;
    margin: 5px;
}

.index-form{
    /* max-width: 1100px; */
    background: #FFFFFF;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06);
    border-radius: 19px;
    width: 100% !important;
    overflow: hidden;
}

.soul-wrap{
    display: flex;
    flex-direction: row;
}

.form-wrap-section{
    /* max-width: 1100px; */
    background: #FFFFFF;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06);
    border-radius: 19px;
    width: 46% !important;
    overflow: hidden;
   
}

.soul-tool-title-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    /* background-color: green; */
    padding: 50px;
}

.soul-tool-title-label{
    max-width: 498px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #141414;
    margin: 0px;
   
}

.soul-form-carousel .slick-list{
    width: 100% !important;
}
.soul-form-carousel .ant-carousel .slick-dots-bottom{
    top: -15px !important;
}

.submitResponseButton{
    width: 140px;
    height: 50px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}

.submitResponseButton:hover{
    background: #4CCB1F;
    color: #FAFAFA;
    border: 2px solid #4CCB1F;
}

.submitResponseButton:focus{
    background: #4CCB1F;
    color: #FAFAFA;
    border: 2px solid #4CCB1F;
}
.submitResponseButton-back{
    width: 140px;
    height: 50px;
    background: #FAFAFA;
    border: 2px solid #8C8C8C;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
}

.submitResponseButton-back:hover{
    background: #FAFAFA;
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}

.submitResponseButton-back:focus{
    background: #FAFAFA;
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}
.specific-tool .slick-current{
    margin-top: 80px;
  }
  
.specific-tool .ant-carousel .slick-dots-bottom{
    top:12px;
    height: 10px;
  }
    
.specific-tool .ant-carousel .slick-dots li button{
    background: #D9D9D9;
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
  
.specific-tool .ant-carousel .slick-dots li.slick-active{
    width: 17px;
  }
  
.specific-tool .ant-carousel .slick-dots li.slick-active button{
    background: #4CCB1F;
  }
  .specific-tool .ant-carousel .slick-dots li button:before{
    color: #ffff;
    visibility: hidden;
  }
  .specific-tool .ant-carousel .slick-dots{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .carouselWrapper{
    width:90%; 
    margin:auto;
  }

  .specific-tool .ant-carousel .slick-prev{
    position: absolute;
    top: -18px;
    display: block !important;
    border: none !important;
    background: transparent !important;
  }
  
  .specific-tool .ant-carousel .slick-next{
    position: absolute;
    top: -18px;
    display: block !important;
    border: none !important;
    background: transparent !important;
  }


  .specific-tool .ant-btn-background-ghost{
    color: #8C8C8C !important;
    border: none !important;
    box-shadow: none !important;
  }

.ant-popover-inner{
    padding: 15px;
    }



.soul-space-btw{
    display: flex;
    justify-content: space-between;
}

.tool-bread-crubs-container{
    position: fixed;
    top: 20px;
    left: 50%;
    right: 50%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.tool-bread-crubs-tab{
    border-radius: 5px;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #8C8C8C;
    background: transparent;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: none;
}
.tool-bread-crubs-tab:hover{
    background: transparent;
    color: #8C8C8C;   
}
.tool-bread-crubs-tab:focus{
    background: transparent;
    color: #8C8C8C;   
}
.tool-active-bread-crubs{
    background: #E1FFD7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #6BB96A;
}
.tool-active-bread-crubs:hover{
    color: #6BB96A;   
    background: #E1FFD7;
}
.tool-active-bread-crubs:focus{
    color: #6BB96A !important;   
    background: #E1FFD7 !important;
}
.tool-padding-form{
    padding-right: 30px;
}

.new-set-btn{
    padding: 0px 15px;
    height: 40px;
    border: 1.5px solid #8C8C8C;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    background: #fff;
}
.new-set-btn:focus,.new-set-btn:hover{
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}
@media screen and (max-width:1300px) {
    .soul-space-btw{
        flex-direction: column !important;
    }
    .bottom-section-container{
        width: 100% !important;
    }
    .form-wrap-section{
        width: 99% !important;
        margin: 15px 5px;
    }
}

@media screen and (min-width:950px) and  (max-width:1300px) {
    .tool-bread-crubs-container{
            grid-gap: 10px;
            gap: 10px;
    }
    .tool-bread-crub{
            grid-gap: 10px;
            gap: 10px;
    }
}

@media screen and (min-width:800px) and  (max-width:950px) {
    .soul-tool-title-label{
        font-size: 23px;
    }
    .tool-titile{
            font-size: 28px; 
    }
}

@media (max-width:950px) {
    .top-section-container{
        width: 100%;
    }
    .text-render-margin{
        margin: 5px 20px;
    }
    .soul-top-margin{
        margin-top: 5px;
    }

    .tool-background{
        background:transparent
    }
    .tool-bread-crubs-container{
            display: none !important;
    }
}
@media screen and (min-width:600px) and  (max-width:800px) {
    .soul-tool-title-label{
        font-size: 20px;
    }
    .tool-titile{
        font-size: 28px; 
    }
}

@media screen and (max-width:600px)  {
    .soul-value-section{
        padding: 0px;
        box-shadow: none !important;
    }
    .values-wrapper{
        padding: 0px !important;
    }
    .submitResponseButton{
        max-width: 217px;
        width: 90%;
        border: 1.26414px solid #4CCB1F;
        border-radius: 93px;
    }
    .submitResponseButton-back{
        max-width: 217px;
        width: 90%;
        border: 1.26414px solid #8C8C8C;
        border-radius: 93px;
    }
    .soul-tool-title-section{
        flex-direction: column;
    }
    .tool-web-screen{
        display: none !important;
    }
    .soul-tool-title-label{
        font-size: 18px !important;
    }
    .inst-header{
        font-size: 18px;
    }
    /* .top-section-container li{
        font-size: 14px;
    } */
    .tool-body-padding{
            padding:0 0.7em;
    }
    .index-form{
        box-shadow: none !important;
        padding: 0px !important;
    }
    .form-wrap-section{
        box-shadow: none !important;
        padding: 0px !important;
    }
    .tool-padding-form{
        padding-right: 30px;
    }
    .tool-titile{
        font-size: 18px;
        line-height: 20px !important;
        color: #141414;
        white-space: normal;
        margin: 0px !important;
        text-align: center !important;

    }
}

.ant-popover-buttons .ant-btn-sm{
    border: 1px solid rgba(0, 0, 0, 0.85);
    border-color: #141414;
}


.ant-popover-buttons .ant-btn-sm:hover{
    background: #FFFFFF;
}



.ant-popover-buttons .ant-btn-sm:hover span, .ant-popover-buttons .ant-btn-sm:active{
    color: rgba(0, 0, 0, 0.85);
}



.ant-popover-buttons .ant-btn-primary{
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.ant-popover-buttons .ant-btn-primary:hover span{
    color: #fff;
}

.ant-popover-buttons .ant-btn-primary:hover{
    background: #4CCB1F;
    color: #fff;
    border: 1px solid #4CCB1F;
}





.extra-padding{
    padding-left: 50px;
}
.green-action-btn,  .green-action-btn:focus, .green-action-btn:active, .green-action-btn:hover{
    border-radius: 0.375em;
    border: 2px solid #4CCB1F;
    background: #4CCB1F;
    display: inline-flex;
    padding:1em 1.625em;
    justify-content: center;
    align-items: center;
    color:  #FAFAFA !important;
    font-family: Poppins;
    font-size: 1.4375em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
}

.grey-action-btn,  .grey-action-btn:focus, .grey-action-btn:active, .grey-action-btn:hover{
    border-radius: 0.375em;
    border: 2px solid #8C8C8C;
    background: #FFF;
    display: inline-flex;
    padding:1em 1.625em;
    justify-content: center;
    align-items: center;
    color:  #8C8C8C !important;
    font-family: Poppins;
    font-size: 1.4375em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
}

.in-edit-icon{
    cursor: pointer;
    max-width: 30px;
    max-height: 30px;
    margin-left: 10px;
}

.in-textarea{
    resize: none;
    height: 250px !important;
    border-radius: 1.375em !important;
    border: 1px solid rgba(90, 81, 81, 0.25) !important;
    background: transparent !important;
    color: #1F1F1F !important;
    font-family: Poppins !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: normal;
    width: 100%;
    padding: 10px;
    outline: none;
}

.row-btn-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.green-action-btn:disabled{
    color:  #8C8C8C !important;
}

.in-compare-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
}
.impact-narrative-spin{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    position: fixed !important;
}

.impact-narrative-spin .ant-spin-text{
    width: 60% !important;
    font-size: 20px;
    font-weight: 500;
    color: black;

}

.report-tool-impact-container{
    display: flex;
    flex-direction: column;
    grid-gap:16px;
    gap:16px;
    width: 100%;
    padding: 1px 20px;
}

.impact-tool-head{
    color: #141414;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    text-align: left;
}

.impact-tool-content{
    color: #1F1F1F;
    text-align: left;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0px;
}

.impact-tool-survey-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
}

.impact-tool-res-chip{
    border-radius: 8px;
    border: 1px solid #8C8C8C;
    padding:8px ;
    margin-bottom:10px;
}

@media screen and (max-width:960px)  {
    .in-edit-icon{
        width: 25.83px;
        height: 25.83px ;
    }
}


@media screen and (max-width:600px)  {
    .in-edit-icon{
        width: 20.83px;
        height: 20.83px ;
    }
    .in-compare-section{
        flex-direction: column;
    }
}

@media screen and (min-width:960px)  {
    .impact-narrative-spin-left-align{
        left: 170px !important;
    }
    
    .impact-narrative-spin-left-align .ant-spin-dot-spin{
        margin-left: 150px !important;
    }
}


.generate-btn{
    border-radius: 0.375em; 
    background: #4CCB1F; 
    width: -webkit-fit-content; 
    width: -moz-fit-content; 
    width: fit-content;
    padding:0.5em 1em; 
    text-align: center;
    color:  #FAFAFA !important;
    font-family: Poppins;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
    cursor: pointer;
}

.generate-btn-disabled{
    color:  #8C8C8C !important;
    background: #e1e1e1 !important;
    cursor: not-allowed;
}
.slide-tab-container{
    background-color: transparent;
    margin-top: 49px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 9.08px;
    gap: 9.08px;
    overflow: auto;
    width: 100%;
    padding: 0px;
}
.slide-tab-container::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
}
.slide-tab-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.slide-tab-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}

.slide-btn{
    background: #F0F0F0;
    border: 1.26414px solid #F0F0F0;
    border-radius: 5px;
    height: 37px;
    padding: 6.32069px 12.6414px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
    margin-bottom: 5px;
}

.slide-btn:hover{
    background-color: #F0F0F0; 
    color: #8C8C8C;
    border: 1.26414px solid #F0F0F0;
}

.slide-btn:focus{
    background-color: #F0F0F0; 
    color: #8C8C8C;
    border: 1.26414px solid #F0F0F0;
}

.active-slide-btn{
    background: #009AF1;
    color: #FFFFFF;
}

.active-slide-btn:focus{
    background: #009AF1 !important;
    color: #FFFFFF !important;
    border: 1.26414px solid #F0F0F0;
}

.mobile-impact-card{
    margin-top: 40px;
    border: 1.92233px solid #009AF1;
    width: 100%;
    
    position: relative;
    /* height: fit-content; */
    border-bottom-left-radius: 13.4563px;
    border-bottom-right-radius: 13.4563px;
    /* border-radius:13.4563px ; */
    box-sizing: border-box;
    /* padding: 0px 21px 0px 21px; */
    min-height: 100px;
    
}

.impact-text-container{
    position: absolute;
    background: #fff;
    top: -9px;
    right: 15px;
    left: 15px;
    padding: 0px 6px;
    /* width: 100%; */
    
}
.hidden{
    visibility: hidden;
    padding: 0px 21px 0px 21px;
}

.impact-text-container,.hidden *{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    color: #1F1F1F;
}

.user-compare-tab{
    max-width: 300px;
    width: 100%;
    margin-top: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-tab-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #BFBFBF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* background-color: aqua; */
}

.act-user-tab-label{
    color: #4CCB1F !important;
    text-decoration: underline;
}

@media screen and (max-width:320px)  {
    .header-border-label{
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .tab-btn{
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        height: 27px;
        padding: 3.5px ;
    }
    .slide-btn{
        border-radius: 5px;
        height: 35px;
        padding: 4.32069px 10.6414px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
    }
}
.one-one-one-home-view{
    font-size: 16px !important;
}

.one-on-one-left-section{
    width:72% !important;
}
.one-on-one-right-section{
    width:28% !important;
}
@media (max-width:1150px) {
    .one-on-one-left-section{
        width:100% !important;
    }
    .one-on-one-right-section{
        width:100% !important;
    }
}
.meeting-create-selction .ant-form-item-control-input-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.meeting-create-selction .ant-form-item{
    border: none !important;
}
.one-on-one-home-container{
    padding: 10px 5px ;
}
.one-on-one-heading-seaction{
    position: relative;
    margin: 0 0.6em 0 0.6em;
}
.back-icon-one-on-one{
    position: absolute;
    left: 0;
}

.back-icon-summary
{
    width: 13px;
    position: absolute;
    left: 30px;
}

.delete-modal-meeting .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
}

.one-on-one-feedback-form-title div p{
    width: 450px;    
    margin-bottom: 6px;
}

.agenda-list-container .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    cursor:default;
}

.one-on-one-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 3.75em;
    letter-spacing: -0.02em;
    color: #141414;
    margin-bottom: 10px;
}
.greeting-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 2.25em;
    letter-spacing: -0.02em;
    color: #141414;
    margin: 0;
}

.agenda-list-container .ant-collapse-content-box{
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;  
}




.past-meeting-data-container  .anticon-caret-down{
    color: #009AF1;
}

.meeting-note-dropdown-menu{
    border-radius: 8px;
    min-width: 290px;
}

.meeting-note-dropdown-menu-item{
    text-align: center;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.greeting-paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.375em;
    line-height: 2em;
    color: #141414;
    margin-top: -8px;
}

.schedule-one-on-one-container .ant-picker-input > input{
  font-family: poppins;
  padding-left: 10px;
}

.schedule-one-on-one-container .ant-picker-input{
    flex-direction: row-reverse;
}

.instant-btn{
    width: 142px;
    height: 48px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}



.notes-select-box input{
    position: absolute !important;
    top: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
    left: 0px !important;
    font-size: 14px !important;
    text-align: left;
    height: 100% !important;
  }

  .notes-select-box{
    min-width: 200px !important;
  }
  .notes-select-box .ant-select-selection-placeholder {
    text-align: left !important;
    flex: none;
    font-size: 14px;
  }

.instant-btn:hover{
    -webkit-filter: brightness(1.13);
            filter: brightness(1.13);
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}
.instant-btn:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}
.schedule-btn{
    width: 156px;
    height: 48px;
    background: #EFEFFD;
    /* box-shadow: inset 0px -1px 0.5px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: 1px solid #EFEFFD;
}
.schedule-btn:hover{
    -webkit-filter: brightness(1.04);
            filter: brightness(1.04);
    color: #4CCB1F;
    background: #EFEFFD;
    border-color: #EFEFFD;
}
.schedule-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border-color: #EFEFFD;
}
.one-on-one-tab .ant-tabs-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #BFBFBF;
}
.one-on-one-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4CCB1F;
    text-shadow:none;
}
.one-on-one-tab .ant-tabs-ink-bar{
    background: #4CCB1F !important;
    height: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.one-on-one-note-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#31D0AA !important;
    text-shadow:none;
}
.one-on-one-note-tab .ant-tabs-ink-bar{
    background: #31D0AA !important;
    height: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.one-on-one-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    position: relative;
    margin-top: -16px !important;
    overflow: hidden;
    padding-right: 10px;
    min-height: 485px;
    max-height:-webkit-fit-content;
    max-height:-moz-fit-content;
    max-height:fit-content;

  }
.one-on-one-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.one-on-one-meeting-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #FFFF !important;
    border-radius: 8px;
    border: 1px solid #FFFFFF !important;
    position: relative;
    margin-top: -16px !important;
    overflow: hidden;
    padding-right: 10px;
    min-height: 200px !important;
    max-height: 100% !important;

  }
.meeting-overflow::-webkit-scrollbar {
    height: 100%;
    display: none;
    width:5px;
  }
.meeting-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
padding-right: 10px;
margin-bottom:10px;
margin-top:10px
}

.meeting-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color:#D9D9D9;
}

.agenda-create-overflow::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.agenda-create-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.agenda-create-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-custom-ck::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.one-on-one-custom-ck::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.one-on-one-custom-ck::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-menu {
    background: #F5F5F5;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;
}
.one-on-one-menu .ant-dropdown-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #595959;
    text-align: center;
    padding-bottom: 10px;
}
.input-filter-one-on-one{
    background: #FFFFFF;
    border: "1px solid #D9D9D9";
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height:46px !important;
}
.one-one-filter-menu .ant-dropdown-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #BBBBBB;
    padding-bottom: 10px;
}

.one-on-one-selected-filter{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4CCB1F;
}



.one-one-filter-menu .ant-dropdown-menu-item-active{
    color: #141414;
}


.one-on-one-drop-dtn:hover{
    background: #FFFFFF;
    border: 1px solid #D9D9D9
}
.one-on-one-drop-dtn:focus{
    background: #FFFFFF;
    border: 1px solid #D9D9D9
}

/************************************/
        /* ONE ON ONE CARDS CSS */
/************************************/

.one-on-one-card-div{
    background: #FFFFFF;
    /* box-shadow: -6px 2px 14px -7px rgba(0, 0, 0, 0.09); */
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    margin: 10px;
    position: relative;
    /* min-width: 340px; */
}
.one-on-one-card-div:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.one-on-one-card-div .bookmark-disable{
    position: absolute;
    right: 10px;
    top: 10px;
}
.one-on-one-card-div .edit-one-on-one{
    position: absolute;
    right: 10px;
    top: 11px;
    display: none;
}
.one-on-one-card-div:hover .edit-one-on-one{
    display: block;
}
.one-on-one-card-div .card-data-container{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}
.view-meeting-icon{
    display: block;
}

.view-meeting-icon-hovered{
 display: none;
}

.one-on-one-card-div:hover .view-meeting-icon{
    display: none;
}

.one-on-one-card-div:hover .view-meeting-icon-hovered{
    display: block;
}
 .one-on-one-date-section{
    border-right:0.5px solid #cfcfcf;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-right: 10px;
    height: 95px;
    grid-gap: 0;
    gap: 0;
}
.one-on-one-date-section .one-on-one-date{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #141414;
    white-space: nowrap;
}
.one-on-one-date-section .one-on-one-time{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #009AF1;
    margin: 0;
    white-space: nowrap;
}
.one-on-one-card-div .one-on-one-title{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    text-align: left;
    color: #141414;
    line-break: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}               
.one-on-one-card-div .one-on-one-start-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 11.8962px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: .5px solid #4CCB1F;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.one-on-one-card-div .one-on-one-start-btn:hover{
    color: #fff;
    border: .5px solid #4CCB1F;
    background-color: #4CCB1F;
}
.one-on-one-card-div .agenda-button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #8C8CA2;
    cursor: pointer;
    padding-top: 4px;
    white-space: nowrap;
}
.one-on-one-card-div .agenda-button:hover{
    color: #B0B0D2;
}
.one-on-one-card-div .agenda-button:hover img{
    color: #B0B0D2;
}
.one-on-one-card-div .user-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #141414;
    white-space: nowrap;
    margin: 0;
}
.one-on-one-card-div .user-name span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.one-on-one-card-div .button-section{
    margin-top: 20px !important;
}
.meeting-user-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* align-items: center; */
    color: #141414;
}

.one-on-one-bookmark{
    background: #FFFFFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding: 15px 20px;
    /* height: 250px;
    margin-top: -190px; */
    height: 100%;
    position: relative;
}
.one-on-one-bookmark .link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    padding-top: 5px;
    cursor: pointer;
}
/************************************/
        /* ONE ON ONE  QUICK LINK CSS */
/************************************/

.one-on-one-quick-link-container{
    margin-top: 10px;
    background: #FFFFFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
    padding: 22px 20px;
}
.one-on-one-quick-link-container .link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
}
.one-on-one-quick-link-container .quick-link-button{
    padding: 15px 28px;
    background: #ECF1F4;
    border-radius: 8px;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content:start;
    align-items: start;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.one-on-one-quick-link-container .quick-link-button:hover{
    background: #EEF7FE;
}
.one-on-one-quick-link-container .quick-link-button:hover .quick-link-title{
    color: #161673;
}
.one-on-one-quick-link-container .quick-link-button .quick-link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E0E2C;
    margin: 0;
    white-space: nowrap;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.one-on-one-quick-link-container .quick-link-button .quick-link-icon{
    margin-top: 6px;
}


.one-on-one-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141414;
    margin: 0;
}
.one-on-one-agenda-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #0E0E2C;
    margin: 0;
    cursor: default;
    line-break: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.agend-list-div{
    background: #F5F5F5;
    border-radius: 8px;
    padding: 10px 15px;
    width: 100%;
}
.agenda-list-container{
    padding: 10px 50px;
}

.overflow-feedbacks{
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 10px;
} 

.one-on-one-note-tab .ant-tabs-tabpane-active{
    position: relative;
    top: 20px;
    margin-bottom: 30px;
}

.overflow-feedbacks::-webkit-scrollbar {
    height: 100%;
    width:5px;
    display: none;
  }
  .overflow-feedbacks::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.overflow-feedbacks::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}


.overflow-feedbacks .ant-collapse-borderless > .ant-collapse-item:first{

    margin-top: 0px !important;
}


.agenda-list-container svg{
    color: #4CCB1F;
    font-size: 15px;
    cursor: pointer;
    margin-top: 0px;
}

.meeting-filter-btn{
    border-radius: 35px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    padding: 6px 17px;
    border: 1px solid #31D0AA;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #31D0AA;
    height: 100%;
    background: #F5F5F5;
    white-space: nowrap;
}
.meeting-filter-btn:hover{
    background: #31D0AA;
    border:1px solid #31D0AA;
    color: #ffff;
}
.meeting-filter-btn:focus{
    background: #31D0AA;
    border:1px solid #31D0AA;
    color: #ffff;
}
.meeting_date_icon_inactive{
    display: block;
}
.meeting_date_icon_active{
    display: none;
}
.meeting-filter-btn:hover .meeting_date_icon_active{
    display: block;
}
.meeting-filter-btn:hover .meeting_date_icon_inactive{
    display: none;
}
.meeting-filter-btn:focus .meeting_date_icon_active{
    display: block;
}
.meeting-filter-btn:focus .meeting_date_icon_inactive{
    display: none;
}
.instant-meeting-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #141414;
}
.instant-mmeting-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141414;
}
.one-on-one-custom-time-picker .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-radius: 8px !important;
    height: 40px !important;
    
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-search-input{
    height: 40px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #d0d0d0 !important;
    padding-left: 0px !important;
    padding-top: 4.5px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 40px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-item{
    height: 40px !important;
    padding-top: 4.5px !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-radius: 8px !important;
    height: 52px !important;
    
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input{
    height: 52px !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input::-webkit-input-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input:-ms-input-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input::placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 52px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #303030 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search{
    left: 35px;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-placeholder{
    padding: 8px 10px 8px 25px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    padding-left: 35px;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-item{
    padding: 8px 10px 8px 25px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #303030 !important;
}
.one-on-one-meeting-title-input{
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #303030 !important;
    padding-left: 35px;
}
.one-on-one-meeting-title-input::-webkit-input-placeholder{
    color: #D9D9D9 !important;
}
.one-on-one-meeting-title-input:-ms-input-placeholder{
    color: #D9D9D9 !important;
}
.one-on-one-meeting-title-input::placeholder{
    color: #D9D9D9 !important;
}
.instant-form-icon{
    top:13px !important;
}
.meeting-instant-popup{
    padding: 30px;
}
.meeting-instant-popup-inner{
    padding: 10px 40px;
}
.instant-start-btn{
    margin-top: 50px;
    height: 48px;
    width: 120px !important;
    background: #4CCB1F;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.instant-start-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.instant-start-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.one-on-one-select-employee-dropdown-item{
    font-family: 'Poppins';
    font-weight: 500;
}
.custom-meeting-picker{
    margin-top: -35px;
    padding: 0px;
    font-family: 'Poppins';
    font-size: 12px;
    max-width: 440px;
    z-index: 1160 !important;
}
.custom-meeting-picker .ant-picker-panel-container{
    box-shadow:none;
    border-radius: none;
    border: none;   
    /* margin-left: 27px !important; */
}
.custom-meeting-picker .ant-picker-range-arrow{
    display: none;
}
.custom-meeting-picker .ant-picker-range-wrapper{
    /* min-width: 300px; */
    /* max-width: 540px; */
}
.custom-meeting-picker .ant-picker-date-panel .ant-picker-content{
    width:200px !important;
}
.custom-meeting-picker .ant-picker-cell {
    padding: 0px;
}
.custom-meeting-picker .ant-picker-date-panel .ant-picker-content th {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.custom-meeting-picker .ant-picker-date-panel{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.date-range-meeting{
    /* width: 98%; */
    /* margin: 0px 50px; */
}
.date-range-meeting input{
    z-index: 1100 !important;
}
.cancel-date-btn{
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    padding: 8px 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
    cursor: pointer;
}
.cancel-date-btn:hover{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.cancel-date-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.save-date-btn{
    height: 40px;
    width: 100px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    padding: 8px 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    cursor: pointer;
}
.save-date-btn:hover{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.save-date-btn:focus{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.notes-container{
  position:relative;
  left:22px;
}

.scheduleNext-link{
    color: #BFBFBF;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    margin-top: 15px;
}

.scheduleNext-link:hover{
    color: #4CCB1F;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    -webkit-transform: scale(1.1) ;
            transform: scale(1.1) ;
}



.scheduleNext-link:hover + .nextmeeting-link-arrow{
    color: #4CCB1F;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    -webkit-transform: scale(1.1) ;
            transform: scale(1.1) ;
}



/*********************************************/
/****************START MEETING****************/
/*********************************************/

.one-on-one-time-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #009AF1;
    margin: 0;
    margin-top: -10px;
}

.past-meeting-container{
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 570px;
    height: 100%;
    /* max-height: 100px; */
    transition: all .5s ease;
    padding: 15px 20px;
    margin: 30px 15px;
}

.past-meeting-container-expand{
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    height: 100%;
    width:100%;
    transition: all 0.8s ease;
    padding: 15px 20px 25px 20px;
}

.previous-meeting-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #141414;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
}
.previous-meeting-date{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    margin: 0;
}
.expand-meeting-arrow-up{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition: all .5s ease;
}
.expand-arrow-left{
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    transition: all .5s ease;
}
.meeting-tool-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
    margin: 0;
}


.meeting-soultip-container .ant-spin-nested-loading{
     width: 25%;
     position:absolute;
     z-index: 500;
     margin-right: 20px;
     right: 0px;   
     top:-60px;
     background: #FFFFFF;
     box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
}
.soultip-overflow{
    max-height: 90px !important;
}
.soultip-height{
    height: 100% !important;
    max-height: 250px !important;
}
.soultip-overflow::-webkit-scrollbar {
    height: 100%;
    width:3px;
  }
.soultip-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.soultip-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.soultip-inner{
    top: 0px;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots{
    margin-left: 0px !important;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots-bottom{
    bottom:12px;
    height: 10px;
    margin-right: 20px;
  }
    
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button{
    background: #D9D9D9;
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
  
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active{
    width: 17px;
  }
  
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active button{
    background: #4CCB1F;
  }
  .soul-tip-one-on-one-tool .ant-carousel .slick-dots li button:before{
    color: #ffff;
    visibility: hidden;
  }
.meeting-tool-title-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-align: center;
    margin: 0;
}
.meeting-tool-container{
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
   
}
.meeting-details-container{
    background: #F5F5F5;
    border-radius: 8px;
    padding: 30px 40px;
}
.current-one-on-one-meeting-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
    text-align: left;
}

.current-one-on-one-meeting-head span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
}
.past-meeting-data-container{
    background: #FFFFFF;
    /* border: 1px solid #F0F0F0; */
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px;
}

.meeting-notes-scroll::-webkit-scrollbar-thumb {
     margin-top: 10px; 
     -webkit-columns: red; 
             columns: red;
  }


  .meeting-notes-scroll::-webkit-scrollbar {
    margin-top: 10px; 
  }
  .meeting-notes-scroll::-webkit-scrollbar-track {
    margin-top: 21px !important; 
}


.notes-container-card .ant-select-selector{
    text-align: left;
}

.current-meeting-data-container{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    width: 100%;
    height: 400px;
    padding: 15px; 
}

.transcript-link{
    color: #8C8CA1;
    font-size: 12px;
}

.pt-80{
    padding-top: 80px;
}

.pt-63{
    padding-top: 63px;
}

.pb-70{
    padding-bottom: 70px;
}

.pt-70{
    padding-top: 70px;
}



.pb-85{
    padding-bottom: 85px;
}




.past-meeting-data-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
}
.past-meeting-data-overflow{
    height: 100%;
    max-height: 500px;
    overflow: auto;
}
.past-meeting-data-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .past-meeting-data-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  

  .notes-container-card .ant-select-selector{
     text-align: left;
  }


  .past-meeting-data-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }
  .meeting-detail-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #0E0E2C;
    margin-bottom: 0px !important;
  }


  .past-meeting-data-overflow p{
    margin-bottom: 0px;
    /* padding-top: 10px; */
    padding: 10px 7px 0px 3px;
  }


  .previous-meeting-filter-date-picker{
    border:none;
    border-radius: none;
    background: #F5F5F5;
    width: 130px;
    cursor: pointer;
    border-color: #F5F5F5 !important;
    box-shadow:none !important;
    padding: 4px 0px !important;
    display: flex !important;
    align-items: start !important;
    justify-content: start !important;
  }
  .previous-meeting-filter-date-picker:focus{
    border: none;
    box-shadow: none;
    border-color: #F5F5F5;
  }
  .previous-meeting-filter-date-picker .ant-picker-input input{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align:left;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    cursor: pointer;
  }

  .one-on-one-meeting-items-add-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    text-align: center;
    border: 1px solid #4CCB1F;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .one-on-one-meeting-items-add-btn:hover{
    border: 1px solid #4CCB1F;
    color: #FFF;
    background: #4CCB1F;
  }
  .one-on-one-meeting-items-add-btn:focus{
    border: 1px solid #4CCB1F;
    color: #FFF;
    background: #4CCB1F;
  }
  .add-agenda-container-section{
    /* border: 1px solid #4CCB1F; */
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    /* height: 230px; */
    /* overflow: auto; */
  }
  .one-on-one-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}
.one-on-one-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}
.one-on-one-ck-editor .cke_bottom{
display: none;
}
.one-on-one-ck-editor .cke_reset{
    padding: 0px 0px;
}
.one-on-one-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.one-on-one-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.one-on-one-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-check .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009AF1;
    border-color:#009AF1;
    width: 16px;
    height: 16px;
}
.one-on-one-check:hover{
    border-color:#009AF1; 
}
.one-on-one-check:focus{
    border-color:#009AF1; 
}
.one-on-one-input-field-style{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #595959;
}
.one-on-one-input-field-style::-webkit-input-placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9;
}
.one-on-one-input-field-style:-ms-input-placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9;
}
.one-on-one-input-field-style::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9;
}
.one-on-one-check-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #595959;
}
.one-on-one-okr-selection-drop{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    max-width: 500px !important;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #595959;
}
.action-item-marckdown{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: left;
    max-height: 200px;
    overflow: auto;
    color: #0E0E2C;
    }
.action-item-markdown-heades{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #141414;
    margin: 0px;
}
.one-on-one-bo-back-btn{
    width: 126px;
    height: 48px;
    background: #EFEFFD;
    /* box-shadow: inset 0px -1px 0.5px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: 1px solid #EFEFFD;
}
.one-on-one-bo-back-btn:hover{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.one-on-one-bo-back-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.one-on-one-save-btn{
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    width: 126px;
    height: 48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn:hover{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn:focus{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.one-on-one-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    line-height: 140%;
    text-align:left;
    margin-bottom: 10px;
    color: #141414;
}



.schedule-one-on-one-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: 0px 15px;
}

.schedule-one-on-one-container{
    padding: 30px 100px !important;
}
.one-on-one-input-field-style input {
    height: 40px;
    width: 100px;
    border-radius: 8px;
    border:1px solid #D9D9D9;
}
.one-on-one-input-field-style input:hover{
    border:1px solid #D9D9D9;
    box-shadow: none;
}
.one-on-one-input-field-style input:focus{
    border:1px solid #D9D9D9;
    box-shadow: none;
}
.agend-list-div-hover-action:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.one-on-one-meeting-popup-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #141414;
}
.one-on-one-meeting-popup-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}
.one-on-one-save-btn-modal{
    padding: 8px 24px;
    height: 40px;
    width: 116px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin-top: 10px;
}
.one-on-one-save-btn-modal:hover{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn-modal:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-cancel-btn-modal{
    padding: 8px 24px;
    height: 40px;
    width: 116px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
    border-radius: 8px;
    margin-top: 10px;
}
.one-on-one-cancel-btn-modal:hover{
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}
.one-on-one-cancel-btn-modal:focus{
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}

.inactive-rating-star-count{
    display: none;
}
.active-rating-star{
    display: none;
}
.active-rating-star-count{
    display: block;
}
.meeting-rating-text-area{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #303030;
    padding: 10px;
    outline: none;
}
.meeting-rating-text-area:focus{
    outline: none;
}
.meeting-rating-text-area::-webkit-input-placeholder{
    /* background: url('../../assets/dislike.svg'); */
    background-repeat: no-repeat;
    background-position-x:  5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9
}
.meeting-rating-text-area:-ms-input-placeholder{
    /* background: url('../../assets/dislike.svg'); */
    background-repeat: no-repeat;
    background-position-x:  5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9
}
.meeting-rating-text-area::placeholder{
    /* background: url('../../assets/dislike.svg'); */
    background-repeat: no-repeat;
    background-position-x:  5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9
}
.one-on-one-feedback-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
}
.one-on-one-feedback-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #141414;
    border-radius: 8px;
    border: 1px solid #141414;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn:hover{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn:focus{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-focus{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn-focus:hover{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-focus:focus{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn-active:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}
.one-on-one-feedback-tab-btn-active:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}
.new-tag-disable{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: #BFBFBF;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 3px;
    margin: 0;
    position: absolute;
    right: -55px;
    top:0px
}
.transcript-tab-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #BFBFBF;
    margin: 0;
}
.transcript-tab-head-active{
    color: #31D0AA;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin: 0;
}
.new-tag-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: #31D0AA;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 3px;
    margin: 0;
    position: absolute;
    right: -55px;
    top:0px
}
.load-mode-title{
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #303030;
    margin-bottom: -10px;
    margin-top: 10px;
}
.load-mode-title:hover{
    color:#4CCB1F;
}

.meeting-notes-dropdown{
    width: 300px !important;
    cursor: pointer;
}

.meeting-notes-dropdown-prev-meeting{
    width: 250px !important;
    cursor: pointer;
}


.collapse-right-outlined-icon{
    padding: 9px 0px !important;
}


@media (max-width:1600px) {
    .one-one-one-home-view{
        font-size: 14px !important;
    }
    .transcript-tab-head{
        font-size: 14px;
    }
    .transcript-tab-head-active{
        font-size: 14px;
    }
    .one-on-one-feedback-form-title{
        font-size: 14px;
    }
    .meeting-user-name{
        font-size: 18px;
        line-height: 32px;
    }
    .action-item-markdown-heades{
        font-size: 8.5px;
    }
    .action-item-marckdown{
        font-size: 12px;
    }
    .one-on-one-head{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
    } 
    .one-on-one-tab .ant-tabs-tab-btn{
        font-size: 14px;
    }

    .one-on-one-menu .ant-dropdown-menu-item{
        font-size: 13px
    }
    .one-one-filter-menu .ant-dropdown-menu-item{
        font-size: 14px;
    }
    .one-on-one-selected-filter{
        font-size: 15px;
    }

    .notes-select-box{
        min-width:180px !important;
        font-size: 13px !important;
      }
   
    /************************************/
        /* ONE ON ONE CARDS CSS */
    /************************************/
    .one-on-one-date-section .one-on-one-date{
        font-size: 17px;
        line-height: 21px
    }
    .one-on-one-date-section .one-on-one-time{
        font-size: 11px;
    }
    .one-on-one-card-div .one-on-one-title{
        font-size: 17px;
        line-height: 25px;
    }

    /************************************/
        /* ONE ON ONE  QUICK LINK CSS */
    /************************************/

    .one-on-one-quick-link-container .quick-link-button .quick-link-title{
        font-size: 15px;
    }
    .one-on-one-quick-link-container .quick-link-button{
        height: 58px;
    }

    .one-on-one-title-modal{
        font-size: 18px;
    }
    .one-on-one-agenda-title{
        font-size: 14px;
    }
    .agenda-list-container{
        padding: 10px 20px;
    }
    .meeting-filter-btn{
        font-size: 14px;
    }
    .instant-meeting-head{
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #141414;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector{
        height: 44px !important;
        
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input{
        height: 44px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-placeholder{
        padding: 8px 10px 4px 25px !important;
        font-size: 14px !important;
        line-height: 28px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
        height: 44px !important;
        font-size: 13px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-item{
        padding: 8px 10px 4px 25px !important;
        font-size: 14px !important;
        line-height: 28px !important;
    }
    .one-on-one-meeting-title-input{
        height: 44px !important;
        font-size: 13px !important;
        line-height: 28px !important;
    }
    .instant-form-icon{
        top:10px !important;
        left: 17px !important;
    }

    .instant-meeting-form-icon{
        top:10px;
        left: 13px !important;
    }

    .edit-series-meeting-container .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background: #FFFFFF;
    }

    .meeting-instant-popup{
        padding: 20px;
    }
    .meeting-instant-popup-inner{
        padding: 10px 30px;
    }
    .instant-start-btn{
        font-size: 14px;
    }
    /*********************************************/
    /****************START MEETING****************/
    /*********************************************/

.one-on-one-time-title{
    font-size: 20px;
    margin-top: -13px;
}
.past-meeting-container{
    width: 30%;
    margin: auto;
    height: 100%;
}

.previous-meeting-title{
    font-size: 20px;
    line-height: 30px;
}
.previous-meeting-date{
    font-size: 14px;
    line-height: 22px;
}
.previous-meeting-filter-date-picker{
    width: 115px;
}
.previous-meeting-filter-date-picker .ant-picker-input input{
    font-size: 14px;
    line-height: 22px;
  }
.meeting-tool-title{
 
    font-size: 20px;
    line-height: 30px;
}
.meeting-details-container{
    padding: 20px 20px;
}
.current-one-on-one-meeting-head{
    font-size: 17px;
}

.current-one-on-one-meeting-head span{
    font-size: 17px;
}
.past-meeting-data-container{
    /* padding: 10px; */
}
.past-meeting-data-title{
    font-size: 14px;
    /* margin-bottom: 30px; */
}

.pt-50{
    padding-top: 50px;
}


.pt-100{
    padding-top: 100px;
}

.pt-43{
    padding-top: 43px;
}


.meeting-detail-para{
    font-size: 13px;
    
  }
  .one-on-one-form-title{
    font-size: 14px;
}
.schedule-one-on-one-container{
    padding: 30px 80px !important;
}
.one-on-one-meeting-popup-head{
    font-size: 20px;
}
.one-on-one-meeting-popup-para{
    font-size: 14px;
}
.new-tag-disable{
    font-size: 10px;
    padding: 2px 3px;
    line-height: 14px;
    right: -35px;
    top:2px
}
.new-tag-active{
    font-size: 10px;
    padding: 2px 3px;
    line-height: 14px;
    right: -35px;
    top:2px
}
.soultip-overflow{
   max-height: 90px !important;
}
.soultip-height{
    max-height: 155px !important;
}
.soultip-inner{
    top: -20px;
}
}

@media (min-width:1366px){
    .previous-meeting-card-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media (min-width:600px)  and (max-width:1366px) and (orientation:landscape) {

    .previous-meeting-card-wrapper{
        padding-left: 13px;
        padding-right: 13px;
    }

}



@media (max-width:1200px) {
    .schedule-one-on-one-container{
        padding: 10px 20px !important;
    }

    .past-meeting-container{
        width: 40%;
        margin: auto;
    }
    
  
}



@media (max-width:600px) {
    .schedule-one-on-one-container{
        padding: 10px 20px !important;
    }


    .notes-container{
        left: 0px;
    }

    .notes-select-box{
        min-width:120px !important;
        font-size: 13px !important;
      }

    .past-meeting-container{
        width: 70%;
        margin: auto;
    }
    
  
}






@media (max-width:320px) {

    .past-meeting-container{
        width: 330px;
    }

    .previous-meeting-title{
        font-size: 15px;
    }

}
@media (max-width:992px) {
    .greeting-content-section{
      align-items: center !important;
    }
    .greeting-content-section{
        align-items: center;
    }
    .metting-button-section{
        margin-left: 0px !important;
        flex-wrap:wrap !important;
    }
    .meeting-instant-popup{
        padding: 15px;
    }
    .meeting-instant-popup-inner{
        padding: 10px 25px;
    }
    .schedule-one-on-one-container{
        padding: 20px 50px !important;
    }
}

@media (min-height:800px){
    .past-meeting-data-container{
        /* min-height: 400px; */
    }
    .meeting-overflow{
        max-height: 420px !important;
    }
}
@media (min-height:920px){
    .past-meeting-data-container{
        /* min-height: 420px; */
    }
    .meeting-overflow{
        max-height: 500px !important;
    }
}

@media screen and (min-width:950px) {
    

    .greeting-content-section{
        margin-left: 30px;
      }


    .one-on-one-left-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
        margin-left: 30px;
      }
  
}

@media screen and (min-width:730px) and (max-width:1200px){

    .notes-container{
        left: 0px;
    }

    .notes-select-box{
        min-width: 110px !important;
    }

}



@media screen and (min-width:1390px){

    .previous-meeting-card-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }
}



@media screen and (min-width:2200px){

    .previous-meeting-card-wrapper{
        padding-left: 40px;
        padding-right: 40px;
    }


}

.transcript-scroll{
    height: 400px;
    margin-bottom: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 3px;
}

.transcript-msg-container{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    align-items: start;
}

.transcript-profile-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
    /* align-items: center; */
    width: 100%;
}

.transcript-img-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 1px solid #4CCB1F;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.transcript-img-container-noborder{
    border: 1px solid transparent;
}

.transcript-pro-img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
}

.transcript-pro-char{
    margin: 0px !important;
    font-size: 16px;
    font-weight: 500;
}

.transcript-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.transcript-text{
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    word-break:normal;
    margin-top: -10px;
}

.transcript-date{
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #c3c3c3;
}

.transcript-scroll::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.transcript-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.transcript-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.transcript-rcd-btn{
    background: #4CCB1F;
    border-radius: 5px;
    min-width: 80px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
}

.transcript-rcd-btn:hover,.transcript-rcd-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.transcription-data-aligner{
    display: flex;
    flex-direction: column;
    grid-gap: 0.15em;
    gap: 0.15em;
    width: 100%;
}

.transcript-user-name{
    margin: 0px;
    text-align: left;
}

.transcription-empty{
    height: 98%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}
.transcription-empty .transcription-icon{
    width: 80px;
    height: 80px;
}

.transcription-nodata{
    text-align: center;
    max-width: 80%;
    font-size: 1.1em;
    color: #BFBFBF;
}

.souptip-container{
    display: none;
    max-height: 175px;
    height:175px;
    overflow: auto; 
}


.souptip-container::-webkit-scrollbar {
    display: none;
}



@media screen and (min-width:1024px) {
   
    .souptip-container{
       display: flex;
    }

}

@media screen and (min-width:1600px) {
   
    .souptip-container{
       max-height: 160px;
       display: flex;
    }

}

.pt-33{
    padding-top: 33px;
}

.pt-75{
    padding-top: 75px;
}

.summary-arrow{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.one-on-one-bottom-div{
    position: fixed;
    left: 7.2em;
    right: 0;
    bottom: 0;
    width:100%; 
    z-index:999;
    display:flex;
    flex-direction:row; grid-gap:20px; gap:20px;
    justify-content:center;
    align-items: center;
    padding: 20px 10px;
    background: #FFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    transition-duration: 1.2s;
    padding-right: 8em;
}
.one-on-one-div-margin-left{
    margin-left: 249px;
    transition-duration: 1s;
    padding-right: 25em;
}
@media (max-width:960px) {
    .one-on-one-bottom-div{
        left:0em;
        padding-right: 0em;
    }
    .one-on-one-div-margin-left{
    margin-left: 0px;
    padding-right: 0em;
    }
}
.occurance-modal{
    width: 800px !important;
    max-width: 90% !important;
    height: 80vh !important;
}
/* .occurance-modal .ant-modal-content{
 height: 400px;
} */
.aligin-center{
    display: flex;
    align-items: center !important;
}
.occarance-modal-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin: 0px !important;
}


.meeting-enede-button-panel .ant-radio-button-wrapper:not(:first-child)::before{
    display: none;
}

.occurance-save{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #fff;
    padding: 0px 15px 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 60px;
}

.occurance-save:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #fff;
}

.occurance-row{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.day-btn{
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    height: 40px;
    width: 40px;
    font-size: 16px;
    border: 1px solid #4CCB1F;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.day-btn:hover{
    background: #4CCB1F;
    color: #fff;
    border: 1px solid #4CCB1F;
}
.day-btn:focus{
    background-color: #fff;
    color: black;
    border: 1px solid #4CCB1F;
}

.active-day-btn{
    background: #4CCB1F !important;
    color: #fff !important;
}

.oc-duration input{
        height: 36px !important;
       
}

.invalid-duration input{
border: 1px solid #ff4d4f;
}

.soul-tool-tip{
    z-index: 550 !important;
}


.meeting-feedback-detailed-box{
    min-height: 150px;
}

/* .ant-picker-footer {
    display: none;
} */
.occurance_dropdown .ant-select-selector input{
    width: 120px !important;
    margin-left: -10px !important;
}
@media screen and (max-width:960px) {
    .occarance-modal-title{
      font-size:22px ;
    }
  
}
  
@media screen and (max-width:600px) {
    .occarance-modal-title{
        font-size:20px ;
    }
}

.transcription-summary-root{
    font-size: 16px;
}

.tsearch-container{
    position: relative;
    max-width: 200px;
    width: 95%;
}

.tsummary-search{
    width: 100%;
    padding-left: 2em;
    border-radius: .5em;
    margin-bottom: .25em;
}

.tsearch-icon{
    position: absolute;
    left: .25em;
    top: 5px;
    width: 20px;
}

.timg-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
}

.tchara-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 1px solid #4CCB1F;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.tright-aligned-container{
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0.25em;
    padding-right: .5em;
}

.tleft-aligned-container{
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 0.25em;
    padding-right: .5em;
}

.tmsg-container{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: .5em;
    gap: .5em;
    background-color: rgb(229, 228, 228);
    justify-content: space-between;
    padding: .5em;
    border-radius: .5em;
}

.tpro-char{
    margin: 0px !important;
    font-size: 16px;
    font-weight: 500;
}

.tmsg-info{
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.tuser-info{
    min-height: 30px;   
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.align-end{
    justify-content: end;
}

.align-start{
    justify-content: start;
}

.tmsg{
    font-size: 0.875em;
    font-weight: 400;
    text-align: left;
    word-break:normal;
}

.tuser-info-label{
    font-size: 0.8125em;
}

.transcript-view-more{
    margin-top: .5em;
    font-size: 1em;
    cursor: pointer;
    text-decoration: underline;
}

.transcript-view-more:hover{
    color: #4CCB1F;
}

.audio-border{
    height: 30px !important;
    border: 2px solid #4CCB1F;
    width: 100%;
    border-radius: 8px;
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.audio-download-icon{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.audio-progress{
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: #c3c3cc;
}

.dummy-selction{
    width: 10px;
    height: 100%;
}

.auido-sound-signal{
    position: absolute;
    right: 0px;
    width: 25px;
    top: -95px;
    cursor: pointer;
}
.audio-player {
    width: 100% !important; 
}

.hide-audio-player{
    height: 0px !important; 
    visibility: hidden;
}

.transcript-audio-player{
    height: 30px !important;
}

.transcript-audio-player::-webkit-media-controls-time-remaining-display,
::-webkit-media-controls-current-time-display,
::-webkit-media-controls-menu {
    display: none !important;
}

.transcript-audio-player::-webkit-media-controls-timeline {
    padding: 0px !important;
    margin: 0px 2px !important;
}

.transcript-audio-player::-webkit-media-controls-panel {
    padding: 0px !important;
    background:rgb(229, 228, 228) !important;
}

.transcript-audio-player::-webkit-media-controls{
    background: rgb(229, 228, 228);
    padding: 0px !important;
    border-radius: 8px;
    border: 2px solid #4CCB1F;
}

.highlight-audio-player::-webkit-media-controls{
    border: 2px solid #009AF1 !important; 
}

.audio-container{
    position: relative;
    width: 100%;
}

.custom-audio-player{
    position: absolute;
    top: -35px;
}

.custom-audio-player .ant-progress-inner {
    height: 4px;
    border-radius: 5px;
}

.active-audio-player{
    border: 2px solid #009AF1 !important; 
}

.audio-border-custom{
    height: 30px !important;
    border: 2px solid #4CCB1F;
    width: 100%;
    border-radius: 8px;
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.audio-border-custom .ant-slider-track{
    background-color: #4CCB1F;
}

.active-audio-player .ant-slider-track{
    background-color: #009AF1;
}
.on-one-close-btn .ant-modal-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    border-radius: 1.9em;
}
.call-handler-container{
    height: 500px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 0px;
    overflow: auto;
    font-size: 16px;
}

.center-align{
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-handler-container::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.call-handler-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.call-handler-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.default-call-tab{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.handler-info{
    text-align: center;
    font-size: 1em;
    color: #BFBFBF;
}

.handler-icons-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.handler-icons{
    padding: 1em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5em;
    border: 2px solid #4CCB1F;
    cursor: pointer;
   
}

.decline-icons{
    padding: 1em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5em;
    border: 2px solid red;
    cursor: pointer;
}

/* .handler-icons :hover{
    animation: shake 1s;
    animation-iteration-count: infinite;
} */

.handler-icons img, .decline-icons img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* object-fit: contain; */
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translate(1px, 1px) rotate(0deg);
                transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
                transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
                transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(3px, 2px) rotate(0deg);
                transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
                transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
                transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
                transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(3px, 1px) rotate(-1deg);
                transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
                transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(1px, 2px) rotate(0deg);
                transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
                transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translate(1px, 1px) rotate(0deg);
                transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
                transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
                transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(3px, 2px) rotate(0deg);
                transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
                transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
                transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
                transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(3px, 1px) rotate(-1deg);
                transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
                transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(1px, 2px) rotate(0deg);
                transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
                transform: translate(1px, -2px) rotate(-1deg);
    }
}

.call-state-container{
    width: 100%;
    height: 100%;
    /* background-color: red; */
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.dot-flashing-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    margin-bottom: 1em;
}

.dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    -webkit-animation: dot-flashing 1s infinite linear alternate;
            animation: dot-flashing 1s infinite linear alternate;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    -webkit-animation: dot-flashing 1s infinite alternate;
            animation: dot-flashing 1s infinite alternate;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    -webkit-animation: dot-flashing 1s infinite alternate;
            animation: dot-flashing 1s infinite alternate;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  @-webkit-keyframes dot-flashing {
    0% {
      background-color:  #BFBFBF;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
  
  @keyframes dot-flashing {
    0% {
      background-color:  #BFBFBF;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }

  .call-system-icon-container{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    margin-bottom: .5em;
  }

  .call-accept-reject{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
  }

  .call-system-control-icon{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  /* call connected css */

  .call-connected-col-container{
    width: 100%;
    height: 100%;
    position: relative;
  }

  .call-remote-stream{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg)
  }

  .call-local-stream{
    height: 25%;
    width: 25%;
    object-fit: cover;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg)
  }

  .call-menu-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .exe-menu-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    border-top:  1px solid #474850;
  }

  .stream-container{
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
  }

  .exe-stream-container{
    /* change 100% to 75% when we add chat */
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    /* background-color: #1C1F2E; */
  }

  .exe-call-remote-stream{
    max-width: 90%;
    height: calc(100% - 150px);
    object-fit: cover;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    border-radius: 10px;
  }

  .exe-remote-audio-container{
    height: 100%;
    width: 100%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    background-color: #242736;
    padding-top: 60px;
  }

  .exe-call-local-stream{
    /* width: 100%; */
    height: 30%;
    object-fit: cover;
    position: absolute;
    bottom: 10px;
    right: 10px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    border-radius: 10px;
  }

  .call-end-btn , .call-end-btn:hover , .call-end-btn:focus {
    width: 60px;
    padding: 2px 5px 5px 5px;
    border-radius: 20px;
    background-color: red;
    color: #fff;
    text-align: center;
    font-weight: 500;
    border: 1px solid red;
  }

  .call-connected-exe-container{
    position: fixed !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    background-color: #1C1F2E;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .call-connected-exe-container .call-remote-stream{
    object-fit: contain;
  }

  .call-connected-exe-container .call-local-stream{
    object-fit: contain;
    /* height: 35; */
  }

  .missed-call-contianer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .missed-call-contianer img{
    width: 20px;
    height: 20px;
  }

  .clamp-to-3line{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .expanded-menu-button{
    background-color: #242736;
    border: 1px solid #474850;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .expanded-menu-button img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .call-exp-header{
    height: 60px;
    width: 100%;
    position: fixed;
    background-color: #242736;
    border-bottom:  1px solid #474850;
    z-index: 110000;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .call-header-text{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-align: center;
    margin: 12px 5px 10px 5px;
    text-overflow: ellipsis;
  }

  .call-map-logo{
    height: 40px;
  }

  .ant-tooltip-placement-top{
    z-index: 100000;
  }

  .remote-audio-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    background-color: #242736;
  }

  .local-audio-container{
    height: 25%;
    width: 25%;
    max-width: 200px;
    max-height: 200px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;

  }

  .local-audio-sub-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    background-color: #474850;
    grid-gap: 5px;
    gap: 5px;
  }

  .remote-avatar{
    border-radius: 50%;
    background-color: #474850;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remote-avatar img{
    object-fit: contain;
    width: 80%;
    height: 80%;
  }

  .local-avatar{
    border-radius: 50%;
    background-color: #242736;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .local-avatar img{
    object-fit: contain;
    width: 85%;
    height: 85%;
  }

  .call-user-name{
    text-align: left;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    margin: 5px;
  }

  .call-reconnection{
    /* height: 100%; */
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 50px;
    background-color: rgb(78, 74, 74);
    opacity: .7;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .reconnecting-text{
    font-size: 1.2em;
    color: #fff;
    font-weight: 500;
  }

  .call-info-title{
    text-align: center;
    font-size: 1.4em;
  }

  .call-info-tip{
    padding: 1em 1.5em;
  }

  .call-li-point{
    font-size: 1.1em !important;
  }

  .call-info-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .call-confirmation,.call-confirmation:hover{
    background: #4CCB1F;
    border-radius: 8px;
    width: 100px;
    height: 38px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: .9em;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin: 5px;
  }


.one-on-one-effectiveness-root{
    font-size: 16px;
    font-family: poppins;
}



@media screen and (max-width:1200px) {
    .one-on-one-effectiveness-root{
      font-size: 14px;
    }
  
  }
  
  
  @media screen and (max-width:960px) {
    .one-on-one-effectiveness-root{
      font-size: 12px;
    }
  
  }
  
  @media screen and (max-width:600px) {
    .one-on-one-effectiveness-root{
      font-size: 11px;
    }
  
    .filter-panel .ant-input-affix-wrapper{
      padding: 0.38em 0.68em !important;
    }
  

  }

  .one-on-one-effectiveness-root{
    position: relative;
    top: 1.5em;
  }


    .feedback-card-suggestions{
      padding-top: 2em;
    }


  .effectiveness-title{
    font-size: 2em;
    font-weight: 700;
    color: #141414;
  }

  .back-icon-one-on-one-effectiveness{
    position: absolute;
    left: 0.84em;
  }

  .filter-panel{
    position: relative;
    top: 4em;

  }

  .effectiveness-feedback-body{
    position: relative;
    top: 5em;
    grid-gap:0.5em;
    gap:0.5em;
    margin-bottom: 2em;
  }

   .emp-search-filter .ant-btn:hover, .emp-search-filter .ant-btn:focus{
    color: #000000;
  }

  .feedback-column-content{
   height: auto;
   overflow: auto;
   max-height: 22em;
   width: 97%;
   margin-top: 3em;
   margin-bottom: 1em;
   padding:0em 1em 0em 0.7em;
  }
  
  .feedback-column{
    margin-bottom: 5em;
  }

  .feedback-column-content .ant-card-bordered{
   margin-top: 0em !important;
   /* margin-bottom: 0em !important; */
    border-radius: 0.5em;

  }

  .feedback-column{
    /* margin:auto; */
    margin-top:0em;
    background-color:rgba(217, 217, 217, 0.23);

  }

  .received-header{
    position: relative;
    top: -0.4em;
    max-width: 250px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  .feedback-column-header{
    padding:2em 1.5em 0em 1em;
    font-weight:500;
    font-size: 1.2em;
  }


  .feedback-column-content::-webkit-scrollbar {
    height: 0em;
    width: 0.47em;
    /* padding-left: ; */
  }
  
  .feedback-column-content::-webkit-scrollbar-track {
    display: none;
  }
  
  .feedback-column-content::-webkit-scrollbar-thumb {
    border-radius: 0.6em;
    background: #D9D9D9;
    ;
  }
  
  .graph-container-effective{
    height:21.12em;
    /* width: 26.12em; */
  }


  .filter-panel .ant-input-affix-wrapper{
    padding: 0.43em 0.68em;
    border-radius: 0.5em;
  }

  .filter-panel .anticon svg{
    font-size: 1.3em;
    color: #8C8CA1;
  }

  .effective-filter-all-active{
    color:#4CCB1F;
    font-weight:500;
    font-size: 1em;
    border-bottom: 0.1em solid #4CCB1F;
    padding-bottom:0.2em;
  }


  .effective-filter-all-non-active{
    color: #00000080;
    font-weight:500;
    font-size: 1em;
    border-bottom: 0.1em solid #00000080;
    padding-bottom:0.2em;
  }

  .effective-emp-filter-all-active .ant-btn{
     border-color: #4CCB1F;
     width:15.6em;
  }


  .effective-emp-filter-all-non-active .ant-btn{
    border-color: #d9d9d9;
    width:15.6em;
 }

 .modal-user-name{
  font-size: 1.1em;
  font-weight: 500;
  margin-left: 0.3em;
  color: #000000;
 }

 .modal-feedback-date{
  font-size: 1.1em;
  color: #009AF1;
 }

 .modal-section-header{
  font-size: 1.1em;
  font-weight: 500;
  line-height: 4em;
  color: #000000;
 }



 .modal-feedback-header-for-user{
  width: 75%;
 }

 .feedback-details-modal .ant-modal-body{
  overflow: scroll;
  max-height: 28.12em;

 }

 .star-rating-effectivness-feedback{
  width: 2.5em;
  margin-right: 1em;
 }

 .feedback-note{
  font-size: 1em;
  color: #000000;
  font-weight: 500;
  padding-bottom: 2em;
 }
.rating-section:hover{
  background: rgba(76, 203, 31, 0.05) !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 8px !important;
}
.star-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.875em;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);

}
.rating-section:hover .star-title{
  color: #000000 !important;
}
.message-inactive{
  display: block;
}
.message-active{
  display: none;
}
.rating-section:hover .message-active{
  display: block;
}
.rating-section:hover .message-inactive{
  display: none;
}
 @media screen and (max-width:900px) {

  .received-header{
    font-size: 0.8em;
    top: -0.8em;
  }

 }


 @media screen and (max-width:800px) {
  
  .feedback-column, .graph-container-effective{
    margin-bottom: 1em;

    margin-top: 1em;
  }

  .emp-search-filter{
    line-height: 3em;
  }


  .effective-filter-all-active,.effective-filter-all-non-active{
    font-size: 1.3em;
  }

  .graph-container-effective{
    width: 100%;
  }

  .feedback-column{
    margin-bottom: 5em;
  }

 



 }

 @media screen and (max-width:500px) {

  .modal-feedback-header-for-user{
    margin-bottom: 1em;
    width: 100%;
  }

 }

 @media screen and (max-width:380px)  {

  .effectiveness-title{
    font-size: 1.3em;
  }

 }


 @media screen and (min-width:381px)  {

  .emp-search-filter{
    margin-left: 2em;
  }
  
 }

 @media screen and (max-width:450px)  {

  .emp-search-filter{
    line-height: 3em;
    margin-bottom: 2em;
  }

  .back-icon-one-on-one-effectiveness{
    /* left: 1em; */
  }

  .feedback-title-search-box{
     width: 80%;
  }

 }

 @media screen and (max-width:320px)  {

  .back-icon-one-on-one-effectiveness{
    left: 0em;
  }
  
 }


 @media screen and (max-width:600px) and (orientation:landscape) {

  .emp-search-filter{
    line-height: 3em;
    /* margin-bottom: 2em; */
  }

 }

 @media screen and (max-width:500px)  {

  .emp-search-filter{
    margin-bottom: 2em;
  }

 }

 @media screen and (min-width:500px) and  (max-width:700px) {
  
  .feedback-title-search-box{
     width: 45%;
  }

  .effective-emp-filter-all-non-active .ant-btn{
    width: 12em;
  }


 }


 @media screen and (min-height:500px) {
  
  .feedback-column-content{
    max-height: 28em;
  }

 }



 @media screen and (min-height:700px) {
  
  .feedback-column-content{
    max-height: 28em;
  }

 }


 @media screen and (min-height:890px) {
  
  .feedback-column-content{
    max-height: 40em;
  }

 }

 @media screen and (min-width:2000px) {
  
  .one-on-one-effectiveness-root{
    top: 5em;
  }

 }




 .feedback-details-modal .ant-modal-body::-webkit-scrollbar {
  height: 0em;
  width: 0.47em;
  display: none;
}
.star-title-past-job{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.875em;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #000000;
}
@media (max-width:1280px) {
  .past-title-section{
    width: 45% !important;
  }
}
@media (max-width:1060px) {
  .past-title-section{
    width: 30% !important;
  }
}
@media (max-width:890px) {
  .past-title-section{
    width: 100% !important;
  }
  .past-container{
    flex-direction: column !important;
  }
}
@media (max-width:600px) {
  .past-title-section{
    width: 100% !important;
  }
}
 
.org-profile-view-container{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    align-items: center; 
    width: -webkit-fit-content; 
    width: -moz-fit-content; 
    width: fit-content;
}

.org-profile-img-container{
    width: 6em;
    height: 6em; 
}

.org-profile-img-container img{ 
    width: 100%;
    height: 100%; 
    border-radius: 50%;
    object-fit: cover;
}

.org-detail-admin-profile-main-container{
    padding: 0.5em;
    width: 33%;
}

.org-detail-admin-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 0.5em 1em;  
    align-items: center; 
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
    width: 100%; 
}

.admin-profile-img-container{
    width: 5em;
    height: 5em; 
    min-width: 5em;
    min-height: 5em;
}

.admin-profile-img-container img{ 
    width: 100%;
    height: 100%; 
    border-radius: 50%;
    object-fit: cover;
}

.org-admin-profile-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1.25em 0em; 
}

.org-admin-info-section{ 
    overflow: hidden; 
}

.org-admin-info-section h4{
    font-family: 'Poppins'; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

@media screen and (max-width:1250px) {
    .org-detail-admin-profile-main-container{
        width: 50%;
    }
}

@media screen and (max-width:750px) {
    .org-detail-admin-profile-main-container{
        width: 100%;
        padding: 0.5em 0em;
    }
}
.readiness-root{
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}
.readiness-main-container {
    height: calc(100vh - 199px);
}
.readiness-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.75em;
    color: #000;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.readiness-form-lable{
    font-size: 1.15em;
    font-weight: 500;
    color: #262626;
    font-family: Poppins;
    line-height: normal;
    font-style: normal;
    margin: 0px !important;

}
.readiness-form-lable p{
    margin: 0px !important;
}
.readiness-add-btn,.readiness-add-btn:focus,.readiness-add-btn:hover{
    height: 2.5em;
    width: 8em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color: #4CCB1F;
    background-color: #fff;
    border: 1px solid #4CCB1F;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-save-btn,.readiness-save-btn:focus,.readiness-save-btn:hover{
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color:#fff;
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-cancel-btn,.readiness-cancel-btn:focus,.readiness-cancel-btn:hover{
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color:#8998A2;
    background-color: #fff;
    border: 1px solid #8998A2;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-view-btn,.readiness-view-btn:hover,.readiness-view-btn:focus{
    font-family: 'poppins';
    background: rgba(76, 203, 31, 0.7);
    color:#fff;
    height: 2.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: .5em 1em;
    border-radius: .5em;
    font-size: 1em;
    border: none;
    outline: none;
    cursor: pointer;
}
.readiness-delete-btn,.readiness-delete-btn:hover,.readiness-delete-btn:focus{
    color:#fff;
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    background: #E86161;
    border: 1px solid #E86161;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-modal{
    max-width: 900px;
}
.readiness-recieved-date{
color: rgba(137, 152, 162, 1);
font-family: "Poppins";
font-size: .95em;
font-weight: 500;
font-style: normal;
line-height: normal;
}
.readiness-modal .ant-modal-body{
    height: 90vh;
    /* overflow: auto; */
}

.readiness-tooltip .ant-tooltip-inner{
    background-color: #0E0E2C !important;
    color: #FAFCFE !important;
    font-size: .85em !important;
    font-family: "Poppins";
    font-weight:500;
    border-radius: .5em !important;
}
.readiness-tooltip .ant-tooltip-arrow-content{
    background-color: #0E0E2C !important;
}
.readiness-waring-title{
    font-size: 1.15em;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.readiness-link-text{
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    color: #009AF1;
    margin: 0px !important;
}
/* InputRange */

.readiness-zone-btn{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    text-align: center !important;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;
    margin: 0px;
}

.green-readiness-zone{
    background: #4CCB1F33;
    color: #4CCB1F;
}

.orange-readiness-zone{
    background: #F7A11F33;
    color: #F7A11F;
}

.red-readiness-zone{
    background: #CB1F1F33;
    color: #CB1F1F;
}

.purple-readiness-zone{
    background: #7F1FCB33;
    color: #7F1FCB;
}

.blue-readiness-zone{
    background: #1575E633;
    color: #1575E6;
}

@media (max-width:1800px){
    .readiness-page-title {
        font-size: 1.5em;
    }
    .readiness-form-lable{
        font-size: 1.15em;
    }
}

.assessement-space{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:750px){
    .assessement-space{
        align-items: start;
        flex-direction: column;
    }
}

.readiness-slider .ant-slider-dot{
    display: none !important;
}

.readiness-slider .ant-slider-rail{
    height: 7px !important;
    background: #A9A7A7 !important;
    border-radius: 20px;
}
.readiness-slider .ant-slider-track{
    height: 7px !important;
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important;
    border-radius: 15px;
    /* transition:  0.2s !important; */
}
.readiness-slider .ant-slider-handle{
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important; 
    border: none;
    width: 15px;
    height: 15px;
    /* margin-top: -3px; */
    /* transition: 0.1s !important; */
}

.readiness-slider .ant-slider-mark-text{
    margin-top: 5px !important;
    color: #000000;
    font-family:'Poppins';
    font-size: 1rem !important;
    font-weight: 500;
}
.coaching-title {
    margin: 0em !important;
    font-size: 38px;
}
.inner-page-heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #2E2E2E;
    margin: 0;
}
.page_heading-table-grow{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #414141;
    margin: 0;
}
.grow-custom-table{
    padding: 0px 75px;
}
.grow-custom-link{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 33px;
}
.grow-table-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    /* identical to box height */


    color: #000000;
}
.grow-detail-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #000000;
}
.grow-question-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25em;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
}

.coach-eng-comm-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
    text-align: justify;
    width: -webkit-max-content;
    width: max-content;
    margin-bottom: 1em;
}
/* css below for commitment list section */
.comm-list-section{
    padding-top: 0.8em;
    width: 75%;
}
.grow-status-inprgress{
    background: rgba(255, 199, 0, 0.2);
    width: 8em;
    border-color: .5em;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1em;
    font-style: normal;
    line-height: normal;
    color: rgba(255, 199, 0, 1);
    padding: .35em 1em;
}
.grow-status-complete{
    background: rgba(13, 214, 113, 0.2);
    color: rgba(13, 214, 113, 1);
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    width: 8em;
    border-radius: .25em;

}

/* css for styling the table */

.ant-table-content-grow table {
    background-color: #f7fef1;
    /* width: 900px !important; */
}

.ant-table-content-grow .ant-table table {
    border-spacing: 0em !important;
}

.ant-table-content-grow .ant-table-thead .ant-table-cell {
    background-color: #e7f9e096;

}

.ant-table-content-grow .ant-table-cell p {
    margin-bottom: 0 !important;
}

/* css for table title */
.ant-table-content-grow .ant-table-title {
    background-color: #e7f9e096;

}

.grow-answer-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.15em;
    line-height: normal;
    text-align: justify;
    letter-spacing: -0.8px;
    color: #000000;
    margin: 0;
}
.grow-table-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.35em;
    line-height: normal;
    text-align: justify;
    letter-spacing: -0.8px;
    color: #3B8855;
    margin: 0;
}
.ant-form-item-control-input-content{
    padding-left: 0px !important;
}
@media (max-width:1600px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 28px;
    }  
    .inner-page-heading{
        font-size: 30px;
        line-height: 40px;
    }
    .page_heading-table-grow{
        font-size: 37px;
        line-height: 50px;
    }
    .grow-custom-table{
        padding: 0px 55px;
    }
    .grow-custom-link{
        font-size: 16px;
        line-height: 22px;
    }
    .grow-table-content{
        font-size: 16px;
        line-height: 22px;
    }
    .grow-detail-title{
        font-size: 16px;
    }
}
@media (max-width:960px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 22px;
    }  
    .inner-page-heading{
        font-size: 26px;
        line-height: 36px;
    }
    .page_heading-table-grow{
        font-size: 30px;
        line-height: 45px;
    }
    .comm-list-section{
        padding-top: 0.8em;
        width: 100%;
    }
}
@media (max-width:480px) {
    .coaching-title {
        margin: 0em !important;
        font-size: 16px;
    }  
    .inner-page-heading{
        font-size: 22px;
        line-height: 42px;
    }
    .page_heading-table-grow{
        font-size: 22px;
        line-height: 32px;
    }
    .grow-custom-table{
        padding: 0px 25px;
    }
}


.engagement-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width:100%;
}
.enggement-main-button,.enggement-main-button:hover,.enggement-main-button:focus{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    padding: .5em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.25em !important;
    border-radius: .5em;
    height: 3em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
}
.coaching-conversation-root{
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}
.coaching-engagement-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.75em;
    color: #000;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.coaching-engagement-form-lable{
    font-size: 1.15em;
    font-weight: 500;
    color: #262626;
    font-family: Poppins;
    line-height: normal;
    font-style: normal;
    margin: 0 !important;

}
/* .coaching-engagement-form-lable p{
    font-family:"Poppins" !important;
    font-size: 1em !important;
    font-weight: 500 !important;
    color: #000;
    line-height: normal;
    font-style: normal;
    margin: 0 !important;
    margin-bottom: 0px !important;
} */
.custom-modal .ant-modal{
    width: 800px !important; 
    -webkit-transform-origin: 349px 124px !important; 
            transform-origin: 349px 124px !important;
    background-color: #fff; /* Change as needed */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 20px;
  }



.requests-side-tool button{
    width: 47px !important;
    height: 47px !important;
}

.requests-side-tool .ant-btn{
    top: 210px !important;
}

.filter-drop .selected-filter{
    color: #595959;
}

.requests-side-tool .ant-btn:hover{
  background: rgb(176, 203, 31) !important;
}

.requst-detail-margin{
    padding: 0em 4em;
}
.discard-botton-div-new{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px rgba(0, 0, 0, 0.14);
    height: 120px;
    position:fixed;
    bottom: 0px !important;
    left: 10em;
    right:10em;
    width:100%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    justify-content: center center;
    align-items: center;
    padding-top: 40px;
}
@media  (max-width:1200px) {
    .requst-detail-margin{
        padding: 0em 2em;
    } 
}
@media  (max-width:960px) {
    .requst-detail-margin{
        padding: 0em 1em;
    } 
}
@media (max-width:600px) {
    .requst-detail-margin{
        padding: 0em 1em;
    }
}
@media screen and (min-height:601px){ 
    .requests-side-tool button{
        width: 47px !important;
        height: 47px !important;
        top: 240px !important;
    }
    
    
    }

.okr-discard-request-delete-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 220px);
}

.discard-request-delete-warning{
    font-size: 30px;
}




.custom-popover-wrapper .ant-popover-inner{
    width: 230px !important;
    height: 130px !important;
    background: #255F63;
    border-radius: 5px;
}
    

  .ant-form-item-control-input-content{
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-popover-inner{
    margin-bottom: 5px;
  }

  .ant-popover-arrow{
    margin-bottom: 5px;
  }


  .ant-popover-inner-content{
    padding:0px !important;
  }
  
.rd3t-leaf-node{
  cursor: grab !important;
}
.aligin-center{
  display: flex;
  align-items: center !important;
}

.foreign-parent{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-node-border {
    border: 9px solid #25EA68 !important;
}

.foreign-rect{
    background-color: #fff;
    box-shadow: -1px 3px 23px -7px rgba(0, 0, 0, 0.17);
    border-radius: 13px;
    border: 9px solid #fff;
    height: 195px;
    width: 435px;
    max-width: 435px;
    cursor: pointer;
    /* position: relative; */
}

.foreign-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
}

.react-data-container{
  margin-left: -35px;
  padding-top:20px;
  height: 100%;
  width: 100%;
}

.foreign-circle-left{
    /* position: absolute !important; */
    margin-top: 55px !important;
    margin-left: -37px !important;
    /* z-index: 500; */
}

.node-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    margin: 0px !important;
    color: #FFFFFF;
    white-space: nowrap;
}
.node-goal-statement{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 31px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 380px;
    text-align: center;
}

.node-user-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 27px; */
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding: 0px;
}

.custom-node-btn{
    width: 45px;
    height: 35px;
    background: #FFFFFF;
    box-shadow: -1px 1px 8px -1px rgba(0, 0, 0, 0.09);
    border-radius: 2px;
    padding: 2px;
    /* margin: 10px 2px; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer !important;
    border: 1px solid transparent;
    position: static !important;
    position: initial !important;
}

.hover-key:hover{
  border: 1px solid #009AF1;
}

.hover-mile:hover{
  border: 1px solid #FAC53C;
}


.modal-heading{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin: 0px !important;
}

.close-icon{
  cursor: pointer;
  /* margin-right: 100px; */
}

.tree-modal .ant-modal-content {
    border-radius: 20px;
    width:100%;
    background-color: #F5F5F5;
}

.foreign-circle .ant-spin-dot-item {
    background-color: #FFF;
  }

  .null-link{
    stroke: black !important;
    stroke-width: 3px;
  }

  .objective-link{
    stroke: rgb(76, 203, 31) !important;
    stroke-width: 3px;
  }

  .key-res-link {
    stroke: #009AF1 !important;
    stroke-width: 3px;
  }

  .milestone-link{
    stroke: #FAC53C !important;
    stroke-width: 3px;
  }

  .progress-div{
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 55px;
  }

  .okr-title-div{
    padding: 8px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    overflow: hidden;
    width: 95%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mile-title-hover:hover{
    background: #d9a111 !important;
  }

  .key-title-hover:hover{
    background: #023fe6f0 !important;
  }

  .okr-title-div .title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    white-space: nowrap;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .container-div{
    width: 450px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: nowrap;
    margin: 15px 0px;
  }

  .container-div .progress{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #595959;
    margin: 0px;
  }

  .contributor-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
}

.contributor-circle .node-name{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.okr-list-container{
    max-height: 350px;
    overflow: auto;
}

.okr-list-container::-webkit-scrollbar {	
    height: 0px;	
    width: 8px;
    padding-left:10px;
  }	
  .okr-list-container::-webkit-scrollbar-track {	
    display:none;
  }	
  .okr-list-container::-webkit-scrollbar-thumb {	
    border-radius: 10px;	
    background-color:#F0F0F0;	
  } 

  .okr-child-modal{
    width: 565px !important;
    max-width: 95% !important;
  }

  .okr-child-modal .ant-modal-body{
    min-height: 437px !important;
  }

  

 

  .process-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .d3-modal-sub-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
  }

  .modal-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 27px !important;
    line-height: 33px;
    color: #262626;
  }

 

  .progress-parent .ant-progress-bg, .progress-parent .ant-progress-inner, .progress-parent .ant-progress-line{
    position: static !important;
    position: initial !important;
  }

  .level-label{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 31px;

    color: #000000;
  }

  .process-div{
    width: 85px;
  }


  .level-selector{
    width: 53px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
  }

  .level-selector .ant-select-selector{
    background-color: transparent !important;
    border-radius: inherit !important;
    border: none !important;
  }

  .level-info{
    width:16px;
    height: 16px;
    cursor: pointer;
  }

  .recenter-icon{
    width: 17px;
    height: 17px;
  }

  .arrows{
    width: 28px;
    height: 28px;
  }

  @media screen and  (min-width:1700px) {
    .close-icon{
      /* margin-right: 200px; */
    }

    .level-info{
      width:18px;
      height: 18px;
      cursor: pointer;
    }

    .process-div{
      width: 105px;
    }
    .level-selector{
      width: 65px;
    }
    .level-label{
      font-size: 20px;
    }
  
 }

 @media screen and (max-width:960px) {
  .modal-heading{
    font-size:30px ;
  }

  .close-icon{
    /* margin-right: 10px; */
  }

}

 @media screen and (max-width:600px) {
  .modal-heading{
    font-size:28px ;
  }

  .close-icon{
    /* margin-right: 5px; */
  }

}
.add-okr-table .ant-form-item-control-input-content {
    padding-left: 0px;
}


.okr-side-tool button {
    width: 47px !important;
    height: 47px !important;
    top: 210px !important;
}

.okrType-filter .selected-filter {
    color: rgb(48, 48, 48);
}

.okr-side-tool .ant-btn {
    top: 210px !important;
}


.okr-side-tool .ant-btn:hover {
    background: rgb(176, 203, 31) !important;
}

.okr-side-tool .ant-btn::before {
    background: rgb(176, 203, 31) !important;
}

.main-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: 52px;
    color: #141414;
    margin: 0px;
    text-align: center;
}

.okr-dashboard-tab .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
}

@media screen and (min-width:800px) and (max-width:950px) {
    .main-page-title {
        font-size: 28px;
    }
}

@media screen and (min-width:600px) and (max-width:800px) {

    .main-page-title {
        font-size: 28px;
    }
}

@media screen and (max-width:600px) {

    .main-page-title {
        font-size: 18px;
        line-height: 20px;
        color: #141414;
        margin: 0px !important;
    }
}



.okr-home-wrapper .title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 0px; */
}



@media (max-width:600px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}


@media screen and (min-width:600px) and (max-width:960px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}

@media screen and (min-width:960px) and (max-width:1200px) {

    .okr-back-button .back-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

}


@media screen and (min-width:1200px) and (max-width:1800px) {

    .okr-back-button .back-btn {
        width: 35px;
        height: 35px;
        margin-bottom: 0px;
    }

}

@media screen and (min-height:601px) {


    .okr-side-tool button {
        width: 47px !important;
        height: 47px !important;
        top: 240px !important;
    }
}

.okr-home-arrow {
    margin-left: 0.32em;
}

@media screen and (min-width: 569px) and (max-width: 568px) and (orientation: landscape) {
    .okr-home-arrow {
        margin-left: 0.25em;
    }
}

.okr-main-menu {
    background: #FFFFFF;
    box-shadow: 1px 3px 4.900000095367432px 4px #AABAC64D;
    border-radius: 0.625em;
    padding: 0.5em 0em;
    margin-top: 0.25em;
    width: 150px;
}

.okr-menu-label {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0.75em 0.5em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
}

.okr-row-wrapper {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.custom-okr-picker .ant-btn {
    border-radius: 12px !important;
}

.okr-detail-status-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
}

.okr-detail-status-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin: 5px;
}

.okr-detail-status-section h4 {
    margin: 0px;
    text-align: center;
}

.okr-status-progress-section {
    width: 300px;
    max-width: 100%;
}

.okr-detail-main-layout {
    width: 100%;
    padding: 0px 40px;
}

.okr-detail-status-sub-container {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
}

.okr-detail-titile-section {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 2.5em;
}

.okr-detail-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: normal;
    text-align: center;
    color: #000000;
    display: -webkit-box;
    margin: 0px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.okr-detail-input-box {
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width: 100% !important;
    font-family: 'Poppins';
    border-color: #a4a4a4 !important;
}

.okr-detail-competency-container{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    grid-gap: 1em;
    gap: 1em;
    flex-direction: row;
}

.okr-competency-card{
    width: 200px;
    padding: 24px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 42px 1px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1F1F1F;
    margin-bottom:15px ;
    max-width: 100% !important;
  }

@media screen and (max-width:1100px) {
    .okr-detail-status-container {
        flex-direction: column;
    }

    .okr-detail-status-sub-container {
        width: 100%;
        flex-wrap: wrap;
        grid-gap: 16px;
        gap: 16px;
        justify-content: center;
    }

    .okr-detail-titile-section {
        font-size: 14px;
    }
}

@media screen and (max-width:960px) {

    .okr-detail-titile-section {
        font-size: 12px;
    }
    .okr-competency-card{
        padding: 20px 8px;
    }
}

@media screen and (max-width:700px) {
    .okr-detail-competency-container{
        flex-direction: column;
    }
}

@media screen and (max-width:600px) {
    .okr-detail-main-layout {
        padding: 0px 0px;
    }

    .okr-detail-titile-section {
        font-size: 10px;
    }
}

@media screen and (max-width:480px) {
    .okr-competency-card{
        width: 100%;
    }
}


.your-kudos-card-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .your-kudos-card {
    border-radius: 12px;
    text-align: center;
  }
  
  .your-kudos-card-image {
    border-radius: 50%;
    height: 100px !important;
    width: 100px !important;

  }
  

  .your-kudos-card-title {
    margin: 0.3em 0 0.1em;
    font-size: 0.9em;
    font-weight: 600;
  }
  
  .your-kudos-card-subtitle {
    color: gray;
    font-size: 0.75em;
  }
  

  .your-kudos-card-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 0.5em;
  }
  

  .your-kudos-button {
    width: 85px;
    height: 30px;
    font-size: 0.7em;
    border-radius: 6px;
    font-weight: 500;
  }
  
  .your-kudos-receive {
    border-color: #4ccb1f;
    color: black;
  }
  
  .your-kudos-given {
    background-color: #4ccb1f;
    border-color: black;
    color: white;
  }
  .your-kudos-recent{
    margin-top: 1em;
    height: 4.5em;
    display: flex;
    flex-direction: row;
    border-radius: 1em;
    justify-content: space-around;
    align-items: center;
    margin: 40px 30px 0px 30px;
  }
  .your-kudos-recent-image{
    margin: 12px;
    border-radius: 50%;
  }
  .your-kudos-recent-badge{
    margin: 12px;
    border-radius: 50%;
  }



  .your-kudos-recent-text{
    color: white;
    font-size: small;
  }
  .your-kudos-main-title{
    display: flex;
    justify-content: space-around;
  }

.your-kudos-button,.your-kudos-button:hover,.your-kudos-button:focus{
  font-family: Poppins;
  font-weight: 700;
  font-size: .8em;
  line-height: normal;
  text-align: center;
  padding: 1em;
  border-radius: .5em;
  color: rgba(12, 12, 13, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  outline: none;
  width: 7em;
  height: 3em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

}
.your-kudos-button-select,.your-kudos-button-select:hover,.your-kudos-button-select:focus{
  font-family: Poppins;
  font-weight: 700;
  font-size: .8em;
  line-height: normal;
  text-align: center;
  padding: 1em;
  border-radius: .5em;
  color: #fff;
  border: 2px solid #4CCB1F;
  background:#4CCB1F;
  outline: none;
  width: 7em;
  height: 3em !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.your-kudos-barde-label{
  font-family: Poppisn;
  font-size: .9em;
  font-weight: 700;
  line-height: normal;
  font-style: normal;
  margin: 0px;
}
.your-kudos-popup-container-scroll{
  overflow: auto;
  max-height: 20em;
  padding-right: 10px;
}
.your-kudos-popup-container-scroll::-webkit-scrollbar {
  height: 4px;
  width: 4px !important;
  display:block;
}
.your-kudos-popup-container-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
  }
  
.your-kudos-popup-container-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#b1b0b0e1;
}
.kudos-card-root{
    font-size: 16px;
    font-family: Poppins;
}
.kudos-card-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    width: 100%;
}
.kudos-new-card{
    border: 1px solid #AABAC6 !important;
    border-radius: 1em !important;
    background: #ffff;
    width: 100%;
    padding: 1em;
    height: 100%;
    min-height: 30em;
}
.kudos-card-user-name{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    margin: 0px;
}
.kudos-card-user-role{
    font-family: Poppins;
    font-size: .95em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    margin: 0px;
    color: #8998A2;
}
.kudos-card-detail-container{
    margin: 1em 3em;
    height: 100%;
    border-radius: 1em;
    min-height: 20em;
    padding: 3em 2em;
}
.kudos-card-detail-title{
    font-family: Poppins !important;
    font-size:1.75em;
    font-weight: 500 !important;
    color: #fff;
    font-style: normal;
    margin: 0px;
}
.kudos-card-detail-description{
    font-family: Poppins;
    font-size:1em;
    font-weight: 400;
    color: #fff;
    font-style: normal;
    margin: 0px;
    padding-top: .5em;
}
.kudos-card-award-btn{
    box-shadow: -10px -10px 20px 0px rgba(250, 255, 255, 0.25);     
    font-size: 1em;
    font-family: Poppins;
    font-size: 1.2em;
    padding: .75em 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    border-radius: 1em;

}
.kudos-card-badge-image{
    width: 11.5em;
    height: 12em;
    object-fit: contain;

}
.kudos-card-comment-section{
    width:100%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    grid-gap:.5em;
    gap:.5em
}

.kudos-card-text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    max-width: 100%;
  }
.Kudos-card-badge-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:1em;
    gap:1em;
}
.Kudos-card-badge-image-container-inner{
    position: relative;
    width: 100%;
    height: 100%;
}  
.Kudos-card-badge-image-container-inner:hover .Kudos-card-badge-image-container-inner-front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
  
.Kudos-card-badge-image-container-inner:hover .Kudos-card-badge-image-container-inner-back {
-webkit-transform: rotateY(0);
        transform: rotateY(0);
}

.Kudos-card-badge-image-container-inner-front, .Kudos-card-badge-image-container-inner-back{
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
display: flex;
align-items: center;
justify-content: center;
transition: -webkit-transform 0.6s ease-in-out;
transition: transform 0.6s ease-in-out;
transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
backface-visibility: hidden;
}
.Kudos-card-badge-image-container-inner-back{
-webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
position: absolute;
top:0;
}

.kudos-card-filter-text {
    color: rgba(26, 26, 26, 1);
    font-size: 1em !important;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
  }
  .user-kudos-badge-select .ant-select-selector{
    background-color: #ffff !important;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    border-radius: 8px !important;
  }
.kudos-search-imput-popup,
.kudos-search-imput-popup:hover,
.kudos-search-imput-popup:focus{
    border: none !important;
    box-shadow: none !important;
}
.new-kudos-badge-award-carosul-container{
    border: 1px solid rgba(170, 186, 198, 1);
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 42em!important;
    height: 10em !important;
    padding: 0px 1em;
    border-radius: .5em;
    margin-top: 1em;
  }
  .new-kudos-badge-award-carosul-container .ant-spin-nested-loading{
    width: 100% !important;
  }
  .new-kudos-badge-award-carosul-container-loading{
    border: 1px solid rgba(170, 186, 198, 1);
      -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width:100% !important;
      min-width: 100% !important;
      max-width: 42em!important;
      height: 10em !important;
      padding: 0px 1em;
      border-radius: .5em;
  }
  .new-kudos-badge-award-carosul-container .slick-next{
    right: 0px !important;
  }
  .new-kudos-badge-award-carosul-container .slick-prev{
    left: -4px !important;
  }
  .new-kudos-user-award-badge-image-selected-container{
    height:8.5em;
    width:7.5em !important;
    border-radius: 8px;
    border: 1px solid #AABAC633;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    margin-top: 1em;
    cursor: pointer;
  }
  .new-kudos-user-award-badge-image-selected-container:hover{
    border: 1px solid #4CCB1F;
  }
  /* .dashboard-show-kudos-detail-card{
    display: none !important;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-show-kudos-detail-card{
    display: block;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected{
    display: none;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected-title{
    display: none;
  } */
  .new-kudos-user-award-badge-image-selected-container-selected{
    border: 2px solid #4CCB1F;
  }
  .give-kudos-popup-container-scroll{
    overflow: auto;
    max-height: 74vh;
    padding-right: 10px;
  }
  .give-kudos-popup-container-scroll::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    display:block;
}
.give-kudos-popup-container-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.give-kudos-popup-container-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}
.kudos-custom-date{
  margin-top: -15px;
  padding: 0px;
  font-family: 'Poppins';
  font-size: 12px;
  max-width: 440px;
  z-index: 1260 !important;
}
.kudos-custom-date .ant-picker-panel-container{
  box-shadow:none;
  border-radius: none;
  border: none;   
  margin-left: 3px !important;
  position: absolute;
  top:-300px !important;
}
.kudos-custom-date .ant-picker-range-arrow{
  display: none;
}
.kudos-custom-date .ant-picker-range-wrapper{
  /* min-width: 300px; */
  max-width: 540px;
}
.kudos-custom-date .ant-picker-date-panel .ant-picker-content{
  width:200px !important;
}
.kudos-custom-date .ant-picker-cell {
  padding: 0px;
}
.kudos-custom-date .ant-picker-date-panel .ant-picker-content th {
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}
.kudos-custom-date .ant-picker-date-panel{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}
.kudos-reaction-card{
  box-shadow: 0px 4px 22px 3px rgba(137, 152, 162, 0.3);
  background: rgba(255, 255, 255, 1);
  padding: .75em 2em;
  display: flex;
  flex-direction: row;
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1em;
}
.kudos-react-icon{
  height: 3em;
  width: 3em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kudos-react-gif{
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
  display: none;
}
.kudos-react-icon .kudos-react-icon-img{
  display: block;
  height: 2.25em;
  width: 2.25em;
}
.kudos-react-icon .kudos-react-gif{
  display: none;
}
.kudos-react-icon:hover .kudos-react-icon-img{
  display: none;
}
.kudos-react-icon:hover .kudos-react-gif{
  display: block;
  height: 2.75em !important;
  width: 2.75em !important;
}
/* .kudos-react-icon-party{
  display: block !important;
}
.kudos-react-icon-party:hover{
  display: none !important;
}
.kudos-react-gif-party{
  display: none !important;
}
.kudos-react-icon-party:hover .kudos-react-gif-party{
  display: block !important;
  } */
/* .kudos-react-icon-like:hover{
  display: none;
  }
.kudos-react-icon-heart:hover{
  display: none;
  } */

/* .kudos-react-icon-like:hover .kudos-react-gif-like{
  display: block;
  }
.kudos-react-icon-heart:hover .kudos-react-gif-heart{
  display: block;
  } */
 
.kudos-card-action-btn{
  width: 10em;
  font-size: 1.25em !important;
  font-weight: 500 !important;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  padding: 0.5em 1em;
}
.kudos-card-action-btn-active {
  background-color: rgba(170, 186, 198, 0.2);
  border-radius: 0.2em;
  width: 10em;
  font-size: 1.25em !important;
  font-weight: 500 !important;
  cursor: pointer;
  text-align: center;
  padding: 0.5em 1em;
}
.kudos-comment-input,.kudos-comment-input:hover,.kudos-comment-input:focus{
  width: 100%;
  background-color: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.47) inset;
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  padding-right: 80px;
  height: 3em;
  border-radius: .5em;
}
.kudos-emoji-picker-container{
  width:100%;
  max-width: 30em;
  position: absolute;
  bottom: 50px;
  right: 10px;
  background-color: #fff;
  border-radius: .5em;
  display: flex;
  flex-wrap: wrap;
  grid-gap:.25em;
  gap:.25em;
  z-index: 1000;
  max-height: 200px;
  overflow: auto;
  padding: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.kudos-badge-comment-section-scroll{
  height:100%;
  max-height:30em;
  overflow:auto;
}

.kudos-badge-comment-section-scroll::-webkit-scrollbar {
  height: 4px;
  width: 4px !important;
  display:block;
}
.kudos-badge-comment-section-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
  }
  
.kudos-badge-comment-section-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#b1b0b0e1;
}
.kudos-left-board-container-responseive{
  display: none !important;
}
.kudos-left-board-cards-responseive{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
  width: 100%;
  margin-bottom:2em;
}
@media (max-width:1400px) {
  .kudos-card-root{
    font-size: 14px;
    font-family: Poppins;
}
}
@media (max-width:960px) {
  .kudos-card-root{
    font-size: 12px;
    font-family: Poppins;
}
}
@media (max-width:600px) {
  .kudos-card-root{
    font-size: 10px;
    font-family: Poppins;
}
}
@media (max-width:480px) {
  .kudos-card-root{
    font-size: 8px;
    font-family: Poppins;
}
}
@media (max-width:1100px) {
  .kudos-card-section-list-container{
    width:100% !important
  }
  .kudos-left-board-container{
    display: none !important;
  }
  .kudos-left-board-container-responseive{
    display: block !important;
  }
}
@media (max-width:700px) {
  .kudos-left-board-cards-responseive{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    width: 100%;
    margin-bottom:2em;
  }
}
.top-receiver {
    background: linear-gradient(to right, #E0F2DB, rgba(224, 242, 219, 0));
    color: #333;
    border-radius: 5px;
    padding :5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-weight:bold ;
    margin-bottom: 20px;
}
.top-sender {
    background: linear-gradient(to right, #E0F2DB, rgba(224, 242, 219, 0));
    color: #333;
    border-radius: 5px;
    padding :5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-weight:bold ;
    margin-bottom: 20px;
}
.engagement-board-avatar{
    width: 45px;
    height: 45px;
    font-size: 2em;
    font-weight: 600;
    background-color: #AABAC6 !important;
    display: flex !important;
    align-items: center !important;
}
.engagement-board-title{
    margin-bottom: -5px;
    font-weight: 600 !important;
    cursor: pointer;
}
.comparison-container {
    margin-top: 1.5em;
    width: 100%;
    height: 100%;
}

.comparison-title {
    font-size: 1.4em;
}

.comparison-modal {
    width: 90% !important;
    max-width: 800px !important;
}

.comparison-btn {
    padding: 0em 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 1.15em;
    line-height: 1.25em;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.comparison-btn:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.comparison-btn:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.comparison-secton {
    padding: 0rem 3em;
    margin-top: 1.25em;
}

.heading-section {
    padding: 0em 0.95em;
}

.comparison-left-section {
    width: 50%;
}

.comparison-right-section {
    width: 50%;
}

.survey-edit-section div {
    margin-bottom: 0px !important;
}

.survey-edit-section .ant-col-offset-2 {
    margin-left: 0px !important;
}

.question-title {
    margin-top: 1em;
}

.question-title p {
    margin-bottom: 0px !important;
}

.comparision-headingSectiion {
    display: flex;
    flex-direction: row;
}



/* new css */

.user-manual-root {
    font-family: "Poppins";
    font-size: 16px;
    padding: 0em 5em;
}

.user-manual-align-action-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.user-manual-request-btn,
.user-manual-request-btn:hover,
.user-manual-request-btn:focus {
    padding: .5em 1em;
    font-size: 1.15em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(13, 214, 113, 1);
    color: #fff;
    border-radius: .5em;
    border: 1px solid rgba(13, 214, 113, 1);
}

.user-manual-switch-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.user-manual-switch-tab-text {
    padding: .5em;
    font-size: 1.15em;
    font-weight: 500;
    line-height: 1;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: 13.5em;
    height: 2.6em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-manual-switch-tab-selected {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}

.user-manual-main-body {
    width: 100%;
    border: 1px solid rgba(201, 208, 218, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 1em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
}

.user-manual-banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    border: 1px solid rgba(13, 214, 113, 1);
    border-radius: 8px;
    background: linear-gradient(270deg, #FFFFFF 0%, #E4FFF1 100%);

}

.user-manual-label {
    font-size: 3em;
    font-weight: 700;
    line-height: 1.2em;
    text-align: left;
    font-family: "Poppins";
}

.user-manual-label-compare {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1em;
    text-align: left;
    font-family: "Poppins";
    /* word-break: break-all; */
}

.user-manual-crad-section {
    padding: 1.25em;
    background: rgba(246, 251, 255, 1);
    margin: 0.25em 1.5em;
    border-radius: 8px;
}

.um-question-section {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.um-question-section p {
    font-size: 1.35em;
    font-weight: 500;
    line-height: normal;
    color: rgba(38, 88, 63, 1);
}

.um-question-section p em {
    font-size: 0.65em !important;
    font-weight: 400;
    line-height: normal;
    margin-left: -2.5em;
    text-align: left;
    color: rgba(184, 137, 51, 1);
    display: none;
}

.display-block-um-emtag p em {
    display: block !important;
}
.um-response-section {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.um-response-list {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5em;
    gap: 0.5em;
    width: 100%;
}

.um-response-card {
    padding: 0px 1em;
    border-radius: 4px;
}

.um-response-label {
    margin: 0px;
    font-size: 1em !important;
    font-weight: 500 !important;
    color: rgba(31, 31, 31, 1);
}

.um-card-space-btw-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: space-between;
    align-items: start;
}

.um-survey-select {
    width: 50%;
}

.um-edit-icon {
    cursor: pointer;
    width: 1.5em;
}

.um-card-action-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.user-manual-action-btn,
.user-manual-action-btn:hover,
.user-manual-action-btn:focus {
    padding: .5em 1em;
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 6em;
    height: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
    margin: 0.5em;
}

.um-action-green,
.um-action-green:hover,
.um-action-green:focus {
    background: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    color: #fff;
}

.um-action-dark,
.um-action-dark:hover,
.um-action-dark:focus {
    background: rgba(170, 186, 198, 1);
    border: 1px solid rgba(170, 186, 198, 1);
    color: #fff;
}

.um-compare-icon {
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
}

.um-compare-spacebtw {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    /* align-items: center; */
}


.um-employee-selector {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
    text-transform: capitalize;
}

.um-employee-selector .ant-select-selector {
    border-radius: 7px !important;
    border: 1.5px solid #BFBFBF !important;
    text-align: left !important;
    cursor: pointer !important;
    height: 3em !important;
}

.um-employee-selector input {
    cursor: pointer !important;
    height: 100% !important;
}

.um-employee-selector .ant-select-selection-item,
.ant-select-selection-placeholder {
    padding: 0px 0.5em !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
    color: #595959 !important;
}

.um-employee-selector .ant-select-selection-item div {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user_manual_banner_icon {
    width: 15em;
}

.um-response-card-height {
    height: 20em;
    overflow: auto; 
}

.um-response-card-height::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    display: block; 
}

.um-response-card-height::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px
}

.um-response-card-height::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.um-active-response{
    background: rgba(76, 203, 31, 0.3);
}
 
@media screen and (max-width:1100px) {
    .user_manual_banner_icon {
        display: none;
    }

    .compare-banner {
        height: 10em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-manual-label-compare {
        text-align: center !important;
    }
    .user-manual-align-action-section{
        flex-direction: column;
        grid-gap: 1em;
        gap: 1em;
    }
}

@media screen and (max-width:1000px) {
    .user-manual-team-logo{
        display: none;
    }

    .user-manual-label{
        text-align: center !important;  
        font-size: 2.25em;
    }
    
    .user-manual-banner{
        justify-content: center;
    }

    .um-compare-spacebtw{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:800px) {
    .user-manual-switch-tab-text {
        width: 12em;
    }
}

@media screen and (max-width:600px) {
    .user-manual-switch-tab {
        width: 100%;
    }

    .user-manual-switch-tab-text {
        width: 100%;
    }
}

@media (max-width:1400px) {
    .user-manual-root {
        font-size: 14px;
        padding: 0em 3em;
    }
}

@media (max-width:960px) {
    .user-manual-root {
        font-size: 12px;
        padding: 0em 2em;
    }

    .comparison-left-section {
        width: 100%;
    }

    .comparison-right-section {
        width: 100%;
    }

    .comparison-secton {
        padding: 0em 2em;
    }

    .heading-section {
        padding: 0em 2em;
    }
}

@media (max-width:600px) {
    .user-manual-root {
        font-size: 10px;
        padding: 0em 0em;
    }

    .comparison-secton {
        padding: 0em 1em;
    }

    .heading-section {
        padding: 0em 1em;
    }
}
.active-icon{
    display: block;
}
.icactive-icon{
    display: none;
}

.survey-comp-root{
    font-size: 16px;
}

.comp-header-nav-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2em;
}

.comp-tab-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.comp-tab-icon{
    width: 1.8em;
    height: 1.8em;
    margin: 0 .5em;
    cursor: pointer;
}

.comp-search-box{
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-radius: .5em;
    /* padding: .25em; */
    width: 50%;
    height: 2.5em;
    font-size: 1em
    /* border-right: none; */
}

.comp-request-note{
    width: 100%; 
    height: 15em;
    resize: none;
    border: 1px solid #C2C2C2;
    border-radius: .5em;
    outline: none !important;
    padding: .25em .8em;
    font-size: 1em;
    line-height: 1.5715;
    margin-top: 1em;
}

.comp-sent-request-btn{
    padding: 0.75em 1.5em;
    grid-gap: 0.625em;
    gap: 0.625em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin:1em;
}

.comp-sent-request-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.comp-sent-request-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.request-scroll{
    margin-top: 1em;
    height: 50vh;
    overflow: auto;
    padding: 1em 1em 1em 0px;
}

.request-data-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.request-main-padding{
    padding: 1em;
    width: 50%;
    padding-left: 0px !important;
}

.request-user-card{
    display: flex;
    flex-flow: row nowrap;
    grid-gap: .5em;
    gap: .5em;
    justify-content: space-between;
    align-items: center;
    border-radius: .5em;
    padding: .5em;
    box-sizing: border-box;
    border: 1px solid #4CCB1F;
    cursor: default;
    width: 100%;
}

.profile-info-contianer{
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    grid-gap: .5em;
    gap: .5em;
}

.req-user-image-div{
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
}

.req-user-char-div{
    border: 1px solid #4CCB1F;
    display: flex;
    padding: 0px !important;
    align-items: center;
    justify-content: center;
}

.req-user-char-div h4{
    margin: 0 !important;
    line-height: 0 !important;
}
.req-user-image-div .profile-image{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.um-request-tab-info{
    width: 100%; 
    display: flex;
    flex-direction: column;
}

.request-user-name{ 
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 1em;
}

.request-user-email{
    font-size: 0.75em;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.comp-request-btn{
    padding: 0.75em;
    grid-gap: 0.625em;
    gap: 0.625em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 400;
    font-size: .8em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.comp-request-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.comp-request-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.request-bin{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
}

.request-card{
    display: flex;
    flex-flow: column ;
    grid-gap: .5em;
    gap: .5em;
    border-radius: .5em;
    padding: .5em;
    box-sizing: border-box;
    border: 1px solid #4CCB1F;
    cursor: default;
    width: 100%;
}

.request-card-section{
    display: flex;
    flex-flow: row nowrap ;
    justify-content: space-between;
    align-items: center;
}
.request-accepted,.request-pending{
    margin-left: .5em;
}
.request-accepted img,.request-pending img{
    height: 1em;
    width: 1em;
}

.request-accepted span{
 color: #4CCB1F;
}

.request-pending span{
 color: #22A6FC;
}

.req-msg-icon{
    width: 2em;
    height: 2em;
    cursor: pointer;
}

.req_pagination_view{
    cursor: pointer;
}
.req_pagination_view:hover{
    text-decoration: underline;
    color: #4CCB1F;
}

@media only screen and (max-width: 1200px) {
    .survey-comp-root{
        font-size: 14px;
    }
}


@media only screen and (max-width: 600px) {
    .survey-comp-root{
        font-size: 12px;
    }
    .request-main-padding{
        width: 100%;
    }
}




.sales-tool-heading{
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
}

.sales-tool-description{
    width: 80%;
    font-size: 16px;
    font-weight: 500;
}

.tool-subheading-sales-tool{
    font-size: 35px;
    margin-bottom: 20px;
    margin-top: -30px;
    font-weight: 500;
}


@media screen and (max-width:1200px) {


 .sales-tool-heading{
        font-size: 35px;
    }

    .sales-tool-description{
        width: 100% !important;
        font-size: 20px;
    }

    .mapp-tool-text-col-sales{
        max-width: 100% !important;
        margin-left: 0px !important;
    }


    .mapp-tool-text-col-sales ul{
        max-width: 80% !important;
        margin-left: 40px !important;
    }



}




@media screen and (max-width:768px) {


    /* mapp tool styles */

    .sales-tool-button span{
        font-size: 16px;
    }



    .mapp-tool-text-col-sales{
        margin-top: 40px;
    }

    .sales-tool-heading,.tool-subheading-sales-tool,.sales-tool-description{
        text-align: start;
    }


    .mapp-tool-sales-page{
        flex-direction: column-reverse;
    }

    .sales-tool-heading,.tool-subheading-sales-tool,.sales-tool-description{
        text-align: justify;
    }

    .sales-tool-heading{
        font-size: 35px;
    }

   

}





@media screen and (max-width:500px) {

    /* mapp tool styles */

    .sales-tool-button span{
        font-size: 14px;
    }

    .sales-tool-heading{
        font-size: 25px;
        margin-bottom: 20px;
    }

    .tool-subheading-sales-tool{
        margin-top: 10px;
    }

    .tool-subheading-sales-tool{
        font-size: 18px;
    }
 

}

@media screen and (min-width:1500px) {

    .sales-tool-description{
    font-size: 20px;
    }
}

@media screen and (min-width:700px) and (min-height:768px) {

    .mapp-tool-sales-page{
        flex-direction: row;
    }
 
}


.create-bars-root {
    font-size: 16px;

}

.create-bars-body {
    width: 90%;
    border-radius: 0.8em;
    margin: auto;
    background-color: #F4F9FD;
    padding: 1.5em 4em;
}

.create-bars-header {
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 2.1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.37em;
    margin-bottom: 1em;
    /* 88% */
}

.create-bars-header span {
    font-weight: 700;
}

.create-bar-sub-he {
    font-size: 1.2em;
    font-weight: 500;
}

.bars-create-competency-title {
    padding: 0.7em 0em 0.7em 2.5em;
    border-radius: 0.5em;
    background: #fff;
    border: 1px solid #AABAC6;
}

.bars-tab-panel .ant-tabs-tab-active, .bar-sub-competency-view .ant-tabs-tab-active{
    background: #4CCB1F;
    color: #fff;
    border-radius: 0.5em;
}

.bars-tab-panel .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.bar-sub-competency-view .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: #fff;
    padding: 0.7em 1.3em 0.7em 1.3em;
    font-size: 1.3em;
    font-weight: 500;
}

.bars-tab-panel .ant-tabs-tab-btn, .bar-sub-competency-view .ant-tabs-tab-btn{
    font-size: 1.3em;
    font-weight: 500;
    padding: 0.7em 1.3em 0.7em 1.3em;
    text-align: center;
    width: 100%;
}

.bars-tab-panel .ant-tabs-tab-btn, .bar-sub-competency-view .ant-tabs-tab-btn:hover{
    color: #000;
}

.bars-tab-panel .ant-tabs-ink-bar, .bar-sub-competency-view .ant-tabs-ink-bar  {
    display: none;
}

.bars-tab-panel .ant-tabs-tab+.ant-tabs-tab, .bar-sub-competency-view .ant-tabs-tab+.ant-tabs-tab{
    margin: 0px;
}

.bars-tab-panel .ant-tabs-tab, .bar-sub-competency-view .ant-tabs-tab {
    min-width: 10em;
}

.bars-tab-panel .ant-tabs-tab,  .bar-sub-competency-view .ant-tabs-tab {
    padding: 0em;
}

.bars-rating-scale-text {
    width: 100%;
    height: 8.5em !important;
    border-radius: 0.5em;
    padding: 1.5em;
    border: 1px solid #AABAC6;
}

.bars-rating-scale-text:focus {
    border: 1px solid #AABAC6;

}

.bars-rating-scale-text:focus-visible {
    outline: none;
}

.bars-tab-panel .ant-tabs-nav-wrap, .bar-sub-competency-view .ant-tabs-nav-wrap {
    border: 0.0625em solid #AABAC6;
    border-radius: 0.5em;
    background-color: #fff;
}

.create-bar-sub-heading {
    font-size: 1.2em;
    font-weight: 600;

}

.create-bar-left-arrow-completed{
    cursor: pointer !important;
}

.create-bar-left-arrow-completed, .create-bars-arrow-completed{
    -webkit-filter: invert(54%) sepia(94%) saturate(421%) hue-rotate(59deg) brightness(100%) contrast(93%);
            filter: invert(54%) sepia(94%) saturate(421%) hue-rotate(59deg) brightness(100%) contrast(93%);
}


.bars-button-panel-container .ant-btn[disabled], 
.bars-button-panel-container .ant-btn[disabled]:hover, 
.bars-button-panel-container .ant-btn[disabled]:focus, 
.bars-button-panel-container .ant-btn[disabled]:active{
    font-size: 1.2em;
    font-family: poppins;
    border-radius: 0.5em;
    height: 3.3em;
    background-color:#F4F9FD;
    padding: 0.7em 1.3em;
    color: #8C8CA1 ;
    border: 0.0625em solid #8C8CA1;
    background: #F4F9FD;
}


.bars-next-level {
    font-size: 1.2em;
    font-family: poppins;
    border-radius: 0.5em;
    height: 3.3em;
    background-color:#F4F9FD;
    padding: 0.7em 1.3em;
    color: #8C8CA1 ;
    border: 0.0625em solid #8C8CA1;
    background: #F4F9FD;
}

.bars-next-level:hover,
.bars-next-level:focus {
    color: #8C8CA1;
    border-radius: 12px;
    border: 0.0625em solid #8C8CA1;
    background-color: #F4F9FD;
}


.bars-next-level-completed{
    background-color: #F4F9FD !important;
      border-color: #4CCB1F !important;
      color: #4CCB1F !important;
    }
    


.create-bars-arrow {
    width: 1em;
}

.create-bar-left-arrow {
    width: 4.6em;
    cursor: not-allowed;
}

.create-bar-margin-seperator {
    margin-bottom: 1em;
    margin-top: 0.6em;
}

.bars-button-panel-container {
    margin-top: 2em;
    margin-bottom: 1.5em;
}

.create-bar-back-arrow {
    position: relative;
    left: 1em;
    top: 4em;
}

.create-bar-competency-heading {
    color:  #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25em;
}

.bar-sub-competency-view .ant-tabs-nav-wrap{
    padding: 0.7em;
}

.bars-tab-panel .ant-tabs-nav-wrap{
    padding: 0em;
}


.complete-level-bars{
    color: #000 !important;
}


.incomplete-level-bars{
    font-family: poppins;
    color: #8C8CA1 !important;
}


.bars-rating-scale-text:hover{
border: 1px solid #AABAC6;
}


.define-bars-selector .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #AABAC6 !important;
    box-shadow: none !important;
}

.define-bars-selector .ant-select-selector{
    border-color: #AABAC6 !important;
}

.define-bars-selector .ant-select-selector:hover{
    border-color: #AABAC6 !important;
}


.define-bars-selector .ant-select-selector:focus{
    box-shadow: none !important
}

.define-bars-selector .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #c7d943;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
}
.bars-home-root {
    font-size: 16px;
    padding-right: 3.5em;
}


.bars-header {
    font-size: 2em;
    color: #434343;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    position: relative;
    right: 1em;
}

.bars-heading-back-arrow-container {
    width: 100%;
    margin-bottom: 1.87em;
}

.bars-add {
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0em 0.125em 0em 0em rgba(0, 0, 0, 0.04);
    color: #FFF;
    text-align: center;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 500;
    display: flex;
    width: 7.5em;
    height: 2.8em;
    padding: 0.25em 0.93em;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    flex-shrink: 0;
    margin-bottom: 1.5em;

}

.bars-add span {
    padding-right: 0.62em;
}

.bars-filter-input {
    width: 22.62em;
    height: 2.8em;
    flex-shrink: 0;
    border-radius: 0.5em 0em 0em 0.5em;
    border: 0.062em solid #A9A9A9;
    border-right: none !important;
    padding: 0.4em 0.4em 0.4em 0.4em;
}

.bars-filter-input.ant-input-affix-wrapper>input.ant-input {
    padding-left: 0.6em;
}

.bars-filter-container {
    border-radius: 0em 0.5em 0.5em 0em;
    border: 0.062em solid #A9A9A9;
    background: #FFF;
    grid-gap: 0.62em;
    gap: 0.62em;
    height: 2.45em;
    flex-shrink: 0;
    
}

.bars-selected-filter {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    padding-left: 1.5em;
}

.bars-filter-drop-down-icon{
    padding-right: 1.5em;
}

.filter-overlay-container {
    background: #ECF1F4;
    padding: 0em;
    border-radius: 0.5em;
}

.bars-filter-element {
    color: #000;
    padding: 0.6em 1em;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
    /* 110% */
}

.bars-filter-element-action-overlay {
    color: #000;
    padding: 0em !important;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    width: 10em;
    justify-content: center;
    font-weight: 400;
    line-height: 1.37em;
}

.bars-filter-element-incomplete {
    color: gray;
}

.bars-table {
    padding: 2.5em 3.5em 1em 3.5em;
    border-radius: 1em;
    border: 0.0625em solid #ECF1F4;
    background: #F4F9FD;
    margin-top: -3.125em;
}

.bars-table .ant-table-thead>tr>th {
    text-align: center;
    z-index: 100;
}


.bars-table .ant-table-container table>thead>tr:first-child th:last-child {
    padding-left: 2em;
}

.bars-functionality-panel {
    margin-bottom: 1.5em;
    flex-direction: column;
}

.bars-filter-input:hover,
.bars-filter-input:focus {
    border: 0.062em solid #d9d9d9;
}




.bars-table table {
    border-spacing: 0em 1em;
}

.bars-table .ant-table {
    background: #F4F9FD;
}

.bars-table tr {
    background-color: #FFF;
}

.bars-table .ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #FFF;
}

.bars-table .ant-table-thead>tr>th {
    background: none;
}

.bars-table thead {
    /* need to manage background gradient */
    /* background:  linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%); */
}

.bars-table .ant-table-cell:first-child {
    padding: 10px 0px 10px 12px !important;
}


.bars-table .ant-table-cell:nth-child(3) div {
    display: flex;
    justify-content: center;
}

.bars-incomplete-action {
    color: #0098F0;
    font-size: 0.9em;
    font-weight: 400;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.bars-table tbody tr td {
    height: 4.5em;


}

.bars-table .ant-table-tbody>tr>td {
    border-top: 1px solid #AABAC6;
    border-bottom: 1px solid #AABAC6;
}

.bars-table tbody tr td:first-child {
    border-left: 1px solid #AABAC6;
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em !important;

}

.bars-table tbody tr td:last-child {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-right: 1px solid #AABAC6;
}

.bars-status-container-incomplete div,
.bars-status-container-complete div {
    padding: 10px 0px 10px 10px;
    border-radius: 8px;
}

.bars-status-container-incomplete,
.bars-status-container-complete {
    border-radius: 0.5em;
    width: 8em;
}

.bars-status-container-complete {
    width: 9em;
}

.bars-status-container-incomplete {
    background: rgba(225, 63, 63, 0.10);
}

.bars-status-container-complete {
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(76, 203, 31, 0.10);
}

.bars-table .ant-btn:hover,
.bars-table .ant-btn:focus {
    border: 1px solid #4CCB1F;
    color: #1F1F1F;
}

.bars-table thead tr th {
    height: 3.2em;
    font-size: 1.4em;
    font-weight: 600;
}

.bars-table thead tr th:first-child {
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em;
}

.bars-table thead tr th:last-child {
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em;
}

.bars-action-element {
    grid-gap: 0.8em;
    gap: 0.8em;
    padding-bottom: 0.8em;
}

.bars-action-element div {
    font-weight: 500;
}

.bars-action-element img {
    width: 1.5em;
}

.bars-action-element:last-child {
    padding-bottom: 0em;
}

.bars-action-element-action-overlay {
    grid-gap: 0.8em;
    gap: 0.8em;
    padding-bottom: 0.8em;
}

.bars-action-element-action-overlay div {
    font-weight: 500;
}

.bars-action-element-action-overlay img {
    width: 1.5em;
}

.bars-action-element-action-overlay:last-child {
    padding-bottom: 0em;
}

.bars-action-element-action-overlay:hover {
    background-color: #4CCB1F;
    color: #fff;
}




.bars-add:hover,
.bars-add:focus,
.bars-add:active {
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}


.filter-overlay-container .ant-dropdown-menu-item-active {
    background: #ECF1F4 !important;
}

.bars-search-icon {
    position: absolute;
    left: 1.2em;
    width: 1.25em;
    top: 0.62em;
}

.bars-expanded-table {
    position: relative;
    left: 2em;
}

.bars-expanded-table thead tr th {
    font-size: 1.2em;
}

.bars-expanded-levels-container {
    display: flex;
    justify-content: center;
    position: relative;
    left: 10px;
}

.bars-expanded-table .ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #F4F9FD;
}

.bars-expanded-table tr {
    background-color: #F4F9FD;
}

.bars-expanded-table .ant-table-tbody>tr>td {
    border-bottom: none;
    border-top: none;
}

.bars-expanded-table tbody tr td:first-child {
    border-left: none;
    border: none;
}

.bars-expanded-table tbody tr td:last-child {
    border-right: none;
    border: none;
}


.bars-popup-message {
    text-align: center;
    font-family: poppins;
    font-size: 1.15em;
    font-weight: 500;
    margin-bottom: 1em;
}

.bars-confimation-action {
    background: #4CCB1F;
    width: 5.81em;
    font-size: 1.15em;
    color: #FFF;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
}

.bars-cancel-action {
    background: #fff;
    width: 5.81em;
    font-size: 1.15em;
    color: #000;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #000;
}

.bars-confimation-action:hover,
.bars-confimation-action:focus {
    background: #4CCB1F;
    color: #FFF;
    border-color: #4CCB1F;
}

.bars-cancel-action:hover,
.bars-cancel-action:focus {
    background: #fff;
    color: #000;
    border-color: #000;
}


.bars-submit-action {
    background: #4CCB1F;
    width: 7.61em;
    font-size: 1.15em;
    color: #FFF;
    font-weight: 500;
    height: 3em;
    border-radius: 0.5em;
    margin-top: 2em;
}

.bars-submit-action:hover,
.bars-submit-action:focus {
    background: #4CCB1F;
    color: #FFF;
    border-color: #4CCB1F;

}


.bars-popup-sub-message {
    text-align: center;
    font-family: poppins;
    font-size: 0.9em;
    margin-bottom: 1.5em;
}

.bars-confirm-action-delete {
    background: #E86161;
    width: 5.81em;
    font-size: 1.15em;
    color: #fff;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #E86161;
}

.bars-confirm-action-delete:hover,
.bars-confirm-action-delete:focus {
    background: #E86161;
    color: #fff;
    border: 1px solid #E86161;
}

.bars-action-icon {
    width: 1.8em;
}

.permission-check-bars {
    width: 100%;
}

.bars-filter-element-incomplete:hover,
.bars-filter-element:hover {
    background-color: #4CCB1F !important;
    color: #fff;
}

.bars-filter-element:hover .bar-action-icon {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}


.action-overlay-bar .ant-dropdown-menu-item {
    padding: 0em !important;
}


.bar-action-icon {
    width: 1.4em;
}

.view-more-bars {
    width: 100%;
    margin-top: 1em;
    font-size: 1.3em;
    font-weight: 500;

}

.bars-selected-level {
    background-color: #4CCB1F;
    color: #fff;
}

.bars-filter-input:hover {
    border-color: #A9A9A9 !important;
    box-shadow: none;
}


.bars-table-gradient {
    width: 91.2%;
    position: relative;
    height: 3.97em;
    background: linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
    background: var(--Dashboard-2, linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%));
    z-index: 100;
    top: 4.19em;
    margin: auto;
    border-radius: 0.5em;
}

.bars-defining-hover-effect{
    font-size: 1em;
    font-family: poppins;
    background: #0E0E2C;
    border-radius: 0.8em;

}


.bars-defining-hover-effect .ant-tooltip-inner{
    border-radius: 0.8em;
}

.bars-table-attribute{
white-space: nowrap;
overflow: hidden;
max-width: 16em;
text-overflow: ellipsis;
}
.define-bars-root {
    font-size: 16px;
}


.bars-drop-down-button {
    border-radius: 1em;
    border: 0.062em solid #AABAC6;
    background: #FFF;
    height: 3.2em;
    padding-left: 2em;

}

.define-bars-drop-down-container {
    border-radius: 1em;
    border: 0.062em solid #ECF1F4;
    background: #F4F9FD;
    padding: 3em 4em 3em 4em;
    margin: auto;
}

.define-bars-header {
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 2.27em;
    font-style: normal;
    font-weight: 500;
    line-height: 2.87em;
    /* 100% */
}

.define-bars-header span {
    font-weight: 700;
}

.define-bars-competency-header {
    color: #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.16em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.37em;
    margin-bottom: 0.8em;

}

.define-bars-dropdown {
    top: 28.4em !important;
    max-height: 20em;
    overflow: auto;
}

.define-bars-overlay-container {
    width: 60%;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu {
    background: #ECF1F4;

}

.define-bars-overlay-container .ant-dropdown-menu-submenu-title {
    height: 3.5em;
    color: #000;
    font-family: Poppins;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
}

/* .define-bars-overlay-container .ant-dropdown-menu-item:hover, */
.define-bars-overlay-container .ant-dropdown-menu-submenu-title:hover {
    background: #ECF1F4;
}

.define-bars-overlay-container {
    background-color: #ECF1F4 !important;
    padding: 0px;
}

.define-bars-dropdown{
   width:60% !important;
}

.define-bars-overlay-container .ant-dropdown-menu-item:hover{
    border: 0.062 solid #4CCB1F;
}

.define-bars-overlay-container .ant-dropdown-menu-item,
.define-bars-overlay-container .ant-dropdown-menu-submenu-title {
    padding: 0.90em 0.75em;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu-title:hover{
    border: 1px solid #4CCB1F;
}

.define-bars-selected-row div{
    background: #4CCB1F !important;
    color: #FFF;
}

.define-bars-selected-row .ant-dropdown-menu-submenu-title{
    color: #FFF;
}

.define-bars-overlay-container .ant-dropdown-menu-item-only-child{
    width: 40%;
}

.define-bars-submenu {
    width: 32em;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: poppins;
}

.define-bars-submenu:hover{
    background: #ECF1F4;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu-popup{
    top: 366px;
    background: #ECF1F4;
}

.ant-dropdown-menu-submenu-popup{
    padding: 0px;
}


.ant-dropdown-menu-submenu-popup ul{
    padding: 0px;
    background: #ECF1F4;
}


.define-bars-dropdown .ant-dropdown-menu-submenu-expand-icon{
    width: 0.6em;
    position: absolute;
    right: 1.125em;
    top: 1.2em;
 }

 .define-bars-selected-row .ant-dropdown-menu-submenu-expand-icon{
    -webkit-filter:brightness(0) invert(1);
            filter:brightness(0) invert(1);
 }

 .define-bar-back-arrow{
    position: relative;
    left: 5em;
    top: 4em;
 }

 .define-bars-selector{
    width: 100%;
    border-radius: 0.8em;
 }

 /* .define-bars-drop-down-container */
 .define-bars-selector
  .ant-select-selector{
    height: 3.5em !important;
    border-radius: 0.8em !important;
 }

 .define-bars-selector .ant-select-selection-item,.define-bars-selector .ant-select-selection-placeholder{
    padding: 0.53em 0.625em !important;
 }

 .define-bars-selector  .ant-select-selection-search-input{
    margin-top: 0.5em !important;
    margin-left: 0.6em !important;
 }


 .define-bars-option{
    height: 3.3em !important;
    font-size: 1.12em;
    font-weight: 400;
    background-color: #ECF1F4 !important;
 }
 
 .define-bars-option .ant-select-item-option-content{
    margin-top: 0.65em;
    font-family: poppins;
 }

 .define-bars-option:hover{
    background-color: #4CCB1F !important;
    color: #fff;
    font-family: poppins;
 }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: #ECF1F4 !important;
    color: #fff;
    font-size: 1em;
 }



 .ant-select-item-option-selected:hover{
    background-color: #4CCB1F !important;
    color: #fff !important;
 }

 .ant-select-item-option-selected{
    font-size: 1.12em !important;
 }

 .define-bars-selector .ant-select-selection-item{
    font-size: 1.12em;
 }

 .define-bars-selector .ant-select-selection-placeholder{
    font-size: 1.12em !important;
 }

 .ant-select-item-option-selected{
    color: #fff !important;
    font-size: 1.12em !important;
    background-color: #ECF1F4 !important;
 }

 .ant-select:not(.ant-select-disabled):hover, .ant-select-selector .define-bars-selector{
    border-color: #AABAC6 !important;
 }

 .define-bars-selector .ant-select:not(.ant-select-disabled):hover,.define-bars-selector .ant-select-selector:focus,
 .define-bars-selector .ant-select-selector:active {
    border-color: #AABAC6 !important;
    outline: none !important;
 }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    font-size: 1.12em !important;
 }

.bar-detail-heading-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    padding: 0em 2.5em;
}
.bar-detail-title{
    color: #262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.bar-detail-container-seaction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 3em;
    gap: 3em;
    width: 100%;
    margin-top: 2em;
}
.bar-select-filed .ant-select-selector,.bar-select-filed .ant-select-selector:hover,.bar-select-filed .ant-select-selector:focus,.bar-select-filed .ant-select-selector:focus-visible,.rd-select-filed .ant-select-selector:focus-within{
    background: linear-gradient(270deg, #25EA68 18.24%, #02D7A4 87.83%) !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: none !important;
    font-weight: 400;
    font-size: 1em;
    padding-right: 1em !important;
    min-width: 12em;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.bar-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1.15em;
    font-family:'Poppins';
    color: #fff !important;
    font-weight: 400;
}
.bar-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 1.15em;
    text-align: left !important;
}
.bar-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 1.15em;
}
.bar-raing-scale-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.5em;
    padding: 0em 5em;
}
.bar-rating-card-container{
    padding: 1em;
}
.bar-rating-card{
    min-width: 17em;
    border-radius: 0.5em;
    background: #FFF;
    box-shadow: 0px 4px 89px 1px rgba(210, 216, 220, 0.57);
    width: 100%;
    min-height: 25em;
    max-height: 35em;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    padding:2em !important;
}
.bar-rating-scale-title{
    color:#262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.bar-rating-scale-description{
    color: #000;
    text-align: justify;
    -webkit-font-feature-settings: 'clig' off, 'liga' off;
            font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.05em;
    overflow-y: auto;
    word-break: break-all;
}
.bar-tab-container{
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0px 6px 45px 0px rgba(170, 186, 198, 0.21);
    width: 100%;
    height: 5em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    grid-gap: 3em;
    gap: 3em;
    padding: 2em;
    overflow-x: auto;
    overflow-y:hidden;
}
.bar-tab-container-seaction{
    padding: 0em 5.5em;
    width: 100%;
}
.bar-tab-title{
    text-align: center;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
}
.bar-sub-title-container{
    display: flex;
    justify-content: start;
    width:"100%";
    padding:0em 5.5em
}
@media (max-width:1000px) {
    .bar-rating-card-container{
        width: 33.33%;
    }
}
@media (max-width:800px) {
    .bar-rating-card-container{
        width: 50%;
    }
    .bar-sub-title-container{
        padding:0em 3.5em
    }
    .bar-tab-container-seaction{
        padding:0em 3.5em
    }
    .bar-raing-scale-container{
        padding:0em 3.5em
    }
}
@media (max-width:560px) {
    .bar-rating-card-container{
        width: 100%;
    }
    .bar-sub-title-container{
        padding:0em 2em
    }
    .bar-tab-container-seaction{
        padding:0em 2em
    }
    .bar-raing-scale-container{
        padding:0em 2em
    }
}
.rd-job-family-sub-info-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em 2em;
    position: relative;
}

.rd-job-family-sub-info-heading-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.rd-job-family-sub-info-title {
    color: #414141;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.675em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    min-width: 6.25em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: rgba(255, 255, 255, 0.37);
    box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.10);
    padding: .7em 1em;
    border-radius: 1em;
    height: 3em;
    width: fit-content;
}

.rd-job-family-sub-info-label {
    color: #000;
    text-align: left;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}


.jf-modal-close-icon {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
}

.jf-no-data-fund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4em;
}

.rd-job-family-sub-info-label-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    margin-top: 1em;
    max-height: 60%;
    overflow: auto;

}




.jf-margin-seperator {
    margin-top: 1em;
    margin-bottom: 1em;
}

.jf-row-header-seperator {
    margin-bottom: 0.8em;
}



.jf-body {
    padding: 1.5em 3em 2.5em 3em;
    background: #F4F9FD;
    border-radius: 1em;
}

.jf-title {

    text-align: center;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 2.87em;

}


.jf-icons {
    width: 1.4em
}

.jf-icons:first-child {
    margin-right: 0.5em;
}

.jf-text-content-body {
    text-align: center;
    line-height: 1.37em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.jf-attribute-values {
    padding: 1.25em 1.35em 1.25em 1.35em;
    font-weight: 500;
    border-radius: 1em;
    background: #FFF;
    font-size: 1em;
}

.jf-text-body {
    color: #000;
}

.jf-text-header {
    color: #262626;
}


.jf-side-info {
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 500;

}

.jf-side-info:first-child {
    margin-right: 1em;
}

.jf-row-header {
    text-align: left;
    font-size: 1.16em;
    font-style: normal;
    font-weight: 600;

}

.jf-spinner {
    top: 10em;
}


.jf-desc-header {
    color: #000;
    text-align: left;
    font-family: Poppins;
    font-size: 0.95em;
    font-style: normal;
    font-weight: 600;
    line-height: 0.97em;
}


.jf-description {
    color: #000;
    font-size: 0.6em;
    font-style: normal;
    font-weight: 500;
    line-height: 0.97em;

}

.jf-margin-seperator .ant-col-8 {
    display: block;
    flex: 0 0 31.33333333% !important;
    max-width: 31.33% !important;
}

.jf-margin-seperator-eligibilities .ant-col-8 {
    display: block;
    flex: 0 0 48.33333333% !important;
    max-width: 48.33% !important;
}

.jf-margin-seperator-eligibilities .jf-text-content-body {
    text-align: left;
    white-space: normal;
}

.jf-attr-row {
    grid-column-gap: 1em !important;
    -webkit-column-gap: 1em !important;
            column-gap: 1em !important;
    grid-row-gap: 1em !important;
    row-gap: 1em !important;
}

.jf-attr-section-margin-seperator {
    margin-bottom: 1.5em;
}

.eligibility-desc {
    margin-bottom: -0.62em;
}



.rd-detail-pop-up-title div {
    color: #414141;
    text-align: center;
    margin-top: 2.75em;
    white-space: nowrap;
    z-index: 500;
    -webkit-font-feature-settings: 'clig' off, 'liga' off;
            font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 1.62em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.05em;
    padding: 0.55em;
    border-radius: 0.5em;

}

.rd-detail-pop-up-title {
    border-radius: 2.5em;
    width: 100%;
    margin: auto;
    box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
}

.rd-detail-popup-margin-spacer {
    margin-top: 3.125em;
    margin-bottom: 3.125em;
}

.rd-detail-popup-attbitute-title {
    color: #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.37em;
}

.rd-detail-root {
    max-height: 36em;
    overflow: auto;
    position: relative;
    padding: 0em 4.37em 4.125em 4.37em;
}

.rd-detail-desc {
    line-height: 2.1;
    font-size: 1.1em;
}

.rd-detail-desc {
    font-size: 1.1em;
}

.close-icon-rd-detail {
    width: 2em;
    z-index: 1500;
    margin-bottom: 1em;
    cursor: pointer;
    position: absolute;
    top: 1.85em;
    right: 1.55em;
}


.rd-details-modal .ant-modal-body {
    padding: 0px !important;
}


.header-info-rd-detail {
    flex-wrap: nowrap;
}


@media screen and (max-width:600px) {


    .header-info-rd-detail {
        flex-wrap: wrap;
    }

    .jf-title {
        line-height: 1.5em;
        text-align: left;
    }

    .header-rd-detail-actions {
        margin-bottom: 2em;
        margin-top: 1em;
    }

    .jf-margin-seperator .ant-col-8 {
        display: block;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width:380px) {


    .jf-body {
        padding: 1.5em 1.5em 2.5em 1.5em;
    }

}




.hide-btn, .hide-btn:hover, .hide-btn:focus, .hide-btn:active {
    border: none;
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px 0px 0px 8px !important;
}

.back-arrow-rd-detail{
    width: 20px;
    height: 20px;
    margin-left: 1em;
}
.rd-root{
    font-size: 16px;
    font-family: Poppins;
}

.rd-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: hidden;
    padding-bottom: 1.5em;
    min-height: 80vh;
}

.page-main-title{
    color:#262626;
    text-align: center;   
    font-size: 2.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
    font-family: Poppins;
    margin-top: 0.35em;
    margin-bottom: -0.05em;
}

.rd-container{
    /* height: calc(100vh - 150px); */
    width: 100%;
    /* overflow: auto; */
}

.rd-sidebar-container{
    width: 100%;
}

.rd-sidebar-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}


.rd-sidebar-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
    display: flex;
    flex-direction: row;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.sidebar-child-tab{
    display: flex;
    flex-direction: row;
    grid-gap: 1.5em;
    gap: 1.5em;
    flex-wrap: nowrap;
    background: #F4F9FD;
    cursor: pointer;
    border-radius: 0.75rem;
    margin: 1em;
    padding: 0.75em;
    box-shadow: 0px 4px 15px 0px rgba(170, 186, 198, 0.25);
}


.rd-main-sidebar-expand{
    background: #F4F9FD;
    border-radius: 1em;
    box-shadow: 0px 4px 15px 0px rgba(170, 186, 198, 0.25);
    height: 10.3em;
    transition-duration: 0.5s;
}

.rd-sd-down{
    transition-duration: 0.75s;
}

.rd-sd-up{
    transition-duration: 0.75s;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}



.rd-sidebar-label{
    color: #8998A2;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}

.rd-active-sidebar{
    border-bottom: 5px solid #4CCB1F;
}

.rd-active-sidebar-label{
    color: #4CCB1F;
}

.rd-router-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5em;
}

.rd-main-content{
    border-radius: 1em;
    background: #F4F9FD;
    padding: 3em;
    margin-top: 2rem;
}


.rd-delete-icon{
    width: 1.6em;
    height: 1.8em;
    cursor: pointer;
}

.rd-modal .ant-modal-content{
    background: #F4F9FD;
}

.rd-confirmation-dialog{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:1em;
    gap:1em;
}

.rd-confirmation-message{
    color: #000;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    width: 90%;
    text-align: center;
    font-family: Poppins;
}

.rd-confirmation-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.rd-align-center{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
}
.rd-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    grid-gap: 1em;
    gap: 1em;
}
.rd-text-input, .rd-text-input:hover, .rd-text-input:focus, .rd-text-input:focus-visible, .rd-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.rd-text-area, .rd-text-area:hover, .rd-text-areat:focus, .rd-text-area:focus-visible, .rd-text-area:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 12.25em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
    resize: none;
}
.rd-select-filed .ant-select-selector,.rd-select-filed .ant-select-selector:hover,.rd-select-filed .ant-select-selector:focus,.rd-select-filed .ant-select-selector:focus-visible,.rd-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.rd-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.rd-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.rd-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.rd-add-edit-competency-container .ant-select-dropdown{
    box-shadow: none !important;
}
.rd-add-edit-competency-container .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    /* border: 1px solid #ffffff !important; */
}
.rd-select-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
}
.rd-select-option.ant-select-item p{
    margin: 0px;
}
.rd-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.rd-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.rd-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.rd-multi-select .ant-select-selector .ant-select-selection-item{
    height: 2em !important;
    background: #ffff !important;
    color: #000;
    font-family: 'Poppins';
    font-size: 1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1em;
    padding: .375em .5em !important;
    text-align: center;
    /* border: none !important; */
    margin-top: .25em !important;
}
.rd-input-star{
    color: #E86161;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em; 
    padding-top: 10px !important;
}
.rd-input-title{
    color: #262626;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0;
}

/* table design */
.rd-table-container{
    margin-top: 2em;
    width: 100%;
    overflow: auto;
}

.rd-table{
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.rd-row-header{
    width: 100%;
    background: #31D0AA;
}

.rd-row-header th{
    padding: 1.25em 0px;
    background: #31D0AA;
}

.rd-table-row-padding-left{
    padding-left: 2em !important;
}

.rd-table-row-padding-rigth {
    padding-right: 2em !important;
}

.rd-content-center{
    text-align: center;
}

.rd-content-right{
    text-align: right;
}

.rd-row-body{
    background: #FCFCFC;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.rd-row-body td{
    padding: 1.25em 0px;
}

.td-center-padding{
    padding-left: 1.6rem !important;
}

.rd-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.rd-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.275em;
    margin: 0px 0.5em;
}

.rd-left-radious{
  border-top-left-radius: 0.5em !important;
  border-bottom-left-radius: 0.5em !important;
}

.rd-right-radious{
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.rd-table-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.rd-table-actions-right{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.rd-add-button{
    height:2.75em;
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height:  1.25em;
    color: #fff;
    border: 1px solid #4CCB1F;
    outline: none;
    white-space: nowrap;
    /* margin-top: 0.5em; */
}

.rd-dotted-button, .rd-dotted-button:focus , .rd-dotted-button:hover{
    height:2.75em;
    border-radius: 0.5em;
    background: #fff;
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height:  1.25em;
    color: #4CCB1F;
    border: 2px dashed #4CCB1F !important;
    outline: none;
    white-space: nowrap;
    /* margin-top: 0.5em; */
}

.rd-nodata{
    width: 100%;
    border-radius: 0.5em ;
    background-color: #fff;
    min-height: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}

.rd-nodata-label{
    font-size: 1.5em;
}

.rd-custom-form-table{
    width: 100%;
    min-width: 900px;
}

.rd-form-table-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
    padding: 1.25em 4.2375em 1.25em 4.2375em;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 1em 1em 0px 0px;
    background: #31D0AA;
}

.rd-form-table-body{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0.8em;
    border-radius: 0px 0px 1em 1em;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.rd-form-table-content{
    border-radius: 1em;
    background: #F4F9FD;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.4375em;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.competency-popup-title{
    color: #000;
    font-family: Poppins;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.rd-chiplist-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap:0.75em;
    gap:0.75em;
    width: 100%;
    margin-top: 0.75em;
}

.rd-chip{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    border-radius: 0.75rem;
    border: 1px solid #AABAC6;
    background: #FFF;
    height: 2.8125rem;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em;
}

.rd-chip-label{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chip-select .ant-select-selection-item{
    display: none;
    
}

.rd-modal-role .ant-modal-content{
    background: #FFFFFF;
}

.rd-pagination{
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.rd-pagination:hover{
    text-decoration: underline;
    color: #4CCB1F;
}

.mobile-sidebar-menu{
    cursor: pointer;
    width: 3em;
    height: 3em;
    display: none;
    margin-top: 1em;
}

.rd-th-label-select-table{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0px
}

.rd-table thead{
    background: #31D0AA;
}

.mobile-sidebar-overlay{
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: transparent;
    position: fixed;
    z-index: 1;
}

.role-no-permission-container{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expand-dis{
    height: 100%;
    overflow: auto;
}

@media screen and (max-width:1600px) {
    .rd-root{
        font-size: 14px;
    }

}

@media screen and (max-width:1200px) {
   
}

@media screen and (max-width:1000px) {
    .mobile-sidebar-menu{
        display: block;
    }
    .rd-root{
        font-size: 12px;
    }
    .show-rd-side-bar{
        max-width: 17.5em !important;
        min-width: 17.5em !important;
        transition-duration: 0.25s;
        background: #FFF;
        position: fixed;
        z-index: 1;
        padding-right: 0.5em;
        overflow: auto !important;
        top: 76px;
        bottom: 0px;
    }
    
    .hide-rd-side-bar{
        max-width: 4em !important;
        min-width: 4em !important;
        position: fixed;
        z-index: 1;
        transition-duration: 0.25s;
        overflow: auto !important;
        background: #FFF;
        top: 76px;
        bottom: 0px;
    }
    
  

    .rd-router-outlet{
        padding: 0px 1em 0em 1em;
    }

}

@media screen and (max-width:850px) {
    .rd-root{
        font-size: 10px;
    }

    .rd-main-content{
        padding: 1.5em;
    }

    .rd-th-label-select-table{
        font-size: 1.25em;
    }
}

@media screen and (max-width:450px) {
    
    .rd-main-content{
        padding: 1em;
    }

    .rd-root .page-main-title{
        font-size: 2em;
    }
}

.rd-menu-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0.75em 0.5em;
}

.rd-main-menu{
    background: #FFFFFF;
    box-shadow: 1px 3px 4.900000095367432px 4px #AABAC64D;
    border-radius: 0.625em;
    padding: 0.5em 0em;
    margin-top: 0.25em;
}

.tab-exp-arrow{
    width: 1em;
}
.rd-table-scroll{
    overflow: auto;
}

.rd-custom-table{
    width: 100%;
    min-width: 350px;
}

.rd-table-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.5em;
    background: #31D0AA;
}

.rd-th-full{
    width: 100%;
    overflow: hidden;
    min-width: 10em;
}

.rd-th-action{
    width: 10em;
    min-width: 10em;
    max-width: 10em;
    display: flex;
    justify-content: center;
}

.rd-th-label{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.35em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.35em;
    margin: 0px
}


.rd-table-body{
    display: flex;
    flex-direction: column;
    grid-gap: 0.8em;
    gap: 0.8em;
    padding-top: 1.7em;
}

.rd-table-content{
    border-radius: 0.5em;
    background: #FCFCFC;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.el-expansion-container{
    position: absolute;
    height: 100%;
    top: 0px;
    left: 1.6em;
    display: flex;
    align-items: center;
}

.rd-expansion-arrow{
    cursor: pointer;
    transition-duration: 0.5s;
}

.rd-open-expansion{
    transition-duration: 0.5s;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.rd-td-label{
    color:  #1F1F1F;
    font-size: 1.15em !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35em;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.rd-expanded-detail{
    height: 0px;
    padding: 0;
    border-radius: 0.75em;
    background: #FFF;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    overflow: hidden;
    transition-duration: 0.5s;
}

.rd-open-expansion-detail{
    height: 14.5em;
    padding: 1.6em;
    transition-duration: 0.5s;
}

.rd-expanded-label{
    color: #000;
    font-size: 1em !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: 'Poppins';
}
.rd-expanded-label-desc{
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75em;
    cursor: default;
  }
.rd-modal-max-width{
    max-width: 58rem;
}


/* File upload css*/

.rd-file-upload-drag{
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.rd-file-upload-label{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
}
.rd-uploaded-file-container{
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    padding: 1.5em;
    display: flex;
    flex-direction:column;
    grid-gap: 1em;
    gap: 1em;
    margin-top: 1em;
}
.rd-file-name{
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    margin: 0;
}
.rd-file-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
}
.rd-uploaded-file-container .ant-progress-inner{
    border-radius: 8px !important;
}
.rd-divider{
    margin: 2em 0em!important;
}
.rd-divider .ant-divider-inner-text{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    margin: 0em 1em !important;
}
.rd-divider.ant-divider-with-text::before{
    border-top:1px solid #8C8CA1 !important;
}
.rd-divider.ant-divider-with-text::after{
    border-top:1px solid #8C8CA1 !important;
}

.rd-template-container{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
}

.sample-xlsx-container{
  position: absolute;
  left: 0.75em;
  top: 0.25em;
  height: 2.5em;
}

.template-label{
  color: #000;
  font-family: Poppins;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
  text-align: center;
  margin-top: 0.65em;
}

.sample-xlsx-div{
  display: flex;
  flex-direction: row;
  grid-gap: 0.75em;
  gap: 0.75em;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rd-url-upload-container{
  position: absolute;
  right: 0.25em;
  top: 0.25em;
  border-radius: 0.5rem;
  background: #F4F9FD;
  width: 6em;
  height: 2.5em;
  overflow: hidden;
  cursor: pointer;
}

.rd-url-upload-label{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rd-url-upload-btn{
    color: #0098F0;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0px;
   
}
.rd-rogress-count{
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    position: absolute;
    top: -25px;
    right: 10px;
}
.rd-file-size{
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin: 0;
}
.rd-search-input-filter, .rd-search-input-filter:hover, .rd-search-input-filter:focus {
    border-radius: 0.5em 0em 0em 0.5rem;
    border: 1px solid #A9A9A9;
    background: #FFF;    
    padding-left: 35px;
    width: 16em;
    height: 3em !important;
    font-size: 1.1em;
    /* border-right: none; */
    font-family: 'Poppins';
    font-weight: 500;
  }
  .rd-input-filter-new {
    border: 1px solid #A9A9A9;
    background: #FFF;   
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    height: 3em !important;
    font-size: 1em;
    border-right: none;
    font-family: 'Poppins';
    font-weight: 500;
    height:46px !important;
  }
  
  .rd-input-filter-new:hover {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  
  .rd-input-filter-new:focus {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  .rd-input-filter {
    border: 1px solid #A9A9A9;
    background: #FFF;   
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;
    /* height: 3.3em !important; */
    height:46px !important;
  }
  .rd-input-filter:hover {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000"
  }
  
  .rd-input-filter:focus {
    border: 1px solid #A9A9A9 !important;
    background: #FFF;  
    color: "#000000";
  }
  .rd-search-menu-title{
    color: #4CCB1F;
    font-family: "Poppins";
    font-size: 1.1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rd-dropdown-btn{
    width: 250px;
    min-width:100px;
    display:flex;
    height:46px !important;
    justify-content:space-between;
    align-items:center;
    grid-gap:5px;
    gap:5px;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 0em .5em !important;
    border-right:1px solid #A9A9A9;
  }
  .rd-add-plus-btn, .rd-add-plus-btn:hover, .rd-add-plus-btn:focus{
    border-radius: 1rem;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    width: 3em;
    height: 1.4em !important;
    margin-top: .5em;
    cursor: pointer;
  }
  .rd-selected-main-competecny-title-container{
    border-radius: 0.5em;
    background: #FFF;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    padding:1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: .5em;
    gap: .5em;
  }
  .rd-create-main-competency-container{
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em 2em;
    position: relative;
}
  .cp-modal-close-icon{
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
}
.cp-modal-max-width{
  max-width: 58em;
  padding: 0em 2em;
}

.rd-add-new-popover .ant-popover-inner{
  background:rgba(170, 186, 198, 1) !important;
  border-radius:.875em !important;
  margin-bottom:-4px !important;
  margin-right:-10px !important;
}
.rd-add-new-popover .ant-popover-inner .ant-popover-inner-content{
  color: #fff !important;
  font-size: 1em;
  font-weight: 500 !important;
  font-family:"Poppins";
}
.rd-add-new-popover .ant-popover-content > .ant-popover-arrow{
  border-right-color:rgba(170, 186, 198, 1);
  border-bottom-color: rgba(170, 186, 198, 1);
  margin-bottom:-5px !important;
  margin-right:-8px !important;
}
.rd_competency_drop_render{
  background:  #ECF1F4;
  width: 98%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 6em;
  overflow: auto;
}
.rd-selected-option{
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 500;
  color: #fff;
  background:#4CCB1F;
  line-height: 1.375em;
  padding: .5em;
}
.rd-competency-option{
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 500;
  color: #000;
  line-height: 1.375em;
  padding: .5em;
}
.rd-competency-option:hover{
  color: #fff;
  background:#4CCB1F;
}

.rd-expanded-detail-comp{
  height: 0px;
  padding: 0;
  border-radius: 0.75em;
  background: #F4F9FD;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  overflow: hidden;
  transition-duration: 0.5s;
}

.rd-open-expansion-detail-competency{
  height: 14.5em;
  padding: 1.2em;
  transition-duration: 0.5s;
  margin: .5em 1em;
}
.rd-expanded-label-title-comp{
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375em;
}
.rd-expanded-label-comp{
  color: #000;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75em;
  cursor: default;
}
.rd-expand-date-lable{
  color:#262626;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375em;
  cursor: default;
}
.rd-selected-competency-table-value-container{
  border-radius: 0.5rem;
  background: #F4F9FD;
  box-shadow: 0px 4px 0px -3px rgba(170, 186, 198, 0.50);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  height: 2.8125em;
  width: 100%;
  padding: .5em 1em;
}
.rd-selected-competency-table-value{
  color: #1F1F1F;
  text-align: center;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.175em;
}

.rd-table-select-filed .ant-select-selector,.rd-table-select-filed .ant-select-selector:hover,.rd-table-select-filed .ant-select-selector:focus,.rd-table-select-filed .ant-select-selector:focus-visible,.rd-table-select-filed .ant-select-selector:focus-within{
  background: #F4F9FD !important;
  height:2.8125em !important;
  border-radius: 0.5em !important;
  width:100% !important;
  font-family:'Poppins';
  border: none !important;
  box-shadow: 0px 4px 0px -3px rgba(170, 186, 198, 0.50);
  font-weight: 400;
  font-size: 1em;
}
.rd-table-select-filed .ant-select-selector .ant-select-selection-search-input{
  height:2.8125em !important;
  font-size: 1em;
  font-family:'Poppins';
  font-weight: 400;
}
.rd-tabel-type-select .ant-select-selector .ant-select-selection-item{
  padding-top: .275em !important;
  font-weight: 500;
  font-size: 1em;
}
.rd-tabel-type-select .ant-select-selector .ant-select-selection-placeholder{
  padding-top: .275em !important;
  font-weight: 500;
  font-size: 1em;
}

.rd-text-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0em;
  width: 14em;

}

.rd-text-ellipsis:before {
  content: '';
  display: block;
}

@media (max-width:693px) {
  .rd-coll-competency{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    grid-gap: 1em !important;
    gap: 1em !important;
  }
  .rd-dotted-button{
    margin-right: 0em !important;
  }
}
@media (max-width:480px) {
  .rd-dropdown-btn{
    min-width: 80px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .rd-search-input-filter{
    width: 12em ;
  }
}
@media (max-width:400px) {
  .rd-coll-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
  }
  .rd-add-button{
    margin-right: 0em !important;
  }
}
.role-row-alignment{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    flex-direction: nowrap;
    width: 100%;
}

.role-dropsection-alignment{
    display: flex;
    flex-direction: row;
    grid-gap: 0.25em;
    gap: 0.25em;
    flex-wrap: nowrap;
    width: 100%;
}

.role-plus-btn, .role-plus-btn:hover, .role-plus-btn:focus{
    border-radius: 0.85rem;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    height: 3em;
    margin-top: 0.75em;
    width: 4em;
    cursor: pointer;
    padding: 0px;
}

.must-have-section{
    width: 100%;
    height: 3em;
    background: #FFF;
    border-radius: 1em;
    padding: 0.5em 1.5em 0.5em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.must-have-label{
    margin: 0xp !important;
    padding: 0px !important;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    margin-top: 0.5em;
}

.must-have-parent{
    width: 1.625rem;
    height: 1.625rem;   
    max-width: 1.625rem;
    max-height: 1.625rem;   
    border: 1px solid #8C8CA1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.must-have-child{
    width: 50%;
    height: 50%;
    border-radius: 50%;
}

.competency-blue{
    border: 1px solid #0098F0;
}

.competency-child-blue{
    background:#0098F0 ;
}

.competency-green{
    border: 1px solid #4CCB1F;
}

.competency-child-green{
    background:#4CCB1F ;
}

.role-comp-table-must-have{
    padding: 0.25em;
    height: 2.75em;
    background: #FFFFFF;
    border-radius: 1.25em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 20em;
    min-width: 20em;
}

.table-must-have-container{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.role-comp-table-label{
    color: #AABAC6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0px;
    padding: 0px;
}

.role-comp-table-setion{
    width: 50%;
    border-radius: 1.25em;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-eligibility-btn, .add-eligibility-btn:hover, .add-eligibility-btn:focus{
    border-radius: 1rem;
    border: 1px solid  #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    width: 12em;
    height:2.8em;
    max-width: 90%;
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    cursor: pointer;
    margin-top: 1.2em;
}

.eligible-year{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    white-space: nowrap;
    margin-top: 2.3em;
}
.rd-role-filter-container{
    width: 100%;
    max-width:50%;
    padding-right:.5em;
    margin-top: 1em;
}

.slider-label-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}

.slider-container{
    display: flex;
    flex-direction: row;
    grid-gap: 1.25em;
    gap: 1.25em;
    flex-direction: nowrap;
    width: 100% !important;
    align-items: center;
}

.role-eligibility-table{
    border-radius: 1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 1.12em 0em;
}

.eligibility-table-main-header{
    border-radius: 1rem 1rem 0rem 0rem;
    height: 4.06rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1.5em 0.5em 2.69em;
}

.eligibility-table-main-header h4{
    margin: 0px;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.eligibility-tr{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0em 1.5em 0em 2.69em;
    min-width: 620px;
}

.eligibility-th{
    width: 33.33%;
    min-width: 33.33%;
    text-align: center;
    height: 4.06rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eligibility-td{
    width: 33.33%;
    min-width: 33.33%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 1.88em;
    gap: 1.88em;
    padding: 1.88em 0.5em;
}

.eligibility-th h4{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.eligibility-td h4{
    color: #262626;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.eligible-tr-border{
    border-radius: 0em 0em 1em 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    border-top:none ;
}

.eligibility-table-body{
    overflow: scroll;
}

.eligible-table-action{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
}

.eligible-table-action img{
    width: 1.25em;
    height: 1.25em;
    cursor: pointer;
}

.rd-eligibility-slider .ant-slider-handle{
    background-color: #4CCB1F !important;
    border: 2px solid #4CCB1F !important;
    margin-top: -4px !important;
}
.rd-eligibility-slider .ant-slider-rail{
    border-radius: 1em !important;
    height: .5em !important;
    background: rgba(170, 186, 198, 0.50) !important;
}
.rd-eligibility-slider .ant-slider-track{
    height: .5em !important;
    background-color: #4CCB1F !important;
}
.rd-search-drop-placeholder{
    color: #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}
.rd-advace-filter-title{
    color: rgba(0, 152, 240, 1);
    text-decoration: underline;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
    margin-top: .35em;
}
@media (max-width:850px) {

    .add-eligibility-btn, .add-eligibility-btn:hover, .add-eligibility-btn:focus{
        width: 13em;
        height:2.8em;
        font-size: 1.25em;
    }
    
}

@media (max-width:700px) {
    .rd-role-filter-container{
        width: 100%;
        max-width:50%;
        padding-right:.5em;
    }
    .role-row-alignment{
        flex-direction: column;
    }
}

@media (max-width:480px) {
    .rd-role-filter-container{
        width: 100%;
        max-width:100%;
        padding-right:0em;
    }
}

@media (max-width:400px) {
    .role-dropsection-alignment{
        flex-direction: column;
        grid-gap: 0em;
        gap: 0em;
    }

    .role-plus-btn{
        margin-top: -10px;
        width: 3em;
        padding: 0px;
    }
}

.rating-scale-parent {
  font-size: 16px;
}


.ratingScale-table .ant-table-thead {
  background: #31D0AA;
  border-radius: 0.5em;
}

.ratingScale-table .ant-table-thead .ant-table-cell {
  background: transparent !important;
  border: 1px solid #31D0AA;
  padding: 1.25em 3.4375em 1.25em 1.69em !important;

}

.ratingScale-table .ant-table-thead .ant-table-cell:first-child {

  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.ratingScale-table .ant-table-thead .ant-table-cell:last-child {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 1.25em 3.4375em 1.25em 5.69em !important;
  border-left: none;
}

.ratingScale-table .ant-table-thead .ant-table-cell:not(:first-child):not(:last-child) {
  padding: 1.25em 1.94em 1.25em 3.6875em !important;

}


.ratingScale-table .ant-table {
  background: #F4F9FD;
}


.ant-table table {
  border-spacing: 0em 1em;
}



.ratingScale-table th h4 {

  font-weight: 600;
  text-align: left;
  line-height: 1.37em;
  padding-left: 1.87em;
  padding-right: 1.25em;
  font-family: 'Poppins'
}




.ratingScale-table .ant-table-tbody>tr>td {
  padding: 0.8em 6em 0.8em 3.6875em !important;
}


.ratingScale-table th:first-child {
  border-top-left-radius: 0.68em !important;
  border-bottom-left-radius: 0.68em !important;
}

.ratingScale-table th:last-child {
  text-align: right;
  border-bottom-right-radius: 0.68em !important;
  border-top-right-radius:0.68em !important;
}


.ratingScale-table td {

  border-spacing: 2em;
  text-align: center;

}

.ratingScale-table td div {

  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.25em;
  color: #000000;
  font-family: 'Poppins'

}


.ratingScale-table tbody tr {
  background: #fff;
}

.ratingScale-table td:first-child {

  border-top-left-radius: 0.68em !important;
  border-bottom-left-radius: 0.68em !important;
}


.ratingScale-table td:last-child {
  text-align: center;

  border-bottom-right-radius: 0.68em !important;
  border-top-right-radius: 0.68em !important;
}


.create-rating-scale-row {
  position: relative;
  right: 1.56em;
}


.rating-scale-parent .ant-input {
  padding: 0.75em 0.75em 0.75em 1.56em;
  border-radius: 0.5em;
  border-color: #AABAC6;
}

.ratingScale-table .ant-table-tbody > tr > td{
  border-bottom: none;
}



.row-dragging-threshold {
  background: #fff !important;
  display: flex;
  position: relative;
  left: 5.12em;
  border-radius: 0.5em;
  justify-content: space-between;
  align-items: center !important;
}

.row-dragging-threshold .threshold-record{
  padding: 0.7em 0em 0.7em 0.7em;
}

.row-dragging-threshold .ant-table-tbody>tr>td{
  padding:  0.8em 6em 0.8em 3.6875em !important;
  
 
}


.row-dragging-threshold td div {
  font-weight: 400;
  padding-right: 3em;
  font-size: 1.1em;
  line-height: 1.25em;
  color: #000000;
  font-family: 'Poppins';
}


.threshold-button-panel {

  position: relative;
  left: 3.8em;
  grid-column-gap: 0.93em;
  -webkit-column-gap: 0.93em;
          column-gap: 0.93em;
  justify-content: space-evenly;

}

.delete-rating-scale-icon {
  width: 1.6em;
  left: 1.5em;
  position: relative;
}


.delete-rating-scale-icon,
.threshold-button-panel {
  position: relative;
  z-index: 1000;
}

.create-rating-scale-row .ant-input:-ms-input-placeholder {
  font-size: 1em;
  color: #AABAC6;
}

.create-rating-scale-row .ant-input:placeholder-shown {
  font-size: 1em;
  color: #AABAC6;
}

.delete-button-threshold-record {
  position: relative;
  left: 3em;
  flex-wrap: nowrap;
  grid-column-gap: 3.3em;
  -webkit-column-gap: 3.3em;
          column-gap: 3.3em;
  justify-content: center;
}

.threshold-icon {
  width: 1.8em;
}

.threshold-record {
  padding: 0.7em 0em;
  text-align: left;
}

.create-rating-scale-row .ant-input:focus,
.create-rating-scale-row .ant-input-focused,
.create-rating-scale-row .ant-input:hover {
  border: 1px solid #AABAC6 !important;
}

.rd-custom-table .ant-empty-normal {
  text-align: center !important;
}

.threshold-form {
  display: none;
}


.invalid-threshold-input{
  text-align: left;
  width: 100%;
  font-family: poppins;
  position: relative;
  bottom:0.43em;
  font-size:0.9em;
  color: red;
}

.drag-visible{
  position: relative;
  left: 1.9em;
}

.drag-visible-create-row{
  position: relative;
  left: 0.5em;
}

.ca-root {
    font-size: 16px;
    font-family: "Poppins";
}

.ca-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
    padding: 0em 6.5em;
}

.ca-main-title {
    text-align: center;
    font-family: "Poppins";
    font-size: 2.2em !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.ca-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-gap: 2em;
    gap: 2em;
    margin-top: 2em;
}

.ca-add-button {
    height: 2.8em;
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff;
    border: 1px solid #4CCB1F;
    outline: none;
    white-space: nowrap;
}

.ca-search-menu-title {
    color: #4CCB1F;
    font-family: "Poppins";
    font-size: 1.1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ca-dropdown-btn {
    min-width: 100px;
    display: flex;
    height: 3.33em !important;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 0em .5em !important;
}

.ca-employee-card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.5em;
    padding: 0em 6.5em;
}

.ca-employee-card-seaction {
    width: 33.33%;
    padding: .75em;
}

.ca-employee-card {
    border-radius: 1em;
    box-shadow: 0px 10px 15px 0px #aabac633;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5em;
}

.cs-user-profile {
    background: #AABAC6;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs-user-name {
    color: #262626;
    text-align: left;
    font-family: "Poppins";
    font-size: 1.15em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.175em;
    margin: 0;
}

.cs-label {
    color: #262626;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.24em;
    margin: 0;
}

.cs-employee-progress-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    width: 100%;
    padding:1em 1.5em;
    border-top: 2px solid rgba(217, 217, 217, 1);
}
.cs-employe-progress-view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.5em;
    border-radius: 1em;
    width: 100%;
    
}
.cs-employee-progress-status{
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0;
    max-width: 5em;
}
.ca_remaining_day{
    color: #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    border-radius: 0.25em 0em 0em 0.25em;
    padding: .3em .5em;
    white-space: nowrap;
    margin-top: .2em;
    display: none;
}
.ca-edit-icon-container:hover .ca_remaining_day {
    display: block;
    
}
.cs-see-detail-btn{
    border-radius: 1.875em;
    background:#4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    height: 2.5em;
    padding: 0.25em 0.9375em;
    justify-content: center;
    align-items: center;
    color:  #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    cursor: pointer;
    width: 8em;
    border: none;
    outline: none;
}
.ca-custom-menu-date-container{
    background: #ECF1F4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: .5em;
    gap: .5em;
}
.ca-date-seletion-title{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: .5em;
}
.ca-date-seleted-title{
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #4CCB1F;
    padding: .5em;
    cursor: pointer;
}
.ca-date-see-more{
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}
.ca-dropdown-btn{
    border-radius: 0.75em;
    background: #FFF;
    display: flex;
    max-width: 10em;
    height: 2.4375em;
    padding: 0.625em 1.125em;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1.3125em;
    gap: 1.3125em;
    border: none;
}
.ca-chart-container{
    border-radius: 1.0625em;
    background: #F4F9FD;
    /* padding: 1em; */
    display: flex;
    width: 100%;
    flex-direction: column;
}
.ca-chart-filter-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.87em 2.19em;
}
.ca-view-competecny-title{
    color: #0098F0;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}
.ca-modal .ant-modal-content{
    background: #F4F9FD;
}
.ca-section-modal-heading{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.75em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.ca-select-filed .ant-select-selector,.ca-select-filed .ant-select-selector:hover,.ca-select-filed .ant-select-selector:focus,.ca-select-filed .ant-select-selector:focus-visible,.ca-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    /* border: 1px solid #AABAC6 !important; */
    border: none !important;
    font-weight: 400;
    font-size: 1em;
}
.ca-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.ca-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.ca-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.ca-select-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
}
.ca-select-option.ant-select-item p{
    margin: 0px;
}
.ca-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.ca-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.ca-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.ca-cancel-btn, .ca-cancel-btn:hover, .ca-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #8C8CA1;
    color: #8C8CA1;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins";
}
.ca-save-btn, .ca-save-btn:hover, .ca-save-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #fff;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins";
}
.ca-no-data{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 5em;
}
.ca-no-data-text{
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    text-align: center;
    color: #000;
    line-height: normal;
}
.ca-profile-icon{
    font-family: 'Poppins';
    font-size: 2.2em;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: normal;
    margin: 0;
}
.ca-next-page-icon, .ca-next-page-icon:hover, .ca-next-page-icon:focus{
    width: 9em;
    height: 3em;
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.3em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em; 
    border: 1px solid #4CCB1F;
    background-color: #FFF;
    border-radius: .5em;
}
.ca-page-count{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.ca-page-count span{
    border-radius: 0.5rem;
    border: 1px solid #8C8CA1;
    height: 2em;
    padding: 0em 1.5em;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ca-scroll-container{
    width: 100%;
    overflow: auto;
}
@media screen and (max-width:1600px) {
    .ca-root {
        font-size: 14px;
    }
}

@media screen and (max-width:1000px) {
    .ca-root {
        font-size: 12px;
    }
    .ca-employee-card-seaction {
        width: 50%;
        padding: .5em;
    }
    
}

@media screen and (max-width:850px) {
    .ca-root {
        font-size: 10px;
    }
    .ca-heading-container{
        padding: 0em 4.5em;
    }
    .ca-employee-card-container {
        padding: 0em 4.5em;
    }
}
@media (max-width:600px) {
    .ca-employee-card-seaction {
        width: 100%;
        padding: .5em;
    }
    .ca-heading-container{
        padding: 0em 2.5em;
    }
    .ca-employee-card-container {
        padding: 0em 2.5em;
    }
}
@media (max-width:480px) {
    .ca-dropdown-btn {
        min-width: 80px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ca-search-input-filter {
        width: 12em;
    }
    .ca-heading-container{
        padding: 0em 1em;
    }
    .ca-employee-card-container {
        padding: 0em 1em;
    }
}
.ca-root{
    font-size: 16px;
    font-family: Poppins;
}

.ca-root-padding{
    padding: 0px 5em;
}

.ca-container-bg{
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #ECF1F4;
    background: #F4F9FD;
}

.selection-container-padding{
    padding: 3.5em 8em 3.5em 3.8em;
}

.section-container-padding{
    padding: 1.75em 3.8em; 
}

.ca-label{
    color: #262626;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: 2.875em;
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
}

.ca-role-detail{
    color: #000;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.ca-role-detail span{
    color: #0098F0 !important;
}

.section-title{
    color: #000;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    line-height: 0.75;
}

.ca-text-input, .ca-text-input:hover, .ca-text-input:focus, .ca-text-input:focus-visible, .ca-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}

.ca-gradient{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    padding: 0px 0.75em !important;
    margin: 0px !important;
}

.ca-must-have-grad{
    background: linear-gradient(90deg, rgba(0, 152, 240, 0.50) 0.07%, rgba(217, 217, 217, 0.00) 104.03%);
}

.ca-good-have-grad{
    background: linear-gradient(90deg, rgba(76, 203, 31, 0.50) 0.07%, rgba(217, 217, 217, 0.00) 104.03%);
}

.competency-list-container{
    width: 100%;
    min-height: 15.125em;
    border-radius: 1em;
    background: #FFF;
    padding: 2.6em 1.9em;
    margin: 1.19em 0px;
}

.sub-competency-list-container{
    width: 100%;
    min-height: 15.125em;
    border-radius: 1em;
    background: #F4F9FD;
    padding: 2.8em 1.9em;
    margin: 1.19em 0px 1.19em 1em;
}
.ca-competency-title{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ca-sample-detail{
    color: #0098F0;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.025em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
    white-space: nowrap;
}

.category-expand-icon{
    margin-right: 1.25em;
    width: 0.6875em;
    height: 1.125em;
    cursor: pointer;
    transition-duration: 0.5s;
}

.category-active-expand-icon{
    transition-duration: 0.5s;
    margin-right: 1.25em;
    width: 0.6875em;
    height: 1.125em;
    cursor: pointer;
    rotate: 90deg; 
}

.ca-threshold-container{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    margin-top: 1.63em;
    padding: 0em 1em;
}

.ca-threshold-card{
    min-width: 20.875em;
    height: 4.5em;
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    overflow: hidden;
    padding: 1.25em;
    transition-duration: 0.5s;
    cursor: pointer;
}

.ca-threshold-card-expanded{
    height: 27em;
    transition-duration: 0.5s;
}

.ca-threshold-card-tile{
    /* height: 5em; */
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    grid-gap: 0.5em;
    gap: 0.5em;
    width: 100%;
}

.ca-threshold-label{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    line-height: normal;
    margin: 0px !important;
}

.ca-radio-outer{
    width: 2em;
    height: 2em;
    min-width: 2em;
    min-height: 2em;
    border-radius: 50%;
    border: 3px solid #8C8CA1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ca-radio-inner{
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: transparent;
}

.ca-behaviour-sample-container{
    margin-top: 1.13em;
    height: 21em;
    overflow: scroll;
}

.ca-action-container{
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.other-competency-btn, .other-competency-btn:hover, .other-competency-btn:focus{
    width: 18.1875em;
    height: 3.4375em;
    max-width: 95%;
    border-radius: 1em;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin-top: 2.62em;
}

.ca-sub-label{
    margin: 1.25em 0.85em 1.25em 0.85em;
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ca-input-max{
    max-width: 34.5em;
}

.ca-action-gap{
    grid-gap: 2.69em;
    gap: 2.69em;
}

.ca-page-title-margin{
    margin-top: 4em;
}

@media screen and (max-width:1600px) {
    .ca-root{
        font-size: 14px;
    }
}

@media screen and (max-width:1000px) {
    .ca-root{
        font-size: 12px;
    }
    .col-1000-justify-space-between-center{
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .ca-input-max{
        max-width: 100% !important;
    }
    .ca-action-gap{
        grid-gap: 0.5em;
        gap: 0.5em;
    }

    .ca-root-padding{
        padding: 0px 2em;
    }
}

@media screen and (max-width:850px) {
    .ca-root{
        font-size: 10px;
    }
}

@media screen and (max-width:600px) {
    .ca-root-padding{
        padding: 0px 0em;
    }
}

.ca-small-card-seaction {
    border-radius: 1.0625em;
    background: #F4F9FD;
    box-shadow: 0px 4px 45px -24px rgba(0, 0, 0, 0.07);
    padding: 1em 2em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ca-small-detail-role {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ca-small-detail-role-title {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ca-detail-data-container {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    padding: 0em 15em;
    margin-top: 2em;
}

.ca-table-rating-btn {
    border-radius: 0.75em;
    height: 2.2em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 5em;
    text-align: center;
    color: #262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    padding: 0em .5em;
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* table design */
.ca-table-container {
    margin-top: 2em;
    width: 100%;
    overflow: auto;
}

.ca-table {
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.ca-row-header {
    width: 100%;
    background: linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
}

.ca-row-header th {
    padding: 1.25em 0px;
    /* background: linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%); */
}

.ca-table-row-padding-left {
    padding-left: 2em !important;
}

.ca-table-row-padding-rigth {
    padding-right: 2em !important;
}

.ca-content-center {
    text-align: center;
}

.ca-content-right {
    text-align: right;
}

.ca-row-body {
    background: #FCFCFC;
    border: 1px solid black !important;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.ca-row-body td {
    padding: 1.25em 0px;
}

.ca-center-padding {
    padding-left: 1.6rem !important;
}

.ca-table-header-label {
    color: #262626;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.ca-table-body-label {
    color: #000;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.275em;
    margin: 0px 0.5em;
}

.ca-left-radious {
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em !important;
}

.ca-right-radious {
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.ca-table-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.ca-table-actions-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.ca-custom-menu-filter {
    border-radius: 1em;
    background: #FFF;
    margin-top: 0.31em;
    padding: 1.5em;
}

.ca-graph-advance-filter {
    max-height: 60vh !important;
    overflow: auto !important;
    width: 18em !important;
    max-width: 90vw !important;
    display: flex;
    flex-direction: column;
    grid-gap: 1.8em;
    gap: 1.8em;
}

.ca-graph-advance-filter-button,
.ca-graph-advance-filter-button:hover,
.ca-graph-advance-filter-button:focus {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5em;
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    border-radius: 0.5em;
    background: #F4F9FD;
    border: none !important;
    outline: none !important;
    padding: 1em !important;
}

.graph-menu-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.38em;
    max-height: 10em;
    overflow: auto;
}

.graph-menu-child div {
    width: 100%;
    height: 2.5em;
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    background: #F4F9FD;
    padding: 1em !important;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active-child-selection {
    background: #4CCB1F !important;
    color: #FFF !important;
}

.selected-filter-data {
    display: flex;
    flex-direction: column;
    grid-gap: 0.31em;
    gap: 0.31em;
    width: 100%;
    margin-top: 1em;
}

.selected-filter-data div {
    width: 100%;
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
    background: #F4F9FD;
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ca-competency-filter-dropdown {
    border-radius: 0.75rem !important;
    background: #FFF;
    width: 16.0625em !important;
    max-width: 100% !important;
}

.ca-drop-label {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.00875em;
    margin: 0px;
}

/* *********** */
.ca-overlay-container {
    width: 100%;
}

.cadefine-bars-overlay-container .ant-dropdown-menu-submenu {
    background: #ECF1F4;

}

.ca-overlay-container .ant-dropdown-menu-submenu-title {
    height: 3.5em;
    color: #000;
    font-family: Poppins;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
}

/* .define-bars-overlay-container .ant-dropdown-menu-item:hover, */
.ca-overlay-container .ant-dropdown-menu-submenu-title:hover {
    background: #ECF1F4;
}

.ca-overlay-container {
    background-color: #ECF1F4 !important;
    padding: 0px;
}


.ca-overlay-container .ant-dropdown-menu-item:hover {
    border: 0.062 solid #4CCB1F;
}

.ca-overlay-container .ant-dropdown-menu-item,
.ca-overlay-container .ant-dropdown-menu-submenu-title {
    padding: 0.90em 0.75em;
}

.ca-overlay-container .ant-dropdown-menu-submenu-title:hover {
    border: 1px solid #4CCB1F;
}



.ca-submenu {
    width: 16.0625em !important;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: poppins;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ca-submenu:hover {
    background: #ECF1F4;
}

.ant-dropdown-menu-submenu-popup {
    max-height: 40vh;
    overflow-y: auto;
}

.ca-selected-row div {
    background: #4CCB1F !important;
    color: #FFF;
}

.ca-selected-row .ant-dropdown-menu-submenu-title {
    color: #FFF;
}

.ca-overlay-container .ant-dropdown-menu-item-only-child {
    width: 40%;
}

.ca-submenu {
    width: 32em;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: 'poppins';
    text-overflow: ellipsis;
    overflow: hidden;
}

.ca-submenu:hover {
    background: #ECF1F4;
}

.apply-btn-ca-filter,
.apply-btn-ca-filter:hover,
.apply-btn-ca-filter:focus {
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    width: 5.8125em;
    height: 2.5em;
    color: #FFF;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    /* 137.5% */

}
.ca-adv-filter-title-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.ca-adv-filter-title-container h4{
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.line-chart-legend{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1.5px solid #AABAC6;
    padding: 1em 4em;
}
.legend-container{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0125em;
    padding: 0.35em 0.75em;
    border-radius: 1.34375em;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 42px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    margin: 1em;
    cursor: pointer;
}

.shape {
    width: 20px;
    height: 20px;
    /* border: 1px solid black; */
    display: inline-block;
    margin: 5px;
  }

  .circle {
    border-radius: 50%;
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;

  }
/* ******* */
@media (max-width:1000px) {
    .ca-detail-data-container {
        padding: 0em 10em;
    }
}

@media (max-width:760px) {
    .ca-detail-data-container {
        padding: 0em 6em;
    }
}

@media (max-width:480px) {
    .ca-detail-data-container {
        padding: 0em 3em;
    }
}
.pn_pagination .ant-pagination-item {
    display: none;

}
.pn_pagination{
    display: flex;
    align-items: center;
}

.custom-image-pagination:hover{
    -webkit-filter: none !important;
            filter: none !important;
}

.pagination_parent .ant-pagination-item-active {
background: #4CCB1F !important;
border: unset !important;
}

.pagination_parent .ant-pagination-item-active a {
    color: black;
    margin-top: 1px !important;
}

.pagination_parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select_parent .ant-select-arrow > span {
    color: black !important;

}
.select_parent .ant-btn:hover,.select_parent .ant-btn:focus {
    color: white !important;
    
}

/* pagination for prev and next buttons */

.pn_pagination {
    background-color: #4CCB1F !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4em 2em;
    font-family:'Poppins', sans-serif;
    font-weight: 600;
    color: white;
    border-radius: 9px;
}
.pn_pagination:hover{
    color: white !important;
}


/* results per page section */

.select_parent .ant-select-selection-item {
    margin-right: 5px;
    
}

.select_parent .ant-select-selection-search{
    margin: 3px;
}



.scape-report-container{
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    flex-wrap: nowrap;
    width: 100%;
    height: 700px;
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
}

.scape-report-svg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    /* background-color: green; */
}

.scape-active-btn, .scape-active-btn:hover, .scape-active-btn:focus, .scape-active-btn:active{
    width: 3.6em;
    height: 3.6em;
    cursor: pointer;
    border-radius: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.scape-inactive-btn, .scape-inactive-btn:hover, .scape-inactive-btn:focus, .scape-inactive-btn:active{
    width: 3.6em;
    height: 3.6em;
    border-radius: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    background-color: #ECF1F4;
    border: 1px solid #ECF1F4;
}

.scape-active-btn img, .scape-inactive-btn img{
    width: 85%;
    height: 85%;
    object-fit: contain;
}
.scape-report-table-container {
    font-size: 16px;
    margin-top: 1em;
}

.scape-report-data-container {
    max-width: 90%;
    margin: auto;
    
}

.scape-report-data-container-overview{
    width: 90%;
    margin: auto;
    
}


.scape-report-table-radius {
    border: 0.08em solid #AABAC6;
}

.table-response-row {
    width: 8.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-cell-scape-report {
    width: 8.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.table-cell-scape-report-overview{
    width: 4.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.scape-report-table-padding {
    padding: 0.5em;
}


.scape-report-group:first-child {
    border-bottom: none;
}

.scape-report-group0,.scape-report-personality-group0 {
    padding-top: 2em;
    font-weight: 600;
}

.scape-report-group0{
    height: 80px;
}

.scape-report-personality-group0 {
    height: 79px;
}

.scape-label-font {
    font-size: 0.9em;
    font-weight: 500;
}

.scape-report-parameter {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.scape-report-group {
    font-weight: 500;
}

.scape-report-group-row {
    font-weight: 500;
}

.scape-table-count,
.scape-table-total {
    font-weight: 500;
}

.scape-table-count {
    border: 0.0825em solid #A5E58F;
    background: #DBF5D2;
    position: relative;
    z-index: 1000;
}


.scape-table-total {
    border: 0.0825em solid rgba(76, 203, 31, 0.00);
    background: #A5E58F;
}

.scape-report-personality-text5{
visibility: hidden;
}

.scape-overview-user{
    width: 12.23em;
    min-width: 12.23em;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.personality-scape-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.scape-overview-name{
    padding: 0.5em 3.9em;
    position: relative;
    top: 0.3em;
    left: 0em;
    text-align: center;
    font-size: 1em;
    border-bottom: none;
    font-weight: 600;
}

.table-border-top-none{
    border-top: none;
}

.table-border-left-none{
    border-left: none;
}

.table-border-bottom-none{
    border-bottom: none;
}

.response-survey-0,.scape-report-personality-group0,.parameter-column-0{
    border-left: 0.08em solid #AABAC6;
}

.table-response-row:last-child{
    border-bottom: none;
    position: relative;
    z-index: 1000;
}

.scape-report-group0{
    border-left: 0.08em solid #AABAC6;
}

.parameter-counting-0{
    width: 11.07em !important;
    border-left: 0.08em solid #A5E58F;
    border-right: 0.08em solid #A5E58F;
    position: relative;
    z-index: 1005;
}

.scape-report-group{
    height: auto;
}

.reponse-personality-0{
    border-left: 0.08em solid #AABAC6;
}

.survey-response{
    max-height: 20em;
    overflow: auto;
}

.table-total-count-stick-bottom{
    position: fixed;
    bottom: 2.4em;
}

.table-count-stick-bottom{
    position: fixed;
    bottom: 0em;
}


.scrollable-column{
    max-width: 80%;
    overflow: auto;
}

.scape-report-personality-group:last-child{
    border-right: 0.0825em solid rgba(76, 203, 31, 0.00) !important;
}

.back-icon-scape{
    /* position: absolute; */
    top: 10px !important;
    left: 20px;
    cursor: pointer;
}


.scape-report-parameter00{
  display: none;
}


.scape-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #141414;
    margin: 0;
}
.scape-dropdown{
    width: 402px !important;
    height: 50px;
    border: 1.24587px solid #BFBFBF;
    border-radius: 5.81405px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: #303030;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    padding-top: 10px;
}
.scape-dropdown:hover{
    border: 1.24587px solid #BFBFBF;
    color: #BFBFBF;
}
.scape-dropdown:focus{
    border: 1.24587px solid #BFBFBF;
    color: #BFBFBF;
}
.scape-dropdown.ant-dropdown-trigger > .anticon.anticon-down{
    display: none;
  }
.scape-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align:left;
    color: #303030;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
.scape-table-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    color: #000000;
}
.scape-table-answer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    text-align: center;
    color: #000000;
}
.scape-table-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #000000;
}
.scape-table-container::-webkit-scrollbar {
    height: 3px;
    width: 100%;
  }
  
  .scape-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
    padding-right: 10px;
  }
  
  .scape-table-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:rgb(159, 159, 159);
  }
  .scape-dropdown-employee .ant-select-selector{
    width: 402px !important;
    height: 50px;
    border: 1.24587px solid #BFBFBF !important;
    border-radius: 5.81405px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    text-align: left;
    color: #303030;
    border-color:#BFBFBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer !important;
  }
  .scape-dropdown-employee{
    cursor: pointer !important;
  }
  .scape-dropdown-employee .ant-select-selection-placeholder{
    font-size: 18px !important;
    color: #BFBFBF !important;
  }
  .scape-dropdown-employee .ant-select-selection-item-content {
    max-width: 280px !important;
  }
  .scape-dropdown-employee .ant-select-selector:hover{
    border: 1.24587px solid #BFBFBF !important;
    border-color:#BFBFBF;
  }
  .scape-dropdown-employee .ant-select-selector:focus{
    border: 1.24587px solid #BFBFBF !important;
    border-color:#BFBFBF;
  }
  .scape-dropdown-employee .ant-select-selector .ant-select-selection-overflow .ant-select-multiple .ant-select-selection-item{
    font-size: 12px !important;
    height: 20px !important;
    line-height: 18px !important;
  }
  .scape-dropdown-survey .ant-select-selector{
    padding-top: 7px !important;
    font-size: 16px !important;
    line-height: 27px !important;
  }
  .search-button{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    height: 50px !important;
  }
  .search-button:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
  .search-button:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
@media (max-width:1700px) {
    .scape-table-title{
        font-size: 22px !important;
        line-height: 32px;
    }
    .scape-table-answer{
        font-size: 19px;
        line-height: 31px;
    }
    .scape-table-content{
        font-size: 18px;
        line-height: 29px;
    }
}
@media (max-width:1440px) {
    .scape-head{
        font-size: 26px;
        line-height: 36px;
        margin: 0;
    }
    .scape-dropdown{
        width: 300px !important;
        height: 45px;
        font-size: 16px;
    }
    .scape-dropdown-employee .ant-select-selection-item-content {
        max-width: 180px !important;
      }
    .scape-dropdown-employee .ant-select-selector{
        width: 300px !important;
        height: 45px !important;
      }
      .scape-dropdown-employee .ant-select-selection-placeholder{
        font-size: 16px !important;
        color: #BFBFBF !important;
      }
      .search-button{
        background: #4CCB1F;
        border: 2px solid #4CCB1F;
        border-radius: 6px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.005em;
        color: #FAFAFA;
        height: 45px !important;
      }
    .scape-menu-item{
        font-size: 13px;
        line-height: 24px;
        max-width: 300px !important;;
    }
    .scape-table-title{
        font-size: 20px !important;
        line-height: 32px;
    }
    .scape-table-answer{
        font-size: 17px;
        line-height: 28px;
    }
    .scape-table-content{
        font-size: 16px;
        line-height: 26px;
    }
}
.scape-root{
        font-size: 16px;
        font-family: "Poppins";
}

.scape-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
    padding: 0em 6.5em;
    margin: 1em 0em;
}

.scape-main-title {
    text-align: center;
    font-family: "Poppins";
    font-size: 2.2em !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.scape-data-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2em;
    grid-gap: 1em;
    gap: 1em;
}

.scape-selection-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    flex-wrap: wrap;
}

.scape-view-report-btn,.scape-view-report-btn:hover,.scape-view-report-btn:focus{
    background-color: #4CCB1F;
    padding: 0em 1.5em;
    height: 2.6em;
    color:#FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0.5em;
    border: none;
    outline: none;
    margin-top: 1em;
}
.scape-vertical-filter{
    /* background: #ECF1F4; */
    width: 100%;
    height: 350px !important;
    /* min-height: 30em; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    grid-gap: .5em;
    gap: .5em;
    padding: .5em 1em;
}
.scape-filter-content{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: start;
    align-items: center;
}
.scape-filter-text{
    margin: 0;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.scape-space-between-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    justify-content:space-between;
    padding: 0em 6.5em;
}
.scape-toggle-container{
    border-radius: 0.5em;
    border: 1px solid #ECF1F4;
    background: #FFF;
    width: 12em;
    height: 3.3em;
    display: flex;
    flex-direction: row;
}
.scape-toggle-button{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height:normal; 
    width: 100%;
    cursor: pointer;
}
.scape-toggle-button-active{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #4CCB1F;
    box-shadow: 0px 4px 4px 0px rgba(76, 203, 31, 0.20);
    color: #FFF;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    cursor: pointer;
}
.scape-preference-tab-container{
    display: flex;
    flex-direction: row;  
    justify-content: start;  
    width: 100%;
    padding: 0em 6.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
.scape-preference-tab{
    display: flex;
    flex-direction: row;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    border: 1px solid #F4F9FD;
    background: #F4F9FD;
    grid-gap: 1em;
    gap: 1em;
    height: 3.3em;
    overflow: auto;
    width: 100%;
    justify-content: start;
    padding-left: .3em;
    padding-right: .3em;
}
.scape-preference{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    min-width: 10em !important;
    width: 100% !important;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.scape-preference-color-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8px;
}
.scape-input-filter {
    border: 1px solid #ECF1F4;
    background: #FFF;
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    /* height: 3.3em !important; */
    height:46px !important;
  }
@media screen and (max-width:1600px) {
    .scape-root {
        font-size: 14px;
    }
    .scape-heading-container {
        padding: 0em 4.5em;
    }
    .scape-space-between-container{
        padding: 0em 4.5em;
    }
    .scape-preference-tab-container{
        width: 100%;
        padding: 0em 4.5em;
        }
}

@media screen and (max-width:1000px) {
    .scape-root {
        font-size: 12px;
    }
    .scape-heading-container {
        padding: 0em 3.5em;
    }
    .scape-space-between-container{
        padding: 0em 3.5em;
    }
    .scape-preference-tab-container{
        padding: 0em 3.5em;
        }
}

@media screen and (max-width:850px) {
    .scape-root {
        font-size: 10px;
    }
    .scape-heading-container {
        padding: 0em 1.5em;
    }
    .scape-space-between-container{
        padding: 0em 1.5em;
    }
    .scape-preference-tab-container{
        padding: 0em 1.5em;
        }
}

.sa-root{
    font-size: 16px;
    font-family: 'Poppins';
    width: 100%;
}
.sa-main-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.sa-page-title {
    font-family: 'Poppins';
    font-size: 2em;
    font-weight: 700;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-create-btn-large,.sa-create-btn-large:focus,.sa-create-btn-large:hover{
    background-color: #4CCB1F;
    padding: 0em 1em;
    height: 2.2em;
    color:#FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.4em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0.3em;
    border: none;
    outline: none;
    margin-top: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.sa-from-title{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sc-main-container-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em 7em;
}
.sc-main-content-container{
    width: 100%;
    background: #F4F9FD;
    border-radius: 6px;
    min-height: 20em;
    padding: 2em 1.5em;
}
.sa-home-page-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
}
.sa-table-container{
    width: 100%;
    margin: 2em 1em 1em 1em;
    display: flex;
    flex-direction: column;
}
.sc-table-header-container{
    width: 100%;
    background: #31D0AA;
    display: flex;
    border-radius: 0.5em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em;
}
.sa-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.sa-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.275em;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-label-head{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.275em;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-label-des{
    color:  #1F1F1F;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-container{
    border-radius: 0.5em;
    background: #FFF;
    width: 100%;
    margin-top: 1em;
}
.sa-table-boday-header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
}
.sa-action-icon{
    width: 20px;
    cursor: pointer;
}

.sa-expansion-arrow{
    cursor: pointer;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition-duration: 0.5s;
}

.sa-open-expansion{
    transition-duration: 0.5s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.sa-table-body-inner-container{
    padding: 1em 2em ;
    border-top: 1px soild #898989;
    width:100%;
}
.sa-global-submit-btn, .sa-global-submit-btn:hover, .sa-global-submit-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #FFF;
    text-align: center;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins';
}

.sa-global-cancel-btn, .sa-global-cancel-btn:hover, .sa-global-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #262626;
    color: #262626;
    text-align: center;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins';
}
.sa-delete-cancel-btn, .sa-delete-cancel-btn:focus, .sa-delete-cancel-btn:hover {
    color: #8C8CA1;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    border-radius: 0.5rem;
    border: 1px solid #8C8CA1;
    padding: 0.75em 1.5em;
    cursor: pointer;
    margin-top: .5em;
    background: #fff;
    outline: none;
    height: auto;
}
.sa-delete-delete-btn, .sa-delete-delete-btn:focus, .sa-delete-delete-btn:hover{
    color: #fff;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    border-radius: 0.5rem;
    border: 1px solid #E86161;
    padding: 0.75em 1.5em;
    cursor: pointer;
    margin-top: .5em;
    background: #E86161;
    outline: none;
    height: auto;

}
.sa-delete-warning-para{
    color: #000;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.025rem;
    text-transform: capitalize;
}
.selection-container{
    border-radius: 1rem;
    border: 1px solid #AABAC6;
    background: #FFF;
    min-width: 100% !important;
    width: 100% !important;
    height: 28.625em;
    overflow-y: auto;
    padding: .5em;
}
.sa-choice-modal{
    width:100%;
    min-width: 60%;
    height: 90%;
}
.sa-choice-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
    overflow-y: auto;
    max-height: 90vh;
}
.sa-choice-question-container{
    border-radius: 0.5rem;
    background: #F4F9FD;
    padding: .5em;
    margin-top: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    grid-gap: .75em;
    gap: .75em;
    width: 100%;
}
.sa-choice-question-text{
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    margin-bottom: 0em !important;
}
.sa-choice-question-text p{
    margin-bottom: 0em !important;
}
.sa-table-body-label-head p{
    margin-bottom: 0em !important;
}
.sa-choice-question-checkbox{
    width: 20px !important;
    height: 20px !important;
    border-radius: .25em;
    border: .5px solid #AABAC6;
    cursor: pointer;
}
.sa-radio-btn-group .ant-radio-checked .ant-radio-inner,.ant-radio-checked:hover,.ant-radio-checked:focus{
    border-color: #4CCB1F !important;
}
.sa-radio-btn-group .ant-radio-inner::after {
    background-color: #4CCB1F !important;
}
.sa-radio-btn-group .ant-radio-input:hover,.sa-radio-btn-group .ant-radio-input:focus{
    border-color: #4CCB1F !important;
}

.sa-edit-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
   
}
@media (max-width:1200px) {
    .sa-root{
        font-size: 14px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 5em;
    }
    .sa-choice-modal{
        width:100%;
        min-width: 85%;
        height: 90%;
    }
    .selection-container{
        min-width: 100% !important;
        width: 100% !important;
        height: 27.625em;
      
    }
}

@media (max-width:960px) {
    .sa-root{
        font-size: 12px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 3em;
    }
    .sa-choice-modal{
        width:100%;
        min-width: 90%;
        height: 90%;
    }
    .selection-container{
        min-width: 100% !important;
        width: 100% !important;
        height: 25.625em;
      
    }
}
@media (max-width:600px) {
    .sa-root{
        font-size: 10px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 1em;
    }
    .selection-container{
        min-width: 50% !important;
        height: 25.625em;
      
    }
}

.table-overflow{
    overflow: auto;
}

.sa-title-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.sc-create-container-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
    padding: 1em 10em;
}
.sc-create-content-container{
    width: 100%;
    background: #F4F9FD;
    border-radius: 6px;
    min-height: 20em;
    padding: 2em 1.5em;
}
.sa-create-title{
    font-family: 'Poppins';
    font-size: 1.2em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-create-main-title{
    font-family: 'Poppins';
    font-size: 1.5em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    grid-gap: 1em;
    gap: 1em;
}
.sa-text-input, .sa-text-input:hover, .sa-text-input:focus, .sa-text-input:focus-visible, .sa-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.sa-text-area, .sa-text-area:hover, .sa-text-areat:focus, .sa-text-area:focus-visible, .sa-text-area:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 12.25em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
    resize: none;
}
.sa-select-filed .ant-select-selector,.sa-select-filed .ant-select-selector:hover,.sa-select-filed .ant-select-selector:focus,.sa-select-filed .ant-select-selector:focus-visible,.sa-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.sa-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.sa-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.sa-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.sa-select-option.ant-select-item p{
    margin: 0px;
}
.sa-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.sa-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.sa-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.sa-switch{
     height: 2.25em;
     margin-top: 2em;
}
.sa-switch .ant-switch-handle{
    height: 1.5em;
    width: 1.5em;
    top: 5px;
    border-radius: 50% !important;
}
.sa-switch .ant-switch-inner{
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2px;
}
.sa-form-container .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 6px);
}
.sa-form-container .ant-switch-checked {
    background-color: #4CCB1F !important;
}
.sa-confirmation-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.sa-parametric-popup-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
}
.sa-parametric-title{
    font-family: 'Poppins';
    font-size: 1.5em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-gropup-modal{
    min-width: 50%;
    width: 80%;
}
.sa-gropup-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
   
}
.main-survey-card{
    padding: 0px 1em 1em 0px;
    width: 33.33%;
    max-width: 29.125em;
}

.survey-card{
    width: 100%;
    box-shadow: 0px 4px 15px 0px #AABAC626;
    background: #FFFFFF;
    border-radius: 0.75em;
    padding: 1.2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.survey-main-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}
.survey-title-section{
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.survey-title{
    margin: 0px;
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #1F1F1F;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.survey-info{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: justified;
    margin-top: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.common-btn{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.04em;
    text-align: center;
    width: 50%;
    padding: 0em 0.5em;
    border-radius: 0.5em;
    min-height: 2.938em;
    margin-top: 1em;
}

.survey-green-btn, .survey-green-btn:hover, .survey-green-btn:focus, .survey-green-btn:active{
    border: 1px solid #4CCB1F;
    color: #4CCB1F;
}

.survey-blue-btn, .survey-blue-btn:hover, .survey-blue-btn:focus, .survey-blue-btn:active{
    border: 1px solid #009AF1;
    color: #009AF1;
}

.no-data-container{
    height: 80vh;
 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-data-label{
    font-family: Poppins;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
}
.survey-invite-title{
    font-size: 1.5em;
    font-weight: 600;
    font-family: 'Poppins';
    font-style: normal;
    margin: 0;
    color: #000;
    line-height: normal;
}
.s-i-table-body-container{
    display: flex;
    flex-direction: row;
    border-radius: 0.5em;
    background: #FFF;
    width: 100%;
    margin-top: 1em;
    border: 1px solid #D5E5F2;
    padding: 1em;
}
.s-i-pending-button{
    background: rgba(249, 95, 83, 0.2);
    width: 7em;
    padding: .25em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    color: rgba(249, 95, 83, 1);
    font-size: 1em;
    border-radius: .5em;
}
.s-i-complete-button{
    background: rgba(76, 203, 31, 0.2);
    width: 7em;
    padding: .25em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    color: rgba(76, 203, 31, 1);
    font-size: 1em;
    border-radius: .5em;
}
.s-i-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    font-family: "Poppins";
    text-align: center;
}
.s-i-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
}
.invite-switch-tab{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
}
.invite-switch-tab-text{
    padding: .5em 1em;
    font-family: "Poppins";
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}
.invite-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
}
.invite-main-content-container{
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
}
.survey-invite-form-title{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.25em;
    font-style: normal;
    line-height: normal;
}

.survey-invite-form-title-input,.survey-invite-form-title-input:hover,.survey-invite-form-title-input:focus{
    width: 100%;
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid rgba(170, 186, 198, 1);
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.survey-invite-cancel-btn,.survey-invite-cancel-btn:hover,.survey-invite-cancel-btn:focus{
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    text-align: center;
    font-style: normal;
    line-height: normal;
    padding: .5em 1em;
    color: rgba(137, 152, 162, 1);
    border: 1px solid rgba(137, 152, 162, 1);
    border-radius: .25em;
    height: 3em;
    width:8em;
}

.survey-invite-send-btn,.survey-invite-send-btn:hover,.survey-invite-send-btn:focus{
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    text-align: center;
    font-style: normal;
    line-height: normal;
    padding: .5em 1em;
    color:#fff;
    background-color: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: .25em;
    height: 3em;
    width:8em;
}

.invite-multipple-employee-list-container{
    width: 100%;
    border-bottom: 1px solid rgba(201, 208, 218, 1);
}

.invite-multipple-employee-title{
    font-size: 1.5em;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    margin: 0;
    color: rgba(0, 0, 0, 1);
}
.invite-wrap-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    flex-wrap: nowrap;
}
.invite-container-padding{
    padding: 0em 6em;
}
.invite-message-content{
    font-size: .85em;
    font-weight: 400;
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
}
@media screen and (max-width:1260px) {
    .main-survey-card{
        width: 50%;
        max-width: 40em;
    }
    .invite-container-padding{
        padding: 0em 4em;
    }
}

@media screen and (max-width:860px) {
    .main-survey-card{
        width: 100%;
        max-width: none;
    }
    .invite-wrap-container{
        flex-wrap: wrap;
    }
    .invite-container-padding{
        padding: 0em 1em;
    }
}

.report-action-btn, .report-action-btn:hover, .report-action-btn:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;     border:1px solid rgb(216, 216, 216);
    cursor: pointer;
}

.report-action-btn-disabled, .report-action-btn-disabled:hover, .report-action-btn-disabled:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center; 
    border:1px solid rgb(216, 216, 216);
    cursor:not-allowed;
    background: rgb(233, 233, 233);
}

.report-action-btn-disabled img{
    width: 2em;
    height: 2em;
    object-fit: contain;
}
 

.report-action-btn img{
    width: 2em;
    height: 2em;
    object-fit: contain;
}
.poc-survey-report-root {
    font-size: 16px;
    background: rgba(255, 255, 255, 1);
    border: 8px;
    padding: 1em;
    margin-top: 1em;
}

.poc-survey-report-main-container{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
}

.poc-report-table-height{
    max-height: calc(80vh - 140px); 
  }

.poc-table-action-td {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.border-action-btn,
.border-action-btn:hover,
.border-action-btn:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(216, 216, 216);
    cursor: pointer;
    background-color: transparent;
}

.border-action-btn img {
    width: 2em;
    height: 2em;
    object-fit: contain;
}

.poc-360-invite-pop-head {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

.row-layout-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    grid-gap: 0.5em;
    gap: 0.5em; 
}

.poc-360-search-container {
    position: relative;
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    width: 100%;
    max-width: 17em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    grid-gap: .25em;
    gap: .25em;
    padding: 0.1em 0.5em;
    border-radius: 0.5em;
}

.poc-survey-selector-report{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 0.25em;
    gap: 0.25em;
}

.poc-report-action-detail-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.poc-report-export-score-scetion{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    grid-gap: 0.25em;
    gap: 0.25em;
}

.poc-report-export-btn,.poc-report-export-btn:focus,.poc-report-export-btn:hover {
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    color: #000;
    font-size: 1em;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    padding: .2em 1em;
    border-radius: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em !important;
}



@media screen and (max-width:1000px) {
    .row-layout-wrap{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:450px){
    .poc-report-action-detail-section{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.idp-home-root {
    font-size: 16px;
    font-family: "Poppins";
}
.idp-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    width:100%
}
.idp-green-btn,.idp-green-btn:hover,.idp-green-btn:focus{
    background-color: #4CCB1F;
    height:2.75em;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    border: 1px solid #4CCB1F;
    box-shadow: none;
    border-radius: .5em;
    outline: none;
    padding: .5em 1.5em;
    cursor: pointer;
    color: #fff;
}
.idp-green-border-btn,.idp-green-border-btn:hover,.idp-green-border-btn:focus{
    background-color: #fff;
    height:2.75em;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    border: 1px solid #4CCB1F;
    box-shadow: none;
    border-radius: .5em;
    outline: none;
    padding: .5em 1.5em;
    cursor: pointer;
    color: #4CCB1F;
}
.idp-team-button{
    color: #009AF1;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.idp-home-container{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #AABAC633;
    border-radius: .5em;
    padding: 1em 2em;
    min-height: 80vh;
    /* margin:1em 10em */
}
.idp-table-container{
    width:100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    
    
}

.idp-row-body{
    background: #FFFF;
    width: 100%;
    padding: 1em 3.4375em 1em 3.6875em;
}

.idp-row-body td{
    padding: 1em 0px;
    border: 1px solid #D5E5F2;
}
.idp-pending-btn{
    background: rgba(249, 95, 83, 0.2);
    color: rgba(249, 95, 83, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-inprogress-btn{
    background: rgba(242, 182, 82, 0.2);
    color: rgba(242, 182, 82, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-approved-btn{
    background: rgba(76, 203, 31, 0.2);
    color: rgba(76, 203, 31, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-complete-btn{
    background: rgba(13, 214, 113, 0.2);
    color: rgba(13, 214, 113, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-revise-btn{
    background: rgba(217, 176, 59, 0.2);
    color: rgba(217, 176, 59, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-draft-btn{
    background: rgba(14, 14, 44, 0.2);
    color: rgba(0, 0, 0, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;
}
.idp-text-transform{
    text-transform: lowercase;
}
.idp-text-transform::first-letter {
    text-transform: uppercase;
  }
@media (max-width: 1600px) {
    .idp-home-root {
        font-size: 14px;
    }
}
@media (max-width: 960px) {
    .idp-home-root {
        font-size: 12px;
    }
}
@media (max-width: 600px) {
    .idp-home-root {
        font-size: 10px;
    }
}
.idp-card{
    box-shadow: 0px 10px 15px 0px #AABAC633;
    background: #FFFFFF;
    border-radius: 0.75em;
    padding: 1em 2em;
    margin-top: 1em;
}

.idp-create-body{
    width: 55%;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5em;
    gap: 1.5em;
    padding-top: 1em;
}

.idp-switch-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.idp-switch-tab-text{
    padding: .5em ;
    font-family: "Poppins";
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: 15.5em;
    height: 3em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.idp-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}

.idp-note{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    color: #8998A2;
    margin-top: 1em;
}

.idp-input,.idp-input:hover,.idp-input:focus{
    width: 100%;
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.idp-form-label{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}

.idp-chip-wraper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0.25em;
    gap: 0.25em;
    margin-top: 1em;
}

.idp-ck-editor .cke_top {
    border-bottom: 1px solid #D5E5F2;
    background: #fff !important;
    padding: 5px 0px 5px 0px !important;
    white-space: normal;
}


.idp-ck-editor .cke_button__underline{
    border-right: 2px solid #D5E5F2;
    padding-right: 10px;
}
.idp-ck-editor .cke_toolgroup{
    margin: 0px !important;
}
.idp-ck-editor .cke_button{
    height: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.idp-ck-editor .cke_button__bulletedlist{
    border-right: 2px solid #D5E5F2;
    padding-right: 10px;
}

.idp-ck-editor .ant-form-item-control-input-content{
 padding: 0px !important;
}

.idp-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}

.idp-ck-editor .cke_bottom{
display: none;
}
.idp-ck-editor .cke_reset{
    padding: 0px 0px;
}
.idp-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.idp-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.idp-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.idp-ck-editor .cke_toolgroup a.cke_button:last-child:after{
    border-right: none !important ;
}

.idp-row-wrapper{
    display: flex;
    flex-direction: row;
    grid-gap: 1.5em;
    gap: 1.5em;
    flex-wrap: nowrap;
    align-items: center;
}

.idp-btn{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 500;
    line-height:1.25em;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.5em 1.25em;
    height: 3em;
    margin: 1em 0px !important;
    margin-right: 1em !important;
    border-radius: 0.5em;
    min-width: 9em !important;
}

.idp-submit, .idp-submit:hover, .idp-submit:focus{
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px #0000000B;
    color: #FFFFFF;
    border: 1px solid #4CCB1F
}

.idp-cancel, .idp-cancel:hover, .idp-cancel:focus{
    border: 1px solid #AABAC6;
    background: #FFFFFF;
    color: #8998A2;
}

.idp-submit-outline, .idp-submit-outline:hover, .idp-submit-outline:focus{
    background: #FFFFFF;
    color: #4CCB1F;
    border: 1px solid #4CCB1F
}

.row-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.idp-modal .ant-modal-content{
    background: #FFFFFF;
}

.idp-select-container{
    width: 100%;
    margin-top: 2em;
}

.idp-select .ant-select-selector{
    height: 3em !important;
    border-radius: 0.75em !important;
    border: 1px solid #AABAC6 !important;
}

.idp-detail-select .ant-select-selector, .idp-detail-select .ant-select-selector:focus{
    border: 1px solid transparent !important;
    outline: none !important;
}

.idp-select .ant-select-selection-placeholder , .idp-select .ant-select-selection-item{
    display: flex !important;
    align-items: center !important;
    color:#AABAC6 !important;
    font-size: 1.25em !important;
}

.idp-detail-select .ant-select-selection-placeholder , .idp-detail-select .ant-select-selection-item{
    color:#000 !important;
    font-size: 1.5em !important;
    font-weight: 600 !important;
}

.idp-select .ant-select-selection-search-input{
    height: 100% !important;
    color:#AABAC6 !important;
    font-size: 1.25em !important;
}


@media screen and (max-width:1200px) {
    .idp-create-body{
        width: 75%;
    }
}

@media screen and (max-width:960px) {
    .idp-create-date{
        height: 2.25em !important;
    }
}

@media screen and (max-width:800px) {
    .idp-create-body{
        width: 100%;
    }
    .idp-switch-tab-text{
        width: 12em;
    }
}

@media screen and (max-width:600px) {
    .idp-switch-tab{
        width: 100%;
    }
    .idp-switch-tab-text{
        width: 100%;
    }
    .idp-mobile-col{
        flex-direction: column !important;
    }
    .idp-create-date{
        max-width: 100% !important;

    }
}

.idp-create-date{
    max-width: 15em;
    height: 2.5em;
}

.team-idp-main-head{
    font-family: "Poppins";
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    margin: 0;
    font-size: 1.5em;
    color:rgba(0, 0, 0, 1);
}
.idp-tab-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.idp-tab-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.idp-tab-active{
    border-bottom: 5px solid #4CCB1F;
}

.idp-tab-active-label{
    color: #4CCB1F !important;
}

.idp-tab-label{
    color: #000000;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}

.idp-detail-heading{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 2em;
    font-weight: 600;
    margin: 0;
}
.idp-detail-small-title{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
}
.idp-detail-para{
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
    color: #000;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
}

.idp-markdown a {
    color: #009AF1 !important;
}

.idp-markdown *{
    word-break: break-all;
}

.idp-detail-left-container{
    width:60%;
}
.idp-detail-right-container{
    width:20%;
}
.idp-container-wrap{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
}
.idp-textarea{
    border: 1px solid #AABAC6;
    border-radius: 1em;
    width:100%;
    height: 15em;
    resize: none;
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 500;
    outline: none;
    padding: 1em;
}
.idp-textarea:focus{
    outline: none;
}
.idp-date-picker-custom{
    border: 1px solid #fff;
    border-radius: 8px !important;
    width: 100%;
    background-color: #fff;
}
.idp-date-picker-custom input{
    font-family:'Poppins';
    color: #000;
    font-size: 1.5em !important;
    font-weight: 600 !important;
}
.idp-date-picker-custom:hover{
    border-radius: 8px;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus-visible{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}
.idp-date-picker-custom:focus-within{
    border-radius: 8px;
    border: 1px solid #FFF !important;
    outline: 0;
    box-shadow: none !important;
}

.detail-mobile-view{
    display: none;
}

.detail-web-view{
    display: block;
}

@media (max-width:1200px) {
    .idp-detail-left-container{
        width:60%;
    }
    .idp-detail-right-container{
        width:30%;
    }
}
@media (max-width:960px) {
    .idp-detail-left-container{
        width:60%;
    }
    .idp-detail-right-container{
        width:40%;
    }

    .idp-detail-left-container{
        width:100%;
    }
    .idp-detail-right-container{
        width:100%;
    }
    .idp-container-wrap{
        flex-direction: column !important;
    }
    .detail-mobile-view{
        display: block;
    }
    
    .detail-web-view{
        display: none;
    }
}

.idp-public-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5em;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    padding: 10px 20px 10px;
}

.idp-public-container{
  padding: 2em 1.25em;
}

.overflow-ellipse{
    max-width: 100% !important;
    overflow: hidden !important;
}

.idp-table-overflow{
    min-width: 800px;
}

.poc-dahboard-root{
    font-size: 16px;
    font-family: 'Poppins';
    width: 100%;
    height: 100%;
}
.dashboard-home-banner-cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.dashboard-banner-card-continer{
    width: 25%;
    padding: 1em;
}
.dasboard-banner-card{
    background: #FFFFFF;
    border-radius: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
   
}
.poc-banner-icon{
    width:4em;
    height: 4em;
}
.poc-banner-title{
    color: #000000;
    font-size: 1.2em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.poc-banner-count{
    color: #000000;
    font-size: 2.5em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    margin: 0px;
}
.poc-dashboard-card-title{
    color: #000000;
    font-size: 1.5em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    /* white-space: nowrap; */
    margin: 0px;
}
.poc-dashboard-card-container{
    width: 50%;
    padding: 1em;
    height: 100%;
}
.poc-align-spacebetween{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    flex-wrap: wrap;
}
.poc-licence-sub-title{
    color: #000000;
    font-size: 1.25em;
    font-weight: 500;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.poc-active-btn,.poc-active-btn:hover,.poc-active-btn:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: none;
    border-radius:.5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}
.poc-card-flex{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    width: 100%;
    height: 100%;
}
.vertical-select .ant-select-selector,.vertical-select .ant-select-selector:hover,.vertical-select .ant-select-selector:focus {
    border: none !important;
    border-radius: 0px;
    box-shadow: none !important;
    border-color:#fff !important
}
.vertical-select,.vertical-select:not(.ant-select-disabled):hover,.vertical-select:not(.ant-select-disabled):focus{
    border-color:#fff !important;
    box-shadow: none !important;
}
.export-btn,.export-btn:focus,.export-btn:hover {
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    color: #000;
    font-size: .85em;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    padding: .3em 1em;
    border-radius: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.org-survey-completed{
    background-color: rgba(76, 203, 31, 0.2);
    color:rgba(76, 203, 31, 1);
    padding:.25em 1em;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    font-style: normal;
}
.org-survey-pending{
    background-color: rgba(249, 95, 83, 0.2);
    color:rgba(249, 95, 83, 1);
    padding:.25em 1em;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    font-style: normal;
}
.assesment-card{
    border: 1px solid rgba(170, 186, 198, 1);
    border-radius:1em;
    background: rgba(213, 229, 242, 0.2);
    width:100%;
    padding:1em;
    margin-top:1em;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: start;
    grid-gap:1em;
    gap:1em;
}
.assessment-report-container{
    width:100%;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content: space-between;
    overflow: auto;
}
.assessment-report-text-container{
    width: 50%;
}
.report-chart-table-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
}
.report-grap-container{
    width: 60%;
}
.report-table-container{
    width: 40%;
}
@media (max-width:1600px) {
    .poc-dahboard-root{
        font-size: 14px;
    }
    
}
@media (max-width:1400px){
    .assessment-report-container{
        flex-direction: column;
    }
    .assessment-report-text-container{
        width: 100% !important;
    }
    .report-grap-container{
        width: 100%;
    }
    .report-table-container{
        width: 100%;
    }
}
@media (max-width:1200px) {
    .poc-dahboard-root{
        font-size: 12px;
    }
}
@media (max-width:1070px){
    .report-chart-table-container{
        flex-direction: column;
    }
    .report-grap-container{
        width: 100% !important;
    }
    .report-table-container{
        width: 100% !important;
    }
}
@media (max-width:960px) {
    .poc-dahboard-root{
        font-size: 10px;
    }
}

.card-height{
    height: 28em;
}

@media (max-width:600px) {
    .poc-dahboard-root{
        font-size: 8px;
    }
    .poc-card-flex{
        display: flex;
        flex-direction:column;
        grid-gap: 1em;
        gap: 1em;
        width: 100%;
        height: 100%;
    }
    .card-height{
        height: 50em;
    }
    .poc-analystic{
        height: 48em !important;
    }
}
@media (max-width:560px) {
    .dashboard-banner-card-continer{
        width: 50%;
    }
}
@media (max-width:320px) {
    .dashboard-banner-card-continer{
        width: 100%;
    }
}

.poc_bar_chart_container{
    min-height: 300px;
    width: 100%;
}

.poc-analystic{
    height: 21em; 
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0px;
    overflow: auto;
}

.poc-analystic::-webkit-scrollbar {
    height: 100%;
    width: 4px;
    display: block;
  }
  
  .poc-analystic::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .poc-analystic::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

.analystic-main-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0.5em;
    gap: 0.5em;
}
.analystic-container{
    width: 45%;
    padding: 1em;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
    background: rgba(243, 249, 246, 1);
    margin: 0.5em 0em;
    border-radius: 8px;
    cursor: pointer;
}

.table-border-left{
    border-left: 1px solid #D5E5F2 !important;
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    border-right: none !important;
}
.table-border-right{
    border-left: none !important;
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    border-right: 1px solid #D5E5F2 !important;
}

@media screen and (max-width:1200px) {
    .analystic-container{
        width: 100%;
    }
}


.poc-org-root{
    font-size: 16px;
    font-family: Poppins;
}

.poc-org-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 230px);
}
.poc-grap-container{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
}

.poc-org-nav-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.poc-org-router-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 300px) ;
    padding: 0px 1em;
}
.vertical-switch-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.vertical-switch-tab-text{
    padding: .5em ;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}
.vertical-input,.vertical-input:hover,.vertical-input:focus{
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.vertical-form-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}
.organization-save-btn,.organization-save-btn:hover,.organization-save-btn:focus{
    background: rgba(76, 203, 31, 1);
    height: 2.5em;
    color: #fff;
    border: 1px solid rgba(76, 203, 31, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}
.organization-delete-btn,.organization-delete-btn:hover,.organization-delete-btn:focus{
    background: rgba(249, 95, 83, 1);
    height: 2.5em;
    color: #fff;
    border: 1px solid rgba(249, 95, 83, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}
.organization-cancel-btn,.organization-cancel-btn:hover,.organization-cancel-btn:focus{
    background: #fff;
    height: 2.5em;
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}

.module-usage-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 140px);
}

.module-usage-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 210px);
    padding: 0px 1em;
    overflow: auto; 
}

.module-usage-outlet::-webkit-scrollbar {
    height: 100%;
    width: 4px;
    display: block;
  }
  
  .module-usage-outlet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .module-usage-outlet::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .custom_height_class{
    max-height: calc(100vh - 250px);
    margin-top: 1em;
  }

  .module-usage-table-height{
    max-height: calc(100vh - 300px);
    margin-top: 1em;
  }
.usage-date-filter-container{
    background: #F1F7FC;
    padding: .5em 1em;
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.usage-date-filter-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    padding: .5em 1em;
    white-space: nowrap;
    cursor: pointer;
}
.usage-date-filter-label-selected{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    background: #4CCB1F;
    color: #fff;
    padding: .5em 1em;
    white-space: nowrap;
    cursor: pointer;
    border-radius: .5em;
}
@media screen and (max-width:1000px) {

    .poc-org-router-outlet{
        padding: 0px 1em 0em 1em;
    }

}
.poc-org-tree-container{
    width: 100%;
    height: 100%;
}

.non-edit-poc-foreign-parent{
    width: 100%;
    height: 180px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer !important;
}

.child-count-container{
    /* position: absolute; */
    margin-top: -15px;
}

.tree-child-count{
    width: 1em;
    height: 1em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.tree-child-count span{
    font-size: 1em;
    color: #FFFFFF;
    font-weight: 500;
}
.poc-foreign-parent{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
}

.poc-node-template{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.tree-default-node-border{
    border: 2px solid transparent;
}


.tree-organization-node-border{
    border: 2px solid #0C8AFF;
}

.tree-team-node-border{
    border: 2px solid #21D796;
}

.tree-vertical-node-border{
    border: 2px solid #21CCD7;
}

.tree-sub-vertical-node-border{
    border: 2px solid #6876ED;
}

.tree-default-link{
    stroke: #D5E5F2 !important;
    stroke-width: 2px;
}

.tree-team-link{
    stroke: #21D796!important;
    stroke-width: 2px;
}

.tree-vertical-link{
    stroke:  #21CCD7!important;
    stroke-width: 2px;
}

.tree-sub-vertical-link{
    stroke:  #6876ED!important;
    stroke-width: 2px;
}

.tree-row-spacebetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
}

.tree-row{
    display: flex;
    flex-direction: row;  
    grid-gap: 16px;  
    gap: 16px;
}

.tree-menu-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
}

.tree-menu-icon{
    width: 28px;
    height: 28px;
}

.rotate-menu-icon{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.tree-large-profile-container{
    width:54px;
    height:54px;
    min-width: 54px;
    min-height: 54px;
    border-radius:50%;
    background:#E9E9E9
}

.tree-medium-profile-container{
    width:40px;
    height:40px;
    min-width: 40px;
    min-height: 40px;
    border-radius:50%;
    background:#E9E9E9
}

.tree-data-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
}

.tree-title{
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.tree-node-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
}

.tree-node-bottom-container{
    padding: 8px 16px;
    width: 100%;
}

.tree-input-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.tree-input-box{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.tree-choice-type-container{
    width: 100%;
    height: 100%;
}

.dual-type-main-border{
    width: 240px;
    height: 110px;
    border: 2px solid #D5E5F2 ;
    border-bottom: none;
}

.mono-type-main-border{
    width: 2px;
    height: 110px;
    border: 2px solid #D5E5F2 ;
    border-bottom: none;
}

.tree-create-circle{
    width:36px;
    height:36px;
    min-width: 36px;
    min-height: 36px;
    border-radius:50%;
    background:#C9D0DA;
    font-size: 28px;
    padding:18px 8px 0px 8px;
    cursor: pointer !important;
}

.tree-create-circle:hover{
    background: #00CA64;
}

.tree-choice-circle-left{
    margin-top: 30px;
    margin-left: -18px;
}

.tree-choice-circle-right{
    margin-top: -35px;
    margin-left: 220px;
}

.tree-choice-btn{
    width: 170px;
    height: 47px;
    border-radius: 8px;
    background-color: #D5E5F2;
    font-weight: 500px;
    font-size: 16px;
    padding: 12px 5px 5px 5px;
    text-align: center;
    color: #AABAC6;
    cursor: pointer;
}

.tree-choice-btn:hover{
    background: #00CA64;
    color: #FFFFFF;
}

.tree-choice-left-btn{
    margin-top: 35px;
    margin-left: -82px;
}

.tree-choice-right-btn{
    margin-top: -47px;
    margin-left: 148px;
}

.tree-profile-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.tree-role-label{
    font-size: 12px;
    color: #7B8994;
    font-weight: 400;
    text-transform: capitalize;
}

.tree-profile-name{
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #C9D0DA;
}

.tree-multiselect-label-container{
    width: 100%;
    height: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0.5em;
    border: 1px solid #AABAC6;
    border-radius: 8px;
    margin-top: 0.75em;
}

.tree-label-chip{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    background: rgba(13, 214, 113, 0.18);
    border: 1px solid rgba(13, 214, 113, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
    margin: 0px 4px;
}

.tree-label-chip h4{
    color:#000000;
    font-size: 1em;
    margin: 0px !important;
    white-space: nowrap;
}

.chip-clear{
    cursor: pointer;
    width: 10px;
}

.tree-dropdown-menu{
    background: rgba(238, 243, 246, 1) !important;
    max-height: 250px;
    overflow: auto;
    border-radius: 20px;
    padding: 8px;
    max-width: 100% !important;
    width: 500px !important;
}

.tree-dropdown-menu .ant-dropdown-menu{
    background:transparent !important;
    box-shadow: none !important;
 
}

.tree-dropdown-menu .ant-dropdown-menu-item{

    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
}

.tree-dropdown-menu .ant-dropdown-menu-item h4{
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 500 !important;
    font-size: 1em;
    margin: 0px !important;
}

.tree-menu-dropdown-inactive-select{
    width: 26px;
    height: 26px;
    border: 1px solid rgba(140, 140, 161, 1);
    border-radius: 4px;
}

.tree-menu-dropdown-active-select{
    width: 26px;
    height: 26px;
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-mark-container{
    width: 75%;
    height: 75%;
    background: rgba(76, 203, 31, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.popup-menu-dropdown-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: transparent;
    z-index: 1060;
}

.dropdown-menu-title{
    font-size: 1em;
    font-weight: 600;
    line-height: 1.25em;
    color:rgba(0, 0, 0, 1);
}

.menu-search-container{
    width: 100%;
    position: relative;
}

.menu-search-input, .menu-search-input:hover, .menu-search-input:focus{
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1em;
    line-height: 24px;
    border: 1px solid rgba(169, 169, 169, 1);
    outline: none;
    padding-left: 28px;
}

.menu-search-input::-webkit-input-placeholder{
    color: rgba(217, 217, 217, 1);
}

.menu-search-input:-ms-input-placeholder{
    color: rgba(217, 217, 217, 1);
}

.menu-search-input::placeholder{
    color: rgba(217, 217, 217, 1);
}

.menu-search-icon{
    position: absolute;
    z-index: 1060;
    top: 8px;
    left: 5px;
    width: 1.25em;
    height: 1.25em;
}

.organization-detail-view .ant-modal-body{
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
}

.org-modal-detail{
    width: 100%;
    height: 90vh;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.org-color-container{
    width: 25%;
    min-width: 60px;
    height: 100%;
}

.org-detail-container{
    width: 100%;
    height: 100%;
}

.close-icon-container{
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: end;
}

.org-detail-section{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    margin-top: 1em;
    align-items: center;
    margin-left: -50px;
}

.org-logo-container{
    border-radius: 4px;
    height: 6.25em;
    width: 6.25em;
    min-height: 6.25em;
    min-width: 6.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.org-logo-container img{
    object-fit: contain;
    width: 80%;
    height: 80%;
}

.modal-main-title{
    font-size: 2em;
    font-weight: 600;
    line-height: 1em;
}

.modal-sub-title{
    font-size: 1.25em;
    font-weight: 400;
    line-height: 0.5em;

}

.popup-employee-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.popup-usersection-overflow-manager{
    width: 100%;
    padding: 1.25em 2em;
    margin-top: 1.25em;
    margin-right: 1em;
    overflow: auto;
    height: calc(80vh - 50px);
}

.popup-usersection-overflow-manager::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.popup-usersection-overflow-manager::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.popup-overflow-manager{
    width: 100%;
    padding: 1.25em 2em;
    margin-top: 1.25em;
    margin-right: 1em;
    overflow: auto;
    height: calc(80vh - 180px);
}

.popup-overflow-manager::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.popup-overflow-manager::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.org-manager-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5em;
    gap: 1.5em;
}


.manager-detail-container{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    width: 45%;
    margin: 0.75em;
    min-width: 200px;
}


.manager-logo-container{
    height: 5em;
    width: 5em;
    min-height: 5em;
    min-width: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager-logo-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.org-manager-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.org-manager-info h3{
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.org-manager-info h4{
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0;
}

.org-tree-loader{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(245, 244, 244, 0.957);
    opacity: 0.25;
}

.loader-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tree-mode-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5em;
    gap: 0.5em;
   justify-content: space-between;
   align-items: center;
   padding: 1em;
   flex-wrap: wrap;
}


@media screen and (max-width:1200px) {
    .org-modal-detail{
        font-size: 14px;
    }
}

@media screen and (max-width:960px) {
    .org-modal-detail{
        font-size: 12px;
    }
}

@media screen and (max-width:600px) {
    .modal-main-title{
        font-size: 1.75em;
    }
    .modal-sub-title{
        font-size: 1.15em;
    }
    .org-modal-detail{
        font-size: 10px;
    }
}

.tree-mode-switch{
    display: flex;
    flex-direction: row;
    padding: 0.25em;
    background: rgba(0, 202, 100, 0.08);
    border: 1px solid rgba(170, 186, 198, 0.2);
    border-radius: 8px;
    grid-gap: 0.25em;
    gap: 0.25em;
}

.mode-inactive{
    background: transparent; 
    color: rgba(108, 114, 127, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 0.25em 0.5em;
    cursor: pointer;
}

.mode-active{
    background: rgba(0, 202, 100, 1);   
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 0.25em 0.5em;
    border-radius: 8px;
    cursor: pointer;
}

.tree-recenter-btn, .tree-recenter-btn:hover, .tree-recenter-btn:focus {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(13, 214, 113, 1);
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: rgba(0, 202, 100, 1);
    border-radius: 8px;
    padding: 1em 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.org-detail-spacebtw{
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employee-card-container{
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    width: 30%;
    margin: 0.75em;
    min-width: 200px;
}

.userlist-main-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
}

@media screen and (min-width:1800px) {
    .employee-card-container{
        width: 22%;
    }
}

@media screen and (max-width:1000px) {
    .employee-card-container{
        width: 45%;
    }
    .manager-detail-container{
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .employee-card-container{
        width: 100%;
    }
 
}

.legends-container{
    width: .8em;
    height: .8em;
    border-radius: 50%;
}

.legend-label{
    margin: 0px;
    color: rgba(108, 114, 127, 1);
    font-size: .8em;
    white-space: nowrap;
    cursor: default;
}

.org-table-container{
    width: 100%;
    background-color: #fff;
    overflow: auto;
}
.org-table{
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.org-row-header{
    width: 100%;
    background: #31D0AA;
}

.org-row-header th{
    padding: 1em 4px;
    background: #31D0AA;
}

.org-table-row-padding-left{
    padding-left: 2em !important;
}

.org-table-row-padding-rigth {
    padding-right: 2em !important;
}

.org-content-center{
    text-align: center;
}

.org-content-right{
    text-align: right;
}

.org-row-body{
    background: #FCFCFC;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.org-row-body td{
    padding: 0.5em 4px;
}

.td-center-padding{
    padding-left: 1.6rem !important;
}

.org-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.org-table-body-label{
    color:  #1F1F1F;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.175em;
    margin: 0px 0.5em;
}
.org-table-body-label_disabled{
    color:  #d5d5d5;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.175em;
    margin: 0px 0.5em;
}
.org-left-radious{
  border-top-left-radius: 0.5em !important;
  border-bottom-left-radius: 0.5em !important;
}

.org-right-radious{
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.org-table-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.org-table-actions-right{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}
.org-poc-type{
    background: rgba(72, 133, 121, 0.5);
    color:#FFF;
    padding: .5em;
}
.org-employee-type{
    border: 1px solid rgba(170, 186, 198, 1);
    padding: .5em;
    color:rgba(0, 0, 0, 1);
}
.org-vertical-type{
    background: rgba(38, 196, 165, 0.1);
    color: rgba(16, 165, 136, 1);
    padding: .5em 1em;
}
.org-status-active{
    border: 1px solid rgba(236, 241, 244, 1);
    background-color: #fff;
    color: rgba(67, 67, 67, 1);
    font-size: .9em;
    padding: .5em 1em;
    max-width: 8em;

}
.org-status-active span {
    background: rgba(13, 214, 113, 1);
    height: .65em;
    width: .65em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .75em;
}
.org-status-inactive{
    border: 1px solid rgba(236, 241, 244, 1);
    background-color: #fff;
    color: rgba(67, 67, 67, 1);
    font-size: .9em;
    padding: .5em 1em;
    max-width: 8em;

}
.org-status-inactive span {
    background: rgba(106, 106, 106, 1);
    height: .65em;
    width: .65em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .75em;
}
.org-poc-select-filed .ant-select-selector{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid rgba(170, 186, 198, 1);
    font-size: 1em;
}
.org-poc-select-filed .ant-select-selector:hover,.org-poc-select-filed .ant-select-selector:focus,.org-poc-select-filed .ant-select-selector:focus-visible,.org-poc-select-filed .ant-select-selector:focus-within{
    border-radius: .5em;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    outline: 0;
    box-shadow: none !important;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 4px !important;
    color: rgba(137, 152, 162, 1) !important;
    font-weight: 300;
    font-size: 1em;
    margin-left: 0px;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-search-input {
    height: 2.75em !important;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 6px !important;
    font-weight: 400;
    font-size: 1em;
}
.org-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    
}
.org-option.ant-select-item p{
    margin: 0px;
}
.org-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.org-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
    
}
.org-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
}

.poc_table_scroll{
    overflow: auto;
    padding-right: 1rem;
}
.poc_table_scroll::-webkit-scrollbar {
    height: 100%;
    width: 8px;
    display: block;
  }
  
  .poc_table_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .poc_table_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .container-alignment{
    max-height: calc(80vh - 200px);
    margin-top: 1em;
  }

  .container-alignmentpoc-survey-response{
    max-height: calc(80vh - 200px);
    margin-top: 1em;
  }
  .org-export-btn-parent .ant-spin-dot-item {
    background-color: rgba(137, 152, 162, 1) !important;
  }
  .org-export-btn-parent .ant-spin-text {
    color: rgba(137, 152, 162, 1) !important;
  }
  .org-export-btn-parent .ant-progress .ant-progress-inner{
    width: 45px !important;
    height: 45px !important;
    font-size: 10px !important;
  }
  .org-export-btn-parent .ant-progress .ant-progress-inner .ant-progress-text{
        color: #4CCB1F !important;
  }
.custom-filter-container{
    box-shadow: 8px 8px 20px 0px #00000040;
    border-radius: 1em;
    background-color: #fff;
    padding: 1em;
    margin-top: 1em;
}
.custom-filter-main-container{
    border-radius: 1em;
    background-color: #fff;
}
.filter-btn,.filter-btn:hover,.filter-btn:focus{
    background-color: #0DD671;
    color: #fff;
    font-family: 'Poppins';
    border-radius: .5em;
    border: none;
    padding: .5em 1em;
    justify-content: center;
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
    width: 7em;
}
.filter-title{
    font-family: 'Poppins';
    font-size: 1.15em;
    font-weight: 600;
    line-height: normal;
    color: #000;
    margin: 0px;
}
.clear-filter-btn,.clear-filter-btn:hover,.clear-filter-btn:focus{
    background-color: #DBE6EF;
    color: #000;
    font-family: 'Poppins';
    border-radius: .5em;
    border: none;
    padding: .5em 1em;
    justify-content: center;
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
}
.filter-data-container{
    font-size: .9em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    gap: 1em;
    justify-content:space-between;
    align-items: center;
    max-width: 12em;
    width: 100%;
    margin-top: .5em;
    padding: .25em 1em;
    background: rgba(219, 230, 239, 0.2);
}
.filter-data-container-selected{
    background-color: #4CCB1F;
    color: #fff !important;
}
.custom-filter-scroll::-webkit-scrollbar {
    height: 5px;
    display: block !important;
    width: 4px;
  }
  
  .custom-filter-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .custom-filter-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
.create-employee-invitation-root{
    box-shadow: 0px 10px 15px 0px rgba(170, 186, 198, 0.2);
    background: rgba(255, 255, 255, 1);
    border-radius:0.75em;
    min-height: 70vh;
}

.create-invite-header-section{
    background:rgba(170, 186, 198, 0.2);
    border: 1px solid transparent;
    width:-webkit-max-content !important;
    width:max-content !important;
    min-width: 100%;
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.75em 0.5em;
    grid-gap: 1em;
    gap: 1em;
}

.create-invite-form-container{
    background: #fff;
    border: 1px solid rgba(170, 186, 198, 1);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 100%;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    grid-gap: 0.25em;
    gap: 0.25em;
    padding: 0.75em 0.5em;
    margin-top: 1em;
}

.create-item-section{
    width: 16.66% !important;
    min-width: 10em !important;
    overflow: hidden;
}



.invite-form-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}



.create-invite-form-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 1em;
    gap: 1em;
}



.add-mode-btn,.add-mode-btn:hover,.add-mode-btn:focus{
    margin-top: 1em;
    border: 1px solid rgba(13, 214, 113, 1);
    border-radius: .5em;
    height: 2.5em;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    font-style: normal;
    color: rgba(13, 214, 113, 1);
    background-color: #fff;
    outline: none;
    padding: .5em 1em;
}
.org-invite-cancel-btn,.org-invite-cancel-btn:focus,.org-invite-cancel-btn:hover{
    border: 1px solid rgba(108, 114, 127, 1);
    background: #FFF;
    color: rgba(108, 114, 127, 1);
    border-radius:.5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.25em;
    text-transform: uppercase;
    font-family: "Poppins";
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.poc-height-limit{
    overflow: auto;
    max-height: calc(100vh - 360px);
    padding-right: 0.5em;
}

.invitation-overflow{
    overflow: auto;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}
.invitation-overflow::-webkit-scrollbar ,.poc-height-limit::-webkit-scrollbar{
    height: 5px;
    display: block !important;
    width: 4px;
  }
  
  .invitation-overflow::-webkit-scrollbar-track ,.poc-height-limit::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .invitation-overflow::-webkit-scrollbar-thumb ,.poc-height-limit::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .poc-employee-invite-container{
    width: 100%;
    padding: 0px 0.5em;
  }

  .poc-employee-invite-table{
    width: 100%;
  }



  .action-row-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .poc-employee-invite-table{
    border-spacing: 0px 1em;
    border-collapse: separate;
  }

  .poc-employee-invite-table thead{
    height: 3.5em;
  }

  .poc-employee-invite-table thead tr{
    background: rgba(170, 186, 198, 0.2);
  }
  
  .poc-employee-invite-table thead tr :first-child{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  .poc-employee-invite-table thead tr :last-child{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .poc-employee-invite-table thead tr th{
    padding: 0.5em;
  }

  .poc-employee-invite-table tbody{
    height: 3.5em;
  }

  .poc-employee-invite-table tbody tr :first-child{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  .poc-employee-invite-table tbody tr :last-child{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .poc-employee-invite-table tbody tr td{
    border: 1px solid rgba(170, 186, 198, 1);
    padding: 0.5em;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .invite-table-border-left{
    border-left: 1px solid rgba(170, 186, 198, 1) !important;
  }

  .invite-table-border-right{
    border-right: 1px solid rgba(170, 186, 198, 1) !important;
  }

  .invite-form-input{
    width: 16em !important;
  }

  /* error handle */

 .poc-employee-invite-table tbody .create-invite-form-invalid td{
    border: 1px solid #E86161;
    padding: 0.5em !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  } 

  .create-invite-form-invalid .invite-table-border-left{
    border-left: 1px solid #E86161 !important;
  }

  .create-invite-form-invalid .invite-table-border-right{
    border-right: 1px solid #E86161 !important;
  }


  @media only screen and (max-width: 1800px) {
    .invite-form-input{
        width: 14em !important;
      }
  }

  @media only screen and (max-width: 1600px) {
    .invite-form-input{
        width: 13em !important;
      }
  }
.learning-root{
    font-size: 16px;
    font-family: Poppins !important;
    width: 100%;
}
.learning-container{
    width: 100%;
    background-color: #fff;
    overflow: auto;
    border-radius: .5em;
    padding: 1.5em 3em;
    margin-top: 1.5em;
}
.learing-back-title{
    color: #000000;
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.learing-main-title{
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: 3em;
    margin: 0;
}
.learing-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
}
.learn-completed-progress{
    font-family: "Poppins";
    font-weight: 500;
    line-height: normal;
    color: #4CCB1F;
    font-size: 2em;
}
.learn-progress .ant-progress-bg{
    height: 1.8em !important;
    border-radius: 1.3em;
}
.learn-progress .ant-progress-inner{
    border-radius: 1.3em !important;
    background: #F6FBFF !important;
}
.landing-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; 
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 3em;
    margin-left: 0px;
    -ms-scroll-snap-type: x mandatory !important;
        scroll-snap-type: x mandatory !important;
    width:100%
}
.landing-card-container::-webkit-scrollbar {
    height: 8px;
    display: block;
  }
  
  .landing-card-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px;
    display: flex;
  }

  
  .landing-card-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
.landing-card{
    scroll-snap-align: center;
    min-width: 20em !important;
    width: 20em !important;
    height: 29.5em;
    padding: 1.5em;
    border-radius: 1em;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    position: relative;
    margin-left: 0px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 1em;
    gap: 1em;
}
.landing-card:hover{
    z-index: 1100 !important;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}
.learn-start-button {
    background-color: #00d084;
    color: white;
    border-radius: 50%;
    padding: 1em 2em;
    height: 6em;
    width: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3em;
    right: -3em;
    font-size: 1em;
    opacity: 0;
    transition: ease-in-out .5s;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: 1em;
    cursor: pointer;
}

.landing-card:hover .learn-start-button {
    opacity: 1;
    transition: ease-in-out .5s;
}
.landing-card:hover .landing-card-counter{
    display: none;
}
.landing-card-title{
    font-family: 'Poppins' !important;
    line-height: normal;
    font-style: normal;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
}
.landing-card-description{
    margin-top: .5em;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: .9em;
    font-weight: 400;
    margin: 0;
    width: 16em;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.learn-card-icon{
    margin-top: -.5em;
    max-height: 9em;
}
.landing-card-counter{
    color: #8C8CA14A;
    font-family: Amita;
    font-size: 3.5em;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0 !important;
}
.learn-description{
    font-size: 1.15em;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    color: #212121;
    margin: 0;
}
.learn-active-button,.learn-active-button:hover,.learn-active-button:focus{
    border: 1px solid #4CCB1F;
    box-shadow: 0px 6px 2px -4px #0E0E2C1A;
    background: #4CCB1F;
    font-size: 1.25em;
    font-weight: 700;
    font-family: 'Poppins';
    color:#fff;
    text-transform: uppercase;
    border-radius: .5em;
    padding:.5em 2em;
    height: 2.5em;
    cursor: pointer;
    outline: none;
}
.learn-skip-btn,.learn-skip-btn:hover,.learn-skip-btn:focus{
    background: #AABAC6;
    color: #ffff;
    font-size: 1em;
    font-weight: 700;
    font-family: 'Poppins';
    border:none;
    border-radius: 2em;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    padding: .5em 2em;
}
@media (max-width:1520px) {
    .landing-card-container{
        justify-content: start !important;
    }
}
@media (max-width:1800px) {
    .learning-root{
        font-size: 14px;
    }
}
@media (max-width:1200px) {
    .learning-root{
        font-size: 12px;
    }
}
@media (max-width:960px) {
    .learning-root{
        font-size: 10px;
    }
}
@media (max-width:600px) {
    .learning-root{
        font-size: 8px;
    }
}

.soul-banner-container{
    width: 100%;
    border-radius: .5em;
    background: url('https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739427991/MApp/UI/SVGs/soul-learn-bg-inactive_tl8u3p.svg');
    background-repeat: no-repeat;
    background-size:cover;
    padding: 2.5em 2em;
    transition: ease-in-out .3s;
}
.soul-banner-container:hover{
    background: url('https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739428362/MApp/UI/SVGs/soul-learn-bg-active_s0fjzw.svg');
    background-repeat: no-repeat;
    background-size: cover;
    transition: ease-in-out .3s;
}
.banner-play-icon{
    display: none;
    position: absolute;
    bottom: 2em;
    right: 4em;
    width: 6em;
    height: 6em;
    cursor: pointer;
    transition: ease-in-out .3s;
}
.soul-banner-container:hover .banner-play-icon{
    display: block;
    transition: ease-in-out .3s;
}
.soul-read-timer{
    color: #8998A2;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.content-width{
    width:75%
}
.soul-card-continer{
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    grid-gap: 3em;
    gap: 3em;
    justify-content: space-between;
    
}
.soul-card-container-section{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    max-width: 50%;
    width: 100%;
}
.soul-learn-survey-card{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #5674FA4A;
    border-radius: .5em;
    max-width: 42em;
    width: 100% !important;
    height: 30em;
    padding: 1em 2em;
    position: relative;
}

.soul-learn-card-thumbnail{
    margin-top: 1em;
    width:100%;
    height: 18em;
    object-fit: cover;
    cursor: pointer;
}
.soul-learn-status-card-container{
    display: flex;
    flex-direction: row;
    grid-gap: 2em;
    gap: 2em;
    align-items: center;
    justify-content:center;
    max-width: 45em;
    width: 100%;
}
.soul-learn-status-card{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #5674FA4A;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 13em;
    height: 16em;
}
.soul-learn-take-survey-btn,.soul-learn-take-survey-btn:hover,.soul-learn-take-survey-btn:focus{
    background: #4CCB1F;
    border:none;
    color: #fff;
    font-size: 1em !important;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}
.soul-learn-take-survey-btn[disabled],.soul-learn-take-survey-btn[disabled]:hover,.soul-learn-take-survey-btn[disabled]:focus{
    border:none;
    color: #fff;
    font-size: 1em;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: not-allowed !important;
    text-transform: uppercase;
    font-weight: 600;
    background: #AABAC6;
}
.soul-learn-completed-btn,.soul-learn-completed-btn:hover,.soul-learn-completed-btn:focus{
    background: url(/static/media/complete-button-animation.c2753592.gif);
    color: #fff;
    font-size: .9em !important;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}
.info-preview-modal{
    max-width: 60vw;
    width: 100%;
    padding: 3em;
}
.info-preview-modal .ant-modal-content {
    background-color: #fff !important;
    background: linear-gradient(0deg, rgba(13, 214, 113, 0.1) 0%, rgba(13, 214, 113, 0.01) 100%);
    box-shadow: none !important;
}
.congrats-modal {
    max-width: 35em !important;
    width: 100% !important;
}
.congrats-modal .ant-modal-content {
    /* background-color: #fff !important; */
    max-width: 35em;
    background:url(/static/media/congratulation.1ef02c16.gif) !important;
    box-shadow: none !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size:cover !important;
    border-radius: 2.5em !important;
}
.congrats-modal .ant-modal-body{
    padding: 0px !important;
}
.gif-bg{
    background: url(/static/media/congratulation-bg.1ef02c16.gif);
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size:cover !important;
}
.learn-value-list{
    padding: 0.625em 0.8125em 0.625em 0.8125em;
    margin: .5em 0.2em;
    border-radius: 0.6875em;
    background:  #F5F5F5;
    display: flex;
    justify-content: start;
    align-items: center;
    width:70%
}

.learn-value-list h4{
    color:#1F1F1F;
    font-family: Poppins;
    font-size: 1.3125em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    /* word-break: break-all; */
}

.learn-value-list span{
    color:#009AF1;
    margin-right: 0.75em;
}
.congrats-modal-value {
    max-width: 40em !important;
    min-height:30em;
    width: 100% !important;
}
.congrats-modal-value .ant-modal-content {
    /* background-color: #fff !important; */
    max-width: 40em;
    box-shadow: none !important;
    border-radius: 2.5em !important;
    padding-bottom: 3em;
}
.congrats-modal-value .ant-modal-body{
    padding: 0px !important;
}
.soul-card-mobile-view{
    display: none;
}
.tropy-img{
    height: 11em !important;
}
@media (max-width:1400px) {
    .soul-learn-survey-card{
        max-width:100%;
        width: 100% !important;
        height: 30em;
    }  
    .soul-learn-card-thumbnail{
        height: 18em;
    }
    .soul-learn-status-card-container{
        max-width: 30em;
    } 
    .soul-learn-status-card{
        height: 14em;
        width: 12em;
    }
    .sm-font-size{
        font-size: 1em;
    }
    .tropy-img{
        height: 9em !important;
    }
    .landing-title-font-size-bold{
        font-size: 1.5em;
    }
    .landing-title-font-size-normal{
        font-size: 1.2em;
    }
}
@media (max-width:960px){
    .content-width{
        width:80%
    } 
    .banner-play-icon{
        bottom: 1em;
        right: 2em;
        width: 5em;
        height: 5em;
    }
    .tropy-img{
        height: 10em !important;
    }
}
@media (max-width:780px){
    .soul-learn-survey-card{
        max-width: 100% !important;
        width: 100% !important;
        height: 30em;
    }  
    .tropy-img{
        height: 8em !important;
    }
    .landing-title-font-size-bold{
        font-size: 1.5em;
    }
    .landing-title-font-size-normal{
        font-size: 1em;
    }
}
@media (max-width:670px) {
    .soul-card-web-vew{
        display: none;
    }
    .soul-card-mobile-view{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 2em;
        gap: 2em;
        margin-top: 2em;
    }
    .tropy-img{
        height: 10em !important;
    }
    .landing-title-font-size-bold{
        font-size: 2em;
    }
    .landing-title-font-size-normal{
        font-size: 1.25em;
    }
}
@media (max-width:600px) {
    .content-width{
        width:100%
    } 
    .soul-banner-container{  
        background-size:cover;
        background-position: center;
    }
    .soul-banner-container:hover{
        background-size:cover;
        background-position: center;
    }
    .banner-play-icon{
        bottom: 1em;
        right: 1em;
        
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  /* IE */
  src: local('Poppins', sans-serif), url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap') format("truetype");
  /*non-IE*/
}

@media only screen and (max-width: 480px) {
  h1 {
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    font-size: 12px;
    font-weight: bold;
  }
}

.ant-form-item-label>label {
  height: auto;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
  border-width: 3px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
}

.ant-checkbox-wrapper.ant-transfer-list-checkbox {
  display: none;
}

/* .ant-collapse-item-active > .ant-collapse-header {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
  box-shadow: 0px 4px 10px rgb(32 32 32 / 10%);
  margin-bottom: 10px;
} */
/* .ant-collapse-header:hover {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
} */

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  background: #f5f5f5;
}

.ant-checkbox-wrapper .ant-transfer-list-checkbox {
  display: none;
}

.ant-modal-content {
  border-radius: 10px;
}

.custom-modal .ant-modal-content {
  border-radius: 20px;
  width: 100%
}

.custom-modal-comp .ant-modal-content {
  border-radius: 20px;
  width: 100%;
  max-width: 640px;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-table-cell {
  padding: 10px 16px !important;
}

.ant-table-cell .ant-table-wrapper {
  max-width: 95% !important;
}

.ant-comment-content-author {
  justify-content: space-between !important;
}

/* .css-fycli8 > .css-oalq6z > .css-n4cq1w::before {
  height: unset;
} */

/* Spinner */
.ant-spin-nested-loading>div>.ant-spin-lg>.ant-spin-dot {
  position: fixed !important;
}

/* Card */
.ant-card-bordered .ant-card-cover {
  margin: 0;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  border-radius: 0;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-line {
  line-height: 0;
}

.ant-progress-inner {
  border-radius: 0;
}

/* ProgressCounterBtn */

.base-timer {
  position: relative;
  width: 30px;
  height: 30px;
}

.base-timer__svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ant-form-item-required {
  display: -webkit-box !important;
}

.visible-table-border-color {
  border-right: 1px solid #e2e0e0 !important;
  border-bottom: 1px solid #e2e0e0 !important;
}

.ant-dropdown {
  z-index: 1000 ;
}

@-webkit-keyframes fadeIt {

  /* 0%   { background-color: #FFFFFF; } */
  50% {
    background-color: #d1e750;
  }

  /* 100% { background-color: #FFFFFF; } */
}

@keyframes fadeIt {

  /* 0%   { background-color: #FFFFFF; } */
  50% {
    background-color: #d1e750;
  }

  /* 100% { background-color: #FFFFFF; } */
}

.backgroundAnimated {
  background-image: none !important;
  -webkit-animation: fadeIt 5s ease-in-out;
          animation: fadeIt 5s ease-in-out;
}

.ant-col-offset-1 {
  margin-left: 0px !important;
}

.custom_select_drop {
  width: 100%;
}

.custom_select_drop .ant-col-24 {
  max-width: 100% !important;
  flex: none !important;
  width: 300px !important;

}

.skillTitle:hover {
  color: #d1e750;
  text-decoration: underline;
}

.page_heading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 45px;
  line-height: 70px;
  margin: auto;
  text-align: center;
  letter-spacing: -0.8px;
}

.page_title {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 82px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #404040;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.8px;
  color: rgba(13, 35, 68, 0.8);
}

@media only screen and (max-width: 900px) {
  .search-input-filter {
    width: 250px !important;
  }

  .page_title {
    font-weight: 500;
    font-size: 34px;
    line-height: 48px;
  }

  .page_heading {
    font-weight: 500;
    font-size: 33px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .detail-page-view {
    flex-direction: column !important;
  }

  .page_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
  }

  .subtitle {
    font-size: 14px;
  }

  .page_heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
}

.engagement_btn {
  width: 290px;
  height: 50px;
  background: #B0CB1F;
  border-radius: 64px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  position: relative;
}

.engagement_btn:hover {
  background: #B0CB1F;
  color: #FFFFFF;
}

.engagement_btn .plus {
  width: 50px;
  height: 48px;
  position: absolute;
  background: #92AA11;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  right: 0px;
}

.search_box {
  box-shadow: 0px 1px 28px 8px rgba(0, 0, 0, 0.07);
  padding: 5px 50px 5px 50px;
  font-size: 18px;
  width: 600px;
  max-width: 100%;
  height: 50px;
  border: 1px solid #CACACA;
  border-radius: 70px;
}

.search-container {
  position: relative;
  margin-top: 35px;
  width: 600px;
  max-width: 80%;
}

.search_image {
  position: absolute;
  right: 20px;
  top: 12px;
}

.arrow_btn {
  width: 27px;
  height: 32px;
  background: #B0CB1F;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.arrow_label {
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #2E2E2E;
}

.calender_name {
  margin: 0px 30px;
  width: 250px;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.37);
  box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
  border-radius: 148px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 5px;
}

.next_pre_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 30px;
}

.calender_icon {
  width: 20px;
  height: 20px;
}

.grow_survey .ant-col-offset-2 {
  margin: 0px !important;
}

.action_item_section {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 15px;
}

.action_data {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.action_data .inactive {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #B0CB1F;
}

.action_data .active {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #B0CB1F;
  background-color: #B0CB1F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back_section {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  align-content: center;
}

.custom_new_table .ant-table {
  background: transparent;
}

.job-family-title {
  width: 100%;
  margin: auto;
  word-wrap: break-word;
  text-align: center;
}


.custom_link div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
}

li .navTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

li .navTitle:hover {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  /* text-decoration: underline !important; */
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item li {
  border-bottom: 2px solid transparent !important;
}

.navTitle-selected {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item li div {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 32px; */
  text-align: left;
  color: #000000 !important;
}

.custom-menu-item li div:hover {
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item-selected {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 32px; */
  text-align: left;
  color: #000000;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item {
  border: none !important;
}

.custom-selected {
  color: #000000 !important;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  color: #000000 !important;
}

.add-btn {
  font-family: 'Poppins';
  background: #4CCB1F;
  border: 1px solid #4CCB1F;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 5px 15px;
  cursor: pointer;
  height: 50px;
  width: 180px;
}

.add-btn:hover {
  border: 2px solid #4CCB1F !important;
  color: #FFFFFF !important;
  background-color: #4CCB1F !important;
}

.add-btn:focus {
  border: 2px solid #4CCB1F !important;
  color: #FFFFFF !important;
  background-color: #4CCB1F !important;
}

.import-btn {
  font-family: 'Poppins';
  background: #FFF;
  border: 2px dashed #4CCB1F;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 22px;
  text-align: center;
  color: #4CCB1F;
  padding: 10px 15px;
  cursor: pointer;
}

.import-btn:hover {
  border: 2px dashed #4CCB1F !important;
  color: #4CCB1F !important;
  background-color: #fff !important;
}

.import-btn:focus {
  border: 2px dashed #4CCB1F !important;
  color: #4CCB1F !important;
  background-color: #fff !important;
}

/* .import-btn :hover{
  border: 2px solid #4CCB1F;
  color: #4CCB1F;
} */
.page-sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  color: #262626;
}

.search-input {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-radius: 8px;
  width: 300px;
  height: 38px;
  padding-left: 35px;
}

.search-input-filter {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: none;
  border-top-right-radius: none;
  padding-left: 35px;
  width: 350px;
  height: 46px;
  font-size: 16px;
  border-right: none;
}

.search-input-bars {
  width: 330px !important;
}

.input-filter {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 46px !important;
}

.input-filter-new {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: none;
  border-top-right-radius: none;
  height: 46px !important;
  border-right: none;
}

.input-filter-new:hover {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter-new:focus {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter:hover {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter:focus {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.search-icon {
  position: "absolute";
  left: 0;
}

.go-back-btn {
  font-family: 'poppins';
  padding: 10px 15px !important;
  padding: 10px 15px !important;
  font-style: normal;
  height: 44px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #8C8C8C;
  border: 1px solid #8C8C8C;
  cursor: pointer;
  border-radius: 8px;
  width: 140px !important;
  white-space: nowrap;
  background-color: #fff;
  margin-top: 0px !important;
  background-color: #fff;
  white-space: nowrap;
}

.delete-btn {
  font-family: 'poppins';
  background: #E86161;
  border: 1px solid #E86161;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 15px !important;
  height: 45px !important;
  width: 160px
}


@media screen and (max-width:380px) {

  .delete-btn {
    margin-top: 10px;
  }

}

.calender_name_bars {
  margin: 0px 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.37);
  box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.competency-bg {
  background: url(/static/media/competency-bg.813c16dd.svg);
}

.formLabel label {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
}

.action_link {
  font-family: 'Poppins';
  color: #0052F1;
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.shadow-container {
  background: #F0F0F0;
  width: 100%;
  border: 2px solid #D9D9D9;
  box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 40px 0px;
}

.shadow-container label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #262626;
}

.conatiner-search {
  background: #F8FAFC;
  border: 1px solid #BFBFBF;
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13); */
  border-radius: 4px;
  margin-top: 20px;
  height: 40px;
  /* max-width:200px; */
  margin-left: 0px !important;
}

.blue-radio {
  background: #89dbf9;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px;
  border: 2px solid #009AF1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-radio {
  background: #C7FDD9;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px;
  border: 2px solid #4CCB1F;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-div {
  margin-top: 0px !important;
}

.blue-radio div {
  background: #009AF1;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50px;
}

.green-radio div {
  background: #4CCB1F;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50px;
}

.know-more-card {
  background: rgba(217, 217, 217, 0.11);
  box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

.know-more-card-bars {
  background: rgba(217, 217, 217, 0.11);
  box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 1.5em;
  width: -webkit-min-content;
  width: min-content;
}

.bootom-div {
  background: #7CB305;
  height: 90px;
  left: 150px;
  right: 150px;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  padding-top: 20px
}

.dashboard-btn-bottom {
  height: 56px;
  background: #FFFFFF;
  border-radius: 8px;
  align-items: center;
  padding: 10px 24px;
  border: 2px solid #7CB305;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #7CB305;
}

.dashboard-btn-bottom:hover,
.dashboard-btn-bottom:focus {
  border: 2px solid #7CB305;
  color: #7CB305;
  background: #fff;
}

.go-back-btn-bottom {
  height: 56px;
  background: #7CB305;
  border-radius: 8px;
  align-items: center;
  padding: 10px 24px;
  border: 2px solid #FFF;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #FFF;
}

.go-back-btn-bottom:hover,
.go-back-btn-bottom:focus {
  border: 2px solid #7CB305;
  color: #fff;
  background: #7CB305;
}

.title-container {
  font-family: 'Poppins';
  background: #F0F0F0;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 30px; */
}

.radio-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 20px;
  gap: 20px;
}

.add-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 57px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #2E2E2E;
}

.text-filed .test-input {
  height: 38px;
  background: #F8FAFC;
  box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  width: 100%
}

.custom-ckeck .ant-checkbox .ant-checkbox-inner {
  height: 25px;
  width: 25px;
  border: 2px solid #4CCB1F;
  border-radius: 1.5px;
  font-size: 20px;
}

.custom-ckeck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4CCB1F;
  border-color: #4CCB1F;
}

.custom-ckeck .ant-checkbox-inner::after {
  width: 10px;
  height: 15px;
}

.section-blue {
  height: 40px !important;
  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #009AF1 !important;
  color: #009AF1 !important;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}

.active-blue {
  color: #fff !important;
  background-color: #009AF1 !important;
  box-shadow: none;
  font-size: 13px;
}

.section-green {
  height: 40px !important;
  border-radius: 0px 20px 20px 0px !important;
  border: 1px solid #4CCB1F !important;
  color: #4CCB1F !important;
  border-left: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}

.active-green {
  color: #fff !important;
  background-color: #4CCB1F !important;
  box-shadow: none;
  font-size: 13px;
}

.save-btn {
  font-family: 'Poppins';
  background: #4CCB1F;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: 2px solid #4CCB1F;
  width: 150px;
  height: 50px;
  margin: 10px;
}

.save-btn:hover,
.save-btn:focus {
  color: #fff !important;
  background: #4CCB1F !important;
  border: 2px solid #4CCB1F !important;
}

.cancel-btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #595959;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  border: 1px solid #595959;
  background-color: #fff;
  width: 150px;
  height: 50px;
  margin: 10px;
}

.cancel-btn:hover,
.cancel-btn:focus {
  color: #595959 !important;
  background: #fff !important;
  border: 1px solid #595959 !important;
}

.table .ant-table-thead {
  background: linear-gradient(to right, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
  background: -webkit-linear-gradient(right, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
  background-attachment: fixed;


}

.table .ant-table-thead .ant-table-cell {
  background: transparent !important;
  border: none;
}

.compe_card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.compe_modal_head {
  font-family: 'poppins';
  top: 53px;
  background: rgba(255, 255, 255, 0.37);
  box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #414141;
  font-family: 'Poppins';
  padding: 10px 30px;
  border-radius: 50px;
}

.add-plus {
  font-family: 'Poppins';
  border: 1px solid #4CCB1F;
  color: #4CCB1F;
  cursor: pointer;
  font-weight: 500;
}

.add-plus:hover {
  border: 1px solid #4CCB1F;
  color: #4CCB1F;
}

.page-inner-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  color: #262626;
}

.page-inner-head span {
  font-weight: 700;
}

.bars_ck .cke_chrome {
  margin-bottom: 20px;
}

.add-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  color: #141414;
  text-align: center;
}

.save-btn-add-small {
  font-family: 'Poppins';
  background: #009AF1;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #009AF1;
  width: 115px;
  height: 43px;
}

.save-btn-add-small:hover,
.save-btn-add-small:focus {
  color: #FFFFFF !important;
  background: #009AF1 !important;
  border: 1px solid #009AF1 !important;
}

.cancel-btn-add-small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #262626;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  border: 1px solid #262626;
  background-color: #fff;
  width: 115px;
  height: 43px;
}

.cancel-btn-add-small:hover,
.cancel-btn-add-small:focus {
  color: #262626 !important;
  background-color: #fff !important;
  border: 1px solid #262626 !important;
}

.sub-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #262626;
}

.form-group p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: #262626;
}

.description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px !important;
  line-height: 32px;
  color: #000000;
}

.modal-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.modal-content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-top: 20px
}

.level_container {
  width: 150px;
  height: 35px;
  border: 1px solid #4CCB1F;
  margin: 15px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.side-modal {
  width: 100%
}

.side-modal .ant-modal-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 420px;
  overflow: "auto";
  padding: 20px;
}

.side-modal .ant-modal-body {
  max-height: 380px;
  overflow: auto;
  margin-right: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: -0.8px;
  color: #5E5E5E;
}

.side-modal .ant-modal-body::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.side-modal .ant-modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px
}

.side-modal .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F0F0F0;
}
.side-tool {
  width: 100%
}

.side-tool .ant-modal-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 420px;
  overflow: "auto";
  padding: 20px;
}

.side-tool .ant-modal-body {
  max-height: 380px;
  overflow: auto;
  margin-right: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: -0.8px;
  color: #5E5E5E;
}

.side-tool .ant-modal-body::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.side-tool .ant-modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px
}

.side-tool .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F0F0F0;
}
.LevelContainer {
  background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
  border-radius: 11px;
  min-width: 282px;
  max-width: 400px;
  /* height: 52px; */
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  padding: 10px;
  color: #FFFFFF;
}

.render-text {
  font-family: 'poppins';
}

.render-text p {
  font-family: 'poppins';
  font-weight: 400;
  font-size: 17px;
}

.full-length {
  height: 100vh !important;
}

.table-div {
  max-width: 715px;
  width: 100%
}

.left_bg {
  position: absolute;
  right: -30px;
  top: 61vh;
  width: 26%
}

.right_bg {
  position: absolute;
  left: -30px;
  top: 61vh;
  width: 26%
}

.table-div-bars {
  width: 75%
}

.create-comp-div {
  position: relative;
  /* background: url('../src/modules/assets/competency-bg.svg');
  background-repeat: no-repeat;
  background-position: right; */
  height: 100%;
}

.sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #262626;
}

.bars-head {
  margin: 0.2em 0em 0em 0.24em !important;
  padding-bottom: 10px !important;
}

.bars-logo {
  max-height: 50% !important;
  max-width: 58% !important;
}

@media only screen and (max-width: 600px) {
  .date-display {
    margin-top: 10px !important;
  }

  .bootom-div {
    right: 10px;
    left: 10px;
  }

  .table-div-bars {
    width: 100%;
  }

  .page_heading {
    font-size: 28px;
    line-height: 30px;
  }

  .page-sub-title {
    font-size: 22px;
    line-height: 40px;
  }



  .form-group p {
    font-size: 15px;
  }

  .calender_name_bars {
    width: 80%
  }

  .sub-title {
    font-size: 15px;
  }

  .description {
    font-size: 14px;
    line-height: 26px;
  }

  .search-input-filter {
    width: 150px;
  }

  .bars-select-box {
    min-width: 180px !important;
    font-size: 13px !important;
  }

  .bars-head {
    letter-spacing: 0.375em;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 30px;
  }

  .subtitle {
    padding-top: 0px;
    font-size: 14px;
    line-height: 24px;
  }

  .bars-logo {
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important
  }

  .search-input-bars {
    width: 230px !important;
  }

  .add-header {
    font-size: 24px;
  }
}

@media screen and (min-width:1100px) and (max-width:1600px) {
  .page_heading {
    font-size: 38px;
    line-height: 60px;
  }

  .add-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 45px;
    width: 160px;
    padding: 5px 10px;
  }

  .table-div {
    max-width: 615px;
    width: 100%
  }

  .left_bg {
    width: 30%
  }

  .right_bg {
    position: absolute;
    width: 30%
  }

  .go-back-btn {
    font-size: 16px;
    line-height: 22px;
    width: 140px;
    background-color: #fff;
    padding: 10px 10px;
  }

  .delete-btn {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 10px;
    width: 140px
  }

  .modal-head {
    font-size: 25px;
    text-align: center;
  }

  .modal-content {
    font-size: 17px;
  }

  .search-input {
    border-radius: 8px;
    width: 300px;
    height: 38px;
    padding-left: 35px;
  }

  .table-div-bars {
    width: 70%
  }

  .page-sub-title {
    font-size: 28px;
    line-height: 40px;
  }

  .form-group p {
    font-size: 15px;
  }

  .sub-title {
    font-size: 16px;
  }

  .description {
    font-size: 15px !important;
    line-height: 28px;
  }

  .bars-select-box {
    min-width: 180px !important;
    font-size: 14px !important;
  }

  .bars-head {
    letter-spacing: 0.275em !important;
    font-weight: 600;
    font-size: 51px;
  }

  .subtitle {
    font-size: 20px;
  }

  .bars-save-btn,
  .bars-cancel-btn {
    width: 180px;
    height: 56px;
    font-size: 17px !important;
  }
}

.bars-save-btn,
.bars-cancel-btn {
  width: 180px;
  height: 56px;
  font-size: 17px !important;
}

@media (max-width:960px) {
  .left_bg {
    display: none !important;
  }

  .right_bg {
    display: none !important;
  }

  .bars-head {
    letter-spacing: 0.275em !important;
    font-weight: 600;
    font-size: 41px;
    margin-bottom: 10px;
  }

  .subtitle {
    padding-top: 20px;
    font-size: 20px;
  }

  .bars-logo {
    max-height: 100% !important;
    max-width: 100% !important;
  }
}


.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:first-child {
  border-right: 8px solid;
}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:nth-child(2) {
  border-right: 8px solid;
}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-right: none;
}

.create_bar_table td:first-child {
  background: rgb(250, 250, 250);

}

.create_bar_table td {
  background: rgb(250, 250, 250);

}

.create_bar_table .ant-table-tbody>tr.ant-table-row:hover>td {
  background: rgb(250, 250, 250);

}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container {
  border: none;
}

.formLabel-modal label {
  font-family: 'Poppins';
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
}


.crop-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.custom-label {
  font-family: 'Poppins' !important;
  font-size: 19px !important;
  font-weight: 600 !important;
}

.comp_modal_content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
  margin-top: 30px;
}

.detail-page-view {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 30px;
  gap: 30px;
}


.row-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px
}

.margin {
  margin-right: 10px !important;
}

.bars-select-box input {
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  left: 0px !important;
  font-size: 14px !important;
  text-align: left;
  height: 100% !important;
}

.bars-select-box {
  min-width: 200px !important;
}

.bars-select-box .ant-select-selection-placeholder {
  text-align: left !important;
  flex: none;
  font-size: 14px;
}

.bars-head {
  text-align: center !important;
  letter-spacing: 0.375em;
  font-weight: 600;
  font-size: 77px;
}

.bars-cancel-btn:hover,
.bars-cancel-btn:focus {
  color: #4CCB1F !important;
  border: 1px solid #4CCB1F !important;
}

.table-row-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  grid-gap: 30px;
  gap: 30px;
}

@media (max-width:450px) {
  .search-input-filter {
    width: 100% !important;
    max-width: 130px !important;
  }

  .search-input-bars {
    width: 130px !important;
  }

  .bars-save-btn {
    font-size: 15px !important;
    width: 140px !important
  }

  .bars-cancel-btn {
    font-size: 15px !important;
    width: 140px !important
  }

  .bars-head {
    letter-spacing: 0.175em !important;
    font-size: 28px !important;
  }

  .subtitle {
    padding-top: 0px !important;
    font-size: 18px !important;
  }

  .button-div {
    margin-top: 0px !important;
  }

  .padding-class {
    padding-top: 10px !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width:600px) and (max-width:700px) {
  .search-input-filter {
    width: 250px !important;
  }
}

@media (max-Width:330px) {
  .bars-head {
    letter-spacing: 0.175em !important;
    font-size: 24px !important;
  }

  .subtitle {
    font-size: 15px !important;
  }
}

.custom-scroll::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #B0CB1F;
}

.bars-textarea {
  border-radius: 10px;
  padding: 7px;
  font-size: 14px;
  line-height: 1.5;
  height: 120px;
  resize: none;
  width: 100%;
  min-width: 200px;
}

.bars_ck {
  margin: 5px;
  display: flex;
  justify-content: center;
}

.level-info-container {
  height: 144.5px;
  margin-bottom: 19px;
}

.level-info-container div {
  line-height: 1.5;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.level-info-container-bars {}

.create_bar_table .ant-col-16 {
  max-width: none !important;
}

.level-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.level-info-bars {
  margin: 0 !important;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-submenu-popup>.ant-menu {
  background: #F5F5F5 !important;
  border-radius: 14px;
  right: -20px !important;
}

.ant-menu-submenu-popup {
  right: 40px !important;
  width: 100px;
}

.polygon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: -6px !important;
  top: -45px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #F5F5F5;
  z-index: 1500;
}

.polygon-for-note {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 45px !important;
  top: -25px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #F5F5F5;
}

.custom-menu-item {
  margin-top: 20px;
  border-radius: 20px;
}

.custom-menu-item .ant-drawer-mask {
  opacity: 0 !important;
}

.custom-menu-item .ant-drawer-content-wrapper {
  max-height: 90%;
  border-radius: 18px !important;
}

.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -21px 3px 147px rgba(0, 0, 0, 0.6) !important;
}

.custom-menu-item .ant-drawer-content {
  border-radius: 18px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.profile-name-side-nav {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
}

.navTitle-side {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15.64px;
  line-height: 23px;
  color: #000000;
  padding-left: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.ant-menu-submenu-active {
  color: #000000 !important;
  text-decoration: none !important;
}

.no-data {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #303030;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .side_tool_views {
    display: none;
  }
}

/* CSS FOR KUDOS FAMILY BUTTON IN ORGANIZATION DETAIL PAGE */
.organization-kudos {
  margin-top: 10px;
  width: 185px;
  height: 48px;
  background: #4CCB1F;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

.organization-kudos:hover {
  background: #4CCB1F;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

.organization-kudos:focus {
  background: #4CCB1F;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

/* THIS IS THE GLOBAL CSS WRITTEN TO ADJUST THE BACK ARROW RIGHT BELOW THE M LETTER OF MAPP LOGO */

@media screen and (min-width: 319px) and (max-width: 415px) {
  .back-arrow-global {
    padding: 0 2.04em 0 2.04em;
  }
}

@media screen and (min-width: 416px) and (max-width: 510px) {
  .back-arrow-global {
    padding: 0 2.05em 0 2.05em;
  }
}

@media screen and (min-width: 510px) and (max-width: 552px) {
  .back-arrow-global {
    padding: 0 1.92em 0 1.92em;
  }
}

@media screen and (min-width: 553px) and (max-width: 667px) {
  .back-arrow-global {
    padding: 0 2.4em 0 2.4em;
  }
}

@media screen and (min-width: 668px) and (max-width: 737px) {
  .back-arrow-global {
    padding: 0 2.45em 0 2.45em;
  }
}

@media screen and (min-width: 738px) and (max-width: 835px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 836px) and (max-width: 981px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 982px) and (max-width: 1281px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 1323px) and (max-width: 1982px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}


.green-btn,
.green-btn:hover {
  background-color: #4CCB1F !important;
  border: 1px solid #4CCB1F !important;
  color: #fff !important;
  margin: 0px 5px !important;
}

.warning-btn {
  margin: 0px 5px !important;
}

.containct-us-table .ant-table-column-sorters {
  padding: 10px 0px !important;
}

.tool-close-btn-icon:hover {
  background: #009AF1 !important;
}

.tool-close-btn-icon:focus {
  background: #009AF1 !important;
}

.ant-tooltip{
  z-index: 999 ;
}

.feedback-info-tooltip {
  z-index: 1500 !important;
  max-width: 500px !important;
}


.ant-modal-mask{
  z-index: 1150 !important;
}

.ant-modal-wrap{
  z-index: 1150 !important;
}

.ant-dropdown{
  z-index: 1160 ;
}

.ant-select-dropdown{
  z-index: 1160 ;
}

.ant-modal{
  top:25px !important
}

.content-dropdown{
  z-index: 1050 !important;
}
.kuos-dropdown-popup{
  z-index: 1160 !important;
}
.modal-datepicker-index{
  z-index: 1160 !important;
}
 
.table-border-left{
  border: 1px solid #D5E5F2;
  border-right: none !important;
}

.table-border-right{
  border: 1px solid #D5E5F2;
  border-left: none !important;
}

.table-border-up-down{
  border: 1px solid #D5E5F2;
  border-left: none !important;
  border-right: none !important;
}
.cke_notifications_area {
  pointer-events: none;
  display: none !important;
}

/* custom loader css */

/* From Uiverse.io by Nawsome */ 
.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  -webkit-animation: ringA 2s linear infinite;
          animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #3E7CA1;
}

.pl__ring--b {
  -webkit-animation-name: ringB;
          animation-name: ringB;
  stroke: #3F8866;
}

.pl__ring--c {
  -webkit-animation-name: ringC;
          animation-name: ringC;
  stroke: #427E81;
}

.pl__ring--d {
  -webkit-animation-name: ringD;
          animation-name: ringD;
  stroke: #7D835B;
}

/* Animations */
@-webkit-keyframes ringA {
  from, 4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%, 54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%, to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}
@keyframes ringA {
  from, 4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%, 54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%, to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@-webkit-keyframes ringB {
  from, 12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%, 62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%, to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringB {
  from, 12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%, 62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%, to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@-webkit-keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%, 58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%, 58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@-webkit-keyframes ringD {
  from, 8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%, 50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {
  from, 8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%, 50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}
.ant-form-item-children-icon{
  margin-right: 7px !important;
}
.ant-form-item-children-icon{
  margin-right: 7px !important;
}
.vetical-menu{
    display: block;
}

.mobile-vertical-nav-okr{
    display: none;
}

.okr-table-gap{
    grid-gap: 25px;
    gap: 25px;
}
.center{
    display: flex;
    justify-content: center !important;
}

.item-center{
    align-items: center;
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    width:100%;
    
}

.row-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}
.custom-table-v2{
    min-width: 1200px;
}
.custom-table-v2-discard{
    min-width: 1100px;
}
.overflow-div{
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
.overflow-div::-webkit-scrollbar {
    height: 5px;
  }
  
  .overflow-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .overflow-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
  .detail-scroll{
    overflow: auto;
    width: 100%;
  }
  .detail-scroll::-webkit-scrollbar {
    display: none;
  }
 

.custom-table-tr{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.table-header-section{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #8C8C8C;
    padding: 5px 10px;
}

.table-section-td{
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #595959;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    grid-gap: 15px;
    gap: 15px;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.table-body-tr-border{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 7px;
    margin-bottom: 20px;
}

.table-section-td span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.table-section-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #595959;
    padding-top: 20px;
}
.page-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }

.add-btn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 57px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
.add-button-disable{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 57px;
    background: #f5f5f5 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25) !important;
    border: none;
  }
  .add-button-disable:hover{
    border: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
.extendDeadlineButton, .extendDeadlineButton:hover, .extendDeadlineButton:focus, .extendDeadlineButton:active{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineConfirmationButton{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    margin-left: 20px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineConfirmationButton:hover{
    background: #4CCB1F;
    color: #FFFFFF;
}

@media screen and (max-width:750px) {
    .extendDeadlineButton{
        font-size:14px;
    }

    .newCorrectionDeadlineLabel{
        margin-bottom:10px;
    }

}

.buttons-container{
    justify-content: center;
}


@media screen  and  (max-width:420px){
    .buttons-container{
        justify-content: flex-start;
        margin-left:20px;
    }

}

.cancel-Modal-button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    margin-left: 20px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineButton:hover{
    background: #4CCB1F;
    color: #FFFFFF;
}


.button-with-icon{
    
        width: -webkit-fit-content;
    
        width: -moz-fit-content;
    
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 7px 25px;
        /* height: 57px; */
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        border:none;
     

}

.generic-button{
    
    width: -webkit-fit-content;
    
    width: -moz-fit-content;
    
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 8px 20px;
    /* height: 57px; */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    
    border: none;

}


.generic-button:hover{
    color:#000000
}

.okr-competency-button:hover,.okr-competency-button:focus{
    color: #000000 !important;
}

.add-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .add-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
.add-btn .plus-icon{
    font-size: 22px;
}

.progress .ant-progress-inner{
    border-radius: 22px !important;
}
.menu-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #595959;
    text-align: center;
    white-space: nowrap;
}
.menu-head-comma{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #595959;
    text-align: center;
    white-space: nowrap;
    margin: 0px !important;
}

.expansion-arrow-down{
    transition-duration: .5s; 
    -webkit-transform: rotate(90deg); 
            transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.expansion-arrow{
   cursor: pointer;
   transition-duration: .5s; 
}
.custom-menu{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: -1px 6px 27px -9px rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    margin-top:0px;
    max-width:450px;
    min-width: 300px;
    width:100%;
    position:absolute;
    top:0px;
    z-index:1000
}


.input-select-expand{
    /* background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px 0px 0px 2px; */
    height: 41px !important;
    width: 208px !important;
    border-right: none;
}
.input-select-expand.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height:38px !important;
    border-right: none;
}
.input-select-expand.ant-select-multiple .ant-select-selector {
    height:38px !important;
    border-right: none;
    padding-left: 30px !important;
}
.input-select-expand.ant-select-multiple .ant-select-selection-item{
    display: none !important;
}
.input-select-expand.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    padding-left: 25px;
}
.input-select-expand-assign.ant-select-single .ant-select-selector .ant-select-selection-item{
display: none !important;
}

.input-select-expand .ant-select-selection-item {
    display: none !important;
}
.input-filter-expand{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 0px 2px 2px 0px;
    height: 38px !important;
    width: 133px;
}
.section-assign{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #4CCB1F !important;
    color: #4CCB1F !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  
  .active-assign{
    color: #FFF!important;
    background-color: #4CCB1F !important;
    box-shadow: none;
    font-size: 13px;
  }
  
  .section-collab{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #4CCB1F !important;
    color: #4CCB1F !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  
  .active-collab{
    color: #fff !important;
    background-color: #4CCB1F !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-cascade{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #009AF1 !important;
    color: #009AF1  !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-cascade{
    color: #FFF!important;
    background-color: #009AF1 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-non-cascade{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #009AF1 !important;
    color: #009AF1 !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-non-cascade{
    color: #fff !important;
    background-color: #009AF1 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .bottom-div{
    background: #FFFFFF;
    box-shadow: -1px -1px 27px rgba(0, 0, 0, 0.11);
    width: 100%;
    bottom: 0;
    height: 90px;
    justify-content: center;
  }
  .assign-btn{
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    outline: none;
  }
  .assign-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .assign-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .assign-btn-disable{
    background: #f5f5f5 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    outline: none;
  }
  .assign-btn-disable:hover{
    border: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  .selected-item{
    width: 100%;
    height: 50px;
    background: #B0CB1F;
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:10px 20px;
    margin-top: 20px;
    text-align: center;
  }
  .selected-item p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
  }
  .select-content-div{
    max-height: 200px!important;
    min-height: 200px !important;
    overflow: auto;
    width: 295px;
  }
  .select-content-div::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .select-content-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .select-content-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }

  .input-filter-dynamic{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
  }
  .number-input{
    padding: 4px 12px;
    /* width: 39px;
    height: 36px; */
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    outline: none;
  }
  .item-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-right: 20px;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-select-expand input{
    height: 36px !important;
}

.input-select-expand .ant-select-selection-placeholder{
    margin-top: 2px !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.weight-head{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #4CCB1F;
    display: flex;
    justify-content: end;
    padding-right:16px ;
    padding-top: 10px;
}
.modal-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}
.custom-new-modal .ant-modal-content {
    border-radius: 20px;
    width:100%;
    /* max-width: 640px; */
  }
.custom-new-modal{
    padding:10px;
}
.custom-performance-modal .ant-modal-content {
    border-radius: 15px;
    width:100% !important;
    min-height: 570px !important;
    /* max-width: 640px; */
  }
  .custom-performance-modal .ant-modal-wrap{
    width:100% !important;
    overflow: hidden;
  }
  .custom-performance-modal{
    width:80% !important;
    top:10px !important
  }
@media (max-width:992px) {
    .custom-performance-modal .ant-modal-content {
        border-radius: 15px;
        width:100% !important;
        height:100% !important;
        /* max-width: 640px; */
      }
}
.modal-sub-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}
.modal-sub-head span{
    font-weight: 600;
}
.modal-textarea{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width:100%;
    resize:none;
     border-radius:4px;
     height:150px;
     padding:10px;
     overflow:auto;
     outline:none;
     margin-top:20px;
}
.modal-cancel-btn{
    padding: 8px 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
}
.modal-cancel-btn:hover{
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    background-color: #fff;
}
.modal-cancel-btn:focus{
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    background-color: #fff;
}
.modal-submit-btn{
    padding: 8px 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 8px;
    gap: 8px;
    background: #009AF1;
    border:1px solid #009AF1;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.modal-submit-btn:hover{
    background: #009AF1; 
    border:1px solid #009AF1;
    color: #FFFFFF;
}
.modal-submit-btn:focus{
    background: #009AF1; 
    border:1px solid #009AF1;
    color: #FFFFFF;
}
.title-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0.76em;
    /* padding: 20px 1em; */
}
.discard-tab{
    padding-top: 20px;
}
/* .discard-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #E86161;
} */
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}

.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-discard,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-collaboration,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-assignment,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .discard-tab-color {
    background:#E86161 !important;
}
.discard-tab .assignment-tab-color{
    background:  #4CCB1F !important;
}
.discard-tab .collaboration-tab-color{
    background:  #4CCB1F !important;
}
.discard-tab .ant-tabs-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #BFBFBF;
    padding-top: 20px;
}
.discard-tab #rc-tabs-0-tab-discard:hover{
    color: #E86161 !important;
}
.discard-tab #rc-tabs-0-tab-assignment:hover{
    color:  #4CCB1F !important;
}
.discard-tab #rc-tabs-0-tab-collaboration:hover{
    color:  #4CCB1F !important;
}
.discard-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #FCFCFC;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    position: relative;
    margin-top: -16px !important;
    overflow: auto;
    padding-right: 10px;
  }
.okr-tab .ant-menu-horizontal {
    margin-top: 30px;
    border-bottom: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
}
.okr-tab .ant-menu-submenu-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #7CB305;
}
.okr-tab .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    padding-bottom: 10px;
    color: #7CB305;
    border-bottom: 3px solid #7CB305;;
}
.tab-inner{
    padding: 0em 2em;
}
.dropdown-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
    margin: 0;
}
.reset-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #A7A7A7;
    border: none;
    cursor: pointer;
    box-shadow: none;
}
.reset-btn:hover{
    color: #A7A7A7;
    border: none;
    box-shadow: none;
    background-color: #fff;
}
.reset-btn:focus{
    color: #A7A7A7;
    border: none;
    box-shadow: none;
    background-color: #fff;
}
.view-button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
}
.view-button:hover{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
}
.view-button:focus{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
}
.custom-table-body-discard{
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
}
.custom-table-body-discard::-webkit-scrollbar {
    height: 100%;
    width: 4px;
  }
  
  .custom-table-body-discard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .custom-table-body-discard::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
.objective-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: normal;
    color: #262626;
}
.objective-title-wrap{
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow: hidden;
    width:300px
}
.discard-small-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    margin: 0;
    padding-top: 30px;
}
.discard-small-data{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #262626;
    text-align: center;
    margin: 0;
    padding-top: 10px;
}
.dot-status{
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.discard-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px;
    text-align:left !important;
    color: #262626;
}
.discard-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: justify;
    color: #262626;
}
.comment-card{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px -18px rgba(0, 0, 0, 0.14);
    border-radius: 17px;
    padding:1.5em;
    margin-top: 1em;
}
.rational-div{
    margin-top:.5em;
    padding: 1.5em;
}

.details-div{
    margin-top: 0px;
    padding: 0px 20px;
}

.detail-div{
    background: #FFFFFF;
    border: 2px solid #BFBFBF;
    border-radius: 17px;
    padding: 30px 40px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.discard-botton-div{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px rgba(0, 0, 0, 0.14);
    height: 8em;
    position: fixed;
    bottom: 0px;
    left: 14em;
    right: 5.7em;
    /* max-width: 93.75em; */
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    padding-top: 2.5em;
    transition-duration: 1s;
}
.discard-div-margin-left{
    margin-left: 220px;
    transition-duration: 1s;
}
.approve-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    align-items: center;
    border:1px solid #4CCB1F;
    border-radius: 8px;
    padding: 10px 20px !important;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}
.approve-btn:hover{
    background-color: #fff;
    border:1px solid #4CCB1F;
    color: #4CCB1F;
}
.approve-btn:focus{
    background-color: #fff;
    border:1px solid #4CCB1F;
    color: #4CCB1F;
}
.disapprove-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 22px;
    text-align: center;
    color: #E86161;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    align-items: center;
    border:1px solid #E86161;
    border-radius: 8px;
    padding: 10px 20px !important;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}
.disapprove-btn:hover{
    background-color: #fff;
    border:1px solid #E86161;
    color: #E86161;
}
.disapprove-btn:focus{
    background-color: #fff;
    border:1px solid #E86161;
    color: #E86161;
}
.pagination-label{
    font-size: 18px;
}

.progress-modal{
    border-radius: 28px !important;
    width: 1300px !important;
    max-width: 80%!important;
}
.ant-steps-item-container > .ant-steps-item-tail::after {
    height: 10px;
}

 .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    height: 15px;
    width: 15px; 
    border-radius: 50%;
}

.ant-steps-item-icon .ant-steps-icon {
    right: 2.5px !important;
}

.ant-steps-item-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.modal-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}

.progress-add-container{
    width: 586px;
    height: 204px;
    max-width: 90%;
    background: #FFFFFF;
    box-shadow: -1px 3px 58px -18px rgba(0, 0, 0, 0.31);
    border-radius: 8px;
    padding: 10px;
}

.progress-textarea-container{
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    height: 95px;
    resize: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #BFBFBF;
    padding:5px 35px 5px 5px;
}

.progress-textarea-container:focus{
    border: 1px solid #D9D9D9;
}

.textarea-submit{
    position: absolute;
    bottom:20px;
    right:20px;
    cursor:pointer
}

.modal-subtitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}
.discard-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.okr-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}

.pop-over-content .pop-over-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.disable-textarea{
    width:100%;
    height:95px;
    resize: none;
    border: 1px solid #255F63;
    color: #fff;
    padding: 5px;
    background-color: #255F63 !important;
}

.enable-textarea{
    width:100%;
    height:95px;
    resize: none;
    border: 1px solid #fff;
    color: #fff;
    padding-top: 5px;
    border-radius: 6px;
    background-color: #255F63 !important;
    padding: 5px;
}

.textarea-scroll ::-webkit-scrollbar {
    height: 0px;
    width: 3px;
  }
  
  .textarea-scroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 5px;
    padding-right: 2px;
  }
  
  .textarea-scroll  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color:#255F63;
  }

  .comma-separated-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
  }

  .table-overlay{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.55);
    border-radius: 7px;
    display: flex;
    align-items: center;
  }

  .filter-input{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px 0px 0px 2px;
    font-family: 'Poppins';
    height: 42px;
    width:420px;
  }
  .filter-drop{
    height: 42px !important;
    width:120px !important;
  }
  .selected-filter{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #BBBBBB;
  }
  .filter-drop.ant-dropdown-trigger > .anticon.anticon-down{
    display: none;
  }
  .comp-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #1F1F1F;
  }


.discuss-btn{
    background: #009AF1;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    padding: 10px 15px;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: none;
    color: #fff;
    margin-top: 30px;
}
.discuss-btn:hover{
    background: #009AF1;
    color: #fff;
    border: none;
}
.discuss-btn:focus{
    background: #009AF1;
    color: #fff;
    border: none;
}
.meet-sub{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    margin: 0;
}
.chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}

.doughnut-chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}

.ind-chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}


.doughnutChartsWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
}




/* @media screen and (min-width:35px) and (max-width:1800px) { */
    @media screen and (max-width:350px) {
        .ind-chart-container{
            margin: 20px 0px 20px 20px;
           }

           .doughnutChartsWrapper{
            margin: 20px 0px 20px 0px;
           }

          
           .doughnutChartsWrapper{
           margin-left:10px;
           }

           .graphs-wrapper{
            margin-left:10px;
           }

           .verticalCharts{
             margin:20px 0px 20px 20px;
           }


           .orgCharts{
            margin-left:10px;
           }

           .dashboard-tabs{
            margin-left:20px;
           }

           .dashboard-back-button{
            margin-left:20px;
           }

           .radio-doughnutChart{
            width:250px;
            margin:auto;
        }

        .ModalRadioGroup{
            margin-right:10px
        }
        
}

@media screen and  (min-width:351px) and (max-width:380px) {
    .ind-chart-container{
        margin: 20px 0px 20px 65px;
       }

       .doughnutChartsWrapper{
        margin: 20px 0px 20px 35px;
       }


       .graphs-wrapper{
        margin-left:35px;
       }

       .verticalCharts{
        margin:20px 0px 20px 65px;
      }

      .orgCharts{
        margin-left:35px;
       }

       .dashboard-tabs{
        margin-left:40px;
       }

       .dashboard-back-button{
        margin-left:20px;
       }

       .ModalRadioGroup{
        margin-right:10px
    }

}


@media screen and  (min-width:381px) and (max-width:420px) {
    .ind-chart-container{
        margin: 20px 0px 20px 45px;
       }

       .doughnutChartsWrapper{
        margin: 20px;
       }

       .graphs-wrapper{
        margin-left:20px;
       }


       .verticalCharts{
        margin:20px 0px 20px 45px;
      }

      .orgCharts{
        margin-left:20px;
       }

       .dashboard-tabs{
        margin-left:35px;
       }

       .ModalRadioGroup{
        margin-right:10px
    }

}

@media screen and  (min-width:421px) and (max-width:600px) {
    .ind-chart-container{
        margin: 20px 0px 20px 65px;
       }

       .verticalCharts{
        margin:20px 0px 20px 65px;
      }

      .orgCharts{
        margin-left:30px;
       }

       .dashboard-tabs{
        margin-left:30px;
       }

       .doughnutChartsWrapper{
        margin-left:30px;
       }


       .graphs-wrapper{
        margin-left:30px;
       }


}






.chart-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 49px;
    letter-spacing: -0.04em;
    color: #141414;

}
.performance-graph{
    width: 100%;
    border-left: 1px solid #BFBFBF;
    border-bottom:  1px solid #BFBFBF;
    min-height: 350px;
    vertical-align: bottom;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.performamce-overFlow{
    max-height: 430px;
    overflow: auto;
    padding-right: 10px;
    min-height: 430px;
}
.performamce-overFlow::-webkit-scrollbar {
    height: 100%;
    width: 5px !important;
  }
  
.performamce-overFlow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.performamce-overFlow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.performance-progress .ant-progress-bg{
    height: 35px!important;
}
.performance-progress .ant-progress-inner{
    background-color: #fff;
}
.progress-percentage{
    position: absolute;
    top:30px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 17px !important;
    line-height: 33px;
    color: #262626;
}
.graph-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding-left: 20px;
    max-width: 300px; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.okr-performance-graph-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding-left: 20px;
    max-width: 100%; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.user-card{
    background: #FFFFFF;
    box-shadow: -1px 3px 23px -7px rgba(0, 0, 0, 0.17);
    border-radius: 13px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    min-width: 300px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.horizontal-overflow{
    overflow: auto;
}
.horizontal-overflow::-webkit-scrollbar {
    height: 3px;
    width: 100%;
  }
  
.horizontal-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.horizontal-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.member-progress{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #4CCB1F;
}
.user-card-attribute{
    height: 70px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    min-width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.popup-padding{
    padding-left: 50px;
}
.date-range{
    width: 260px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
}
.date-range .ant-picker-input > input{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #BFBFBF;
}
.date-range .ant-picker-suffix {
margin-top: 4px;
}
@media screen and (min-width:1200px) and (max-width:1800px) {
    .page-title{
        font-size: 32px;
    }
    .add-btn{
        font-size: 18px;
        line-height: 18px;
        height: 47px;
    }
    .add-button-disable{
        font-size: 18px;
        line-height: 18px;
        height: 47px;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .input-search-expand{
        height: 38px;
        width: 208px;
    }
    .input-filter-expand{
        height: 38px !important;
        width: 133px;
    }
    .bottom-div{
        height: 70px;
    }
    .selected-item{
        width: 200px;
        height: 45px;
    }
    .modal-head{
        font-size: 26px;
    }
    .modal-sub-head{
        font-size: 15px;
        line-height: 24px;
    }
    .modal-cancel-btn{
        font-size: 16px;
        padding: 8px 25px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 16px;
        padding: 8px 25px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:35px;
        height: 35px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 22px;
    }
    .table-section-td{
        font-size: 16px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .objective-title{
        font-size: 1.5em;
    }
    .discard-small-head{
        font-size: 17px;
    }
    .discard-small-data{
        font-size: 16px;
    }
    .dot-status{
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 16px;
    }
    .discard-content{
        font-size: 16px;
        line-height: 28px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .approve-btn{
        font-size: 20px;
    }
    .disapprove-btn{
        font-size: 20px;
    }
    .comma-separated-text{
        font-size: 16px;
    }
    .filter-input{
        height: 38px;
        width:320px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 20px;
      }
      .discuss-btn{
        font-size: 20px;
        padding: 10px 15px;
     }
    .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 24px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 16px;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 18px;
    }
    .popup-padding{
        padding-left: 50px;
    }
}
@media screen and (min-width:960px) and (max-width:1200px) {
    .page-title{
        font-size: 30px;
    }
    .add-btn{
        font-size: 16px;
        line-height: 16px;
        height: 45px;
    }
    .add-button-disable{
        font-size: 16px;
        line-height: 16px;
        height: 45px;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .menu-head{
        font-size: 16px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 16px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 200px;
        height: 45px;
    }
    .modal-head{
        font-size: 24px;
    }
    .modal-sub-head{
        font-size: 14px !important;
        line-height: 23px;
    }
    .modal-cancel-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 20px;
    }
    .table-section-td{
        font-size: 15px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .discard-small-head{
        font-size: 16px;
    }
    .discard-small-data{
        font-size: 15px;
    }
    .dot-status{
        width: 11px;
        height: 11px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 15px;
    }
    .discard-content{
        font-size: 16px;
    }
    .approve-btn{
        font-size: 18px;
    }
    .disapprove-btn{
        font-size: 18px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 38px;
        width:300px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 20px;
      }

      .discuss-btn{
        font-size: 20px;
        padding: 10px 15px;
     }
     .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 22px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 16px;
    }
    .progress-percentage{
        top:28px;
    }
    .member-progress{
        font-size: 18px;
    }
    .popup-padding{
        padding-left: 40px;
    }
}
@media screen and (min-width:600px) and (max-width:960px) {
    .page-title{
        font-size: 26px;
    }
    .add-btn{
        font-size: 16px;
        line-height: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .add-button-disable{
        font-size: 16px;
        line-height: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .menu-head{
        font-size: 16px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 16px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 180px;
        height: 40px;
    }
    .modal-head{
        font-size: 26px;
    }
    .modal-sub-head{
        font-size: 14px !important;
        line-height: 23px;
    }
    .modal-cancel-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 18px;
    }
    .table-section-td{
        font-size: 15px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .discard-small-head{
        font-size: 15px;
    }
    .discard-small-data{
        font-size: 14px;
    }
    .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 14px;
    }
    .discard-content{
        font-size: 14px;
        line-height: 24px;
    }
    .approve-btn{
        font-size: 20px;
    }
    .disapprove-btn{
        font-size: 20px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 38px;
        width:280px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 18px;
      }

      .discuss-btn{
        font-size: 18px;
        padding: 6px 15px;
     }
     .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 20px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 14px;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 16px;
    }
    .popup-padding{
        padding-left: 30px;
    }
}


@media (max-width:600px) {
    .page-title{
        font-size: 24px;
    }
    .add-btn{
        font-size: 15px;
        line-height: 15px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .add-button-disable{
        font-size: 15px;
        line-height: 15px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .add-btn .plus-icon{
        font-size: 15px;
    }
    .menu-head{
        font-size: 14px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 14px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 180px;
        height: 40px;
    }
    .modal-head{
        font-size: 20px !important;
    }
    .modal-sub-head{
        font-size: 12px;
        line-height: 22px;
    }
    .modal-cancel-btn{
        font-size: 16px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 16px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 14px;
    }
    .table-section-td{
        font-size: 14px;
    }
    .table-section-head{
        font-size: 16px;
    }
    .discard-small-head{
        font-size: 14px;
    }
    .discard-small-data{
        font-size: 13px;
    }
    .dot-status{
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 13px;
    }
    .discard-content{
        font-size: 14px;
        line-height: 24px;
    }
    .comment-card{
        padding:1em
    }
    .approve-btn span{
       display: none;
    }
    .disapprove-btn span{
        display: none;
    }
    /* .discard-botton-div{
        height: 100px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 36px;
        width:150px;
      }
      .filter-drop{
        height: 36px !important;
        width:90px !important;
      }
      .selected-filter{
        font-size: 12px;
      }
      .comp-head{
        font-size: 16px;
      }

      .discuss-btn{
        font-size: 16px;
        padding: 6px 15px;
     }
    .meet-sub{
        font-size: 12px;
    }
    .chart-title{
        font-size: 16px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 13px;
        font-weight: 500;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 15px;
    }
    .popup-padding{
        padding-left: 0px;
        padding-right:40px;
    }
}



.activeTab{
  background: #3F6600;
  cursor: pointer;
}
.nonActiveTab{
  background: #B0CB1F;
  cursor: pointer;
}
.nonActiveTab:hover{
    background: #3F6600;
    cursor: pointer;
  }
.vetical-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #3F6600 !important;
    cursor: pointer;
}
.vetical-menu.ant-menu-vertical .ant-menu-item:first-child{
    margin-top: 30px;
}
.vetical-menu.ant-menu-vertical .ant-menu-item{
    height: 60px;
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.expand-table-sider{
    height: 60px;
    width: 50px;
    min-width: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
    /* background:green; */
}

.milestone-table-expand-table-sider{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;

}

.okr-input{
    width: 100% !important;
    border: 1px solid #D9D9D9 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959 !important;
}
.table-section-td .ant-form-item{
    margin-bottom: 0px;
    width: 100%;
}
.table-section-td .ant-form-item-explain-error,.ant-form-item-explain-success{
    display: none;
}

.table-save-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    border-radius: 5px;
    padding: 5px 25px !important;
    border: 1px solid #4CCB1F;
}

.table-save-btn:hover{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.range-date-picker{
    width: 100% !important;
    border: 1px solid #D9D9D9 !important;
    height: 38px;
}
.range-date-picker .ant-picker-input input{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959 !important;
}

.table-section-td .input-filter-dynamic{
    border:none !important

}

.details-page-logos{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.logo-margin{
    margin: 0px 20px 0px 20px;
}

.logo-wrapper{
    width: 45px;
    height: 45px;
    background: #F5F5F5;
    border-radius:50px;
}

.chat-icon{
    height: 45px;
    width:45px;
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: -1px;
    z-index: 100;
}

.chat-overlay{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
    /* opacity: .5; */
    z-index: 1;
}

.chat-container-div{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: -1px 5px 121px -26px rgba(0, 0, 0, 0.31);
    border-radius: 36px; 
    width: 100%!important;
    /* z-index: 1000px; */
}

.new-chat-message-box{
    width: 100%;
    /* background-color: #fff; */
    position: absolute;
    bottom: 30px;
}

.chat-expand{
    width: 70%;
    max-width: 450px;
}

.chat-outer-container{
    position: fixed;
    top: 180px;
    /* background: red; */
    right: 20px;
}
.new-reply-box{
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #D9D9D9;
}

.cornor-radius{
    border-radius: 3px;
}
.bottom-radius{
    border-radius: 0px 0px 3px 3px;
}


.font-family-poppins{
    font-family: Poppins;
    font-size: 16px;
}

.color-gray{
    color:#8C8C8C;
}

.empty-blocks{
    height:'40px';
    width:'75px';
}


.overlay-dots{
    height:500px;
    width: 300px;
}



.shadow-container-modal{
    background: #D9D9D9;
    width: 100%;
    border: 2px solid #D9D9D9;
    border-radius: 8px;
    padding: 40px 40px;
    margin-top: 60px;
  }
 

  .custom-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    margin: 40px 0px;
    line-height: 36px;
    color: #262626;
  }

 
  .poppins{
    font-family: Poppins;
  }


  
  .keyresultTable::-webkit-scrollbar {
    display: none;
  }



  .custom-table-tr div span::before {
    content: '';
    display: block;
  }

  .overlay-dynamic-island {
    position: fixed; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: 2;
  }
  .overlay-dynamic-island-notification {
    position: fixed; 
    top: 60px;
    bottom: 0;
    z-index: 2;
    left: 0;
    /* right: 0; */
    height: 100vh;
  }



  .display-msg-reply{
    background: #F0F0F0;
    width: 100%;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 21px -4px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 8px;
  }

  .table-side-section{
    height: 60px;
    width: 20px;
    min-width: 20px;
  }

  .table-side-section-by-sub-team{
    height: 60px;
    width: 40px;
    min-width: 40px;
  }

  .table-side-section-by-sub-employe{
    height: 60px;
    width: 80px;
    min-width: 80px;
  }

  .fs-20{
    font-size: 20px;
  }

  .fs-55{
    font-size: 55px;
  }

  .dashboard-tab{
    height: 160px;
    
  }


  .section-cascade-modal{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #595959 !important;
    color: #595959  !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-cascade-modal{
    color: #FFF!important;
    background-color: #595959 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-non-cascade-modal{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #595959 !important;
    color: #595959 !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-non-cascade-modal{
    color: #fff !important;
    background-color: #595959 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .dashboard-tabs .ant-tabs-ink-bar {
    display: none !important;
  }

  .dashboard-tabs .ant-tabs-tab{
    border-radius: 15px !important;
  }
  .dashboard-tabs .ant-carousel{
    height: 100% !important;
  }

  .dashboard-tabs .ant-carousel div{
    height: 100% !important;
  }


  .dashboard-tabs .ant-carousel .slick-slider{
    height: 100% !important;
  }
  .dashboard-tabs .ant-tabs-tab-btn{
    width:100%
  }

  .dashboard-tab-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left:10px;
    margin-right: 10px;
    margin: 0;
  }
  .dashboard-tab-content{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #D9D9D9 !important;
    margin: 0;
    padding: 10px 0px;
  }

  .ant-tabs-tab-active .dashboard-tab-title{
    color: #fff !important;
  }

  .ant-tabs-tab-active .dashboard-tab-content{
    color: #fff !important;
  }

 .tab-child{
    min-width: 1000px;
 }
 .tab-child .ant-tabs-nav-wrap{
    border-bottom: none;
 }
 .dashboard-tabs::-webkit-scrollbar {
    height: 3px;
    
  }
  
  .dashboard-tabs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  .dashboard-tab-inner-container{
    padding: 10px 10px;
  }
  .dashboard-tabs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
    cursor: pointer;
  }

  .dashboard-tabs .ant-spin-dot-item {
    background-color: #FFF;
  }
  .dashboard-tabs .green-spinner .ant-spin-dot-item {
    background-color: #B0CB1F ;
  }
  
  .cascadedOkrs{
    margin-right:15px;
  }

  .nonCascadedOkrs{
    margin-left:15px;
  }


.spinner{
    width: 95%;
    background:#FFFFFF;
    height: 100%;
    border-radius: 15px;
}

  .performanceContainer{
    padding:30px 80px;
   }

 
  @media screen and (max-width:1150px) {
    .performanceContainer{
        padding:30px 30px;
      }
    
  }


  @media screen and (max-width:768px) {
      .spinner{
        width: 100%;
      }

      .cascadedOkrs{
        margin-right:0px;
      }


    
  .nonCascadedOkrs{
    margin-top:15px;
    margin-left:0px;
  }

  }




  .mb-20{
    margin-bottom:20px;
  }


  .round{
    margin-right: 6px;
    width: 12px;
    height:12px;
    border-radius:20px;
  }

  .legend-missed{
    background: #EF7878;
  }

  .legend-In-Progress{
    background: #FAC53C;
  }

  .legend-completed{
    background: #9FEA84;
  }

  .legend-discarded{
    background: #8A8A8A;
  }

  .legend-pending{
    background: #009AF1;
  }

  .Manrope{
    font-family:'Manrope';
  }

  .okrPerformance-table-heading{
    color: #A3A3A3;
    font-weight: 500;
  }

 @media screen and (max-width:500px) {
    .ant-picker-date-range-wrapper .ant-picker-panel-container {
        width:260px;
        overflow: auto;
     }

 }

 @media screen and (max-width:350px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 290px;
        margin-left:17px;
   }
 }

 @media screen and (min-width:351px) and  (max-width:380px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 310px;
        margin-left:40px;
   }
 }


 @media screen and (min-width:381px) and  (max-width:500px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 330px;
        margin-left:55px;
   }
 }


 @media screen and (min-width:500px) and  (max-width:600px) {
    
    

    .custom-menu-dashboard{
        
        margin-left:80px;
   }
 }
.inactive-user{
display: block;
}
.active-user{
    display: none;
}
.active-profile:hover .active-user{
    display: block;
}
.active-profile:hover .inactive-user{
    display: none;
}
.bell-inactive{
    display: block;
}
.bell-hover{
    display: none;
}

.active-notification:hover .bell-inactive{
    display: none;
}
.active-notification:hover .bell-hover{
    display: block !important;
}
.notifcation-icons{
    margin-bottom: -12px;
}
 .question-inactive{
    display: block;
 }
 .question-hover{
    display: none;
 }
 .question-sction:hover .question-hover{
    display: block;
 }
 .question-sction:hover .question-inactive{
    display: none;
 }

/* .nav-menu .ant-menu-horizontal{
    line-height: 62px;
} */

.ant-layout-header {
    line-height: 0px !important;
    height: 0px !important;
    padding: 0px 0px;
}
.icon-size{
    width: 19px;
    height: 24px;
}

.icon-size-profile{
    width: 17px;
}

.icon-size-question{
    width:22px
}
.three-dot-menu{
    border-radius: 13px;
    padding: 10px 0px;
}
  .popover-progress-tracker {
    position: fixed; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: 2;
  }

  .full-width-progress{
    display: flex;
    padding-left:80px;
    border-radius:50px;
    padding-right:80px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .incomplete-progress-width{
    display: flex;
    padding-left:80px;
    border-top-left-radius: 50px;
    border-bottom-left-radius : 50px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .full-width-progress{
    display: flex;
    padding-left:80px;
    border-radius:50px;
    padding-right:80px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .incomplete-progress-width{
    display: flex;
    padding-left:80px;
    border-top-left-radius: 50px;
    border-bottom-left-radius : 50px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }



.date-range-okr{
    visibility: visible;
    /* height: 0;
    padding: 0; */
    width: 40%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    /* padding-left: 24px; */
    cursor: pointer;
    /* min-width: 480px; */
    /* position: absolute; */
}
.date-range-okr input{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
    color: #009AF1;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
    z-index: 1100 !important;

}
.date-range-okr input::-webkit-input-placeholder{
    content: '-';
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
}
.date-range-okr input:-ms-input-placeholder{
    content: '-';
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
}
.date-range-okr input::placeholder{
    content: '-';
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
}
.date-range-okr .ant-picker-range-separator{
    display: none !important;
}
.date-range-okr .ant-picker-suffix{
   display: none !important;
}
.date-range-okr .ant-picker-active-bar{
    display: none !important;
}
.date-range-okr input:hover::after{
    content: 'tttt';
}
/* .date-range-okr .ant-picker-range-arrow{
display: none;
} */
.custom-date{
    margin-top: -15px;
    padding: 0px;
    font-family: 'Poppins';
    font-size: 12px;
    max-width: 440px;
}
.custom-date .ant-picker-panel-container{
    box-shadow:none;
    border-radius: none;
    border: none;   
    margin-left: 3px !important;
    position: absolute;
    top:-300px !important;
}
.custom-date .ant-picker-range-arrow{
    display: none;
}
.custom-date .ant-picker-range-wrapper{
    /* min-width: 300px; */
    max-width: 540px;
}
.custom-date .ant-picker-date-panel .ant-picker-content{
    width:200px !important;
}
.custom-date .ant-picker-cell {
    padding: 0px;
}
.custom-date .ant-picker-date-panel .ant-picker-content th {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.custom-date .ant-picker-date-panel{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
/* .custom-date .ant-picker-header-super-prev-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-prev-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-next-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-super-next-btn{
    visibility: visible !important;
} */
/* .custom-date .ant-picker-header-super-next-btn{
    display: none;
} */
/* .custom-datePicker-container{
    border-right: 1px solid rgb(171, 171, 171);
} */
.date-label-head{
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.seletion-title{
    padding: 3px 10px;
    text-align: center;
    font-size: 12px;
    font-family: 'Poppins';
    cursor: pointer;
    justify-content: center;
    white-space: nowrap;
    margin-top:3px;
}
.seletion-title:hover{
    background-color: #B0CB1F;
    color: #fff;
}
.overflow-selection{
    max-height: 110px;
    overflow: auto;
    padding-right:10px;
    padding-top: 10px;
}

.overflow-selection::-webkit-scrollbar {
    height: 100%;
    width: 3px !important;
  }
  
.overflow-selection::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.overflow-selection::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.see-more{
    text-align: center;
    color: #B0CB1F;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.seletion-title-selected{
    background-color: #B0CB1F;
    color: #fff;
    padding: 3px 10px;
    text-align: center;
    font-size: 12px;
    font-family: 'Poppins';
    cursor: pointer;
    justify-content: center;
    margin-top:3px;
}
.custon-datepicker-btn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px 10px;
    height: 30px;
    background: #B0CB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
.custon-datepicker-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #B0CB1F !important;
  }
  .custon-datepicker-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #B0CB1F !important;
  }
  .custon-datepicker-btn .plus-icon{
    font-size: 16px;
}
.custon-datepicker-btn-cancel{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px 10px;
    height: 30px;
    background: #FFF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #303030;
}
.custon-datepicker-btn-cancel:hover{
    color: #303030!important;
    background-color: #FFF!important;
  }
  .custon-datepicker-btn-cancel:focus{
    color: #303030!important;
    background-color: #FFF!important;
  }
  .custon-datepicker-btn-cancel .plus-icon{
    font-size: 14px;
}
.display-date{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
    color: #303030;
    margin: 0;
    white-space: nowrap;
}

/* @media (max-height:640px) {
    .custom-date{
        top:260px !important;
    }
} */
.horizontal-overflow{
    width:100%;
    overflow: auto;
    background-color: #fff;
}
.horizontal-overflow::-webkit-scrollbar {
    height: 3px;
    width: 100px !important;
  }
  
.horizontal-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.horizontal-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}

.horizontal-overflow-team-dashboard{
    width:100%;
    overflow: auto;
    background-color: #fff;
}
.horizontal-overflow-team-dashboard::-webkit-scrollbar {
    height: 3px;
    width: 7px !important;
  }
  
.horizontal-overflow-team-dashboard::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}

@media (max-width:740px) {
    .custom-okr-picker{
        display: none !important;
    }
    .custom-date{
        display: none !important;
    }
    .custom-menu{
        display: none !important;
    }
    .okr-picker{
        display: block;
        flex-wrap: nowrap;
    }
}
@media (min-width:740px) {
    .custom-okr-picker{
        display: block !important;
    }
    .okr-picker{
        display: none !important;
    }
    .okr-picker .ant-picker-dropdown{
        display: none !important;
    }
    .custom-date-small{
        display: none !important;
    }
}

.custom-menu-expandable{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: -1px 6px 27px -9px rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    margin-top:0px;
    max-width:450px;
    min-width: 450px;
    width:100%;
    position:absolute;
    top:0px;
    z-index:1000
}
.overlay-dynamic-island-nav {
    position: fixed;
    width:100%;
    z-index: 2;
    left: 0;
    height: 100vh !important;
  }
  .nav-profile-dropdown{
    width:180px;
    padding: 20px;
    background: #F5F5F5;
    border-radius: 14px;
    margin-top:25px !important;
    margin-right: -20px !important;
  }
  .notification-dropdown{
    width:350px;
    border-radius:14px;
    background: #F5F5F5;
    margin-top:25px !important;
    margin-right: -50px !important;
  }
  .nav-drop-content-div{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    color: #000000;
    cursor: pointer;
  }
  .notification-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    text-align: left;
    max-width: 250px;
  }
  .notification-title-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
  }
  .hover-notification-titile:hover{
    cursor: pointer;
    color: #4CCB1F;
  }
  .tab-style-note{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #B3B3B3;
    cursor: pointer;
  }
  .selected-tab-note{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  .undo-button{
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-color: #595959;
            text-decoration-color: #595959;
    text-underline-offset: 3px;
    color: #595959;
  }

.ant-message{
    z-index: 10000;
}
.selected-tab-non-selected{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8C8C8C;
    margin: 0;
    padding: 5px 5px;
}
@media screen and (min-width:950px)  {
    .admin-access{
        margin-left:20px ;
    }
  
    .admin-access li{
     margin: 0px 3px !important;
    }
}

@media screen and (max-width:950px)  {
    .admin-access{
        margin-left:20px ;
    }
  
    .admin-access li{
     margin: 0px 3px !important;
    }
}

.progress-input{
    width:55px
}
/* .custom-okr-picker{
    width: 280px !important;
    min-width: 280px !important;
} */



.role-image-colored{
    display: block;
}

.role-image-bw{
    display: block;
}

.ant-switch-checked {
    background-color: #2EC7FF !important;
 }
/* .role-card-images:hover .role-image-colored{
    display: block;
}

.role-card-images:hover .role-image-bw{
    display: none;
} */


@media screen and (max-width:950px) {
    .vetical-menu{
        display: none;
    }
    .side-tool-hide{
        display:none;
      }
    .okr-table-gap{
        grid-gap: 0px !important;
        gap: 0px !important;
    }
    .mobile-vertical-nav-okr{
        z-index: 1000;
        display: block;
        height: 40px;
        width: 40px;
        background-color: rgb(176, 203, 31);
        position: fixed;
        top: 100px;
        right: 0px;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nav-opener{
    width: 30px;
    height: 30px;
}

.expand-vertical-bar{
    width: 280px !important;
    z-index: 1000;
}

.expand-vertical-bar ul{
    width: 280px;
    background-color: transparent;
}

.expand-vertical-bar li{
    height: 35px !important;
    padding: 0px !important;
}
.expand-vertical-bar li img{
    width: 30px;
    height: 30px;
    margin-top: -28px;
}

.rotate-icon {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
}

.competency-select .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;   
    border: 1px solid #C2C2C2;
}
.competency-select-invalid .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;   
    border: 1px solid #ff4d4f !important;
}
.competency-select .ant-select-selector .ant-select-selection-item{
    line-height: 37px !important;
}
.without-tool-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}
.without-tool-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}
.without-tool-ck-editor .cke_bottom{
display: none;
}
.without-tool-ck-editor .cke_reset{
    padding: 0px 0px;
}
.without-tool-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.without-tool-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.without-tool-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.ant-list-split .ant-list-item {
    border-bottom: none !important;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }


  /* Styles for impact narratives */

  .impact-narrative-visible{
    display: block;
  }

  .impact-narratives{
    display: none;
    visibility: hidden;
  }

  .pointer-events-know-more{
    pointer-events: none;
  }

  .hide-know-more{
    display: none !important;
  }

  .row-dragging-group {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
   } 

   .row-dragging-group td:last-child {
    position: relative;
    right: 0px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    
   } 

   .row-dragging-group td:nth-child(2){
    margin-left: -335px;
   }


   .row-dragging-group td:nth-child(3){
    margin-left: -340px;
   }

   .bread-crumb-last-child{
    margin-left: 0.31em;
   }

   .global-root-em{
    font-size: 16px;
   }

   .container-row-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   }

   .main-page-card{
    background: #FFFFFF;
    border-radius: 12px;
    padding: 2em 0.5em;
    min-height: 80vh;
   }

   .menu-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    width:30px
  }

  .menu-icon .dot {
    width: 6px;
    height: 6px;
    min-height: 6px;
    min-width: 6px;
    border-radius: 50%;
    margin: 4px;
    background-color: #8C8CA1;
  }
  .rd-input-star-grow{
    color: #E86161;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em; 
    padding-top: -1px !important;
}
@media (max-width:1200px) {
    .discard-botton-div{
        left:12em;
        right:3.9em
    }
}
@media (max-width:960px) {
    .discard-botton-div{
        left:3.5em;
        right:2.9em
    }
    .discard-div-margin-left{
        margin-left: 0px;
        }
}

.pointer{
    cursor: pointer;
}

.text-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.video-preview-modal{
    max-width: 68vw;
    width: 100%;
}
.video-preview-modal .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
}
.video-preview-container {
    margin: 1em 3em ;
}
.preview-video{
    width: 100%;
     max-height:75vh !important;
     min-height: 75vh !important;
     border-radius:1em;
     background:#D9D9D9;
}
@media (max-width:960px){
    .video-preview-container {
        margin: 1em 2em ;
    }
    .preview-video{
        width: 100%;
        max-height:50vh !important;
        min-height: 50vh !important;
    }
    .video-preview-modal{
        max-width: 80vw;
        width: 100%;
    }
}
@media (max-width:600px){
    .video-preview-container {
        margin: 1em 0em ;
    }
    .preview-video{
        width: 100%;
        max-height: 40vh !important;
        min-height: 40vh !important;
    }
    .video-preview-modal{
        max-width: 100vw;
        width: 100%;
    }
}
.marquee-text{
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.marquee-text span{
    font-size: 14px !important;
    margin: 0px !important;
    margin-left: 5px !important;
}

.marquee-container{
    width: 100%;
    background: rgb(106, 234, 60);
    padding:5px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: .5em;
}
.marquee-container img{
    cursor: pointer;
}
.text-transform-capitalize {
    text-transform: capitalize;
}
.generic-date-picker-date .ant-picker-footer{
    display: none !important;
}

*{
    font-family: Poppins !important; 
}

.page-back{
   width: 4.75em;
   height: 1.25em;
   display: flex;
   justify-content: end;
   align-items: center;
   padding: 1.5em 0px;
}

.page-back img{
    cursor: pointer;
}


.global-submit-btn, .global-submit-btn:hover, .global-submit-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #FFF;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: Poppins;
}

.global-cancel-btn, .global-cancel-btn:hover, .global-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #262626;
    color: #262626;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    font-family: Poppins;
}

.ant-form-item-has-error .ant-input{
    border-color: #ff4d4f !important;
}

.add-okr-table .ant-form-item-has-error .ant-input,
.add-okr-table .ant-form-item-has-error .ant-input:focus
{
    border-color: #c7d943 !important;
    box-shadow: none !important;
}

.custom-multiselectdropdown{
    width: 100%;
    height: 3em;
    padding: 0em 0em 0em 1em;
    position: relative;
    border-radius: 0.5em;
    border: 1px solid #AABAC6;
    background: #FFF;
}

.custom-multiselectdropdown-icon{
    position: absolute;
    right: 1.8em;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
}

.custom-multiselectdropdown-search{
    width: 100%;
    border-radius: 1em;
    background: #FFF;
    height: 2.8em;
    margin: 0px !important;
    padding: 0em 4em 0em 0em;
    outline: none;
    border: none;
}

.custom-multiselectdropdown-menu{
    width: 100%;
    /* padding: 1em; */
    background:#FFF;
    border: 1px solid #AABAC6;
    border-radius: 1em;
}

.custom-multiselectdropdown-choice, .custom-multiselectdropdown-choice:hover, .custom-multiselectdropdown-choice:focus{
    border-radius: 1em;
    background: #F4F9FD;
    height: 3em;
    padding: 0.5em 1em 0.5em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 1em 0em;
    cursor: pointer;
    flex-wrap: nowrap;
}

.multiselectdropdown-choice-label{
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.01em;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
}


.multiselectdropdown-choice-selection{
    border-radius: 0.75rem;
    background: #FFF;
    width: 2rem;
    height: 2rem;
    border: none;
    outline: none;
    color: #000;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    cursor: pointer;
}

.active-choice-selection{
    background: #4CCB1F;
    color: #FFF
}

.custom-multiselectdropdown-add{
    border-top: 1px solid #AABAC6 ;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5em;
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}

.custom-multiselectdropdown-scroll{
    max-height: 250px;
    overflow: auto;
}

.custom-multiselectdropdown-scroll::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
}
.custom-multiselectdropdown-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.custom-multiselectdropdown-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}

.custom-dropdown-with-chip{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    grid-gap: 0.25em !important;
    gap: 0.25em !important;
    width: 100% !important;
    padding: 0em 0em 0em 1em;
    position: relative;
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
}

.custom-dropdown-insider-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    max-width: 100%;
    /* padding-top: 0.5em; */
}


.custom-dropdown-insider-ele{
    height: 2.25em;
    border-radius: 0.5rem;
    background: rgba(244, 249, 253, 0.80);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin: 0.5em;
    padding: 0.01em 0.5em;
    justify-content: space-between;
    align-items: center;
}

.custom-dropdown-insider-ele span{
    color: #262626;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    white-space: nowrap;
}

.custom-dropdown-insider-ele img{
    width: 0.75em;
    height: 0.75em;
    cursor: pointer;
}

.custom-multiselectdropdown-search-chip{
    /* width: 100%; */
    border-radius: 1em;
    background: #FFF;
    height: 2.8em;
    margin: 0px !important;
    padding: 0em 0.5em 0em 0.5em;
    outline: none;
    border: none;
    margin: 0.5em;
}

.row-justify-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.align-content-center{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

@media screen and (max-width:1000px) {
    .page-back{
        width: 100%;
        display: flex;
        justify-content: start;
    }
}

.main-router-container{
    background-color: #f6fbff;
    min-height: 100vh;
    padding: 105px 25px 20px 140px;
    transition-duration: 1s;
}

.collapsed-router{
    padding: 105px 25px 20px 360px;
    transition-duration: 1s;
}

.no-navbar-router{
    padding: 0px;
}

.page-title-wrapper{
 display: flex;
 flex-direction: row;
 grid-gap: 1em;
 gap: 1em;   
 margin-bottom: 1em;
}

.page-title-wrapper h4{
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 2em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.page-title-wrapper img{
    width: 1.25em;
    cursor: pointer;
}

@media screen and (max-width:960px) {
    .main-router-container{
        padding: 105px 25px 20px 35px;
    }
    .collapsed-router{
        padding: 105px 25px 20px 35px;
    }
}
