.readiness-root{
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}
.readiness-main-container {
    height: calc(100vh - 199px);
}
.readiness-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.75em;
    color: #000;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.readiness-form-lable{
    font-size: 1.15em;
    font-weight: 500;
    color: #262626;
    font-family: Poppins;
    line-height: normal;
    font-style: normal;
    margin: 0px !important;

}
.readiness-form-lable p{
    margin: 0px !important;
}
.readiness-add-btn,.readiness-add-btn:focus,.readiness-add-btn:hover{
    height: 2.5em;
    width: 8em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color: #4CCB1F;
    background-color: #fff;
    border: 1px solid #4CCB1F;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-save-btn,.readiness-save-btn:focus,.readiness-save-btn:hover{
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color:#fff;
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-cancel-btn,.readiness-cancel-btn:focus,.readiness-cancel-btn:hover{
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    color:#8998A2;
    background-color: #fff;
    border: 1px solid #8998A2;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-view-btn,.readiness-view-btn:hover,.readiness-view-btn:focus{
    font-family: 'poppins';
    background: rgba(76, 203, 31, 0.7);
    color:#fff;
    height: 2.5em;
    width: fit-content;
    padding: .5em 1em;
    border-radius: .5em;
    font-size: 1em;
    border: none;
    outline: none;
    cursor: pointer;
}
.readiness-delete-btn,.readiness-delete-btn:hover,.readiness-delete-btn:focus{
    color:#fff;
    height: 2.5em;
    width: 6em;
    font-size: 1.15em;
    font-family: 'Poppins';
    outline: none;
    font-weight: 500;
    background: #E86161;
    border: 1px solid #E86161;
    border-radius: .5em;
    cursor: pointer;
    padding: .5em;
}
.readiness-modal{
    max-width: 900px;
}
.readiness-recieved-date{
color: rgba(137, 152, 162, 1);
font-family: "Poppins";
font-size: .95em;
font-weight: 500;
font-style: normal;
line-height: normal;
}
.readiness-modal .ant-modal-body{
    height: 90vh;
    /* overflow: auto; */
}

.readiness-tooltip .ant-tooltip-inner{
    background-color: #0E0E2C !important;
    color: #FAFCFE !important;
    font-size: .85em !important;
    font-family: "Poppins";
    font-weight:500;
    border-radius: .5em !important;
}
.readiness-tooltip .ant-tooltip-arrow-content{
    background-color: #0E0E2C !important;
}
.readiness-waring-title{
    font-size: 1.15em;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.readiness-link-text{
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    color: #009AF1;
    margin: 0px !important;
}
/* InputRange */

.readiness-zone-btn{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    text-align: center !important;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;
    margin: 0px;
}

.green-readiness-zone{
    background: #4CCB1F33;
    color: #4CCB1F;
}

.orange-readiness-zone{
    background: #F7A11F33;
    color: #F7A11F;
}

.red-readiness-zone{
    background: #CB1F1F33;
    color: #CB1F1F;
}

.purple-readiness-zone{
    background: #7F1FCB33;
    color: #7F1FCB;
}

.blue-readiness-zone{
    background: #1575E633;
    color: #1575E6;
}

@media (max-width:1800px){
    .readiness-page-title {
        font-size: 1.5em;
    }
    .readiness-form-lable{
        font-size: 1.15em;
    }
}

.assessement-space{
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:750px){
    .assessement-space{
        align-items: start;
        flex-direction: column;
    }
}

.readiness-slider .ant-slider-dot{
    display: none !important;
}

.readiness-slider .ant-slider-rail{
    height: 7px !important;
    background: #A9A7A7 !important;
    border-radius: 20px;
}
.readiness-slider .ant-slider-track{
    height: 7px !important;
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important;
    border-radius: 15px;
    /* transition:  0.2s !important; */
}
.readiness-slider .ant-slider-handle{
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important; 
    border: none;
    width: 15px;
    height: 15px;
    /* margin-top: -3px; */
    /* transition: 0.1s !important; */
}

.readiness-slider .ant-slider-mark-text{
    margin-top: 5px !important;
    color: #000000;
    font-family:'Poppins';
    font-size: 1rem !important;
    font-weight: 500;
}