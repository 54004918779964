.pn_pagination .ant-pagination-item {
    display: none;

}
.pn_pagination{
    display: flex;
    align-items: center;
}

.custom-image-pagination:hover{
    filter: none !important;
}

.pagination_parent .ant-pagination-item-active {
background: #4CCB1F !important;
border: unset !important;
}

.pagination_parent .ant-pagination-item-active a {
    color: black;
    margin-top: 1px !important;
}

.pagination_parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select_parent .ant-select-arrow > span {
    color: black !important;

}
.select_parent .ant-btn:hover,.select_parent .ant-btn:focus {
    color: white !important;
    
}

/* pagination for prev and next buttons */

.pn_pagination {
    background-color: #4CCB1F !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4em 2em;
    font-family:'Poppins', sans-serif;
    font-weight: 600;
    color: white;
    border-radius: 9px;
}
.pn_pagination:hover{
    color: white !important;
}


/* results per page section */

.select_parent .ant-select-selection-item {
    margin-right: 5px;
    
}

.select_parent .ant-select-selection-search{
    margin: 3px;
}


