.call-handler-container{
    height: 500px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 0px;
    overflow: auto;
    font-size: 16px;
}

.center-align{
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-handler-container::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.call-handler-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.call-handler-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.default-call-tab{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.handler-info{
    text-align: center;
    font-size: 1em;
    color: #BFBFBF;
}

.handler-icons-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.handler-icons{
    padding: 1em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5em;
    border: 2px solid #4CCB1F;
    cursor: pointer;
   
}

.decline-icons{
    padding: 1em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5em;
    border: 2px solid red;
    cursor: pointer;
}

/* .handler-icons :hover{
    animation: shake 1s;
    animation-iteration-count: infinite;
} */

.handler-icons img, .decline-icons img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* object-fit: contain; */
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.call-state-container{
    width: 100%;
    height: 100%;
    /* background-color: red; */
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.dot-flashing-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 1em;
}

.dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color:  #BFBFBF;
    color:  #BFBFBF;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color:  #BFBFBF;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }

  .call-system-icon-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-bottom: .5em;
  }

  .call-accept-reject{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .call-system-control-icon{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  /* call connected css */

  .call-connected-col-container{
    width: 100%;
    height: 100%;
    position: relative;
  }

  .call-remote-stream{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    transform: rotateY(180deg)
  }

  .call-local-stream{
    height: 25%;
    width: 25%;
    object-fit: cover;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    transform: rotateY(180deg)
  }

  .call-menu-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .exe-menu-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-top:  1px solid #474850;
  }

  .stream-container{
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
  }

  .exe-stream-container{
    /* change 100% to 75% when we add chat */
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    /* background-color: #1C1F2E; */
  }

  .exe-call-remote-stream{
    max-width: 90%;
    height: calc(100% - 150px);
    object-fit: cover;
    transform: rotateY(180deg);
    border-radius: 10px;
  }

  .exe-remote-audio-container{
    height: 100%;
    width: 100%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    background-color: #242736;
    padding-top: 60px;
  }

  .exe-call-local-stream{
    /* width: 100%; */
    height: 30%;
    object-fit: cover;
    position: absolute;
    bottom: 10px;
    right: 10px;
    transform: rotateY(180deg);
    border-radius: 10px;
  }

  .call-end-btn , .call-end-btn:hover , .call-end-btn:focus {
    width: 60px;
    padding: 2px 5px 5px 5px;
    border-radius: 20px;
    background-color: red;
    color: #fff;
    text-align: center;
    font-weight: 500;
    border: 1px solid red;
  }

  .call-connected-exe-container{
    position: fixed !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    background-color: #1C1F2E;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .call-connected-exe-container .call-remote-stream{
    object-fit: contain;
  }

  .call-connected-exe-container .call-local-stream{
    object-fit: contain;
    /* height: 35; */
  }

  .missed-call-contianer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .missed-call-contianer img{
    width: 20px;
    height: 20px;
  }

  .clamp-to-3line{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .expanded-menu-button{
    background-color: #242736;
    border: 1px solid #474850;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .expanded-menu-button img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .call-exp-header{
    height: 60px;
    width: 100%;
    position: fixed;
    background-color: #242736;
    border-bottom:  1px solid #474850;
    z-index: 110000;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .call-header-text{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-align: center;
    margin: 12px 5px 10px 5px;
    text-overflow: ellipsis;
  }

  .call-map-logo{
    height: 40px;
  }

  .ant-tooltip-placement-top{
    z-index: 100000;
  }

  .remote-audio-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    background-color: #242736;
  }

  .local-audio-container{
    height: 25%;
    width: 25%;
    max-width: 200px;
    max-height: 200px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;

  }

  .local-audio-sub-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius : 8px;
    border-top-right-radius : 8px;
    background-color: #474850;
    gap: 5px;
  }

  .remote-avatar{
    border-radius: 50%;
    background-color: #474850;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remote-avatar img{
    object-fit: contain;
    width: 80%;
    height: 80%;
  }

  .local-avatar{
    border-radius: 50%;
    background-color: #242736;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .local-avatar img{
    object-fit: contain;
    width: 85%;
    height: 85%;
  }

  .call-user-name{
    text-align: left;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    margin: 5px;
  }

  .call-reconnection{
    /* height: 100%; */
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 50px;
    background-color: rgb(78, 74, 74);
    opacity: .7;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  .reconnecting-text{
    font-size: 1.2em;
    color: #fff;
    font-weight: 500;
  }

  .call-info-title{
    text-align: center;
    font-size: 1.4em;
  }

  .call-info-tip{
    padding: 1em 1.5em;
  }

  .call-li-point{
    font-size: 1.1em !important;
  }

  .call-info-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .call-confirmation,.call-confirmation:hover{
    background: #4CCB1F;
    border-radius: 8px;
    width: 100px;
    height: 38px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: .9em;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin: 5px;
  }