.one-one-one-home-view{
    font-size: 16px !important;
}

.one-on-one-left-section{
    width:72% !important;
}
.one-on-one-right-section{
    width:28% !important;
}
@media (max-width:1150px) {
    .one-on-one-left-section{
        width:100% !important;
    }
    .one-on-one-right-section{
        width:100% !important;
    }
}
.meeting-create-selction .ant-form-item-control-input-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.meeting-create-selction .ant-form-item{
    border: none !important;
}
.one-on-one-home-container{
    padding: 10px 5px ;
}
.one-on-one-heading-seaction{
    position: relative;
    margin: 0 0.6em 0 0.6em;
}
.back-icon-one-on-one{
    position: absolute;
    left: 0;
}

.back-icon-summary
{
    width: 13px;
    position: absolute;
    left: 30px;
}

.delete-modal-meeting .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
}

.one-on-one-feedback-form-title div p{
    width: 450px;    
    margin-bottom: 6px;
}

.agenda-list-container .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    cursor:default;
}

.one-on-one-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 3.75em;
    letter-spacing: -0.02em;
    color: #141414;
    margin-bottom: 10px;
}
.greeting-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 2.25em;
    letter-spacing: -0.02em;
    color: #141414;
    margin: 0;
}

.agenda-list-container .ant-collapse-content-box{
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;  
}




.past-meeting-data-container  .anticon-caret-down{
    color: #009AF1;
}

.meeting-note-dropdown-menu{
    border-radius: 8px;
    min-width: 290px;
}

.meeting-note-dropdown-menu-item{
    text-align: center;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.greeting-paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.375em;
    line-height: 2em;
    color: #141414;
    margin-top: -8px;
}

.schedule-one-on-one-container .ant-picker-input > input{
  font-family: poppins;
  padding-left: 10px;
}

.schedule-one-on-one-container .ant-picker-input{
    flex-direction: row-reverse;
}

.instant-btn{
    width: 142px;
    height: 48px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}



.notes-select-box input{
    position: absolute !important;
    top: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
    left: 0px !important;
    font-size: 14px !important;
    text-align: left;
    height: 100% !important;
  }

  .notes-select-box{
    min-width: 200px !important;
  }
  .notes-select-box .ant-select-selection-placeholder {
    text-align: left !important;
    flex: none;
    font-size: 14px;
  }

.instant-btn:hover{
    filter: brightness(1.13);
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}
.instant-btn:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}
.schedule-btn{
    width: 156px;
    height: 48px;
    background: #EFEFFD;
    /* box-shadow: inset 0px -1px 0.5px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: 1px solid #EFEFFD;
}
.schedule-btn:hover{
    filter: brightness(1.04);
    color: #4CCB1F;
    background: #EFEFFD;
    border-color: #EFEFFD;
}
.schedule-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border-color: #EFEFFD;
}
.one-on-one-tab .ant-tabs-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #BFBFBF;
}
.one-on-one-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4CCB1F;
    text-shadow:none;
}
.one-on-one-tab .ant-tabs-ink-bar{
    background: #4CCB1F !important;
    height: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.one-on-one-note-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#31D0AA !important;
    text-shadow:none;
}
.one-on-one-note-tab .ant-tabs-ink-bar{
    background: #31D0AA !important;
    height: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.one-on-one-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    position: relative;
    margin-top: -16px !important;
    overflow: hidden;
    padding-right: 10px;
    min-height: 485px;
    max-height:fit-content;

  }
.one-on-one-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.one-on-one-meeting-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #FFFF !important;
    border-radius: 8px;
    border: 1px solid #FFFFFF !important;
    position: relative;
    margin-top: -16px !important;
    overflow: hidden;
    padding-right: 10px;
    min-height: 200px !important;
    max-height: 100% !important;

  }
.meeting-overflow::-webkit-scrollbar {
    height: 100%;
    display: none;
    width:5px;
  }
.meeting-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
padding-right: 10px;
margin-bottom:10px;
margin-top:10px
}

.meeting-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color:#D9D9D9;
}

.agenda-create-overflow::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.agenda-create-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.agenda-create-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-custom-ck::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.one-on-one-custom-ck::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.one-on-one-custom-ck::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-menu {
    background: #F5F5F5;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;
}
.one-on-one-menu .ant-dropdown-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #595959;
    text-align: center;
    padding-bottom: 10px;
}
.input-filter-one-on-one{
    background: #FFFFFF;
    border: "1px solid #D9D9D9";
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height:46px !important;
}
.one-one-filter-menu .ant-dropdown-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #BBBBBB;
    padding-bottom: 10px;
}

.one-on-one-selected-filter{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4CCB1F;
}



.one-one-filter-menu .ant-dropdown-menu-item-active{
    color: #141414;
}


.one-on-one-drop-dtn:hover{
    background: #FFFFFF;
    border: 1px solid #D9D9D9
}
.one-on-one-drop-dtn:focus{
    background: #FFFFFF;
    border: 1px solid #D9D9D9
}

/************************************/
        /* ONE ON ONE CARDS CSS */
/************************************/

.one-on-one-card-div{
    background: #FFFFFF;
    /* box-shadow: -6px 2px 14px -7px rgba(0, 0, 0, 0.09); */
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    margin: 10px;
    position: relative;
    /* min-width: 340px; */
}
.one-on-one-card-div:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.one-on-one-card-div .bookmark-disable{
    position: absolute;
    right: 10px;
    top: 10px;
}
.one-on-one-card-div .edit-one-on-one{
    position: absolute;
    right: 10px;
    top: 11px;
    display: none;
}
.one-on-one-card-div:hover .edit-one-on-one{
    display: block;
}
.one-on-one-card-div .card-data-container{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.view-meeting-icon{
    display: block;
}

.view-meeting-icon-hovered{
 display: none;
}

.one-on-one-card-div:hover .view-meeting-icon{
    display: none;
}

.one-on-one-card-div:hover .view-meeting-icon-hovered{
    display: block;
}
 .one-on-one-date-section{
    border-right:0.5px solid #cfcfcf;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-right: 10px;
    height: 95px;
    gap: 0;
}
.one-on-one-date-section .one-on-one-date{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #141414;
    white-space: nowrap;
}
.one-on-one-date-section .one-on-one-time{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #009AF1;
    margin: 0;
    white-space: nowrap;
}
.one-on-one-card-div .one-on-one-title{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    text-align: left;
    color: #141414;
    line-break: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}               
.one-on-one-card-div .one-on-one-start-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 11.8962px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: .5px solid #4CCB1F;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.one-on-one-card-div .one-on-one-start-btn:hover{
    color: #fff;
    border: .5px solid #4CCB1F;
    background-color: #4CCB1F;
}
.one-on-one-card-div .agenda-button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #8C8CA2;
    cursor: pointer;
    padding-top: 4px;
    white-space: nowrap;
}
.one-on-one-card-div .agenda-button:hover{
    color: #B0B0D2;
}
.one-on-one-card-div .agenda-button:hover img{
    color: #B0B0D2;
}
.one-on-one-card-div .user-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #141414;
    white-space: nowrap;
    margin: 0;
}
.one-on-one-card-div .user-name span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.one-on-one-card-div .button-section{
    margin-top: 20px !important;
}
.meeting-user-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* align-items: center; */
    color: #141414;
}

.one-on-one-bookmark{
    background: #FFFFFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding: 15px 20px;
    /* height: 250px;
    margin-top: -190px; */
    height: 100%;
    position: relative;
}
.one-on-one-bookmark .link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    padding-top: 5px;
    cursor: pointer;
}
/************************************/
        /* ONE ON ONE  QUICK LINK CSS */
/************************************/

.one-on-one-quick-link-container{
    margin-top: 10px;
    background: #FFFFFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;
    padding: 22px 20px;
}
.one-on-one-quick-link-container .link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
}
.one-on-one-quick-link-container .quick-link-button{
    padding: 15px 28px;
    background: #ECF1F4;
    border-radius: 8px;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content:start;
    align-items: start;
    gap: 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.one-on-one-quick-link-container .quick-link-button:hover{
    background: #EEF7FE;
}
.one-on-one-quick-link-container .quick-link-button:hover .quick-link-title{
    color: #161673;
}
.one-on-one-quick-link-container .quick-link-button .quick-link-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E0E2C;
    margin: 0;
    white-space: nowrap;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.one-on-one-quick-link-container .quick-link-button .quick-link-icon{
    margin-top: 6px;
}


.one-on-one-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141414;
    margin: 0;
}
.one-on-one-agenda-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #0E0E2C;
    margin: 0;
    cursor: default;
    line-break: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.agend-list-div{
    background: #F5F5F5;
    border-radius: 8px;
    padding: 10px 15px;
    width: 100%;
}
.agenda-list-container{
    padding: 10px 50px;
}

.overflow-feedbacks{
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 10px;
} 

.one-on-one-note-tab .ant-tabs-tabpane-active{
    position: relative;
    top: 20px;
    margin-bottom: 30px;
}

.overflow-feedbacks::-webkit-scrollbar {
    height: 100%;
    width:5px;
    display: none;
  }
  .overflow-feedbacks::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.overflow-feedbacks::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}


.overflow-feedbacks .ant-collapse-borderless > .ant-collapse-item:first{

    margin-top: 0px !important;
}


.agenda-list-container svg{
    color: #4CCB1F;
    font-size: 15px;
    cursor: pointer;
    margin-top: 0px;
}

.meeting-filter-btn{
    border-radius: 35px;
    width: fit-content;
    margin: 10px;
    padding: 6px 17px;
    border: 1px solid #31D0AA;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #31D0AA;
    height: 100%;
    background: #F5F5F5;
    white-space: nowrap;
}
.meeting-filter-btn:hover{
    background: #31D0AA;
    border:1px solid #31D0AA;
    color: #ffff;
}
.meeting-filter-btn:focus{
    background: #31D0AA;
    border:1px solid #31D0AA;
    color: #ffff;
}
.meeting_date_icon_inactive{
    display: block;
}
.meeting_date_icon_active{
    display: none;
}
.meeting-filter-btn:hover .meeting_date_icon_active{
    display: block;
}
.meeting-filter-btn:hover .meeting_date_icon_inactive{
    display: none;
}
.meeting-filter-btn:focus .meeting_date_icon_active{
    display: block;
}
.meeting-filter-btn:focus .meeting_date_icon_inactive{
    display: none;
}
.instant-meeting-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #141414;
}
.instant-mmeting-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141414;
}
.one-on-one-custom-time-picker .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-radius: 8px !important;
    height: 40px !important;
    
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-search-input{
    height: 40px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #d0d0d0 !important;
    padding-left: 0px !important;
    padding-top: 4.5px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 40px !important;
}
.one-on-one-custom-time-picker .ant-select-selector .ant-select-selection-item{
    height: 40px !important;
    padding-top: 4.5px !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-radius: 8px !important;
    height: 52px !important;
    
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input{
    height: 52px !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input::placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 52px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #303030 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search{
    left: 35px;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-placeholder{
    padding: 8px 10px 8px 25px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    padding-left: 35px;
    color: #D9D9D9 !important;
}
.one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-item{
    padding: 8px 10px 8px 25px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #303030 !important;
}
.one-on-one-meeting-title-input{
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #303030 !important;
    padding-left: 35px;
}
.one-on-one-meeting-title-input::placeholder{
    color: #D9D9D9 !important;
}
.instant-form-icon{
    top:13px !important;
}
.meeting-instant-popup{
    padding: 30px;
}
.meeting-instant-popup-inner{
    padding: 10px 40px;
}
.instant-start-btn{
    margin-top: 50px;
    height: 48px;
    width: 120px !important;
    background: #4CCB1F;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.instant-start-btn:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.instant-start-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}
.one-on-one-select-employee-dropdown-item{
    font-family: 'Poppins';
    font-weight: 500;
}
.custom-meeting-picker{
    margin-top: -35px;
    padding: 0px;
    font-family: 'Poppins';
    font-size: 12px;
    max-width: 440px;
    z-index: 1160 !important;
}
.custom-meeting-picker .ant-picker-panel-container{
    box-shadow:none;
    border-radius: none;
    border: none;   
    /* margin-left: 27px !important; */
}
.custom-meeting-picker .ant-picker-range-arrow{
    display: none;
}
.custom-meeting-picker .ant-picker-range-wrapper{
    /* min-width: 300px; */
    /* max-width: 540px; */
}
.custom-meeting-picker .ant-picker-date-panel .ant-picker-content{
    width:200px !important;
}
.custom-meeting-picker .ant-picker-cell {
    padding: 0px;
}
.custom-meeting-picker .ant-picker-date-panel .ant-picker-content th {
    width:fit-content;
}
.custom-meeting-picker .ant-picker-date-panel{
    width:fit-content;
}
.date-range-meeting{
    /* width: 98%; */
    /* margin: 0px 50px; */
}
.date-range-meeting input{
    z-index: 1100 !important;
}
.cancel-date-btn{
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    padding: 8px 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
    cursor: pointer;
}
.cancel-date-btn:hover{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.cancel-date-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.save-date-btn{
    height: 40px;
    width: 100px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    padding: 8px 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    cursor: pointer;
}
.save-date-btn:hover{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.save-date-btn:focus{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.notes-container{
  position:relative;
  left:22px;
}

.scheduleNext-link{
    color: #BFBFBF;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    margin-top: 15px;
}

.scheduleNext-link:hover{
    color: #4CCB1F;
    transition: transform .5s ease;
    transform: scale(1.1) ;
}



.scheduleNext-link:hover + .nextmeeting-link-arrow{
    color: #4CCB1F;
    transition: transform .5s ease;
    transform: scale(1.1) ;
}



/*********************************************/
/****************START MEETING****************/
/*********************************************/

.one-on-one-time-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #009AF1;
    margin: 0;
    margin-top: -10px;
}

.past-meeting-container{
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 570px;
    height: 100%;
    /* max-height: 100px; */
    transition: all .5s ease;
    padding: 15px 20px;
    margin: 30px 15px;
}

.past-meeting-container-expand{
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    height: 100%;
    width:100%;
    transition: all 0.8s ease;
    padding: 15px 20px 25px 20px;
}

.previous-meeting-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #141414;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
}
.previous-meeting-date{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    margin: 0;
}
.expand-meeting-arrow-up{
    transform: rotate(360deg);
    transition: all .5s ease;
}
.expand-arrow-left{
    transform: rotate(270deg);
    transition: all .5s ease;
}
.meeting-tool-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
    margin: 0;
}


.meeting-soultip-container .ant-spin-nested-loading{
     width: 25%;
     position:absolute;
     z-index: 500;
     margin-right: 20px;
     right: 0px;   
     top:-60px;
     background: #FFFFFF;
     box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
}
.soultip-overflow{
    max-height: 90px !important;
}
.soultip-height{
    height: 100% !important;
    max-height: 250px !important;
}
.soultip-overflow::-webkit-scrollbar {
    height: 100%;
    width:3px;
  }
.soultip-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.soultip-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.soultip-inner{
    top: 0px;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots{
    margin-left: 0px !important;
}
.soul-tip-one-on-one-tool .ant-carousel .slick-dots-bottom{
    bottom:12px;
    height: 10px;
    margin-right: 20px;
  }
    
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li button{
    background: #D9D9D9;
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
  
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active{
    width: 17px;
  }
  
.soul-tip-one-on-one-tool .ant-carousel .slick-dots li.slick-active button{
    background: #4CCB1F;
  }
  .soul-tip-one-on-one-tool .ant-carousel .slick-dots li button:before{
    color: #ffff;
    visibility: hidden;
  }
.meeting-tool-title-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-align: center;
    margin: 0;
}
.meeting-tool-container{
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
   
}
.meeting-details-container{
    background: #F5F5F5;
    border-radius: 8px;
    padding: 30px 40px;
}
.current-one-on-one-meeting-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
    text-align: left;
}

.current-one-on-one-meeting-head span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #141414;
}
.past-meeting-data-container{
    background: #FFFFFF;
    /* border: 1px solid #F0F0F0; */
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px;
}

.meeting-notes-scroll::-webkit-scrollbar-thumb {
     margin-top: 10px; 
     columns: red;
  }


  .meeting-notes-scroll::-webkit-scrollbar {
    margin-top: 10px; 
  }
  .meeting-notes-scroll::-webkit-scrollbar-track {
    margin-top: 21px !important; 
}


.notes-container-card .ant-select-selector{
    text-align: left;
}

.current-meeting-data-container{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    width: 100%;
    height: 400px;
    padding: 15px; 
}

.transcript-link{
    color: #8C8CA1;
    font-size: 12px;
}

.pt-80{
    padding-top: 80px;
}

.pt-63{
    padding-top: 63px;
}

.pb-70{
    padding-bottom: 70px;
}

.pt-70{
    padding-top: 70px;
}



.pb-85{
    padding-bottom: 85px;
}




.past-meeting-data-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
}
.past-meeting-data-overflow{
    height: 100%;
    max-height: 500px;
    overflow: auto;
}
.past-meeting-data-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .past-meeting-data-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  

  .notes-container-card .ant-select-selector{
     text-align: left;
  }


  .past-meeting-data-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }
  .meeting-detail-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #0E0E2C;
    margin-bottom: 0px !important;
  }


  .past-meeting-data-overflow p{
    margin-bottom: 0px;
    /* padding-top: 10px; */
    padding: 10px 7px 0px 3px;
  }


  .previous-meeting-filter-date-picker{
    border:none;
    border-radius: none;
    background: #F5F5F5;
    width: 130px;
    cursor: pointer;
    border-color: #F5F5F5 !important;
    box-shadow:none !important;
    padding: 4px 0px !important;
    display: flex !important;
    align-items: start !important;
    justify-content: start !important;
  }
  .previous-meeting-filter-date-picker:focus{
    border: none;
    box-shadow: none;
    border-color: #F5F5F5;
  }
  .previous-meeting-filter-date-picker .ant-picker-input input{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align:left;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #009AF1;
    cursor: pointer;
  }

  .one-on-one-meeting-items-add-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    text-align: center;
    border: 1px solid #4CCB1F;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .one-on-one-meeting-items-add-btn:hover{
    border: 1px solid #4CCB1F;
    color: #FFF;
    background: #4CCB1F;
  }
  .one-on-one-meeting-items-add-btn:focus{
    border: 1px solid #4CCB1F;
    color: #FFF;
    background: #4CCB1F;
  }
  .add-agenda-container-section{
    /* border: 1px solid #4CCB1F; */
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    /* height: 230px; */
    /* overflow: auto; */
  }
  .one-on-one-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}
.one-on-one-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}
.one-on-one-ck-editor .cke_bottom{
display: none;
}
.one-on-one-ck-editor .cke_reset{
    padding: 0px 0px;
}
.one-on-one-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.one-on-one-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.one-on-one-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.one-on-one-check .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009AF1;
    border-color:#009AF1;
    width: 16px;
    height: 16px;
}
.one-on-one-check:hover{
    border-color:#009AF1; 
}
.one-on-one-check:focus{
    border-color:#009AF1; 
}
.one-on-one-input-field-style{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #595959;
}
.one-on-one-input-field-style::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9;
}
.one-on-one-check-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #595959;
}
.one-on-one-okr-selection-drop{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    max-width: 500px !important;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #595959;
}
.action-item-marckdown{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: left;
    max-height: 200px;
    overflow: auto;
    color: #0E0E2C;
    }
.action-item-markdown-heades{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #141414;
    margin: 0px;
}
.one-on-one-bo-back-btn{
    width: 126px;
    height: 48px;
    background: #EFEFFD;
    /* box-shadow: inset 0px -1px 0.5px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4CCB1F;
    border: 1px solid #EFEFFD;
}
.one-on-one-bo-back-btn:hover{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.one-on-one-bo-back-btn:focus{
    color: #4CCB1F;
    background: #EFEFFD;
    border: 1px solid #EFEFFD;
}
.one-on-one-save-btn{
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    width: 126px;
    height: 48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn:hover{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn:focus{
    color: #FFFFFF;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}
.one-on-one-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    line-height: 140%;
    text-align:left;
    margin-bottom: 10px;
    color: #141414;
}



.schedule-one-on-one-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: 0px 15px;
}

.schedule-one-on-one-container{
    padding: 30px 100px !important;
}
.one-on-one-input-field-style input {
    height: 40px;
    width: 100px;
    border-radius: 8px;
    border:1px solid #D9D9D9;
}
.one-on-one-input-field-style input:hover{
    border:1px solid #D9D9D9;
    box-shadow: none;
}
.one-on-one-input-field-style input:focus{
    border:1px solid #D9D9D9;
    box-shadow: none;
}
.agend-list-div-hover-action:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.one-on-one-meeting-popup-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #141414;
}
.one-on-one-meeting-popup-para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}
.one-on-one-save-btn-modal{
    padding: 8px 24px;
    height: 40px;
    width: 116px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin-top: 10px;
}
.one-on-one-save-btn-modal:hover{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-save-btn-modal:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.one-on-one-cancel-btn-modal{
    padding: 8px 24px;
    height: 40px;
    width: 116px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
    border-radius: 8px;
    margin-top: 10px;
}
.one-on-one-cancel-btn-modal:hover{
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}
.one-on-one-cancel-btn-modal:focus{
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}

.inactive-rating-star-count{
    display: none;
}
.active-rating-star{
    display: none;
}
.active-rating-star-count{
    display: block;
}
.meeting-rating-text-area{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #303030;
    padding: 10px;
    outline: none;
}
.meeting-rating-text-area:focus{
    outline: none;
}
.meeting-rating-text-area::placeholder{
    /* background: url('../../assets/dislike.svg'); */
    background-repeat: no-repeat;
    background-position-x:  5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #D9D9D9
}
.one-on-one-feedback-form-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
}
.one-on-one-feedback-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #141414;
    border-radius: 8px;
    border: 1px solid #141414;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn:hover{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn:focus{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-focus{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn-focus:hover{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-focus:focus{
    border: 1px solid #141414;
    background-color: #141414;
    color: #fff;
}
.one-on-one-feedback-tab-btn-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}
.one-on-one-feedback-tab-btn-active:hover{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}
.one-on-one-feedback-tab-btn-active:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}
.new-tag-disable{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: #BFBFBF;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 3px;
    margin: 0;
    position: absolute;
    right: -55px;
    top:0px
}
.transcript-tab-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #BFBFBF;
    margin: 0;
}
.transcript-tab-head-active{
    color: #31D0AA;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin: 0;
}
.new-tag-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: #31D0AA;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 3px;
    margin: 0;
    position: absolute;
    right: -55px;
    top:0px
}
.load-mode-title{
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #303030;
    margin-bottom: -10px;
    margin-top: 10px;
}
.load-mode-title:hover{
    color:#4CCB1F;
}

.meeting-notes-dropdown{
    width: 300px !important;
    cursor: pointer;
}

.meeting-notes-dropdown-prev-meeting{
    width: 250px !important;
    cursor: pointer;
}


.collapse-right-outlined-icon{
    padding: 9px 0px !important;
}


@media (max-width:1600px) {
    .one-one-one-home-view{
        font-size: 14px !important;
    }
    .transcript-tab-head{
        font-size: 14px;
    }
    .transcript-tab-head-active{
        font-size: 14px;
    }
    .one-on-one-feedback-form-title{
        font-size: 14px;
    }
    .meeting-user-name{
        font-size: 18px;
        line-height: 32px;
    }
    .action-item-markdown-heades{
        font-size: 8.5px;
    }
    .action-item-marckdown{
        font-size: 12px;
    }
    .one-on-one-head{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
    } 
    .one-on-one-tab .ant-tabs-tab-btn{
        font-size: 14px;
    }

    .one-on-one-menu .ant-dropdown-menu-item{
        font-size: 13px
    }
    .one-one-filter-menu .ant-dropdown-menu-item{
        font-size: 14px;
    }
    .one-on-one-selected-filter{
        font-size: 15px;
    }

    .notes-select-box{
        min-width:180px !important;
        font-size: 13px !important;
      }
   
    /************************************/
        /* ONE ON ONE CARDS CSS */
    /************************************/
    .one-on-one-date-section .one-on-one-date{
        font-size: 17px;
        line-height: 21px
    }
    .one-on-one-date-section .one-on-one-time{
        font-size: 11px;
    }
    .one-on-one-card-div .one-on-one-title{
        font-size: 17px;
        line-height: 25px;
    }

    /************************************/
        /* ONE ON ONE  QUICK LINK CSS */
    /************************************/

    .one-on-one-quick-link-container .quick-link-button .quick-link-title{
        font-size: 15px;
    }
    .one-on-one-quick-link-container .quick-link-button{
        height: 58px;
    }

    .one-on-one-title-modal{
        font-size: 18px;
    }
    .one-on-one-agenda-title{
        font-size: 14px;
    }
    .agenda-list-container{
        padding: 10px 20px;
    }
    .meeting-filter-btn{
        font-size: 14px;
    }
    .instant-meeting-head{
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #141414;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector{
        height: 44px !important;
        
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search-input{
        height: 44px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-placeholder{
        padding: 8px 10px 4px 25px !important;
        font-size: 14px !important;
        line-height: 28px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
        height: 44px !important;
        font-size: 13px !important;
    }
    .one-on-one-select-employee-dropdown .ant-select-selector .ant-select-selection-item{
        padding: 8px 10px 4px 25px !important;
        font-size: 14px !important;
        line-height: 28px !important;
    }
    .one-on-one-meeting-title-input{
        height: 44px !important;
        font-size: 13px !important;
        line-height: 28px !important;
    }
    .instant-form-icon{
        top:10px !important;
        left: 17px !important;
    }

    .instant-meeting-form-icon{
        top:10px;
        left: 13px !important;
    }

    .edit-series-meeting-container .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background: #FFFFFF;
    }

    .meeting-instant-popup{
        padding: 20px;
    }
    .meeting-instant-popup-inner{
        padding: 10px 30px;
    }
    .instant-start-btn{
        font-size: 14px;
    }
    /*********************************************/
    /****************START MEETING****************/
    /*********************************************/

.one-on-one-time-title{
    font-size: 20px;
    margin-top: -13px;
}
.past-meeting-container{
    width: 30%;
    margin: auto;
    height: 100%;
}

.previous-meeting-title{
    font-size: 20px;
    line-height: 30px;
}
.previous-meeting-date{
    font-size: 14px;
    line-height: 22px;
}
.previous-meeting-filter-date-picker{
    width: 115px;
}
.previous-meeting-filter-date-picker .ant-picker-input input{
    font-size: 14px;
    line-height: 22px;
  }
.meeting-tool-title{
 
    font-size: 20px;
    line-height: 30px;
}
.meeting-details-container{
    padding: 20px 20px;
}
.current-one-on-one-meeting-head{
    font-size: 17px;
}

.current-one-on-one-meeting-head span{
    font-size: 17px;
}
.past-meeting-data-container{
    /* padding: 10px; */
}
.past-meeting-data-title{
    font-size: 14px;
    /* margin-bottom: 30px; */
}

.pt-50{
    padding-top: 50px;
}


.pt-100{
    padding-top: 100px;
}

.pt-43{
    padding-top: 43px;
}


.meeting-detail-para{
    font-size: 13px;
    
  }
  .one-on-one-form-title{
    font-size: 14px;
}
.schedule-one-on-one-container{
    padding: 30px 80px !important;
}
.one-on-one-meeting-popup-head{
    font-size: 20px;
}
.one-on-one-meeting-popup-para{
    font-size: 14px;
}
.new-tag-disable{
    font-size: 10px;
    padding: 2px 3px;
    line-height: 14px;
    right: -35px;
    top:2px
}
.new-tag-active{
    font-size: 10px;
    padding: 2px 3px;
    line-height: 14px;
    right: -35px;
    top:2px
}
.soultip-overflow{
   max-height: 90px !important;
}
.soultip-height{
    max-height: 155px !important;
}
.soultip-inner{
    top: -20px;
}
}

@media (min-width:1366px){
    .previous-meeting-card-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media (min-width:600px)  and (max-width:1366px) and (orientation:landscape) {

    .previous-meeting-card-wrapper{
        padding-left: 13px;
        padding-right: 13px;
    }

}



@media (max-width:1200px) {
    .schedule-one-on-one-container{
        padding: 10px 20px !important;
    }

    .past-meeting-container{
        width: 40%;
        margin: auto;
    }
    
  
}



@media (max-width:600px) {
    .schedule-one-on-one-container{
        padding: 10px 20px !important;
    }


    .notes-container{
        left: 0px;
    }

    .notes-select-box{
        min-width:120px !important;
        font-size: 13px !important;
      }

    .past-meeting-container{
        width: 70%;
        margin: auto;
    }
    
  
}






@media (max-width:320px) {

    .past-meeting-container{
        width: 330px;
    }

    .previous-meeting-title{
        font-size: 15px;
    }

}
@media (max-width:992px) {
    .greeting-content-section{
      align-items: center !important;
    }
    .greeting-content-section{
        align-items: center;
    }
    .metting-button-section{
        margin-left: 0px !important;
        flex-wrap:wrap !important;
    }
    .meeting-instant-popup{
        padding: 15px;
    }
    .meeting-instant-popup-inner{
        padding: 10px 25px;
    }
    .schedule-one-on-one-container{
        padding: 20px 50px !important;
    }
}

@media (min-height:800px){
    .past-meeting-data-container{
        /* min-height: 400px; */
    }
    .meeting-overflow{
        max-height: 420px !important;
    }
}
@media (min-height:920px){
    .past-meeting-data-container{
        /* min-height: 420px; */
    }
    .meeting-overflow{
        max-height: 500px !important;
    }
}

@media screen and (min-width:950px) {
    

    .greeting-content-section{
        margin-left: 30px;
      }


    .one-on-one-left-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
        margin-left: 30px;
      }
  
}

@media screen and (min-width:730px) and (max-width:1200px){

    .notes-container{
        left: 0px;
    }

    .notes-select-box{
        min-width: 110px !important;
    }

}



@media screen and (min-width:1390px){

    .previous-meeting-card-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }
}



@media screen and (min-width:2200px){

    .previous-meeting-card-wrapper{
        padding-left: 40px;
        padding-right: 40px;
    }


}

.transcript-scroll{
    height: 400px;
    margin-bottom: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 3px;
}

.transcript-msg-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
}

.transcript-profile-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    /* align-items: center; */
    width: 100%;
}

.transcript-img-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 1px solid #4CCB1F;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.transcript-img-container-noborder{
    border: 1px solid transparent;
}

.transcript-pro-img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
}

.transcript-pro-char{
    margin: 0px !important;
    font-size: 16px;
    font-weight: 500;
}

.transcript-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.transcript-text{
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    word-break:normal;
    margin-top: -10px;
}

.transcript-date{
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #c3c3c3;
}

.transcript-scroll::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.transcript-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}
.transcript-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}

.transcript-rcd-btn{
    background: #4CCB1F;
    border-radius: 5px;
    min-width: 80px;
    width: fit-content;
    height: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
}

.transcript-rcd-btn:hover,.transcript-rcd-btn:focus{
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.transcription-data-aligner{
    display: flex;
    flex-direction: column;
    gap: 0.15em;
    width: 100%;
}

.transcript-user-name{
    margin: 0px;
    text-align: left;
}

.transcription-empty{
    height: 98%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.transcription-empty .transcription-icon{
    width: 80px;
    height: 80px;
}

.transcription-nodata{
    text-align: center;
    max-width: 80%;
    font-size: 1.1em;
    color: #BFBFBF;
}

.souptip-container{
    display: none;
    max-height: 175px;
    height:175px;
    overflow: auto; 
}


.souptip-container::-webkit-scrollbar {
    display: none;
}



@media screen and (min-width:1024px) {
   
    .souptip-container{
       display: flex;
    }

}

@media screen and (min-width:1600px) {
   
    .souptip-container{
       max-height: 160px;
       display: flex;
    }

}

.pt-33{
    padding-top: 33px;
}

.pt-75{
    padding-top: 75px;
}

.summary-arrow{
    transform: rotate(180deg);
}
.one-on-one-bottom-div{
    position: fixed;
    left: 7.2em;
    right: 0;
    bottom: 0;
    width:100%; 
    z-index:999;
    display:flex;
    flex-direction:row; gap:20px;
    justify-content:center;
    align-items: center;
    padding: 20px 10px;
    background: #FFF;
    box-shadow: -6px 2px 72px -11px rgba(0, 0, 0, 0.09);
    transition-duration: 1.2s;
    padding-right: 8em;
}
.one-on-one-div-margin-left{
    margin-left: 249px;
    transition-duration: 1s;
    padding-right: 25em;
}
@media (max-width:960px) {
    .one-on-one-bottom-div{
        left:0em;
        padding-right: 0em;
    }
    .one-on-one-div-margin-left{
    margin-left: 0px;
    padding-right: 0em;
    }
}