.define-bars-root {
    font-size: 16px;
}


.bars-drop-down-button {
    border-radius: 1em;
    border: 0.062em solid #AABAC6;
    background: #FFF;
    height: 3.2em;
    padding-left: 2em;

}

.define-bars-drop-down-container {
    border-radius: 1em;
    border: 0.062em solid #ECF1F4;
    background: #F4F9FD;
    padding: 3em 4em 3em 4em;
    margin: auto;
}

.define-bars-header {
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 2.27em;
    font-style: normal;
    font-weight: 500;
    line-height: 2.87em;
    /* 100% */
}

.define-bars-header span {
    font-weight: 700;
}

.define-bars-competency-header {
    color: #262626;
    text-align: left;
    font-family: Poppins;
    font-size: 1.16em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.37em;
    margin-bottom: 0.8em;

}

.define-bars-dropdown {
    top: 28.4em !important;
    max-height: 20em;
    overflow: auto;
}

.define-bars-overlay-container {
    width: 60%;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu {
    background: #ECF1F4;

}

.define-bars-overlay-container .ant-dropdown-menu-submenu-title {
    height: 3.5em;
    color: #000;
    font-family: Poppins;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
}

/* .define-bars-overlay-container .ant-dropdown-menu-item:hover, */
.define-bars-overlay-container .ant-dropdown-menu-submenu-title:hover {
    background: #ECF1F4;
}

.define-bars-overlay-container {
    background-color: #ECF1F4 !important;
    padding: 0px;
}

.define-bars-dropdown{
   width:60% !important;
}

.define-bars-overlay-container .ant-dropdown-menu-item:hover{
    border: 0.062 solid #4CCB1F;
}

.define-bars-overlay-container .ant-dropdown-menu-item,
.define-bars-overlay-container .ant-dropdown-menu-submenu-title {
    padding: 0.90em 0.75em;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu-title:hover{
    border: 1px solid #4CCB1F;
}

.define-bars-selected-row div{
    background: #4CCB1F !important;
    color: #FFF;
}

.define-bars-selected-row .ant-dropdown-menu-submenu-title{
    color: #FFF;
}

.define-bars-overlay-container .ant-dropdown-menu-item-only-child{
    width: 40%;
}

.define-bars-submenu {
    width: 32em;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: poppins;
}

.define-bars-submenu:hover{
    background: #ECF1F4;
}

.define-bars-overlay-container .ant-dropdown-menu-submenu-popup{
    top: 366px;
    background: #ECF1F4;
}

.ant-dropdown-menu-submenu-popup{
    padding: 0px;
}


.ant-dropdown-menu-submenu-popup ul{
    padding: 0px;
    background: #ECF1F4;
}


.define-bars-dropdown .ant-dropdown-menu-submenu-expand-icon{
    width: 0.6em;
    position: absolute;
    right: 1.125em;
    top: 1.2em;
 }

 .define-bars-selected-row .ant-dropdown-menu-submenu-expand-icon{
    filter:brightness(0) invert(1);
 }

 .define-bar-back-arrow{
    position: relative;
    left: 5em;
    top: 4em;
 }

 .define-bars-selector{
    width: 100%;
    border-radius: 0.8em;
 }

 /* .define-bars-drop-down-container */
 .define-bars-selector
  .ant-select-selector{
    height: 3.5em !important;
    border-radius: 0.8em !important;
 }

 .define-bars-selector .ant-select-selection-item,.define-bars-selector .ant-select-selection-placeholder{
    padding: 0.53em 0.625em !important;
 }

 .define-bars-selector  .ant-select-selection-search-input{
    margin-top: 0.5em !important;
    margin-left: 0.6em !important;
 }


 .define-bars-option{
    height: 3.3em !important;
    font-size: 1.12em;
    font-weight: 400;
    background-color: #ECF1F4 !important;
 }
 
 .define-bars-option .ant-select-item-option-content{
    margin-top: 0.65em;
    font-family: poppins;
 }

 .define-bars-option:hover{
    background-color: #4CCB1F !important;
    color: #fff;
    font-family: poppins;
 }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: #ECF1F4 !important;
    color: #fff;
    font-size: 1em;
 }



 .ant-select-item-option-selected:hover{
    background-color: #4CCB1F !important;
    color: #fff !important;
 }

 .ant-select-item-option-selected{
    font-size: 1.12em !important;
 }

 .define-bars-selector .ant-select-selection-item{
    font-size: 1.12em;
 }

 .define-bars-selector .ant-select-selection-placeholder{
    font-size: 1.12em !important;
 }

 .ant-select-item-option-selected{
    color: #fff !important;
    font-size: 1.12em !important;
    background-color: #ECF1F4 !important;
 }

 .ant-select:not(.ant-select-disabled):hover, .ant-select-selector .define-bars-selector{
    border-color: #AABAC6 !important;
 }

 .define-bars-selector .ant-select:not(.ant-select-disabled):hover,.define-bars-selector .ant-select-selector:focus,
 .define-bars-selector .ant-select-selector:active {
    border-color: #AABAC6 !important;
    outline: none !important;
 }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    font-size: 1.12em !important;
 }