
.soul-banner-container{
    width: 100%;
    border-radius: .5em;
    background: url('https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739427991/MApp/UI/SVGs/soul-learn-bg-inactive_tl8u3p.svg');
    background-repeat: no-repeat;
    background-size:cover;
    padding: 2.5em 2em;
    transition: ease-in-out .3s;
}
.soul-banner-container:hover{
    background: url('https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739428362/MApp/UI/SVGs/soul-learn-bg-active_s0fjzw.svg');
    background-repeat: no-repeat;
    background-size: cover;
    transition: ease-in-out .3s;
}
.banner-play-icon{
    display: none;
    position: absolute;
    bottom: 2em;
    right: 4em;
    width: 6em;
    height: 6em;
    cursor: pointer;
    transition: ease-in-out .3s;
}
.soul-banner-container:hover .banner-play-icon{
    display: block;
    transition: ease-in-out .3s;
}
.soul-read-timer{
    color: #8998A2;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.content-width{
    width:75%
}
.soul-card-continer{
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    gap: 3em;
    justify-content: space-between;
    
}
.soul-card-container-section{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2em;
    max-width: 50%;
    width: 100%;
}
.soul-learn-survey-card{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #5674FA4A;
    border-radius: .5em;
    max-width: 42em;
    width: 100% !important;
    height: 30em;
    padding: 1em 2em;
    position: relative;
}

.soul-learn-card-thumbnail{
    margin-top: 1em;
    width:100%;
    height: 18em;
    object-fit: cover;
    cursor: pointer;
}
.soul-learn-status-card-container{
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
    justify-content:center;
    max-width: 45em;
    width: 100%;
}
.soul-learn-status-card{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #5674FA4A;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 13em;
    height: 16em;
}
.soul-learn-take-survey-btn,.soul-learn-take-survey-btn:hover,.soul-learn-take-survey-btn:focus{
    background: #4CCB1F;
    border:none;
    color: #fff;
    font-size: 1em !important;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}
.soul-learn-take-survey-btn[disabled],.soul-learn-take-survey-btn[disabled]:hover,.soul-learn-take-survey-btn[disabled]:focus{
    border:none;
    color: #fff;
    font-size: 1em;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: not-allowed !important;
    text-transform: uppercase;
    font-weight: 600;
    background: #AABAC6;
}
.soul-learn-completed-btn,.soul-learn-completed-btn:hover,.soul-learn-completed-btn:focus{
    background: url('../../assets/learn-icons/complete-button-animation.gif');
    color: #fff;
    font-size: .9em !important;
    border: none;
    padding: .5em 2em;
    border-radius: .5em;
    height: 3em;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}
.info-preview-modal{
    max-width: 60vw;
    width: 100%;
    padding: 3em;
}
.info-preview-modal .ant-modal-content {
    background-color: #fff !important;
    background: linear-gradient(0deg, rgba(13, 214, 113, 0.1) 0%, rgba(13, 214, 113, 0.01) 100%);
    box-shadow: none !important;
}
.congrats-modal {
    max-width: 35em !important;
    width: 100% !important;
}
.congrats-modal .ant-modal-content {
    /* background-color: #fff !important; */
    max-width: 35em;
    background:url('../../assets/learn-icons/congratulation.gif') !important;
    box-shadow: none !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size:cover !important;
    border-radius: 2.5em !important;
}
.congrats-modal .ant-modal-body{
    padding: 0px !important;
}
.gif-bg{
    background: url('../../assets/learn-icons/congratulation-bg.gif');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size:cover !important;
}
.learn-value-list{
    padding: 0.625em 0.8125em 0.625em 0.8125em;
    margin: .5em 0.2em;
    border-radius: 0.6875em;
    background:  #F5F5F5;
    display: flex;
    justify-content: start;
    align-items: center;
    width:70%
}

.learn-value-list h4{
    color:#1F1F1F;
    font-family: Poppins;
    font-size: 1.3125em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    /* word-break: break-all; */
}

.learn-value-list span{
    color:#009AF1;
    margin-right: 0.75em;
}
.congrats-modal-value {
    max-width: 40em !important;
    min-height:30em;
    width: 100% !important;
}
.congrats-modal-value .ant-modal-content {
    /* background-color: #fff !important; */
    max-width: 40em;
    box-shadow: none !important;
    border-radius: 2.5em !important;
    padding-bottom: 3em;
}
.congrats-modal-value .ant-modal-body{
    padding: 0px !important;
}
.soul-card-mobile-view{
    display: none;
}
.tropy-img{
    height: 11em !important;
}
@media (max-width:1400px) {
    .soul-learn-survey-card{
        max-width:100%;
        width: 100% !important;
        height: 30em;
    }  
    .soul-learn-card-thumbnail{
        height: 18em;
    }
    .soul-learn-status-card-container{
        max-width: 30em;
    } 
    .soul-learn-status-card{
        height: 14em;
        width: 12em;
    }
    .sm-font-size{
        font-size: 1em;
    }
    .tropy-img{
        height: 9em !important;
    }
    .landing-title-font-size-bold{
        font-size: 1.5em;
    }
    .landing-title-font-size-normal{
        font-size: 1.2em;
    }
}
@media (max-width:960px){
    .content-width{
        width:80%
    } 
    .banner-play-icon{
        bottom: 1em;
        right: 2em;
        width: 5em;
        height: 5em;
    }
    .tropy-img{
        height: 10em !important;
    }
}
@media (max-width:780px){
    .soul-learn-survey-card{
        max-width: 100% !important;
        width: 100% !important;
        height: 30em;
    }  
    .tropy-img{
        height: 8em !important;
    }
    .landing-title-font-size-bold{
        font-size: 1.5em;
    }
    .landing-title-font-size-normal{
        font-size: 1em;
    }
}
@media (max-width:670px) {
    .soul-card-web-vew{
        display: none;
    }
    .soul-card-mobile-view{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
        margin-top: 2em;
    }
    .tropy-img{
        height: 10em !important;
    }
    .landing-title-font-size-bold{
        font-size: 2em;
    }
    .landing-title-font-size-normal{
        font-size: 1.25em;
    }
}
@media (max-width:600px) {
    .content-width{
        width:100%
    } 
    .soul-banner-container{  
        background-size:cover;
        background-position: center;
    }
    .soul-banner-container:hover{
        background-size:cover;
        background-position: center;
    }
    .banner-play-icon{
        bottom: 1em;
        right: 1em;
        
    }
}