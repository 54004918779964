.ca-small-card-seaction {
    border-radius: 1.0625em;
    background: #F4F9FD;
    box-shadow: 0px 4px 45px -24px rgba(0, 0, 0, 0.07);
    padding: 1em 2em;
    width: fit-content;
}

.ca-small-detail-role {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ca-small-detail-role-title {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ca-detail-data-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 0em 15em;
    margin-top: 2em;
}

.ca-table-rating-btn {
    border-radius: 0.75em;
    height: 2.2em;
    width: fit-content;
    min-width: 5em;
    text-align: center;
    color: #262626;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    padding: 0em .5em;
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* table design */
.ca-table-container {
    margin-top: 2em;
    width: 100%;
    overflow: auto;
}

.ca-table {
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.ca-row-header {
    width: 100%;
    background: linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
}

.ca-row-header th {
    padding: 1.25em 0px;
    /* background: linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%); */
}

.ca-table-row-padding-left {
    padding-left: 2em !important;
}

.ca-table-row-padding-rigth {
    padding-right: 2em !important;
}

.ca-content-center {
    text-align: center;
}

.ca-content-right {
    text-align: right;
}

.ca-row-body {
    background: #FCFCFC;
    border: 1px solid black !important;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.ca-row-body td {
    padding: 1.25em 0px;
}

.ca-center-padding {
    padding-left: 1.6rem !important;
}

.ca-table-header-label {
    color: #262626;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.ca-table-body-label {
    color: #000;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.275em;
    margin: 0px 0.5em;
}

.ca-left-radious {
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em !important;
}

.ca-right-radious {
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.ca-table-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
}

.ca-table-actions-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    gap: 1.5em;
}

.ca-custom-menu-filter {
    border-radius: 1em;
    background: #FFF;
    margin-top: 0.31em;
    padding: 1.5em;
}

.ca-graph-advance-filter {
    max-height: 60vh !important;
    overflow: auto !important;
    width: 18em !important;
    max-width: 90vw !important;
    display: flex;
    flex-direction: column;
    gap: 1.8em;
}

.ca-graph-advance-filter-button,
.ca-graph-advance-filter-button:hover,
.ca-graph-advance-filter-button:focus {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5em;
    color: #262626;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    border-radius: 0.5em;
    background: #F4F9FD;
    border: none !important;
    outline: none !important;
    padding: 1em !important;
}

.graph-menu-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.38em;
    max-height: 10em;
    overflow: auto;
}

.graph-menu-child div {
    width: 100%;
    height: 2.5em;
    color: #8C8CA1;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    background: #F4F9FD;
    padding: 1em !important;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active-child-selection {
    background: #4CCB1F !important;
    color: #FFF !important;
}

.selected-filter-data {
    display: flex;
    flex-direction: column;
    gap: 0.31em;
    width: 100%;
    margin-top: 1em;
}

.selected-filter-data div {
    width: 100%;
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
    background: #F4F9FD;
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ca-competency-filter-dropdown {
    border-radius: 0.75rem !important;
    background: #FFF;
    width: 16.0625em !important;
    max-width: 100% !important;
}

.ca-drop-label {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.00875em;
    margin: 0px;
}

/* *********** */
.ca-overlay-container {
    width: 100%;
}

.cadefine-bars-overlay-container .ant-dropdown-menu-submenu {
    background: #ECF1F4;

}

.ca-overlay-container .ant-dropdown-menu-submenu-title {
    height: 3.5em;
    color: #000;
    font-family: Poppins;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
}

/* .define-bars-overlay-container .ant-dropdown-menu-item:hover, */
.ca-overlay-container .ant-dropdown-menu-submenu-title:hover {
    background: #ECF1F4;
}

.ca-overlay-container {
    background-color: #ECF1F4 !important;
    padding: 0px;
}


.ca-overlay-container .ant-dropdown-menu-item:hover {
    border: 0.062 solid #4CCB1F;
}

.ca-overlay-container .ant-dropdown-menu-item,
.ca-overlay-container .ant-dropdown-menu-submenu-title {
    padding: 0.90em 0.75em;
}

.ca-overlay-container .ant-dropdown-menu-submenu-title:hover {
    border: 1px solid #4CCB1F;
}



.ca-submenu {
    width: 16.0625em !important;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: poppins;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ca-submenu:hover {
    background: #ECF1F4;
}

.ant-dropdown-menu-submenu-popup {
    max-height: 40vh;
    overflow-y: auto;
}

.ca-selected-row div {
    background: #4CCB1F !important;
    color: #FFF;
}

.ca-selected-row .ant-dropdown-menu-submenu-title {
    color: #FFF;
}

.ca-overlay-container .ant-dropdown-menu-item-only-child {
    width: 40%;
}

.ca-submenu {
    width: 32em;
    height: 3.5em;
    padding: 0.75em;
    background: #ECF1F4;
    position: relative;
    left: -0.5em;
    font-size: 1.1em;
    padding: 0.90em 0.75em;
    color: #8C8CA1;
    font-family: 'poppins';
    text-overflow: ellipsis;
    overflow: hidden;
}

.ca-submenu:hover {
    background: #ECF1F4;
}

.apply-btn-ca-filter,
.apply-btn-ca-filter:hover,
.apply-btn-ca-filter:focus {
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    width: 5.8125em;
    height: 2.5em;
    color: #FFF;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    /* 137.5% */

}
.ca-adv-filter-title-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.ca-adv-filter-title-container h4{
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.line-chart-legend{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1.5px solid #AABAC6;
    padding: 1em 4em;
}
.legend-container{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0125em;
    padding: 0.35em 0.75em;
    border-radius: 1.34375em;
    min-width: fit-content;
    height: 42px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    margin: 1em;
    cursor: pointer;
}

.shape {
    width: 20px;
    height: 20px;
    /* border: 1px solid black; */
    display: inline-block;
    margin: 5px;
  }

  .circle {
    border-radius: 50%;
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;

  }
/* ******* */
@media (max-width:1000px) {
    .ca-detail-data-container {
        padding: 0em 10em;
    }
}

@media (max-width:760px) {
    .ca-detail-data-container {
        padding: 0em 6em;
    }
}

@media (max-width:480px) {
    .ca-detail-data-container {
        padding: 0em 3em;
    }
}