.role-row-alignment{
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-direction: nowrap;
    width: 100%;
}

.role-dropsection-alignment{
    display: flex;
    flex-direction: row;
    gap: 0.25em;
    flex-wrap: nowrap;
    width: 100%;
}

.role-plus-btn, .role-plus-btn:hover, .role-plus-btn:focus{
    border-radius: 0.85rem;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    height: 3em;
    margin-top: 0.75em;
    width: 4em;
    cursor: pointer;
    padding: 0px;
}

.must-have-section{
    width: 100%;
    height: 3em;
    background: #FFF;
    border-radius: 1em;
    padding: 0.5em 1.5em 0.5em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.must-have-label{
    margin: 0xp !important;
    padding: 0px !important;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    margin-top: 0.5em;
}

.must-have-parent{
    width: 1.625rem;
    height: 1.625rem;   
    max-width: 1.625rem;
    max-height: 1.625rem;   
    border: 1px solid #8C8CA1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.must-have-child{
    width: 50%;
    height: 50%;
    border-radius: 50%;
}

.competency-blue{
    border: 1px solid #0098F0;
}

.competency-child-blue{
    background:#0098F0 ;
}

.competency-green{
    border: 1px solid #4CCB1F;
}

.competency-child-green{
    background:#4CCB1F ;
}

.role-comp-table-must-have{
    padding: 0.25em;
    height: 2.75em;
    background: #FFFFFF;
    border-radius: 1.25em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 20em;
    min-width: 20em;
}

.table-must-have-container{
    width: fit-content !important;
    min-width: fit-content !important;
}

.role-comp-table-label{
    color: #AABAC6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0px;
    padding: 0px;
}

.role-comp-table-setion{
    width: 50%;
    border-radius: 1.25em;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-eligibility-btn, .add-eligibility-btn:hover, .add-eligibility-btn:focus{
    border-radius: 1rem;
    border: 1px solid  #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    width: 12em;
    height:2.8em;
    max-width: 90%;
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    cursor: pointer;
    margin-top: 1.2em;
}

.eligible-year{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
    white-space: nowrap;
    margin-top: 2.3em;
}
.rd-role-filter-container{
    width: 100%;
    max-width:50%;
    padding-right:.5em;
    margin-top: 1em;
}

.slider-label-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}

.slider-container{
    display: flex;
    flex-direction: row;
    gap: 1.25em;
    flex-direction: nowrap;
    width: 100% !important;
    align-items: center;
}

.role-eligibility-table{
    border-radius: 1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 1.12em 0em;
}

.eligibility-table-main-header{
    border-radius: 1rem 1rem 0rem 0rem;
    height: 4.06rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1.5em 0.5em 2.69em;
}

.eligibility-table-main-header h4{
    margin: 0px;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.eligibility-tr{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0em 1.5em 0em 2.69em;
    min-width: 620px;
}

.eligibility-th{
    width: 33.33%;
    min-width: 33.33%;
    text-align: center;
    height: 4.06rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eligibility-td{
    width: 33.33%;
    min-width: 33.33%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.88em;
    padding: 1.88em 0.5em;
}

.eligibility-th h4{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.eligibility-td h4{
    color: #262626;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.eligible-tr-border{
    border-radius: 0em 0em 1em 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    border-top:none ;
}

.eligibility-table-body{
    overflow: scroll;
}

.eligible-table-action{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
}

.eligible-table-action img{
    width: 1.25em;
    height: 1.25em;
    cursor: pointer;
}

.rd-eligibility-slider .ant-slider-handle{
    background-color: #4CCB1F !important;
    border: 2px solid #4CCB1F !important;
    margin-top: -4px !important;
}
.rd-eligibility-slider .ant-slider-rail{
    border-radius: 1em !important;
    height: .5em !important;
    background: rgba(170, 186, 198, 0.50) !important;
}
.rd-eligibility-slider .ant-slider-track{
    height: .5em !important;
    background-color: #4CCB1F !important;
}
.rd-search-drop-placeholder{
    color: #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}
.rd-advace-filter-title{
    color: rgba(0, 152, 240, 1);
    text-decoration: underline;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
    margin-top: .35em;
}
@media (max-width:850px) {

    .add-eligibility-btn, .add-eligibility-btn:hover, .add-eligibility-btn:focus{
        width: 13em;
        height:2.8em;
        font-size: 1.25em;
    }
    
}

@media (max-width:700px) {
    .rd-role-filter-container{
        width: 100%;
        max-width:50%;
        padding-right:.5em;
    }
    .role-row-alignment{
        flex-direction: column;
    }
}

@media (max-width:480px) {
    .rd-role-filter-container{
        width: 100%;
        max-width:100%;
        padding-right:0em;
    }
}

@media (max-width:400px) {
    .role-dropsection-alignment{
        flex-direction: column;
        gap: 0em;
    }

    .role-plus-btn{
        margin-top: -10px;
        width: 3em;
        padding: 0px;
    }
}
