.scape-report-table-container {
    font-size: 16px;
    margin-top: 1em;
}

.scape-report-data-container {
    max-width: 90%;
    margin: auto;
    
}

.scape-report-data-container-overview{
    width: 90%;
    margin: auto;
    
}


.scape-report-table-radius {
    border: 0.08em solid #AABAC6;
}

.table-response-row {
    width: 8.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-cell-scape-report {
    width: 8.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.table-cell-scape-report-overview{
    width: 4.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.scape-report-table-padding {
    padding: 0.5em;
}


.scape-report-group:first-child {
    border-bottom: none;
}

.scape-report-group0,.scape-report-personality-group0 {
    padding-top: 2em;
    font-weight: 600;
}

.scape-report-group0{
    height: 80px;
}

.scape-report-personality-group0 {
    height: 79px;
}

.scape-label-font {
    font-size: 0.9em;
    font-weight: 500;
}

.scape-report-parameter {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.scape-report-group {
    font-weight: 500;
}

.scape-report-group-row {
    font-weight: 500;
}

.scape-table-count,
.scape-table-total {
    font-weight: 500;
}

.scape-table-count {
    border: 0.0825em solid #A5E58F;
    background: #DBF5D2;
    position: relative;
    z-index: 1000;
}


.scape-table-total {
    border: 0.0825em solid rgba(76, 203, 31, 0.00);
    background: #A5E58F;
}

.scape-report-personality-text5{
visibility: hidden;
}

.scape-overview-user{
    width: 12.23em;
    min-width: 12.23em;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.personality-scape-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.scape-overview-name{
    padding: 0.5em 3.9em;
    position: relative;
    top: 0.3em;
    left: 0em;
    text-align: center;
    font-size: 1em;
    border-bottom: none;
    font-weight: 600;
}

.table-border-top-none{
    border-top: none;
}

.table-border-left-none{
    border-left: none;
}

.table-border-bottom-none{
    border-bottom: none;
}

.response-survey-0,.scape-report-personality-group0,.parameter-column-0{
    border-left: 0.08em solid #AABAC6;
}

.table-response-row:last-child{
    border-bottom: none;
    position: relative;
    z-index: 1000;
}

.scape-report-group0{
    border-left: 0.08em solid #AABAC6;
}

.parameter-counting-0{
    width: 11.07em !important;
    border-left: 0.08em solid #A5E58F;
    border-right: 0.08em solid #A5E58F;
    position: relative;
    z-index: 1005;
}

.scape-report-group{
    height: auto;
}

.reponse-personality-0{
    border-left: 0.08em solid #AABAC6;
}

.survey-response{
    max-height: 20em;
    overflow: auto;
}

.table-total-count-stick-bottom{
    position: fixed;
    bottom: 2.4em;
}

.table-count-stick-bottom{
    position: fixed;
    bottom: 0em;
}


.scrollable-column{
    max-width: 80%;
    overflow: auto;
}

.scape-report-personality-group:last-child{
    border-right: 0.0825em solid rgba(76, 203, 31, 0.00) !important;
}
