.profile-update-Root{
    font-size: 16px;
    font-family: Poppins;
}
.profile-update-popup-main-head{
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.5em;
    font-style: normal;
    line-height: normal;
    color:rgba(38, 38, 38, 1);
    margin: 0;
}
.save-btn-profile-update, .save-btn-profile-update:hover, .save-btn-profile-update:focus{
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2em;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
    background: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: .5em;
    padding: .5em 2em;
    cursor: pointer;
    outline: none;
    border: none;
    height: 3em;
}
.cancel-btn-profile-update, .cancel-btn-profile-update:hover, .cancel-btn-profile-update:focus{
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2em;
    font-style: normal;
    line-height: normal;
    color: rgba(137, 152, 162, 1);
    background: #ffffff;
    border-radius: .5em;
    padding: .5em 2em;
    cursor: pointer;
    outline: none;
    border: none;
    height: 3em;
    border: 1px solid rgba(170, 186, 198, 1)
}
.profile-edit-select .ant-select-selector{
    border-radius: .5em !important;
    height: 3em !important;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    box-shadow: none !important;
}
.profile-edit-select .ant-select-selection-item{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.profile-edit-input{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.profile-edit-select .ant-select-selection-search-input{
    height: 2.5em !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
}
.update-profile-form .ant-form-item {
    margin-bottom: 1em;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}