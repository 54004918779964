
.sa-root{
    font-size: 16px;
    font-family: 'Poppins';
    width: 100%;
}
.sa-main-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.sa-page-title {
    font-family: 'Poppins';
    font-size: 2em;
    font-weight: 700;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-create-btn-large,.sa-create-btn-large:focus,.sa-create-btn-large:hover{
    background-color: #4CCB1F;
    padding: 0em 1em;
    height: 2.2em;
    color:#FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.4em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0.3em;
    border: none;
    outline: none;
    margin-top: .5em;
    width: fit-content;
}
.sa-from-title{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sc-main-container-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    padding: 1em 7em;
}
.sc-main-content-container{
    width: 100%;
    background: #F4F9FD;
    border-radius: 6px;
    min-height: 20em;
    padding: 2em 1.5em;
}
.sa-home-page-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.sa-table-container{
    width: 100%;
    margin: 2em 1em 1em 1em;
    display: flex;
    flex-direction: column;
}
.sc-table-header-container{
    width: 100%;
    background: #31D0AA;
    display: flex;
    border-radius: 0.5em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding: 1em;
}
.sa-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.sa-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.275em;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-label-head{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.275em;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-label-des{
    color:  #1F1F1F;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 0.5em;
    font-family: "Poppins";
}
.sa-table-body-container{
    border-radius: 0.5em;
    background: #FFF;
    width: 100%;
    margin-top: 1em;
}
.sa-table-boday-header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
}
.sa-action-icon{
    width: 20px;
    cursor: pointer;
}

.sa-expansion-arrow{
    cursor: pointer;
    transform: rotate(-90deg);
    transition-duration: 0.5s;
}

.sa-open-expansion{
    transition-duration: 0.5s;
    transform: rotate(0deg);
}
.sa-table-body-inner-container{
    padding: 1em 2em ;
    border-top: 1px soild #898989;
    width:100%;
}
.sa-global-submit-btn, .sa-global-submit-btn:hover, .sa-global-submit-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #FFF;
    text-align: center;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins';
}

.sa-global-cancel-btn, .sa-global-cancel-btn:hover, .sa-global-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #262626;
    color: #262626;
    text-align: center;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins';
}
.sa-delete-cancel-btn, .sa-delete-cancel-btn:focus, .sa-delete-cancel-btn:hover {
    color: #8C8CA1;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    border-radius: 0.5rem;
    border: 1px solid #8C8CA1;
    padding: 0.75em 1.5em;
    cursor: pointer;
    margin-top: .5em;
    background: #fff;
    outline: none;
    height: auto;
}
.sa-delete-delete-btn, .sa-delete-delete-btn:focus, .sa-delete-delete-btn:hover{
    color: #fff;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    border-radius: 0.5rem;
    border: 1px solid #E86161;
    padding: 0.75em 1.5em;
    cursor: pointer;
    margin-top: .5em;
    background: #E86161;
    outline: none;
    height: auto;

}
.sa-delete-warning-para{
    color: #000;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.025rem;
    text-transform: capitalize;
}
.selection-container{
    border-radius: 1rem;
    border: 1px solid #AABAC6;
    background: #FFF;
    min-width: 100% !important;
    width: 100% !important;
    height: 28.625em;
    overflow-y: auto;
    padding: .5em;
}
.sa-choice-modal{
    width:100%;
    min-width: 60%;
    height: 90%;
}
.sa-choice-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
    overflow-y: auto;
    max-height: 90vh;
}
.sa-choice-question-container{
    border-radius: 0.5rem;
    background: #F4F9FD;
    padding: .5em;
    margin-top: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: .75em;
    width: 100%;
}
.sa-choice-question-text{
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    margin-bottom: 0em !important;
}
.sa-choice-question-text p{
    margin-bottom: 0em !important;
}
.sa-table-body-label-head p{
    margin-bottom: 0em !important;
}
.sa-choice-question-checkbox{
    width: 20px !important;
    height: 20px !important;
    border-radius: .25em;
    border: .5px solid #AABAC6;
    cursor: pointer;
}
.sa-radio-btn-group .ant-radio-checked .ant-radio-inner,.ant-radio-checked:hover,.ant-radio-checked:focus{
    border-color: #4CCB1F !important;
}
.sa-radio-btn-group .ant-radio-inner::after {
    background-color: #4CCB1F !important;
}
.sa-radio-btn-group .ant-radio-input:hover,.sa-radio-btn-group .ant-radio-input:focus{
    border-color: #4CCB1F !important;
}

.sa-edit-modal .ant-modal-body{
    border-radius: 1em;
    background: #F4F9FD;
   
}
@media (max-width:1200px) {
    .sa-root{
        font-size: 14px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 5em;
    }
    .sa-choice-modal{
        width:100%;
        min-width: 85%;
        height: 90%;
    }
    .selection-container{
        min-width: 100% !important;
        width: 100% !important;
        height: 27.625em;
      
    }
}

@media (max-width:960px) {
    .sa-root{
        font-size: 12px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 3em;
    }
    .sa-choice-modal{
        width:100%;
        min-width: 90%;
        height: 90%;
    }
    .selection-container{
        min-width: 100% !important;
        width: 100% !important;
        height: 25.625em;
      
    }
}
@media (max-width:600px) {
    .sa-root{
        font-size: 10px;
        font-family: 'Poppins';
    }
    .sc-main-container-section{
        padding: 1em 1em;
    }
    .selection-container{
        min-width: 50% !important;
        height: 25.625em;
      
    }
}

.table-overflow{
    overflow: auto;
}