.poc-survey-report-root {
    font-size: 16px;
    background: rgba(255, 255, 255, 1);
    border: 8px;
    padding: 1em;
    margin-top: 1em;
}

.poc-survey-report-main-container{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
}

.poc-report-table-height{
    max-height: calc(80vh - 140px); 
  }

.poc-table-action-td {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5em;
}

.border-action-btn,
.border-action-btn:hover,
.border-action-btn:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(216, 216, 216);
    cursor: pointer;
    background-color: transparent;
}

.border-action-btn img {
    width: 2em;
    height: 2em;
    object-fit: contain;
}

.poc-360-invite-pop-head {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

.row-layout-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    gap: 0.5em; 
}

.poc-360-search-container {
    position: relative;
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    width: 100%;
    max-width: 17em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: .25em;
    padding: 0.1em 0.5em;
    border-radius: 0.5em;
}

.poc-survey-selector-report{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.25em;
}

.poc-report-action-detail-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5em;
}

.poc-report-export-score-scetion{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.25em;
}

.poc-report-export-btn,.poc-report-export-btn:focus,.poc-report-export-btn:hover {
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    color: #000;
    font-size: 1em;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    padding: .2em 1em;
    border-radius: .5em;
    width: fit-content;
    height: 2.5em !important;
}



@media screen and (max-width:1000px) {
    .row-layout-wrap{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:450px){
    .poc-report-action-detail-section{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}