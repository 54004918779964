
.token-input-field .ant-input,.token-input-field .ant-select-selector{
    height: 36px !important;
}

.select-employee-survey .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    font-size: 1em !important;
}

.user-edit-icon-btn{
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #B0CB1F;
    margin-top: 33px;
}

.user-edit-icon-btn svg{
    width: 18px;
    height: 18px;
    fill: #B0CB1F;
}

.value-counter{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 5px 6px 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px;
}

.value-survey-card{
    position: relative;
    border-radius: 8px;
}

.value-survey-card:hover .ant-card-body{
    background-color: #4ccb1f !important;
    border-radius: 8px;
}

.value-active-card .ant-card-body{
    background-color: #4ccb1f !important;
    border-radius: 8px;
}

.value-survey-card:hover .value-card-text{
    color: #fff;
}

.value-active-card .value-card-text{
    color: #fff !important;
}
.value-survey-card .ant-card-body{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.value-card-text{
    color: #1F1F1F;
    font-size: 1em;
    width: 85%;
}