.side-bar-root {
  font-size: 16px;
  font-family: "Poppins";
}

.main-header-continer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
  border-bottom: 0.0625em solid #aabac6;
  padding-right: 1.5em;
  position: fixed;
  height: 90px;
  z-index: 1100 !important;
  background-color: #f6fbff;
}

.main-side-bar-container{
  background: transparent !important;
  position: fixed;
  top: 90px;
  left: 0px;
  bottom: 0px;
  width: 130px;
  z-index: 1000 !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  transition-duration: 1s !important;
  
}

.expand-main-side-bar{
  transition-duration: 1s !important;
  width: 350px !important;
  max-width: 90% !important;
}

.expand-side-nav-section {
  width: 100%;
  background-color: #fff;
  padding: 0px 15px;
}

.child-route-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  /* align-items: center; */
  justify-content: start;
  padding: 16px 5px;
  overflow: auto;
  height: 100%;
}



.main-body-container {
  width: 100%;
  background: #f6fbff;
  height: calc(100vh - 5.85em);
  overflow-y: auto;
}
.side-bar-scroll-container {
  height: 100%;
  width: 100px !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1em;
  background-color:#E1F1FF;
  padding: 1em 0px;

}

.side-bar-log-continer {
  background: #e1f1ff;
  width: 100px;
  height: 91px;
  border-bottom: 0.0625em solid #aabac6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.side-bar-container {
  height: 100%;
  width: 100px;
  position: relative;
}

.sidebar-expand-icon{
  position: absolute;
  top: 15px;
  left: 100px;
  cursor: pointer;
}

.side-bar-logo {
  height: 60px !important;
  width: 60px !important;
  cursor: pointer;
}

.nav-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.side-nav-selector {
  visibility: hidden;
}
.side-nav-selector-active {
  visibility: visible;
}
.nav-card-container:hover .side-nav-selector {
  visibility: visible;
}

.side-nav-icon-card {
  width: 70px;
  height: 70px;
  border-radius: 0.75em;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5px;
  cursor: pointer;
  padding: 5px !important;
}
.side-nav-icon-card:hover .side-nav-ttile {
  font-weight: 500;
}

.side-nav-ttile {
  color: #1f1f1f;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.side-nav-ttile-active{
  font-weight: 500;
}

/* *********** */





.side-nav-card-icon {
  height: 2.225em;
  width: 2.225em;
}
.side-bar-logo {
  height: 5.125em;
  width: 5.125em;
  cursor: pointer;
}


.search-icon-container {
  width: 3.5em;
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.65em;
  background-color: #fff;
  margin-left: 1em;
  cursor: pointer;
}
.search-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 5.8em;
  padding: 0em 1em;
  width: 250px;
}
.search-input-container-input {
  border: none !important;
  background-color: #fff;
  font-size: 1em;
  font-family: "Poppins";
  color: #303030;
  width: 100%;
}


.active-side-icon {
  display: none;
}
.non-active-side-icon {
  display: block;
}
.child-route-button:hover {
  /* border: 1px solid rgba(213, 229, 242, 1); */
  box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
  /* border-radius: 0.75em; */
}


.child-route-button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  /* width: 100%; */
  width: 92%;
  min-height: 56px;
  padding: 0.5em 0.25em 0.5em 0.75em;
  border: 1px transparent;
  font-family: "Poppins";
  font-size: 0.95em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* white-space: nowrap; */
}
.child-route-button-active{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  /* width: 100%; */
  width: 92%;
  min-height: 56px;
  padding: 0.5em 0.25em 0.5em 0.75em;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.child-route-button-selected {
  background-color: #4ccb1f;
  color: #fff;
}
.child-route-button-selected .active-side-icon {
  display: block;
}
.child-route-button-selected .non-active-side-icon {
  display: none;
}

.header-page-title{
  font-size: 1.5em;
  font-weight: 700;
  margin: 0px;
  margin-left: 10px !important;

}
.expand-child-route{
  border: 1px solid rgba(213, 229, 242, 1) !important;
  box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
  display: flex;
  flex-direction: column;
  gap: .5em;
  height: fit-content;
  width: 92%;
  border-radius: 0.75em;
  transition-duration: 1s;
  transition: ease .5s;
  outline: none !important;
  padding-left: .5em;
  padding-right: .5em;
}

.bread-crum-btn{
  background: #E1FFD7;
  width: fit-content;
  padding: 5px 1em 2px 1em;
  border-radius: 0.25em;
  font-family: "Poppins";
  font-size: 1.15em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  color: #6BB96A;
}
.bread-crum-btn-inactive{
  width: fit-content;
  padding: 5px 1em 2px 1em;
  border-radius: 0.25em;
  font-family: "Poppins";
  font-size: 1.15em;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.bread-crum-container{
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  gap: 1em;
}
/* .child-route-button-active{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
  border-radius: 0.75em;
  cursor: pointer;
  width: 100%;
  min-height: 56px;
  padding: 0.5em 1em;
  font-family: "Poppins";
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */
.top-nav-bar-icons-align{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 2em;
}
.sidebar-mobile-expand-icon{
  position: absolute;
  left:0px;
  top:105px;
  cursor: pointer;
  display: none;
  z-index: 1;
}
@media (max-width:960px) {
  .sidebar-mobile-expand-icon{
    display: block;
  }
  .main-side-bar-container-for-web{
    display: none;
  }
}
@media (max-width:380px) {
  .top-nav-bar-icons-align{
    gap: 1em;
  }
}

.header-dropdown-index{
  z-index: 1500 !important;
}

.side-nav-ttile-green-active{
  color: #4CCB1F !important;
}
.navigation-warning-btn, .navigation-warning-btn:hover, .navigation-warning-btn:focus {
  margin: 8px;
  border: 1px solid #4ccb1f;
  color: #4ccb1f;
  border-radius: 8px;
  width: 120px;
  height: 44px;
  font-size: 1.15rem;
  font-weight: 500;
}

.custom_nav_icon_size{
  width:28px;
  height: 28px;
}