.ca-root{
    font-size: 16px;
    font-family: Poppins;
}

.ca-root-padding{
    padding: 0px 5em;
}

.ca-container-bg{
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #ECF1F4;
    background: #F4F9FD;
}

.selection-container-padding{
    padding: 3.5em 8em 3.5em 3.8em;
}

.section-container-padding{
    padding: 1.75em 3.8em; 
}

.ca-label{
    color: #262626;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: 2.875em;
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
}

.ca-role-detail{
    color: #000;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.ca-role-detail span{
    color: #0098F0 !important;
}

.section-title{
    color: #000;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    line-height: 0.75;
}

.ca-text-input, .ca-text-input:hover, .ca-text-input:focus, .ca-text-input:focus-visible, .ca-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}

.ca-gradient{
    width: fit-content;
    max-width: 100%;
    padding: 0px 0.75em !important;
    margin: 0px !important;
}

.ca-must-have-grad{
    background: linear-gradient(90deg, rgba(0, 152, 240, 0.50) 0.07%, rgba(217, 217, 217, 0.00) 104.03%);
}

.ca-good-have-grad{
    background: linear-gradient(90deg, rgba(76, 203, 31, 0.50) 0.07%, rgba(217, 217, 217, 0.00) 104.03%);
}

.competency-list-container{
    width: 100%;
    min-height: 15.125em;
    border-radius: 1em;
    background: #FFF;
    padding: 2.6em 1.9em;
    margin: 1.19em 0px;
}

.sub-competency-list-container{
    width: 100%;
    min-height: 15.125em;
    border-radius: 1em;
    background: #F4F9FD;
    padding: 2.8em 1.9em;
    margin: 1.19em 0px 1.19em 1em;
}
.ca-competency-title{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ca-sample-detail{
    color: #0098F0;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.025em;
    text-decoration-line: underline;
    cursor: pointer;
    white-space: nowrap;
}

.category-expand-icon{
    margin-right: 1.25em;
    width: 0.6875em;
    height: 1.125em;
    cursor: pointer;
    transition-duration: 0.5s;
}

.category-active-expand-icon{
    transition-duration: 0.5s;
    margin-right: 1.25em;
    width: 0.6875em;
    height: 1.125em;
    cursor: pointer;
    rotate: 90deg; 
}

.ca-threshold-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    margin-top: 1.63em;
    padding: 0em 1em;
}

.ca-threshold-card{
    min-width: 20.875em;
    height: 4.5em;
    border-radius: 1em;
    border: 1px solid #AABAC6;
    background: #FFF;
    overflow: hidden;
    padding: 1.25em;
    transition-duration: 0.5s;
    cursor: pointer;
}

.ca-threshold-card-expanded{
    height: 27em;
    transition-duration: 0.5s;
}

.ca-threshold-card-tile{
    /* height: 5em; */
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    gap: 0.5em;
    width: 100%;
}

.ca-threshold-label{
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    line-height: normal;
    margin: 0px !important;
}

.ca-radio-outer{
    width: 2em;
    height: 2em;
    min-width: 2em;
    min-height: 2em;
    border-radius: 50%;
    border: 3px solid #8C8CA1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ca-radio-inner{
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: transparent;
}

.ca-behaviour-sample-container{
    margin-top: 1.13em;
    height: 21em;
    overflow: scroll;
}

.ca-action-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.other-competency-btn, .other-competency-btn:hover, .other-competency-btn:focus{
    width: 18.1875em;
    height: 3.4375em;
    max-width: 95%;
    border-radius: 1em;
    border: 1px solid #4CCB1F;
    background: rgba(76, 203, 31, 0.08);
    color: #4CCB1F;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin-top: 2.62em;
}

.ca-sub-label{
    margin: 1.25em 0.85em 1.25em 0.85em;
    color: #000;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ca-input-max{
    max-width: 34.5em;
}

.ca-action-gap{
    gap: 2.69em;
}

.ca-page-title-margin{
    margin-top: 4em;
}

@media screen and (max-width:1600px) {
    .ca-root{
        font-size: 14px;
    }
}

@media screen and (max-width:1000px) {
    .ca-root{
        font-size: 12px;
    }
    .col-1000-justify-space-between-center{
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .ca-input-max{
        max-width: 100% !important;
    }
    .ca-action-gap{
        gap: 0.5em;
    }

    .ca-root-padding{
        padding: 0px 2em;
    }
}

@media screen and (max-width:850px) {
    .ca-root{
        font-size: 10px;
    }
}

@media screen and (max-width:600px) {
    .ca-root-padding{
        padding: 0px 0em;
    }
}
