@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

.vetical-menu{
    display: block;
}

.mobile-vertical-nav-okr{
    display: none;
}

.okr-table-gap{
    gap: 25px;
}
.center{
    display: flex;
    justify-content: center !important;
}

.item-center{
    align-items: center;
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    width:100%;
    
}

.row-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.custom-table-v2{
    min-width: 1200px;
}
.custom-table-v2-discard{
    min-width: 1100px;
}
.overflow-div{
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
.overflow-div::-webkit-scrollbar {
    height: 5px;
  }
  
  .overflow-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .overflow-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
  .detail-scroll{
    overflow: auto;
    width: 100%;
  }
  .detail-scroll::-webkit-scrollbar {
    display: none;
  }
 

.custom-table-tr{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    min-width: fit-content;
}

.table-header-section{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #8C8C8C;
    padding: 5px 10px;
}

.table-section-td{
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #595959;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.table-body-tr-border{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 7px;
    margin-bottom: 20px;
}

.table-section-td span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.table-section-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #595959;
    padding-top: 20px;
}
.page-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }

.add-btn{
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 57px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
.add-button-disable{
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 57px;
    background: #f5f5f5 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25) !important;
    border: none;
  }
  .add-button-disable:hover{
    border: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
.extendDeadlineButton, .extendDeadlineButton:hover, .extendDeadlineButton:focus, .extendDeadlineButton:active{
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineConfirmationButton{
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    margin-left: 20px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineConfirmationButton:hover{
    background: #4CCB1F;
    color: #FFFFFF;
}

@media screen and (max-width:750px) {
    .extendDeadlineButton{
        font-size:14px;
    }

    .newCorrectionDeadlineLabel{
        margin-bottom:10px;
    }

}

.buttons-container{
    justify-content: center;
}


@media screen  and  (max-width:420px){
    .buttons-container{
        justify-content: flex-start;
        margin-left:20px;
    }

}

.cancel-Modal-button{
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    height: 44px;
    margin-left: 20px;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.extendDeadlineButton:hover{
    background: #4CCB1F;
    color: #FFFFFF;
}


.button-with-icon{
    
        width: fit-content;
        height: fit-content;
        padding: 7px 25px;
        /* height: 57px; */
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        border:none;
     

}

.generic-button{
    
    width: fit-content;
    height: fit-content;
    padding: 8px 20px;
    /* height: 57px; */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    
    border: none;

}


.generic-button:hover{
    color:#000000
}

.okr-competency-button:hover,.okr-competency-button:focus{
    color: #000000 !important;
}

.add-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .add-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
.add-btn .plus-icon{
    font-size: 22px;
}

.progress .ant-progress-inner{
    border-radius: 22px !important;
}
.menu-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #595959;
    text-align: center;
    white-space: nowrap;
}
.menu-head-comma{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #595959;
    text-align: center;
    white-space: nowrap;
    margin: 0px !important;
}

.expansion-arrow-down{
    transition-duration: .5s; 
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.expansion-arrow{
   cursor: pointer;
   transition-duration: .5s; 
}
.custom-menu{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: -1px 6px 27px -9px rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    margin-top:0px;
    max-width:450px;
    min-width: 300px;
    width:100%;
    position:absolute;
    top:0px;
    z-index:1000
}


.input-select-expand{
    /* background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px 0px 0px 2px; */
    height: 41px !important;
    width: 208px !important;
    border-right: none;
}
.input-select-expand.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height:38px !important;
    border-right: none;
}
.input-select-expand.ant-select-multiple .ant-select-selector {
    height:38px !important;
    border-right: none;
    padding-left: 30px !important;
}
.input-select-expand.ant-select-multiple .ant-select-selection-item{
    display: none !important;
}
.input-select-expand.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    padding-left: 25px;
}
.input-select-expand-assign.ant-select-single .ant-select-selector .ant-select-selection-item{
display: none !important;
}

.input-select-expand .ant-select-selection-item {
    display: none !important;
}
.input-filter-expand{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 0px 2px 2px 0px;
    height: 38px !important;
    width: 133px;
}
.section-assign{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #4CCB1F !important;
    color: #4CCB1F !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  
  .active-assign{
    color: #FFF!important;
    background-color: #4CCB1F !important;
    box-shadow: none;
    font-size: 13px;
  }
  
  .section-collab{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #4CCB1F !important;
    color: #4CCB1F !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  
  .active-collab{
    color: #fff !important;
    background-color: #4CCB1F !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-cascade{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #009AF1 !important;
    color: #009AF1  !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-cascade{
    color: #FFF!important;
    background-color: #009AF1 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-non-cascade{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #009AF1 !important;
    color: #009AF1 !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-non-cascade{
    color: #fff !important;
    background-color: #009AF1 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .bottom-div{
    background: #FFFFFF;
    box-shadow: -1px -1px 27px rgba(0, 0, 0, 0.11);
    width: 100%;
    bottom: 0;
    height: 90px;
    justify-content: center;
  }
  .assign-btn{
    background: #4CCB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    outline: none;
  }
  .assign-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .assign-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .assign-btn-disable{
    background: #f5f5f5 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    outline: none;
  }
  .assign-btn-disable:hover{
    border: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  .selected-item{
    width: 100%;
    height: 50px;
    background: #B0CB1F;
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:10px 20px;
    margin-top: 20px;
    text-align: center;
  }
  .selected-item p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
  }
  .select-content-div{
    max-height: 200px!important;
    min-height: 200px !important;
    overflow: auto;
    width: 295px;
  }
  .select-content-div::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .select-content-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .select-content-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }

  .input-filter-dynamic{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
  }
  .number-input{
    padding: 4px 12px;
    /* width: 39px;
    height: 36px; */
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    outline: none;
  }
  .item-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between center;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-select-expand input{
    height: 36px !important;
}

.input-select-expand .ant-select-selection-placeholder{
    margin-top: 2px !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.weight-head{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #4CCB1F;
    display: flex;
    justify-content: end;
    padding-right:16px ;
    padding-top: 10px;
}
.modal-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}
.custom-new-modal .ant-modal-content {
    border-radius: 20px;
    width:100%;
    /* max-width: 640px; */
  }
.custom-new-modal{
    padding:10px;
}
.custom-performance-modal .ant-modal-content {
    border-radius: 15px;
    width:100% !important;
    min-height: 570px !important;
    /* max-width: 640px; */
  }
  .custom-performance-modal .ant-modal-wrap{
    width:100% !important;
    overflow: hidden;
  }
  .custom-performance-modal{
    width:80% !important;
    top:10px !important
  }
@media (max-width:992px) {
    .custom-performance-modal .ant-modal-content {
        border-radius: 15px;
        width:100% !important;
        height:100% !important;
        /* max-width: 640px; */
      }
}
.modal-sub-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}
.modal-sub-head span{
    font-weight: 600;
}
.modal-textarea{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width:100%;
    resize:none;
     border-radius:4px;
     height:150px;
     padding:10px;
     overflow:auto;
     outline:none;
     margin-top:20px;
}
.modal-cancel-btn{
    padding: 8px 15px;
    height: fit-content;
    width: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
}
.modal-cancel-btn:hover{
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    background-color: #fff;
}
.modal-cancel-btn:focus{
    color: #8C8C8C;
    border:1px solid #8C8C8C;
    background-color: #fff;
}
.modal-submit-btn{
    padding: 8px 15px;
    height: fit-content;
    width: fit-content;
    gap: 8px;
    background: #009AF1;
    border:1px solid #009AF1;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.modal-submit-btn:hover{
    background: #009AF1; 
    border:1px solid #009AF1;
    color: #FFFFFF;
}
.modal-submit-btn:focus{
    background: #009AF1; 
    border:1px solid #009AF1;
    color: #FFFFFF;
}
.title-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0.76em;
    /* padding: 20px 1em; */
}
.discard-tab{
    padding-top: 20px;
}
/* .discard-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #E86161;
} */
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-0-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-1-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-2-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}

.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-discard,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-discard{
    color: #E86161 !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-collaboration,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-collaboration{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-3-tab-assignment,.discard-tab .ant-tabs-tab.ant-tabs-tab-active #rc-tabs-4-tab-assignment{
    color:  #4CCB1F !important;
    text-shadow: none !important;
}
.discard-tab .discard-tab-color {
    background:#E86161 !important;
}
.discard-tab .assignment-tab-color{
    background:  #4CCB1F !important;
}
.discard-tab .collaboration-tab-color{
    background:  #4CCB1F !important;
}
.discard-tab .ant-tabs-tab-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #BFBFBF;
    padding-top: 20px;
}
.discard-tab #rc-tabs-0-tab-discard:hover{
    color: #E86161 !important;
}
.discard-tab #rc-tabs-0-tab-assignment:hover{
    color:  #4CCB1F !important;
}
.discard-tab #rc-tabs-0-tab-collaboration:hover{
    color:  #4CCB1F !important;
}
.discard-tab .ant-tabs-content-holder {
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    background: #FCFCFC;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    position: relative;
    margin-top: -16px !important;
    overflow: auto;
    padding-right: 10px;
  }
.okr-tab .ant-menu-horizontal {
    margin-top: 30px;
    border-bottom: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
}
.okr-tab .ant-menu-submenu-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #7CB305;
}
.okr-tab .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    padding-bottom: 10px;
    color: #7CB305;
    border-bottom: 3px solid #7CB305;;
}
.tab-inner{
    padding: 0em 2em;
}
.dropdown-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
    margin: 0;
}
.reset-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #A7A7A7;
    border: none;
    cursor: pointer;
    box-shadow: none;
}
.reset-btn:hover{
    color: #A7A7A7;
    border: none;
    box-shadow: none;
    background-color: #fff;
}
.reset-btn:focus{
    color: #A7A7A7;
    border: none;
    box-shadow: none;
    background-color: #fff;
}
.view-button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
}
.view-button:hover{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
}
.view-button:focus{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    background-color: #fff;
}
.custom-table-body-discard{
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
}
.custom-table-body-discard::-webkit-scrollbar {
    height: 100%;
    width: 4px;
  }
  
  .custom-table-body-discard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .custom-table-body-discard::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
.objective-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: normal;
    color: #262626;
}
.objective-title-wrap{
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow: hidden;
    width:300px
}
.discard-small-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    margin: 0;
    padding-top: 30px;
}
.discard-small-data{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #262626;
    text-align: center;
    margin: 0;
    padding-top: 10px;
}
.dot-status{
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.discard-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px;
    text-align:left !important;
    color: #262626;
}
.discard-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: justify;
    color: #262626;
}
.comment-card{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px -18px rgba(0, 0, 0, 0.14);
    border-radius: 17px;
    padding:1.5em;
    margin-top: 1em;
}
.rational-div{
    margin-top:.5em;
    padding: 1.5em;
}

.details-div{
    margin-top: 0px;
    padding: 0px 20px;
}

.detail-div{
    background: #FFFFFF;
    border: 2px solid #BFBFBF;
    border-radius: 17px;
    padding: 30px 40px;
    width: fit-content;
}
.discard-botton-div{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px rgba(0, 0, 0, 0.14);
    height: 8em;
    position: fixed;
    bottom: 0px;
    left: 14em;
    right: 5.7em;
    /* max-width: 93.75em; */
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    padding-top: 2.5em;
    transition-duration: 1s;
}
.discard-div-margin-left{
    margin-left: 220px;
    transition-duration: 1s;
}
.approve-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    width:fit-content;
    height: fit-content;
    text-align: center;
    align-items: center;
    border:1px solid #4CCB1F;
    border-radius: 8px;
    padding: 10px 20px !important;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}
.approve-btn:hover{
    background-color: #fff;
    border:1px solid #4CCB1F;
    color: #4CCB1F;
}
.approve-btn:focus{
    background-color: #fff;
    border:1px solid #4CCB1F;
    color: #4CCB1F;
}
.disapprove-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 22px;
    text-align: center;
    color: #E86161;
    width:fit-content;
    height: fit-content;
    text-align: center;
    align-items: center;
    border:1px solid #E86161;
    border-radius: 8px;
    padding: 10px 20px !important;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}
.disapprove-btn:hover{
    background-color: #fff;
    border:1px solid #E86161;
    color: #E86161;
}
.disapprove-btn:focus{
    background-color: #fff;
    border:1px solid #E86161;
    color: #E86161;
}
.pagination-label{
    font-size: 18px;
}

.progress-modal{
    border-radius: 28px !important;
    width: 1300px !important;
    max-width: 80%!important;
}
.ant-steps-item-container > .ant-steps-item-tail::after {
    height: 10px;
}

 .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    height: 15px;
    width: 15px; 
    border-radius: 50%;
}

.ant-steps-item-icon .ant-steps-icon {
    right: 2.5px !important;
}

.ant-steps-item-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.modal-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}

.progress-add-container{
    width: 586px;
    height: 204px;
    max-width: 90%;
    background: #FFFFFF;
    box-shadow: -1px 3px 58px -18px rgba(0, 0, 0, 0.31);
    border-radius: 8px;
    padding: 10px;
}

.progress-textarea-container{
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    height: 95px;
    resize: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #BFBFBF;
    padding:5px 35px 5px 5px;
}

.progress-textarea-container:focus{
    border: 1px solid #D9D9D9;
}

.textarea-submit{
    position: absolute;
    bottom:20px;
    right:20px;
    cursor:pointer
}

.modal-subtitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}
.discard-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.okr-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}

.pop-over-content .pop-over-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.disable-textarea{
    width:100%;
    height:95px;
    resize: none;
    border: 1px solid #255F63;
    color: #fff;
    padding: 5px;
    background-color: #255F63 !important;
}

.enable-textarea{
    width:100%;
    height:95px;
    resize: none;
    border: 1px solid #fff;
    color: #fff;
    padding-top: 5px;
    border-radius: 6px;
    background-color: #255F63 !important;
    padding: 5px;
}

.textarea-scroll ::-webkit-scrollbar {
    height: 0px;
    width: 3px;
  }
  
  .textarea-scroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 5px;
    padding-right: 2px;
  }
  
  .textarea-scroll  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color:#255F63;
  }

  .comma-separated-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
  }

  .table-overlay{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.55);
    border-radius: 7px;
    display: flex;
    align-items: center;
  }

  .filter-input{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px 0px 0px 2px;
    font-family: 'Poppins';
    height: 42px;
    width:420px;
  }
  .filter-drop{
    height: 42px !important;
    width:120px !important;
  }
  .selected-filter{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #BBBBBB;
  }
  .filter-drop.ant-dropdown-trigger > .anticon.anticon-down{
    display: none;
  }
  .comp-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #1F1F1F;
  }


.discuss-btn{
    background: #009AF1;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    padding: 10px 15px;
    width:100%;
    height: fit-content;
    border: none;
    color: #fff;
    margin-top: 30px;
}
.discuss-btn:hover{
    background: #009AF1;
    color: #fff;
    border: none;
}
.discuss-btn:focus{
    background: #009AF1;
    color: #fff;
    border: none;
}
.meet-sub{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #8C8C8C;
    margin: 0;
}
.chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}

.doughnut-chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}

.ind-chart-container{
    background: #FFFFFF;
    border-radius: 15px;
    width:100%;
    margin: 10px 0px;
    padding:20px;
    border: 1px solid #D5E5F2;
}


.doughnutChartsWrapper{
    width:100%;
    display:flex;
    flex-direction:row;
}




/* @media screen and (min-width:35px) and (max-width:1800px) { */
    @media screen and (max-width:350px) {
        .ind-chart-container{
            margin: 20px 0px 20px 20px;
           }

           .doughnutChartsWrapper{
            margin: 20px 0px 20px 0px;
           }

          
           .doughnutChartsWrapper{
           margin-left:10px;
           }

           .graphs-wrapper{
            margin-left:10px;
           }

           .verticalCharts{
             margin:20px 0px 20px 20px;
           }


           .orgCharts{
            margin-left:10px;
           }

           .dashboard-tabs{
            margin-left:20px;
           }

           .dashboard-back-button{
            margin-left:20px;
           }

           .radio-doughnutChart{
            width:250px;
            margin:auto;
        }

        .ModalRadioGroup{
            margin-right:10px
        }
        
}

@media screen and  (min-width:351px) and (max-width:380px) {
    .ind-chart-container{
        margin: 20px 0px 20px 65px;
       }

       .doughnutChartsWrapper{
        margin: 20px 0px 20px 35px;
       }


       .graphs-wrapper{
        margin-left:35px;
       }

       .verticalCharts{
        margin:20px 0px 20px 65px;
      }

      .orgCharts{
        margin-left:35px;
       }

       .dashboard-tabs{
        margin-left:40px;
       }

       .dashboard-back-button{
        margin-left:20px;
       }

       .ModalRadioGroup{
        margin-right:10px
    }

}


@media screen and  (min-width:381px) and (max-width:420px) {
    .ind-chart-container{
        margin: 20px 0px 20px 45px;
       }

       .doughnutChartsWrapper{
        margin: 20px;
       }

       .graphs-wrapper{
        margin-left:20px;
       }


       .verticalCharts{
        margin:20px 0px 20px 45px;
      }

      .orgCharts{
        margin-left:20px;
       }

       .dashboard-tabs{
        margin-left:35px;
       }

       .ModalRadioGroup{
        margin-right:10px
    }

}

@media screen and  (min-width:421px) and (max-width:600px) {
    .ind-chart-container{
        margin: 20px 0px 20px 65px;
       }

       .verticalCharts{
        margin:20px 0px 20px 65px;
      }

      .orgCharts{
        margin-left:30px;
       }

       .dashboard-tabs{
        margin-left:30px;
       }

       .doughnutChartsWrapper{
        margin-left:30px;
       }


       .graphs-wrapper{
        margin-left:30px;
       }


}






.chart-title{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 49px;
    letter-spacing: -0.04em;
    color: #141414;

}
.performance-graph{
    width: 100%;
    border-left: 1px solid #BFBFBF;
    border-bottom:  1px solid #BFBFBF;
    min-height: 350px;
    vertical-align: bottom;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.performamce-overFlow{
    max-height: 430px;
    overflow: auto;
    padding-right: 10px;
    min-height: 430px;
}
.performamce-overFlow::-webkit-scrollbar {
    height: 100%;
    width: 5px !important;
  }
  
.performamce-overFlow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.performamce-overFlow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.performance-progress .ant-progress-bg{
    height: 35px!important;
}
.performance-progress .ant-progress-inner{
    background-color: #fff;
}
.progress-percentage{
    position: absolute;
    top:30px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 17px !important;
    line-height: 33px;
    color: #262626;
}
.graph-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding-left: 20px;
    max-width: 300px; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.okr-performance-graph-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding-left: 20px;
    max-width: 100%; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.user-card{
    background: #FFFFFF;
    box-shadow: -1px 3px 23px -7px rgba(0, 0, 0, 0.17);
    border-radius: 13px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    min-width: 300px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.horizontal-overflow{
    overflow: auto;
}
.horizontal-overflow::-webkit-scrollbar {
    height: 3px;
    width: 100%;
  }
  
.horizontal-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.horizontal-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.member-progress{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #4CCB1F;
}
.user-card-attribute{
    height: 70px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    min-width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.popup-padding{
    padding-left: 50px;
}
.date-range{
    width: 260px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
}
.date-range .ant-picker-input > input{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #BFBFBF;
}
.date-range .ant-picker-suffix {
margin-top: 4px;
}
@media screen and (min-width:1200px) and (max-width:1800px) {
    .page-title{
        font-size: 32px;
    }
    .add-btn{
        font-size: 18px;
        line-height: 18px;
        height: 47px;
    }
    .add-button-disable{
        font-size: 18px;
        line-height: 18px;
        height: 47px;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .input-search-expand{
        height: 38px;
        width: 208px;
    }
    .input-filter-expand{
        height: 38px !important;
        width: 133px;
    }
    .bottom-div{
        height: 70px;
    }
    .selected-item{
        width: 200px;
        height: 45px;
    }
    .modal-head{
        font-size: 26px;
    }
    .modal-sub-head{
        font-size: 15px;
        line-height: 24px;
    }
    .modal-cancel-btn{
        font-size: 16px;
        padding: 8px 25px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 16px;
        padding: 8px 25px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:35px;
        height: 35px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 22px;
    }
    .table-section-td{
        font-size: 16px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .objective-title{
        font-size: 1.5em;
    }
    .discard-small-head{
        font-size: 17px;
    }
    .discard-small-data{
        font-size: 16px;
    }
    .dot-status{
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 16px;
    }
    .discard-content{
        font-size: 16px;
        line-height: 28px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .approve-btn{
        font-size: 20px;
    }
    .disapprove-btn{
        font-size: 20px;
    }
    .comma-separated-text{
        font-size: 16px;
    }
    .filter-input{
        height: 38px;
        width:320px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 20px;
      }
      .discuss-btn{
        font-size: 20px;
        padding: 10px 15px;
     }
    .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 24px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 16px;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 18px;
    }
    .popup-padding{
        padding-left: 50px;
    }
}
@media screen and (min-width:960px) and (max-width:1200px) {
    .page-title{
        font-size: 30px;
    }
    .add-btn{
        font-size: 16px;
        line-height: 16px;
        height: 45px;
    }
    .add-button-disable{
        font-size: 16px;
        line-height: 16px;
        height: 45px;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .menu-head{
        font-size: 16px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 16px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 200px;
        height: 45px;
    }
    .modal-head{
        font-size: 24px;
    }
    .modal-sub-head{
        font-size: 14px !important;
        line-height: 23px;
    }
    .modal-cancel-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 20px;
    }
    .table-section-td{
        font-size: 15px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .discard-small-head{
        font-size: 16px;
    }
    .discard-small-data{
        font-size: 15px;
    }
    .dot-status{
        width: 11px;
        height: 11px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 15px;
    }
    .discard-content{
        font-size: 16px;
    }
    .approve-btn{
        font-size: 18px;
    }
    .disapprove-btn{
        font-size: 18px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 38px;
        width:300px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 20px;
      }

      .discuss-btn{
        font-size: 20px;
        padding: 10px 15px;
     }
     .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 22px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 16px;
    }
    .progress-percentage{
        top:28px;
    }
    .member-progress{
        font-size: 18px;
    }
    .popup-padding{
        padding-left: 40px;
    }
}
@media screen and (min-width:600px) and (max-width:960px) {
    .page-title{
        font-size: 26px;
    }
    .add-btn{
        font-size: 16px;
        line-height: 16px;
        height: fit-content;
    }
    .add-button-disable{
        font-size: 16px;
        line-height: 16px;
        height: fit-content;
    }
    .add-btn .plus-icon{
        font-size: 16px;
    }
    .menu-head{
        font-size: 16px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 16px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 180px;
        height: 40px;
    }
    .modal-head{
        font-size: 26px;
    }
    .modal-sub-head{
        font-size: 14px !important;
        line-height: 23px;
    }
    .modal-cancel-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 18px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 18px;
    }
    .table-section-td{
        font-size: 15px;
    }
    .table-section-head{
        font-size: 18px;
    }
    .discard-small-head{
        font-size: 15px;
    }
    .discard-small-data{
        font-size: 14px;
    }
    .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 14px;
    }
    .discard-content{
        font-size: 14px;
        line-height: 24px;
    }
    .approve-btn{
        font-size: 20px;
    }
    .disapprove-btn{
        font-size: 20px;
    }
    /* .discard-botton-div{
        height: 110px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 38px;
        width:280px;
      }
      .filter-drop{
        height: 38px !important;
      }
      .selected-filter{
        font-size: 14px;
      }
      .comp-head{
        font-size: 18px;
      }

      .discuss-btn{
        font-size: 18px;
        padding: 6px 15px;
     }
     .meet-sub{
        font-size: 14px;
    }
    .chart-title{
        font-size: 20px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 14px;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 16px;
    }
    .popup-padding{
        padding-left: 30px;
    }
}


@media (max-width:600px) {
    .page-title{
        font-size: 24px;
    }
    .add-btn{
        font-size: 15px;
        line-height: 15px;
        height: fit-content;
    }
    .add-button-disable{
        font-size: 15px;
        line-height: 15px;
        height: fit-content;
    }
    .add-btn .plus-icon{
        font-size: 15px;
    }
    .menu-head{
        font-size: 14px;
        line-height: 22px;
    }
    .menu-head-comma{
        font-size: 14px;
        line-height: 22px;
    }
    .input-search-expand{
        height: 36px;
        width: 150px;
    }
    .input-filter-expand{
        height: 36px !important;
        width: 120px;
    }
    .bottom-div{
        height: 60px;
    }
    .selected-item{
        width: 180px;
        height: 40px;
    }
    .modal-head{
        font-size: 20px !important;
    }
    .modal-sub-head{
        font-size: 12px;
        line-height: 22px;
    }
    .modal-cancel-btn{
        font-size: 16px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .modal-submit-btn{
        font-size: 16px;
        padding: 6px 15px;
        border-radius: 6px;
    }
    .back-btn{
        /* width:30px;
        height: 30px; */
        /* margin-bottom: 20px; */
    }
    .discard-tab .ant-tabs-tab-btn{
        font-size: 14px;
    }
    .table-section-td{
        font-size: 14px;
    }
    .table-section-head{
        font-size: 16px;
    }
    .discard-small-head{
        font-size: 14px;
    }
    .discard-small-data{
        font-size: 13px;
    }
    .dot-status{
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
    .discard-subhead{
        font-size: 13px;
    }
    .discard-content{
        font-size: 14px;
        line-height: 24px;
    }
    .comment-card{
        padding:1em
    }
    .approve-btn span{
       display: none;
    }
    .disapprove-btn span{
        display: none;
    }
    /* .discard-botton-div{
        height: 100px;
        padding-top: 30px;
    } */
    .filter-input{
        height: 36px;
        width:150px;
      }
      .filter-drop{
        height: 36px !important;
        width:90px !important;
      }
      .selected-filter{
        font-size: 12px;
      }
      .comp-head{
        font-size: 16px;
      }

      .discuss-btn{
        font-size: 16px;
        padding: 6px 15px;
     }
    .meet-sub{
        font-size: 12px;
    }
    .chart-title{
        font-size: 16px;
        font-weight: 700;
    }
    .graph-title, .okr-performance-graph-title{
        font-size: 13px;
        font-weight: 500;
    }
    .progress-percentage{
        font-size: 18px;
    }
    .member-progress{
        font-size: 15px;
    }
    .popup-padding{
        padding-left: 0px;
        padding-right:40px;
    }
}



.activeTab{
  background: #3F6600;
  cursor: pointer;
}
.nonActiveTab{
  background: #B0CB1F;
  cursor: pointer;
}
.nonActiveTab:hover{
    background: #3F6600;
    cursor: pointer;
  }
.vetical-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #3F6600 !important;
    cursor: pointer;
}
.vetical-menu.ant-menu-vertical .ant-menu-item:first-child{
    margin-top: 30px;
}
.vetical-menu.ant-menu-vertical .ant-menu-item{
    height: 60px;
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.expand-table-sider{
    height: 60px;
    width: 50px;
    min-width: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
    /* background:green; */
}

.milestone-table-expand-table-sider{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959;

}

.okr-input{
    width: 100% !important;
    border: 1px solid #D9D9D9 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959 !important;
}
.table-section-td .ant-form-item{
    margin-bottom: 0px;
    width: 100%;
}
.table-section-td .ant-form-item-explain-error,.ant-form-item-explain-success{
    display: none;
}

.table-save-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #4CCB1F;
    border-radius: 5px;
    padding: 5px 25px !important;
    border: 1px solid #4CCB1F;
}

.table-save-btn:hover{
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.range-date-picker{
    width: 100% !important;
    border: 1px solid #D9D9D9 !important;
    height: 38px;
}
.range-date-picker .ant-picker-input input{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #595959 !important;
}

.table-section-td .input-filter-dynamic{
    border:none !important

}

.details-page-logos{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.logo-margin{
    margin: 0px 20px 0px 20px;
}

.logo-wrapper{
    width: 45px;
    height: 45px;
    background: #F5F5F5;
    border-radius:50px;
}

.chat-icon{
    height: 45px;
    width:45px;
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: -1px;
    z-index: 100;
}

.chat-overlay{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
    /* opacity: .5; */
    z-index: 1;
}

.chat-container-div{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: -1px 5px 121px -26px rgba(0, 0, 0, 0.31);
    border-radius: 36px; 
    width: 100%!important;
    /* z-index: 1000px; */
}

.new-chat-message-box{
    width: 100%;
    /* background-color: #fff; */
    position: absolute;
    bottom: 30px;
}

.chat-expand{
    width: 70%;
    max-width: 450px;
}

.chat-outer-container{
    position: fixed;
    top: 180px;
    /* background: red; */
    right: 20px;
}
.new-reply-box{
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #D9D9D9;
}

.cornor-radius{
    border-radius: 3px;
}
.bottom-radius{
    border-radius: 0px 0px 3px 3px;
}


.font-family-poppins{
    font-family: Poppins;
    font-size: 16px;
}

.color-gray{
    color:#8C8C8C;
}

.empty-blocks{
    height:'40px';
    width:'75px';
}


.overlay-dots{
    height:500px;
    width: 300px;
}



.shadow-container-modal{
    background: #D9D9D9;
    width: 100%;
    border: 2px solid #D9D9D9;
    border-radius: 8px;
    padding: 40px 40px;
    margin-top: 60px;
  }
 

  .custom-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    margin: 40px 0px;
    line-height: 36px;
    color: #262626;
  }

 
  .poppins{
    font-family: Poppins;
  }


  
  .keyresultTable::-webkit-scrollbar {
    display: none;
  }



  .custom-table-tr div span::before {
    content: '';
    display: block;
  }

  .overlay-dynamic-island {
    position: fixed; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: 2;
  }
  .overlay-dynamic-island-notification {
    position: fixed; 
    top: 60px;
    bottom: 0;
    z-index: 2;
    left: 0;
    /* right: 0; */
    height: 100vh;
  }



  .display-msg-reply{
    background: #F0F0F0;
    width: 100%;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 21px -4px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 8px;
  }

  .table-side-section{
    height: 60px;
    width: 20px;
    min-width: 20px;
  }

  .table-side-section-by-sub-team{
    height: 60px;
    width: 40px;
    min-width: 40px;
  }

  .table-side-section-by-sub-employe{
    height: 60px;
    width: 80px;
    min-width: 80px;
  }

  .fs-20{
    font-size: 20px;
  }

  .fs-55{
    font-size: 55px;
  }

  .dashboard-tab{
    height: 160px;
    
  }


  .section-cascade-modal{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 20px 0px 0px 20px  !important;
    border: 1px solid #595959 !important;
    color: #595959  !important;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-cascade-modal{
    color: #FFF!important;
    background-color: #595959 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .section-non-cascade-modal{
    font-family:'Poppins';
    height: 39px !important;
    border-radius: 0px 20px 20px 0px  !important;
    border: 1px solid #595959 !important;
    color: #595959 !important;
    border-left: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 34px;
  }
  .active-non-cascade-modal{
    color: #fff !important;
    background-color: #595959 !important;
    box-shadow: none;
    font-size: 13px;
  }
  .dashboard-tabs .ant-tabs-ink-bar {
    display: none !important;
  }

  .dashboard-tabs .ant-tabs-tab{
    border-radius: 15px !important;
  }
  .dashboard-tabs .ant-carousel{
    height: 100% !important;
  }

  .dashboard-tabs .ant-carousel div{
    height: 100% !important;
  }


  .dashboard-tabs .ant-carousel .slick-slider{
    height: 100% !important;
  }
  .dashboard-tabs .ant-tabs-tab-btn{
    width:100%
  }

  .dashboard-tab-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left:10px;
    margin-right: 10px;
    margin: 0;
  }
  .dashboard-tab-content{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #D9D9D9 !important;
    margin: 0;
    padding: 10px 0px;
  }

  .ant-tabs-tab-active .dashboard-tab-title{
    color: #fff !important;
  }

  .ant-tabs-tab-active .dashboard-tab-content{
    color: #fff !important;
  }

 .tab-child{
    min-width: 1000px;
 }
 .tab-child .ant-tabs-nav-wrap{
    border-bottom: none;
 }
 .dashboard-tabs::-webkit-scrollbar {
    height: 3px;
    
  }
  
  .dashboard-tabs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  .dashboard-tab-inner-container{
    padding: 10px 10px;
  }
  .dashboard-tabs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
    cursor: pointer;
  }

  .dashboard-tabs .ant-spin-dot-item {
    background-color: #FFF;
  }
  .dashboard-tabs .green-spinner .ant-spin-dot-item {
    background-color: #B0CB1F ;
  }
  
  .cascadedOkrs{
    margin-right:15px;
  }

  .nonCascadedOkrs{
    margin-left:15px;
  }


.spinner{
    width: 95%;
    background:#FFFFFF;
    height: 100%;
    border-radius: 15px;
}

  .performanceContainer{
    padding:30px 80px;
   }

 
  @media screen and (max-width:1150px) {
    .performanceContainer{
        padding:30px 30px;
      }
    
  }


  @media screen and (max-width:768px) {
      .spinner{
        width: 100%;
      }

      .cascadedOkrs{
        margin-right:0px;
      }


    
  .nonCascadedOkrs{
    margin-top:15px;
    margin-left:0px;
  }

  }




  .mb-20{
    margin-bottom:20px;
  }


  .round{
    margin-right: 6px;
    width: 12px;
    height:12px;
    border-radius:20px;
  }

  .legend-missed{
    background: #EF7878;
  }

  .legend-In-Progress{
    background: #FAC53C;
  }

  .legend-completed{
    background: #9FEA84;
  }

  .legend-discarded{
    background: #8A8A8A;
  }

  .legend-pending{
    background: #009AF1;
  }

  .Manrope{
    font-family:'Manrope';
  }

  .okrPerformance-table-heading{
    color: #A3A3A3;
    font-weight: 500;
  }

 @media screen and (max-width:500px) {
    .ant-picker-date-range-wrapper .ant-picker-panel-container {
        width:260px;
        overflow: auto;
     }

 }

 @media screen and (max-width:350px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 290px;
        margin-left:17px;
   }
 }

 @media screen and (min-width:351px) and  (max-width:380px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 310px;
        margin-left:40px;
   }
 }


 @media screen and (min-width:381px) and  (max-width:500px) {
    
    .select-content-div{
       width:260px;
    }

    .custom-menu-dashboard{
        width: 330px;
        margin-left:55px;
   }
 }


 @media screen and (min-width:500px) and  (max-width:600px) {
    
    

    .custom-menu-dashboard{
        
        margin-left:80px;
   }
 }
.inactive-user{
display: block;
}
.active-user{
    display: none;
}
.active-profile:hover .active-user{
    display: block;
}
.active-profile:hover .inactive-user{
    display: none;
}
.bell-inactive{
    display: block;
}
.bell-hover{
    display: none;
}

.active-notification:hover .bell-inactive{
    display: none;
}
.active-notification:hover .bell-hover{
    display: block !important;
}
.notifcation-icons{
    margin-bottom: -12px;
}
 .question-inactive{
    display: block;
 }
 .question-hover{
    display: none;
 }
 .question-sction:hover .question-hover{
    display: block;
 }
 .question-sction:hover .question-inactive{
    display: none;
 }

/* .nav-menu .ant-menu-horizontal{
    line-height: 62px;
} */

.ant-layout-header {
    line-height: 0px !important;
    height: 0px !important;
    padding: 0px 0px;
}
.icon-size{
    width: 19px;
    height: 24px;
}

.icon-size-profile{
    width: 17px;
}

.icon-size-question{
    width:22px
}
.three-dot-menu{
    border-radius: 13px;
    padding: 10px 0px;
}
  .popover-progress-tracker {
    position: fixed; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    z-index: 2;
  }

  .full-width-progress{
    display: flex;
    padding-left:80px;
    border-radius:50px;
    padding-right:80px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .incomplete-progress-width{
    display: flex;
    padding-left:80px;
    border-top-left-radius: 50px;
    border-bottom-left-radius : 50px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .full-width-progress{
    display: flex;
    padding-left:80px;
    border-radius:50px;
    padding-right:80px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }


  .incomplete-progress-width{
    display: flex;
    padding-left:80px;
    border-top-left-radius: 50px;
    border-bottom-left-radius : 50px;
    background:linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%);
  }



.date-range-okr{
    visibility: visible;
    /* height: 0;
    padding: 0; */
    width: 40%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    /* padding-left: 24px; */
    cursor: pointer;
    /* min-width: 480px; */
    /* position: absolute; */
}
.date-range-okr input{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
    color: #009AF1;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
    z-index: 1100 !important;

}
.date-range-okr input::placeholder{
    content: '-';
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
}
.date-range-okr .ant-picker-range-separator{
    display: none !important;
}
.date-range-okr .ant-picker-suffix{
   display: none !important;
}
.date-range-okr .ant-picker-active-bar{
    display: none !important;
}
.date-range-okr input:hover::after{
    content: 'tttt';
}
/* .date-range-okr .ant-picker-range-arrow{
display: none;
} */
.custom-date{
    margin-top: -15px;
    padding: 0px;
    font-family: 'Poppins';
    font-size: 12px;
    max-width: 440px;
}
.custom-date .ant-picker-panel-container{
    box-shadow:none;
    border-radius: none;
    border: none;   
    margin-left: 3px !important;
    position: absolute;
    top:-300px !important;
}
.custom-date .ant-picker-range-arrow{
    display: none;
}
.custom-date .ant-picker-range-wrapper{
    /* min-width: 300px; */
    max-width: 540px;
}
.custom-date .ant-picker-date-panel .ant-picker-content{
    width:200px !important;
}
.custom-date .ant-picker-cell {
    padding: 0px;
}
.custom-date .ant-picker-date-panel .ant-picker-content th {
    width:fit-content;
}
.custom-date .ant-picker-date-panel{
    width:fit-content;
}
/* .custom-date .ant-picker-header-super-prev-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-prev-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-next-btn{
    visibility: visible !important;
}
.custom-date .ant-picker-header-super-next-btn{
    visibility: visible !important;
} */
/* .custom-date .ant-picker-header-super-next-btn{
    display: none;
} */
/* .custom-datePicker-container{
    border-right: 1px solid rgb(171, 171, 171);
} */
.date-label-head{
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.seletion-title{
    padding: 3px 10px;
    text-align: center;
    font-size: 12px;
    font-family: 'Poppins';
    cursor: pointer;
    justify-content: center;
    white-space: nowrap;
    margin-top:3px;
}
.seletion-title:hover{
    background-color: #B0CB1F;
    color: #fff;
}
.overflow-selection{
    max-height: 110px;
    overflow: auto;
    padding-right:10px;
    padding-top: 10px;
}

.overflow-selection::-webkit-scrollbar {
    height: 100%;
    width: 3px !important;
  }
  
.overflow-selection::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.overflow-selection::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}
.see-more{
    text-align: center;
    color: #B0CB1F;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.seletion-title-selected{
    background-color: #B0CB1F;
    color: #fff;
    padding: 3px 10px;
    text-align: center;
    font-size: 12px;
    font-family: 'Poppins';
    cursor: pointer;
    justify-content: center;
    margin-top:3px;
}
.custon-datepicker-btn{
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    height: 30px;
    background: #B0CB1F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
.custon-datepicker-btn:hover{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #B0CB1F !important;
  }
  .custon-datepicker-btn:focus{
    border: none !important;
    color: #FFFFFF!important;
    background-color: #B0CB1F !important;
  }
  .custon-datepicker-btn .plus-icon{
    font-size: 16px;
}
.custon-datepicker-btn-cancel{
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    height: 30px;
    background: #FFF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #303030;
}
.custon-datepicker-btn-cancel:hover{
    color: #303030!important;
    background-color: #FFF!important;
  }
  .custon-datepicker-btn-cancel:focus{
    color: #303030!important;
    background-color: #FFF!important;
  }
  .custon-datepicker-btn-cancel .plus-icon{
    font-size: 14px;
}
.display-date{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 24px;
    color: #303030;
    margin: 0;
    white-space: nowrap;
}

/* @media (max-height:640px) {
    .custom-date{
        top:260px !important;
    }
} */
.horizontal-overflow{
    width:100%;
    overflow: auto;
    background-color: #fff;
}
.horizontal-overflow::-webkit-scrollbar {
    height: 3px;
    width: 100px !important;
  }
  
.horizontal-overflow::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}
  
.horizontal-overflow::-webkit-scrollbar-thumb {
border-radius: 10px;
background-color: #F0F0F0;
}

.horizontal-overflow-team-dashboard{
    width:100%;
    overflow: auto;
    background-color: #fff;
}
.horizontal-overflow-team-dashboard::-webkit-scrollbar {
    height: 3px;
    width: 7px !important;
  }
  
.horizontal-overflow-team-dashboard::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
margin-left: 10px;
margin-bottom:20px;
margin-top:10px
}

@media (max-width:740px) {
    .custom-okr-picker{
        display: none !important;
    }
    .custom-date{
        display: none !important;
    }
    .custom-menu{
        display: none !important;
    }
    .okr-picker{
        display: block;
        flex-wrap: nowrap;
    }
}
@media (min-width:740px) {
    .custom-okr-picker{
        display: block !important;
    }
    .okr-picker{
        display: none !important;
    }
    .okr-picker .ant-picker-dropdown{
        display: none !important;
    }
    .custom-date-small{
        display: none !important;
    }
}

.custom-menu-expandable{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: -1px 6px 27px -9px rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    margin-top:0px;
    max-width:450px;
    min-width: 450px;
    width:100%;
    position:absolute;
    top:0px;
    z-index:1000
}
.overlay-dynamic-island-nav {
    position: fixed;
    width:100%;
    z-index: 2;
    left: 0;
    height: 100vh !important;
  }
  .nav-profile-dropdown{
    width:180px;
    padding: 20px;
    background: #F5F5F5;
    border-radius: 14px;
    margin-top:25px !important;
    margin-right: -20px !important;
  }
  .notification-dropdown{
    width:350px;
    border-radius:14px;
    background: #F5F5F5;
    margin-top:25px !important;
    margin-right: -50px !important;
  }
  .nav-drop-content-div{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    color: #000000;
    cursor: pointer;
  }
  .notification-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    text-align: left;
    max-width: 250px;
  }
  .notification-title-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
  }
  .hover-notification-titile:hover{
    cursor: pointer;
    color: #4CCB1F;
  }
  .tab-style-note{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #B3B3B3;
    cursor: pointer;
  }
  .selected-tab-note{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  .undo-button{
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-decoration-line: underline;
    text-decoration-color: #595959;
    text-underline-offset: 3px;
    color: #595959;
  }

.ant-message{
    z-index: 10000;
}
.selected-tab-non-selected{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8C8C8C;
    margin: 0;
    padding: 5px 5px;
}
@media screen and (min-width:950px)  {
    .admin-access{
        margin-left:20px ;
    }
  
    .admin-access li{
     margin: 0px 3px !important;
    }
}

@media screen and (max-width:950px)  {
    .admin-access{
        margin-left:20px ;
    }
  
    .admin-access li{
     margin: 0px 3px !important;
    }
}

.progress-input{
    width:55px
}
/* .custom-okr-picker{
    width: 280px !important;
    min-width: 280px !important;
} */



.role-image-colored{
    display: block;
}

.role-image-bw{
    display: block;
}

.ant-switch-checked {
    background-color: #2EC7FF !important;
 }
/* .role-card-images:hover .role-image-colored{
    display: block;
}

.role-card-images:hover .role-image-bw{
    display: none;
} */


@media screen and (max-width:950px) {
    .vetical-menu{
        display: none;
    }
    .side-tool-hide{
        display:none;
      }
    .okr-table-gap{
        gap: 0px !important;
    }
    .mobile-vertical-nav-okr{
        z-index: 1000;
        display: block;
        height: 40px;
        width: 40px;
        background-color: rgb(176, 203, 31);
        position: fixed;
        top: 100px;
        right: 0px;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nav-opener{
    width: 30px;
    height: 30px;
}

.expand-vertical-bar{
    width: 280px !important;
    z-index: 1000;
}

.expand-vertical-bar ul{
    width: 280px;
    background-color: transparent;
}

.expand-vertical-bar li{
    height: 35px !important;
    padding: 0px !important;
}
.expand-vertical-bar li img{
    width: 30px;
    height: 30px;
    margin-top: -28px;
}

.rotate-icon {
    transform: rotate(180deg) !important;
}

.competency-select .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;   
    border: 1px solid #C2C2C2;
}
.competency-select-invalid .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;   
    border: 1px solid #ff4d4f !important;
}
.competency-select .ant-select-selector .ant-select-selection-item{
    line-height: 37px !important;
}
.without-tool-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}
.without-tool-ck-editor .cke_chrome{
    border: none;
    padding: 0 !important;
}
.without-tool-ck-editor .cke_bottom{
display: none;
}
.without-tool-ck-editor .cke_reset{
    padding: 0px 0px;
}
.without-tool-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width:5px;
  }
.without-tool-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
}

.without-tool-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
}
.ant-list-split .ant-list-item {
    border-bottom: none !important;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }


  /* Styles for impact narratives */

  .impact-narrative-visible{
    display: block;
  }

  .impact-narratives{
    display: none;
    visibility: hidden;
  }

  .pointer-events-know-more{
    pointer-events: none;
  }

  .hide-know-more{
    display: none !important;
  }

  .row-dragging-group {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
   } 

   .row-dragging-group td:last-child {
    position: relative;
    right: 0px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    
   } 

   .row-dragging-group td:nth-child(2){
    margin-left: -335px;
   }


   .row-dragging-group td:nth-child(3){
    margin-left: -340px;
   }

   .bread-crumb-last-child{
    margin-left: 0.31em;
   }

   .global-root-em{
    font-size: 16px;
   }

   .container-row-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   }

   .main-page-card{
    background: #FFFFFF;
    border-radius: 12px;
    padding: 2em 0.5em;
    min-height: 80vh;
   }

   .menu-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    width:30px
  }

  .menu-icon .dot {
    width: 6px;
    height: 6px;
    min-height: 6px;
    min-width: 6px;
    border-radius: 50%;
    margin: 4px;
    background-color: #8C8CA1;
  }
  .rd-input-star-grow{
    color: #E86161;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em; 
    padding-top: -1px !important;
}
@media (max-width:1200px) {
    .discard-botton-div{
        left:12em;
        right:3.9em
    }
}
@media (max-width:960px) {
    .discard-botton-div{
        left:3.5em;
        right:2.9em
    }
    .discard-div-margin-left{
        margin-left: 0px;
        }
}

.pointer{
    cursor: pointer;
}

.text-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.video-preview-modal{
    max-width: 68vw;
    width: 100%;
}
.video-preview-modal .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
}
.video-preview-container {
    margin: 1em 3em ;
}
.preview-video{
    width: 100%;
     max-height:75vh !important;
     min-height: 75vh !important;
     border-radius:1em;
     background:#D9D9D9;
}
@media (max-width:960px){
    .video-preview-container {
        margin: 1em 2em ;
    }
    .preview-video{
        width: 100%;
        max-height:50vh !important;
        min-height: 50vh !important;
    }
    .video-preview-modal{
        max-width: 80vw;
        width: 100%;
    }
}
@media (max-width:600px){
    .video-preview-container {
        margin: 1em 0em ;
    }
    .preview-video{
        width: 100%;
        max-height: 40vh !important;
        min-height: 40vh !important;
    }
    .video-preview-modal{
        max-width: 100vw;
        width: 100%;
    }
}
.marquee-text{
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.marquee-text span{
    font-size: 14px !important;
    margin: 0px !important;
    margin-left: 5px !important;
}

.marquee-container{
    width: 100%;
    background: rgb(106, 234, 60);
    padding:5px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: .5em;
}
.marquee-container img{
    cursor: pointer;
}
.text-transform-capitalize {
    text-transform: capitalize;
}
.generic-date-picker-date .ant-picker-footer{
    display: none !important;
}

*{
    font-family: Poppins !important; 
}
