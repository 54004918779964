
.poc-dahboard-root{
    font-size: 16px;
    font-family: 'Poppins';
    width: 100%;
    height: 100%;
}
.dashboard-home-banner-cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.dashboard-banner-card-continer{
    width: 25%;
    padding: 1em;
}
.dasboard-banner-card{
    background: #FFFFFF;
    border-radius: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
   
}
.poc-banner-icon{
    width:4em;
    height: 4em;
}
.poc-banner-title{
    color: #000000;
    font-size: 1.2em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.poc-banner-count{
    color: #000000;
    font-size: 2.5em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    margin: 0px;
}
.poc-dashboard-card-title{
    color: #000000;
    font-size: 1.5em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    /* white-space: nowrap; */
    margin: 0px;
}
.poc-dashboard-card-container{
    width: 50%;
    padding: 1em;
    height: 100%;
}
.poc-align-spacebetween{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
}
.poc-licence-sub-title{
    color: #000000;
    font-size: 1.25em;
    font-weight: 500;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.poc-active-btn,.poc-active-btn:hover,.poc-active-btn:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: none;
    border-radius:.5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: Poppins !important;
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}
.poc-card-flex{
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    height: 100%;
}
.vertical-select .ant-select-selector,.vertical-select .ant-select-selector:hover,.vertical-select .ant-select-selector:focus {
    border: none !important;
    border-radius: 0px;
    box-shadow: none !important;
    border-color:#fff !important
}
.vertical-select,.vertical-select:not(.ant-select-disabled):hover,.vertical-select:not(.ant-select-disabled):focus{
    border-color:#fff !important;
    box-shadow: none !important;
}
.export-btn,.export-btn:focus,.export-btn:hover {
    border: 1px solid rgba(170, 186, 198, 1);
    background: rgba(255, 255, 255, 1);
    color: #000;
    font-size: .85em;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    padding: .3em 1em;
    border-radius: .5em;
    width: fit-content;
}
.org-survey-completed{
    background-color: rgba(76, 203, 31, 0.2);
    color:rgba(76, 203, 31, 1);
    padding:.25em 1em;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    font-style: normal;
}
.org-survey-pending{
    background-color: rgba(249, 95, 83, 0.2);
    color:rgba(249, 95, 83, 1);
    padding:.25em 1em;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    font-style: normal;
}
.assesment-card{
    border: 1px solid rgba(170, 186, 198, 1);
    border-radius:1em;
    background: rgba(213, 229, 242, 0.2);
    width:100%;
    padding:1em;
    margin-top:1em;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: start;
    gap:1em;
}
.assessment-report-container{
    width:100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    overflow: auto;
}
.assessment-report-text-container{
    width: 50%;
}
.report-chart-table-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}
.report-grap-container{
    width: 60%;
}
.report-table-container{
    width: 40%;
}
@media (max-width:1600px) {
    .poc-dahboard-root{
        font-size: 14px;
    }
    
}
@media (max-width:1400px){
    .assessment-report-container{
        flex-direction: column;
    }
    .assessment-report-text-container{
        width: 100% !important;
    }
    .report-grap-container{
        width: 100%;
    }
    .report-table-container{
        width: 100%;
    }
}
@media (max-width:1200px) {
    .poc-dahboard-root{
        font-size: 12px;
    }
}
@media (max-width:1070px){
    .report-chart-table-container{
        flex-direction: column;
    }
    .report-grap-container{
        width: 100% !important;
    }
    .report-table-container{
        width: 100% !important;
    }
}
@media (max-width:960px) {
    .poc-dahboard-root{
        font-size: 10px;
    }
}

.card-height{
    height: 28em;
}

@media (max-width:600px) {
    .poc-dahboard-root{
        font-size: 8px;
    }
    .poc-card-flex{
        display: flex;
        flex-direction:column;
        gap: 1em;
        width: 100%;
        height: 100%;
    }
    .card-height{
        height: 50em;
    }
    .poc-analystic{
        height: 48em !important;
    }
}
@media (max-width:560px) {
    .dashboard-banner-card-continer{
        width: 50%;
    }
}
@media (max-width:320px) {
    .dashboard-banner-card-continer{
        width: 100%;
    }
}

.poc_bar_chart_container{
    min-height: 300px;
    width: 100%;
}

.poc-analystic{
    height: 21em; 
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0px;
    overflow: auto;
}

.poc-analystic::-webkit-scrollbar {
    height: 100%;
    width: 4px;
    display: block;
  }
  
  .poc-analystic::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .poc-analystic::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

.analystic-main-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
}
.analystic-container{
    width: 45%;
    padding: 1em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    background: rgba(243, 249, 246, 1);
    margin: 0.5em 0em;
    border-radius: 8px;
    cursor: pointer;
}

.table-border-left{
    border-left: 1px solid #D5E5F2 !important;
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    border-right: none !important;
}
.table-border-right{
    border-left: none !important;
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    border-right: 1px solid #D5E5F2 !important;
}

@media screen and (max-width:1200px) {
    .analystic-container{
        width: 100%;
    }
}

