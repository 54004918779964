.org-table-container{
    width: 100%;
    background-color: #fff;
    overflow: auto;
}
.org-table{
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.org-row-header{
    width: 100%;
    background: #31D0AA;
}

.org-row-header th{
    padding: 1em 4px;
    background: #31D0AA;
}

.org-table-row-padding-left{
    padding-left: 2em !important;
}

.org-table-row-padding-rigth {
    padding-right: 2em !important;
}

.org-content-center{
    text-align: center;
}

.org-content-right{
    text-align: right;
}

.org-row-body{
    background: #FCFCFC;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.org-row-body td{
    padding: 0.5em 4px;
}

.td-center-padding{
    padding-left: 1.6rem !important;
}

.org-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.org-table-body-label{
    color:  #1F1F1F;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.175em;
    margin: 0px 0.5em;
}
.org-table-body-label_disabled{
    color:  #d5d5d5;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.175em;
    margin: 0px 0.5em;
}
.org-left-radious{
  border-top-left-radius: 0.5em !important;
  border-bottom-left-radius: 0.5em !important;
}

.org-right-radious{
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.org-table-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
}

.org-table-actions-right{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    gap: 1.5em;
}
.org-poc-type{
    background: rgba(72, 133, 121, 0.5);
    color:#FFF;
    padding: .5em;
}
.org-employee-type{
    border: 1px solid rgba(170, 186, 198, 1);
    padding: .5em;
    color:rgba(0, 0, 0, 1);
}
.org-vertical-type{
    background: rgba(38, 196, 165, 0.1);
    color: rgba(16, 165, 136, 1);
    padding: .5em 1em;
}
.org-status-active{
    border: 1px solid rgba(236, 241, 244, 1);
    background-color: #fff;
    color: rgba(67, 67, 67, 1);
    font-size: .9em;
    padding: .5em 1em;
    max-width: 8em;

}
.org-status-active span {
    background: rgba(13, 214, 113, 1);
    height: .65em;
    width: .65em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .75em;
}
.org-status-inactive{
    border: 1px solid rgba(236, 241, 244, 1);
    background-color: #fff;
    color: rgba(67, 67, 67, 1);
    font-size: .9em;
    padding: .5em 1em;
    max-width: 8em;

}
.org-status-inactive span {
    background: rgba(106, 106, 106, 1);
    height: .65em;
    width: .65em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .75em;
}
.org-poc-select-filed .ant-select-selector{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid rgba(170, 186, 198, 1);
    font-size: 1em;
}
.org-poc-select-filed .ant-select-selector:hover,.org-poc-select-filed .ant-select-selector:focus,.org-poc-select-filed .ant-select-selector:focus-visible,.org-poc-select-filed .ant-select-selector:focus-within{
    border-radius: .5em;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    outline: 0;
    box-shadow: none !important;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 4px !important;
    color: rgba(137, 152, 162, 1) !important;
    font-weight: 300;
    font-size: 1em;
    margin-left: 0px;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-search-input {
    height: 2.75em !important;
}
.org-poc-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 6px !important;
    font-weight: 400;
    font-size: 1em;
}
.org-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    
}
.org-option.ant-select-item p{
    margin: 0px;
}
.org-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.org-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
    
}
.org-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
}

.poc_table_scroll{
    overflow: auto;
    padding-right: 1rem;
}
.poc_table_scroll::-webkit-scrollbar {
    height: 100%;
    width: 8px;
    display: block;
  }
  
  .poc_table_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .poc_table_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .container-alignment{
    max-height: calc(80vh - 200px);
    margin-top: 1em;
  }

  .container-alignmentpoc-survey-response{
    max-height: calc(80vh - 200px);
    margin-top: 1em;
  }
  .org-export-btn-parent .ant-spin-dot-item {
    background-color: rgba(137, 152, 162, 1) !important;
  }
  .org-export-btn-parent .ant-spin-text {
    color: rgba(137, 152, 162, 1) !important;
  }
  .org-export-btn-parent .ant-progress .ant-progress-inner{
    width: 45px !important;
    height: 45px !important;
    font-size: 10px !important;
  }
  .org-export-btn-parent .ant-progress .ant-progress-inner .ant-progress-text{
        color: #4CCB1F !important;
  }