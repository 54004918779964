.tool-body-padding{
    padding:0px 16px
}
.tool-background{
    background:#F7F7F7
}
.space-between-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px; 
    justify-content: space-between;
    align-items: center;
}
.text-render-margin{
    margin: 15px;
}
.tool-titile{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    color: #141414;
    margin: 0px;
  }

.soul-section{
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-top: 41px; */
    padding: 20px 0px;
}
.soul-top-margin{
    margin-top: 41px;
}

.soul-fluid-container{
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1460px;
}

.first-section{
    flex-wrap: nowrap !important;
    display: flex;
    align-items: center;
}


.top-section-container{
    width: 45%;
    margin: 15px;
    /* margin: 21px; */
}

.inst-header{
    font-size: 22px;
}

/* .top-section-container li{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    color: #1F1F1F;
} */

.top-section-container video{
    max-height: 431px;
    max-width: 762px;
    width: 100%;
    height: 100%;
    border-radius: 19px !important;
}

.bottom-section-container{
    width: 100%;
    margin: 15px;
    /* margin: 21px; */
    /* background-color: red; */
}

.soul-value-section{
    width: 321px;
    /* width: 25% !important; */
    background: #FFFFFF !important;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06) !important;
    border-radius: 19px !important;
    height: fit-content;
}

.soul-value-section video{
    width: 310px !important;
    margin: 5px;
}

.index-form{
    /* max-width: 1100px; */
    background: #FFFFFF;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06);
    border-radius: 19px;
    width: 100% !important;
    overflow: hidden;
}

.soul-wrap{
    display: flex;
    flex-direction: row;
}

.form-wrap-section{
    /* max-width: 1100px; */
    background: #FFFFFF;
    box-shadow: -6px 2px 20px 12px rgba(0, 0, 0, 0.06);
    border-radius: 19px;
    width: 46% !important;
    overflow: hidden;
   
}

.soul-tool-title-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    /* background-color: green; */
    padding: 50px;
}

.soul-tool-title-label{
    max-width: 498px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #141414;
    margin: 0px;
   
}

.soul-form-carousel .slick-list{
    width: 100% !important;
}
.soul-form-carousel .ant-carousel .slick-dots-bottom{
    top: -15px !important;
}

.submitResponseButton{
    width: 140px;
    height: 50px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}

.submitResponseButton:hover{
    background: #4CCB1F;
    color: #FAFAFA;
    border: 2px solid #4CCB1F;
}

.submitResponseButton:focus{
    background: #4CCB1F;
    color: #FAFAFA;
    border: 2px solid #4CCB1F;
}
.submitResponseButton-back{
    width: 140px;
    height: 50px;
    background: #FAFAFA;
    border: 2px solid #8C8C8C;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
}

.submitResponseButton-back:hover{
    background: #FAFAFA;
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}

.submitResponseButton-back:focus{
    background: #FAFAFA;
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}
.specific-tool .slick-current{
    margin-top: 80px;
  }
  
.specific-tool .ant-carousel .slick-dots-bottom{
    top:12px;
    height: 10px;
  }
    
.specific-tool .ant-carousel .slick-dots li button{
    background: #D9D9D9;
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
  
.specific-tool .ant-carousel .slick-dots li.slick-active{
    width: 17px;
  }
  
.specific-tool .ant-carousel .slick-dots li.slick-active button{
    background: #4CCB1F;
  }
  .specific-tool .ant-carousel .slick-dots li button:before{
    color: #ffff;
    visibility: hidden;
  }
  .specific-tool .ant-carousel .slick-dots{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .carouselWrapper{
    width:90%; 
    margin:auto;
  }

  .specific-tool .ant-carousel .slick-prev{
    position: absolute;
    top: -18px;
    display: block !important;
    border: none !important;
    background: transparent !important;
  }
  
  .specific-tool .ant-carousel .slick-next{
    position: absolute;
    top: -18px;
    display: block !important;
    border: none !important;
    background: transparent !important;
  }


  .specific-tool .ant-btn-background-ghost{
    color: #8C8C8C !important;
    border: none !important;
    box-shadow: none !important;
  }

.ant-popover-inner{
    padding: 15px;
    }



.soul-space-btw{
    display: flex;
    justify-content: space-between;
}

.tool-bread-crubs-container{
    position: fixed;
    top: 20px;
    left: 50%;
    right: 50%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.tool-bread-crubs-tab{
    border-radius: 5px;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #8C8C8C;
    background: transparent;
    height: fit-content;
    border: none;
}
.tool-bread-crubs-tab:hover{
    background: transparent;
    color: #8C8C8C;   
}
.tool-bread-crubs-tab:focus{
    background: transparent;
    color: #8C8C8C;   
}
.tool-active-bread-crubs{
    background: #E1FFD7;
    width: fit-content;
    color: #6BB96A;
}
.tool-active-bread-crubs:hover{
    color: #6BB96A;   
    background: #E1FFD7;
}
.tool-active-bread-crubs:focus{
    color: #6BB96A !important;   
    background: #E1FFD7 !important;
}
.tool-padding-form{
    padding-right: 30px;
}

.new-set-btn{
    padding: 0px 15px;
    height: 40px;
    border: 1.5px solid #8C8C8C;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    background: #fff;
}
.new-set-btn:focus,.new-set-btn:hover{
    color: #8C8C8C;
    border: 2px solid #8C8C8C;
}
@media screen and (max-width:1300px) {
    .soul-space-btw{
        flex-direction: column !important;
    }
    .bottom-section-container{
        width: 100% !important;
    }
    .form-wrap-section{
        width: 99% !important;
        margin: 15px 5px;
    }
}

@media screen and (min-width:950px) and  (max-width:1300px) {
    .tool-bread-crubs-container{
            gap: 10px;
    }
    .tool-bread-crub{
            gap: 10px;
    }
}

@media screen and (min-width:800px) and  (max-width:950px) {
    .soul-tool-title-label{
        font-size: 23px;
    }
    .tool-titile{
            font-size: 28px; 
    }
}

@media (max-width:950px) {
    .top-section-container{
        width: 100%;
    }
    .text-render-margin{
        margin: 5px 20px;
    }
    .soul-top-margin{
        margin-top: 5px;
    }

    .tool-background{
        background:transparent
    }
    .tool-bread-crubs-container{
            display: none !important;
    }
}
@media screen and (min-width:600px) and  (max-width:800px) {
    .soul-tool-title-label{
        font-size: 20px;
    }
    .tool-titile{
        font-size: 28px; 
    }
}

@media screen and (max-width:600px)  {
    .soul-value-section{
        padding: 0px;
        box-shadow: none !important;
    }
    .values-wrapper{
        padding: 0px !important;
    }
    .submitResponseButton{
        max-width: 217px;
        width: 90%;
        border: 1.26414px solid #4CCB1F;
        border-radius: 93px;
    }
    .submitResponseButton-back{
        max-width: 217px;
        width: 90%;
        border: 1.26414px solid #8C8C8C;
        border-radius: 93px;
    }
    .soul-tool-title-section{
        flex-direction: column;
    }
    .tool-web-screen{
        display: none !important;
    }
    .soul-tool-title-label{
        font-size: 18px !important;
    }
    .inst-header{
        font-size: 18px;
    }
    /* .top-section-container li{
        font-size: 14px;
    } */
    .tool-body-padding{
            padding:0 0.7em;
    }
    .index-form{
        box-shadow: none !important;
        padding: 0px !important;
    }
    .form-wrap-section{
        box-shadow: none !important;
        padding: 0px !important;
    }
    .tool-padding-form{
        padding-right: 30px;
    }
    .tool-titile{
        font-size: 18px;
        line-height: 20px !important;
        color: #141414;
        white-space: normal;
        margin: 0px !important;
        text-align: center !important;

    }
}

.ant-popover-buttons .ant-btn-sm{
    border: 1px solid rgba(0, 0, 0, 0.85);
    border-color: #141414;
}


.ant-popover-buttons .ant-btn-sm:hover{
    background: #FFFFFF;
}



.ant-popover-buttons .ant-btn-sm:hover span, .ant-popover-buttons .ant-btn-sm:active{
    color: rgba(0, 0, 0, 0.85);
}



.ant-popover-buttons .ant-btn-primary{
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.ant-popover-buttons .ant-btn-primary:hover span{
    color: #fff;
}

.ant-popover-buttons .ant-btn-primary:hover{
    background: #4CCB1F;
    color: #fff;
    border: 1px solid #4CCB1F;
}





.extra-padding{
    padding-left: 50px;
}