.sales-page-link {
    font-size: 17px;
    cursor: pointer;
}

.tool-nav-item-sales {
    font-size: 17px;
}

.back-icon-thank-you{
    width: 18px;
    filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.thank-you-message-text{
    font-size: 14px;
    font-weight: 500;
}

.page-intro-header-sales-page {
    font-size: 45px;
    font-weight: 700;
}

.auto-margin-sales-page {
    margin: auto;
}

.sales-page-hero-section {
    margin-top: 55px;
}

.sales-page-tagline {
    font-size: 22px;
    color: #4CCB1F;
    font-family: poppins;
    font-weight: 700;
    margin-top: 25px;
}

.sales-page-navbar-button {
    background-color: #4CCB1F;
    color: #FFFFFF;
    height: 40px;
    border-radius: 8px;
    font-weight: 700;
}

.sales-page-navbar-button-panel .ant-btn:hover,
.sales-page-navbar-button-panel .ant-btn:focus {
    background-color: #4CCB1F;
    color: #FFFFFF;
}

.sales-page-link:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2.5px;
    text-decoration-color: rgb(186, 208, 64);
}

.team-size-menu-element {
    background: #FFFFFF;
    text-align: left;
    color: #8C8CA1;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    padding: 20px;
    border: 1px solid #ECF1F4;
    border-radius: 8px;
}



.team-size-menu-element:hover{
    background-color:rgb(236, 241, 244);
    color: #000000;
}


.ordered-list-sales-page {
    font-weight: 500;
    height: 40px;
    font-size: 18px;
    letter-spacing: 0.9px;
}

.explore-mapp-sales-page:hover {
    color: #FFFFFF;
    background: #4CCB1F;
}


.explore-mapp-sales-page {
    width: 30%;
    height: 50px;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 8px;
    color: #FFFFFF;
    background: #0D57FF;
}

.soul-page-ordered-list {
    font-weight: 500;
    font-size: 16px;
    font-family: poppins;
}

.soul-identity-margin {
    margin: 20px auto;
}


.soul-identity-title-container {
    line-height: 30px;
    justify-content: start;
}

.soul-identity-bread-crumb {
    color: #2BA341;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 20px 4px 20px;
    text-align: center;
    border-radius: 40px;
    background: #CDEFBF 80%;
    text-transform: uppercase;
}

.soul-identity-bread-crumb-container {
    justify-content: start;
}

.tool-break-crumb-sales {
    padding: 4px 30px 4px 30px;
}

.button-link-sales-page {

    height: 50px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 8px;
    font-family: poppins;
    color: #FFFFFF;
    background: #4CCB1F;
}

.button-link-sales-page span {
    letter-spacing: 0.8px;
    font-size: 16px;
}

.button-link-sales-page:hover {
    background: #0D57FF;
    color: #FFFFFF;
}

.soul-identity-header {
    font-size: 40px;
    line-height: 100px;
    font-weight: 650;
}

.tool-card-sales-page {
    padding: 40px;
    min-height: 415px;
    border-radius: 20px;
    background: #F8FAFF;
}

.tool-card-sales-page:hover {
    background: #054BFF;
    transition: 0.5s ease;
}

.contact-number-container .ant-input-prefix {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.tool-nav-item-sales:hover {
    color: #000000;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2.5px;
    position: relative;
    z-index: 1100;
    text-decoration-color: rgb(186, 208, 64);
}


.tool-title-sales-pape,
.tool-description-sales-pape,
.sales-page-tool-link {
    color: #262626;
}

.tool-card-sales-page:hover .tool-title-sales-pape {
    color: #FFFFFF;
}

.tool-card-sales-page:hover .tool-description-sales-pape {
    color: #FFFFFF;
}

.tool-card-sales-page:hover .sales-page-tool-link {
    color: #FFFFFF;
}

.tool-title-sales-pape {
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
}

.tool-description-sales-pape {
    font-size: 14px;
    max-height: 130px;
    overflow: auto;

}

.card-image-sales-page svg {
    width: 66px;
    height: 66px;
    transition: 0.5s;
}



.tool-card-sales-page:hover svg {
    width: 76px;
    height: 76px;
    transition: 0.5s ease;
}


.sales-page-tool-link {
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    top: 380px;
    color: #054BFF;
}



.book-demo-button {
    background: #0D57FF;
    height: 40px;
    border: none;
    height: 50px;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.book-demo-button:hover {
    background: #4CCB1F;
    ;


}

.sales-page-white-color {
    color: #FFFFFF;
}

.sales-page-spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}

.sales-page-navlink-container .ant-btn {
    border: none;
    height: auto;
    padding: 0px !important;
}

.navbar-menu-container {
    width: 800px;
    background: #F8FAFF;
    padding: 10px;
    position: relative;
    top: 23px;
    border-radius: 6px;
}

.info-text-get-in-touch {
    color: #FFFFFF;
}

.form-header-get-in-touch {
    line-height: 37px;
    font-size: 30px;
    font-weight: 700;
}

.messgae-tag-get-in-touch {

    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 8px;

}

.form-label-get-in-touch {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 10px;
    color: #868686;
}

.input-box-get-in-touch {
    background: #ECF1F4;
    margin-bottom: 1.2em;
}

.textarea-get-in-touch {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    border: none;
    border-radius: 8px;
    height: 110px;
    resize: none;
}

/* .your-msg-box:focus {
    outline: 2px solid #88aff6;
    -webkit-outline: 2px solid #88aff6;
} */

.input-form-get-in-touch .ant-input {
    background-color: #ECF1F4;
    padding: 11px;
    font-size: 12px;
    border: none;
    border-radius: 8px;
}

.input-form-get-in-touch .ant-input-suffix {
    position: absolute;
    left: 5px;
    top: 10px;
}

.input-form-get-in-touch .ant-input-affix-wrapper {
    padding: 9px;
    border: none;
    border-radius: 8px;
}

.input-form-get-in-touch .ant-input-affix-wrapper>input.ant-input {
    margin-left: 30px;
}

.input-form-get-in-touch .ant-input-suffix img {
    width: 20px;
}

.input-form-get-in-touch .ant-checkbox-checked::after {
    border: none;
}

.input-form-get-in-touch .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: #C4C4C4;
    border-color: #ECF1F4 !important;
}

.input-form-get-in-touch .ant-checkbox-inner::after {
    height: 14px;
    top: 46%;
    left: 27%;
}

.input-form-get-in-touch .ant-checkbox-input:focus {
    border-color: #237AFF;
    ;
}

.input-form-get-in-touch .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #FFFFFF;
    border-top: 0;
    border-left: 0;
    transform: rotate(50deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    content: ' ';
}

.input-form-get-in-touch .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #237AFF;
    border-color: #D9D9D9;
}

.up_down_arrow {
    transition: transform 0.45s ease;
}

.up_down_arrow.up {
    transform: rotate(180deg);
}


.checkboxes-get-in-touch {
    background-color: #ECF1F4;
    padding: 3px 7px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxes-get-in-touch.green {
    background-color: #4CCB1F;

}

.checkbox-choice-get-in-touch {
    font-size: 14px;
    color: #8C8CA1;
    align-self: center;
}

.checkbox-choice-get-in-touch.gray {
    color: #FFFFFF;
}

.checkbox-selection-get-in-touch {
    width: 30px;
    height: 30px;
    background-color: #D9D9D9;
    border-radius: 25px;
}


.send-msg-button {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    font-weight: 500;
}

.send-msg-button-enabled {
    background: #237AFF;
    color: #FFFFFF;
}



.send-msg-button-diabled {
    background: #D9D9D9;
}

.send-msg-button-diabled span {
    color: #FFFFFF;
}

.send-msg-button-enabled span {
    color: #FFFFFF;
}


.send-msg-button:hover {
    background: #237AFF;
}

.advanced-option-container-enabled {
    display: block;
    transition: 0.5s ease-out;
}

.advanced-option-container-disabled {
    display: none;
    transition: 0.5s ease-out;
}

.contact-number-container .ant-input-affix-wrapper>input.ant-input {
    margin-left: 0px !important;
}

.contact-number-container .ant-input {

    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

}

.contact-number-container .ant-input-prefix {
    background: #ECF1F4;
    margin-left: 0px !important;
    margin-right: -2px;
}

.book-demo-container {
    margin-top: 15px;
}


.info-text-get-in-touch {
    font-size: 13px;
}

.contact-number-container .ant-input-affix-wrapper {
    padding: 0px !important;
    height: 40px !important;
}

.soul-tool-intro-section video {
    clip-path: inset(1px 1px);
}

.contact-label-get-in-touch {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 17px !important;
    font-weight: 600;
}

.footer-mapp-description {
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
}

.footer-column-header-sales-page {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 30px;
    font-weight: 600;
}

.footer-menu-item-sales-page {
    font-size: 14px;
    margin-top: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
}

.footer-menu-item-sales-page:hover {
    color: #0D57FF;
}

.sales-footer-spacer {
    padding-top: 37px;
    padding-left: 30px;
    border-right: 1px solid #D9D9D9;
}

.sales-footer-spacer:last-child {
    border: none;
}

.ordered-list-container li:before {
    content: "• ";
    color: #4CCB1F;
    position: relative;
    top: 8px;
    font-size: 40px;
}

.feature-row {
    margin: 0px 15px 15px 0px;
}

.feature-label {
    position: absolute;
    top: 22px;
    left: 30px;
    font-family: poppins;
    font-size: 22px;
    font-weight: 500;
}

.feature-description-image-soul-tool {
    padding: 0px 20px 20px 0px;
}

.feature-description-soul-tool-title {
    font-size: 45px;
    font-weight: 700;
}

.feature-description-soul-tool-text {
    font-size: 17px;
    font-weight: 500;
}

.soul-tool-spacer {
    margin-top: 70px;
    margin-bottom: 70px;
}

.soul-tool-feature-row {
    margin-bottom: 20px;
}

.soul-tool-feature-header {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    left: 85px;
}

.soul-tool-identity-header {
    font-size: 45px;
    font-weight: 700;
}

.thank-you-modal .ant-modal-body {
    background: #ECF1F4;
}

.team-size-options-menu-item .ant-menu-item {
    padding: 0px !important;
}

.selected-team-size:hover {
    color:#000000;
    text-decoration-color: #8C8CA1 !important;
}

.selected-team-size .ant-btn:hover,
.selected-team-size .ant-btn:focus {
    color: rgb(140, 140, 161);
}

.feature-col-soul-sales-page:hover {
    transform: scale(1.04);
    transition: all 0.3s ease-out;
}

.info-container-get-in-touch {
    top: 250px;
    position: relative;
    /* margin-top: calc(100% - 50px); */
}

.sales-page-footer-container {
    padding-bottom: 30px;
    padding-top: 70px;
}


.header-decorative-line {
    position: absolute;
    width: 95%;
    margin: auto;
    object-fit: cover;
}

.soul-decorative-line-sales {
    position: absolute;
    width: 50%;
    top: 21%;
    left: -9px;
}

.sales-tools-decorative-line {
    width: 25%;
    position: absolute;
    top: 37.8%;
    right: 0px;
}

.contact-number-container .ant-select-selection-item {
    cursor: pointer;
}


.send-msg-button-disbaled {
    background: #D9D9D9 !important;
}

.send-msg-button {
    transition: none !important;
}

.input-form-get-in-touch .ant-btn[disabled] {
    color: #000000 !important;
}


.book-demo-background-sales {
    height: 340px;
}

.mapp-tools-header {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 15px;
}

.tools-card-sales {
    padding: 25px;
    min-height: 375px;
}


.book-demo-header {
    font-size: 45px;
    color: #FFFFFF;
    font-weight: 700;
}

.book-demo-description-sales {
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 25px;
    margin-bottom: 40px;
    font-weight: 600;
}

.tool-set-footer-sales {
    padding-left: 27px;
}

.get-in-touch-sales {
    width: 100%;
    position: relative;
    z-index: 10;
}

.mapp-logo-get-in-touch {
    margin-top: 15px;
    position: relative;
    left: -95px;
}

.text-content-get-in-touch {
    flex-direction: column;
    position: absolute;
    z-index: 100;
    padding: 65px;
    gap: 10px;
    top: 0px;
}

.back-icon-get-in-touch {
    width: 15px;
    margin-right: 10px;
}

.checkbox-panel-get-in-touch {
    flex-wrap: nowrap;
}

.get-in-touch-sales {
    height: 100%;
    /* min-height: 830px; */
    /* max-height: 840px; */
}


.soul-tool-intro-section video {
    height: 600px;
    position: relative;
    z-index: 10;
}

.survey-type-soul-tool {
    flex-wrap: nowrap;
}


.feature-card-soul-tool {
    gap: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.soul-tool-feature-card-navigation {
    gap: 15px;
    margin-right: 15px;
}


.feature-description-image-soul-tool {
    width: 85%;
}

.sales-navbar-web {
   width:100%
}

.sales-navbar-drawer {
    display: none;
}

.sales-drwaer-item {
    margin-bottom: 20px;
}

.sales-nav-phone-icon {
    position: absolute;
    right: 18px;
    top: -18px;
}


.sales-navbar-web {
    box-shadow: 0px 4px 53px rgba(0, 0, 0, 0.09);
    padding: 20px 30px 20px 50px;
    position: relative;
    z-index: 100;
}

.sales-menu-container .ant-select-selector,.sales-menu-container .ant-select-selector:hover,.sales-menu-container .ant-select-selector:focus,.sales-menu-container .ant-select-selector:focus-visible,.sales-menu-container .ant-select-selector:focus-within{
    background: rgb(236, 241, 244) !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: none !important;
    font-weight: 400;
    font-size: 1em;
}
.sales-menu-container .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-bottom: 0.375em !important;
}
.sales-menu-container .ant-select-selector .ant-select-selection-item{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
    padding-top: 0.375em !important;
}
.sales-menu-container .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #868686 !important;
    font-weight: 400 !important;
    font-size: .85em;
    font-family:'Poppins';
}
/* styles for bigger device */


@media screen and (max-width:1380px) {

    .messgae-tag-get-in-touch,
    .form-label-get-in-touch,
    .input-form-get-in-touch .ant-input {
        font-size: 16px;
    }

    .form-label-get-in-touch {
        margin-bottom: 20px;
    }

    .input-box-get-in-touch {
        margin-bottom: 2em;
    }

    /* sales page styles */

    .explore-mapp-sales-page {
        margin-top: 50px;
        width: 41%;
    }



    .tool-break-crumb-sales {
        width: 10%;
    }

    .page-intro-header-sales-page {
        font-size: 37px;
    }

}




@media screen and (max-width:1030px) {

    .checkbox-panel-get-in-touch .ant-col:first-child {
        margin-bottom: 20px;
    }

    .checkbox-panel-get-in-touch .ant-col-lg-12 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .soul-tool-intro-section {
        flex-direction: column-reverse;
    }

    .get-in-touch-sales {
        height: 100%;
        max-height: 1000px;
    }

    .get-in-touch-image-info-wrapper {
        flex-wrap: nowrap;
    }


    .ordered-list-sales-page {
        font-size: 15px;
    }

    .explore-mapp-sales-page {
        width: 46%;
    }

    .checkbox-panel-get-in-touch {
        flex-wrap: wrap;
    }


}


@media screen and (max-width:1024px) and (min-height:768px) {

    .soul-tool-intro-section .ant-col-lg-11 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .soul-tool-intro-section .ant-col-lg-10 {
        flex: 0 0 80%;
        max-width: 80%;
    }

}


@media screen and (min-height:830px) {

    .get-in-touch-sales {
        min-height: 834px;
    }
}



/* @media screen and (max-width:900px) {

    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }
} */




@media screen and (max-width:768px) {

    /* footer styles */

    .sales-footer-spacer {
        border-right: none;
    }


    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }

    


    /* soul tool styles */



    .feature-description-image-soul-tool {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .feature-description-soul-tool-title {
        text-align: center;
    }

    .feature-description-soul-tool-text {
        text-align: justify;
        padding-left: 20px;
        padding-right: 20px;
    }


    .feature-row {
        margin: 0px;
    }

    .horizontal-card-container-soul-tool img {
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .soul-tool-feature-card-navigation {
        margin-right: 0px;
    }

    .feature-card-soul-tool {
        margin-right: 0px;
    }

    .survey-type-soul-tool {
        flex-wrap: wrap;
    }

    .survey-type-soul-tool .ant-col {
        margin: auto;
        margin-bottom: 20px;
    }

    .soul-tool-intro-section video {
        width: 95%;
        margin: auto;
        height: auto;
    }

    .soul-tool-intro-section .ant-row {
        margin: auto;
        text-align: justify;
    }




    .soul-tool-feature-header {
        left: 0px;
        font-size: 22px;
        text-align: center;
    }






    /* get in touch page styles */

    .form-col-get-in-touch {
        padding-top: 40px !important;
    }

    .get-in-touch-image-info-wrapper {
        flex-wrap: wrap !important;
    }


    .info-text-get-in-touch {
        font-size: 16px;
    }

    .get-in-touch-sales {
        height: 800px;
    }

    .contact-label-get-in-touch {
        font-size: 18px;
    }


    .mapp-logo-get-in-touch {
        left: -230px;
    }



    /* Sales page home styles */




    .page-intro-header-sales-page {
        font-size: 25px;
    }

    .ordered-list-sales-page {
        font-size: 14px;
    }

    .explore-mapp-sales-page span {
        font-size: 14px;
    }

    .explore-mapp-sales-page {
        width: 33%;
        margin-top: 10px;
    }

    .sales-page-spacer {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .soul-identity-bread-crumb {
        margin-top: 50px;
    }

    .soul-identity-header {
        font-size: 35px;
    }

    .soul-page-ordered-list {
        text-align: justify;
    }



    .button-link-sales-page span {
        font-size: 14px;
    }

    .tool-break-crumb-sales {
        width: 12% !important;
    }

    .mapp-tools-header {
        font-size: 35px;
        margin-top: 20px;
    }

    .tool-title-sales-pape {
        font-size: 22px;
    }

    .card-image-sales-page svg {
        width: 46px;
        height: 46px;
    }

    .tool-card-sales-page:hover svg {
        width: 56px;
        height: 56px;
    }

    .tools-card-sales {
        padding: 15px;
    }

    .book-demo-header {
        font-size: 25px;
    }


    .book-demo-description-sales {
        font-size: 16px;
        text-align: center;
    }

    .footer-column-header-sales-page {
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .tool-set-footer-sales {
        padding-left: 18px;
    }


    .checkbox-panel-get-in-touch {
        flex-wrap: wrap;
    }

    .checkbox-panel-get-in-touch .ant-col {
        margin: auto;
    }




}


@media screen and (min-width:760px) and (min-height:1000px) {

    .survey-type-soul-tool {
        flex-wrap: nowrap;
    }
}








/* styles for smaller device */

@media screen and (max-width:500px) {




    /* get in touch page styles */

    .form-header-get-in-touch {
        padding-top: 40px;
    }

    .mapp-logo-get-in-touch {
        left: 0px;
    }

    .text-content-get-in-touch {
        padding-left: 40px;
    }


    .get-in-touch-sales {
        width: 100%;
        position: relative;
    }

    .info-container-get-in-touch {
        position: relative;
        z-index: 100;
    }

    .input-form-get-in-touch {
        position: relative;
        padding-bottom: 40px;
    }



    /* sales page home styles */

    .soul-identity-bread-crumb-container,
    .soul-identity-container {
        justify-content: center;
    }

    .page-intro-header-sales-page {
        margin-top: 20px;
    }

    .sales-page-hero-section {
        align-items: center;
        flex-direction: column-reverse;
    }

    .page-intro-header-sales-page {
        font-size: 25px;
    }

    .ordered-list-sales-page {
        font-size: 15px;
    }

    .explore-mapp-sales-page span {
        font-size: 14px;
    }

    .explore-mapp-sales-page {
        width: 52%;
        margin-top: 61px;
    }

    .sales-page-spacer {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .soul-identity-bread-crumb {
        margin-top: 20px;
    }

    .soul-identity-header {
        font-size: 35px;
    }

    .soul-page-ordered-list {
        text-align: justify;
    }



    .button-link-sales-page span {
        font-size: 14px;
    }

    .tool-break-crumb-sales {
        width: 30% !important;
    }

    .mapp-tools-header {
        font-size: 35px;
        margin-top: 20px;
    }

    .tool-title-sales-pape {
        font-size: 22px;
    }

    .card-image-sales-page svg {
        width: 46px;
        height: 46px;
    }

    .tool-card-sales-page:hover svg {
        width: 56px;
        height: 56px;
    }

    .tools-card-sales {
        padding: 15px;
    }

    .book-demo-header {
        font-size: 25px;
    }


    .book-demo-description-sales {
        font-size: 16px;
        text-align: center;
    }

    .footer-column-header-sales-page {
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .tool-set-footer-sales {
        padding-left: 18px;
    }


}


/* Styles for bigger device */


@media screen and (min-width:1500px) {

    .book-demo-background-sales {
        height: 340px;
    }

    .soul-decorative-line-sales {
        top: 26.5%;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 100%; */
        /* height: 17%; */
    }

    .sales-tools-decorative-line {
        top: 51%;
    }

    .sales-page-tagline {
        font-size: 24px;
    }

    .ordered-list-sales-page {
        font-size: 20px;
    }

    .soul-identity-bread-crumb {
        font-size: 18px;
    }

    .tool-description-sales-pape {
        font-size: 18px;
    }

    .soul-page-ordered-list {
        font-size: 20px;
    }

    .sales-page-tool-link {
        font-size: 20px;
    }

}



@media screen and (min-width:1900px) {

    .header-decorative-line {
        position: absolute;
        /* width: 108%; */
        /* height: 17%; */
    }

    .soul-decorative-line-sales {
        top: 27%;
    }

    .sales-tools-decorative-line {
        top: 51.7%;
    }

    .messgae-tag-get-in-touch,
    .form-label-get-in-touch,
    .input-form-get-in-touch .ant-input {
        font-size: 20px;
    }

    .form-label-get-in-touch {
        margin-bottom: 30px;
    }

    .input-box-get-in-touch {
        margin-bottom: 2em;
    }

    .contact-label-get-in-touch {
        font-size: 22px;
    }

    .info-text-get-in-touch {
        font-size: 18px;
    }

    /* navbar styles */

    .sales-page-link {
        font-size: 22px;
    }

    .sales-page-navbar-button {
        height: 55px;
    }

    .sales-page-navbar-button .ant-btn {
        font-size: 18px;
    }

    .sales-navbar-web {
        width: 100%;
        padding: 25px 35px 25px 50px
    }

    .tool-nav-item-sales {
        font-size: 22px;
    }

}

@media screen and (min-width:2100px) {

    .page-intro-header-sales-page {
        font-size: 70px;
    }

    .sales-page-tagline {
        font-size: 37px;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 115%; */
    }

    .sales-tools-decorative-line {
        top: 53.1%;
    }

    .sales-page-tagline {
        font-size: 26px;
    }

    .ordered-list-sales-page {
        font-size: 26px;
        height: 60px;
    }

    .soul-identity-bread-crumb {
        font-size: 24px;
    }

    .tool-description-sales-pape {
        font-size: 22px;
    }

    .soul-page-ordered-list {
        font-size: 26px;
    }

    .sales-page-tool-link {
        font-size: 24px;
    }

    .button-link-sales-page span {
        font-size: 25px;
    }

    .tool-title-sales-pape {
        font-size: 34px;
    }

}



@media screen and (min-width:2200px) {

    .soul-decorative-line-sales {
        top: 28.5%;
    }

    .header-decorative-line {
        position: absolute;
        /* width: 121%; */
    }

    .sales-tools-decorative-line {
        top: 54.8%;
    }

}

@media screen and (min-width:2300px) {


    .sales-tools-decorative-line {
        top: 53.8%;
    }

}

.team-size-drop:focus{
    color:#000000 !important
}

.team-size-drop:hover{
    color:#000000 !important
}

.focus-black{
    outline: none !important;
}
.focus-black:focus{
    outline: none !important;
}

.rating-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    font-family: Arial, sans-serif;
  }
  
  .nps-rating-value {
    font-family: Poppins;
    font-size: 2.5em;
    font-weight: 400;
    line-height: normal;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px;
  }
  
  .stars {
    display: flex;
    gap: .5em;
  }
  
  .star {
    width: 1.5em !important;
    height: 1.5em !important;
  }
.nps-rating-heading{
    font-family: Poppins;
    font-size: .5em;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    background-color: #000000;
    text-align: center;
    padding: 0em 1em;
    border-radius: 1em;
    margin: 0px;
    height: 2em;
    padding-top: .35em;
}
  
@media (max-width: 1010px) {
    .sales-navbar-web {
        padding: 20px 10px 20px 10px
    }
}
@media (max-width: 880px) {
    .sales-navbar-web {
        display: none !important;
    }
    .sales-navbar-drawer {
        display: block !important;
    }
    .hide-nps-title{
        display: none !important;
    }
    .nps-container{
        flex-direction: column !important;
    }
    .sales-page-navbar-button{
        width: 100%;
    }

}