
.user-badge-root-class{
        font-size: 16px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
}
.user-badge-award-popup-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.5em;
    line-height: 2.5em;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: center;
    margin: 0;
}
.user-badge-award-popup-input-div{
    width:20.688em;
    height: 40px;
}
.dashboard-user-badge-award-popup-input-div{
  width:100%;
  height: 40px;
}
.search-input-user-kudos{
    height: 40px;
    border: 1px solid #8F8D8D;
    border-radius: 0px 8px 8px 0px;
}
.dashboard-search-input-user-kudos{
  height: 38px !important;
  width: 100%;
}
.search-input-user-kudos input:focus{
  box-shadow: none !important;
}
.dashboard-search-input-user-kudos input:focus{
  box-shadow: none !important;
}
.filter-select-user-select .ant-select-selector{
  height: 40px !important;
  border: none;
  padding-top: 3px !important;
}
.dasboard-filter-select-user-select .ant-select-selector{
  height: 38px !important;
  border:none !important;
  padding-top: 3px !important;
  margin-left: 1px !important;
}
.filter-select-user-select .ant-select-selector:focus{
  box-shadow: none !important;
}
.dasboard-filter-select-user-select .ant-select-selector:focus{
  box-shadow: none !important;
}
.filter-select-user-select .ant-select-selector input{
  height: 40px !important;
}
.dasboard-filter-select-user-select .ant-select-selector input{
  height: 38px !important;
}
.filter-select-user-select .ant-select-selector input:focus{
  box-shadow: none !important;
}
.dasboard-filter-select-user-select .ant-select-selector input:focus{
  box-shadow: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  color:#4CCB1F !important;
  font-weight: normal !important;
  background-color: #fff !important;
  font-size: 1em !important;
}
.user-badge-award-popup-input{
    width:22.688em;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #8C8CA2;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 500;
    padding-left: 40px;
}
.user-badge-award-carosul-container{
    border: 1px solid #000000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 14px;
    width:100% !important;
    max-width: 70.5em;
    height: 17em !important;
    padding: 0px 1em;
    min-width: 50em;
}
.user-badge-award-carosul-container-loading{
  border: 1px solid #000000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 14px;
  width:100%;
  min-width: 50em;
  max-width: 70.5em;
  height: 17em !important;
  padding: 0px 1em;
}
.feedback-badge-award-carosul-container-loading{
  height: 10em !important;
}
.feedback-badge-award-carosul-height{
  max-width: 70.5em;
  height: 13em !important;
}
.dasboard-badge-award-carosul-container{
  border-top: 1px solid #8F8D8D;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 414px!important;
    height: 10em !important;
    padding: 0px 1em;
    border-top: none !important;
}
.dasboard-badge-award-carosul-container .ant-spin-nested-loading{
  width: 100% !important;
}
.dasboard-badge-award-carosul-container-loading{
  border-top: 1px solid #8F8D8D;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 414px!important;
    height: 10em !important;
    padding: 0px 1em;
    border-top: none !important;
}
.dasboard-badge-award-carosul-container .slick-next{
  right: 0px !important;
}
.dasboard-badge-award-carosul-container .slick-prev{
  left: -4px !important;
}

.user-badge-award-carosul-container .slick-next{
  right: 10px !important;
}
.user-badge-award-carosul-container .slick-prev{
  left: 10px !important;
}
.user-award-badge-image{
    width:200px !important;
    height: 210px !important;
    margin-top: 15px;
    padding: 10px;
    cursor: pointer;
}

.user-award-badge-image-selected{
    width:200px !important;
    height: 210px !important;
    margin-top: 15px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:160px !important;
  height: 170px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:160px !important;
  height: 170px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
.dashboard-user-award-badge-image-selected-container{
  height:8.5em;
  width:7.5em !important;
  border-radius: 8px;
  border: 1px solid #AABAC633;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
  cursor: pointer;
}
.dashboard-show-kudos-detail-card{
  display: none;
}
.dashboard-selected-kudos-card{
  border-radius: 8px;
  border: 1px solid #AABAC633;
  width: 93%;
  padding: .5em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 9em;
  margin: .5em 1em;
}
.dashboard-user-award-badge-image-selected-container:hover{
  border: 1px solid #4CCB1F;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-show-kudos-detail-card{
  display: block;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected{
  display: none;
}
.dashboard-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected-title{
  display: none;
}
.dashboard-user-award-badge-image-selected-container-selected{
  border: 2px solid #4CCB1F;
}
.dashboard-user-award-badge-image-selected{
  height: 5em !important;
  width: 5em!important;
  cursor: pointer;
  object-fit: contain;
  align-items: center;
  margin: .5em 0em;
}
.dashboard-user-award-badge-image-selected-title{
  font-family: 'Poppins';
  font-size: .65em;
  font-weight: 400;
  font-size: normal;
  font-style: normal;
  text-align: center;
  border-top: 1px solid #AABAC633;
  padding-top: 6px;
}
.dashboard-user-award-badge-image-selected-detail{
  font-family: 'Poppins';
  font-size: .65em;
  font-weight: 400;
  font-size: normal;
  font-style: normal;
  text-align: center;
  border-top: 1px solid #AABAC633;
  padding-top: 6px;
  max-height: 8em;
  text-overflow: ellipsis;
}
.dashboard-details-elipsis{
  display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 3; 
            overflow: hidden; 
            text-overflow: ellipsis; 
}
.user-badge-award-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    line-height: 2.5em;
    color: #000000;
    text-align: left;
    display: flex;
    align-items: start;
    margin: 0;
}
.user-badge-award-selected-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2em;
    line-height: 2.5em;
    color: #4CCB1F;
    text-align: left;
    margin: 0;
}
.user-badge-award-selected-description-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 1.65em;
    color: #8C8CA2;
    text-align: justify;
    margin: 0;
    word-break: break-all;
}
.user-badge-award-carosul-second-container{
    width:100%;
    min-width:100% !important;
    max-width: 70.5em;
}
  
.user-badge-award-carosul-second-section-image-container{
    width:190px;
    height: 200px;
    align-items: center;
}
.feedback-badge-award-carosul-second-section-image-container{
  width:140px;
  height: 150px;
  align-items: center;
}
.user-badge-award-carosul-second-section-image{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: fill;
}
.user-badge-award-popup-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  .user-badge-award-popup-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .user-badge-award-popup-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#F0F0F0;
  }
  .user-awards-view-container{
    width:100%;
    max-height: 17em;
    background: rgba(217, 217, 217, 0.04);
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.14);
    text-align: center;
    padding: 10px 20px;

  }
  .user-awards-view-header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.5em;
    line-height: 1em;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #4CCB1F;
    margin: 0px;
    padding-bottom: 10px;
  }
  .user-awards-view-slider {
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
    /* cursor: grab; */
  }
  
  .user-awards-view-slider::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  
  .user-awards-view-slider::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 0px;
    margin: 0px;
  }
  
  .user-awards-view-slider::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #eeeded;
  }
  .user-awards-view-image{
    width: 10em;
    height: 10em;
    border: 2px solid rgba(217, 217, 217, 0.04);
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
  }
  .user-awards-view-image:hover{
    border: 2px solid #9CD600;
  }
  .recived-award-image-container{
    width: 20.25em;
    height: 19.875em;
    background: #FFFFFF;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1.25em 1.25em;
    cursor: pointer;
    margin-top: 1em;
  }
  .recived-award-image-container:hover{
    background: #FFFFFF;
    border: 2px solid #9CD600;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .recived-award-image-container:hover .award-delete-icon{
    left:25px !important;
    top:15px !important;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:50px !important;
    top:50px !important; */
  }
  .recived-award-image-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
    margin-top: 10px;
  }
  .recived-award-image-container:hover .recived-award-image-title{
    color: #4CCB1F;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #B0CB1F;
    border-color: #B0CB1F;
}
  .issued-award-image-container{
    width: 20em;
    height: 20.125em;
    background: #FFFFFF;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1.25em 1.25em;
    margin-top: 1em;
  }
  .issued-award-image-container:hover{
    background: #FFFFFF;
    border: 2px solid #9CD600;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .recived-award-image{
    max-width: 75%;
    max-height: 73%;
    object-fit: contain;
    object-position: center;
  }
  .uer-profile-award-main-card-container{
    width: 100%;
    padding: 1em 2em;
    min-height: 60vh;
    }
  .uer-profile-award-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1.5em;
    margin: 0px 3.5em;
}
.uer-profile-award-vertical-container{
    width: 25% !important;
    padding: 0em .5em .5em .5em;
    display: flex;
    flex-direction: column;
    /* position: relative; */
}
.user-profile-award-detail-left-container{
  /* max-width: 25%; */
  width: 14em;
}
.user-profile-award-detail-right-container{
  /* width: 75%; */
  padding: 1em;
}
.user-profile-award-detail-right-container-inner{
  margin-left: 2em;
 
}
.user-badge-award-popup-inner-container{
  display: flex;
  flex-wrap: nowrap;
  width:100%;
}
.user-badge-award-detail-modal{
  min-width: 62.313em;
  max-width: 62.313em;
}
.user-badge-award-share-modal{
  min-width: 62.313em;
  max-width: 62.313em;
}
.user-badge-award-share-modal .ant-modal-content{
  background: url('../assets/share\ modal-bg.png');
  background-size:100%;
  background-repeat: no-repeat;
  background-position: center;
  height:40em;
  border-radius: 8px;
}
.recent-badge-modal .ant-modal-content{
  background-image: url('../assets/surprise_happy_bday_instagram.gif') !important;
  background-size:100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  min-width: 700px;
}
.recent-modal-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 3.25em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #539300;
  margin: 0;
}
.recent-modal-sub-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 3em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin: 0;
  color: #080800;
}
.recent-modal-image{
  width:17.266em;
  height: 15.938em;
  object-fit: contain;
}
.social-icon{
  width:6.25em !important;
  height: 6.25em !important;
}
.user-badge-award-share-div{
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  /* padding: 10px; */
  cursor: pointer;
}
.user-badge-award-share-div:hover {
  transform: scale(1.1);
}
.user-profile-issued-employee-name{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
  padding: 3px 5px;
  border:none !important;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.user-profile-issued-employee-name-selected{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
  border: 2px solid #4CCB1F !important;
  padding: 3px 5px;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.issued-detail-nav-button{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 3em;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 0;
}
.kudos-user-home-select-option{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.7em;
  color: #000000;
}
.user-badge-drop{
  background-color: rgba(246, 246, 246, 0.62) !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
  height: 40px !important;
}
.user-badge-drop-active{
  background-color: #fff !important;
  border: 1px solid #9CD600 !important;
  border-radius: 8px !important;
  height: 40px !important;
}
.user-badge-select .ant-select-selector{
  background-color: rgba(246, 246, 246, 0.62) !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
}
.user-badge-select-active .ant-select-selector{
  background-color: #fff !important;
  border: 1px solid #9CD600 !important;
  border-radius: 8px !important;
}
.user-badge-select .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  color: rgb(194, 194, 194) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-top: 4px !important;
}
.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  display: block !important;
  padding-top: 4px !important;
  white-space: nowrap;
}
.ant-select-dropdown {
  padding: 0px 0px !important;
  }

.kudos-tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.kudos-tab .ant-tabs-tab{
  /* padding: 5px 0px !important; */
}
.kudos-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 1.75em !important;
}
.give-kudos-btn{
  width: 9.375em;
  height: 3em;
  background: #4CCB1F;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  border:none;
}
.give-kudos-btn:hover{
  background: #4CCB1F;
  color: #FFFFFF;
  border:none;
}
.give-kudos-btn:focus{
  background: #4CCB1F;
  color: #FFFFFF;
  border:none;
}
.badge-check span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.66);
}
.badge-check + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.give-kudos-form-section{
  background: rgba(246, 246, 246, 0.62) !important;
  border-radius: 8px;
  padding: 1em 1.313em;
}
.give-kudos-save-btn[disabled]{
  color: #FFFFFF !important;
  background: #8C8CA2 !important;
  border-color: #8C8CA2 !important;
}
.give-kudos-save-btn[disabled]:hover{
  color: #FFFFFF !important;
  background: #8C8CA2 !important;
  border-color: #8C8CA2 !important;
}
.share-kudos-button{
  font-size: 1.25em !important;
}

.badge-popover .ant-popover-inner{
  background: #F1F1F1 !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21) !important;
  border-radius: 8px !important;
  margin-top:-15px !important;
}
.badge-popover .ant-popover-arrow{
  display: none !important;
}
.filter-badge-family-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  white-space: nowrap;
  color: #000000;
}
.filter-badge-family-para{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.65em;
  color: rgba(0, 0, 0, 0.7);
}
.card-group {
  display: flex;
  width:100%
}

  /* .slick-slider {
    display: flex !important;
  }

.slick-list {
  display: flex !important;
  align-items: flex-start !important;
}

.slick-slide {
  display: flex !important;
  justify-content: flex-start !important;
} */
.user-badge-award-carosul-container .slick-track{
  display: flex !important;
  gap: 10px !important;
  width: 100% !important;
}

.radio-button-section .ant-radio-checked .ant-radio-inner{
  border-color:#B0CB1F !important ;
  border: 6px solid #B0CB1F !important;
  border-radius: 50% !important;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:after{
  background-color: #fff;
  width: 0px !important;
  height: 0px !important;
}

.radio-button-section .ant-radio:hover .ant-radio-inner {
  border-color: #B0CB1F ;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:focus{
  border-color: #B0CB1F;
}
.radio-button-section .ant-radio-checked .ant-radio-inner:hover{
  border-color: #B0CB1F;
}
.radio-button-section .ant-radio-group .ant-radio-wrapper{
  font-size: 15px !important;
  font-weight: 500 !important;
}
.user-given-badge-popup-container{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap:1em;
  width:100%;
  margin-top: 30px;
}
.given-kudos-right-container{
  max-width:35%;
}
.kudos-okr-selection-drop{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  max-width: 500px !important;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #595959;
}
@media (max-width:1600px) {
  .recent-badge-modal .ant-modal-content{
    min-width: 100%;
  }
  .issued-award-image-container{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container:hover{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container{
    width: 17em;
    height: 16.875em;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:45px !important;
    top:45px !important; */
  }
    .user-badge-root-class{
        font-size: 14px !important;
    }
    .user-badge-award-carosul-container{
        width:85%;
        max-width: 60.5em;
        min-width: 40em;
        height: 14em !important;
    }
    .feedback-badge-award-carosul-height{
      width:85%;
        max-width: 60.5em;
        height: 10em !important;
    }
    .user-badge-award-carosul-second-container{
        width:100%;
        min-width: 100%;
        max-width: 68.5em;
    }
    .user-award-badge-image{
        width:160px !important;
        height: 170px !important;
        margin-top: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .user-award-badge-image-selected{
        width:160px !important;
        height: 170px !important;
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #4CCB1F;
        border-radius: 3em;
        cursor: pointer;
    }
    .feedback-award-badge-image{
      width:110px !important;
      height: 120px !important;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
    }
    .feedback-award-badge-image-selected{
      width:110px !important;
      height: 120px !important;
      margin-top: 10px;
      padding: 10px;
        border: 2px solid #4CCB1F;
        border-radius: 34px;
        cursor: pointer;
    }
    .user-badge-award-selected-description-modal{
      font-size: 1.2em;
    }
    .user-badge-award-selected-title-modal{
      font-size: 1.6em;
    }
    .user-badge-award-popup-title{
      font-size: 2em;
      line-height: 2em;
    }
    .user-badge-award-subhead{
      font-size: 1.6em;
    }
    .issued-detail-nav-button{
      font-size: 1em;
    }
    .user-badge-award-share-modal{
      min-width: 53.313em;
    }
    .user-badge-award-share-modal .ant-modal-content{
      height:34em;
    }
    .social-icon{
      width:5.375em !important;
      height:5.375em !important;
    }
    .share-modal-container{
      height: 400px !important;
    }
    .user-badge-award-detail-modal{
      min-width: 54.313em;
      max-width: 54.313em !important;
    }
    .filter-badge-family-title{
      font-size: 1.6em;
    }
    .filter-badge-family-para{
      font-size: 1.3em;
    }
}
@media (max-width:1450px) {
  .issued-award-image-container{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container:hover{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container{
    width: 16em;
    height: 15.875em;
  }
  .recived-award-image-container:hover .award-share-icon{
    /* right:40px !important;
    top:40px !important; */
  }
  .kudos-fluid-container .ant-tabs-top > .ant-tabs-nav{
    margin-top: -20px !important;
  }
  .user-badge-award-share-modal{
    min-width: 45.313em;
  }
  .user-badge-award-share-modal .ant-modal-content{
    height:30em;
  }
  .user-badge-award-share-modal{
    min-width: 42.313em;
  }
  .user-badge-award-share-modal .ant-modal-content{
    height:28em;
  }
  .social-icon{
    width:5.375em !important;
    height:5.375em !important;
  }
  .share-modal-container{
    height: 350px !important;
  }
  .user-badge-award-detail-modal{
    min-width: 50.313em;
    max-width: 50.313em !important;
  }
  .recent-modal-title{
    font-size: 2em;
    line-height: 2.2em;
  }
  .recent-modal-sub-title{
    font-size: 1.5em;
    line-height: 2em;
  }
  .recent-modal-image{
    width:13.266em;
    height: 11.938em;
  }
}
@media (max-width:1380px) {
  .issued-award-image-container{
    width: 15em;
    height: 14.875em;
  }
  .recived-award-image-container:hover{
    width: 14em;
    height: 12.875em;
  }
  .recived-award-image-container{
    width: 13em;
    height: 11.875em;
  }
  .recent-modal-image{
    width:13.266em;
    height: 11.938em;
  }
  .filter-badge-family-title{
    font-size: 1.5em;
  }
  .filter-badge-family-para{
    font-size: 1.2em;
  }
  .uer-profile-award-card-container{
     justify-content: center;
  }
}
@media (max-width:1300px) {
  .uer-profile-award-vertical-container{
    width:33.33% !important
  }
}
@media (max-width:1200px) {
    .user-badge-root-class{
        font-size: 12px !important;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:35px !important;
      top:35px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.2em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
    .user-badge-award-carosul-container{
      width:100%;
      max-width: 60.5em;
      min-width: 40em;
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
    width:100%;
      max-width: 60.5em;
      height: 12em !important;
  }
  
}

@media (max-width:960px) {
    .user-badge-root-class{
        font-size: 11px !important;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:30px !important;
      top:30px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.5em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
    .filter-badge-family-title{
      font-size: 1.25em;
    }
    .filter-badge-family-para{
      font-size: 1em;
    }
    .user-badge-award-selected-description-modal{
      font-size: 1em;
    }
    .user-badge-award-carosul-container{
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
      height: 12em !important;
  }
  .user-award-badge-image{
    width:150px !important;
    height: 155px !important;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
}
.user-award-badge-image-selected{
    width:150px !important;
    height: 155px !important;
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 3em;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:95px !important;
  height: 105px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:95px !important;
  height: 105px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
}

@media (max-width:920px) {
  .uer-profile-award-card-container{
    margin: 0px 3em;
  }
  
}
@media (max-width:780px) {
  .uer-profile-award-vertical-container{
    width:50% !important
  }
  .uer-profile-award-card-container{
    margin: 0px 2.5em;
  }
  .user-badge-award-detail-modal{
    min-width: 100%;
    max-width: 100%;
  }
  .recent-modal-title{
    font-size: 2em;
    line-height: 2.5em;
  }
  .recent-modal-sub-title{
    font-size: 1.5em;
    line-height: 2em;
  }
  .user-badge-award-carosul-container{
      width:100%;
      max-width: 60.5em;
      min-width: 40em;
      height: 16em !important;
  }
  .feedback-badge-award-carosul-height{
    width:100%;
    max-width: 60.5em;
    height: 12em !important;
}
}
@media (max-width:600px) {
    .user-badge-root-class{
        font-size: 10px !important;
    }
    .user-badge-award-detail-modal{
      min-width:98% !important;
      max-width:98% !important;
    }
    .uer-profile-award-card-container{
      margin: 0px 2.5em;
      justify-content: center;
      align-items: center;
    }
    .recived-award-image-container:hover .award-share-icon{
      /* right:25px !important;
      top:25px !important; */
    }
    .recent-modal-title{
      font-size: 2em;
      line-height: 2.5em;
    }
    .recent-modal-sub-title{
      font-size: 1.5em;
      line-height: 2em;
    }
}

@media (max-width:450px) {
  .user-badge-award-popup-inner-container{
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width:480px) {
  .uer-profile-award-vertical-container{
    width:100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width:360px) {
  .user-award-badge-image{
    width:130px !important;
    height: 135px !important;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
}
.user-award-badge-image-selected{
    width:130px !important;
    height: 135px !important;
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 3em;
    cursor: pointer;
}
.feedback-award-badge-image{
  width:90px !important;
  height: 100px !important;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.feedback-award-badge-image-selected{
  width:90px !important;
  height: 100px !important;
  margin-top: 10px;
  padding: 10px;
    border: 2px solid #4CCB1F;
    border-radius: 34px;
    cursor: pointer;
}
}
.recieved-badge-head{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
  color: #4CCB1F;
  text-align: center;
  margin: 0;
}


.user-badge-award-popup-input-div .ant-select-clear{
  color: red !important;
}
.dashboard-user-badge-award-popup-input-div .ant-select-clear{
  color: red !important;
}