.tabpane-feedback-view {
    color: red;
}

.tabpane-feedback-view:hover {
    color: black;
    background: black;
}

.add-new-feed-btn{
    width: 25em !important;
}

.feedback-search-container{
    width: 100%;
    display: flex;
    justify-content: end;
}

.feedback-actions-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}

.dummy-feedback-div{
    width: 100%;
    /* max-width: 95%; */
    height: 46px;
}

.feedback-search-bar {
    /* display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -2em;
    margin-top: 2em;
    position: absolute;
    right: 66px;
    top: 163px; */
    z-index: 100;
}

.feedback-tabpane .ant-tabs-tab {
    padding: 6px;
}

.search-input-bars:hover {
    border: 1px solid #4CCB1F;
}

.feedback-drop-dtn:hover {
    background: #FFFFFF;
    border: 1px solid #4CCB1F
}

.feedback-drop-dtn:hover+div div div .search-bar-feedback {
    border-right: none;
}

.feedback-drop-dtn:focus {
    background: #FFFFFF !important;
    border: 1px solid #4CCB1F;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

.search-bar-feedback {
    border: 1px solid #d9d9d9 !important;
    /* .ant-input:focus, .ant-input-focused */
}

.search-bar-feedback:hover {
    border-color: #4CCB1F !important;
}

/* css media queries for mobile upto 415 width */
@media screen and (max-width: 415px) {
    /* .feedback-search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 2em;
    } */

    .feedback-tab-bar {
        margin-top: 3em;
    }

    .feedback-tab-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

/* css media queries for mobile upto 320 width */

@media screen and (max-width: 320px) {

    .feedback-tab-bar {
        margin-top: 3em;
    }
}

/* css media queries for tabs from size 416px to 770px */
@media screen and (min-width: 416px) and (max-width: 770px) {

    .feedback-tab-bar {
        margin-top: 3em;
    }
}

/* css above 770px */

@media screen and (max-width: 900px) {
    .dummy-feedback-div{
        display: none;
    }

    .add-new-feed-btn{
        width: 10em !important;
        max-width: 100%;
    }

    .feedback-actions-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .feedback-search-container{
        justify-content: center;
    }
}

.feedback-tabpane .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4CCB1F;
    font-weight: 700;
    outline: none;
}

.feedback-tabpane .ant-tabs-tab-btn:hover {
    color: #4CCB1F;
}

.feedback-tabpane .ant-tabs-tab:hover {
    color: #4CCB1F;
}

.feedback-tabpane .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    color: #4CCB1F;
    background-color: #4CCB1F;
    border-bottom: 2px solid #4ccb1e !important;
}

.feedback-tabpane .ant-tabs {
    color: #BFBFBF;
    font-weight: 700;
}

.feedback-tabpane {
    z-index: 0;
    /* margin: 0 2.5em 0 2.5em; */
}


.give_feedback_btna {
    background-color: #4CCB1F;
    width: 170px;
    height: 42px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
}

.give_feedback_btna {
    background-color: #4CCB1F;
    border: solid #4CCB1F;
    -webkit-border: solid #4CCB1F;
    border-radius: 5px;
}

.give_feedback_btna:hover {
    background: #39DD00;
    background-color: #39DD00;
    -webkit-background: #39DD00;
    border: solid #39DD00;
    -webkit-border: solid #39DD00;
}
