.tip-section-container {
  display: flex;
}

.section-title-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 30%;
  font-weight: bold;
  margin: 15px 0px;
}

.first-element {
  border-top: 1px solid #cbd5e1;
}

.message-action-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cbd5e1;
  padding: 0rem 2rem;
}

.action-message-label {
  margin: 15px 0px;
}

.tip-action-switch {
  margin: 15px 0px;
}

.tip-section-container .ant-switch-handle::before {
  background-color: #aabac6 !important;
}

.tip-section-container .ant-switch-checked .ant-switch-handle::before {
  background-color: #0dd671 !important;
}

.message-action-container .ant-switch,
.message-action-container .ant-switch-checked {
  background-color: #f6fbff !important;
  border: 1px solid #e1f1ff !important;
}

@media (max-width: 1140px) {
  .ai-tips-root {
    max-width: 100% !important;
  }
}

@media (max-width: 760px) {
  .tip-section-container {
    flex-direction: column !important;
  }

  .section-title-label {
    max-width: none !important;
    margin: 15px 0px 0px 0px !important;
  }

  .first-element {
    border-top: none !important;
  }

  .first-section {
    border-top: 1px solid #cbd5e1 !important;
    align-items: normal !important;
  }

  .message-action-container {
    padding: 0px !important;
  }
}
