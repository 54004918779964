.back-icon-scape{
    /* position: absolute; */
    top: 10px !important;
    left: 20px;
    cursor: pointer;
}


.scape-report-parameter00{
  display: none;
}


.scape-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #141414;
    margin: 0;
}
.scape-dropdown{
    width: 402px !important;
    height: 50px;
    border: 1.24587px solid #BFBFBF;
    border-radius: 5.81405px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: #303030;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
}
.scape-dropdown:hover{
    border: 1.24587px solid #BFBFBF;
    color: #BFBFBF;
}
.scape-dropdown:focus{
    border: 1.24587px solid #BFBFBF;
    color: #BFBFBF;
}
.scape-dropdown.ant-dropdown-trigger > .anticon.anticon-down{
    display: none;
  }
.scape-menu-item{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align:left;
    color: #303030;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
.scape-table-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    color: #000000;
}
.scape-table-answer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    text-align: center;
    color: #000000;
}
.scape-table-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #000000;
}
.scape-table-container::-webkit-scrollbar {
    height: 3px;
    width: 100%;
  }
  
  .scape-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
    padding-right: 10px;
  }
  
  .scape-table-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:rgb(159, 159, 159);
  }
  .scape-dropdown-employee .ant-select-selector{
    width: 402px !important;
    height: 50px;
    border: 1.24587px solid #BFBFBF !important;
    border-radius: 5.81405px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    text-align: left;
    color: #303030;
    border-color:#BFBFBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer !important;
  }
  .scape-dropdown-employee{
    cursor: pointer !important;
  }
  .scape-dropdown-employee .ant-select-selection-placeholder{
    font-size: 18px !important;
    color: #BFBFBF !important;
  }
  .scape-dropdown-employee .ant-select-selection-item-content {
    max-width: 280px !important;
  }
  .scape-dropdown-employee .ant-select-selector:hover{
    border: 1.24587px solid #BFBFBF !important;
    border-color:#BFBFBF;
  }
  .scape-dropdown-employee .ant-select-selector:focus{
    border: 1.24587px solid #BFBFBF !important;
    border-color:#BFBFBF;
  }
  .scape-dropdown-employee .ant-select-selector .ant-select-selection-overflow .ant-select-multiple .ant-select-selection-item{
    font-size: 12px !important;
    height: 20px !important;
    line-height: 18px !important;
  }
  .scape-dropdown-survey .ant-select-selector{
    padding-top: 7px !important;
    font-size: 16px !important;
    line-height: 27px !important;
  }
  .search-button{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    height: 50px !important;
  }
  .search-button:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
  .search-button:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
@media (max-width:1700px) {
    .scape-table-title{
        font-size: 22px !important;
        line-height: 32px;
    }
    .scape-table-answer{
        font-size: 19px;
        line-height: 31px;
    }
    .scape-table-content{
        font-size: 18px;
        line-height: 29px;
    }
}
@media (max-width:1440px) {
    .scape-head{
        font-size: 26px;
        line-height: 36px;
        margin: 0;
    }
    .scape-dropdown{
        width: 300px !important;
        height: 45px;
        font-size: 16px;
    }
    .scape-dropdown-employee .ant-select-selection-item-content {
        max-width: 180px !important;
      }
    .scape-dropdown-employee .ant-select-selector{
        width: 300px !important;
        height: 45px !important;
      }
      .scape-dropdown-employee .ant-select-selection-placeholder{
        font-size: 16px !important;
        color: #BFBFBF !important;
      }
      .search-button{
        background: #4CCB1F;
        border: 2px solid #4CCB1F;
        border-radius: 6px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.005em;
        color: #FAFAFA;
        height: 45px !important;
      }
    .scape-menu-item{
        font-size: 13px;
        line-height: 24px;
        max-width: 300px !important;;
    }
    .scape-table-title{
        font-size: 20px !important;
        line-height: 32px;
    }
    .scape-table-answer{
        font-size: 17px;
        line-height: 28px;
    }
    .scape-table-content{
        font-size: 16px;
        line-height: 26px;
    }
}