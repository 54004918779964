
.idp-home-root {
    font-size: 16px;
    font-family: "Poppins";
}
.idp-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width:100%
}
.idp-green-btn,.idp-green-btn:hover,.idp-green-btn:focus{
    background-color: #4CCB1F;
    height:2.75em;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    border: 1px solid #4CCB1F;
    box-shadow: none;
    border-radius: .5em;
    outline: none;
    padding: .5em 1.5em;
    cursor: pointer;
    color: #fff;
}
.idp-green-border-btn,.idp-green-border-btn:hover,.idp-green-border-btn:focus{
    background-color: #fff;
    height:2.75em;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    border: 1px solid #4CCB1F;
    box-shadow: none;
    border-radius: .5em;
    outline: none;
    padding: .5em 1.5em;
    cursor: pointer;
    color: #4CCB1F;
}
.idp-team-button{
    color: #009AF1;
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.idp-home-container{
    background: #FFFFFF;
    box-shadow: 0px 10px 15px 0px #AABAC633;
    border-radius: .5em;
    padding: 1em 2em;
    min-height: 80vh;
    /* margin:1em 10em */
}
.idp-table-container{
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    
    
}

.idp-row-body{
    background: #FFFF;
    width: 100%;
    padding: 1em 3.4375em 1em 3.6875em;
}

.idp-row-body td{
    padding: 1em 0px;
    border: 1px solid #D5E5F2;
}
.idp-pending-btn{
    background: rgba(249, 95, 83, 0.2);
    color: rgba(249, 95, 83, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-inprogress-btn{
    background: rgba(242, 182, 82, 0.2);
    color: rgba(242, 182, 82, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-approved-btn{
    background: rgba(76, 203, 31, 0.2);
    color: rgba(76, 203, 31, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-complete-btn{
    background: rgba(13, 214, 113, 0.2);
    color: rgba(13, 214, 113, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-revise-btn{
    background: rgba(217, 176, 59, 0.2);
    color: rgba(217, 176, 59, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;

}
.idp-draft-btn{
    background: rgba(14, 14, 44, 0.2);
    color: rgba(0, 0, 0, 1);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    font-style: normal;
    padding: .35em 1em;
    max-width: 10em;
    width: 100%;
    border-radius: .25em;
}
.idp-text-transform{
    text-transform: lowercase;
}
.idp-text-transform::first-letter {
    text-transform: uppercase;
  }
@media (max-width: 1600px) {
    .idp-home-root {
        font-size: 14px;
    }
}
@media (max-width: 960px) {
    .idp-home-root {
        font-size: 12px;
    }
}
@media (max-width: 600px) {
    .idp-home-root {
        font-size: 10px;
    }
}