.change-password-input,.change-password-input:hover,.change-password-input:focus {
    border: 1px solid #AABAC6 !important;
    border-radius: .5em;
    width: 100%;
    height: 3.5em !important;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 400;
    box-shadow: none;
}
.change-password-action-button-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: start;
    align-items: center;
}
.profile-notification-root .ant-form-item-children-icon{
    display: none !important;
}