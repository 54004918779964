.badge-upload-container .anticon-eye{
    display: none;
}

.kudos-root-class{
    font-size: 16px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
}
.kudos-modal-delete{
    width: 38.625em !important;
}
.kudos-modal-delete .ant-modal-body{
    padding: 0px 10px 10px 10px !important;
}
.kudos-modal{
    width: 53.063em !important;
}

.kudos-modal-overflow{
    max-height: 80vh;
    overflow: auto;
}

.kudos-modal-overflow::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    display: block;
  }
  
  .kudos-modal-overflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .kudos-modal-overflow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
  }

.kudos-space-between-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.625em; 
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    /* padding: 0.38em 2.75em 3em 2.75em; */
    /* background-color: #4CCB1F; */
}
.kudos-prev-page,.kudos-next-page{
    position: absolute;
    top: 22em;
    background-color: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding: 0px;
}

.kudos-next-page{
    right: 0px;
}

.kudos-prev-page:hover,.kudos-next-page:hover{
    background-color: transparent !important;
    border: none;
}

.kudos-prev-page:active,.kudos-next-page:active{
    background-color: transparent;
    border: none;
}

.kudos-prev-page:focus,.kudos-next-page:focus{
    background-color: transparent;
    border: none;
}

.kudos-prev-page:disabled,.kudos-next-page:disabled{
    background-color: transparent;
}

.kudos-tool-titile{
    font-weight: 500;
    font-size: 2.1875em;
    line-height: 1;
    color: #141414;
    margin: 0px;
}

.kudos-sub-title{
    font-weight: 500 !important;
    font-size: 1em !important;
    /* line-height: 140%; */
    color: #595959;
    text-align: center;
    margin-top: .5em;
}

.kudos-add-badge{
    padding: 0.75em 1.5em;
    gap: 0.625em;
    width: 8.9375em;
    height: 3em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 700;
    font-size: 1em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}
.kudos-add-badge:hover,.kudos-add-badge:active,.kudos-add-badge:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;

}

.kudos-btn-disable{
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;
}

.kudos-cursor-disabled{
    cursor: not-allowed !important;
}

.kudos-btn-disable:hover,.kudos-btn-disable:active,.kudos-btn-disable:focus{
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.kudos-search-container{
    padding: 0px;
    width: 22.6875em;
    height: 3em;
    position: relative;
}
.kudos-search-input{
    /* width: 100%; */
    padding-left: 2.5em;
    height: 100%;
    border-radius: 10px;
}
.kudos-search-icon{
    position: absolute;
    top: 0.75em;
    left: 0.625em;
    z-index: 1;
    width: 1.5em;
    height: 1.5em;
}

.kudos-modal-title{
    font-weight: 400 !important;
    font-size: 1.6em !important;
    line-height: 1 !important;
    letter-spacing: -0.02em;
}


.kudos-badge-form{
    padding: 1em 7% 1em 0px ;
}

.kudos-form-group{
    width: 100%;
}

.kudos-description{
    width: 100%; 
    height: 120px;
    resize: none;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    outline: none !important;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5715;
}

.kudos-description::placeholder{
    color: rgb(194, 194, 194);
}

.badge-info-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
}

.badge-info-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-bottom: 1.25em;
}

.upload-container{
    min-width: 110px;
    min-height: 110px;
    border-radius: 50%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: #D9D9D9;
    cursor: pointer;
}

.dummy-container{
    min-width: 110px;
    min-height: 110px;
    border-radius: 50%;
    background-color: transparent;
}
/* 7.438em; */
.upload-success-container{
    min-width: 110px;
    min-height: 110px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
}

.upload-success-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
}

.kudos-modal-btn{
    padding: 0.75em 1.5em;
    line-height: 1.5em;
    font-size: 1em;
    margin: 0.75em;
    height: 3em;
    border-radius: 0.5em;
    font-weight: 700;
    cursor: pointer;
}

.kudos-save, .kudos-save:hover, .kudos-save:focus{
    background: #4CCB1F;
    color: #FFFFFF;
    border: 2px solid #4CCB1F;
}

.kudos-new-badge, .kudos-new-badge:hover, .kudos-new-badge:focus{
    background: #FFFFFF;
    color: #4CCB1F;
    border: 2px solid #4CCB1F;
}

.kudos-form-group .ant-select .ant-select-selector{
    height: 40px !important;
    border-radius: 8px !important;
}

.kudos-form-group .ant-select .ant-select-arrow{
    top: 48% !important;
}

.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    display: flex;
    align-items: center;
}

.kudos-form-group .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: rgb(194, 194, 194) !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.kudos-form-group .ant-select-selector .ant-select-selection-search-input{
    padding-top:7px !important;
}
.no-border-category .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
}
.badge-index{
    font-weight: 400;
    font-size: 1.5em;
    line-height: 36px;
    letter-spacing: -0.02em;
    padding: 0px !important;
    margin: 0px !important;
    color: rgba(0, 0, 0, 0.67);
}

.selected-badge-index{
    color: #4CCB1F !important;
}

/* kudos home screen */

.kudos-fluid-container{
    width: 100%;
    /* max-width: 1920px; */
    
}

.kudos-fluid-container .ant-tabs-top > .ant-tabs-nav, .kudos-fluid-container .ant-tabs-bottom > .ant-tabs-nav,.kudos-fluid-container .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin: 0px !important;
}


.kudos-main-card-container{
    width: 100%;
    background: rgba(246, 246, 246, 0.62);
    padding: 1em 2.5em;
    
}

.card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px 2em;
    /* background: red; */
    flex-wrap: wrap;
}

.badge-card-vertical-container{
    width: 33.33%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    /* position: relative; */
}

.badge-card{
    width: 100%;
    height: 15.375em;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.11);
    border-radius: 0.3em;
    overflow: hidden;
    padding: 0.813em 0.938em;
    display: flex;
    flex-direction: column;
    transition-duration: .5s;
    margin-bottom:6.375em ;
}

.badge-card-exp{
    height: 44.75em;
    transition-duration: .5s;
}

.kudos-delete-icon{
    width: 1.125em;
    height: 1.5em;
    cursor: pointer;
}

.kudos-card-info{
    width: 100%;
    /* height: 100%; */
    height: 11.8em; 
    background-color: rgba(76, 203, 31, 0.05);;
    border-radius: 0em !important;
    margin-top: 0.375em;
    padding: 0.375em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.badge-info-card-fixed{
    height: 17.2em !important; 
}

.kudos-card-title-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
}

.kudos-badge-sub-action{
    padding: 0px !important;
    gap: 0.5em;
    justify-content: start !important;
    align-items: center !important;
    width: 80%;
}

.kudos-badge-sub-action .title{
    margin: 0px;
    font-weight: 500;
    font-size: 1em;
    line-height: 140%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.kudos-card-title{
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 2em;
  cursor: default;
}

.kudos-card-edit{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
}

.kudos-card-dis{
    overflow: hidden;
    height: 100%;
    padding: 0px 6%;
    text-align: left;
    cursor: default;
    font-weight: 300;
    font-size: 1em;
    line-height: 140%;
    text-transform: lowercase;
    color: #8C8CA2;
}

.kudos-card-action-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}

.kudos-card-add-badge{
    font-weight: 300;
    font-size: 0.875em;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 0.313em;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.card-discription{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* width: 155px; */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.new_drop_arrow{
    transition-duration: .5s;
    transform: rotate(270deg);
    cursor: pointer;
}
.kudos-rotate-arrow{
    transform: rotate(360deg);
    transition-duration: .5s;
}

.badge-list-container{
    height: 0px;
    padding: 0px 1.2em;
}

.badge-list-container *{
    opacity: 0;
}
.badge-list-visible{
    height: 100%;
    overflow: auto;
}

.badge-list-visible::-webkit-scrollbar {
    height: 0px;
    width: 5px;
  }
  
  .badge-list-visible::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .badge-list-visible::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
  }

.badge-list-visible *{
    transition-delay: .5s;
    opacity: 1 !important;
}
.kudos-border{
    border-left: 1px dashed rgba(0, 0, 0, 0.5);
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='1' stroke-dasharray='24' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e"); */
    height: fit-content;
    /* min-height: 100%; */
    padding: 1em 1.419em;
}

.kudos-single-badge-card{
    border-width: 0px 0.3px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5);
    padding: 0px 0.688em;
    margin-bottom: 1.5em;
    height: 3.75em;
    position: relative;
}

.badge-index-circle{
    position: absolute;
    width: 1.625em;
    height: 1.625em;
    top: 1em;
    left: -2.29em;
    background: #FFFFFF;
    border: 1px solid #4CCB1F;
    border-radius: 50%;
}

.badge-behavioural-description{
    text-align: left;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 140%;
    color: #8C8CA2;
    margin-top: 0.5em;
}

.kudos-single-badge-info :hover{
    /* scale: 2; */
    transition-duration: .5s;
}

.badge-card-hide{
    height: 0px;
    padding: 0px;
    opacity: 0;
    margin: 0px !important;
    transition-duration: .5s;
}
.badge-card-hide *{
    display: none !important;
}

.delete-logo{
    width: 15.438em;
    height: 15.938em;
    margin-right: 5em;
}

.kudos-delete-title{
    margin-top: 1.2em;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 2em;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
}

.kudos-delete-btn{
    width: 6.625em;
    height: 3em;
    background: #E86161;
    border-radius: 0.5em;
    margin: 1em;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
    border: 2px solid #E86161;
    padding: 0.75em 1em;
}

.kudos-delete-btn:hover,.kudos-delete-btn:focus{
    background: #E86161;
    border: 2px solid #E86161;
    color: #FFFFFF;
}

.kudos-cancel-btn{
    margin: 1em;
    width: 6.625em;
    height: 3em;
    background:transparent;
    border-radius: 0.5em;
    margin: 1em;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
    border: 2px solid #8C8C8C;
    padding: 0.75em 0px;
}

.kudos-cancel-btn:hover,.kudos-cancel-btn:focus{
    background: transparent;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.kudos-existing-no-data{
    min-height: 50vh;
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(246, 246, 246, 0.62);
    /* background-color: #E86161; */
}

.kudos-no-data-img{
    object-fit: contain;
    max-width: 95%;
}

.kuods-not-found{
    font-weight: 400;
    font-size: 1.5em;
    line-height: 2.25;
    text-transform: capitalize;
    color: #000000;
    margin-top: -4em;
    text-align: center;
}

.kudos-no-data-sub{
    color: #777777;
    font-size: 1em;
    text-align: center;
    width: 53.125em;
    max-width: 100%;
}

.kudos-go-back-btn{
    width: 7.571em;
    height: 2.245em;
    background: #777777;
    color: #FFFFFF;
    font-size: 1.5em;
    font-weight: 400;
    margin-top: 2em;
    cursor: pointer;
    border: 1px solid #777777;
}
.kudos-go-back-btn:hover ,.kudos-go-back-btn:focus{
    background: #777777;
    color: #FFFFFF;
    border: 1px solid #777777;
}

.kudos-page-arrow{
    width: 2em;
    height: 2em;
}
.kudos-fluid-container .ant-tabs-tab div{
    font-size: 1.5em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}

.kudos-fluid-container .ant-tabs-tab-active div{
    color: #4CCB1F !important;
}
.kudos-fluid-container .ant-tabs-ink-bar{
    background-color:#4CCB1F !important;
}

.delete-show-mobile{
    display: none;
}

.delete-hide-mobile{
    display: block;
}
.kudos-popup-inner-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.kudos-detail-right-container-inner{
    padding: 0px 1em;
}
.kudos-badge-title-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 140%;
    color: #4CCB1F;
}
.kudos-badge-description-modal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 140%;
    color: #8C8CA2;
}
.kudos-badge-modal-subhead{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size:2em;
    line-height: 2.4em;
    color: #000000;
    text-align: left;
}
.kudos-modal-main-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2em;
    line-height: 3em;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #000000;
}
.kudos-fluid-container .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: none;
}
.kudos-fluid-container .ant-tabs-tab{
    /* padding: 5px 0px !important; */
  }
  .kudos-fluid-container .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 1.75em !important;
  }

@media (max-width:1600px) {
    .kudos-search-container{
        width: 18em;
        height: 2.5em;
    }
    .kudos-search-icon{
        top: .5em;
    }
    .kudos-modal{
        width: 45.063em !important;
    }
    .badge-card{
        margin-bottom: 4.375em;
    }
}
@media (max-width:1400px) {
    .badge-card-vertical-container{
        width: 50%;
        padding: 1.5em;
    }
}

@media (max-width:1200px) {
    .kudos-root-class{
       font-size: 14px !important;
    }

    .delete-logo{
        margin-right: 8em;
    }

    .kudos-prev-page,.kudos-next-page{
        top: 19em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.4em;
    }
}

@media (max-width:960px) {
    .kudos-root-class{
       font-size: 12px !important;
    }
   
    .kudos-badge-form{
        padding: 1em 10px 1em 0px;
    }
    .delete-logo{
        margin-right: 12.5em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 16.5em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.3em;
    }
}

@media (max-width:700px) {
    .align-logo{
        display: flex;
        align-items: start;
    }
    .delete-logo{
        margin-right:40%;
        margin-left: 40%;
    }
}

@media (max-width:760px) {
    .badge-card-vertical-container{
        width: 100%;
    }
}

@media (max-width:600px) {
    .kudos-root-class{
        font-size: 11px !important;
     }
    .badge-info-row{
        flex-direction: column;
    }

    .badge-info-row .ant-col-sm-13 {
        display: block;
        flex: 0 0 54.16666667%;
        max-width: 100%;
    }
    .badge-info-main{
        flex-direction: column;
    }
    .dummy-container{
        display: none !important;
    }
    .delete-logo{
        margin-right: .5em;
    }
    .kudos-go-back-btn{
        width: 7em;
        height: 2.5em;
        font-size: 1em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 14.5em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1.2em;
    }
    .kudos-form-row{
        display: flex;
        flex-direction: row;
        gap: 2em;
    }
    .delete-show-mobile{
        display: block;
    }
    .delete-hide-mobile{
    display: none;
    }
}

@media (max-width:450px) {
    .kudos-col-450{
        flex-direction: column;
    }
    .kudos-hide-450{
        display: none;
    }
    .kudos-search-container{
        max-width: 100%;
    }
    .kuods-not-found{
        margin-top: -3em;
        font-size: 1.2em;
    }
    .kudos-no-data-sub{
        font-size: 0.8em;
    }
    .kudos-fluid-container .ant-tabs-tab div{
        font-size: 1em;
    }
    .kudos-page-arrow{
        width: 1.5em;
        height: 1.5em;
    }
    .badge-card-vertical-container{
        padding: 1.8em .4em;
    }
    .badge-card{
        margin-bottom: 4.375em;
    }
    .kudos-prev-page,.kudos-next-page{
        top: 13.5em;
    }
}





