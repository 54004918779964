.mapp-review-root{
    font-size: 16px;
    font-family: 'Poppins';
}
.mapp-feedback-review-popup .ant-modal-content{
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 1);
}
.mapp-review-container{
    padding: 2em;
    display:flex;
    flex-direction: column;
    justify-content: start;
    gap:1em;
    align-items: start;
}
.mapp-review-title{
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    color:rgba(23, 15, 73, 1);
    margin: 0px;
}
.mapp-review-comment-input,.mapp-review-comment-input:focus,.mapp-review-comment-input:hover{
    width: 100%;
    resize: none;
    border-radius: 1em;
    border: 1px solid rgba(239, 240, 246, 1);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    outline: none;
    margin-top: 1em;
    padding: .5em;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 400;
}
.mapp-review-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
    margin-top: 1em;
}
.mapp-review-star-container{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 4em;
    width: 100%;
}
.mapp-review-submit-btn,.mapp-review-submit-btn:hover,.mapp-review-submit-btn:focus {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2em;
    height: 3.5em !important;
    border-radius: 2em;
    text-align: center;
    padding: .5em 2em;
    border: none;
    box-shadow: 0px 8px 22px 0px rgba(74, 58, 255, 0.26);
    outline: none;
    cursor: pointer;
}
.mapp-review-skip-btn,.mapp-review-skip-btn:focus,.mapp-review-skip-btn:hover {
    background: rgba(116, 116, 128, 0.08);
    font-family: 'Poppins';
    font-weight: 600;
    font-size: .85em;
    color: rgba(0, 0, 0, 1);
    border-radius: 1em;
    padding: .5em 2em;
    border: none;
    outline: none;
    cursor: pointer;
}
.review-popcon-yes-btn,.review-popcon-yes-btn:hover,.review-popcon-yes-btn:focus {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    height: 2.5em !important;
    border-radius: 1.5em;
    text-align: center;
    padding: 0em 2em;
    border: none;
    box-shadow: 0px 8px 22px 0px rgba(74, 58, 255, 0.26);
    outline: none;
    cursor: pointer;
}
.review-popcon-no-btn,.review-popcon-no-btn:hover,.review-popcon-no-btn:focus {
    background: rgba(116, 116, 128, 0.08);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    color: rgba(0, 0, 0, 1);
    border-radius: 1.5em;
    padding: 0em 2em;
    height: 2.5em !important;
    border: none;
    outline: none;
    cursor: pointer;
}
.review-popcon{
    margin-top: 30px !important;
}
.review-popcon .ant-popover-content{
    margin-top: 80px !important;
}
.review-popcon .ant-popover-buttons {
    display: flex;
    justify-content: center;
}