.poc-org-root{
    font-size: 16px;
    font-family: Poppins;
}

.poc-org-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 230px);
}
.poc-grap-container{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
}

.poc-org-nav-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.poc-org-router-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 300px) ;
    padding: 0px 1em;
}
.vertical-switch-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
    width: fit-content;
}
.vertical-switch-tab-text{
    padding: .5em ;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: fit-content;
    height: 2.5em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    width: fit-content;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}
.vertical-input,.vertical-input:hover,.vertical-input:focus{
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid #AABAC6;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.vertical-form-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}
.organization-save-btn,.organization-save-btn:hover,.organization-save-btn:focus{
    background: rgba(76, 203, 31, 1);
    height: 2.5em;
    color: #fff;
    border: 1px solid rgba(76, 203, 31, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}
.organization-delete-btn,.organization-delete-btn:hover,.organization-delete-btn:focus{
    background: rgba(249, 95, 83, 1);
    height: 2.5em;
    color: #fff;
    border: 1px solid rgba(249, 95, 83, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}
.organization-cancel-btn,.organization-cancel-btn:hover,.organization-cancel-btn:focus{
    background: #fff;
    height: 2.5em;
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 1);
    padding: .5em 1em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
    outline: none;
    border-radius: .25em;
}

.module-usage-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: auto;
    padding-bottom: 1.5em;
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 140px);
}

.module-usage-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 210px);
    padding: 0px 1em;
    overflow: auto; 
}

.module-usage-outlet::-webkit-scrollbar {
    height: 100%;
    width: 4px;
    display: block;
  }
  
  .module-usage-outlet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom:20px;
    margin-top:10px
  }
  
  .module-usage-outlet::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .custom_height_class{
    max-height: calc(100vh - 250px);
    margin-top: 1em;
  }

  .module-usage-table-height{
    max-height: calc(100vh - 300px);
    margin-top: 1em;
  }
.usage-date-filter-container{
    background: #F1F7FC;
    padding: .5em 1em;
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.usage-date-filter-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    padding: .5em 1em;
    white-space: nowrap;
    cursor: pointer;
}
.usage-date-filter-label-selected{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    background: #4CCB1F;
    color: #fff;
    padding: .5em 1em;
    white-space: nowrap;
    cursor: pointer;
    border-radius: .5em;
}
@media screen and (max-width:1000px) {

    .poc-org-router-outlet{
        padding: 0px 1em 0em 1em;
    }

}