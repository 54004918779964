


.containct-us-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    transform: translate(-50%, -50%) scale(0.6);
    width: 25px;
 }
 .containct-us-table .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-197%, -69%);
 }
 .containct-us-table .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-197%, -69%);
 }
 .containct-us-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    display: none;
    background: inherit;
    border: inherit;
 }
 
 