



.sales-tool-heading{
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
}

.sales-tool-description{
    width: 80%;
    font-size: 16px;
    font-weight: 500;
}

.tool-subheading-sales-tool{
    font-size: 35px;
    margin-bottom: 20px;
    margin-top: -30px;
    font-weight: 500;
}


@media screen and (max-width:1200px) {


 .sales-tool-heading{
        font-size: 35px;
    }

    .sales-tool-description{
        width: 100% !important;
        font-size: 20px;
    }

    .mapp-tool-text-col-sales{
        max-width: 100% !important;
        margin-left: 0px !important;
    }


    .mapp-tool-text-col-sales ul{
        max-width: 80% !important;
        margin-left: 40px !important;
    }



}




@media screen and (max-width:768px) {


    /* mapp tool styles */

    .sales-tool-button span{
        font-size: 16px;
    }



    .mapp-tool-text-col-sales{
        margin-top: 40px;
    }

    .sales-tool-heading,.tool-subheading-sales-tool,.sales-tool-description{
        text-align: start;
    }


    .mapp-tool-sales-page{
        flex-direction: column-reverse;
    }

    .sales-tool-heading,.tool-subheading-sales-tool,.sales-tool-description{
        text-align: justify;
    }

    .sales-tool-heading{
        font-size: 35px;
    }

   

}





@media screen and (max-width:500px) {

    /* mapp tool styles */

    .sales-tool-button span{
        font-size: 14px;
    }

    .sales-tool-heading{
        font-size: 25px;
        margin-bottom: 20px;
    }

    .tool-subheading-sales-tool{
        margin-top: 10px;
    }

    .tool-subheading-sales-tool{
        font-size: 18px;
    }
 

}

@media screen and (min-width:1500px) {

    .sales-tool-description{
    font-size: 20px;
    }
}

@media screen and (min-width:700px) and (min-height:768px) {

    .mapp-tool-sales-page{
        flex-direction: row;
    }
 
}

