
.your-kudos-card-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .your-kudos-card {
    border-radius: 12px;
    text-align: center;
  }
  
  .your-kudos-card-image {
    border-radius: 50%;
    height: 100px !important;
    width: 100px !important;

  }
  

  .your-kudos-card-title {
    margin: 0.3em 0 0.1em;
    font-size: 0.9em;
    font-weight: 600;
  }
  
  .your-kudos-card-subtitle {
    color: gray;
    font-size: 0.75em;
  }
  

  .your-kudos-card-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 0.5em;
  }
  

  .your-kudos-button {
    width: 85px;
    height: 30px;
    font-size: 0.7em;
    border-radius: 6px;
    font-weight: 500;
  }
  
  .your-kudos-receive {
    border-color: #4ccb1f;
    color: black;
  }
  
  .your-kudos-given {
    background-color: #4ccb1f;
    border-color: black;
    color: white;
  }
  .your-kudos-recent{
    margin-top: 1em;
    height: 4.5em;
    display: flex;
    flex-direction: row;
    border-radius: 1em;
    justify-content: space-around;
    align-items: center;
    margin: 40px 30px 0px 30px;
  }
  .your-kudos-recent-image{
    margin: 12px;
    border-radius: 50%;
  }
  .your-kudos-recent-badge{
    margin: 12px;
    border-radius: 50%;
  }



  .your-kudos-recent-text{
    color: white;
    font-size: small;
  }
  .your-kudos-main-title{
    display: flex;
    justify-content: space-around;
  }

.your-kudos-button,.your-kudos-button:hover,.your-kudos-button:focus{
  font-family: Poppins;
  font-weight: 700;
  font-size: .8em;
  line-height: normal;
  text-align: center;
  padding: 1em;
  border-radius: .5em;
  color: rgba(12, 12, 13, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  outline: none;
  width: 7em;
  height: 3em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

}
.your-kudos-button-select,.your-kudos-button-select:hover,.your-kudos-button-select:focus{
  font-family: Poppins;
  font-weight: 700;
  font-size: .8em;
  line-height: normal;
  text-align: center;
  padding: 1em;
  border-radius: .5em;
  color: #fff;
  border: 2px solid #4CCB1F;
  background:#4CCB1F;
  outline: none;
  width: 7em;
  height: 3em !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.your-kudos-barde-label{
  font-family: Poppisn;
  font-size: .9em;
  font-weight: 700;
  line-height: normal;
  font-style: normal;
  margin: 0px;
}
.your-kudos-popup-container-scroll{
  overflow: auto;
  max-height: 20em;
  padding-right: 10px;
}
.your-kudos-popup-container-scroll::-webkit-scrollbar {
  height: 4px;
  width: 4px !important;
  display:block;
}
.your-kudos-popup-container-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
  }
  
.your-kudos-popup-container-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#b1b0b0e1;
}