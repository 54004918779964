.main-survey-card{
    padding: 0px 1em 1em 0px;
    width: 33.33%;
    max-width: 29.125em;
}

.survey-card{
    width: 100%;
    box-shadow: 0px 4px 15px 0px #AABAC626;
    background: #FFFFFF;
    border-radius: 0.75em;
    padding: 1.2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.survey-main-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
}
.survey-title-section{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.survey-title{
    margin: 0px;
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #1F1F1F;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.survey-info{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: justified;
    margin-top: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.common-btn{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.04em;
    text-align: center;
    width: 50%;
    padding: 0em 0.5em;
    border-radius: 0.5em;
    min-height: 2.938em;
    margin-top: 1em;
}

.survey-green-btn, .survey-green-btn:hover, .survey-green-btn:focus, .survey-green-btn:active{
    border: 1px solid #4CCB1F;
    color: #4CCB1F;
}

.survey-blue-btn, .survey-blue-btn:hover, .survey-blue-btn:focus, .survey-blue-btn:active{
    border: 1px solid #009AF1;
    color: #009AF1;
}

.no-data-container{
    height: 80vh;
 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-data-label{
    font-family: Poppins;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
}
.survey-invite-title{
    font-size: 1.5em;
    font-weight: 600;
    font-family: 'Poppins';
    font-style: normal;
    margin: 0;
    color: #000;
    line-height: normal;
}
.s-i-table-body-container{
    display: flex;
    flex-direction: row;
    border-radius: 0.5em;
    background: #FFF;
    width: 100%;
    margin-top: 1em;
    border: 1px solid #D5E5F2;
    padding: 1em;
}
.s-i-pending-button{
    background: rgba(249, 95, 83, 0.2);
    width: 7em;
    padding: .25em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    color: rgba(249, 95, 83, 1);
    font-size: 1em;
    border-radius: .5em;
}
.s-i-complete-button{
    background: rgba(76, 203, 31, 0.2);
    width: 7em;
    padding: .25em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    color: rgba(76, 203, 31, 1);
    font-size: 1em;
    border-radius: .5em;
}
.s-i-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    font-family: "Poppins";
    text-align: center;
}
.s-i-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.invite-switch-tab{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em 1em;
}
.invite-switch-tab-text{
    padding: .5em 1em;
    font-family: "Poppins";
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}
.invite-switch-tab-selected{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
}
.invite-main-content-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.survey-invite-form-title{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.25em;
    font-style: normal;
    line-height: normal;
}

.survey-invite-form-title-input,.survey-invite-form-title-input:hover,.survey-invite-form-title-input:focus{
    width: 100%;
    height: 3em;
    background-color: #fff;
    border-radius: .5em;
    border: 1px solid rgba(170, 186, 198, 1);
    font-family: "Poppins";
    font-size: 1em;
    font-weight: 400;
}

.survey-invite-cancel-btn,.survey-invite-cancel-btn:hover,.survey-invite-cancel-btn:focus{
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    text-align: center;
    font-style: normal;
    line-height: normal;
    padding: .5em 1em;
    color: rgba(137, 152, 162, 1);
    border: 1px solid rgba(137, 152, 162, 1);
    border-radius: .25em;
    height: 3em;
    width:8em;
}

.survey-invite-send-btn,.survey-invite-send-btn:hover,.survey-invite-send-btn:focus{
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    text-align: center;
    font-style: normal;
    line-height: normal;
    padding: .5em 1em;
    color:#fff;
    background-color: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: .25em;
    height: 3em;
    width:8em;
}

.invite-multipple-employee-list-container{
    width: 100%;
    border-bottom: 1px solid rgba(201, 208, 218, 1);
}

.invite-multipple-employee-title{
    font-size: 1.5em;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    margin: 0;
    color: rgba(0, 0, 0, 1);
}
.invite-wrap-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
    flex-wrap: nowrap;
}
.invite-container-padding{
    padding: 0em 6em;
}
.invite-message-content{
    font-size: .85em;
    font-weight: 400;
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
}
@media screen and (max-width:1260px) {
    .main-survey-card{
        width: 50%;
        max-width: 40em;
    }
    .invite-container-padding{
        padding: 0em 4em;
    }
}

@media screen and (max-width:860px) {
    .main-survey-card{
        width: 100%;
        max-width: none;
    }
    .invite-wrap-container{
        flex-wrap: wrap;
    }
    .invite-container-padding{
        padding: 0em 1em;
    }
}

.report-action-btn, .report-action-btn:hover, .report-action-btn:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;     border:1px solid rgb(216, 216, 216);
    cursor: pointer;
}

.report-action-btn-disabled, .report-action-btn-disabled:hover, .report-action-btn-disabled:focus {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center; 
    border:1px solid rgb(216, 216, 216);
    cursor:not-allowed;
    background: rgb(233, 233, 233);
}

.report-action-btn-disabled img{
    width: 2em;
    height: 2em;
    object-fit: contain;
}
 

.report-action-btn img{
    width: 2em;
    height: 2em;
    object-fit: contain;
}