


.requests-side-tool button{
    width: 47px !important;
    height: 47px !important;
}

.requests-side-tool .ant-btn{
    top: 210px !important;
}

.filter-drop .selected-filter{
    color: #595959;
}

.requests-side-tool .ant-btn:hover{
  background: rgb(176, 203, 31) !important;
}

.requst-detail-margin{
    padding: 0em 4em;
}
.discard-botton-div-new{
    background: #FFFFFF;
    box-shadow: -1px 3px 173px rgba(0, 0, 0, 0.14);
    height: 120px;
    position:fixed;
    bottom: 0px !important;
    left: 10em;
    right:10em;
    width:100%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    justify-content: center center;
    align-items: center;
    padding-top: 40px;
}
@media  (max-width:1200px) {
    .requst-detail-margin{
        padding: 0em 2em;
    } 
}
@media  (max-width:960px) {
    .requst-detail-margin{
        padding: 0em 1em;
    } 
}
@media (max-width:600px) {
    .requst-detail-margin{
        padding: 0em 1em;
    }
}
@media screen and (min-height:601px){ 
    .requests-side-tool button{
        width: 47px !important;
        height: 47px !important;
        top: 240px !important;
    }
    
    
    }

.okr-discard-request-delete-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 220px);
}

.discard-request-delete-warning{
    font-size: 30px;
}