.poc-org-tree-container{
    width: 100%;
    height: 100%;
}

.non-edit-poc-foreign-parent{
    width: 100%;
    height: 180px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer !important;
}

.child-count-container{
    /* position: absolute; */
    margin-top: -15px;
}

.tree-child-count{
    width: 1em;
    height: 1em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.tree-child-count span{
    font-size: 1em;
    color: #FFFFFF;
    font-weight: 500;
}
.poc-foreign-parent{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
}

.poc-node-template{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.tree-default-node-border{
    border: 2px solid transparent;
}


.tree-organization-node-border{
    border: 2px solid #0C8AFF;
}

.tree-team-node-border{
    border: 2px solid #21D796;
}

.tree-vertical-node-border{
    border: 2px solid #21CCD7;
}

.tree-sub-vertical-node-border{
    border: 2px solid #6876ED;
}

.tree-default-link{
    stroke: #D5E5F2 !important;
    stroke-width: 2px;
}

.tree-team-link{
    stroke: #21D796!important;
    stroke-width: 2px;
}

.tree-vertical-link{
    stroke:  #21CCD7!important;
    stroke-width: 2px;
}

.tree-sub-vertical-link{
    stroke:  #6876ED!important;
    stroke-width: 2px;
}

.tree-row-spacebetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
}

.tree-row{
    display: flex;
    flex-direction: row;  
    gap: 16px;
}

.tree-menu-container{
    width: fit-content;
    display: flex;
    align-items: center;
}

.tree-menu-icon{
    width: 28px;
    height: 28px;
}

.rotate-menu-icon{
    transform: rotate(90deg);
}

.tree-large-profile-container{
    width:54px;
    height:54px;
    min-width: 54px;
    min-height: 54px;
    border-radius:50%;
    background:#E9E9E9
}

.tree-medium-profile-container{
    width:40px;
    height:40px;
    min-width: 40px;
    min-height: 40px;
    border-radius:50%;
    background:#E9E9E9
}

.tree-data-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
}

.tree-title{
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.tree-node-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
}

.tree-node-bottom-container{
    padding: 8px 16px;
    width: 100%;
}

.tree-input-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.tree-input-box{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.tree-choice-type-container{
    width: 100%;
    height: 100%;
}

.dual-type-main-border{
    width: 240px;
    height: 110px;
    border: 2px solid #D5E5F2 ;
    border-bottom: none;
}

.mono-type-main-border{
    width: 2px;
    height: 110px;
    border: 2px solid #D5E5F2 ;
    border-bottom: none;
}

.tree-create-circle{
    width:36px;
    height:36px;
    min-width: 36px;
    min-height: 36px;
    border-radius:50%;
    background:#C9D0DA;
    font-size: 28px;
    padding:18px 8px 0px 8px;
    cursor: pointer !important;
}

.tree-create-circle:hover{
    background: #00CA64;
}

.tree-choice-circle-left{
    margin-top: 30px;
    margin-left: -18px;
}

.tree-choice-circle-right{
    margin-top: -35px;
    margin-left: 220px;
}

.tree-choice-btn{
    width: 170px;
    height: 47px;
    border-radius: 8px;
    background-color: #D5E5F2;
    font-weight: 500px;
    font-size: 16px;
    padding: 12px 5px 5px 5px;
    text-align: center;
    color: #AABAC6;
    cursor: pointer;
}

.tree-choice-btn:hover{
    background: #00CA64;
    color: #FFFFFF;
}

.tree-choice-left-btn{
    margin-top: 35px;
    margin-left: -82px;
}

.tree-choice-right-btn{
    margin-top: -47px;
    margin-left: 148px;
}

.tree-profile-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.tree-role-label{
    font-size: 12px;
    color: #7B8994;
    font-weight: 400;
    text-transform: capitalize;
}

.tree-profile-name{
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #C9D0DA;
}

.tree-multiselect-label-container{
    width: 100%;
    height: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0.5em;
    border: 1px solid #AABAC6;
    border-radius: 8px;
    margin-top: 0.75em;
}

.tree-label-chip{
    width: fit-content;
    padding: 4px 8px;
    background: rgba(13, 214, 113, 0.18);
    border: 1px solid rgba(13, 214, 113, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    align-items: center;
    margin: 0px 4px;
}

.tree-label-chip h4{
    color:#000000;
    font-size: 1em;
    margin: 0px !important;
    white-space: nowrap;
}

.chip-clear{
    cursor: pointer;
    width: 10px;
}

.tree-dropdown-menu{
    background: rgba(238, 243, 246, 1) !important;
    max-height: 250px;
    overflow: auto;
    border-radius: 20px;
    padding: 8px;
    max-width: 100% !important;
    width: 500px !important;
}

.tree-dropdown-menu .ant-dropdown-menu{
    background:transparent !important;
    box-shadow: none !important;
 
}

.tree-dropdown-menu .ant-dropdown-menu-item{

    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.tree-dropdown-menu .ant-dropdown-menu-item h4{
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 500 !important;
    font-size: 1em;
    margin: 0px !important;
}

.tree-menu-dropdown-inactive-select{
    width: 26px;
    height: 26px;
    border: 1px solid rgba(140, 140, 161, 1);
    border-radius: 4px;
}

.tree-menu-dropdown-active-select{
    width: 26px;
    height: 26px;
    border: 1px solid rgba(76, 203, 31, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-mark-container{
    width: 75%;
    height: 75%;
    background: rgba(76, 203, 31, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.popup-menu-dropdown-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: transparent;
    z-index: 1060;
}

.dropdown-menu-title{
    font-size: 1em;
    font-weight: 600;
    line-height: 1.25em;
    color:rgba(0, 0, 0, 1);
}

.menu-search-container{
    width: 100%;
    position: relative;
}

.menu-search-input, .menu-search-input:hover, .menu-search-input:focus{
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1em;
    line-height: 24px;
    border: 1px solid rgba(169, 169, 169, 1);
    outline: none;
    padding-left: 28px;
}

.menu-search-input::placeholder{
    color: rgba(217, 217, 217, 1);
}

.menu-search-icon{
    position: absolute;
    z-index: 1060;
    top: 8px;
    left: 5px;
    width: 1.25em;
    height: 1.25em;
}

.organization-detail-view .ant-modal-body{
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
}

.org-modal-detail{
    width: 100%;
    height: 90vh;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.org-color-container{
    width: 25%;
    min-width: 60px;
    height: 100%;
}

.org-detail-container{
    width: 100%;
    height: 100%;
}

.close-icon-container{
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: end;
}

.org-detail-section{
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 1em;
    align-items: center;
    margin-left: -50px;
}

.org-logo-container{
    border-radius: 4px;
    height: 6.25em;
    width: 6.25em;
    min-height: 6.25em;
    min-width: 6.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.org-logo-container img{
    object-fit: contain;
    width: 80%;
    height: 80%;
}

.modal-main-title{
    font-size: 2em;
    font-weight: 600;
    line-height: 1em;
}

.modal-sub-title{
    font-size: 1.25em;
    font-weight: 400;
    line-height: 0.5em;

}

.popup-employee-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
}

.popup-usersection-overflow-manager{
    width: 100%;
    padding: 1.25em 2em;
    margin-top: 1.25em;
    margin-right: 1em;
    overflow: auto;
    height: calc(80vh - 50px);
}

.popup-usersection-overflow-manager::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.popup-usersection-overflow-manager::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.popup-overflow-manager{
    width: 100%;
    padding: 1.25em 2em;
    margin-top: 1.25em;
    margin-right: 1em;
    overflow: auto;
    height: calc(80vh - 180px);
}

.popup-overflow-manager::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.popup-overflow-manager::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.org-manager-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
}


.manager-detail-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 45%;
    margin: 0.75em;
    min-width: 200px;
}


.manager-logo-container{
    height: 5em;
    width: 5em;
    min-height: 5em;
    min-width: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager-logo-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.org-manager-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.org-manager-info h3{
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.org-manager-info h4{
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0;
}

.org-tree-loader{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(245, 244, 244, 0.957);
    opacity: 0.25;
}

.loader-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tree-mode-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
   justify-content: space-between;
   align-items: center;
   padding: 1em;
   flex-wrap: wrap;
}


@media screen and (max-width:1200px) {
    .org-modal-detail{
        font-size: 14px;
    }
}

@media screen and (max-width:960px) {
    .org-modal-detail{
        font-size: 12px;
    }
}

@media screen and (max-width:600px) {
    .modal-main-title{
        font-size: 1.75em;
    }
    .modal-sub-title{
        font-size: 1.15em;
    }
    .org-modal-detail{
        font-size: 10px;
    }
}

.tree-mode-switch{
    display: flex;
    flex-direction: row;
    padding: 0.25em;
    background: rgba(0, 202, 100, 0.08);
    border: 1px solid rgba(170, 186, 198, 0.2);
    border-radius: 8px;
    gap: 0.25em;
}

.mode-inactive{
    background: transparent; 
    color: rgba(108, 114, 127, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 0.25em 0.5em;
    cursor: pointer;
}

.mode-active{
    background: rgba(0, 202, 100, 1);   
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 0.25em 0.5em;
    border-radius: 8px;
    cursor: pointer;
}

.tree-recenter-btn, .tree-recenter-btn:hover, .tree-recenter-btn:focus {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(13, 214, 113, 1);
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: rgba(0, 202, 100, 1);
    border-radius: 8px;
    padding: 1em 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.org-detail-spacebtw{
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employee-card-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 30%;
    margin: 0.75em;
    min-width: 200px;
}

.userlist-main-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
}

@media screen and (min-width:1800px) {
    .employee-card-container{
        width: 22%;
    }
}

@media screen and (max-width:1000px) {
    .employee-card-container{
        width: 45%;
    }
    .manager-detail-container{
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .employee-card-container{
        width: 100%;
    }
 
}

.legends-container{
    width: .8em;
    height: .8em;
    border-radius: 50%;
}

.legend-label{
    margin: 0px;
    color: rgba(108, 114, 127, 1);
    font-size: .8em;
    white-space: nowrap;
    cursor: default;
}
