.transcription-summary-root{
    font-size: 16px;
}

.tsearch-container{
    position: relative;
    max-width: 200px;
    width: 95%;
}

.tsummary-search{
    width: 100%;
    padding-left: 2em;
    border-radius: .5em;
    margin-bottom: .25em;
}

.tsearch-icon{
    position: absolute;
    left: .25em;
    top: 5px;
    width: 20px;
}

.timg-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
}

.tchara-container{
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 1px solid #4CCB1F;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.tright-aligned-container{
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0.25em;
    padding-right: .5em;
}

.tleft-aligned-container{
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 0.25em;
    padding-right: .5em;
}

.tmsg-container{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: .5em;
    background-color: rgb(229, 228, 228);
    justify-content: space-between;
    padding: .5em;
    border-radius: .5em;
}

.tpro-char{
    margin: 0px !important;
    font-size: 16px;
    font-weight: 500;
}

.tmsg-info{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
}

.tuser-info{
    min-height: 30px;   
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.align-end{
    justify-content: end;
}

.align-start{
    justify-content: start;
}

.tmsg{
    font-size: 0.875em;
    font-weight: 400;
    text-align: left;
    word-break:normal;
}

.tuser-info-label{
    font-size: 0.8125em;
}

.transcript-view-more{
    margin-top: .5em;
    font-size: 1em;
    cursor: pointer;
    text-decoration: underline;
}

.transcript-view-more:hover{
    color: #4CCB1F;
}

.audio-border{
    height: 30px !important;
    border: 2px solid #4CCB1F;
    width: 100%;
    border-radius: 8px;
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.audio-download-icon{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.audio-progress{
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: #c3c3cc;
}

.dummy-selction{
    width: 10px;
    height: 100%;
}

.auido-sound-signal{
    position: absolute;
    right: 0px;
    width: 25px;
    top: -95px;
    cursor: pointer;
}