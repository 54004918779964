

.one-on-one-effectiveness-root{
    font-size: 16px;
    font-family: poppins;
}



@media screen and (max-width:1200px) {
    .one-on-one-effectiveness-root{
      font-size: 14px;
    }
  
  }
  
  
  @media screen and (max-width:960px) {
    .one-on-one-effectiveness-root{
      font-size: 12px;
    }
  
  }
  
  @media screen and (max-width:600px) {
    .one-on-one-effectiveness-root{
      font-size: 11px;
    }
  
    .filter-panel .ant-input-affix-wrapper{
      padding: 0.38em 0.68em !important;
    }
  

  }

  .one-on-one-effectiveness-root{
    position: relative;
    top: 1.5em;
  }


    .feedback-card-suggestions{
      padding-top: 2em;
    }


  .effectiveness-title{
    font-size: 2em;
    font-weight: 700;
    color: #141414;
  }

  .back-icon-one-on-one-effectiveness{
    position: absolute;
    left: 0.84em;
  }

  .filter-panel{
    position: relative;
    top: 4em;

  }

  .effectiveness-feedback-body{
    position: relative;
    top: 5em;
    gap:0.5em;
    margin-bottom: 2em;
  }

   .emp-search-filter .ant-btn:hover, .emp-search-filter .ant-btn:focus{
    color: #000000;
  }

  .feedback-column-content{
   height: auto;
   overflow: auto;
   max-height: 22em;
   width: 97%;
   margin-top: 3em;
   margin-bottom: 1em;
   padding:0em 1em 0em 0.7em;
  }
  
  .feedback-column{
    margin-bottom: 5em;
  }

  .feedback-column-content .ant-card-bordered{
   margin-top: 0em !important;
   /* margin-bottom: 0em !important; */
    border-radius: 0.5em;

  }

  .feedback-column{
    /* margin:auto; */
    margin-top:0em;
    background-color:rgba(217, 217, 217, 0.23);

  }

  .received-header{
    position: relative;
    top: -0.4em;
    max-width: 250px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  .feedback-column-header{
    padding:2em 1.5em 0em 1em;
    font-weight:500;
    font-size: 1.2em;
  }


  .feedback-column-content::-webkit-scrollbar {
    height: 0em;
    width: 0.47em;
    /* padding-left: ; */
  }
  
  .feedback-column-content::-webkit-scrollbar-track {
    display: none;
  }
  
  .feedback-column-content::-webkit-scrollbar-thumb {
    border-radius: 0.6em;
    background: #D9D9D9;
    ;
  }
  
  .graph-container-effective{
    height:21.12em;
    /* width: 26.12em; */
  }


  .filter-panel .ant-input-affix-wrapper{
    padding: 0.43em 0.68em;
    border-radius: 0.5em;
  }

  .filter-panel .anticon svg{
    font-size: 1.3em;
    color: #8C8CA1;
  }

  .effective-filter-all-active{
    color:#4CCB1F;
    font-weight:500;
    font-size: 1em;
    border-bottom: 0.1em solid #4CCB1F;
    padding-bottom:0.2em;
  }


  .effective-filter-all-non-active{
    color: #00000080;
    font-weight:500;
    font-size: 1em;
    border-bottom: 0.1em solid #00000080;
    padding-bottom:0.2em;
  }

  .effective-emp-filter-all-active .ant-btn{
     border-color: #4CCB1F;
     width:15.6em;
  }


  .effective-emp-filter-all-non-active .ant-btn{
    border-color: #d9d9d9;
    width:15.6em;
 }

 .modal-user-name{
  font-size: 1.1em;
  font-weight: 500;
  margin-left: 0.3em;
  color: #000000;
 }

 .modal-feedback-date{
  font-size: 1.1em;
  color: #009AF1;
 }

 .modal-section-header{
  font-size: 1.1em;
  font-weight: 500;
  line-height: 4em;
  color: #000000;
 }



 .modal-feedback-header-for-user{
  width: 75%;
 }

 .feedback-details-modal .ant-modal-body{
  overflow: scroll;
  max-height: 28.12em;

 }

 .star-rating-effectivness-feedback{
  width: 2.5em;
  margin-right: 1em;
 }

 .feedback-note{
  font-size: 1em;
  color: #000000;
  font-weight: 500;
  padding-bottom: 2em;
 }
.rating-section:hover{
  background: rgba(76, 203, 31, 0.05) !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 8px !important;
}
.star-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.875em;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);

}
.rating-section:hover .star-title{
  color: #000000 !important;
}
.message-inactive{
  display: block;
}
.message-active{
  display: none;
}
.rating-section:hover .message-active{
  display: block;
}
.rating-section:hover .message-inactive{
  display: none;
}
 @media screen and (max-width:900px) {

  .received-header{
    font-size: 0.8em;
    top: -0.8em;
  }

 }


 @media screen and (max-width:800px) {
  
  .feedback-column, .graph-container-effective{
    margin-bottom: 1em;

    margin-top: 1em;
  }

  .emp-search-filter{
    line-height: 3em;
  }


  .effective-filter-all-active,.effective-filter-all-non-active{
    font-size: 1.3em;
  }

  .graph-container-effective{
    width: 100%;
  }

  .feedback-column{
    margin-bottom: 5em;
  }

 



 }

 @media screen and (max-width:500px) {

  .modal-feedback-header-for-user{
    margin-bottom: 1em;
    width: 100%;
  }

 }

 @media screen and (max-width:380px)  {

  .effectiveness-title{
    font-size: 1.3em;
  }

 }


 @media screen and (min-width:381px)  {

  .emp-search-filter{
    margin-left: 2em;
  }
  
 }

 @media screen and (max-width:450px)  {

  .emp-search-filter{
    line-height: 3em;
    margin-bottom: 2em;
  }

  .back-icon-one-on-one-effectiveness{
    /* left: 1em; */
  }

  .feedback-title-search-box{
     width: 80%;
  }

 }

 @media screen and (max-width:320px)  {

  .back-icon-one-on-one-effectiveness{
    left: 0em;
  }
  
 }


 @media screen and (max-width:600px) and (orientation:landscape) {

  .emp-search-filter{
    line-height: 3em;
    /* margin-bottom: 2em; */
  }

 }

 @media screen and (max-width:500px)  {

  .emp-search-filter{
    margin-bottom: 2em;
  }

 }

 @media screen and (min-width:500px) and  (max-width:700px) {
  
  .feedback-title-search-box{
     width: 45%;
  }

  .effective-emp-filter-all-non-active .ant-btn{
    width: 12em;
  }


 }


 @media screen and (min-height:500px) {
  
  .feedback-column-content{
    max-height: 28em;
  }

 }



 @media screen and (min-height:700px) {
  
  .feedback-column-content{
    max-height: 28em;
  }

 }


 @media screen and (min-height:890px) {
  
  .feedback-column-content{
    max-height: 40em;
  }

 }

 @media screen and (min-width:2000px) {
  
  .one-on-one-effectiveness-root{
    top: 5em;
  }

 }




 .feedback-details-modal .ant-modal-body::-webkit-scrollbar {
  height: 0em;
  width: 0.47em;
  display: none;
}
.star-title-past-job{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.875em;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #000000;
}
@media (max-width:1280px) {
  .past-title-section{
    width: 45% !important;
  }
}
@media (max-width:1060px) {
  .past-title-section{
    width: 30% !important;
  }
}
@media (max-width:890px) {
  .past-title-section{
    width: 100% !important;
  }
  .past-container{
    flex-direction: column !important;
  }
}
@media (max-width:600px) {
  .past-title-section{
    width: 100% !important;
  }
}