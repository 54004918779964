.bars-home-root {
    font-size: 16px;
    padding-right: 3.5em;
}


.bars-header {
    font-size: 2em;
    color: #434343;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    position: relative;
    right: 1em;
}

.bars-heading-back-arrow-container {
    width: 100%;
    margin-bottom: 1.87em;
}

.bars-add {
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0em 0.125em 0em 0em rgba(0, 0, 0, 0.04);
    color: #FFF;
    text-align: center;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 500;
    display: flex;
    width: 7.5em;
    height: 2.8em;
    padding: 0.25em 0.93em;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    flex-shrink: 0;
    margin-bottom: 1.5em;

}

.bars-add span {
    padding-right: 0.62em;
}

.bars-filter-input {
    width: 22.62em;
    height: 2.8em;
    flex-shrink: 0;
    border-radius: 0.5em 0em 0em 0.5em;
    border: 0.062em solid #A9A9A9;
    border-right: none !important;
    padding: 0.4em 0.4em 0.4em 0.4em;
}

.bars-filter-input.ant-input-affix-wrapper>input.ant-input {
    padding-left: 0.6em;
}

.bars-filter-container {
    border-radius: 0em 0.5em 0.5em 0em;
    border: 0.062em solid #A9A9A9;
    background: #FFF;
    gap: 0.62em;
    height: 2.45em;
    flex-shrink: 0;
    
}

.bars-selected-filter {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    padding-left: 1.5em;
}

.bars-filter-drop-down-icon{
    padding-right: 1.5em;
}

.filter-overlay-container {
    background: #ECF1F4;
    padding: 0em;
    border-radius: 0.5em;
}

.bars-filter-element {
    color: #000;
    padding: 0.6em 1em;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.37em;
    /* 110% */
}

.bars-filter-element-action-overlay {
    color: #000;
    padding: 0em !important;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    width: 10em;
    justify-content: center;
    font-weight: 400;
    line-height: 1.37em;
}

.bars-filter-element-incomplete {
    color: gray;
}

.bars-table {
    padding: 2.5em 3.5em 1em 3.5em;
    border-radius: 1em;
    border: 0.0625em solid #ECF1F4;
    background: #F4F9FD;
    margin-top: -3.125em;
}

.bars-table .ant-table-thead>tr>th {
    text-align: center;
    z-index: 100;
}


.bars-table .ant-table-container table>thead>tr:first-child th:last-child {
    padding-left: 2em;
}

.bars-functionality-panel {
    margin-bottom: 1.5em;
    flex-direction: column;
}

.bars-filter-input:hover,
.bars-filter-input:focus {
    border: 0.062em solid #d9d9d9;
}




.bars-table table {
    border-spacing: 0em 1em;
}

.bars-table .ant-table {
    background: #F4F9FD;
}

.bars-table tr {
    background-color: #FFF;
}

.bars-table .ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #FFF;
}

.bars-table .ant-table-thead>tr>th {
    background: none;
}

.bars-table thead {
    /* need to manage background gradient */
    /* background:  linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%); */
}

.bars-table .ant-table-cell:first-child {
    padding: 10px 0px 10px 12px !important;
}


.bars-table .ant-table-cell:nth-child(3) div {
    display: flex;
    justify-content: center;
}

.bars-incomplete-action {
    color: #0098F0;
    font-size: 0.9em;
    font-weight: 400;
    text-decoration-line: underline;
}

.bars-table tbody tr td {
    height: 4.5em;


}

.bars-table .ant-table-tbody>tr>td {
    border-top: 1px solid #AABAC6;
    border-bottom: 1px solid #AABAC6;
}

.bars-table tbody tr td:first-child {
    border-left: 1px solid #AABAC6;
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em !important;

}

.bars-table tbody tr td:last-child {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-right: 1px solid #AABAC6;
}

.bars-status-container-incomplete div,
.bars-status-container-complete div {
    padding: 10px 0px 10px 10px;
    border-radius: 8px;
}

.bars-status-container-incomplete,
.bars-status-container-complete {
    border-radius: 0.5em;
    width: 8em;
}

.bars-status-container-complete {
    width: 9em;
}

.bars-status-container-incomplete {
    background: rgba(225, 63, 63, 0.10);
}

.bars-status-container-complete {
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(76, 203, 31, 0.10);
}

.bars-table .ant-btn:hover,
.bars-table .ant-btn:focus {
    border: 1px solid #4CCB1F;
    color: #1F1F1F;
}

.bars-table thead tr th {
    height: 3.2em;
    font-size: 1.4em;
    font-weight: 600;
}

.bars-table thead tr th:first-child {
    border-top-left-radius: 0.5em !important;
    border-bottom-left-radius: 0.5em;
}

.bars-table thead tr th:last-child {
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em;
}

.bars-action-element {
    gap: 0.8em;
    padding-bottom: 0.8em;
}

.bars-action-element div {
    font-weight: 500;
}

.bars-action-element img {
    width: 1.5em;
}

.bars-action-element:last-child {
    padding-bottom: 0em;
}

.bars-action-element-action-overlay {
    gap: 0.8em;
    padding-bottom: 0.8em;
}

.bars-action-element-action-overlay div {
    font-weight: 500;
}

.bars-action-element-action-overlay img {
    width: 1.5em;
}

.bars-action-element-action-overlay:last-child {
    padding-bottom: 0em;
}

.bars-action-element-action-overlay:hover {
    background-color: #4CCB1F;
    color: #fff;
}




.bars-add:hover,
.bars-add:focus,
.bars-add:active {
    background: #4CCB1F;
    color: #FFFFFF;
    border-color: #4CCB1F;
}


.filter-overlay-container .ant-dropdown-menu-item-active {
    background: #ECF1F4 !important;
}

.bars-search-icon {
    position: absolute;
    left: 1.2em;
    width: 1.25em;
    top: 0.62em;
}

.bars-expanded-table {
    position: relative;
    left: 2em;
}

.bars-expanded-table thead tr th {
    font-size: 1.2em;
}

.bars-expanded-levels-container {
    display: flex;
    justify-content: center;
    position: relative;
    left: 10px;
}

.bars-expanded-table .ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #F4F9FD;
}

.bars-expanded-table tr {
    background-color: #F4F9FD;
}

.bars-expanded-table .ant-table-tbody>tr>td {
    border-bottom: none;
    border-top: none;
}

.bars-expanded-table tbody tr td:first-child {
    border-left: none;
    border: none;
}

.bars-expanded-table tbody tr td:last-child {
    border-right: none;
    border: none;
}


.bars-popup-message {
    text-align: center;
    font-family: poppins;
    font-size: 1.15em;
    font-weight: 500;
    margin-bottom: 1em;
}

.bars-confimation-action {
    background: #4CCB1F;
    width: 5.81em;
    font-size: 1.15em;
    color: #FFF;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #4CCB1F;
}

.bars-cancel-action {
    background: #fff;
    width: 5.81em;
    font-size: 1.15em;
    color: #000;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #000;
}

.bars-confimation-action:hover,
.bars-confimation-action:focus {
    background: #4CCB1F;
    color: #FFF;
    border-color: #4CCB1F;
}

.bars-cancel-action:hover,
.bars-cancel-action:focus {
    background: #fff;
    color: #000;
    border-color: #000;
}


.bars-submit-action {
    background: #4CCB1F;
    width: 7.61em;
    font-size: 1.15em;
    color: #FFF;
    font-weight: 500;
    height: 3em;
    border-radius: 0.5em;
    margin-top: 2em;
}

.bars-submit-action:hover,
.bars-submit-action:focus {
    background: #4CCB1F;
    color: #FFF;
    border-color: #4CCB1F;

}


.bars-popup-sub-message {
    text-align: center;
    font-family: poppins;
    font-size: 0.9em;
    margin-bottom: 1.5em;
}

.bars-confirm-action-delete {
    background: #E86161;
    width: 5.81em;
    font-size: 1.15em;
    color: #fff;
    font-weight: 500;
    height: 2.5em;
    border-radius: 0.5em;
    border: 1px solid #E86161;
}

.bars-confirm-action-delete:hover,
.bars-confirm-action-delete:focus {
    background: #E86161;
    color: #fff;
    border: 1px solid #E86161;
}

.bars-action-icon {
    width: 1.8em;
}

.permission-check-bars {
    width: 100%;
}

.bars-filter-element-incomplete:hover,
.bars-filter-element:hover {
    background-color: #4CCB1F !important;
    color: #fff;
}

.bars-filter-element:hover .bar-action-icon {
    filter: brightness(0) invert(1);
}


.action-overlay-bar .ant-dropdown-menu-item {
    padding: 0em !important;
}


.bar-action-icon {
    width: 1.4em;
}

.view-more-bars {
    width: 100%;
    margin-top: 1em;
    font-size: 1.3em;
    font-weight: 500;

}

.bars-selected-level {
    background-color: #4CCB1F;
    color: #fff;
}

.bars-filter-input:hover {
    border-color: #A9A9A9 !important;
    box-shadow: none;
}


.bars-table-gradient {
    width: 91.2%;
    position: relative;
    height: 3.97em;
    background: var(--Dashboard-2, linear-gradient(270deg, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%));
    z-index: 100;
    top: 4.19em;
    margin: auto;
    border-radius: 0.5em;
}

.bars-defining-hover-effect{
    font-size: 1em;
    font-family: poppins;
    background: #0E0E2C;
    border-radius: 0.8em;

}


.bars-defining-hover-effect .ant-tooltip-inner{
    border-radius: 0.8em;
}

.bars-table-attribute{
white-space: nowrap;
overflow: hidden;
max-width: 16em;
text-overflow: ellipsis;
}