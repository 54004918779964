
  .image-container-bg{
    background: url('../../assets/login-bg.jpg') !important;
    background-repeat: no-repeat !important;
    background-size:100% 100% !important;
    background-position: center !important;
  }
  .login-logo{
    display: none !important;
  }

  .custom-form .ant-input{
    padding-left: 19px;
  }

  @media (max-width:1380px) {
    .image-container-bg{
      background: url('../../assets/login-bg.jpg') !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      background-position: center !important;
    }
    .login-logo{
      display: none !important;
    }
  }
  @media (max-width:1240px) {
    .image-container-bg{
      background: url('../../assets/login-bg-medium.jpg') !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      background-position: center !important;
    }
    .login-logo{
      display: none !important;
    }
  }
  @media screen and (max-width:1400px) and (min-height:840px) {
    .image-container-bg{
      background: url('../../assets/login-bg-only.png') !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      /* background-size: cover; */
      background-position: center !important;
    }
    .login-logo{
      display: block !important;
    }
    .login-logo{
      height: 130px !important;
    }
  }
  @media (max-width:1050px) {
    .image-container-bg{
      background: url('../../assets/login-bg-only.png') !important;
      background-repeat: no-repeat !important;
      background-size:100% 100% !important;
      /* background-size: cover; */
      background-position: center !important;
    }
    .login-logo{
      display: block !important;
      height: 100px !important;
    }
  }
  .faq-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #D3F261;
    position: absolute;
    right: 50px;
    top: 60px;
    cursor: pointer;
  }
  .login-sub-head{
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.005em;
  
  /* Gray 7 */
  
  color: #BFBFBF;
  }
  .custom-form .ant-form-item-label > label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #434343;
    padding-left: 14px;
  }
  .ant-form-item-explain.ant-form-item-explain-error{
    padding-left: 14px;
    padding-top: 6px;
    font-family: 'Poppins';
  }
  .ant-form-item-explain{
    padding-left: 14px;
    color:#ff4d4f;
    padding-top: 6px;
    font-family: 'Poppins';
  }
  .custom-form .ant-input{
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 119px;
    font-size: 18px;
    padding-left: 19px;
  }
  .custom-form .ant-input-affix-wrapper{
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 80px;
    font-size: 18px;
    padding: 0px 10px 0px 0px;
  }
  .custom-form .ant-input-affix-wrapper .ant-input{
    height: 62px;
    background: #FFFFFF;
    border: none;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 14px;
    padding-left: 19px;
  }
  .custom-form .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon{
    display: none;
  }
  .custom-form .ant-form label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #434343;
  }
  .mGSDE{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4CCB1F !important;
  }
  
  .login-btn{
    width:100%;
    height: 81px;
    background: #4CCB1F;
    border-radius: 216px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: -0.025em;
    color: #FFFFFF;
    border:1px solid #4CCB1F;
  }
  .login-btn:hover{
    border: 2px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
    opacity: .8;
  }
  .login-btn:focus{
    border: 2px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .find-btn{
    height: 53px;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    border-radius: 5.01099px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20.044px;
    line-height: 30px;
    color: #FFFFFF;
    border:1px solid #4CCB1F;
    margin-top: 0px;
  }

  @media screen and (max-width:420px) {
    
    .find-btn{
      margin-bottom: 10px;

    }
  }

  .find-btn:hover{
    border: 1px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
    opacity: .8;
  }
  .find-btn:focus{
    border: 1px solid #4CCB1F !important;
    color: #FFFFFF!important;
    background-color: #4CCB1F !important;
  }
  .account-form{
    margin-top: 20px;
  }
  .go-back-btn{
    height: 53px;
    background: #fff !important;
    border: 1px solid #8C8C8C !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19.2088px;
    line-height: 29px;
    letter-spacing: 0.005em;
    color: #8C8C8C !important;
    border-radius: 5.01099px;
    margin-top: 10px;
  }
  .go-back-btn:hover{
    border: 1px solid #8C8C8C !important;
    color: #8C8C8C!important;
    background-color: #fff !important;
    opacity: .8;
  }
  .go-back-btn:focus{
    border: 1px solid #8C8C8C !important;
    color: #8C8C8C!important;
    background-color: #fff !important;
  }
  .login-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 10px 100px 0px 100px;
    background-color: #f5f5f5;
  }
  .open-logo{
    display: none;
}

@media (max-width:420px) {

  .login-sub-head{   
    text-align: center;
  }

}

  @media (max-width:1700px) {
    .login-logo{
      height: 100px;
    }
    .login-sub-head{
      font-size: 14px;
    }
    .custom-form .ant-form-item-label > label{
      font-size: 14px;
    }
    .custom-form .ant-input{
      height: 43px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 80px;
      font-size: 14px;
      padding-left: 19px;
    }
    .custom-form .ant-input-affix-wrapper{
      height: 43px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 80px;
      font-size: 14px;
    }
    .custom-form .ant-input-affix-wrapper .ant-input{
      height: 40px;
      border: none;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 14px;
      padding-left: 19px;
    }
    .mGSDE{
      font-size: 14px;
    }
    .custom-form .ant-form label {
      font-size: 14px;
    }
    .login-btn{
      height: 61px;
      font-size: 22px;
      border-radius: 160px;
    }
    .find-btn{
        width: 100px !important;
        height:42px;
        font-size: 16px;
    }
    .go-back-btn{
        width: 100px !important;
        height:42px;
        font-size: 14px;
    }
  }
  
  .form-mobile-screen{
    display: none !important;
  }
  
  .custom-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color:#4CCB1F;
  border-color: #4CCB1F;
  }
  
.email-sent-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #1F1F1F;
    padding: 20px 0px 0px 0px;
}
  
  @media (min-width:1700px) {
    .login-popup-head{
        font-size: 24px;
      }
    .email-sent-text{
        font-size: 20px;
    }
  }
  @media (max-width:1150px) {
      .login-bg-bottom {
        position: absolute;
        width: 165% !important;
        z-index: 10;
        left: -237px;
        right: 0;
        top: 58%;
    }
    .login-container{
      padding: 10px 80px 0px 80px;
    }
    .login-btn{
      height: 55px;
      font-size: 22px;
      border-radius: 160px;
    }
  }
  @media (max-width:1010px) {
    .login-bg-bottom {
      position: absolute;
      width: 165% !important;
      z-index: 10;
      left: -207px;
      right: 0;
      top: 64%;
  }
}
  @media (max-width:960px) {
    .login-logo{
      height: 90px;
    }
    .login-container{
      padding: 10px 60px 0px 60px;
    }
    .login-btn{
      height: 50px;
      font-size: 22px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 165% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  @media (max-width:820px) {
    .login-logo{
      height: 90px;
    }
    .login-container{
      padding: 10px 40px 0px 40px;
    }
    .login-btn{
      height: 50px;
      font-size: 20px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 185% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  @media (max-width:740px) {
    .login-logo{
      height: 80px;
    }
    .login-container{
      padding: 10px 20px 0px 20px;
    }
    .login-btn{
      height: 50px;
      font-size: 20px;
      border-radius: 160px;
    }
    .login-bg-bottom {
      position: absolute;
      width: 200% !important;
      z-index: 10;
      left: -187px;
      right: 0;
      top: 64%;
  }
  }
  
  
  @media (max-width:600px) {
    .form-mobile-screen{
      display: flex !important;
    }
    .web-login{
      display: none;
    }
    .login-container{
      background: #fff !important;
      overflow: auto;
      /* margin-top: 20px; */
    }
    .login-btn{
      max-width: 100%;
      width: 100%;
    }
    .login-popup-head{
        font-size: 20px;
    }
    .email-sent-text{
        font-size: 14px;
    }
    .open-logo{
        width: 220px;
        display: block;
    }

  

  }



  @media (max-width:420px) and (orientation:portrait) {

    .login-sub-head{
      width: 63%;
      font-size: 16px;
    }

  }

  @media (max-width:340px) {
    .login-popup-head{
        font-size: 18px !important;
    }
    .email-sent-text{
        font-size: 14px;
    }
    .open-logo{
        width: 140px;
        display: block;
    }
    .mobile-logo{
      margin-top: 40px !important;
      width: 170px;
    }

    .login-sub-head{
      width: 70%;
      font-size: 14px;
    }

  }
  .login-popup-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #000000;
    text-align: center;
  }

  