.top-receiver {
    background: linear-gradient(to right, #E0F2DB, rgba(224, 242, 219, 0));
    color: #333;
    border-radius: 5px;
    padding :5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-weight:bold ;
    margin-bottom: 20px;
}
.top-sender {
    background: linear-gradient(to right, #E0F2DB, rgba(224, 242, 219, 0));
    color: #333;
    border-radius: 5px;
    padding :5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-weight:bold ;
    margin-bottom: 20px;
}
.engagement-board-avatar{
    width: 45px;
    height: 45px;
    font-size: 2em;
    font-weight: 600;
    background-color: #AABAC6 !important;
    display: flex !important;
    align-items: center !important;
}
.engagement-board-title{
    margin-bottom: -5px;
    font-weight: 600 !important;
    cursor: pointer;
}