.one-one-filter-menu-feedback .ant-dropdown-menu-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #141414;
    padding-bottom: 10px;
}

.one-one-filter-menu-feedback .ant-dropdown-menu-item {
    font-size: 14px;
}

/* filter dropdown button css */
.feedback-input-filter-one-on-one {
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    height: 46px !important;
    width: 126px !important;
    border-right: none;
}

/* filter input field css */
.feedback-search-input-filter {
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 35px;
    width: 100%;
    height: 46px;
    font-size: 16px;
    border-right: none;
}

/* search selector button */

.feedback-one-one-filter-menu .ant-dropdown-menu-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #BBBBBB;
    padding-bottom: 10px;
}

.feedback-one-one-filter-menu .ant-dropdown-menu-item-active {
    color: #141414;
}

@media (max-width:1600px) {

    .feedback-one-one-filter-menu .ant-dropdown-menu-item {
        font-size: 14px;
    }

}