@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  /* IE */
  src: local('Poppins', sans-serif), url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap') format("truetype");
  /*non-IE*/
}

@media only screen and (max-width: 480px) {
  h1 {
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    font-size: 12px;
    font-weight: bold;
  }
}

.ant-form-item-label>label {
  height: auto;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
  border-width: 3px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
}

.ant-checkbox-wrapper.ant-transfer-list-checkbox {
  display: none;
}

/* .ant-collapse-item-active > .ant-collapse-header {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
  box-shadow: 0px 4px 10px rgb(32 32 32 / 10%);
  margin-bottom: 10px;
} */
/* .ant-collapse-header:hover {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
} */

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  background: #f5f5f5;
}

.ant-checkbox-wrapper .ant-transfer-list-checkbox {
  display: none;
}

.ant-modal-content {
  border-radius: 10px;
}

.custom-modal .ant-modal-content {
  border-radius: 20px;
  width: 100%
}

.custom-modal-comp .ant-modal-content {
  border-radius: 20px;
  width: 100%;
  max-width: 640px;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-table-cell {
  padding: 10px 16px !important;
}

.ant-table-cell .ant-table-wrapper {
  max-width: 95% !important;
}

.ant-comment-content-author {
  justify-content: space-between !important;
}

/* .css-fycli8 > .css-oalq6z > .css-n4cq1w::before {
  height: unset;
} */

/* Spinner */
.ant-spin-nested-loading>div>.ant-spin-lg>.ant-spin-dot {
  position: fixed !important;
}

/* Card */
.ant-card-bordered .ant-card-cover {
  margin: 0;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  border-radius: 0;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-line {
  line-height: 0;
}

.ant-progress-inner {
  border-radius: 0;
}

/* ProgressCounterBtn */

.base-timer {
  position: relative;
  width: 30px;
  height: 30px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ant-form-item-required {
  display: -webkit-box !important;
}

.visible-table-border-color {
  border-right: 1px solid #e2e0e0 !important;
  border-bottom: 1px solid #e2e0e0 !important;
}

.ant-dropdown {
  z-index: 1000 ;
}

@-o-keyframes fadeIt {

  /* 0%   { background-color: transparent; } */
  50% {
    background-color: #d1e750;
  }

  /* 100% { background-color: #FFFFFF; } */
}

@keyframes fadeIt {

  /* 0%   { background-color: #FFFFFF; } */
  50% {
    background-color: #d1e750;
  }

  /* 100% { background-color: #FFFFFF; } */
}

.backgroundAnimated {
  background-image: none !important;
  -o-animation: fadeIt 5s ease-in-out;
  animation: fadeIt 5s ease-in-out;
}

.ant-col-offset-1 {
  margin-left: 0px !important;
}

.custom_select_drop {
  width: 100%;
}

.custom_select_drop .ant-col-24 {
  max-width: 100% !important;
  flex: none !important;
  width: 300px !important;

}

.skillTitle:hover {
  color: #d1e750;
  text-decoration: underline;
}

.page_heading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 45px;
  line-height: 70px;
  margin: auto;
  text-align: center;
  letter-spacing: -0.8px;
}

.page_title {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 82px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #404040;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.8px;
  color: rgba(13, 35, 68, 0.8);
}

@media only screen and (max-width: 900px) {
  .search-input-filter {
    width: 250px !important;
  }

  .page_title {
    font-weight: 500;
    font-size: 34px;
    line-height: 48px;
  }

  .page_heading {
    font-weight: 500;
    font-size: 33px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .detail-page-view {
    flex-direction: column !important;
  }

  .page_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
  }

  .subtitle {
    font-size: 14px;
  }

  .page_heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
}

.engagement_btn {
  width: 290px;
  height: 50px;
  background: #B0CB1F;
  border-radius: 64px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  position: relative;
}

.engagement_btn:hover {
  background: #B0CB1F;
  color: #FFFFFF;
}

.engagement_btn .plus {
  width: 50px;
  height: 48px;
  position: absolute;
  background: #92AA11;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  right: 0px;
}

.search_box {
  -moz-box-shadow: 0px 1px 28px 8px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 1px 28px 8px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 1px 28px 8px rgba(0, 0, 0, 0.07);
  padding: 5px 50px 5px 50px;
  font-size: 18px;
  width: 600px;
  max-width: 100%;
  height: 50px;
  border: 1px solid #CACACA;
  border-radius: 70px;
}

.search-container {
  position: relative;
  margin-top: 35px;
  width: 600px;
  max-width: 80%;
}

.search_image {
  position: absolute;
  right: 20px;
  top: 12px;
}

.arrow_btn {
  width: 27px;
  height: 32px;
  background: #B0CB1F;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.arrow_label {
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #2E2E2E;
}

.calender_name {
  margin: 0px 30px;
  width: 250px;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.37);
  -moz-box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
  -webkit-box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
  box-shadow: 0px 4px 50px rgba(78, 77, 77, 0.34);
  border-radius: 148px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 5px;
}

.next_pre_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 30px;
}

.calender_icon {
  width: 20px;
  height: 20px;
}

.grow_survey .ant-col-offset-2 {
  margin: 0px !important;
}

.action_item_section {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.action_data {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.action_data .inactive {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #B0CB1F;
}

.action_data .active {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #B0CB1F;
  background-color: #B0CB1F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back_section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  align-content: center;
}

.custom_new_table .ant-table {
  background: transparent;
}

.job-family-title {
  width: 100%;
  margin: auto;
  word-wrap: break-word;
  text-align: center;
}


.custom_link div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
}

li .navTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

li .navTitle:hover {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  /* text-decoration: underline !important; */
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item li {
  border-bottom: 2px solid transparent !important;
}

.navTitle-selected {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item li div {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 32px; */
  text-align: left;
  color: #000000 !important;
}

.custom-menu-item li div:hover {
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item-selected {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 32px; */
  text-align: left;
  color: #000000;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.custom-menu-item {
  border: none !important;
}

.custom-selected {
  color: #000000 !important;
  /* text-decoration:2px underline #B0CB1F!important; */
  /* text-underline-offset: 5px; */
}

.ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  color: #000000 !important;
}

.add-btn {
  font-family: 'Poppins';
  background: #4CCB1F;
  border: 1px solid #4CCB1F;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 5px 15px;
  cursor: pointer;
  height: 50px;
  width: 180px;
}

.add-btn:hover {
  border: 2px solid #4CCB1F !important;
  color: #FFFFFF !important;
  background-color: #4CCB1F !important;
}

.add-btn:focus {
  border: 2px solid #4CCB1F !important;
  color: #FFFFFF !important;
  background-color: #4CCB1F !important;
}

.import-btn {
  font-family: 'Poppins';
  background: #FFF;
  border: 2px dashed #4CCB1F;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 22px;
  text-align: center;
  color: #4CCB1F;
  padding: 10px 15px;
  cursor: pointer;
}

.import-btn:hover {
  border: 2px dashed #4CCB1F !important;
  color: #4CCB1F !important;
  background-color: #fff !important;
}

.import-btn:focus {
  border: 2px dashed #4CCB1F !important;
  color: #4CCB1F !important;
  background-color: #fff !important;
}

/* .import-btn :hover{
  border: 2px solid #4CCB1F;
  color: #4CCB1F;
} */
.page-sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  color: #262626;
}

.search-input {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-radius: 8px;
  width: 300px;
  height: 38px;
  padding-left: 35px;
}

.search-input-filter {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: none;
  border-top-right-radius: none;
  padding-left: 35px;
  width: 350px;
  height: 46px;
  font-size: 16px;
  border-right: none;
}

.search-input-bars {
  width: 330px !important;
}

.input-filter {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 46px !important;
}

.input-filter-new {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: none;
  border-top-right-radius: none;
  height: 46px !important;
  border-right: none;
}

.input-filter-new:hover {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter-new:focus {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter:hover {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.input-filter:focus {
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  color: "#000000"
}

.search-icon {
  position: "absolute";
  left: 0;
}

.go-back-btn {
  font-family: 'poppins';
  padding: 10px 15px !important;
  padding: 10px 15px !important;
  font-style: normal;
  height: 44px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #8C8C8C;
  border: 1px solid #8C8C8C;
  cursor: pointer;
  border-radius: 8px;
  width: 140px !important;
  white-space: nowrap;
  background-color: #fff;
  margin-top: 0px !important;
  background-color: #fff;
  white-space: nowrap;
}

.delete-btn {
  font-family: 'poppins';
  background: #E86161;
  border: 1px solid #E86161;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 15px !important;
  height: 45px !important;
  width: 160px
}


@media screen and (max-width:380px) {

  .delete-btn {
    margin-top: 10px;
  }

}

.calender_name_bars {
  margin: 0px 30px;
  width: fit-content;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.37);
  box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: -6px 2px 65px 12px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.competency-bg {
  background: url('./modules/assets/competency-bg.svg');
}

.formLabel label {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
}

.action_link {
  font-family: 'Poppins';
  color: #0052F1;
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.shadow-container {
  background: #F0F0F0;
  width: 100%;
  border: 2px solid #D9D9D9;
  box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 14px 2px 50px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 40px 0px;
}

.shadow-container label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #262626;
}

.conatiner-search {
  background: #F8FAFC;
  border: 1px solid #BFBFBF;
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13); */
  border-radius: 4px;
  margin-top: 20px;
  height: 40px;
  /* max-width:200px; */
  margin-left: 0px !important;
}

.blue-radio {
  background: #89dbf9;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px;
  border: 2px solid #009AF1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-radio {
  background: #C7FDD9;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px;
  border: 2px solid #4CCB1F;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-div {
  margin-top: 0px !important;
}

.blue-radio div {
  background: #009AF1;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50px;
}

.green-radio div {
  background: #4CCB1F;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50px;
}

.know-more-card {
  background: rgba(217, 217, 217, 0.11);
  box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

.know-more-card-bars {
  background: rgba(217, 217, 217, 0.11);
  box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 4px 89px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 1.5em;
  width: min-content;
}

.bootom-div {
  background: #7CB305;
  height: 90px;
  left: 150px;
  right: 150px;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  padding-top: 20px
}

.dashboard-btn-bottom {
  height: 56px;
  background: #FFFFFF;
  border-radius: 8px;
  align-items: center;
  padding: 10px 24px;
  border: 2px solid #7CB305;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #7CB305;
}

.dashboard-btn-bottom:hover,
.dashboard-btn-bottom:focus {
  border: 2px solid #7CB305;
  color: #7CB305;
  background: #fff;
}

.go-back-btn-bottom {
  height: 56px;
  background: #7CB305;
  border-radius: 8px;
  align-items: center;
  padding: 10px 24px;
  border: 2px solid #FFF;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #FFF;
}

.go-back-btn-bottom:hover,
.go-back-btn-bottom:focus {
  border: 2px solid #7CB305;
  color: #fff;
  background: #7CB305;
}

.title-container {
  font-family: 'Poppins';
  background: #F0F0F0;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  -webkit-animation: pulse 1s infinite;
  -moz-animation: pulse 1s infinite;
  -o-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 30px; */
}

.radio-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
}

.add-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 57px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #2E2E2E;
}

.text-filed .test-input {
  height: 38px;
  background: #F8FAFC;
  box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 4px 142px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  width: 100%
}

.custom-ckeck .ant-checkbox .ant-checkbox-inner {
  height: 25px;
  width: 25px;
  border: 2px solid #4CCB1F;
  border-radius: 1.5px;
  font-size: 20px;
}

.custom-ckeck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4CCB1F;
  border-color: #4CCB1F;
}

.custom-ckeck .ant-checkbox-inner::after {
  width: 10px;
  height: 15px;
}

.section-blue {
  height: 40px !important;
  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #009AF1 !important;
  color: #009AF1 !important;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}

.active-blue {
  color: #fff !important;
  background-color: #009AF1 !important;
  box-shadow: none;
  font-size: 13px;
}

.section-green {
  height: 40px !important;
  border-radius: 0px 20px 20px 0px !important;
  border: 1px solid #4CCB1F !important;
  color: #4CCB1F !important;
  border-left: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}

.active-green {
  color: #fff !important;
  background-color: #4CCB1F !important;
  box-shadow: none;
  font-size: 13px;
}

.save-btn {
  font-family: 'Poppins';
  background: #4CCB1F;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: 2px solid #4CCB1F;
  width: 150px;
  height: 50px;
  margin: 10px;
}

.save-btn:hover,
.save-btn:focus {
  color: #fff !important;
  background: #4CCB1F !important;
  border: 2px solid #4CCB1F !important;
}

.cancel-btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #595959;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  border: 1px solid #595959;
  background-color: #fff;
  width: 150px;
  height: 50px;
  margin: 10px;
}

.cancel-btn:hover,
.cancel-btn:focus {
  color: #595959 !important;
  background: #fff !important;
  border: 1px solid #595959 !important;
}

.table .ant-table-thead {
  background: linear-gradient(to right, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
  background: -webkit-linear-gradient(right, rgba(37, 234, 104, 0.41) 18.24%, rgba(2, 215, 164, 0.41) 87.83%);
  background-attachment: fixed;


}

.table .ant-table-thead .ant-table-cell {
  background: transparent !important;
  border: none;
}

.compe_card:hover {
  transform: scale(1.05);
}

.compe_modal_head {
  font-family: 'poppins';
  top: 53px;
  background: rgba(255, 255, 255, 0.37);
  box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 4px 110px rgba(0, 0, 0, 0.16);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #414141;
  font-family: 'Poppins';
  padding: 10px 30px;
  border-radius: 50px;
}

.add-plus {
  font-family: 'Poppins';
  border: 1px solid #4CCB1F;
  color: #4CCB1F;
  cursor: pointer;
  font-weight: 500;
}

.add-plus:hover {
  border: 1px solid #4CCB1F;
  color: #4CCB1F;
}

.page-inner-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  color: #262626;
}

.page-inner-head span {
  font-weight: 700;
}

.bars_ck .cke_chrome {
  margin-bottom: 20px;
}

.add-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  color: #141414;
  text-align: center;
}

.save-btn-add-small {
  font-family: 'Poppins';
  background: #009AF1;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #009AF1;
  width: 115px;
  height: 43px;
}

.save-btn-add-small:hover,
.save-btn-add-small:focus {
  color: #FFFFFF !important;
  background: #009AF1 !important;
  border: 1px solid #009AF1 !important;
}

.cancel-btn-add-small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #262626;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  border: 1px solid #262626;
  background-color: #fff;
  width: 115px;
  height: 43px;
}

.cancel-btn-add-small:hover,
.cancel-btn-add-small:focus {
  color: #262626 !important;
  background-color: #fff !important;
  border: 1px solid #262626 !important;
}

.sub-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #262626;
}

.form-group p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: #262626;
}

.description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px !important;
  line-height: 32px;
  color: #000000;
}

.modal-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.modal-content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-top: 20px
}

.level_container {
  width: 150px;
  height: 35px;
  border: 1px solid #4CCB1F;
  margin: 15px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.side-modal {
  width: 100%
}

.side-modal .ant-modal-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 420px;
  overflow: "auto";
  padding: 20px;
}

.side-modal .ant-modal-body {
  max-height: 380px;
  overflow: auto;
  margin-right: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: -0.8px;
  color: #5E5E5E;
}

.side-modal .ant-modal-body::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.side-modal .ant-modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px
}

.side-modal .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F0F0F0;
}
.side-tool {
  width: 100%
}

.side-tool .ant-modal-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 420px;
  overflow: "auto";
  padding: 20px;
}

.side-tool .ant-modal-body {
  max-height: 380px;
  overflow: auto;
  margin-right: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: -0.8px;
  color: #5E5E5E;
}

.side-tool .ant-modal-body::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.side-tool .ant-modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow: inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px
}

.side-tool .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #F0F0F0;
}
.LevelContainer {
  background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
  border-radius: 11px;
  min-width: 282px;
  max-width: 400px;
  /* height: 52px; */
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  padding: 10px;
  color: #FFFFFF;
}

.render-text {
  font-family: 'poppins';
}

.render-text p {
  font-family: 'poppins';
  font-weight: 400;
  font-size: 17px;
}

.full-length {
  height: 100vh !important;
}

.table-div {
  max-width: 715px;
  width: 100%
}

.left_bg {
  position: absolute;
  right: -30px;
  top: 61vh;
  width: 26%
}

.right_bg {
  position: absolute;
  left: -30px;
  top: 61vh;
  width: 26%
}

.table-div-bars {
  width: 75%
}

.create-comp-div {
  position: relative;
  /* background: url('../src/modules/assets/competency-bg.svg');
  background-repeat: no-repeat;
  background-position: right; */
  height: 100%;
}

.sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #262626;
}

.bars-head {
  margin: 0.2em 0em 0em 0.24em !important;
  padding-bottom: 10px !important;
}

.bars-logo {
  max-height: 50% !important;
  max-width: 58% !important;
}

@media only screen and (max-width: 600px) {
  .date-display {
    margin-top: 10px !important;
  }

  .bootom-div {
    right: 10px;
    left: 10px;
  }

  .table-div-bars {
    width: 100%;
  }

  .page_heading {
    font-size: 28px;
    line-height: 30px;
  }

  .page-sub-title {
    font-size: 22px;
    line-height: 40px;
  }



  .form-group p {
    font-size: 15px;
  }

  .calender_name_bars {
    width: 80%
  }

  .sub-title {
    font-size: 15px;
  }

  .description {
    font-size: 14px;
    line-height: 26px;
  }

  .search-input-filter {
    width: 150px;
  }

  .bars-select-box {
    min-width: 180px !important;
    font-size: 13px !important;
  }

  .bars-head {
    letter-spacing: 0.375em;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 30px;
  }

  .subtitle {
    padding-top: 0px;
    font-size: 14px;
    line-height: 24px;
  }

  .bars-logo {
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important
  }

  .search-input-bars {
    width: 230px !important;
  }

  .add-header {
    font-size: 24px;
  }
}

@media screen and (min-width:1100px) and (max-width:1600px) {
  .page_heading {
    font-size: 38px;
    line-height: 60px;
  }

  .add-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 45px;
    width: 160px;
    padding: 5px 10px;
  }

  .table-div {
    max-width: 615px;
    width: 100%
  }

  .left_bg {
    width: 30%
  }

  .right_bg {
    position: absolute;
    width: 30%
  }

  .go-back-btn {
    font-size: 16px;
    line-height: 22px;
    width: 140px;
    background-color: #fff;
    padding: 10px 10px;
  }

  .delete-btn {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 10px;
    width: 140px
  }

  .modal-head {
    font-size: 25px;
    text-align: center;
  }

  .modal-content {
    font-size: 17px;
  }

  .search-input {
    border-radius: 8px;
    width: 300px;
    height: 38px;
    padding-left: 35px;
  }

  .table-div-bars {
    width: 70%
  }

  .page-sub-title {
    font-size: 28px;
    line-height: 40px;
  }

  .form-group p {
    font-size: 15px;
  }

  .sub-title {
    font-size: 16px;
  }

  .description {
    font-size: 15px !important;
    line-height: 28px;
  }

  .bars-select-box {
    min-width: 180px !important;
    font-size: 14px !important;
  }

  .bars-head {
    letter-spacing: 0.275em !important;
    font-weight: 600;
    font-size: 51px;
  }

  .subtitle {
    font-size: 20px;
  }

  .bars-save-btn,
  .bars-cancel-btn {
    width: 180px;
    height: 56px;
    font-size: 17px !important;
  }
}

.bars-save-btn,
.bars-cancel-btn {
  width: 180px;
  height: 56px;
  font-size: 17px !important;
}

@media (max-width:960px) {
  .left_bg {
    display: none !important;
  }

  .right_bg {
    display: none !important;
  }

  .bars-head {
    letter-spacing: 0.275em !important;
    font-weight: 600;
    font-size: 41px;
    margin-bottom: 10px;
  }

  .subtitle {
    padding-top: 20px;
    font-size: 20px;
  }

  .bars-logo {
    max-height: 100% !important;
    max-width: 100% !important;
  }
}


.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:first-child {
  border-right: 8px solid;
}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:nth-child(2) {
  border-right: 8px solid;
}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-right: none;
}

.create_bar_table td:first-child {
  background: rgb(250, 250, 250);

}

.create_bar_table td {
  background: rgb(250, 250, 250);

}

.create_bar_table .ant-table-tbody>tr.ant-table-row:hover>td {
  background: rgb(250, 250, 250);

}

.create_bar_table .ant-table.ant-table-bordered>.ant-table-container {
  border: none;
}

.formLabel-modal label {
  font-family: 'Poppins';
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
}


.crop-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.custom-label {
  font-family: 'Poppins' !important;
  font-size: 19px !important;
  font-weight: 600 !important;
}

.comp_modal_content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
  margin-top: 30px;
}

.detail-page-view {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}


.row-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px
}

.margin {
  margin-right: 10px !important;
}

.bars-select-box input {
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  left: 0px !important;
  font-size: 14px !important;
  text-align: left;
  height: 100% !important;
}

.bars-select-box {
  min-width: 200px !important;
}

.bars-select-box .ant-select-selection-placeholder {
  text-align: left !important;
  flex: none;
  font-size: 14px;
}

.bars-head {
  text-align: center !important;
  letter-spacing: 0.375em;
  font-weight: 600;
  font-size: 77px;
}

.bars-cancel-btn:hover,
.bars-cancel-btn:focus {
  color: #4CCB1F !important;
  border: 1px solid #4CCB1F !important;
}

.table-row-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
}

@media (max-width:450px) {
  .search-input-filter {
    width: 100% !important;
    max-width: 130px !important;
  }

  .search-input-bars {
    width: 130px !important;
  }

  .bars-save-btn {
    font-size: 15px !important;
    width: 140px !important
  }

  .bars-cancel-btn {
    font-size: 15px !important;
    width: 140px !important
  }

  .bars-head {
    letter-spacing: 0.175em !important;
    font-size: 28px !important;
  }

  .subtitle {
    padding-top: 0px !important;
    font-size: 18px !important;
  }

  .button-div {
    margin-top: 0px !important;
  }

  .padding-class {
    padding-top: 10px !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width:600px) and (max-width:700px) {
  .search-input-filter {
    width: 250px !important;
  }
}

@media (max-Width:330px) {
  .bars-head {
    letter-spacing: 0.175em !important;
    font-size: 24px !important;
  }

  .subtitle {
    font-size: 15px !important;
  }
}

.custom-scroll::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #B0CB1F;
}

.bars-textarea {
  border-radius: 10px;
  padding: 7px;
  font-size: 14px;
  line-height: 1.5;
  height: 120px;
  resize: none;
  width: 100%;
  min-width: 200px;
}

.bars_ck {
  margin: 5px;
  display: flex;
  justify-content: center;
}

.level-info-container {
  height: 144.5px;
  margin-bottom: 19px;
}

.level-info-container div {
  line-height: 1.5;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.level-info-container-bars {}

.create_bar_table .ant-col-16 {
  max-width: none !important;
}

.level-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.level-info-bars {
  margin: 0 !important;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-submenu-popup>.ant-menu {
  background: #F5F5F5 !important;
  border-radius: 14px;
  right: -20px !important;
}

.ant-menu-submenu-popup {
  right: 40px !important;
  width: 100px;
}

.polygon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: -6px !important;
  top: -45px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #F5F5F5;
  z-index: 1500;
}

.polygon-for-note {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 45px !important;
  top: -25px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #F5F5F5;
}

.custom-menu-item {
  margin-top: 20px;
  border-radius: 20px;
}

.custom-menu-item .ant-drawer-mask {
  opacity: 0 !important;
}

.custom-menu-item .ant-drawer-content-wrapper {
  max-height: 90%;
  border-radius: 18px !important;
}

.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -21px 3px 147px rgba(0, 0, 0, 0.6) !important;
}

.custom-menu-item .ant-drawer-content {
  border-radius: 18px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.profile-name-side-nav {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
}

.navTitle-side {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15.64px;
  line-height: 23px;
  color: #000000;
  padding-left: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.ant-menu-submenu-active {
  color: #000000 !important;
  text-decoration: none !important;
}

.no-data {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #303030;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .side_tool_views {
    display: none;
  }
}

/* CSS FOR KUDOS FAMILY BUTTON IN ORGANIZATION DETAIL PAGE */
.organization-kudos {
  margin-top: 10px;
  width: 185px;
  height: 48px;
  background: #4CCB1F;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

.organization-kudos:hover {
  background: #4CCB1F;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

.organization-kudos:focus {
  background: #4CCB1F;
  color: #FFFFFF;
  border: 1px solid #4CCB1F;
}

/* THIS IS THE GLOBAL CSS WRITTEN TO ADJUST THE BACK ARROW RIGHT BELOW THE M LETTER OF MAPP LOGO */

@media screen and (min-width: 319px) and (max-width: 415px) {
  .back-arrow-global {
    padding: 0 2.04em 0 2.04em;
  }
}

@media screen and (min-width: 416px) and (max-width: 510px) {
  .back-arrow-global {
    padding: 0 2.05em 0 2.05em;
  }
}

@media screen and (min-width: 510px) and (max-width: 552px) {
  .back-arrow-global {
    padding: 0 1.92em 0 1.92em;
  }
}

@media screen and (min-width: 553px) and (max-width: 667px) {
  .back-arrow-global {
    padding: 0 2.4em 0 2.4em;
  }
}

@media screen and (min-width: 668px) and (max-width: 737px) {
  .back-arrow-global {
    padding: 0 2.45em 0 2.45em;
  }
}

@media screen and (min-width: 738px) and (max-width: 835px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 836px) and (max-width: 981px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 982px) and (max-width: 1281px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}

@media screen and (min-width: 1323px) and (max-width: 1982px) {
  .back-arrow-global {
    padding: 0 3.2em 0 3.2em;
  }
}


.green-btn,
.green-btn:hover {
  background-color: #4CCB1F !important;
  border: 1px solid #4CCB1F !important;
  color: #fff !important;
  margin: 0px 5px !important;
}

.warning-btn {
  margin: 0px 5px !important;
}

.containct-us-table .ant-table-column-sorters {
  padding: 10px 0px !important;
}

.tool-close-btn-icon:hover {
  background: #009AF1 !important;
}

.tool-close-btn-icon:focus {
  background: #009AF1 !important;
}

.ant-tooltip{
  z-index: 999 ;
}

.feedback-info-tooltip {
  z-index: 1500 !important;
  max-width: 500px !important;
}


.ant-modal-mask{
  z-index: 1150 !important;
}

.ant-modal-wrap{
  z-index: 1150 !important;
}

.ant-dropdown{
  z-index: 1160 ;
}

.ant-select-dropdown{
  z-index: 1160 ;
}

.ant-modal{
  top:25px !important
}

.content-dropdown{
  z-index: 1050 !important;
}
.kuos-dropdown-popup{
  z-index: 1160 !important;
}
.modal-datepicker-index{
  z-index: 1160 !important;
}
 
.table-border-left{
  border: 1px solid #D5E5F2;
  border-right: none !important;
}

.table-border-right{
  border: 1px solid #D5E5F2;
  border-left: none !important;
}

.table-border-up-down{
  border: 1px solid #D5E5F2;
  border-left: none !important;
  border-right: none !important;
}
.cke_notifications_area {
  pointer-events: none;
  display: none !important;
}

/* custom loader css */

/* From Uiverse.io by Nawsome */ 
.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #3E7CA1;
}

.pl__ring--b {
  animation-name: ringB;
  stroke: #3F8866;
}

.pl__ring--c {
  animation-name: ringC;
  stroke: #427E81;
}

.pl__ring--d {
  animation-name: ringD;
  stroke: #7D835B;
}

/* Animations */
@keyframes ringA {
  from, 4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%, 54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%, to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {
  from, 12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%, 62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%, to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%, 58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {
  from, 8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%, 50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}
.ant-form-item-children-icon{
  margin-right: 7px !important;
}
.ant-form-item-children-icon{
  margin-right: 7px !important;
}