.ca-root {
    font-size: 16px;
    font-family: "Poppins";
}

.ca-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    gap: 1em;
    padding: 0em 6.5em;
}

.ca-main-title {
    text-align: center;
    font-family: "Poppins";
    font-size: 2.2em !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.ca-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2em;
    margin-top: 2em;
}

.ca-add-button {
    height: 2.8em;
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff;
    border: 1px solid #4CCB1F;
    outline: none;
    white-space: nowrap;
}

.ca-search-menu-title {
    color: #4CCB1F;
    font-family: "Poppins";
    font-size: 1.1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ca-dropdown-btn {
    min-width: 100px;
    display: flex;
    height: 3.33em !important;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 0em .5em !important;
}

.ca-employee-card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.5em;
    padding: 0em 6.5em;
}

.ca-employee-card-seaction {
    width: 33.33%;
    padding: .75em;
}

.ca-employee-card {
    border-radius: 1em;
    box-shadow: 0px 10px 15px 0px #aabac633;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5em;
}

.cs-user-profile {
    background: #AABAC6;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs-user-name {
    color: #262626;
    text-align: left;
    font-family: "Poppins";
    font-size: 1.15em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.175em;
    margin: 0;
}

.cs-label {
    color: #262626;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.24em;
    margin: 0;
}

.cs-employee-progress-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    width: 100%;
    padding:1em 1.5em;
    border-top: 2px solid rgba(217, 217, 217, 1);
}
.cs-employe-progress-view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.5em;
    border-radius: 1em;
    width: 100%;
    
}
.cs-employee-progress-status{
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0;
    max-width: 5em;
}
.ca_remaining_day{
    color: #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    border-radius: 0.25em 0em 0em 0.25em;
    padding: .3em .5em;
    white-space: nowrap;
    margin-top: .2em;
    display: none;
}
.ca-edit-icon-container:hover .ca_remaining_day {
    display: block;
    
}
.cs-see-detail-btn{
    border-radius: 1.875em;
    background:#4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    height: 2.5em;
    padding: 0.25em 0.9375em;
    justify-content: center;
    align-items: center;
    color:  #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em;
    cursor: pointer;
    width: 8em;
    border: none;
    outline: none;
}
.ca-custom-menu-date-container{
    background: #ECF1F4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5em;
}
.ca-date-seletion-title{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: .5em;
}
.ca-date-seleted-title{
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #4CCB1F;
    padding: .5em;
    cursor: pointer;
}
.ca-date-see-more{
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}
.ca-dropdown-btn{
    border-radius: 0.75em;
    background: #FFF;
    display: flex;
    max-width: 10em;
    height: 2.4375em;
    padding: 0.625em 1.125em;
    justify-content: space-between;
    align-items: center;
    gap: 1.3125em;
    border: none;
}
.ca-chart-container{
    border-radius: 1.0625em;
    background: #F4F9FD;
    /* padding: 1em; */
    display: flex;
    width: 100%;
    flex-direction: column;
}
.ca-chart-filter-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2em;
    padding: 1.87em 2.19em;
}
.ca-view-competecny-title{
    color: #0098F0;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}
.ca-modal .ant-modal-content{
    background: #F4F9FD;
}
.ca-section-modal-heading{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.75em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.ca-select-filed .ant-select-selector,.ca-select-filed .ant-select-selector:hover,.ca-select-filed .ant-select-selector:focus,.ca-select-filed .ant-select-selector:focus-visible,.ca-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    /* border: 1px solid #AABAC6 !important; */
    border: none !important;
    font-weight: 400;
    font-size: 1em;
}
.ca-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.ca-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.ca-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.ca-select-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
}
.ca-select-option.ant-select-item p{
    margin: 0px;
}
.ca-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.ca-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.ca-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.ca-cancel-btn, .ca-cancel-btn:hover, .ca-cancel-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #FFF;
    border: 1px solid #8C8CA1;
    color: #8C8CA1;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins";
}
.ca-save-btn, .ca-save-btn:hover, .ca-save-btn:focus{
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    min-width: 7.1875em;
    padding: 1.15em 0.9375em;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125em;
    background: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #fff;
    text-align: center;
    font-size: 1.1875em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins";
}
.ca-no-data{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 5em;
}
.ca-no-data-text{
    font-family: 'Poppins';
    font-size: 1.25em;
    font-weight: 500;
    text-align: center;
    color: #000;
    line-height: normal;
}
.ca-profile-icon{
    font-family: 'Poppins';
    font-size: 2.2em;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: normal;
    margin: 0;
}
.ca-next-page-icon, .ca-next-page-icon:hover, .ca-next-page-icon:focus{
    width: 9em;
    height: 3em;
    color: #4CCB1F;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.3em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375em; 
    border: 1px solid #4CCB1F;
    background-color: #FFF;
    border-radius: .5em;
}
.ca-page-count{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.ca-page-count span{
    border-radius: 0.5rem;
    border: 1px solid #8C8CA1;
    height: 2em;
    padding: 0em 1.5em;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ca-scroll-container{
    width: 100%;
    overflow: auto;
}
@media screen and (max-width:1600px) {
    .ca-root {
        font-size: 14px;
    }
}

@media screen and (max-width:1000px) {
    .ca-root {
        font-size: 12px;
    }
    .ca-employee-card-seaction {
        width: 50%;
        padding: .5em;
    }
    
}

@media screen and (max-width:850px) {
    .ca-root {
        font-size: 10px;
    }
    .ca-heading-container{
        padding: 0em 4.5em;
    }
    .ca-employee-card-container {
        padding: 0em 4.5em;
    }
}
@media (max-width:600px) {
    .ca-employee-card-seaction {
        width: 100%;
        padding: .5em;
    }
    .ca-heading-container{
        padding: 0em 2.5em;
    }
    .ca-employee-card-container {
        padding: 0em 2.5em;
    }
}
@media (max-width:480px) {
    .ca-dropdown-btn {
        min-width: 80px;
        width: fit-content;
    }

    .ca-search-input-filter {
        width: 12em;
    }
    .ca-heading-container{
        padding: 0em 1em;
    }
    .ca-employee-card-container {
        padding: 0em 1em;
    }
}