

.engagement-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width:100%;
}
.enggement-main-button,.enggement-main-button:hover,.enggement-main-button:focus{
    background: rgba(76, 203, 31, 1);
    color: #fff;
    padding: .5em 1em;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.25em !important;
    border-radius: .5em;
    height: 3em;
    width: fit-content;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
}
.coaching-conversation-root{
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}
.coaching-engagement-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.75em;
    color: #000;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.coaching-engagement-form-lable{
    font-size: 1.15em;
    font-weight: 500;
    color: #262626;
    font-family: Poppins;
    line-height: normal;
    font-style: normal;
    margin: 0 !important;

}
/* .coaching-engagement-form-lable p{
    font-family:"Poppins" !important;
    font-size: 1em !important;
    font-weight: 500 !important;
    color: #000;
    line-height: normal;
    font-style: normal;
    margin: 0 !important;
    margin-bottom: 0px !important;
} */
.custom-modal .ant-modal{
    width: 800px !important; 
    transform-origin: 349px 124px !important;
    background-color: #fff; /* Change as needed */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 20px;
  }