.ai-tip-fixed-container {
    position: fixed;
    bottom: 20px;
    z-index: 1500;
    left: 20px;
    right: 20px;
}

.ai-tip-relative-container {
    width: 100%;
    position: relative;
}

.ai-tips-icon {
    width: 100px;
    height: 100px;
    cursor: grab !important;
    position: absolute;
    bottom: 0px;
}

.tip-hide-mode {
    width: 450px;
    max-width: 90dvw;
    height: 0px;
    overflow: hidden;
    background-color: #fff;
    transition-duration: 0.5s;
    border-radius: 12px;
    position: absolute;
    bottom: 90px;
}


.tip-view-mode {
    width: 450px;
    max-width: 90dvw;
    height: calc(80dvh - 80px);
    transition-duration: 0.5s;
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
    position: absolute;
    bottom: 90px;
}

.tips-view-container {
    height: 100%;
    width: 100%;
    padding: .5em 1em;
}

.tip-header-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
}

.ai-tip-header-title {
    margin: 0px;
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.75em;
    text-align: left;
    color: rgba(76, 203, 31, 1);
}

.ai-tip-list {
    height: calc(80dvh - 220px);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    padding-right: 0.5em;
}

.ai-tip-list::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.ai-tip-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.ai-tip-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}



.border-sepeartion {
    height: 16px;
    width: 1px;
    border: 1px solid rgba(170, 186, 198, 1)
}

.ai-tip-card {
    background: rgba(246, 251, 255, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    padding: 1em;
}

.ai-tip-card h3 {
    font-family: Poppins;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
}

.ai-tip-card h4 {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default
}

.bottom-center {
    left: 50%;
    transform: translateX(-50%)
}

.bottom-left {
    left: 0px;
}

.bottom-right {
    right: 0px;
}

.tip-list-root {
    font-size: 16px;
    width: 100%;
    height: calc(100vh - 200px);
    background-color: #fff;
    border-radius: 1em;
    padding: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;

}

.tip-list-root::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.tip-list-root::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.tip-list-root::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.list-ai-card-container {
    padding: 1em;
    width: 33%;
}

.list-ai-tip-card {
    background: rgba(246, 251, 255, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* justify-content: space-between; */
    padding: 1em;
}

.list-ai-tip-card h3 {
    font-family: Poppins;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
    margin: 0px;
}

.list-ai-tip-card h4 {
    font-family: Poppins;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.75em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    cursor: default;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tip-module-select,
.tip-module-select:hover,
.tip-module-select:focus {
    border: 1px solid rgba(170, 186, 198, 1);
    border-radius: 8px;
    overflow: hidden;
}

.tip-bookmark-btn-filter,
.tip-bookmark-btn-filter:hover,
.tip-bookmark-btn-filter:focus {
    border: 1px solid rgba(170, 186, 198, 1);
    color: rgba(137, 152, 162, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.tip-bookmark-active {
    border: 1px solid #4ccb1f !important;
    color: #fff !important;
    background: #4ccb1f !important
}

.tip-date-picker {
    border: 1px solid rgba(170, 186, 198, 1);
    color: rgba(137, 152, 162, 1);
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.show-preview-tip {
    position: fixed;
    right: 20px;
    width: 450px;
    background-color: #fff;
    box-shadow: -13px 13px 40.9px 0px rgba(219, 238, 255, 1);
    z-index: 2000;
    top: 100px;
    padding: 16px;
    transition-duration: .5s;
    border-radius: 12px;
}

.hide-preview-tip {
    position: fixed;
    right: 10px;
    width: 0px;
    top: 100px;
    overflow: hidden;
    transition-duration: .5s;
    background-color: #fff;
    z-index: 2000;
}

.tip-preview-content {
    width: 100%;
    max-height: 180px;
    overflow: auto;
    padding-right: 0.5em;
}

.tip-preview-content::-webkit-scrollbar {
    width: 4px;
    display: block;
}

.tip-preview-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px
}

.tip-preview-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.ai-tip-loader-container {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 
 
@media (max-width:1200px) {

    .list-ai-card-container {
        padding: 1em;
        width: 50%;
    }
}

@media (max-width:760px) {

    .list-ai-card-container {
        padding: 1em;
        width: 100%;
    }

    .bookmark-container {
        flex-wrap: wrap;
    }
}