.toolcontent img{
        object-fit: fill;
        width: 100%;
}
.impact-body-padding{
        padding:0px 16px
}

.impact-row{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
}
.web-screen{
        display: block;
}
.mobile-screen{
        display: none;
}
.tool-body{
        padding: 15px 30px;
}
.space-between-row-impact{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px; 
        justify-content: space-between;
        align-items: center;
}

.space-between-row-impact-ui-flow-tool{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px; 
        justify-content: space-between;
        align-items: center;
}

.selector-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:10px;
        padding: 5px 10%;
}

.drop-field{
        width:100%;
        max-width:100%;
        overflow: hidden;
}
.drop-field div{
        margin: 0px !important;
        padding: 0px !important;
        max-width:100%
}

.compare-div{
        width: 35px !important;
        height: 30px !important;
        min-width: 35px !important;
        min-height: 30px !important;
        cursor: pointer;
        padding: 0px;
        border: none;
        background: transparent !important;
        /* margin: 0px 50px; */
}

.compare-div img{
        width: 100%;
        height: 100%;
}

.employee-selector{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 5px;
        
}
.employee-selector .ant-select-selector{
        border-radius: 7px !important;
        border: 1.5px solid #BFBFBF !important;
        text-align: left !important;
        cursor: pointer !important;
}
.employee-selector input {
        cursor: pointer !important;
}
.employee-selector .ant-select-selection-item,.ant-select-selection-placeholder{
 padding: 0px 10px !important;
 font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 14px !important;
line-height: 32px !important;
color: #595959 !important;
}

.employee-selector .ant-select-selection-item div{
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
}

.impat-tool-titile{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 52px;
        color: #141414;
        margin: 0px;
}

.tab-container{
        background-color: #F0F0F0;
        border-radius: 8px;
        padding: 6px 7px;
        width: fit-content;
}

.tab-btn{
        background-color: transparent;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        color: #8C8C8C;
        width: fit-content;
        white-space: nowrap;
        height: 53px;
        border: 1px solid transparent;
        cursor: pointer;
}

.tab-btn:hover{
        background-color: transparent; 
        color: #8C8C8C;
        border: 1px solid transparent;
}

.tab-btn:focus{
        background-color: transparent; 
        color: #8C8C8C;
        border: 1px solid transparent;
}

.selected-tab-btn{
        color: #4CCB1F !important;
        background: #FFFFFF !important;
        box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14) !important;
        border-radius: 5px !important;
}

.tool-content{
        width: 100%;
}

.card-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: content-box;
        justify-content: space-between;
        align-items: center;
        margin-top: 75px;
}

.card-gap{
        margin:30px 0px;
}

.team-section-gap{
        margin:0px 12.5px;
        width: 100% !important;
}

.impact-header-card{
        width: 100%;
        box-sizing: border-box;
        border: 4px solid #02D7A4; 
        border-radius: 25px;
        padding: 50px 50px 20px 50px;
        margin-top: 80px;
        position: relative;
}

.impact-header-card *{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        /* color: #1F1F1F; */
}

.profile-img{
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        margin: 0px;
}

.header-border-div{
        position: absolute;
        top: -26px;
        background:transparent;
        width: 100%;
        left: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
}

.compare-border-div{
        top: -36px;
}

.border-inner-content{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        justify-content: center;
        align-items: center;
        width: fit-content;
        background: #f6fbff;
        padding: 0px 20px;
        box-sizing: border-box;
}

.header-border-label{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
        background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        flex: none;
        flex-grow: 0;
        margin: 0px;
}

.compare-border-label{
        font-weight: 700;
        font-size: 25px;
        line-height: 128.5%;
}

.impact-card{
        width: 47%;
        box-sizing: border-box;
        border: 4px solid #009AF1;
        border-radius: 25px;
        position: relative;
        padding: 20px;
}

.compare-full-width{
        width: 100% !important;     
}

.overflow-card{
        padding: 10px 30px 0px 30px;
        min-height: 350px;
        height: 350px; 
        overflow: auto; 
}

.overflow-card::-webkit-scrollbar {
        height: 4px;
        width: 4px !important;
}
      
.overflow-card::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #fff;
-moz-box-shadow:  inset 0 0 5px #fff;
-webkit-box-shadow: inset 0 0 5px #fff;
border-radius: 10px;
padding-right: 10px;
margin-bottom:10px;
margin-top:10px
}

.overflow-card::-webkit-scrollbar-thumb {
border-radius: 10px;

background-color:#b1b0b0e1;
}


.impact-card *{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        /* color: #1F1F1F; */
}



.sub-border-div{
        position: absolute;
        top: -26px;
        background:#f6fbff;
        width: fit-content;
        left: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 15px;
}

.sub-border-label{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 46px;
        color: #009AF1;
        flex: none;
        flex-grow: 0;
        margin: 0px;
}

.bread-crubs-container{
        position: fixed;
        top: 20px;
        left: 50%;
        right: 50%;
        z-index: 1000;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
        align-items: center;
        justify-content: center;
}

.bread-crubs-tab{
        border-radius: 5px;
        padding: 5px 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        color: #8C8C8C;
        background: transparent;
        height: fit-content;
        border: none;
}
.bread-crubs-tab:hover{
        background: transparent;
        color: #8C8C8C;   
}
.bread-crubs-tab:focus{
        background: transparent;
        color: #8C8C8C;   
}
.active-bread-crubs{
        background: #E1FFD7;
        width: fit-content;
        color: #6BB96A;
}
.active-bread-crubs:hover{
        color: #6BB96A;   
        background: #E1FFD7;
}
.active-bread-crubs:focus{
        color: #6BB96A !important;   
        background: #E1FFD7 !important;
}

@media (min-width:1800px) {
        .tab-container{
                margin-top: 60px !important;
        }
}

@media (min-width:1300px) {
        .tab-container{
                margin-top: 40px;
        }
        .tool-content{
                max-width: 1292px;
        }
        .tool-logo{
                max-width: 1250px;
                max-height: 328px;
        }
      
}

@media screen and (max-width:950px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: 37px;
        }
}

@media screen and (max-width:950px) and (min-height:760px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: 0px;
        }
}

@media screen and (max-width:950px) and (min-height:1360px){
        .space-between-row-impact-ui-flow-tool{
                margin-top: -30px;
        }
}

@media screen and (min-width:950px) and  (max-width:1300px) {
        .bread-crubs-container{
                gap: 10px;
        }
        .bread-crub{
                gap: 10px;
        }
        .card-wrapper{
                margin-top: 40px;
        }
        
        .impact-header-card{
                margin-top: 65px;        
        }
        .tool-content{
                max-width: 1292px;
        }
        .tool-logo{
                width: 100%;  
        }
        .tool-body{
                padding: 5px 0px;
        }
        .selector-container{
                padding: 5px 0px;
        }
        .compare-border-label{
                font-size: 22px;
        }

        .compare-border-div{
                top: -30px;
        }
}

@media screen and (min-width:800px) and  (max-width:950px) {
        
        .card-wrapper{
                margin-top: 35px;
        }
        .impact-header-card{
                margin-top: 60px;        
        }
        .impat-tool-titile{
                font-size: 28px; 
        }
        .tool-content{
                max-width: 950px;
        }
        .tool-logo{
                width: 100%;
        }
        .tool-body{
                padding: 5px 0px;
        }
        .tab-btn{
                padding: 5px !important;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                height: fit-content; 
        }
        .impact-header-card{
                padding: 30px 30px 15px 30px;
                border: 2px solid #02D7A4; 

        }
        .sub-border-div{
                left: 15px;
                padding: 0px 8px 0px 15px;

        }

        .impact-card {
                padding: 20px 15px 0px 15px;
                border: 2px solid #009AF1; 
                /* min-height: 200px !important;
                height: 200px !important; */
        }

        .overflow-card{
                padding: 0px 15px 0px 15px;
                min-height: 220px;
                height: 220px; 
                overflow: auto; 
        }

        .impact-header-card *{
                font-size: 14px;
        }
        
        .impact-card *{
                font-size: 14px;
        }

        .header-border-label{
                font-size: 18px !important;    
        }
        .sub-border-label{
                font-size: 16px !important;
        }
        .selector-container{
                padding: 5px 0px;
        }

        .compare-border-label{
                font-size: 15px;
        }

        .compare-border-div{
                top: -22px;
        }
        .compare-border-div img{
                display: none;
        }
}

@media screen and (min-width:600px) and  (max-width:800px) {

        .card-wrapper{
                margin-top: 35px;
        }
        .impact-header-card{
                margin-top: 60px;        
        }
        .impat-tool-titile{
                font-size: 28px; 
        }
        .tool-content{
                max-width: 800px;
        }
        .tool-logo{
                width: 100%;
        }
        .tool-body{
                padding: 5px 0px;
        }
        .tab-btn{
                padding: 5px !important;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                height: fit-content; 
        }
        .impact-header-card{
                padding: 15px 8px 5px 8px;
                border: 2px solid #02D7A4; 
                border-radius: 15px;

        }
        .sub-border-div{
                left: 8px;
                padding: 0px 8px 0px 8px;
        }

        .impact-card {
                padding: 20px 8px 0px 0px;
                border: 2px solid #009AF1; 
                border-radius: 15px;
        }

        .overflow-card{
                padding: 0px 15px 0px 15px;
                min-height: 220px;
                height: 220px; 
                overflow: auto; 
        }

        .impact-header-card *{
                font-size: 14px;
        }
        
        .impact-card *{
                font-size: 14px;
        }

        .header-border-label{
                font-size: 14px !important;    
        }
        .sub-border-label{
                font-size: 14px;
        }
        .selector-container{
                padding: 5px 0px;
        }

        .compare-border-label{
                font-size: 15px;
        }

        .compare-border-div{
                top: -22px;
        }
        .compare-border-div img{
                display: none;
        }
}

@media (max-width:950px) {
        .impact-card{
                width: 100%;
        }
        .bread-crubs-container{
                display: none !important;
        }
}

@media screen and (max-width:600px) and (max-height:768px) {

        .space-between-row-impact-ui-flow-tool{
                margin-top:10px
        }

}

@media screen and (max-width:600px) and (max-height:500px) {
        .space-between-row-impact{
                margin-top: 0px !important;
                /* padding: 10px; */
        }

        .space-between-row-impact-ui-flow-tool{
                margin-top:50px
        }
      
}





@media screen and (max-width:800px) and (max-height:500px) {
        .space-between-row-impact{
                margin-top: 15px;
        }

}

@media screen and (max-width:600px)  {
       
        .impact-back{
                margin-left: -10px;
        }

        .impact-body-padding{
                padding:0
        }
        .impat-tool-titile{
                font-size: 18px;
                line-height: 0px !important;
                color: #141414;
                white-space: nowrap;
                margin: 0px !important;
        }
        .web-screen{
                display: none;
        }
        .mobile-screen{
                display: block;
                /* width: 100%; */
        }
        .tool-body{
                width: 100%;
                padding: 0px;
        }
        .tool-content{
                margin-top: 20px;
        }
        .tool-logo{
                width: 298px;
                height: 198px;
                max-width: 100% !important;
        }
        .tab-container{
                background-color: #F0F0F0;
                border-radius: 4.10042px;
                padding: 4px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 20px;
        }
        .tab-btn{
                background-color: transparent;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                width: fit-content;
                white-space: nowrap;
                height: 27px;
                padding: 4.5px ;
        }
        .impact-header-card{
             width: 100%;
             border-radius: 13.4563px;
             padding: 31px 21px 20px 21px;
             margin-top: 30px;
             border: 1.9px solid #02D7A4; 
        }
        .header-border-label{
                font-weight: 700 !important;
                font-size: 16px !important;
                line-height: 24px !important;
        }
        .profile-img{
                width: 20.83px;
                height: 20.83px ;
        }
        .header-border-div{
               top:-13px
        }
        .border-inner-content{
                padding: 0px 12px;
        }
        .impact-header-card *{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-align: justify;
                /* color: #1F1F1F; */
        }
        .selector-container{
                max-width: 257px;
                width: 100%;
                padding: 0px;
        }
        .compare-border-div{
                top: -25px;
        }
       
}



@media screen and (max-width:420px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:10px
        }
}

@media screen and (max-width:320px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:20px
        }
}

@media screen and (max-width:320px) and (min-height:800px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:0px
        }
}

@media screen and (max-width:420px) and (min-height:1000px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:-25px
        }
}

@media screen and (min-width:1000px) and (max-height:568px) {
        
        .space-between-row-impact-ui-flow-tool{
                margin-top:15px
        }
}


