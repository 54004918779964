.scape-root{
        font-size: 16px;
        font-family: "Poppins";
}

.scape-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    gap: 1em;
    padding: 0em 6.5em;
    margin: 1em 0em;
}

.scape-main-title {
    text-align: center;
    font-family: "Poppins";
    font-size: 2.2em !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.scape-data-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2em;
    gap: 1em;
}

.scape-selection-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
}

.scape-view-report-btn,.scape-view-report-btn:hover,.scape-view-report-btn:focus{
    background-color: #4CCB1F;
    padding: 0em 1.5em;
    height: 2.6em;
    color:#FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 0.5em;
    border: none;
    outline: none;
    margin-top: 1em;
}
.scape-vertical-filter{
    /* background: #ECF1F4; */
    width: 100%;
    height: 350px !important;
    /* min-height: 30em; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: .5em;
    padding: .5em 1em;
}
.scape-filter-content{
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: start;
    align-items: center;
}
.scape-filter-text{
    margin: 0;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.scape-space-between-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;
    justify-content:space-between;
    padding: 0em 6.5em;
}
.scape-toggle-container{
    border-radius: 0.5em;
    border: 1px solid #ECF1F4;
    background: #FFF;
    width: 12em;
    height: 3.3em;
    display: flex;
    flex-direction: row;
}
.scape-toggle-button{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height:normal; 
    width: 100%;
    cursor: pointer;
}
.scape-toggle-button-active{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #4CCB1F;
    box-shadow: 0px 4px 4px 0px rgba(76, 203, 31, 0.20);
    color: #FFF;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    cursor: pointer;
}
.scape-preference-tab-container{
    display: flex;
    flex-direction: row;  
    justify-content: start;  
    width: 100%;
    padding: 0em 6.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
.scape-preference-tab{
    display: flex;
    flex-direction: row;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    border: 1px solid #F4F9FD;
    background: #F4F9FD;
    gap: 1em;
    height: 3.3em;
    overflow: auto;
    width: 100%;
    justify-content: start;
    padding-left: .3em;
    padding-right: .3em;
}
.scape-preference{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #000;
    font-family: "Poppins";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    min-width: 10em !important;
    width: 100% !important;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.scape-preference-color-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8px;
}
.scape-input-filter {
    border: 1px solid #ECF1F4;
    background: #FFF;
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    /* height: 3.3em !important; */
    height:46px !important;
  }
@media screen and (max-width:1600px) {
    .scape-root {
        font-size: 14px;
    }
    .scape-heading-container {
        padding: 0em 4.5em;
    }
    .scape-space-between-container{
        padding: 0em 4.5em;
    }
    .scape-preference-tab-container{
        width: 100%;
        padding: 0em 4.5em;
        }
}

@media screen and (max-width:1000px) {
    .scape-root {
        font-size: 12px;
    }
    .scape-heading-container {
        padding: 0em 3.5em;
    }
    .scape-space-between-container{
        padding: 0em 3.5em;
    }
    .scape-preference-tab-container{
        padding: 0em 3.5em;
        }
}

@media screen and (max-width:850px) {
    .scape-root {
        font-size: 10px;
    }
    .scape-heading-container {
        padding: 0em 1.5em;
    }
    .scape-space-between-container{
        padding: 0em 1.5em;
    }
    .scape-preference-tab-container{
        padding: 0em 1.5em;
        }
}