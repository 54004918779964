/* css for main cards container with media query for card position adjustments */

.feedback-sub-cards-container {
    display: grid;
    width: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: minmax(min-content, max-content);
    gap: 2em;
    padding: 0em 2.1em 0em 2.1em;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    height: min-content;
}

.height-fix-submitted {
    height: 840px;
}

.height-fix-received {
    height: 840px;
}

.feedback-sub-cards-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

@media screen and (min-width: 511px) and (max-width: 1112px) {
    .feedback-sub-cards-container {
        grid-template-columns: 1fr;
      }
}

@media screen and (min-width: 550px) and (max-width: 552px) and (min-height: 830px) {
    .feedback-sub-cards-container {
        grid-template-columns: 1fr 1fr;
      }
}

@media screen and (min-width: 638px) and (max-width: 640px) and (min-height: 1365px) and (orientation: portrait) {
    .feedback-sub-cards-container {
      grid-template-columns: 1fr !important;
    }
  }

@media screen and (max-width: 510px) {
  .feedback-sub-cards-container {
    grid-template-columns: 1fr;

  }
}

@media screen and (max-width: 321px) {

    .feedback-sub-cards-container {
      grid-template-columns: 1fr;
    }
  }

/* the below section is problem area one of. the width is creating issue */
.feedback-sub-detail-text {
    height: 65px !important;
    width: 100px;
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    overflow-y: hidden;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 1.4em;
    font-size: 0.96rem;
    /* word-wrap: break-word; */
    text-align: justify;
    /* grid-area: detail; */
    margin-top: 0.6em;
    color: #8C8CA1;
    overflow: hidden !important;
    /* margin-bottom: -0.3em; */
}

.feedback-sub-detail-text {
    width: 100%;
}

.feedback-rec-detail-text {
    height: 64px !important;
    
}

/* Image CSS */

.feedback_card_picture {
    grid-area: image;
    object-fit: cover;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 1367px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto auto auto;
    }
    .feedback_card_picture {
        width: 7em;
        height: 7em;
    }
}

@media screen and (max-width: 1071px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto auto;
    }
    .feedback_card_picture {
        width: 9em;
        height: 9em;
    }
}

@media screen and (max-width: 695px) {
    .feedback-sub-cards-container {
        grid-template-columns: auto;
    }
    .feedback_card_picture {
        width: 9em;
        height: 9em;
    }
}


/* from / to / posted / kudos badge section container */


.feedback-sub-card-title {
    grid-area: matter;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: 0.6em;
    padding: 1em 0em;
    margin-left: 0.5em;
}

.feedback-sub-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    word-wrap: break-word;
    font-size: 130%;
    /* width: 60%; */
    letter-spacing: -0.02em;
    margin-bottom: 0em;
    /* text-overflow: ellipsis !important;  */
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* received on section div and elements */

.received-on-section {
    align-items: center;
    margin-top: -0.25em;
    margin-bottom: 0;
}

.received-on-section p {
    margin-bottom: 0 !important;
}

.feedback-sub-kudos-image {
    /* align-self: flex-start; */
    margin-right: 2.98em;
    /* margin-left: auto; */
    /* display: inline-block; */
}

.received-on-text {
    /* height: 27%; */
    display: inline;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* font-size: 1.1em; */
    /* text-align: justify; */
    word-wrap: break-word;
    /* or 22px */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #000000;
}

.received-on-date {
    /* height: 27%; */
    color: #009AF1;
    font-family: 'Poppins';
    word-wrap: break-word;
    font-style: normal;
    font-weight: 500;
    /* font-size: 1.1em; */
}

/* p {
    margin-bottom: 0;
} */

/* from text */

.feedback-card-from-text {
    display: block;
    width: 44px;
    height: 15px;
    font-family: 'Poppins';
    margin-bottom: -0.7em;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */
    color: rgba(0, 0, 0, 0.5);
}

/* Kudos Badge Image CSS */

.feedback-sub-from-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback-image-title-cont {
    background: #FFFFFF;
    justify-content: space-between;
    align-items: center;
}

/* on applying width in below container there is issue getting created */
.feedback-image-title-cont {
    width: 100%;
    /* margin: 0 0.8em; */
    display: grid;
    grid-template-areas:
        'image matter matter matter matter'
        'image matter matter matter matter'
        'image matter matter matter matter';
}

.card-border-container {
    border: 1px solid #8C8CA1;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 2em;
    padding: 1em 1.5em;
    height: fit-content;
    width: 350px;
}

/* css for the card elements div for */

.card-border-container:hover {
    border: 1px solid #4CCB1F;
    box-shadow: 0px 14px 14px 0px #00000021;
    transform: scale(1.002);
}

.feedback-sub-main-btns {
    position: absolute;
    display: flex;
    gap: 0.9em;
    align-items: center;
    justify-content: space-between;
    right: 0.7em;
    top: 0.3em;
    z-index: 5;
    width: 15%;
    height: auto;
}

.feedback-sub-main-btns > img {
    width: 60%;
}

/* css for the date wise sorting of cards */

.feedback-date-sort-container {
    margin-left: 1.6em;
    /* margin-top: 1em; */
    /* margin-bottom: -1.5em; */
    /* min-height: min-content; */
}

/* .feedback-date-sort-container > button {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    color: #31D0AA;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
}

.feedback-date-sort-container > button:hover {
    background: #31D0AA;
    color: white;
} */

.clicked-date-btn {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
    background: #31D0AA;
    color: white;
}

.unclicked-date-btn {
    all: unset;
    width: 7.6em;
    height: 2.5em;
    border: 1px solid #31D0AA;
    border-radius: 2rem;
    text-align: center;
    color: #31D0AA;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 1em 0.8em;
    cursor: pointer;
}

/* css to adjust size of custom btn images */
.feedback-date-sort-container > img {
    width: 30px;
    height: 30px;
}

.feedback-image-title-cont {
        min-width: 0;
        max-width: auto;
        height: fit-content;
}

@media screen and (max-width: 415px) {
    .feedback-image-title-cont {
        width: 100%;
        min-height: auto;
        max-height: fit-content;
}
}

/* feedback edit and delete css */

.card-delete-btn-image {
    filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
}

.card-delete-btn-image:hover {
    filter: invert(28%) sepia(89%) saturate(7451%) hue-rotate(4deg) brightness(88%) contrast(124%);
}

.card-edit-btn-image {
    filter: invert(51%) sepia(0%) saturate(3352%) hue-rotate(212deg) brightness(94%) contrast(93%);
    margin-top: 0.2em;
}

.card-edit-btn-image:hover {
    filter: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(5911%) hue-rotate(245deg) brightness(87%) contrast(113%);
}

/* css media queries for mobile upto 320 width */

/* css media queries for mobile upto 416 to 782 width */
@media screen and (min-width: 416px) and (max-width: 770px) {
    .feedback-sub-cards-container {
        height: 912px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        gap: 2em;
        padding: 0em 2em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: fit-content;
        max-width: 500px;
        height: fit-content;
    }
    .feedback-date-sort-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-height: auto;
        max-height: fit-content;
        margin-bottom: 2em;
        overflow: scroll;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 75%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 80px;
        height: 80px;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 17%;
        font-size: 0.7em;

    }
    .received-on-text {
        height: 17%;
        font-size: 0.7em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 17%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
    }
    .feedback-date-sort-container {
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: max-content;
        /* margin-bottom: 2em; */
        margin-bottom: -2em;
        margin-top: 1.5em;

    }
}

/* css media queries for mobile upto 415 width */
@media screen and (max-width: 415px) {
    .feedback-sub-cards-container {
        height: 912px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        gap: 2em;
        padding: 0em 0.5em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: fit-content;
        max-width: 500px;
        height: fit-content;
    }
    .feedback-date-sort-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-height: auto;
        max-height: fit-content;
        margin-bottom: 2em;
        overflow: scroll;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 95%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 80px;
        height: 80px;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 17%;
        font-size: 0.7em;

    }
    .received-on-text {
        height: 17%;
        font-size: 0.7em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 17%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
    }
    .feedback-date-sort-container {
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: max-content;
        /* margin-bottom: 2em; */
        margin-bottom: -2em;
        margin-top: 1.5em;

    }
}

@media screen and (max-width: 320px) {
    .feedback-sub-cards-container {
        height: 600px;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(min-content, max-content);
        /* grid-template-columns: repeat(3, clamp(50px, 1fr, 100px)); */
        gap: 2em;
        padding: 0em 0.4em;
        /* justify-items: center; */
        /* align-items: center; */
    }
    .feedback-image-title-cont {
        width: fit-content;
        max-width: 500px;
        height: fit-content;
    }
    .feedback-date-sort-container {
        width: fit-content;
        min-height: auto;
        max-height: fit-content;
    }
    .feedback-sub-name-text {
        word-wrap: break-word;
        font-size: 75%;
        /* width: 60%; */
        letter-spacing: -0.02em;
        margin-bottom: 0em;
        /* text-overflow: ellipsis !important;  */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .feedback_card_picture {
        grid-area: image;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.8em;
        width: 65px;
        height: 65px;
        margin-left: -0.3em;
    }
    .feedback-sub-card-title {
        grid-area: matter;
        display: flex;
        flex-direction: column;
        /* position: relative; */
        gap: 0.6em;
        width: auto;
    }
    .received-on-date {
        height: 12%;
        font-size: 0.6em;

    }
    .received-on-text {
        height: 12%;
        font-size: 0.6em;
        /* text-align: justify; */
        /* or 22px */
    }
    .feedback-card-from-text {
        display: block;
        height: 12%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.7em;
        /* or 20px */
        color: rgba(0, 0, 0, 0.5);
    }
    .feedback-sub-kudos-image {
        align-self: right;
        width: 31px;
        height: 31px;
    border-radius: 50% !important;

    }

    .feedback-date-sort-container {
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        width: max-content;
        margin-bottom: -2em;
        margin-top: 1.5em;
    }
}

/* css media queries for mobile upto 416 to 782 width */



@media screen and (max-width: 321px) {
    .feedback-date-sort-container {
        width: 100%;
    }
}

.feedback-date-sort-card-icon {
margin-left: 0.24em;
margin-bottom: 0.29em;
}

.feedback-date-sort-card-icon:hover {
    fill: red;
}

/* css for custom date btn */

.active-icon {
    color: white;
}

.inactive-icon {
    color: #31D0AA;
}

/* load more btn css */

.load-more-title-feedback{
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #303030;
    margin-top: -1em;
    margin-bottom: 1.5em;
}
.load-more-title-feedback:hover{
    color:#4CCB1F;
}

.center-item {
    grid-column: 1 / -1;
}

@media screen and (max-width:780px) {
    .adjust-margin-feedback{
        margin-top: 30px;
    }
}

/* feedback action list css */

.feedback-action-list-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.action-card-main-container{
    padding: 1em;
    width: 33%;  
}

.action-card-border-container {
    border: 1px solid #8C8CA1;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 2em;
    padding: 1em 1.5em;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}

.column-section-action-reminder{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    overflow: hidden;
}

.column-section-action-reminder h1, .column-section-action-reminder h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedback-action-dp{
    width: 4.5em;
    height: 4.5em;
    min-width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
}

.feedback-action-dp img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width:1300px) {
    .action-card-main-container{
        width: 50%;
    }
}

@media screen and (max-width:960px) {
    .action-card-main-container{
        width: 100%;
    }
}