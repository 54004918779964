.thank-you-root{
    font-size: 16px !important;
}

.thank-you-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 0;
    height: 100vh;
    overflow: auto;
    padding:5em 5em !important;
}
.content-container{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}
.thank-you-title{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 4em;
    color: #0E0E2C;
    margin: 0;
}
.thank-you-sub-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5em;
    line-height: 2em;
    color: #0E0E2C;
    margin: 0;
}
.thank-you-paragraph{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.15em;
    line-height: 1.55em;
    color: #0E0E2C;
    text-align: left;
    margin: 0;
}
.bg-container{
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 31px 10px #7B589D29;
    width: 55% !important;
    /* max-height: 30rem; */
    padding: 0px 10px;
}
.thank-you-content-container{
    width: 38% !important;
}
.thank-you-bg-image{
    width: 70% !important;
    height: 65% !important;

}
.back-to-home-btn{
    margin-top: 2rem !important;
    border-radius: 8px;
    background-color: #4CCB1F;
    color: #fff;
    padding: 12px 24px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1em;
    line-height: 1.25em;
    text-align: center;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
}
.thank-you-logo-image{
    height: 6em;
    width: fit-content;
    max-width: 12.675em;
    object-position: center;
    object-fit: contain;
}

@media (max-width:960px) {
    .thank-you-root{
        font-size: 14px !important;
    } 
    .thank-you-bg-image{
        width: 100%;
        height: 100%;
    
    }
    .bg-container{
        width: 39% !important;
    }
    .thank-you-content-container{
        width: 59% !important;
    }
    .thank-you-title{
        font-size: 3em;
    }
    .thank-you-sub-title{
        font-size: 1.5em;
    }
    .thank-you-paragraph{
        font-size: 1em;
    }
}
@media (max-width:600px) {
    .thank-you-root{
        font-size: 12px !important;
    } 
    .bg-container{
        display: none !important;
    }
    .thank-you-content-container{
        width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .thank-you-container{
        background: rgba(176, 203, 31, 0.2);
    }
    .thank-you-paragraph{
        text-align: center;
    }
    .thank-you-title{
        font-size: 2.5em;
    }
    .thank-you-sub-title{
        font-size: 1.5em;
    }
    .thank-you-paragraph{
        font-size: 1em;
    }
    .button-div{
        justify-content: center !important;
        align-items: center !important;
    }
}
@media (max-width:480px) {
    .thank-you-root{
        font-size: 10px !important;
    } 
}