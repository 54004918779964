.comparison-container {
    margin-top: 1.5em;
    width: 100%;
    height: 100%;
}

.comparison-title {
    font-size: 1.4em;
}

.comparison-modal {
    width: 90% !important;
    max-width: 800px !important;
}

.comparison-btn {
    padding: 0em 1em;
    width: fit-content;
    height: 2.5em;
    background: #4CCB1F;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 1.15em;
    line-height: 1.25em;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.comparison-btn:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.comparison-btn:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #FFFFFF;
}

.comparison-secton {
    padding: 0rem 3em;
    margin-top: 1.25em;
}

.heading-section {
    padding: 0em 0.95em;
}

.comparison-left-section {
    width: 50%;
}

.comparison-right-section {
    width: 50%;
}

.survey-edit-section div {
    margin-bottom: 0px !important;
}

.survey-edit-section .ant-col-offset-2 {
    margin-left: 0px !important;
}

.question-title {
    margin-top: 1em;
}

.question-title p {
    margin-bottom: 0px !important;
}

.comparision-headingSectiion {
    display: flex;
    flex-direction: row;
}



/* new css */

.user-manual-root {
    font-family: "Poppins";
    font-size: 16px;
    padding: 0em 5em;
}

.user-manual-align-action-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.user-manual-request-btn,
.user-manual-request-btn:hover,
.user-manual-request-btn:focus {
    padding: .5em 1em;
    font-size: 1.15em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: fit-content;
    height: 2.5em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(13, 214, 113, 1);
    color: #fff;
    border-radius: .5em;
    border: 1px solid rgba(13, 214, 113, 1);
}

.user-manual-switch-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    border: 1px solid rgba(201, 208, 218, 1);
    border-radius: 0.5em;
    padding: .5em;
    width: fit-content;
}

.user-manual-switch-tab-text {
    padding: .5em;
    font-size: 1.15em;
    font-weight: 500;
    line-height: 1;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: 13.5em;
    height: 2.6em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-manual-switch-tab-selected {
    background: rgba(76, 203, 31, 1);
    color: #fff;
    border-radius: .5em;
    box-shadow: 11px 9px 10.899999618530273px -1px #4CCB1F4D;
}

.user-manual-main-body {
    width: 100%;
    border: 1px solid rgba(201, 208, 218, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 1em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.user-manual-banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    border: 1px solid rgba(13, 214, 113, 1);
    border-radius: 8px;
    background: linear-gradient(270deg, #FFFFFF 0%, #E4FFF1 100%);

}

.user-manual-label {
    font-size: 3em;
    font-weight: 700;
    line-height: 1.2em;
    text-align: left;
    font-family: "Poppins";
}

.user-manual-label-compare {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1em;
    text-align: left;
    font-family: "Poppins";
    /* word-break: break-all; */
}

.user-manual-crad-section {
    padding: 1.25em;
    background: rgba(246, 251, 255, 1);
    margin: 0.25em 1.5em;
    border-radius: 8px;
}

.um-question-section {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.um-question-section p {
    font-size: 1.35em;
    font-weight: 500;
    line-height: normal;
    color: rgba(38, 88, 63, 1);
}

.um-question-section p em {
    font-size: 0.65em !important;
    font-weight: 400;
    line-height: normal;
    margin-left: -2.5em;
    text-align: left;
    color: rgba(184, 137, 51, 1);
    display: none;
}

.display-block-um-emtag p em {
    display: block !important;
}
.um-response-section {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.um-response-list {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
}

.um-response-card {
    padding: 0px 1em;
    border-radius: 4px;
}

.um-response-label {
    margin: 0px;
    font-size: 1em !important;
    font-weight: 500 !important;
    color: rgba(31, 31, 31, 1);
}

.um-card-space-btw-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: space-between;
    align-items: start;
}

.um-survey-select {
    width: 50%;
}

.um-edit-icon {
    cursor: pointer;
    width: 1.5em;
}

.um-card-action-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.user-manual-action-btn,
.user-manual-action-btn:hover,
.user-manual-action-btn:focus {
    padding: .5em 1em;
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    width: fit-content;
    min-width: 6em;
    height: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
    margin: 0.5em;
}

.um-action-green,
.um-action-green:hover,
.um-action-green:focus {
    background: rgba(76, 203, 31, 1);
    border: 1px solid rgba(76, 203, 31, 1);
    color: #fff;
}

.um-action-dark,
.um-action-dark:hover,
.um-action-dark:focus {
    background: rgba(170, 186, 198, 1);
    border: 1px solid rgba(170, 186, 198, 1);
    color: #fff;
}

.um-compare-icon {
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
}

.um-compare-spacebtw {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    /* align-items: center; */
}


.um-employee-selector {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    text-transform: capitalize;
}

.um-employee-selector .ant-select-selector {
    border-radius: 7px !important;
    border: 1.5px solid #BFBFBF !important;
    text-align: left !important;
    cursor: pointer !important;
    height: 3em !important;
}

.um-employee-selector input {
    cursor: pointer !important;
    height: 100% !important;
}

.um-employee-selector .ant-select-selection-item,
.ant-select-selection-placeholder {
    padding: 0px 0.5em !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
    color: #595959 !important;
}

.um-employee-selector .ant-select-selection-item div {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user_manual_banner_icon {
    width: 15em;
}

.um-response-card-height {
    height: 20em;
    overflow: auto; 
}

.um-response-card-height::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    display: block; 
}

.um-response-card-height::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px
}

.um-response-card-height::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b1b0b0e1;
}

.um-active-response{
    background: rgba(76, 203, 31, 0.3);
}
 
@media screen and (max-width:1100px) {
    .user_manual_banner_icon {
        display: none;
    }

    .compare-banner {
        height: 10em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-manual-label-compare {
        text-align: center !important;
    }
    .user-manual-align-action-section{
        flex-direction: column;
        gap: 1em;
    }
}

@media screen and (max-width:1000px) {
    .user-manual-team-logo{
        display: none;
    }

    .user-manual-label{
        text-align: center !important;  
        font-size: 2.25em;
    }
    
    .user-manual-banner{
        justify-content: center;
    }

    .um-compare-spacebtw{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:800px) {
    .user-manual-switch-tab-text {
        width: 12em;
    }
}

@media screen and (max-width:600px) {
    .user-manual-switch-tab {
        width: 100%;
    }

    .user-manual-switch-tab-text {
        width: 100%;
    }
}

@media (max-width:1400px) {
    .user-manual-root {
        font-size: 14px;
        padding: 0em 3em;
    }
}

@media (max-width:960px) {
    .user-manual-root {
        font-size: 12px;
        padding: 0em 2em;
    }

    .comparison-left-section {
        width: 100%;
    }

    .comparison-right-section {
        width: 100%;
    }

    .comparison-secton {
        padding: 0em 2em;
    }

    .heading-section {
        padding: 0em 2em;
    }
}

@media (max-width:600px) {
    .user-manual-root {
        font-size: 10px;
        padding: 0em 0em;
    }

    .comparison-secton {
        padding: 0em 1em;
    }

    .heading-section {
        padding: 0em 1em;
    }
}