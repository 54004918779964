.rating-scale-parent {
  font-size: 16px;
}


.ratingScale-table .ant-table-thead {
  background: #31D0AA;
  border-radius: 0.5em;
}

.ratingScale-table .ant-table-thead .ant-table-cell {
  background: transparent !important;
  border: 1px solid #31D0AA;
  padding: 1.25em 3.4375em 1.25em 1.69em !important;

}

.ratingScale-table .ant-table-thead .ant-table-cell:first-child {

  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.ratingScale-table .ant-table-thead .ant-table-cell:last-child {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 1.25em 3.4375em 1.25em 5.69em !important;
  border-left: none;
}

.ratingScale-table .ant-table-thead .ant-table-cell:not(:first-child):not(:last-child) {
  padding: 1.25em 1.94em 1.25em 3.6875em !important;

}


.ratingScale-table .ant-table {
  background: #F4F9FD;
}


.ant-table table {
  border-spacing: 0em 1em;
}



.ratingScale-table th h4 {

  font-weight: 600;
  text-align: left;
  line-height: 1.37em;
  padding-left: 1.87em;
  padding-right: 1.25em;
  font-family: 'Poppins'
}




.ratingScale-table .ant-table-tbody>tr>td {
  padding: 0.8em 6em 0.8em 3.6875em !important;
}


.ratingScale-table th:first-child {
  border-top-left-radius: 0.68em !important;
  border-bottom-left-radius: 0.68em !important;
}

.ratingScale-table th:last-child {
  text-align: right;
  border-bottom-right-radius: 0.68em !important;
  border-top-right-radius:0.68em !important;
}


.ratingScale-table td {

  border-spacing: 2em;
  text-align: center;

}

.ratingScale-table td div {

  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.25em;
  color: #000000;
  font-family: 'Poppins'

}


.ratingScale-table tbody tr {
  background: #fff;
}

.ratingScale-table td:first-child {

  border-top-left-radius: 0.68em !important;
  border-bottom-left-radius: 0.68em !important;
}


.ratingScale-table td:last-child {
  text-align: center;

  border-bottom-right-radius: 0.68em !important;
  border-top-right-radius: 0.68em !important;
}


.create-rating-scale-row {
  position: relative;
  right: 1.56em;
}


.rating-scale-parent .ant-input {
  padding: 0.75em 0.75em 0.75em 1.56em;
  border-radius: 0.5em;
  border-color: #AABAC6;
}

.ratingScale-table .ant-table-tbody > tr > td{
  border-bottom: none;
}



.row-dragging-threshold {
  background: #fff !important;
  display: flex;
  position: relative;
  left: 5.12em;
  border-radius: 0.5em;
  justify-content: space-between;
  align-items: center !important;
}

.row-dragging-threshold .threshold-record{
  padding: 0.7em 0em 0.7em 0.7em;
}

.row-dragging-threshold .ant-table-tbody>tr>td{
  padding:  0.8em 6em 0.8em 3.6875em !important;
  
 
}


.row-dragging-threshold td div {
  font-weight: 400;
  padding-right: 3em;
  font-size: 1.1em;
  line-height: 1.25em;
  color: #000000;
  font-family: 'Poppins';
}


.threshold-button-panel {

  position: relative;
  left: 3.8em;
  column-gap: 0.93em;
  justify-content: space-evenly;

}

.delete-rating-scale-icon {
  width: 1.6em;
  left: 1.5em;
  position: relative;
}


.delete-rating-scale-icon,
.threshold-button-panel {
  position: relative;
  z-index: 1000;
}

.create-rating-scale-row .ant-input:placeholder-shown {
  font-size: 1em;
  color: #AABAC6;
}

.delete-button-threshold-record {
  position: relative;
  left: 3em;
  flex-wrap: nowrap;
  column-gap: 3.3em;
  justify-content: center;
}

.threshold-icon {
  width: 1.8em;
}

.threshold-record {
  padding: 0.7em 0em;
  text-align: left;
}

.create-rating-scale-row .ant-input:focus,
.create-rating-scale-row .ant-input-focused,
.create-rating-scale-row .ant-input:hover {
  border: 1px solid #AABAC6 !important;
}

.rd-custom-table .ant-empty-normal {
  text-align: center !important;
}

.threshold-form {
  display: none;
}


.invalid-threshold-input{
  text-align: left;
  width: 100%;
  font-family: poppins;
  position: relative;
  bottom:0.43em;
  font-size:0.9em;
  color: red;
}

.drag-visible{
  position: relative;
  left: 1.9em;
}

.drag-visible-create-row{
  position: relative;
  left: 0.5em;
}
