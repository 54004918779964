.learning-root{
    font-size: 16px;
    font-family: Poppins !important;
    width: 100%;
}
.learning-container{
    width: 100%;
    background-color: #fff;
    overflow: auto;
    border-radius: .5em;
    padding: 1.5em 3em;
    margin-top: 1.5em;
}
.learing-back-title{
    color: #000000;
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    margin: 0px;
}
.learing-main-title{
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: 3em;
    margin: 0;
}
.learing-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}
.learn-completed-progress{
    font-family: "Poppins";
    font-weight: 500;
    line-height: normal;
    color: #4CCB1F;
    font-size: 2em;
}
.learn-progress .ant-progress-bg{
    height: 1.8em !important;
    border-radius: 1.3em;
}
.learn-progress .ant-progress-inner{
    border-radius: 1.3em !important;
    background: #F6FBFF !important;
}
.landing-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; 
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 3em;
    margin-left: 0px;
    scroll-snap-type: x mandatory !important;
    width:100%
}
.landing-card-container::-webkit-scrollbar {
    height: 8px;
    display: block;
  }
  
  .landing-card-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px;
    display: flex;
  }

  
  .landing-card-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
.landing-card{
    scroll-snap-align: center;
    min-width: 20em !important;
    width: 20em !important;
    height: 29.5em;
    padding: 1.5em;
    border-radius: 1em;
    transition: transform 0.3s ease;
    position: relative;
    margin-left: 0px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
}
.landing-card:hover{
    z-index: 1100 !important;
    transform: scale(1.02);
}
.learn-start-button {
    background-color: #00d084;
    color: white;
    border-radius: 50%;
    padding: 1em 2em;
    height: 6em;
    width: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3em;
    right: -3em;
    font-size: 1em;
    opacity: 0;
    transition: ease-in-out .5s;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: 1em;
    cursor: pointer;
}

.landing-card:hover .learn-start-button {
    opacity: 1;
    transition: ease-in-out .5s;
}
.landing-card:hover .landing-card-counter{
    display: none;
}
.landing-card-title{
    font-family: 'Poppins' !important;
    line-height: normal;
    font-style: normal;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
}
.landing-card-description{
    margin-top: .5em;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    font-size: .9em;
    font-weight: 400;
    margin: 0;
    width: 16em;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.learn-card-icon{
    margin-top: -.5em;
    max-height: 9em;
}
.landing-card-counter{
    color: #8C8CA14A;
    font-family: Amita;
    font-size: 3.5em;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0 !important;
}
.learn-description{
    font-size: 1.15em;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    color: #212121;
    margin: 0;
}
.learn-active-button,.learn-active-button:hover,.learn-active-button:focus{
    border: 1px solid #4CCB1F;
    box-shadow: 0px 6px 2px -4px #0E0E2C1A;
    background: #4CCB1F;
    font-size: 1.25em;
    font-weight: 700;
    font-family: 'Poppins';
    color:#fff;
    text-transform: uppercase;
    border-radius: .5em;
    padding:.5em 2em;
    height: 2.5em;
    cursor: pointer;
    outline: none;
}
.learn-skip-btn,.learn-skip-btn:hover,.learn-skip-btn:focus{
    background: #AABAC6;
    color: #ffff;
    font-size: 1em;
    font-weight: 700;
    font-family: 'Poppins';
    border:none;
    border-radius: 2em;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    padding: .5em 2em;
}
@media (max-width:1520px) {
    .landing-card-container{
        justify-content: start !important;
    }
}
@media (max-width:1800px) {
    .learning-root{
        font-size: 14px;
    }
}
@media (max-width:1200px) {
    .learning-root{
        font-size: 12px;
    }
}
@media (max-width:960px) {
    .learning-root{
        font-size: 10px;
    }
}
@media (max-width:600px) {
    .learning-root{
        font-size: 8px;
    }
}