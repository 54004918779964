.main-container-row {
    margin-top: 15px;
}

.survey-section-container {
    margin-top: 5vh;
    overflow-y: auto;
    max-height: calc(90vh - 280px);
    padding-bottom: 3vh;
}

.survey-section-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0px;
    display: block !important;
}

.survey-section-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 95, 95, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.soul-survey-edit-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    color: #141414;
    text-align: center;
    margin-top: 0.5em;
}

.soul-survey-edit-head span {
    font-weight: 700;
}

.survey-index {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    color: #1F1F1F;
    margin-top: 10px !important;
}

.survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1.5px solid #BFBFBF;
    border-radius: 7px;
    width: 484px !important;
    height: 60px !important;
    cursor: pointer;
}

.survey-select-filed .ant-select-selection-search-input {
    width: 484px !important;
    height: 60px !important;
}

.survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-top: 14px;
}

.save-step-2-button {
    padding: 10px 30px;
    height: 60px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}

.save-step-2-button:hover {
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}

.save-step-2-button:focus {
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}

.soul-survey-go-back-button {
    padding: 10px 30px;
    height: 60px;
    background: #fff;
    border: 2px solid #8C8C8C;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
}

.soul-survey-go-back-button:hover {
    background: #fff;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.soul-survey-go-back-button:focus {
    background: #fff;
    border: 2px solid #8C8C8C;
    color: #8C8C8C;
}

.back-icon-survey {
    /* position: absolute; */
    /* left: 11px; */
    cursor: pointer !important;
    top: 15px !important;
    margin-left: 0.82em;
}

.enter-value-mobile-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #141414;
    display: none;
}

@media (max-width:1700px) {
    .soul-survey-edit-head {
        font-size: 28px;
        line-height: 44px;
    }

    .survey-index {
        font-size: 20px;
        line-height: 39px;
        margin-top: 4px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 364px !important;
        height: 43px !important;
    }

    .survey-select-filed .ant-select-selection-search-input {
        width: 364px !important;
        height: 43px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 16px;
        line-height: 22px;
        padding-top: 10px;
    }

    .save-step-2-button {
        height: 50px;
        font-size: 20px;
        line-height: 24px;
    }

    .soul-survey-go-back-button {
        height: 50px;
        font-size: 20px;
        line-height: 24px;
    }
}

@media (min-width:960px) {
    .survey-section-container-margin-left {
        margin-left: 249px;
        transition-duration: 1s;
        padding-right: 25em !important;
    }

    .custom-alignment {
        margin-left: -5em;
    }
}

@media (max-width:650px) {
    .soul-survey-edit-head {
        font-size: 28px;
        line-height: 44px;
    }

    .survey-section-container {
        margin-top: 3vh;
    }

    .survey-index {
        font-size: 20px;
        line-height: 24px;
        margin-top: 5px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        max-width: 280px !important;
        min-width: 100% !important;
        height: 40px !important;
    }

    .survey-select-filed .ant-select-selection-search-input {
        max-width: 280px !important;
        min-width: 100% !important;
        height: 38px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
    }
}

@media (max-width:480px) {
    .main-container-row {
        margin-top: 5px;
    }

    .survey-section-container {
        margin-top: 2vh;
    }

    .enter-value-mobile-head {
        display: block;
    }

    .back-icon-survey {
        top: -30px !important;
    }

    .soul-survey-edit-head {
        margin-top: 0.5em;
        font-size: 20px;
        line-height: 30px;
        max-width: 290px;
        width: 90%;
        /* padding:10px 20px ; */
        background: #F5F5F5;
        border-radius: 11px;

    }

    .survey-index {
        font-size: 13px;
        line-height: 20px;
        margin-top: 5px !important;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-width: 100% !important;
        height: 35px !important;
        border-radius: 3.5px;
    }

    .survey-select-filed .ant-select-selection-search-input {
        min-width: 100% !important;
        height: 30px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 13px;
        line-height: 20px;
        padding-top: 6px;
    }

    /* .save-step-2-button{
        height: 45px;
        width: 80% !important;
        font-size: 20px;
        line-height: 24px;
        border: 1.26414px solid #4CCB1F;
        border-radius: 93px;
        font-size: 16px;
        line-height: 24px
    } */
    .soul-survey-go-back-button {
        height: 45px;
        font-size: 20px;
        line-height: 24px;
        width: 80% !important;
        border: 1.26414px solid #BFBFBF;
        border-radius: 93px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 2em !important;
    }
}

@media (max-width:360px) {
    .soul-survey-edit-head {
        width: 80%;
    }

    .survey-select-filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-width: 100% !important;
        width: 210px !important;
        height: 35px !important;
        border-radius: 3.5px;
    }

    .survey-select-filed .ant-select-selection-search-input {
        min-width: 100% !important;
        width: 210px !important;
        height: 30px !important;
    }

    .survey-select-filed .ant-select-single .ant-select-selector .ant-select-selection-item {
        font-size: 13px;
        line-height: 20px;
        padding-top: 6px;
    }
}

.cs-col-section{
    width: 100%;
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cs-dropdown-employee{
    width: 30em !important;
    max-width: 90% !important;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-search-input{
    height: 100% !important;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-item{
    margin-top: 6px;
}

.cs-dropdown-employee .ant-select-selector .ant-select-selection-placeholder{
    margin-top: 6px;
}

.cs-dropdown-employee .ant-select-selector{
 
    height: 44px !important;
    border: 1.24587px solid #BFBFBF !important;
    border-radius: 5.81405px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 27px;
    text-align: left;
    color: #303030;
    border-color:#BFBFBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer !important;
  }

  .cs-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cs-action-button{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25em;
    line-height: normal;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    height: 3em !important;
    margin: 0.8em;
    width: 220px;
  }
  .cs-action-button:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }
  .cs-action-button:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
  }