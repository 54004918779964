.feedback-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.space-between-elements-feedback {
    margin: 20px auto;
}

.receipient-feedback-title:hover {
    color: #000000;
}

.feedback-badge-award-carousel .ant-carousel .slick-slide.slick-active {
    width: 230px !important;
}


.discarded-okr{
    color: rgb(190, 67, 67) !important;
}

.feedback-badge-award-carousel .ant-select-item-option-content {
    color: #000000;
}

.red-bordered .ant-select-selector {
    border: 1px solid red !important;
}

.normal-bordered .ant-select-selector {
    border: 1px solid #D9D9D9 !important;
}

.checkbox-panel-feedback .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4CCB1F !important;
    border-color: #4CCB1F !important;
}

.checkbox-panel-feedback .ant-checkbox-checked::after {
    border: 1px solid #4CCB1F !important;

}


.feedback-input-box-label {
    margin-bottom: 6px !important;
    /* margin-left: 20px !important; */
}

.receipient-feedback-title {
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.feedback-type-selector .ant-btn:hover, .feedback-type-selector
.ant-btn:focus {
    color: rgba(0, 0, 0, 0.85);
}

.feedback-selection-elements-wrapper {
    width: 80%;
    margin: auto;
}

.feedback-pop-up-header {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    left: 30px
}

.top-bottom-margin-feedback {
    margin-top: 20px;
    margin-bottom: 20px;
}

.given-kudos-container {
    padding: 6px;
    height: 40px;
    width: 50px;
    border: 1px solid #D9D9D9;
    border-color: #4CCB1F;
    border-radius: 8px;
}

.feedback-pop-up-container::-webkit-scrollbar {
    display: none;
}

.delete-given-kudos-button-give-feedback {
    position: relative;
    top: -29px;
    /* left: 57px; */
    background: white;
}

.delete-given-kudos-button-edit-feedback {
    position: relative;
    top: 12px;
    left: 40px;
    background: white;
}



.feedback-receipient-selector .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    border-radius: 10px;
}

.give-kudos-button:hover {
    color: #4CCB1F !important;
    border-color: #4CCB1F !important;
}

.feedback-receipient-selector .ant-select-selection-placeholder {
    color: #D9D9D9 !important;
    margin-top: 3px;
}

.feedback-receipient-selector .ant-select-selection-item {
    margin-top: 3px;
}

.okr-title-feedback {
    padding: 9px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    cursor: not-allowed;
    width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.feedback-user {
    font-size: 18px;
    font-weight: 500;
}

.feedback-pop-up-form-title {
    margin-bottom: 6px;
}

.feedback-pop-up-form-title p {
    margin-bottom: 0px;
}

.text-ellipsis-feedback {
    overflow: hidden;
    margin-bottom: 5px;
    white-space: nowrap;
    width: 280px;
    text-overflow: ellipsis;
}

.fw-500-feedback {
    font-weight: 500;
}

.fw-700-feedback {
    font-weight: 700;
}


.checkbox-panel-feedback {
    margin: 20px auto 10px auto;
    width: 100%;

}

.give-kudos-button {
    height: 45px;
    width: 140px;
    color: #4CCB1F;
    border-color: #4CCB1F;
    border-radius: 8px;
}

/* .meeting-checkbox-selector {
    margin-left: 50px;
} */

.okr-checkbox-selector {
    position: relative;
    right: 20px;
    left: 20px;
}

.feedback-wrapper::-webkit-scrollbar {
    color: red;
    margin-right: 10px;
    width: 5px;
    padding-right: 10px;
}

.feedback-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    padding-right: 10px;
    color: red;
}

.feedback-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    color: red;
    margin-right: 10px;
    padding-right: 10px;
    background-color: #D9D9D9;
}


/* give and edit feedback pop up styles */


.feedback-pop-up-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 6px;
    color: #000000;
}

.feedback-pop-up-ck-editor .cke_top {
    border-bottom: none;
    background: #fff !important;
    padding: 0px 0px 0px !important;
    white-space: normal;
}

.feedback-pop-up-ck-editor .cke_chrome {
    border: none;
    padding: 0 !important;
}

.feedback-pop-up-ck-editor .cke_bottom {
    display: none;
}

.feedback-pop-up-ck-editor .cke_reset {
    padding: 0px 0px;
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar {
    height: 100%;
    width: 5px;
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow: inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px
}

.feedback-pop-up-ck-editor .cke_editable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D9D9D9;
}

.feedback-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    line-height: 140%;
    text-align: left;
    margin-bottom: 10px;
    color: #141414;
}

.feedbaack-give-kudos-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #595959 !important;
}

.feedback-okr-selection-drop {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    max-width: 500px !important;
    line-height: 18px;
    color: #595959;
}

.feedback-input-field-style input {
    height: 40px;
    width: 100px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
}


.feedback-tab-btn-focus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn-focus:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn-focus:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-tab-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #141414;
    border-radius: 8px;
    border: 1px solid #4CCB1F;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-tab-btn-active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
    border-radius: 8px;
    max-width: 33px;
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    text-align: center;
}

.feedback-tab-btn-active:hover {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}

.feedback-tab-btn-active:focus {
    border: 1px solid #4CCB1F;
    background: #4CCB1F;
    color: #fff;
}


.feedback-save-btn-modal {
    padding: 7px 24px;
    height: 40px;
    width: 108px;
    background: #4CCB1F;
    /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
    margin-top: 10px;
}

.feedback-save-btn-modal:hover {
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}

.feedback-save-btn-modal:focus {
    background: #4CCB1F;
    color: #FFFFFF;
    border: 1px solid #4CCB1F;
}



.feedback-pop-up-ck-editor {
    padding-right: 20px;
    border-radius: 8px;
    width: 100%;
}

.normal-border{
    border: 1px solid #D9D9D9;
    padding-left: 2px;
}

.focused-border{
    border: 1px solid #4CCB1F;
}

.feedback-cancel-btn-modal {
    padding: 7px 22px;
    height: 40px;
    width: 105px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
    border-radius: 8px;
    margin-top: 10px;
}

.feedback-cancel-btn-modal:hover {
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}

.feedback-cancel-btn-modal:focus {
    color: #8C8C8C;
    background-color: #fff;
    border: 1px solid #8C8C8C;
}


.close-modal-feedback {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding-right: 30px;
}

.feedback-popup-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #141414;
}

.feedback-popup-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}

.feedback-type-container {
    flex-wrap: nowrap;
}



@media (max-width:500px) {

    .view-feedback-pop-up-header {
        align-items: center;
    }



    .feedback-pop-up-header {
        left: 150px;
    }

    .give-kudos-wrapper {
        margin-top: 20px;
    }

    .feedback-type-container {
        flex-wrap: wrap;
    }

    .user-info-view-feedback-popup {
        margin-top: 20px;
    }

    .close-modal-feedback {
        top: -45px;
        right: -14px;
    }

    .feedback-selection-elements-wrapper {
        width: 52%;
    }

}
.feedback-type-switcher{
    width:100%;
    display:flex;
    flex-direction:row;
    border-radius:.5em;
    height:4em;
    background: rgba(244, 244, 244, 1);
}
.feedback-type-content-container{
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1em;
    cursor:pointer;
    border: 2px solid transparent
}
.feedback-type-content-container:hover {
    border: 2px solid rgba(134, 216, 80, 1);
    border-radius:.5em;
}
.feedback-type-content-container-active{
    background: linear-gradient(274.71deg, #FFFFFF -35.42%, #CCFF41 -0.39%, #29BF00 17.24%, #23A400 34.11%);
    /* border: 2px solid;
    border-image-source: linear-gradient(274.71deg, #FFFFFF -35.42%, #CCFF41 -0.39%, #29BF00 17.24%, #23A400 34.11%); */
    border-radius:.5em;
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1em;
    cursor:pointer
}
.feedback-type-content-container-active h4{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin:0px;
    color:rgba(255, 255, 255, 1);
}
.feedback-type-content-container h4{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin:0px;
    color:rgba(89, 89, 89, 1);
}


@media (max-width:1600px) {

    .feedback-pop-up-form-title {
        font-size: 0.9em;
    }

    .feedback-popup-head {
        font-size: 20px;
    }


    .one-on-one-meeting-popup-para {
        font-size: 14px;
    }

    .feedback-form-title {
        font-size: 14px;
    }

}