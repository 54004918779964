.scape-report-container{
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: nowrap;
    width: 100%;
    height: 700px;
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
}

.scape-report-svg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    /* background-color: green; */
}

.scape-active-btn, .scape-active-btn:hover, .scape-active-btn:focus, .scape-active-btn:active{
    width: 3.6em;
    height: 3.6em;
    cursor: pointer;
    border-radius: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
}

.scape-inactive-btn, .scape-inactive-btn:hover, .scape-inactive-btn:focus, .scape-inactive-btn:active{
    width: 3.6em;
    height: 3.6em;
    border-radius: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    background-color: #ECF1F4;
    border: 1px solid #ECF1F4;
}

.scape-active-btn img, .scape-inactive-btn img{
    width: 85%;
    height: 85%;
    object-fit: contain;
}