 
.org-profile-view-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center; 
    width: fit-content;
}

.org-profile-img-container{
    width: 6em;
    height: 6em; 
}

.org-profile-img-container img{ 
    width: 100%;
    height: 100%; 
    border-radius: 50%;
    object-fit: cover;
}

.org-detail-admin-profile-main-container{
    padding: 0.5em;
    width: 33%;
}

.org-detail-admin-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    padding: 0.5em 1em;  
    align-items: center; 
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 6px 6px 4px 0px rgba(213, 229, 242, 0.5);
    width: 100%; 
}

.admin-profile-img-container{
    width: 5em;
    height: 5em; 
    min-width: 5em;
    min-height: 5em;
}

.admin-profile-img-container img{ 
    width: 100%;
    height: 100%; 
    border-radius: 50%;
    object-fit: cover;
}

.org-admin-profile-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1.25em 0em; 
}

.org-admin-info-section{ 
    overflow: hidden; 
}

.org-admin-info-section h4{
    font-family: 'Poppins'; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

@media screen and (max-width:1250px) {
    .org-detail-admin-profile-main-container{
        width: 50%;
    }
}

@media screen and (max-width:750px) {
    .org-detail-admin-profile-main-container{
        width: 100%;
        padding: 0.5em 0em;
    }
}