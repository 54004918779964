
  .ant-form-item-control-input-content{
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-popover-inner{
    margin-bottom: 5px;
  }

  .ant-popover-arrow{
    margin-bottom: 5px;
  }


  .ant-popover-inner-content{
    padding:0px !important;
  }
  