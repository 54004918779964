.green-action-btn,  .green-action-btn:focus, .green-action-btn:active, .green-action-btn:hover{
    border-radius: 0.375em;
    border: 2px solid #4CCB1F;
    background: #4CCB1F;
    display: inline-flex;
    padding:1em 1.625em;
    justify-content: center;
    align-items: center;
    color:  #FAFAFA !important;
    font-family: Poppins;
    font-size: 1.4375em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
}

.grey-action-btn,  .grey-action-btn:focus, .grey-action-btn:active, .grey-action-btn:hover{
    border-radius: 0.375em;
    border: 2px solid #8C8C8C;
    background: #FFF;
    display: inline-flex;
    padding:1em 1.625em;
    justify-content: center;
    align-items: center;
    color:  #8C8C8C !important;
    font-family: Poppins;
    font-size: 1.4375em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
}

.in-edit-icon{
    cursor: pointer;
    max-width: 30px;
    max-height: 30px;
    margin-left: 10px;
}

.in-textarea{
    resize: none;
    height: 250px !important;
    border-radius: 1.375em !important;
    border: 1px solid rgba(90, 81, 81, 0.25) !important;
    background: transparent !important;
    color: #1F1F1F !important;
    font-family: Poppins !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: normal;
    width: 100%;
    padding: 10px;
    outline: none;
}

.row-btn-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.green-action-btn:disabled{
    color:  #8C8C8C !important;
}

.in-compare-section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
}
.impact-narrative-spin{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    position: fixed !important;
}

.impact-narrative-spin .ant-spin-text{
    width: 60% !important;
    font-size: 20px;
    font-weight: 500;
    color: black;

}

.report-tool-impact-container{
    display: flex;
    flex-direction: column;
    gap:16px;
    width: 100%;
    padding: 1px 20px;
}

.impact-tool-head{
    color: #141414;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    text-align: left;
}

.impact-tool-content{
    color: #1F1F1F;
    text-align: left;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0px;
}

.impact-tool-survey-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.impact-tool-res-chip{
    border-radius: 8px;
    border: 1px solid #8C8C8C;
    padding:8px ;
    margin-bottom:10px;
}

@media screen and (max-width:960px)  {
    .in-edit-icon{
        width: 25.83px;
        height: 25.83px ;
    }
}


@media screen and (max-width:600px)  {
    .in-edit-icon{
        width: 20.83px;
        height: 20.83px ;
    }
    .in-compare-section{
        flex-direction: column;
    }
}

@media screen and (min-width:960px)  {
    .impact-narrative-spin-left-align{
        left: 170px !important;
    }
    
    .impact-narrative-spin-left-align .ant-spin-dot-spin{
        margin-left: 150px !important;
    }
}


.generate-btn{
    border-radius: 0.375em; 
    background: #4CCB1F; 
    width: fit-content;
    padding:0.5em 1em; 
    text-align: center;
    color:  #FAFAFA !important;
    font-family: Poppins;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.00719em;
    margin-top: 3em;
    cursor: pointer;
}

.generate-btn-disabled{
    color:  #8C8C8C !important;
    background: #e1e1e1 !important;
    cursor: not-allowed;
}