.rd-root{
    font-size: 16px;
    font-family: Poppins;
}

.rd-root-style{
    background-color: #FFFFFF;
    border-radius: 1em;
    overflow: hidden;
    padding-bottom: 1.5em;
    min-height: 80vh;
}

.page-main-title{
    color:#262626;
    text-align: center;   
    font-size: 2.25em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
    font-family: Poppins;
    margin-top: 0.35em;
    margin-bottom: -0.05em;
}

.rd-container{
    /* height: calc(100vh - 150px); */
    width: 100%;
    /* overflow: auto; */
}

.rd-sidebar-container{
    width: 100%;
}

.rd-sidebar-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}


.rd-sidebar-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
    display: flex;
    flex-direction: row;
    gap: 1.5em;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.sidebar-child-tab{
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    flex-wrap: nowrap;
    background: #F4F9FD;
    cursor: pointer;
    border-radius: 0.75rem;
    margin: 1em;
    padding: 0.75em;
    box-shadow: 0px 4px 15px 0px rgba(170, 186, 198, 0.25);
}


.rd-main-sidebar-expand{
    background: #F4F9FD;
    border-radius: 1em;
    box-shadow: 0px 4px 15px 0px rgba(170, 186, 198, 0.25);
    height: 10.3em;
    transition-duration: 0.5s;
}

.rd-sd-down{
    transition-duration: 0.75s;
}

.rd-sd-up{
    transition-duration: 0.75s;
    transform: rotate(180deg);
}



.rd-sidebar-label{
    color: #8998A2;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}

.rd-active-sidebar{
    border-bottom: 5px solid #4CCB1F;
}

.rd-active-sidebar-label{
    color: #4CCB1F;
}

.rd-router-outlet{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 5em;
}

.rd-main-content{
    border-radius: 1em;
    background: #F4F9FD;
    padding: 3em;
    margin-top: 2rem;
}


.rd-delete-icon{
    width: 1.6em;
    height: 1.8em;
    cursor: pointer;
}

.rd-modal .ant-modal-content{
    background: #F4F9FD;
}

.rd-confirmation-dialog{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1em;
}

.rd-confirmation-message{
    color: #000;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    width: 90%;
    text-align: center;
    font-family: Poppins;
}

.rd-confirmation-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.rd-align-center{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}
.rd-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1em;
}
.rd-text-input, .rd-text-input:hover, .rd-text-input:focus, .rd-text-input:focus-visible, .rd-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.rd-text-area, .rd-text-area:hover, .rd-text-areat:focus, .rd-text-area:focus-visible, .rd-text-area:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 12.25em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
    resize: none;
}
.rd-select-filed .ant-select-selector,.rd-select-filed .ant-select-selector:hover,.rd-select-filed .ant-select-selector:focus,.rd-select-filed .ant-select-selector:focus-visible,.rd-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.rd-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.rd-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.rd-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.rd-add-edit-competency-container .ant-select-dropdown{
    box-shadow: none !important;
}
.rd-add-edit-competency-container .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    /* border: 1px solid #ffffff !important; */
}
.rd-select-option.ant-select-item{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
}
.rd-select-option.ant-select-item p{
    margin: 0px;
}
.rd-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.rd-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.rd-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.rd-multi-select .ant-select-selector .ant-select-selection-item{
    height: 2em !important;
    background: #ffff !important;
    color: #000;
    font-family: 'Poppins';
    font-size: 1em !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1em;
    padding: .375em .5em !important;
    text-align: center;
    /* border: none !important; */
    margin-top: .25em !important;
}
.rd-input-star{
    color: #E86161;
    text-align: center;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em; 
    padding-top: 10px !important;
}
.rd-input-title{
    color: #262626;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0;
}

/* table design */
.rd-table-container{
    margin-top: 2em;
    width: 100%;
    overflow: auto;
}

.rd-table{
    min-width: 350px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1.25em;
}

.rd-row-header{
    width: 100%;
    background: #31D0AA;
}

.rd-row-header th{
    padding: 1.25em 0px;
    background: #31D0AA;
}

.rd-table-row-padding-left{
    padding-left: 2em !important;
}

.rd-table-row-padding-rigth {
    padding-right: 2em !important;
}

.rd-content-center{
    text-align: center;
}

.rd-content-right{
    text-align: right;
}

.rd-row-body{
    background: #FCFCFC;
    width: 100%;
    padding: 1.25em 3.4375em 1.25em 3.6875em;
}

.rd-row-body td{
    padding: 1.25em 0px;
}

.td-center-padding{
    padding-left: 1.6rem !important;
}

.rd-table-header-label{
    color: #FFF;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.35em !important;
    font-family: "Poppins";
    margin: 0px 0.5em;
    white-space: nowrap;
}

.rd-table-body-label{
    color:  #1F1F1F;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.275em;
    margin: 0px 0.5em;
}

.rd-left-radious{
  border-top-left-radius: 0.5em !important;
  border-bottom-left-radius: 0.5em !important;
}

.rd-right-radious{
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.rd-table-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
}

.rd-table-actions-right{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    gap: 1.5em;
}

.rd-add-button{
    height:2.75em;
    border-radius: 0.5em;
    background: #4CCB1F;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height:  1.25em;
    color: #fff;
    border: 1px solid #4CCB1F;
    outline: none;
    white-space: nowrap;
    /* margin-top: 0.5em; */
}

.rd-dotted-button, .rd-dotted-button:focus , .rd-dotted-button:hover{
    height:2.75em;
    border-radius: 0.5em;
    background: #fff;
    padding: 0.25em 0.938em;
    text-align: center;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height:  1.25em;
    color: #4CCB1F;
    border: 2px dashed #4CCB1F !important;
    outline: none;
    white-space: nowrap;
    /* margin-top: 0.5em; */
}

.rd-nodata{
    width: 100%;
    border-radius: 0.5em ;
    background-color: #fff;
    min-height: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 350px;
}

.rd-nodata-label{
    font-size: 1.5em;
}

.rd-custom-form-table{
    width: 100%;
    min-width: 900px;
}

.rd-form-table-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1.25em 4.2375em 1.25em 4.2375em;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 1em 1em 0px 0px;
    background: #31D0AA;
}

.rd-form-table-body{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0.8em;
    border-radius: 0px 0px 1em 1em;
    gap: 0.5em;
}

.rd-form-table-content{
    border-radius: 1em;
    background: #F4F9FD;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1.25em 3.4375em 1.25em 3.4375em;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.competency-popup-title{
    color: #000;
    font-family: Poppins;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
}

.rd-chiplist-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:0.75em;
    width: 100%;
    margin-top: 0.75em;
}

.rd-chip{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    border-radius: 0.75rem;
    border: 1px solid #AABAC6;
    background: #FFF;
    height: 2.8125rem;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 1em;
}

.rd-chip-label{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chip-select .ant-select-selection-item{
    display: none;
    
}

.rd-modal-role .ant-modal-content{
    background: #FFFFFF;
}

.rd-pagination{
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.rd-pagination:hover{
    text-decoration: underline;
    color: #4CCB1F;
}

.mobile-sidebar-menu{
    cursor: pointer;
    width: 3em;
    height: 3em;
    display: none;
    margin-top: 1em;
}

.rd-th-label-select-table{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5625em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375em;
    margin: 0px
}

.rd-table thead{
    background: #31D0AA;
}

.mobile-sidebar-overlay{
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: transparent;
    position: fixed;
    z-index: 1;
}

.role-no-permission-container{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expand-dis{
    height: 100%;
    overflow: auto;
}

@media screen and (max-width:1600px) {
    .rd-root{
        font-size: 14px;
    }

}

@media screen and (max-width:1200px) {
   
}

@media screen and (max-width:1000px) {
    .mobile-sidebar-menu{
        display: block;
    }
    .rd-root{
        font-size: 12px;
    }
    .show-rd-side-bar{
        max-width: 17.5em !important;
        min-width: 17.5em !important;
        transition-duration: 0.25s;
        background: #FFF;
        position: fixed;
        z-index: 1;
        padding-right: 0.5em;
        overflow: auto !important;
        top: 76px;
        bottom: 0px;
    }
    
    .hide-rd-side-bar{
        max-width: 4em !important;
        min-width: 4em !important;
        position: fixed;
        z-index: 1;
        transition-duration: 0.25s;
        overflow: auto !important;
        background: #FFF;
        top: 76px;
        bottom: 0px;
    }
    
  

    .rd-router-outlet{
        padding: 0px 1em 0em 1em;
    }

}

@media screen and (max-width:850px) {
    .rd-root{
        font-size: 10px;
    }

    .rd-main-content{
        padding: 1.5em;
    }

    .rd-th-label-select-table{
        font-size: 1.25em;
    }
}

@media screen and (max-width:450px) {
    
    .rd-main-content{
        padding: 1em;
    }

    .rd-root .page-main-title{
        font-size: 2em;
    }
}

.rd-menu-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0.75em 0.5em;
}

.rd-main-menu{
    background: #FFFFFF;
    box-shadow: 1px 3px 4.900000095367432px 4px #AABAC64D;
    border-radius: 0.625em;
    padding: 0.5em 0em;
    margin-top: 0.25em;
}

.tab-exp-arrow{
    width: 1em;
}