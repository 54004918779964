.slide-tab-container{
    background-color: transparent;
    margin-top: 49px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 9.08px;
    overflow: auto;
    width: 100%;
    padding: 0px;
}
.slide-tab-container::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
}
.slide-tab-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.slide-tab-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}

.slide-btn{
    background: #F0F0F0;
    border: 1.26414px solid #F0F0F0;
    border-radius: 5px;
    height: 37px;
    padding: 6.32069px 12.6414px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #8C8C8C;
    cursor: pointer;
    margin-bottom: 5px;
}

.slide-btn:hover{
    background-color: #F0F0F0; 
    color: #8C8C8C;
    border: 1.26414px solid #F0F0F0;
}

.slide-btn:focus{
    background-color: #F0F0F0; 
    color: #8C8C8C;
    border: 1.26414px solid #F0F0F0;
}

.active-slide-btn{
    background: #009AF1;
    color: #FFFFFF;
}

.active-slide-btn:focus{
    background: #009AF1 !important;
    color: #FFFFFF !important;
    border: 1.26414px solid #F0F0F0;
}

.mobile-impact-card{
    margin-top: 40px;
    border: 1.92233px solid #009AF1;
    width: 100%;
    
    position: relative;
    /* height: fit-content; */
    border-bottom-left-radius: 13.4563px;
    border-bottom-right-radius: 13.4563px;
    /* border-radius:13.4563px ; */
    box-sizing: border-box;
    /* padding: 0px 21px 0px 21px; */
    min-height: 100px;
    
}

.impact-text-container{
    position: absolute;
    background: #fff;
    top: -9px;
    right: 15px;
    left: 15px;
    padding: 0px 6px;
    /* width: 100%; */
    
}
.hidden{
    visibility: hidden;
    padding: 0px 21px 0px 21px;
}

.impact-text-container,.hidden *{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    color: #1F1F1F;
}

.user-compare-tab{
    max-width: 300px;
    width: 100%;
    margin-top: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-tab-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #BFBFBF;
    width: fit-content;
    /* background-color: aqua; */
}

.act-user-tab-label{
    color: #4CCB1F !important;
    text-decoration: underline;
}

@media screen and (max-width:320px)  {
    .header-border-label{
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .tab-btn{
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        height: 27px;
        padding: 3.5px ;
    }
    .slide-btn{
        border-radius: 5px;
        height: 35px;
        padding: 4.32069px 10.6414px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
    }
}