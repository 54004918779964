.main-container {
    margin-top: 1.7em;
}

.back-icon{
    top: 25px !important;
    left: 20px;
    cursor: pointer;
    height: 100%;
    margin-left: 15px;
}
.bread-crubs{
    position: fixed;
    top: 24px;
    left: 50%;
    right: 50%;
    z-index: 1000;
}
.image-section-mobile{
    display: none !important;
}
.soul-item-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 52px;
    color: #141414;
    margin: 0;
}
.soul-detail-content-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 82%;
    margin: auto;

    /* Gray 2 */

    color: #1F1F1F;
}
.soul-item-bg{
    max-width: 1179px;
    height: 328px;
    width:90%;
    height:100%;
}
.line{
    width:100px;
    height:1px;
    background:
    repeating-linear-gradient(90deg,#BFBFBF 0 3px,#0000 0 7px)
}
.take-test-btn{
    padding: 10px 50px;
    height: 64px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FAFAFA;
    cursor: pointer;
}
.take-test-btn:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.take-test-btn:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.survey-behavior-btn-non-active{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
    padding: 10px 20px;
    height: 64px;
    border-radius: 6px;
    cursor: pointer;
}
.survey-behavior-btn-non-active:hover{
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
}
.survey-behavior-btn-non-active:focus{
    color: #D9D9D9;
    background: #fff;
    border: 2px solid #D9D9D9;
}

.edit-test-survey-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #4CCB1F;
    padding: 10px 20px;
    height: 64px;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #4CCB1F;
    cursor: pointer;
    padding: 10px 50p;
}
.edit-test-survey-btn:hover{
    color: #4CCB1F;
    background: #fff;
    border: 2px solid #4CCB1F;
}
.edit-test-survey-btn:focus{
    color: #4CCB1F;
    background: #fff;
    border: 2px solid #4CCB1F;
}
.survey-behavior-btn-active{
    height: 64px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
    cursor: pointer;
}
.survey-behavior-btn-active:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FFFFFF;
}
.survey-behavior-btn-active:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FFFFFF;
}
.step-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1F1F1F;
}
.step-2-go-test{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1F1F1F;
    margin-top: 20px;
}
.note-modal-container{
    padding: 10px 50px;
}
.soul-modal-bottom-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146.5%;
    color: #8C8C8C;
    text-align: center;
    margin-top: 20px;
}
.note-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #141414;
}
.note-number{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 146.5%;
    text-align: center;
    color: #8C8C8C;
}
.note-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 146.5%;
    color: #000000;
}
.modal-button-for-soul-survey{
    width: 158px;
    height: 51px;
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    letter-spacing: 0.005em;
    color: #FAFAFA;
}
.modal-button-for-soul-survey:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
.modal-button-for-soul-survey:focus{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #FAFAFA;
}
@media (max-width:991px) {
    .bread-crubs{
        display: none !important;
    }
    .soul-item-bg{
        max-width: 100%;
        width:100%;
        height:100%
    }
}

.selected-tab{
    margin: 0;
    background: #E1FFD7;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    color: #6BB96A;
}
.selected-tab-non-selected{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    color: #8C8C8C;
    margin: 0;
    padding-top: 10px !important;
    padding-left: 5px;
    padding-right: 5px;
}
.tab{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8C8C8C;
}
.soul-head{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 36px;
    color: #141414;
}
.soul-sub-head{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
}
.home-icon{
    height: 50px;
}
.image-container-soul{
    box-shadow: 0px -27px 50px -4px rgba(0, 0, 0, 0.09);
    border-radius: 28px;
    position: relative;
    height: 500px;
    margin-top: 30px;
    cursor: pointer;
}
.image-pettal-soul{
    width: 300px !important;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    /* filter: grayscale(100%); */
}
.image-active{
    display: none;
}
.image-inactive{
    display: block;
}
.image-title-section{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:10px;
    background: #FFFFFF;
    text-align: center;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14);
}
.home-component-card .card-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 44px;
    color: #141414;
    padding-bottom: 20px;
}
.start-btn{
    position: absolute;
    bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #4CCB1F;
    cursor: pointer;
}
.start-icon{
    padding-left: 3px;
}
.start-btn:hover .start-icon{
    padding-left: 10px;
}
.edit-btn{
    position: absolute;
    bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #8C8C8C;
    cursor: pointer;
}
.edit-icon{
    padding-left: 3px;
}
.edit-btn:hover .edit-icon{
    padding-left: 10px;
}
.number-title{
    margin-top: -20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 116%;
    text-align: center;
    color: #D9D9D9;
}
.number-container{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #8C8C8C;
    border: 1px solid #BFBFBF;
    padding: 5px 10px;
    border-radius: 50%;
}
.completed-numberContainer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    padding: 6px 10px;
    border-radius: 50%;
}
.completed-numberContainer-first{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 28px; */
    /* letter-spacing: -0.01em; */
    color: #4CCB1F;
    border: 1px solid #4CCB1F;
    padding: 5px 11px;
    border-radius: 50%;
}
.number-first{
    /* padding: 5px 12px; */
}
.stepper-div{
    margin: 40px 0px;
}
.tick-icon{
    position: absolute;
    right: -20px;
    top:-20px;
    width: 50px;
}
.forMobile{
    display: none !important;
}
.image-title-section-for-mobile{
    display: none;
}
.impact-narative-warning-popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5em 1em 1em 1em;
}
.impact-narative-warning-title{
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    font-style: normal;

}
.impact-narative-warning-content{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    text-align: center;

}
.impact-narative-warning-btn{
    padding: 5px 10px;
    min-width: 100px;
    width: fit-content;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    color: #fff;
    background-color: #4CCB1F;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
}
.impact-narative-warning-cancel-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #595959;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    outline: none;
    border: 1px solid #595959;
    background-color: #fff;
    min-width: 100px;
    width: fit-content;
    margin-top: 10px;
}
@media (min-width:1700px) {
    .soul-head{
        font-size: 41px;
        line-height: 52px;
    }
    .soul-sub-head{
        font-size: 23px;
        line-height: 34px;
    }
    .home-icon{
        height: 75px;
    }
}
@media (max-width:1770px){
    .image-pettal-soul{
        width: 230px !important;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    .card-section{
        margin: 30px 0px;
    }
    .image-container-soul{
        border-radius: 25px;
        position: relative;
        height: 400px;
    }
    .image-title-section{
        height: 130px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .home-component-card .card-title{
        font-size: 24px;
        line-height: 34px;
    }
    .number-title{
        font-size: 20px;
    }
    .start-btn{
        font-size: 14px;
    }
    .edit-btn{
        font-size: 14px;
    }
    .take-test-btn{
        height: 54px;
        padding: 8px 40px;
        font-size: 22px;
    }
    .survey-behavior-btn-non-active{
        height: 54px;
        padding: 8px 20px;
        font-size: 22px;
    }
    .edit-test-survey-btn{
        height: 54px;
        padding: 8px 40px;
        font-size: 22px;
    }
    .survey-behavior-btn-active{
        height: 54px;
        padding: 8px 20px;
        font-size: 22px;
    }
    .step-title{
        font-size: 18px;
    }
    .step-2-go-test{
        font-size: 18px;
    }
    .note-head{
        font-size: 18px;
    }
    .note-number{
        font-weight: 400;
        font-size: 16px;
       
    }
    .note-content{
        font-size: 14px;
    }
    .note-modal-container{
        padding: 10px 30px;
    }
    .modal-button-for-soul-survey{
        height: 40px !important;
        font-size: 16px;
        width: 130px;
    }
    .soul-modal-bottom-text{
        font-size: 10px;
    }
    .soul-item-bg{
        width:100%;
        height: 100%;
        max-height:280px;
    }
    .selected-tab{
        font-size: 18px;
        line-height: 24px;
        padding: 6px 10px;
    }
    .selected-tab-non-selected{
        font-size: 18px;
        line-height: 24px;
        padding-top: 7px !important;
    }
} 
@media (max-width:1440px) {
    .stepper-div{
        margin: 30px 0px;
    }
    .soul-item-bg{
        max-width: 1000px !important;
        width:100%;
        height: 100%;
        max-height:280px;
    }
    .image-pettal-soul{
        width: 210px !important;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
    }
    .card-section{
        margin: 20px 0px;
    }
    .image-container-soul{
        border-radius: 23px;
        position: relative;
        height: 345px;
    }
    .image-title-section{
        height: 100px;
        border-bottom-left-radius: 23px;
        border-bottom-right-radius: 23px;
        margin-bottom: -12px;
    }
    .home-component-card .card-title{
        font-size: 18px;
        line-height: 23px;
        margin-left: -21px;
    }
    .number-title{
        font-size: 17px;
        margin-left: 10px;
    }
    .start-btn{
        font-size: 13px;
    }
    .edit-btn{
        font-size: 13px;
    }
    .edit-icon{
        height: 12px;
        margin-bottom: 3px;
    }
    .number-container{
        font-size: 14px;
        padding: 5px 8px !important;
    }
    .number-first{
        padding: 5px 10px;
    }
    .completed-numberContainer-first{
        font-size: 14px;
        padding: 5px 9px;
    }
    .completed-numberContainer{
        font-size: 14px;
        padding: 5px 8px;
    }
    .tick-icon{
        position: absolute;
        right: -15px;
        top:-15px;
        width: 40px;
    }
    .soul-item-title{
        font-size: 25px !important;
    }
    .soul-detail-content-text{
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
    }
    .take-test-btn{
        height: 48px;
        padding: 5px 40px;
        font-size: 18px;
    }
    .survey-behavior-btn-non-active{
        height: 48px;
        padding: 5px 20px;
        font-size: 18px;
    }
    .edit-test-survey-btn{
        height: 48px;
        padding: 5px 40px;
        font-size: 18px;
    }
    .survey-behavior-btn-active{
        height: 48px;
        padding: 5px 20px;
        font-size: 20px;
    }
    .step-title{
        font-size: 16px;
    }
    .step-2-go-test{
        font-size: 16px;
    }
    .note-head{
        font-size: 18px;
    }
    .note-number{
        font-weight: 400;
        font-size: 14px;
       
    }
    .note-modal-container{
        padding: 10px 30px;
    }
    .note-content{
        font-size: 13px;
        line-height: 140.5%;
    }
    .selected-tab{
        font-size: 16px;
        padding: 7px 10px;
    }
    .selected-tab-non-selected{
        font-size: 16px;
    }
}

@media (max-width:600px) {
    .image-pettal-soul{
        width: 200px !important;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        margin-top: -10px !important;
        border-radius: 22px !important;
    }
    .image-container-soul{
        border-radius: 22px;
        position: relative;
        height: 320px;
        margin-top: 40px !important;
    }
    .soul-detail-content-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        padding: 10px;
    }
    .step-2-go-test{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .image-title-section{
        height: 120px;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
    }
    .home-component-card .card-title{
        font-size: 19px;
        line-height: 24px;
    }
    .number-title{
        font-size: 17px;
    }
    .start-btn{
        font-size: 13px;
    }
    .edit-btn{
        font-size: 13px;
    }
    .line{
        width:80px;
    }
    .forMobile{
        display: block !important;
    }
    .forWeb{
        display: none !important;
    }
    .action-btn{
        height: 40px !important;
        width: 40px !important;
    }
    .bulb-icon{
        height: 20px !important;
        margin-right: 5px !important;
    }
    .take-test-btn{
        height: 44px;
        padding: 5px 20px;
        font-size: 16px;
    }
    .survey-behavior-btn-non-active{
        height: 44px;
        padding: 5px 20px;
        font-size: 16px;
    }
    .edit-test-survey-btn{
        height: 44px;
        padding: 5px 20px;
        font-size: 18px;
    }
    .survey-behavior-btn-active{
        height: 44px;
        padding: 5px 20px;
        font-size: 20px;
    }
    .step-title{
        font-size: 14px;
    }
    .note-number{
        font-weight: 400;
        font-size: 14px;
       
    }
    .note-modal-container{
        padding: 10px 20px;
    }
    .note-content{
        font-size: 12px;
        line-height: 140.5%;
    }
    .modal-button-for-soul-survey{
        height: 40px !important;
        font-size: 14px;
        width: 110px;
    }
    .tick-icon{
        position: absolute;
        right: -15px;
        top:-25px;
        width: 40px;
    }
    .soul-item-bg{
        margin: 10px 0px;
    }
}

@media (max-width:480px) {
    .back-icon{
        top: 0px !important;
        left: 10px;
        cursor: pointer;
        height: 100%;
        margin-left: 1em;
    }
    .image-pettal-soul{
        width: 100% !important;
        height: 150px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-radius: 20px !important;
        margin-top: -10px !important;
    }
    .soul-item-bg{
        width:95% !important;
        margin-top: 20px !important;
    }
    .soul-detail-content-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        padding: 20px 10px;
    }
    .step-2-go-test{
        font-size: 12px;
        margin-top: 20px;
    }
    .step-title{
        font-size: 14px;
        margin-top: 10px;
    }
    .button-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:20px
    }
    .edit-test-survey-btn{
        width: 271px;
        height: 45px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .take-test-btn{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .survey-behavior-btn-non-active{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .survey-behavior-btn-active{
        width: 271px;
        height: 45px;
        padding: 5px 20px;
        border-radius: 93px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    .image-container-soul{
        height: 160px;
        margin-top: 35px !important;
    }
    .image-title-section{
        display: none !important;
    }
    .image-title-section-for-mobile{
        height: 50px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
        background: #FFFFFF;
        text-align: center;
        border-bottom-left-radius: 28px;
        border-bottom-right-radius: 28px;
        box-shadow: 14px 2px 90px rgba(0, 0, 0, 0.14);
    }
    .image-section-mobile{
        display: block !important;
    }
    .image-section-web{
        display: none !important;
    }
    .edit-btn{
        position: relative;
        flex-wrap: nowrap;
    }
    .start-btn{
        position: relative;
        flex-wrap: nowrap;
    }
    .home-component-card .card-title{
        font-size: 13px;
        line-height: 20px;
        padding-bottom: 0px;
        white-space: nowrap !important;
    }
    .number-title{
        font-size: 12px;
        margin-top: 0px;
    }
    .home-icon{
        display: none;
    }
    .tick-icon{
        width: 30px;
        top: -20px;
        right: -10px;
    }
    .action-btn{
        top: 120px !important;
    }
}

@media (max-width:380px) {
    .line{
        width:60px;
    }
    .image-pettal-soul{
        width: 100% !important;
        height: 140px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .image-container-soul{
        height: 150px;
    }
    .home-component-card .card-title{
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 0px;
        white-space: nowrap !important;
    }
    .number-title{
        font-size: 11px;
        margin-top: 0px;
    }
    .action-btn{
        top: 100px !important;
    }
}
@media (max-width:340px) {

    .image-pettal-soul{
        width: 100% !important;
        height: 130px !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-radius: 20px !important;
    }
    .image-container-soul{
        height: 140px;
    }
}
@media (max-width:420px) {
    /* .line{
        width:30px;
    } */
}
@media (max-height:500px) {
    .main-container{
        margin-top: 20px !important;
    }
}
@media (max-height:370px) {
    .main-container{
        margin-top: 40px !important;
    }
}
@media screen and (max-width:600px) and (max-height:500px) {
    .main-container{
        margin-top: 0px !important;
    }
}
@media screen and (max-width:800px) and (max-height:500px) {
    .main-container{
            margin-top: 15px;
    }
}
@media (max-width:520px){
    .custom-side-modal{
        max-width: 300px !important;
    }
    .custom-close-btn{
        right: 260px !important;
    }
}
@media (max-width:340px){
    .custom-side-modal{
        max-width: 280px !important;
    }
    .custom-close-btn{
        right: 240px !important;
    }
}
@media (max-height:560px) {
    .side-modal{
        position: absolute !important;
        right: -20px !important;
        top: 80px;
        max-width: 500px;
        z-index: 1200;
        width: 520px;
        transform-origin: 453px 44px;

    }
}
@media (min-height:600px) {
    .action-btn{
        top: 170px !important;
    } 
    .side-modal{
        top: 160px !important;
    }
    .custom-close-btn{
        top: 200px !important;
    }
}
@media (min-height:800px) {
    .action-btn{
        top: 200px !important;
    } 
    .side-modal{
        top: 190px !important;
    }
    .custom-close-btn{
        top: 230px !important;
    }
}
@media (min-height:900px) {
    .action-btn{
        top: 230px !important;
    } 
    .side-modal{
        top: 220px !important;
    }
    .custom-close-btn{
        top: 260px !important;
    }
}
@media (min-height:1150px) {
    .action-btn{
        top: 260px !important;
    } 
    .side-modal{
        top: 250px !important;
    }
    .custom-close-btn{
        top: 290px !important;
    }
  
}
@media screen and (max-width:480px) and (min-height:600px) {
    .action-btn{
        top: 130px !important;
    } 
    .side-modal{
        top: 110px !important;
    }
    .custom-close-btn{
        top: 160px !important;
    }
}

/* soul home cards width adjustment. range from 320px to 438px */


@media screen and (max-width: 375px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 2em 0 2em;
    }
}

@media screen and (max-width: 320px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 1.3em 0 1.3em;
    }
}

@media screen and (min-width: 413px) and (max-width: 438px) and (orientation: portrait) {
    .soul-row-mobile {
        padding: 0 3em 0 3em;
    }
}


.value-section{
    border-radius: 1.1875em;
    background: #FFF;
    box-shadow: -6px 2px 61px 12px rgba(0, 0, 0, 0.06);
    margin: 3em 1em 0.5em 1em;
    padding: 2em 2.1em;
    width: 45%;
}
.value-list{
    padding: 0.625em 0.8125em 0.625em 0.8125em;
    margin: 2em 0.2em;
    border-radius: 0.6875em;
    background:  #F5F5F5;
    display: flex;
    justify-content: start;
    align-items: center;
}

.value-list h4{
    color:#1F1F1F;
    font-family: Poppins;
    font-size: 1.3125em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    /* word-break: break-all; */
}

.value-list span{
    color:#009AF1;
    margin-right: 0.75em;
}

@media screen and (max-width:1150px) {
    .value-section{
        width: 65%;
    }
}

@media screen and (max-width:950px) {
    .value-section{
        width: 75%;
    }
}

@media screen and (max-width:800px) {
    .value-section{
        width: 95%;
    }
}
