.custom-filter-container{
    box-shadow: 8px 8px 20px 0px #00000040;
    border-radius: 1em;
    background-color: #fff;
    padding: 1em;
    margin-top: 1em;
}
.custom-filter-main-container{
    border-radius: 1em;
    background-color: #fff;
}
.filter-btn,.filter-btn:hover,.filter-btn:focus{
    background-color: #0DD671;
    color: #fff;
    font-family: 'Poppins';
    border-radius: .5em;
    border: none;
    padding: .5em 1em;
    justify-content: center;
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
    width: 7em;
}
.filter-title{
    font-family: 'Poppins';
    font-size: 1.15em;
    font-weight: 600;
    line-height: normal;
    color: #000;
    margin: 0px;
}
.clear-filter-btn,.clear-filter-btn:hover,.clear-filter-btn:focus{
    background-color: #DBE6EF;
    color: #000;
    font-family: 'Poppins';
    border-radius: .5em;
    border: none;
    padding: .5em 1em;
    justify-content: center;
    font-size: 1em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
}
.filter-data-container{
    font-size: .9em;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content:space-between;
    align-items: center;
    max-width: 12em;
    width: 100%;
    margin-top: .5em;
    padding: .25em 1em;
    background: rgba(219, 230, 239, 0.2);
}
.filter-data-container-selected{
    background-color: #4CCB1F;
    color: #fff !important;
}
.custom-filter-scroll::-webkit-scrollbar {
    height: 5px;
    display: block !important;
    width: 4px;
  }
  
  .custom-filter-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .custom-filter-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }