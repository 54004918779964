.audio-player {
    width: 100% !important; 
}

.hide-audio-player{
    height: 0px !important; 
    visibility: hidden;
}

.transcript-audio-player{
    height: 30px !important;
}

.transcript-audio-player::-webkit-media-controls-time-remaining-display,
::-webkit-media-controls-current-time-display,
::-webkit-media-controls-menu {
    display: none !important;
}

.transcript-audio-player::-webkit-media-controls-timeline {
    padding: 0px !important;
    margin: 0px 2px !important;
}

.transcript-audio-player::-webkit-media-controls-panel {
    padding: 0px !important;
    background:rgb(229, 228, 228) !important;
}

.transcript-audio-player::-webkit-media-controls{
    background: rgb(229, 228, 228);
    padding: 0px !important;
    border-radius: 8px;
    border: 2px solid #4CCB1F;
}

.highlight-audio-player::-webkit-media-controls{
    border: 2px solid #009AF1 !important; 
}

.audio-container{
    position: relative;
    width: 100%;
}

.custom-audio-player{
    position: absolute;
    top: -35px;
}

.custom-audio-player .ant-progress-inner {
    height: 4px;
    border-radius: 5px;
}

.active-audio-player{
    border: 2px solid #009AF1 !important; 
}

.audio-border-custom{
    height: 30px !important;
    border: 2px solid #4CCB1F;
    width: 100%;
    border-radius: 8px;
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.audio-border-custom .ant-slider-track{
    background-color: #4CCB1F;
}

.active-audio-player .ant-slider-track{
    background-color: #009AF1;
}