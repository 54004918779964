::-webkit-scrollbar {
display: none;
  }
  
/* ::-webkit-scrollbar-thumb {
    background: rgb(196, 188, 188);
    height: 50px;
  } */

  /* .new_feedback_home {
    scrollbar-color: red yellow;
  } */

.feedback-home-image {
width: 60%;
height: auto;
margin-top: 5em;
margin-left: auto;
margin-right: auto;
z-index: 1;
}

.feedback-home-btn-cont {
    margin-left: auto;
    margin-right: auto;
}

.feedback-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-left-wireframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    z-index: 0;
}

.feedback-right-wireframe {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    z-index: 0;
}

.feedback-view-btn:hover {
border-color: #3AE100;
    background-color: #3AE100;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.feedback-view-btn {
    margin: '0 1.5em'; 
    border: 1.5px solid;
    border-color: #4CCB1F; 
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-give-btn {
    background-color: #4CCB1F;
    border: solid #4CCB1F;
    border-radius: 5px;
    cursor: pointer;
}

.feedback-give-btn:hover {
    background-color: #3AE100;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
    border: solid #3AE100;
}

/* CSS FOR HANDLING GRID FOR MOBILE VIEW ADJUSTMENTS */

.feedback-btn-container {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
}

@media screen and (max-width: 415px) {
    .feedback-btn-container {
        margin: 1em auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }
    .feedback-home-image {
        width: 78%;
    margin-top: 2em;
        }
}
