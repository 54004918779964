.occurance-modal{
    width: 800px !important;
    max-width: 90% !important;
    height: 80vh !important;
}
/* .occurance-modal .ant-modal-content{
 height: 400px;
} */
.aligin-center{
    display: flex;
    align-items: center !important;
}
.occarance-modal-title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin: 0px !important;
}


.meeting-enede-button-panel .ant-radio-button-wrapper:not(:first-child)::before{
    display: none;
}

.occurance-save{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #fff;
    padding: 0px 15px 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 60px;
}

.occurance-save:hover{
    background: #4CCB1F;
    border: 2px solid #4CCB1F;
    color: #fff;
}

.occurance-row{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.day-btn{
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    height: 40px;
    width: 40px;
    font-size: 16px;
    border: 1px solid #4CCB1F;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.day-btn:hover{
    background: #4CCB1F;
    color: #fff;
    border: 1px solid #4CCB1F;
}
.day-btn:focus{
    background-color: #fff;
    color: black;
    border: 1px solid #4CCB1F;
}

.active-day-btn{
    background: #4CCB1F !important;
    color: #fff !important;
}

.oc-duration input{
        height: 36px !important;
       
}

.invalid-duration input{
border: 1px solid #ff4d4f;
}

.soul-tool-tip{
    z-index: 550 !important;
}


.meeting-feedback-detailed-box{
    min-height: 150px;
}

/* .ant-picker-footer {
    display: none;
} */
.occurance_dropdown .ant-select-selector input{
    width: 120px !important;
    margin-left: -10px !important;
}
@media screen and (max-width:960px) {
    .occarance-modal-title{
      font-size:22px ;
    }
  
}
  
@media screen and (max-width:600px) {
    .occarance-modal-title{
        font-size:20px ;
    }
}
