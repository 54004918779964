.create-employee-invitation-root{
    box-shadow: 0px 10px 15px 0px rgba(170, 186, 198, 0.2);
    background: rgba(255, 255, 255, 1);
    border-radius:0.75em;
    min-height: 70vh;
}

.create-invite-header-section{
    background:rgba(170, 186, 198, 0.2);
    border: 1px solid transparent;
    width:max-content !important;
    min-width: 100%;
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.75em 0.5em;
    gap: 1em;
}

.create-invite-form-container{
    background: #fff;
    border: 1px solid rgba(170, 186, 198, 1);
    width: fit-content;
    min-width: 100%;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    padding: 0.75em 0.5em;
    margin-top: 1em;
}

.create-item-section{
    width: 16.66% !important;
    min-width: 10em !important;
    overflow: hidden;
}



.invite-form-label{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.375em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px !important;
}



.create-invite-form-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
}



.add-mode-btn,.add-mode-btn:hover,.add-mode-btn:focus{
    margin-top: 1em;
    border: 1px solid rgba(13, 214, 113, 1);
    border-radius: .5em;
    height: 2.5em;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    font-style: normal;
    color: rgba(13, 214, 113, 1);
    background-color: #fff;
    outline: none;
    padding: .5em 1em;
}
.org-invite-cancel-btn,.org-invite-cancel-btn:focus,.org-invite-cancel-btn:hover{
    border: 1px solid rgba(108, 114, 127, 1);
    background: #FFF;
    color: rgba(108, 114, 127, 1);
    border-radius:.5em !important;
    cursor: pointer;
    outline: none;
    font-size: 1.25em;
    text-transform: uppercase;
    font-family: "Poppins";
    font-weight: 500;
    height: 2.5em;
    text-align: center;
    padding: .5em 1em;
}

.poc-height-limit{
    overflow: auto;
    max-height: calc(100vh - 360px);
    padding-right: 0.5em;
}

.invitation-overflow{
    overflow: auto;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}
.invitation-overflow::-webkit-scrollbar ,.poc-height-limit::-webkit-scrollbar{
    height: 5px;
    display: block !important;
    width: 4px;
  }
  
  .invitation-overflow::-webkit-scrollbar-track ,.poc-height-limit::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
  }
  
  .invitation-overflow::-webkit-scrollbar-thumb ,.poc-height-limit::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }

  .poc-employee-invite-container{
    width: 100%;
    padding: 0px 0.5em;
  }

  .poc-employee-invite-table{
    width: 100%;
  }



  .action-row-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
  }

  .poc-employee-invite-table{
    border-spacing: 0px 1em;
    border-collapse: separate;
  }

  .poc-employee-invite-table thead{
    height: 3.5em;
  }

  .poc-employee-invite-table thead tr{
    background: rgba(170, 186, 198, 0.2);
  }
  
  .poc-employee-invite-table thead tr :first-child{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  .poc-employee-invite-table thead tr :last-child{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .poc-employee-invite-table thead tr th{
    padding: 0.5em;
  }

  .poc-employee-invite-table tbody{
    height: 3.5em;
  }

  .poc-employee-invite-table tbody tr :first-child{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  .poc-employee-invite-table tbody tr :last-child{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .poc-employee-invite-table tbody tr td{
    border: 1px solid rgba(170, 186, 198, 1);
    padding: 0.5em;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .invite-table-border-left{
    border-left: 1px solid rgba(170, 186, 198, 1) !important;
  }

  .invite-table-border-right{
    border-right: 1px solid rgba(170, 186, 198, 1) !important;
  }

  .invite-form-input{
    width: 16em !important;
  }

  /* error handle */

 .poc-employee-invite-table tbody .create-invite-form-invalid td{
    border: 1px solid #E86161;
    padding: 0.5em !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  } 

  .create-invite-form-invalid .invite-table-border-left{
    border-left: 1px solid #E86161 !important;
  }

  .create-invite-form-invalid .invite-table-border-right{
    border-right: 1px solid #E86161 !important;
  }


  @media only screen and (max-width: 1800px) {
    .invite-form-input{
        width: 14em !important;
      }
  }

  @media only screen and (max-width: 1600px) {
    .invite-form-input{
        width: 13em !important;
      }
  }