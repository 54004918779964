.profile-detail-layout{
    padding: 0px 3em;
}

.profile-card{
    box-shadow: 0px 10px 15px 0px #AABAC633;
    width: 100%;
    border-radius: 0.75em;
    background-color: #FFFFFF;
    font-family: Poppins;
    color: #000000;
}

.row-wrapper{
    display: flex;
    flex-direction: row;
    
}

.col-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.ipad-view{
    display: none;
}
.web-view{
    display: block;
}
.user-profile-detail{
    width: 35%;
    border-right: 2px solid #8998A2;
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    justify-content: space-between;
    align-items: start;
}

.pro-data-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.user-profile-detail img{
    width: 8em;
    height:8em;
    border-radius: 50%;
    object-fit: cover;
}
.pro-data-image-container{
    width: 8em;
    height:8em;
    border-radius: 50%;
    position: relative;
}
.pro-data-image-container img{
    width: 8em;
    height:8em;
    border-radius: 50%;
    object-fit: cover;
}
.pro-data-image-container:hover .pro-data-hover-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1200;
    gap:.5em
}
.pro-data-hover-content{
    width: 8em;
    height:8em;
    position: absolute !important;
    display: none;
    top: .5em;
}
.pro-data-hover-content img{
    width: 2em;
    height:2em;
}
.pro-data-hover-content p{
    font-size: .85em;
    font-family: 'Poppins';
    font-weight: 500;
    color: #FFFFFF;
    line-height: normal;
    text-align: center;
}
.Profile-pic-option-container{
    box-shadow: 7px 10px 15px 0px #AABAC64D;
    background: #FFF;
    border-radius: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width:fit-content;
    position: absolute;
    left: 10px;
    z-index: 1100 !important;
}
.profile-pic-upload-button .ant-upload{
    font-size: 1em ;
    font-family: 'Poppins' !important;
    font-weight: 500;
}
.Profile-pic-option-container h4 {
    font-size: 1em;
    font-family: 'Poppins' !important;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    white-space: nowrap;
    padding: .5em;
    cursor: pointer;
}
.Profile-pic-option-container h4:hover{
    background: #AABAC633;
    border-radius: .5em;
}
.profile-name{
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.1em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.role-name{
    font-size: 1.15em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.user-surevey-detail{
    width: 75%;
    padding-left: 5%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
}

.info-label-title{
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.313em;
    letter-spacing: 0em;
    text-align: left;
    color: #8998A2;
    margin: 0px;
    white-space: nowrap;
}

.info-label-content{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
}

.profile-tab-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    overflow-x: auto;
}

.profile-tab-normal{
    padding: 1em 2.25em 0.5em 2.25em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #D9D9D9;
}

.profile-tab-active{
    border-bottom: 5px solid #4CCB1F;
}

.profile-tab-active-label{
    color: #4CCB1F;
}

.profile-tab-label{
    color: #8998A2;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
}

.user-content-container{
    padding: 2em 2.5em;
}

.employee-profile-table{
    min-width: 600px;
    width: 100%;
    border-collapse:separate;
    border-spacing:0 15px;
}

.employee-profile-table tr th{
    color:  #A3A3A3;
    font-size: 1em;
    font-family: 'Manrope';
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
    text-align: center;
}

.employee-profile-table .table-body-td{
    border-top: 1px solid #D5E5F2 !important;
    border-bottom: 1px solid #D5E5F2 !important;
    text-align: center;
}

.table-body-tr .first-child{
    border-right: none;
    border-left: 1px solid #D5E5F2 !important;
    border-top-left-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
    padding-left: 1.5em;
}

.table-body-tr .last-child{
    border-left:none ;
    border-right: 1px solid #D5E5F2 !important;
    border-top-right-radius: 0.75em;
    border-bottom-right-radius: 0.75em;
    padding-right: 1.5em;
}
.employee-profile-table  td {
    padding: 1em;
}

.td-main-label{
    font-family: Poppins;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.313em;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
}

.td-sub-label{
    font-family: Poppins;
    font-size: 0.938em;
    font-weight: 400;
    line-height: 1.438em;
    letter-spacing: 0em;
    text-align: left;
    color: #8D8D8D;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
}

.impact-header-userprofile{
    width: 100%;
    box-sizing: border-box;
    border: 4px solid #02D7A4; 
    border-radius: 1.5em;
    padding: 1.25em;
    position: relative;
    margin-top: 2em;
}

.header-border-user-profile{
    position: absolute;
    top: -1.6em;
    background:transparent;
    width: 100%;
    left: 3em;
    right: 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    box-sizing: border-box;
}

.border-inner-content-user-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: #FFFFFF;
    padding: 0px 1em;
    box-sizing: border-box;
}

.header-border-label-user-profile{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75em;
    background: linear-gradient(270.07deg, #25EA68 18.24%, #02D7A4 87.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    flex: none;
    flex-grow: 0;
    margin: 0px;
}

.user-impact-content{
    font-family: Poppins;
    font-size: 1.15em;
    font-weight: 500;
    line-height: 2.25em;
    letter-spacing: 0em;
    text-align: justified;

}

.cs-user-progress-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    width: 100%;
    padding:1em 1.5em;
}

.detail-btn{
    width: 10em;
    max-width: 95%;
    border-radius: 1em;
    border-radius: 0.5em;
    font-family: Poppins;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.5em 1em 1.5em 1em;
}

.no-data-profile{
    font-size: 1.5em;
}

.employee-badge-icon{
    width: 3.5em;
    height: 3.5em;
    object-fit: cover;
}

.employee-badge-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.content-wrap{
    flex-wrap: wrap !important;
}
@media (max-width:1100px) {
    .media-col-wrapper{
        flex-direction: column !important;
        gap: 1.5em;
    }
    .user-profile-detail{
        width: 100% !important;
        border-right: none;
    }
    .user-surevey-detail{
        padding-left: 0px;
        width: 100% !important;
    }

    .ipad-view{
        display: block;
    }
    .web-view{
        display: none;
    }
}
