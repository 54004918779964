
.sa-title-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.sc-create-container-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    padding: 1em 10em;
}
.sc-create-content-container{
    width: 100%;
    background: #F4F9FD;
    border-radius: 6px;
    min-height: 20em;
    padding: 2em 1.5em;
}
.sa-create-title{
    font-family: 'Poppins';
    font-size: 1.2em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-create-main-title{
    font-family: 'Poppins';
    font-size: 1.5em;
    font-weight: 600;
    font-style: normal;
    color: black;
    line-height: normal;
    text-align: center;
}
.sa-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1em;
}
.sa-text-input, .sa-text-input:hover, .sa-text-input:focus, .sa-text-input:focus-visible, .sa-text-input:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 3em;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.sa-text-area, .sa-text-area:hover, .sa-text-areat:focus, .sa-text-area:focus-visible, .sa-text-area:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 12.25em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
    resize: none;
}
.sa-select-filed .ant-select-selector,.sa-select-filed .ant-select-selector:hover,.sa-select-filed .ant-select-selector:focus,.sa-select-filed .ant-select-selector:focus-visible,.sa-select-filed .ant-select-selector:focus-within{
    background: #fff !important;
    border-radius: 0.5em !important;
    height: 3em !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #AABAC6 !important;
    font-weight: 400;
    font-size: 1em;
}
.sa-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 3em !important;
    font-size: 1em;
    font-family:'Poppins';
    font-weight: 400;
}
.sa-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0.375em !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1em;
}
.sa-type-select .ant-select-selector .ant-select-selection-item{
    padding-top: .375em !important;
    font-weight: 500;
    font-size: 1em;
}
.sa-select-option.ant-select-item p{
    margin: 0px;
}
.sa-select-option.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 0.375em !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.sa-select-option.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 0.375em !important;
    
}
.sa-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 500;
    line-height: 1.25em;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: .375em !important;
}
.sa-switch{
     height: 2.25em;
     margin-top: 2em;
}
.sa-switch .ant-switch-handle{
    height: 1.5em;
    width: 1.5em;
    top: 5px;
    border-radius: 50% !important;
}
.sa-switch .ant-switch-inner{
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2px;
}
.sa-form-container .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 6px);
}
.sa-form-container .ant-switch-checked {
    background-color: #4CCB1F !important;
}
.sa-confirmation-action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}